<template>
    <v-dialog v-model="showModal" scrollable width="500px" content-class="ds-dialog" persistent>
        <v-card class="ds-modal-card" dusk="confirmationModal">
            <v-toolbar dark :color="toolbarColor" elevation="0">
                <v-icon v-if="icon" :color="iconColor" class="mr-3">{{ icon }}</v-icon>
                <v-toolbar-title :class="titleClass">{{ title }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content">
                <p class="black--text" v-html="message"></p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn v-if="showCancel" class="mr-2" text color="primary" @click="cancel" dusk="cancelButton">Cancel</v-btn>
                        <v-btn class="ml-2" color="primary" outlined @click="confirm" name="confirm" dusk="confirmButton">{{ confirmText }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    const defaultOptions = {
        title: 'Confirmation dialog',
        titleClass: 'white--text',
        message: 'Are you sure you want to take this action?',
        cancelText: 'Cancel',
        cancelColor: 'primary_text',
        confirmText: 'Confirm',
        toolbarColor: 'primary',
        icon: null,
        iconColor: 'primary',
        showCancel: true,
    }

    export default {
        name: "ConfirmationDialog",

        data() {
            return {
                ...defaultOptions,
                showModal: false,
                resolve: null,
                reject: null,
                api: new formHelper(),
            }
        },

        methods: {
            open(options = {}) {
                this.assignOptions(options)

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                    this.showModal = true
                })
            },

            confirm() {
                this.closeModal()
                this.resolve()
            },

            cancel() {
                this.closeModal()
                this.reject()
            },

            closeModal() {
                this.showModal = false
                this.resetOptions()
            },

            assignOptions({
                              title = this.title,
                              message = this.message,
                              cancelText = this.cancelText,
                              confirmText = this.confirmText,
                              showCancel = this.showCancel,
                              toolbarColor = this.toolbarColor,
                              icon = this.icon,
                              iconColor = this.iconColor,
                              titleClass = this.titleClass,
                              cancelColor = this.cancelColor
            } = {}) {
                this.title = title
                this.message = message
                this.cancelText = cancelText
                this.confirmText = confirmText
                this.showCancel = showCancel
                this.toolbarColor = toolbarColor
                this.icon = icon
                this.iconColor = iconColor
                this.titleClass = titleClass
                this.cancelColor = cancelColor
            },

            resetOptions() {
                this.assignOptions(defaultOptions)
            }
        },
    }
</script>

<style scoped>

</style>
