<template>
    <v-dialog v-model="showModal" scrollable width="500px" persistent>
        <v-card>
            <v-toolbar dark color="error" elevation="0">
                <v-icon color="white" class="mr-3">mdi-alert</v-icon>
                <v-toolbar-title class="white--text">Mark "No Show"</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-6">
                <p class="black--text">Would you like to mark {{ staff_member }} as a "No Show" for this shift?</p>

                <p class="black--text">This action can't be undone.</p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn
                            class="mr-2"
                            text
                            color="primary_text"
                            @click="cancel"
                        >
                            Go Back
                        </v-btn>

                        <v-btn
                            class="ml-2"
                            outlined
                            color="error"
                            @click="confirm"
                            name="confirm"
                        >
                            No Show
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "NoShowConfirmationDialog",

        data() {
            return {
                staff_member: 'Staff Member',
                showModal: false,
                resolve: null,
                reject: null,
                api: new formHelper(),
            }
        },

        methods: {
            open(staff_member) {
                this.staff_member = staff_member

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                    this.showModal = true
                })
            },

            confirm() {
                this.closeModal()
                this.resolve()
            },

            cancel() {
                this.closeModal()
                this.reject()
            },

            closeModal() {
                this.showModal = false
                this.staff_member = "Staff Member"
            },
        },
    }
</script>

<style scoped>

</style>
