<template>
    <v-menu open-on-click bottom offset-y>
        <template v-slot:activator="{ on, value }">
            <div
                v-on="on"
                class="sort-by-menu-container"
            >
                <div class="sort-by-menu-text-container">
                    <span class="sort-by-menu-text-ellipsis">
                        {{ itemText }}
                    </span>
                    <span class="ml-1">({{ sortText }}</span>
                    <v-icon small style="color: #485FF8;">
                        {{ sortAsc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                    </v-icon>
                    <span>
                        )
                    </span>
                </div>
                <v-icon
                    right
                    dark
                    small
                    style="color: #485FF8;"
                >
                    {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </div>
        </template>
        <v-card>
            <v-list>
                <v-list-item-group
                    v-model="selectedId"
                    color="primary"
                    mandatory
                >
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        :value="item._id"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <div class="sort-by-menu-text-container">
                                    <span>
                                        {{ item.text }} ({{ item.sortText }}
                                    </span>
                                    <v-icon small>
                                        {{ item.asc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                                    </v-icon>
                                    <span>
                                        )
                                    </span>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "SortByMenu",
    props: {
        value: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        selectedId: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
        selectedItem () {
            return this.items.find(item => item._id === this.selectedId)
        },
        itemText () {
            return this.selectedItem?.text ?? 'Staff Group'
        },
        sortText () {
            return this.selectedItem?.sortText ?? 'A'
        },
        sortAsc () {
            return this.selectedItem?.asc
        }
    }
}
</script>

<style scoped>
    .sort-by-menu-container {
        display: flex;
        flex-direction: row;
        padding: 8px 2px;
        font-size: 16px;
        color: #485FF8;
        cursor: pointer;
        min-width: 0;
        z-index: 20;
    }

    .sort-by-menu-container:hover {
        background-color: #F1F2FE;
        border-radius: 5px;
    }

    .sort-by-menu-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 0;
    }

    .sort-by-menu-text-ellipsis {
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
