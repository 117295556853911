import { render, staticRenderFns } from "./DepartmentCard.vue?vue&type=template&id=ecb63104&scoped=true"
import script from "./DepartmentCard.vue?vue&type=script&lang=js"
export * from "./DepartmentCard.vue?vue&type=script&lang=js"
import style0 from "./DepartmentCard.vue?vue&type=style&index=0&id=ecb63104&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecb63104",
  null
  
)

export default component.exports