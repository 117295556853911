<template>
    <v-card class="elevation-0">
        <v-toolbar class="ds-toolbar" elevation="0">
            <v-toolbar-title>{{ hospital_group.name ?? 'Organization' }}</v-toolbar-title>
            <v-btn
                class="ds-toolbar-fab-button"
                fab
                small
                elevation="1"
                :disabled="!hasViewSettingsPermission"
                @click="onViewConfigurationOptions"
            >
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="ds-toolbar-action-button"
                elevation="1"
                :disabled="!hasEditLocationsPermission"
                @click="onNewLocation"
            >
                New Location
            </v-btn>
        </v-toolbar>

        <v-container fluid>
            <organization-notice v-if="!notice_dismissed" @noticeDismissed="onNoticeDismissed"></organization-notice>

            <v-row class="mt-4">
                <div v-show="api.busy" style="height:100%">
                    <loading-spinner :value="true"></loading-spinner>
                </div>
                <v-col
                    v-for="location in locations"
                    :key="location._id"
                    cols="12"
                    md="6"
                    lg="4"
                >
                    <location-overview-card
                        :location="location"
                        @onViewLocationDetails="onViewLocationDetails"
                    />
                </v-col>
            </v-row>
        </v-container>
        <location-modal ref="createLocationModal"/>
    </v-card>
</template>

<script>
import OrganizationNotice from "./components/OrganizationNotice";
import LoadingSpinner from "../../../components/loaders/LoadingSpinner";
import LocationOverviewCard from "./components/LocationOverviewCard";
import LocationModal from "../Location/LocationModal";

export default {
    name: "OrganizationWindow",
    components: {
        LocationModal,
        LocationOverviewCard,
        LoadingSpinner,
        OrganizationNotice
    },
    data() {
        return {
            hospital_group: this.$appData.hospital_group,
            locations: this.$appData.locations,
            notice_dismissed: this.$appData.notice_dismissed,
            api: new formHelper(),
        }
    },
    computed: {
        hasViewSettingsPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.SETTINGS.VIEW)
        },
        hasEditLocationsPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.LOCATIONS.EDIT)
        },
    },
    methods: {
        onViewLocationDetails(location) {
            this.emitLocationViewEvent(location)
        },
        onNoticeDismissed() {
            this.notice_dismissed = true
        },
        loadLocations() {
            this.api.get('list-organization-locations')
                .then(({data}) => {
                    this.locations = data
                })
        },
        onNewLocation() {
            if (!this.hasEditLocationsPermission) {
                return
            }

            this.$refs.createLocationModal.open()
                .then(({action, location}) => {
                    if (action === 'created' && location) {
                        this.locationCreated(location)
                    }
                })
                .catch(() => {})
        },
        locationCreated(location) {
            this.emitLocationViewEvent(location, true)
        },
        emitLocationViewEvent(location, location_created = false) {
            this.$emit('location:view', {location: location, location_created: location_created})
        },
        onViewConfigurationOptions() {
            if (!this.hasViewSettingsPermission) {
                return
            }

            this.$emit('configuration-options:view')
        }
    }
}
</script>

<style scoped>

</style>
