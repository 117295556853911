<template>
    <v-autocomplete
        v-model="selectedLocations"
        :items="locations"
        item-value="_id"
        item-text="name"
        :disabled="disabled"
        outlined
        multiple
        hide-details
        clearable
        required
        label="Locations"
        class="mt-2 mb-2 required"
        ref="bulkUploadUsersLocationsRef"
    >
        <template v-slot:prepend-item>
            <div class="d-flex flex-column justify-center align-center">
                <v-btn
                    color="primary"
                    text
                    style="text-transform: none;"
                    @click="onApplyAll"
                >Apply to All</v-btn>
            </div>
        </template>
        <template v-slot:selection="{ item, index }">
            <div v-if="index < maxDisplay" class="v-select__selection v-select__selection--comma">
                {{ index === maxDisplay ? `${item.name}, ` : index === selectedLocations.length - 1 ? item.name : `${item.name}, ` }}
            </div>
            <div v-if="index === maxDisplay" class="v-select__selection v-select__selection--comma">
                (+{{ selectedLocations.length - maxDisplay }})
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "BulkUploadUsersLocations",
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        locations: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        maxDisplay: 2,
    }),
    computed: {
        selectedLocations: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        onApplyAll() {
            this.$refs.bulkUploadUsersLocationsRef?.blur()
            this.$emit('onApplyAll', this.selectedLocations)
        }
    }
}
</script>

<style scoped>

</style>
