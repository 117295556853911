<template>
    <div class="calendar-icon-event-container">
        <div class="calendar-icon-event-icon-container">
            <v-icon color="#000000" class="calendar-icon-event-icon">mdi-clock-outline</v-icon>
        </div>
        <div class="calendar-icon-event-divider-container">
            <div class="calendar-icon-event-divider"></div>
        </div>
        <div class="calendar-icon-event-icon-container">
            <v-icon color="#000000" class="calendar-icon-event-icon">mdi-account</v-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: "IconEvent"
}
</script>

<style scoped>
    .calendar-icon-event-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .calendar-icon-event-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        flex: 1 0 auto;
    }

    .calendar-icon-event-icon {
        font-size: 18px;
    }

    .calendar-icon-event-divider-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 5px;
    }

    .calendar-icon-event-divider {
        border-top: 1px solid white;
        width: 100%;
    }
</style>
