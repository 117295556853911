<template>
    <v-row class="pa-3">
        <v-col cols="12" class="d-flex flex-row justify-space-between align-center">
            <span>
                Standardize Shift Times for Users across all or select Locations. Select a row to edit it.
            </span>
            <v-btn
                color="secondary"
                :disabled="!hasEditShiftTimesPermission"
                @click="onAddShiftTime"
            >
                Add Shift Time
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table
                id="shift-times-table"
                :height="tableHeight"
                :headers="headers"
                :items="shiftTimes"
                :loading="shiftTimesLoading"
                disable-pagination
                fixed-header
                hide-default-footer
                @click:row="onEditShiftTime"
            >
                <template v-slot:item.startTime="{ item }">
                    {{ item.startTime | timeAMPM }}
                </template>

                <template v-slot:item.endTime="{ item }">
                    {{ item.endTime | timeAMPM }}
                </template>

                <template v-slot:item.duration="{ item }">
                    {{ item.duration | timeStringFromMinutes }} Hours
                </template>

                <template v-slot:item.locations="{ item }">
                    <truncating-chips-list :items="item.locations" v-if="item.locations.length > 0" view-only/>
                    <span v-else>N/A</span>
                </template>
            </v-data-table>
        </v-col>
        <save-shift-time-modal
            v-model="shiftTimeModalValue"
            :shift-time-id="selectedShiftTimeId"
            @onSave="onSaveShiftTime"
            @onDelete="onDeleteShiftTime"
        />
    </v-row>
</template>

<script>
import TruncatingChipsList from "../../../../../components/common/Lists/TruncatingChipsList";
import SaveShiftTimeModal from "./SaveShiftTimeModal";

export default {
    name: "ShiftTimesTab",
    components: {TruncatingChipsList, SaveShiftTimeModal},
    props: {
        tab: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            headers: [
                {text: 'Shift Name', value: 'name'},
                {text: 'Start Time', value: 'startTime'},
                {text: 'End Time', value: 'endTime'},
                {text: 'Duration', value: 'duration'},
                {text: 'Applied to Locations', value: 'locations', sortable: false},
            ],
            shiftTimes: [],
            shiftTimeModalValue: false,
            shiftTimesLoading: false,
            selectedShiftTimeId: null,
            api: new formHelper()
        }
    },
    computed: {
        tableHeight() {
            return window.innerHeight - 320
        },
        hasEditShiftTimesPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.SHIFT_TIMES.EDIT)
        }
    },
    methods: {
        onAddShiftTime() {
            if (!this.hasEditShiftTimesPermission) {
                return
            }

            this.shiftTimeModalValue = true
            this.selectedShiftTimeId = null
        },
        onEditShiftTime(shiftTime) {
            if (!this.hasEditShiftTimesPermission) {
                return
            }

            this.shiftTimeModalValue = true
            if (shiftTime && shiftTime._id) {
                this.selectedShiftTimeId = shiftTime._id
            }
        },
        fetchShiftTimes() {
            this.shiftTimesLoading = true
            this.shiftTimes = []
            this.api.get('/shift-time/list-for-organization').then(result => {
                if (result && Array.isArray(result.data)) {
                    this.shiftTimes = result.data.map(shiftTime => ({
                        _id: shiftTime._id,
                        name: shiftTime.label,
                        startTime: shiftTime.start_time,
                        endTime: shiftTime.end_time,
                        duration: shiftTime.duration,
                        locations: shiftTime.locations.map(loc => loc.name),
                    }))
                }
            }).catch(console.log).finally(() => this.shiftTimesLoading = false)
        },
        onSaveShiftTime() {
            this.fetchShiftTimes()
        },
        onDeleteShiftTime() {
            this.fetchShiftTimes()
        }
    },
    watch: {
        tab: {
            immediate: true,
            handler(tab) {
                if (tab === 1) {
                    this.fetchShiftTimes()
                }
            }
        }
    },
}
</script>

<style scoped>
#shift-times-table :deep(tr:hover) {
    cursor: pointer;
}
</style>
