<template>
    <v-row>
        <v-col class="scrollable" style="padding-top: 0;" v-if="!isInPast">
            <div class="d-flex flex-column fill-height">
                <div class="d-flex justify-space-between align-center text--h4 black--text mt-2 mb-3">
                    <div>
                        <slot name="left-list-text-left"></slot>
                    </div>
                    <div>
                        <slot name="left-list-text-right"></slot>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <slot name="left-list"></slot>
                </div>
            </div>
        </v-col>
        <v-col cols="auto" class="divider" style="padding-top: 0;" v-if="!isInPast">
            <div class="action-divider-container">
                <div class="divider-container"></div>
            </div>
        </v-col>
        <v-col class="scrollable" :style="containerPadding">
            <div class="d-flex flex-column fill-height">
                <div class="d-flex justify-space-between align-center text--h4 black--text mt-2 mb-3">
                    <div>
                        <slot name="right-list-text-left"></slot>
                    </div>
                    <div>
                        <slot name="right-list-text-right"></slot>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <slot name="right-list"></slot>
                </div>
                <div class="d-flex flex-row justify-start align-center">
                    <slot name="right-list-action-button"></slot>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "UserListsBase",
    props: {
        isInPast: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        containerPadding () {
            if (this.isInPast) {
                return {
                    'padding-top': '24px'
                }
            }
            return {
                'padding-top': 0
            }
        }
    }
}
</script>

<style scoped>
    .scrollable {
        max-height: 580px;
        overflow-y: auto;
    }

    .divider {
        max-height: 580px;
    }

    .action-divider-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .divider-container {
        flex: 1 1 auto;
        border-left: 1px solid lightgray;
    }
</style>
