<template>
    <v-select
        v-model="selectedStatus"
        :items="status"
        item-value="id"
        item-text="text"
        :readonly="readonly"
        :clearable="!readonly"
        multiple
        label="Offer Status"
        @change="onChangeStatus"
        outlined
    />
</template>

<script>
export default {
    name: "StatusSelectInput",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        },
        preset: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        status: [{
            id: 'requested',
            text: 'Requested',
        }, {
            id: 'accepted',
            text: 'Accepted',
        }, {
            id: 'rejected',
            text: 'Rejected',
        }],
    }),
    computed: {
        selectedStatus: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onChangeStatus() {
            this.$emit('onChangeStatus')
        }
    },
    watch: {
        preset: {
            immediate: true,
            handler(preset) {
                if (preset && preset.filters) {
                    const {status} = preset.filters
                    if (Array.isArray(status)) {
                        this.selectedStatus = Array.from(status)
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
