<template>
    <v-row style="margin-top: 25px;">
        <v-col cols="12" class="d-flex flex-row justify-space-between">
            <h3 class="primary--text" style="margin-top: 2px;">
                Repeat
            </h3>
            <v-switch
                v-model="recurrent"
                color="success"
                class="pt-0 mt-0"
                @change="onChangeRecurrence"
            ></v-switch>
        </v-col>
        <v-expand-transition>
            <v-form ref="recurrenceForm">
                <v-col cols="12" v-if="recurrent">
                    <v-row>
                        <v-col cols="12" md="3" class="pt-6">
                            <label>Every</label>
                        </v-col>
                        <v-col cols="6" md="3" class="pt-0 pb-0">
                            <v-text-field
                                v-model.number="interval"
                                label="Number"
                                type="number"
                                :min="1"
                                :max="100"
                                :disabled="isIntervalDisabled"
                                :rules="intervalRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6" md="6" class="pt-0 pb-0">
                            <v-select
                                v-model="timeframe"
                                label="Timeframe"
                                :items="dynamicTimeframes"
                                :disabled="isTimeframeDisabled"
                                :rules="timeframeRules"
                            >
                                <template v-slot:selection="item">
                                    {{ item.item.value | capitalize | pluralize(interval) }}
                                </template>

                                <template v-slot:item="item">
                                    {{ item.item.value | capitalize | pluralize(interval) }}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="12" md="3" class="pt-5">
                            <label>Ends</label>
                        </v-col>

                        <v-col cols="12" md="9" class="pt-1">
                            <v-radio-group v-model="endOption" :disabled="endOptionsDisabled">
                                <v-row >
                                    <v-col cols="auto" class="py-0">
                                        <v-radio label="On" :value="END_OPTION_VALUES.DATE" class="input-gutter"></v-radio>
                                    </v-col>
                                    <v-col class="py-0">
                                        <time-picker-dialog
                                            v-model="endDate"
                                            :text-field-props="dateTextFieldProps"
                                            :time-picker-props="dateCalendarProps"
                                            :required="isEndOptionDate"
                                            :min-rule="isEndOptionDate"
                                            :max-rule="isEndOptionDate"
                                            is-date-picker
                                        ></time-picker-dialog>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="auto" class="py-0">
                                        <v-radio label="After" :value="END_OPTION_VALUES.OCCURRENCES" class="input-gutter"></v-radio>
                                    </v-col>

                                    <v-col class="py-0">
                                        <v-text-field
                                            v-model.number="occurrences"
                                            label="Number"
                                            type="number"
                                            min="1"
                                            max="100"
                                            :disabled="isOccurrencesDisabled"
                                            :rules="endOccurrencesRules"
                                            class="pt-0 mt-0"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="auto" class="py-0">
                                        <div class="grey--text input-gutter">occurrences</div>
                                    </v-col>
                                </v-row>

                            </v-radio-group>

                        </v-col>
                    </v-row>
                </v-col>
            </v-form>
        </v-expand-transition>
    </v-row>
</template>

<script>
import TimePickerDialog from "../../../modals/TimePickerDialog";
export default {
    name: "RecurrencePicker",
    components: { TimePickerDialog },
    props: {
        dates: {
            type: Array,
            default: () => ([])
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => {
        const TIMEFRAME_VALUES = {
            DAY: 'day',
            WEEK: 'week',
            MONTH: 'month',
        }
        const END_OPTION_VALUES = {
            DATE: 'date',
            OCCURRENCES: 'occurrences',
        }
        return {
            timeframes: [{
                text: 'day',
                value: TIMEFRAME_VALUES.DAY,
            }, {
                text: 'week',
                value: TIMEFRAME_VALUES.WEEK,
            }, {
                text: 'month',
                value: TIMEFRAME_VALUES.MONTH,
            }],
            TIMEFRAME_VALUES,
            interval: null,
            timeframe: null,
            endDate: null,
            occurrences: null,
            endOption: END_OPTION_VALUES.DATE,
            END_OPTION_VALUES,
            recurrent: false,
            EXCEED_TYPES: {
                DAILY: 'daily',
                WEEKLY: 'weekly',
                MONTHLY: 'monthly',
            }
        }
    },
    computed: {
        sortedDates() {
            const { dates } = this
            if (Array.isArray(dates)) {
                return dates.map(date => {
                    return this.genMoment(date)
                }).sort(( a, b ) => {
                    if (a.isBefore(b)) {
                        return -1
                    } else if (a.isAfter(b)) {
                        return 1
                    } else {
                        return 0
                    }
                })
            }
            return []
        },
        exceeds() {
            const { EXCEED_TYPES, sortedDates } = this
            if (sortedDates.length > 1) {
                const first = sortedDates[0]
                const last = sortedDates[sortedDates.length - 1]
                if (last.diff(first, 'months') > 0) {
                    return EXCEED_TYPES.MONTHLY
                } else if (last.diff(first, 'weeks') > 0) {
                    return EXCEED_TYPES.WEEKLY
                } else {
                    return EXCEED_TYPES.DAILY
                }
            }
            return null
        },
        isExceedsMonthly() {
            const { EXCEED_TYPES, exceeds } = this
            return exceeds === EXCEED_TYPES.MONTHLY
        },
        isExceedsWeekly() {
            const { EXCEED_TYPES, exceeds } = this
            return exceeds === EXCEED_TYPES.WEEKLY
        },
        isExceedsDaily() {
            const { EXCEED_TYPES, exceeds } = this
            return exceeds === EXCEED_TYPES.DAILY
        },
        isEndOptionDate() {
            return this.END_OPTION_VALUES.DATE === this.endOption
        },
        isEndOptionOccurrences() {
            return this.END_OPTION_VALUES.OCCURRENCES === this.endOption
        },
        isDailyTimeframe() {
            return this.timeframe === this.TIMEFRAME_VALUES.DAY
        },
        isWeeklyTimeframe() {
            return this.timeframe === this.TIMEFRAME_VALUES.WEEK
        },
        isMonthlyTimeframe() {
            return this.timeframe === this.TIMEFRAME_VALUES.MONTH
        },
        dynamicTimeframes() {
            const { EXCEED_TYPES, exceeds } = this
            if (exceeds === EXCEED_TYPES.MONTHLY) {
                return this.timeframes.map(timeframe => ({
                    ...timeframe,
                    disabled: true,
                }))
            } else if (exceeds === EXCEED_TYPES.WEEKLY) {
                return this.timeframes.map(timeframe => {
                    if (timeframe.value === 'month') {
                        return timeframe
                    }
                    return {
                        ...timeframe,
                        disabled: true,
                    }
                })
            } else if (exceeds === EXCEED_TYPES.DAILY) {
                return this.timeframes.map(timeframe => {
                    if (timeframe.value === 'day') {
                        return {
                            ...timeframe,
                            disabled: true,
                        }
                    }
                    return timeframe
                })
            } else {
                return this.timeframes
            }
        },
        isIntervalDisabled() {
            return this.isExceedsMonthly
        },
        isTimeframeDisabled() {
            return this.isExceedsMonthly
        },
        isEndDateDisabled() {
            return this.isExceedsMonthly || this.isEndOptionOccurrences
        },
        isOccurrencesDisabled() {
            return this.isExceedsMonthly || this.isEndOptionDate
        },
        endOptionsDisabled() {
            return this.isExceedsMonthly
        },
        dateTextFieldProps() {
            let value = null
            if (this.endDate) {
                const endMoment = this.genMoment(this.endDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'End Date',
                class: 'pt-0 mt-0',
                value,
                disabled: this.isEndDateDisabled,
            }
        },
        dateCalendarProps() {
            return {
                min: this.dateMinValue,
                max: this.dateMaxValue,
            }
        },
        dateMinValue() {
            if (this.sortedDates.length > 0) {
                return this.sortedDates[this.sortedDates.length - 1].format('YYYY-MM-DD')
            }
            return null
        },
        dateMaxValue() {
            let maxValue
            if (this.sortedDates.length > 0) {
                maxValue = moment(this.sortedDates[this.sortedDates.length - 1])
            } else {
                maxValue = this.genMoment(moment().format('YYYY-MM-DD'))
            }
            return maxValue.add(1, 'years').format('YYYY-MM-DD')
        },
        dateAllowedDates () {
            if (this.isWeeklyTimeframe || this.isMonthlyTimeframe) {
                if (this.sortedDates.length > 0) {
                    const endValue = moment(this.sortedDates[this.sortedDates.length - 1])
                    if (this.isWeeklyTimeframe) {
                        if (this.interval) {
                            return date => {
                                const momentDate = moment(date).add(1, 'days')
                                const daysDiff = Math.abs(
                                    momentDate.diff(endValue, 'days')
                                )
                                const weeksDiff = Math.abs(
                                    momentDate.diff(endValue, 'weeks')
                                )
                                return daysDiff % 7 === 0 && daysDiff % this.interval === 0 && weeksDiff % this.interval === 0
                            }
                        }
                        return null
                    } else if (this.isMonthlyTimeframe) {
                        if (this.interval) {
                            return date => {
                                const momentDate = moment(date)
                                const monthsDiff = Math.abs(
                                    momentDate.diff(endValue, 'months')
                                )
                                return monthsDiff % this.interval === 0 && endValue.date() === momentDate.date()
                            }
                        }
                        return null
                    } else {
                        return null
                    }
                }
                return null
            }
            return null
        },
        intervalRules() {
            return [
                v => !!v || 'Required',
                v => v > 0 || 'Must be greater than 0',
                v => v <= 100 || 'Can not be greater than 100'
            ]
        },
        timeframeRules() {
            return [
                v => !!v || 'Required',
            ]
        },
        endOccurrencesRules() {
            if (this.isEndOptionOccurrences) {
                if (this.isDailyTimeframe) {
                    return [
                        v => !!v || 'Required',
                        v => v > 0 || 'Must be greater than 0',
                        v => v <= 365 || 'Can not be greater than 365'
                    ]
                } else if (this.isWeeklyTimeframe) {
                    return [
                        v => !!v || 'Required',
                        v => v > 0 || 'Must be greater than 0',
                        v => v <= 52 || 'Can not be greater than 52'
                    ]
                } else if (this.isMonthlyTimeframe) {
                    return [
                        v => !!v || 'Required',
                        v => v > 0 || 'Must be greater than 0',
                        v => v <= 12 || 'Can not be greater than 12'
                    ]
                } else {
                    return [
                        v => !!v || 'Required',
                    ]
                }
            }
            return []
        }
    },
    methods: {
        onChangeRecurrence(value) {
            if (value) {
                this.$emit('onEnableRecurrence')
            }
        },
        setInitialData() {
            if (this.editMode && this.initialRequestForm && this.initialRequestForm.request_form_group && this.initialRequestForm.request_form_group.recurrence_settings) {
                const { recurrence_settings } = this.initialRequestForm.request_form_group
                this.interval = recurrence_settings.interval
                this.timeframe = recurrence_settings.period
                this.endOption = recurrence_settings.end_type
                if (this.endOption === this.END_OPTION_VALUES.DATE) {
                    const { start, timezone } = this.initialRequestForm
                    if (start) {
                        if (timezone) {
                            this.endDate = moment(start).tz(timezone).format('YYYY-MM-DD')
                        } else {
                            this.endDate = moment(start).format('YYYY-MM-DD')
                        }
                    } else {
                        this.endDate = null
                    }
                    this.occurrences = null
                } else {
                    if (this.initialRequestForm.hasOwnProperty('requestFormIndex')) {
                        if (this.initialRequestForm.requestFormIndex >= 0) {
                            this.occurrences = recurrence_settings.end_value - this.initialRequestForm.requestFormIndex
                        } else {
                            this.occurrences = recurrence_settings.end_value
                        }
                    } else {
                        this.occurrences = recurrence_settings.end_value
                    }
                    this.endDate = null
                }
                this.recurrent = true
            } else {
                const timeframe = this.timeframes.find(timeframe => !timeframe.disabled)
                this.interval = this.isExceedsMonthly ? null : 1
                this.timeframe = this.isExceedsMonthly ? null : timeframe ? timeframe.value : null
                this.endDate = this.isExceedsMonthly ? null : moment().add(12, 'weeks').format('YYYY-MM-DD')
                this.occurrences = this.isExceedsMonthly ? null : 12
                this.endOption = this.END_OPTION_VALUES.DATE
            }
        },
        genMoment(date) {
            return moment(date, 'YYYY-MM-DD')
        },
        getFormValues() {
            if (this.recurrent) {
                return {
                    interval: this.interval,
                    timeframe: this.timeframe,
                    endOption: this.endOption,
                    endValue: this.isEndOptionDate ? this.endDate : this.occurrences
                }
            }
            return null
        },
        isDirty() {
            // TODO: Change method when editing
            const values = this.getFormValues()
            if (values) {
                const { interval, timeframe, endValue } = this.getFormValues()
                return !!(interval || timeframe || endValue)
            }
            return false
        },
        validate() {
            if (this.recurrent) {
                return this.$refs.recurrenceForm.validate()
            }
            return true
        }
    },
    watch: {
        dates: {
            immediate: true,
            handler() {
                const { isExceedsMonthly, isExceedsWeekly, isExceedsDaily } = this
                if (isExceedsMonthly) {
                    this.setInitialData()
                } else if (isExceedsWeekly) {
                    if (this.isDailyTimeframe || this.isWeeklyTimeframe) {
                        this.timeframe = null
                    }
                } else if (isExceedsDaily) {
                    if (this.isDailyTimeframe) {
                        this.timeframe = null
                    }
                }
            }
        },
        dateMinValue: {
            immediate: true,
            handler(min) {
                if (min) {
                    const { endDate } = this
                    if (endDate) {
                        const minMoment = this.genMoment(min)
                        const endMoment = this.genMoment(endDate)
                        if (minMoment.isAfter(endMoment)) {
                            this.endDate = null
                        }
                    }
                }
            }
        },
        recurrent: {
            immediate: true,
            handler(recurrent) {
                if (!recurrent) {
                    this.setInitialData()
                }
            }
        }
    },
}
</script>

<style scoped>
    .input-gutter {
        margin-top: 8px;
    }
</style>
