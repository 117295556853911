<template>
    <div :data-component-id="component_id" class="truncating-chips-container">
        <v-chip
            v-for="(item, index) in formatted_items"
            :key="index"
            :ripple="false"
            class="truncating-chip"
        >
            {{ item }}
        </v-chip>
        <v-tooltip content-class="koroid-tooltip koroid-tooltip-no-fixed-width" right>
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="overflow-label-chip hidden-chip"
                    style="margin: 4px 0;"
                    @mouseenter="onMouseEnter"
                    @mouseleave="onMouseLeave"
                >
                    + 00
                </v-chip>
            </template>
            <span class="overflow-label-chip-tooltip">
                <span class="hidden-chips-tooltip-item" v-for="item in tooltipText">{{ item }}</span>
            </span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: "TruncatingChipsList",
    props: {
        items: {
            type: Array,
            default: null
        },
        viewOnly: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            component_id: Date.now() + Math.random(),
            tooltipText: ['None']
        }
    },
    computed: {
        formatted_items() {
            if (!Array.isArray(this.items) || this.items.length < 1) {
                return ['None']
            }

            return this.items
        }
    },
    methods: {
        onMouseEnter() {
            this.$emit('onMouseEnter')
        },
        onMouseLeave() {
            this.$emit('onMouseLeave')
        }
    },
    mounted() {
        const EXTRA_CHIP_WIDTH = 55;

        let chipsContainer = document.querySelector(`.truncating-chips-container[data-component-id="${this.component_id}"]`)
        let chips = chipsContainer.querySelectorAll('.truncating-chip')
        let extraChip = chipsContainer.querySelector('.overflow-label-chip')

        if (this.viewOnly) {
            chips.forEach(chip => {
                chip.classList.add('view-only-chip')
            })
            extraChip.classList.add('view-only-chip')
        }

        let containerWidth = chipsContainer.offsetWidth
        let totalWidth = 0
        let hiddenChips = 0
        let hiddenChipsText = []


        chips.forEach(function (chip) {
            totalWidth += chip.offsetWidth
            if (totalWidth > (containerWidth - EXTRA_CHIP_WIDTH)) {
                hiddenChips++
                chip.classList.add('hidden-chip')
                hiddenChipsText.push(chip.textContent)
            }
        })

        if (hiddenChips) {
            extraChip.textContent = `+ ${hiddenChips}`
            extraChip.classList.remove('hidden-chip')

            if (hiddenChipsText.length) {
                this.tooltipText = hiddenChipsText
            }
        }
    }
}
</script>

<style scoped>
    .truncating-chips-container {
        width: 100%;
    }

    .truncating-chip {
        margin: 4px 2px;
    }

    .hidden-chip {
        display: none;
    }

    .hidden-chips-tooltip-item {
        display: block;
    }

    :deep(.v-chip) {
        background: #F3F0F4 !important;
        color: #303034 !important;
    }

    .view-only-chip {
        background: rgb(243, 240, 244) !important;
    }

    .view-only-chip:before {
        background-color: rgb(243, 240, 244) !important;
    }
</style>
