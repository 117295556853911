import Vue from 'vue'

/**
 * Format the given date.
 */
Vue.filter('dateSimple', value => {
    return moment(value).format('MM/DD/YY')
});

Vue.filter('date12hrDay', value => {
    return moment(value).format('ddd, MM/DD/YYYY, h:mm A')
});

Vue.filter('date12hr', value => {
    return moment(value).format('MM/DD/YYYY, h:mm A')
});

Vue.filter('date12hrNoYear', value => {
    return moment(value).format('MM/DD, h:mm A')
})

Vue.filter('dateFromNow', value => {
    let now = moment()
    let date = moment(value)

    if (now.diff(date, 'h') > 24) {
        return date.format('MM/DD/YY')
    }

    return date.fromNow()
});

Vue.filter('timeAMPM', value => {
    // INPUT MUST BE HH:MM
    let date = moment(moment().format('YYYY-MM-DD') + ' ' + value)
    return date.format('h:mm A')
})

Vue.filter('fullDayFromShort', value => {
    let days = [
        { short: 'mon', full: 'Monday' },
        { short: 'tue', full: 'Tuesday' },
        { short: 'wed', full: 'Wednesday' },
        { short: 'thu', full: 'Thursday' },
        { short: 'fri', full: 'Friday' },
        { short: 'sat', full: 'Saturday' },
        { short: 'sun', full: 'Sunday' }
    ]

    let day = days.find(d => d.short === value.toLowerCase())

    if (!day) {
        return 'N/A'
    }

    return day.full
})

Vue.filter('timeStringFromMinutes', minutes => {
    if (typeof minutes === 'number') {
        let hours = Math.floor(minutes / 60).toString()
        let min = (minutes % 60).toString()
        return hours + ':' + padNumber(min)
    }
    return 'N/A'
})

Vue.filter('hours', hours => {
    if (typeof hours === 'number') {
        return `${hours}:00`
    }
    return 'N/A'
})

function padNumber(number) {
    return number.length === 1 ? '0' + number : number
}

// Old, some still used

Vue.filter('date', value => {
    return moment(value).format('MMMM Do, YYYY')
});

Vue.filter('dateSimple', value => {
    return moment(value).format('MM/DD/YY')
});

Vue.filter('date12hrDay', value => {
    return moment(value).format('ddd, MM/DD/YYYY, h:mm A')
});

Vue.filter('date12hr', value => {
    return moment(value).format('MM/DD/YYYY, h:mm A')
});

Vue.filter('dateFromNow', value => {
    let now = moment()
    let date = moment(value)

    if (now.diff(date, 'h') > 24) {
        return date.format('MM/DD/YY')
    }

    return date.fromNow()
});

Vue.filter('24hr', value => {
    return moment(value).format('MM/DD/YY H:mm');
});

Vue.filter('24Day', value => {
    return moment(value).format('ddd MM/DD/YY H:mm');
});


Vue.filter('toTime', value => {
    return moment(value).format('H:mm');
});

Vue.filter('day', value => {
    return moment(value).format('YYYY-MM-DD');
});

Vue.filter('stripToDay', value => {
    return value.split('-')[2]
});

Vue.filter('initials', value => {
    if(value.first_name && value.last_name)
        return value.first_name.charAt(0).toUpperCase() +  value.last_name.charAt(0).toUpperCase()
})


/**
 * To Snake Case
 */
Vue.filter('snakeCase', value => {
    return _.snakeCase(value);
});

Vue.filter('snakeToTitle', function (str) {
    if(!str)
        return;
    return str.split('_').map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
    }).join(' ');
});


/**
 * Format the given date to human readable datetime difference.
 */
Vue.filter('daysago', value => {
    return moment.utc(value).fromNow()
});

/**
 * Format the given timestamp as time.
 */
Vue.filter('dayTime', value => {
    return moment.unix(value).format('MMMM Do, h:mm a');
});

/**
 * Format the given date as time.
 */
Vue.filter('time', value => {
    return moment.utc(value).local().format('LT')
});

/**
 * Phone formater
 */
Vue.filter('phone', function (phone) {
    return phone.replace(/[^0-9]/g, '')
                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', value => {
    return moment.utc(value).local().format('MMMM Do, YYYY h:mm A');
});


/**
 * Format the given date into a relative time.
 */
Vue.filter('relative', value => {
    return moment.utc(value).local().locale('en-short').fromNow();
});


/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
Vue.filter('capitalize', value => {
    if (! value && value !== 0) {
        return '';
    }

    return value.toString().charAt(0).toUpperCase()
        + value.slice(1);
});

/**
 * Pluralizes a word based on value
 */
Vue.filter('pluralize', function (word, value) {
    return value > 1 ? word + 's' : word;
});

/**
 * Truncate text
 */
Vue.filter('truncate', function (text, length, clamp) {
  text = text || '';
  clamp = clamp || '...';
  length = length || 30;

  if (text.length <= length) return text;

  var tcText = text.slice(0, length - clamp.length);
  var last = tcText.length - 1;


  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length;

  tcText =  tcText.slice(0, last);

  return tcText + clamp;
});


/**
 * Format the given money value.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L70
 */
Vue.filter('currency', value => {
    value = parseFloat(value);

    if (! isFinite(value) || (! value && value !== 0)){
        return '';
    }

    var stringified = Math.abs(value).toFixed(2);

    var _int = stringified.slice(0, -1 - 2);

    var i = _int.length % 3;

    var head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
        : '';

    var _float = stringified.slice(-1 - 2);

    var sign = value < 0 ? '-' : '';

    return sign + '$' + head +
        _int.slice(i).replace(/(\d{3})(?=\d)/g, '$1,') +
        _float;
});
