<template>
    <edit-preset-modal
        v-model="model"
        :preset="preset"
        :filters-body-param="filtersBodyParam"
        @onCloseModal="onCloseModal"
        @onUpdatePreset="onUpdatePreset"
    >
        <template v-slot:filters>
            <payroll-period-filters
                v-model="selectedFilters"
                :report-type="reportType"
                :location="location"
                :preset="preset"/>
        </template>
    </edit-preset-modal>
</template>

<script>
import EditPresetModal from "../EditPresetModal";
import PayrollPeriodFilters from "../../../Filters/PayrollPeriodFilters";

export default {
    name: "EditPayrollPeriodPresetModal",
    components: {EditPresetModal, PayrollPeriodFilters},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => ({})
        },
        reportType: {
            type: String,
            default: 'payroll_period'
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedFilters: {}
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        filtersBodyParam() {
            return {
                staff_type: this.selectedFilters.employmentType,
                staff_groups: this.selectedFilters.staffGroups,
                job_titles: this.selectedFilters.jobTitles,
                staff_members: this.selectedFilters.staffMembers,
                locations: this.preset && this.preset.filters && Array.isArray(this.preset.filters.locations) ? this.preset.filters.locations.map(loc => loc.id) : []
            }
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('onCloseModal')
        },
        onUpdatePreset(preset) {
            this.$emit('onUpdatePreset', preset)
        }
    }
}
</script>

<style scoped>

</style>
