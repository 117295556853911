<template>
    <div class="step-container">
        <div class="step-circle" :style="stepCircleStyleObj">

        </div>
        <div class="text--h4" :style="stepNameStyleObj">
            {{ name }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Step",
    props: {
        name: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            default: false,
        },
        complete: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        stepCircleStyleObj() {
            if (this.complete) {
                return {
                    'background-color': '#A5E5A1'
                }
            } else {
                return {
                    border: '2px solid #C1AD3D'
                }
            }
        },
        stepNameStyleObj() {
            return {
                color: '#FFFFFF',
                ...!this.complete && !this.active && {opacity: '60%'}
            }
        }
    }
}
</script>

<style scoped>
.step-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 17px;
}

.step-circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}
</style>
