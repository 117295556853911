<template>
    <div class="d-flex flex-column align-center px-8" style="width: 100%;">
        <div class="d-flex flex-column align-center px-15" style="width: 100%;">
            <p class="text--h1 text-center">
                Enter a name for your organization
            </p>
            <p class="text--paragraph text-center" style="margin-bottom: 32px;">
                This should be the name of your organization as a whole, not a single location.
            </p>
            <v-form
                ref="organizationForm"
                :lazy-validation="false"
                style="width: 80%;"
            >
                <div class="d-flex flex-row justify-center" style="width: 100%;">
                    <v-text-field
                        name="organization-name"
                        label="Organization Name"
                        v-model.trim="organization"
                        :error-messages="api.hasError('name')"
                        :rules="required"
                        class="required"
                        autocomplete="off"
                        outlined
                    />
                </div>
            </v-form>
        </div>
        <div class="d-flex flex-row justify-end" style="width: 100%; position: relative; top: 40px;">
            <v-btn
                color="primary"
                outlined
                :loading="api.busy"
                @click="onClickNext"
            >
                Next
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: "EnterOrganization",
    props: {
        value: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            api: new formHelper(),
        }
    },
    computed: {
        organization: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        required() {
            return [
                v => !!v && !!v.trim() || 'Required'
            ]
        },
    },
    methods: {
        onClickNext() {
            if (this.$refs.organizationForm.validate()) {
                this.api.post('/onboarding/name-organization', {name: this.organization})
                    .then(({ data }) => {
                        this.$emit('onClickNext', data)
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
