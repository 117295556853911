<template>
    <div class="toggle-container">
        <v-switch
            v-model="selectedValue"
            color="primary"
            inset
        ></v-switch>
        <div style="margin-top: 8px;">View declined offers</div>
    </div>
</template>

<script>
export default {
    name: "ToggleRejectedOffers",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selectedValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        }
    }
}
</script>

<style scoped>
    .toggle-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
</style>
