<template>
    <v-card class="chips-list-card">
        <v-card-title class="chips-list-card-title">
            <v-row >
                <v-col cols="8">
                    <h3 class="ds-h3">
                        <v-icon color="primary" class="title-icon">{{ icon }}</v-icon>
                        {{ label }}
                    </h3>
                </v-col>
                <v-spacer/>
                <v-col class="text-right">
                    <v-btn
                        class="plus-button"
                        small
                        fab
                        :disabled="addButtonDisabled"
                        @click="onAddButtonClick"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="chips-list-card-list-container scrollable-chips-list-content">
            <v-row>
                <v-col v-if="items.length">
                    <v-chip
                        class="ds-chip-outlined chips-list-item"
                        outlined
                        v-for="item in items"
                        :key="item._id"
                        @click="onItemClick(item)"
                    >
                        {{ item.name }}
                    </v-chip>
                </v-col>

                <v-col v-else class="pt-4 text-center primary--text no-items-text">
                    <div>No {{ label }} in this Location</div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "ChipsListCard",
    props: {
        items: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        addButtonDisabled: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {

        }
    },
    methods: {
        onItemClick(item) {
            this.$emit('item:click', item)
        },
        onAddButtonClick() {
            this.$emit('addButton:click')
        }
    }
}
</script>

<style scoped>
    .chips-list-card {
        border-radius: 16px !important;
        height: 60vh;
    }

    .title-icon {
        margin-bottom: 5px;
        margin-right: 16px;
    }

    .plus-button {
        background-color: #fff !important;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30) !important;
    }

    .chips-list-item {
        margin: 0 8px 8px 0;
    }

    .scrollable-chips-list-content {
        height: calc(60vh - 72px);
        overflow-y: auto;
    }

    .no-items-text {
        font-size: 16px;
    }
</style>
