<template>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            class="view-schedule-btn"
            :disabled="loading"
            :href="departmentHref">
            View Schedule
        </v-btn>
    </v-card-actions>
</template>

<script>
export default {
    name: "DepartmentCardActions",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        departmentHref: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
    .view-schedule-btn {
        text-transform: unset !important;
    }
</style>
