<template>
    <div class="d-flex flex-column ml-2">
        <div class="text--h5" style="margin-bottom: 2px !important;">
            {{ title }}
        </div>
        <div class="text--body-sm">
            {{ subtitle }}
        </div>
    </div>
</template>

<script>
export default {
    name: "PayrollPeriodRadioLabel",
    props: {
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        }
    }
}
</script>

<style scoped>

</style>
