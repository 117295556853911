<template>
    <custom-icon icon="mdi-timer-sand" color="rgb(197, 31, 22)"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";
export default {
    name: "RedPendingIcon",
    components: { CustomIcon }
}
</script>

<style scoped>

</style>
