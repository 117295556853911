<template>
    <v-container fluid class="fill-height">
        <v-row class="fill-height">
            <v-col :md="isInPast ? 5 : 3" cols="12">
                <slot name="calendar"></slot>
            </v-col>
            <v-col cols="auto">
                <v-divider vertical></v-divider>
            </v-col>
            <slot name="user-lists"></slot>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "SchedulingContentLayout",
    props: {
        isInPast: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<style scoped>

</style>
