<template>
    <div>
        <v-skeleton-loader
            type="list-item-avatar"
            elevation="1"
            class="mb-1"
        ></v-skeleton-loader>

        <v-skeleton-loader
            type="list-item-avatar"
            elevation="1"
            class="mb-1"
        ></v-skeleton-loader>
    </div>
</template>

<script>
export default {
    name: "StaffingDetailsLoadingSkeleton"
}
</script>

<style scoped>

</style>
