<template>
    <v-container fluid>
        <v-row v-if="loading">
            <v-col cols="12">
                <v-skeleton-loader
                    type="list-item-avatar"
                    elevation="1"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <template v-else>
            <v-row>
                <v-col cols="12" md="8">
                    <h4 class="ds-h4">{{ location.name }}</h4>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                    <v-btn
                        color="secondary"
                        @click="onAddStaffGroup"
                        :disabled="!canEdit"
                    >
                        Add Staff Group
                    </v-btn>
                </v-col>
            </v-row>
            <template v-if="location.staff_groups.length > 0">
                <v-row>
                    <v-col cols="12">
                        <v-expansion-panels
                            v-model="selectedPanel"
                            accordion
                            elevation="1"
                            class="ds-expansion-panels"
                        >
                            <staff-group
                                v-for="(sg, index) in location.staff_groups"
                                :key="index"
                                :user-id="userId"
                                :staff-group="sg"
                                :available-staff-groups="location.available_staff_groups"
                                @onChangeStaffGroup="onChangeStaffGroup($event, index)"
                                @onChangeJobTitle="onChangeJobTitle"
                                @onAddJobTitle="onAddJobTitle(location, $event)"
                                @onSaveJobTitle="onSaveJobTitle"
                                @onDeleteJobTitle="onDeleteJobTitle"
                            />
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </template>
            <v-row v-else>
                <v-col cols="12">
                    <p class="text--paragraph text-center">
                        Add a Staff Group and Job Title to make this Staff Member available for shifts in this Location.
                    </p>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import StaffGroup from "./StaffGroup";

export default {
    name: "Location",
    components: {StaffGroup},
    props: {
        userId: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedPanel: null,
    }),
    computed: {
        canEdit() {
            return this.$userHasPermission(this.$user, this.$config.permissions.USERS.EDIT_OTHERS_JOBS)
        }
    },
    methods: {
        onAddStaffGroup() {
            this.$emit('onAddStaffGroup', {
                locationId: this.location._id,
                locationName: this.location.name,
                staffGroupIndex: this.location.staff_groups.length,
                jobTitleIndex: 0,
            })
            this.$nextTick(() => {
                this.selectedPanel = this.location.staff_groups.length - 1
            })
        },
        onChangeStaffGroup(staffGroup, staffGroupIndex) {
            this.$emit('onChangeStaffGroup', staffGroup, staffGroupIndex)
        },
        onAddJobTitle(location, {staffGroup, jobTitleIndex}) {
            this.$emit('onAddJobTitle', {
                location: {
                    _id: location._id,
                    name: location.name,
                },
                staffGroup,
                jobTitleIndex
            })
        },
        onChangeJobTitle(jobTitle, jobTitleId) {
            this.$emit('onChangeJobTitle', jobTitle, jobTitleId)
        },
        onDeleteJobTitle(jobId, prevJobId, jobs) {
            this.$emit('onDeleteJobTitle', jobId, prevJobId, jobs)
        },
        onSaveJobTitle(jobId, prevJobId, jobs) {
            this.$emit('onSaveJobTitle', jobId, prevJobId, jobs)
        }
    }
}
</script>

<style scoped>

</style>
