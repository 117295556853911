<template>
    <div>
        <div class="d-flex flex-column" style="overflow-x: auto;" >
            <div class="department-scheduler-container">
                <scheduler-loader v-if="schedulerLoading"></scheduler-loader>
                <div v-else-if="events.length > 0">
                    <calendar-days
                        :start-of-week="startOfWeek"
                    >
                        <template v-slot:sort-by-menu>
                            <sort-by-menu
                                v-model="sortOption"
                                :items="sortByItems"
                            ></sort-by-menu>
                        </template>
                    </calendar-days>
                    <template v-for="(schedule, index) in sortedSchedules">
                        <calendar-counts
                            v-if="schedule.type === 'counts'"
                            :key="index"
                            icon="mdi-account-group"
                            :text="schedule.name"
                            :counts="schedule.counts"
                            :start-of-week="startOfWeek"
                        ></calendar-counts>
                        <department-schedule
                            v-else
                            :key="index"
                            :schedule="schedule"
                            :start-of-week="startOfWeek"
                        ></department-schedule>
                    </template>
                    <chart-view
                        class="hide-during-print-view"
                        :key="chartKey"
                        v-if="!schedulerLoading && events.length > 0"
                        style="padding-top: 20px; position: sticky; bottom: 0;"
                        :data-source="chartDataSource"
                        :counts="chartCounts"
                    ></chart-view>
                </div>
                <div v-else>
                    <calendar-days
                        :start-of-week="startOfWeek"
                    >
                        <template v-slot:sort-by-menu>
                            <sort-by-menu
                                v-model="sortOption"
                                :items="sortByItems"
                            ></sort-by-menu>
                        </template>
                    </calendar-days>
                    <div class="department-no-events-container text--secondary" v-if="isSchedulerDisabled">
                        You must be authorized to manage a <span style="font-weight: bold; padding-left: 4px; padding-right: 4px;">Department</span> to begin scheduling.
                    </div>
                    <div class="department-no-events-container text--secondary" v-else>
                        No shifts in this date range. <span style="font-weight: bold; padding-left: 4px; padding-right: 4px;">Add Shifts</span> to begin scheduling.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nest } from 'd3-collection'
import { ascending } from 'd3'
import moment from 'moment-timezone'
import CalendarDays from '../Common/CalendarDays'
import CalendarCounts from '../Common/CalendarCounts'
import SortByMenu from '../SortByMenu'
import DepartmentSchedule from './DepartmentSchedule'
import SchedulerLoader from '../../Misc/SchedulerLoader'
import ChartView from '../Common/ChartView/ChartView'

export default {
    name: "DepartmentScheduler",
    components: { CalendarCounts, SortByMenu, SchedulerLoader, DepartmentSchedule, CalendarDays, ChartView },
    data () {
        return {
            eventsByDepartment: [],
            sortByItems: [
                {
                    _id: 'staffGroupAsc',
                    text: 'Staff Group',
                    sortText: 'A',
                    asc: true,
                },
                {
                    _id: 'staffGroupDesc',
                    text: 'Staff Group',
                    sortText: 'Z',
                    asc: false,
                },
                {
                    _id: 'totalDesc',
                    text: 'Total Shifts',
                    sortText: 'High',
                    asc: false,
                },
                {
                    _id: 'totalAsc',
                    text: 'Total Shifts',
                    sortText: 'Low',
                    asc: true,
                },
                {
                    _id: 'openDesc',
                    text: 'Open Shifts',
                    sortText: 'High',
                    asc: false,
                },
                {
                    _id: 'openAsc',
                    text: 'Open Shifts',
                    sortText: 'Low',
                    asc: true,
                },
            ],
            sortOption: 'staffGroupAsc',
            chartKey: 1111,
            api: new formHelper(),
        }
    },
    computed: {
        events: {
            get () {
                return this.$store.getters.schedulerGetDepartmentEvents
            },
            set (events) {
                this.$store.dispatch('schedulerSetDepartmentEvents', { events })
            }
        },
        eventsLoading: {
            get () {
                return this.$store.getters.schedulerGetDepartmentEventsLoading
            },
            set (eventsLoading) {
                this.$store.dispatch('schedulerSetDepartmentEventsLoading', { eventsLoading })
            }
        },
        isSchedulerDisabled() {
            return this.$store.getters.schedulerGetIsDisabled
        },
        departmentsLoading() {
            return this.$store.getters.schedulerGetDepartmentsLoading
        },
        schedulerLoading() {
            return this.departmentsLoading || this.eventsLoading
        },
        scheduleViewType () {
            return this.$store.getters.schedulerGetScheduleViewType
        },
        _schedules () {
            return this.applyDetails(this.eventsByDepartment)
        },
        schedules () {
            return this.applyCountsByDay(this._schedules)
        },
        schedulesMeta () {
            let insertAt = -1
            return this.schedules.reduce((accumulator, schedule) => {
                if (schedule.type === 'counts') {
                    ++insertAt
                    return [
                        ...accumulator,
                        {
                            name: schedule.name,
                            open: _.sumBy(schedule.counts, 'open'),
                            total: _.sumBy(schedule.counts, 'total'),
                            schedule,
                            schedules: [],
                        }
                    ]
                } else {
                    const copy = Array.from(accumulator)
                    copy[insertAt] = {
                        ...copy[insertAt],
                        schedules: [...copy[insertAt].schedules, schedule],
                    }
                    return copy
                }
            }, [])
        },
        sortedSchedulesMeta () {
            switch (this.sortOption) {
                case 'staffGroupAsc':
                    return _.sortBy(this.schedulesMeta, 'name')
                case 'staffGroupDesc':
                    return _.reverse(_.sortBy(this.schedulesMeta, 'name'))
                case 'totalDesc':
                    return _.reverse(_.sortBy(this.schedulesMeta, 'total'))
                case 'totalAsc':
                    return _.sortBy(this.schedulesMeta, 'total')
                case 'openDesc':
                    return _.reverse(_.sortBy(this.schedulesMeta, 'open'))
                case 'openAsc':
                    return _.sortBy(this.schedulesMeta, 'open')
                default:
                    return this.schedulesMeta
            }
        },
        sortedSchedules () {
            return this.sortedSchedulesMeta.reduce((accumulator, meta) => ([
                ...accumulator,
                meta.schedule,
                ...meta.schedules,
            ]), [])
        },
        departmentId () {
            return this.$store.getters.schedulerGetDepartmentId
        },
        filters () {
            return this.$store.getters.schedulerGetDepartmentFilters
        },
        filtersApplied () {
            return this.$store.getters.schedulerGetDepartmentFiltersApplied
        },
        dates () {
            return this.$store.getters.schedulerGetDates
        },
        startOfWeek () {
            if (Array.isArray(this.dates) && this.dates.length > 1) {
                return moment(this.dates[0]).tz(this.globalTimezone, true)
            }
            return null
        },
        endOfWeek () {
            if (Array.isArray(this.dates) && this.dates.length > 1) {
                return moment(this.dates[1]).tz(this.globalTimezone, true)
            }
            return null
        },
        globalLocation () {
            return this.$root.globalLocation
        },
        globalTimezone () {
            return this.$root.globalTimezone
        },
        chartEvents () {
            return _.flattenDeep(this._schedules.map(schedule => schedule.events))
        },
        chartDataSource () {
            return _.range(84).map(index => {
                const intervalStart = index * 120
                const intervalEnd = (index + 1) * 120

                const intervalEvents = this.chartEvents.filter(event => {
                    const eventStart = event.eventDetails.startMinutes
                    const eventEnd = event.eventDetails.endMinutes
                    return (intervalStart < eventEnd) && (intervalEnd > eventStart)
                })

                const filled = _.sumBy(intervalEvents, 'filled_shifts_count')
                const total = _.sumBy(intervalEvents, 'total_shifts_count')

                return {
                    x: `${index + 1}`,
                    y: total > 0 ? Math.ceil(filled / total * 100) : 0,
                }
            })
        },
        chartCounts () {
            const counts = _.range(7).map(() => ({
                open: 0,
                total: 0,
            }))

            const countSchedules = this.schedules.filter(schedule => schedule.type === 'counts')

            countSchedules.forEach(schedule => schedule.counts.forEach((count, index) => {
                counts[index] = {
                    open: counts[index].open + count.open,
                    total: counts[index].total + count.total,
                }
            }))

            return counts
        },
    },
    methods: {
        groupEventsByDepartment (events) {
            return nest()
                .key(shift => shift.department_name).sortKeys(ascending)
                .key(shift => shift.staff_group_name).sortKeys(ascending)
                .key(shift => shift.job_title_name).sortKeys(ascending)
                .rollup(this.arrangeEvents)
                .entries(events)
        },
        overlapExists (firstEvent, secondEvent) {
            const firstStart = moment(firstEvent.start).tz(this.globalTimezone)
            const firstEnd = moment(firstEvent.end).tz(this.globalTimezone)
            const secondStart = moment(secondEvent.start).tz(this.globalTimezone)
            const secondEnd = moment(secondEvent.end).tz(this.globalTimezone)
            return (firstStart.isSameOrBefore(secondEnd) && (firstEnd.isSameOrAfter(secondStart)))
        },
        arrangeEvents (events) {
            let unarrangedEvents = Array.from(events)
            const numberOfEvents = events.length
            let numberOfArrangedEvents = 0
            let numberOfRows = 0
            let result = {}
            while(numberOfArrangedEvents < numberOfEvents) {
                if(numberOfArrangedEvents > 0) {
                    let eventArranged = false
                    const currentEvent = unarrangedEvents[0]
                    for(let row in result) {
                        const assignedEvents = result[row]
                        if(assignedEvents.every(event => !this.overlapExists(event, currentEvent))) {
                            result = {
                                ...result,
                                [row]: [...result[row], currentEvent]
                            }
                            unarrangedEvents.splice(0, 1)
                            ++numberOfArrangedEvents
                            eventArranged = true
                            break
                        }
                    }
                    if(!eventArranged) {
                        ++numberOfRows
                        result = {
                            ...result,
                            [numberOfRows]: [currentEvent]
                        }
                        unarrangedEvents.splice(0, 1)
                        ++numberOfArrangedEvents
                    }
                } else {
                    result = {
                        ...result,
                        [numberOfRows]: [events[0]]
                    }
                    unarrangedEvents.splice(0, 1)
                    ++numberOfArrangedEvents
                }
            }
            return result
        },
        buildEventsTree (eventsTree = [], result = [], keys = [], level = 0) {
            if(level === 3) {
                Object.values(eventsTree).forEach((events, index) => result.push({
                    keys,
                    events,
                    type: index > 0 ? 'open-no-details' : 'open',
                }))
                return
            }
            eventsTree.forEach(node =>
                this.buildEventsTree(node.values ? node.values : node.value,
                    result,
                    [...keys, node.key],
                    level + 1))
        },
        fetchEvents () {
            const {
                departmentId,
                startOfWeek,
                endOfWeek,
                filters,
                filtersApplied,
                globalLocation,
                globalTimezone
            } = this
            if (departmentId && startOfWeek && endOfWeek && globalLocation && globalTimezone) {
                this.eventsLoading = true
                this.api.post('/schedules/get-department-events', {
                    start: startOfWeek.format('YYYY-MM-DD'),
                    end: endOfWeek.format('YYYY-MM-DD'),
                    timezone: globalTimezone,
                    department_id: departmentId,
                    ...filtersApplied && {
                        filters: {
                            staff_group_ids: Array.isArray(filters.selectedStaffGroupIds) ? filters.selectedStaffGroupIds : [],
                            job_title_ids: Array.isArray(filters.selectedJobTitleIds) ? filters.selectedJobTitleIds : [],
                            shift_time_ids: Array.isArray(filters.selectedShiftTimeIds) ? filters.selectedShiftTimeIds : [],
                            requirement_ids: Array.isArray(filters.selectedCredentialIds) ? filters.selectedCredentialIds : [],
                        }
                    }
                }).then(({ data }) => {
                    if (Array.isArray(data)) {
                        this.events = Array.from(data)
                        this.eventsByDepartment = []
                        this.buildEventsTree(
                            this.groupEventsByDepartment(this.events.map(e => ({
                                ...e,
                                _id: e.shift_request_id,
                            }))),
                            this.eventsByDepartment,
                        )
                    }
                }).catch(console.log).finally(() => { this.eventsLoading = false })
            }
        },
        applyDetails (groupedEvents, loadingShiftRequestId) {
            const schedules = groupedEvents.map(groupedEvent => ({
                ...groupedEvent,
                events: groupedEvent.events
                    .map(event => ({
                        ...event,
                        keys: groupedEvent.keys,
                    }))
                    .map(event => this.addEventDetails(event, loadingShiftRequestId))
                    .filter(this.isEventInInterval)
            }))
            return this.addLevelDetails(schedules.filter(schedule => schedule.events.length > 0))
        },
        applyCountsByDay (schedules) {
            const finalSchedules = Array.from(schedules)

            const insertDayCountsAtIndex = (insertAt, dayIndex, total, open) => {
                if (insertAt < finalSchedules.length) {
                    const counts = finalSchedules[insertAt]?.counts?.[dayIndex]
                    if (counts) {
                        finalSchedules[insertAt].counts[dayIndex] = {
                            total: counts.total + total,
                            open: counts.open + open,
                        }
                    }
                }
            }

            let insertAt = 0
            let offset = 0
            const exists = {}
            schedules.forEach((schedule, index) => {
                if (schedule.events.length > 0) {
                    if (!exists.hasOwnProperty(schedule.events[0].staff_group_id)) {
                        insertAt = index + offset
                        finalSchedules.splice(insertAt, 0, {
                            type: 'counts',
                            name: schedule.keys[1],
                            counts: _.range(7).map(() => ({
                                total: 0,
                                open: 0,
                            }))
                        })
                        ++offset
                        exists[schedule.events[0].staff_group_id] = true
                    }
                    const events = schedule.events.filter(event => event.eventDetails.type !== 'ends')
                    events.forEach(event => insertDayCountsAtIndex(
                        insertAt,
                        this.getEventDayIndex(event),
                        event.total_shifts_count,
                        event.total_shifts_count - event.filled_shifts_count,
                    ))
                }
            })
            return finalSchedules
        },
        getEventDayIndex (event) {
            const start = event.eventDetails.startMinutes
            if (start < 1440) {
                return 0
            } else if (start < 2880) {
                return 1
            } else if (start < 4320) {
                return 2
            } else if (start < 5760) {
                return 3
            } else if (start < 7200) {
                return 4
            } else if (start < 8640) {
                return 5
            } else {
                return 6
            }
        },
        addEventDetails (event, loadingShiftRequestId) {
            // TODO: Implement event loading
            const eventLoading = false
            const { startMinutes, endMinutes } = this.calculateEventMinutes(event)
            const startInInterval = startMinutes >= 0 && startMinutes <= 10079
            const endInInterval = endMinutes >= 0 && endMinutes <= 10079
            if(startMinutes < 0 && endInInterval) {
                return {
                    ...event,
                    eventDetails: {
                        startMinutes: 0,
                        endMinutes,
                        loading: eventLoading,
                        type: 'ends',
                    }
                }
            } else if(endMinutes > 10079 && startInInterval) {
                return {
                    ...event,
                    eventDetails: {
                        startMinutes,
                        endMinutes: 10079,
                        loading: eventLoading,
                        type: 'starts',
                    }
                }
            } else {
                return {
                    ...event,
                    eventDetails: {
                        startMinutes,
                        endMinutes,
                        loading: eventLoading,
                        type: 'normal',
                    }
                }
            }
        },
        calculateEventMinutes (event) {
            const { startOfWeek } = this

            const eventStart = moment(event.start).tz(this.globalTimezone)
            const eventEnd = moment(event.end).tz(this.globalTimezone)

            let startMinutes = eventStart.diff(startOfWeek, 'minutes')
            let endMinutes = eventEnd.diff(startOfWeek, 'minutes')

            const nextWeek = startOfWeek.clone().add(1, 'week')

            if (startOfWeek.isDST() && !nextWeek.isDST()) {
                const dstBias = this.getDSTBias(this.globalTimezone)
                startMinutes -= dstBias
                endMinutes -= dstBias
            }

            return {
                startMinutes,
                endMinutes
            }
        },
        getDSTBias(tz) {
            const janOffset = moment.tz({month: 0, day: 1}, tz).utcOffset()
            const junOffset = moment.tz({month: 5, day: 1}, tz).utcOffset()
            return Math.abs(junOffset - janOffset)
        },
        isEventInInterval (event) {
            const endInterval = 10079
            const { startMinutes, endMinutes } = event.eventDetails
            const eventBefore = startMinutes < 0 && endMinutes < 0
            const eventAfter = startMinutes > endInterval && endMinutes > endInterval
            return !eventBefore && !eventAfter
        },
        addLevelDetails (schedules) {
            let groupingKeys = []
            let isEvenGrouping = false
            let level = 1
            return schedules.map(schedule => {
                const { keys } = schedule
                if(groupingKeys.length === 0) {
                    groupingKeys = Array.from(keys)
                    return {
                        ...schedule,
                        level,
                        isEvenGrouping,
                    }
                }
                if(keys[1] !== groupingKeys[1]) {
                    isEvenGrouping = !isEvenGrouping
                    level = 2
                } else if(keys[2] !== groupingKeys[2]) {
                    isEvenGrouping = !isEvenGrouping
                    level = 3
                } else {
                    level = 4
                }
                groupingKeys = Array.from(keys)
                return {
                    ...schedule,
                    level,
                    isEvenGrouping
                }
            })
        },
        getDateRange() {
            return {
                startOfWeek: this.startOfWeek,
                endOfWeek: this.endOfWeek,
            };
        },
    },
    watch: {
        departmentId () {
            this.fetchEvents()
        },
        dates () {
            this.fetchEvents()
        },
        globalTimezone () {
            this.fetchEvents()
        },
        globalLocation () {
            this.eventsLoading = true
        },
        scheduleViewType: {
            immediate: true,
            handler (scheduleViewType) {
                if (scheduleViewType === 'department') {
                    this.chartKey = this.chartKey + 1
                }
            }
        }
    },
    created () {
        window.EventBus.$on('departmentScheduler/fetchEvents', () => {
            this.fetchEvents()
        })
    }
}
</script>

<style scoped>
    .department-scheduler-container {
        display: block;
        width: 100%;
        max-height: calc(100vh - 297px);
        min-width: 1200px;
        padding-right: 21px;
        padding-left: 1px;
        overflow-y: auto;
    }

    .department-no-events-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        font-size: 22px;
    }
</style>
