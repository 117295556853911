<template>
    <div class="user-credentials-container">
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="ds-modal-input-container">
                    <p class="text--h3">
                        Credentials
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="ds-modal-input-container">
                    <v-autocomplete
                        :key="credentialsKey"
                        v-model="selectedCredentialIds"
                        label='Credentials'
                        placeholder='Select credentials for this user'
                        :items="credentials"
                        :loading="credentialsLoading"
                        :readonly="!canEdit"
                        :disabled="isSavingCredentials"
                        :search-input.sync="searchInput"
                        item-text='name'
                        item-value='_id'
                        prepend-inner-icon='mdi-license'
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        outlined
                        hide-details
                        :clearable="canEdit"
                        @change="searchInput = ''"
                        no-data-text="No Credentials available to this User"
                    >
                        <template v-slot:append>
                            <v-slide-y-reverse-transition>
                                <v-btn
                                    v-if="isDirty"
                                    icon
                                    small
                                    color="primary"
                                    class="credentials-save-btn"
                                    :loading="isSavingCredentials"
                                    @click="onSave"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </v-slide-y-reverse-transition>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "UserCredentials",
    props: {
        credentials: {
            type: Array,
            default: () => ([]),
        },
        credentialsLoading: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
        credentialsKey: 9999,
        prevSelectedCredentialIds: [],
        selectedCredentialIds: [],
        isSavingCredentials: false,
        searchInput: '',
        api: new formHelper(),
    }),
    computed: {
        isDirty() {
            return !_.isEqual(this.prevSelectedCredentialIds, this.selectedCredentialIds)
        },
        canEdit() {
            return this.$userHasPermission(this.$user, this.$config.permissions.USERS.EDIT_OTHERS_JOBS)
        }
    },
    methods: {
        onSave() {
            this.credentialsKey = this.credentialsKey + 1

            this.toggleIsSaving(true)

            this.api.post(`/user/save-requirements/${this.user._id}`, {requirements: this.getSelectedCredentials()})
                .then(({data, message}) => {
                    message('Credentials updated.')
                    this.$emit('onSaveCredentials', data.requirements)
                })
                .catch(() => {
                    this.$snackNotify('warning', 'Unable to save changes to credentials. Please try again.')
                })
                .finally(() => {
                    this.toggleIsSaving(false)
                })
        },
        toggleIsSaving(isSaving) {
            this.$emit('updating', isSaving)
            this.isSavingCredentials = isSaving
        },
        setCredentials() {
            const {user} = this
            if (user && Array.isArray(user.requirements)) {
                const credentials = user.requirements.map(r => r._id)
                this.selectedCredentialIds = Array.from(credentials)
                this.prevSelectedCredentialIds = Array.from(credentials)
            }
        },
        getSelectedCredentials() {
            const credentialsById = _.keyBy(this.credentials, '_id')
            return this.selectedCredentialIds
                .filter(id => credentialsById.hasOwnProperty(id))
                .map(id => credentialsById[id])
        }
    },
    watch: {
        user() {
            this.setCredentials()
        }
    },
    mounted() {
        this.setCredentials()
    }
}
</script>

<style scoped>
.user-credentials-container {
    border: 2px solid #777680;
    border-radius: 12px;
    padding: 16px !important;
    margin-top: 30px;
}

.credentials-save-btn {
    width: 36px !important;
    height: 36px !important;
    margin-top: -6px;
}
</style>
