<template>
    <bar-chart
        :chart-data="chartData"
        :chart-options="chartOptions"
        :class="{ 'dashboard-bar-chart-container': isHovered }"
    />
</template>

<script>
import BarChart from "../../../charts/bar/BarChart";
export default {
    name: "DashboardBarChart",
    components: { BarChart },
    props: {
        dataSource: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        isHovered: false,
    }),
    computed: {
        chartData() {
            let firstDataSet = [], backgroundColors = [], labels = []
            this.dataSource.forEach(({ x, y, percentage }) => {
                labels.push(x)
                firstDataSet.push(y)
                backgroundColors.push(this.getColor(percentage))
            })
            return {
                labels,
                datasets: [
                    {
                        data: firstDataSet,
                        backgroundColor: backgroundColors,
                    }
                ]
            }
        },
        chartOptions() {
            return {
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 95,
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            autoSkip: false,
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: this.maxValue,
                            stepSize: this.stepSizeValue,
                            callback: value => {
                                if(value === 0) {
                                    return ''
                                }
                                return value
                            },
                            padding: 10
                        },
                        gridLines: {
                            borderDash: [15, 15],
                            color: ['#000000', '#000000', '#000000', '#000000'],
                            drawBorder: false,
                            drawTicks: false,
                            z: 1,
                        },
                    }]
                },
                tooltips: {
                    enabled: !this.loading,
                    xAlign: 'center',
                    yAlign: 'bottom',
                    borderWidth: 4,
                    borderColor: '#F6F6F6',
                    backgroundColor: '#FFFFFF',
                    displayColors: false,
                    bodyFont: {
                        family: 'Roboto',
                        weight: 400,
                        size: 14
                    },
                    bodyFontColor: '#000000',
                    titleFont: {
                        family: 'Roboto',
                        weight: 400,
                        size: 14
                    },
                    titleFontColor: '#000000',
                    footerFont: {
                        family: 'Roboto',
                        weight: 400,
                        size: 14
                    },
                    footerFontColor: '#000000',
                    callbacks: {
                        title: title => {
                            return this.getChartValue(this.dataSource, title, 'title')
                        },
                        label: label => {
                            return this.getChartValue(this.dataSource, [label], 'label')
                        },
                        footer: footer => {
                            return this.getChartValue(this.dataSource, footer, 'footer')
                        }
                    },
                    custom: (tooltipModel) => {
                        // Set higher z-index when hovered on bar
                        this.isHovered = tooltipModel.body !== undefined
                    }
                }
            }
        },
        maxValue() {
            if(this.loading) {
                return 100
            }
            const { dataSource } = this
            if(Array.isArray(dataSource) && dataSource.length > 0) {
                const maxValue = (_.sortBy(dataSource, 'y')[dataSource.length - 1] || {}).y
                if(maxValue) {
                    const remainder = maxValue % 4
                    if(remainder === 0) {
                        return maxValue
                    }
                    return maxValue + 4 - remainder
                }
                return 100
            }
            return 100
        },
        stepSizeValue() {
            if(this.loading) {
                return 25
            }
            if(this.maxValue) {
                return this.maxValue / 4
            }
            return 25
        }
    },
    methods: {
        getColor(value) {
            if (value < 10) {
                return '#D13534'
            } else if (value < 20) {
                return '#D8523F'
            } else if (value < 30) {
                return '#DF6F49'
            } else if (value < 40) {
                return '#E68D53'
            } else if (value < 50) {
                return '#EDAA5D'
            } else if (value < 60) {
                return '#EBC366'
            } else if (value < 70) {
                return '#D4C468'
            } else if (value < 80) {
                return '#BAC569'
            } else if (value < 90) {
                return '#9FC76B'
            } else {
                return '#85C86D'
            }
        },
        getChartValue(dataSource, value, key) {
            if(Array.isArray(dataSource) && Array.isArray(value) && value.length > 0) {
                const itemIndex = (value[0] || {}).index || 0
                if(itemIndex < dataSource.length) {
                    const barItem = dataSource[itemIndex]
                    if(barItem && barItem[key]) {
                        return barItem[key]
                    }
                    return ''
                }
                return ''
            }
            return ''
        }
    }
}
</script>

<style scoped>
    .dashboard-bar-chart-container {
        z-index: 1;
    }
</style>
