<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="1200px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text ref="requestFormContent" class="request-form-content">
                <v-row>
                    <v-col cols="12" md="6" class="pl-0">
                        <recurrence-settings
                            v-if="shiftType === 'repeating'"
                            :edit-mode="editMode"
                            :initial-request-form="initialRequestForm"
                            ref="recurrenceSettingsFormRef">
                        </recurrence-settings>
                        <shift-date-form
                            v-else
                            ref="shiftDateFormRef"
                            :edit-mode="editMode"
                            :shift-type="shiftType"
                            :initial-request-form="initialRequestForm"
                            :request-form-ids-by-day="requestFormIdsByDay"
                        ></shift-date-form>
                        <shift-time-form
                            ref="shiftTimeFormRef"
                            v-model="duration"
                            :location="location"
                            :edit-mode="editMode"
                            :initial-request-form="initialRequestForm"
                        />
                    </v-col>
                    <v-col cols="12" md="6" :style="rhsContainerStyle">
                        <shift-details-form
                            ref="shiftDetailsFormRef"
                            :location="location"
                            :edit-mode="editMode"
                            :initial-request-form="initialRequestForm"
                            :auth-staffing-details="authStaffingDetails"
                            :duration="duration"
                            @onAddJobTitle="onAddJobTitle"
                        ></shift-details-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="isCreating"
                    @click="onClose"
                >
                    Close
                </v-btn>
                <v-btn
                    ref="nextBtn"
                    color="primary"
                    outlined
                    :loading="isCreating"
                    @click="onNext"
                >{{ editMode ? 'Save' : 'Next' }}</v-btn>
            </v-card-actions>
        </v-card>
        <unsaved-changes-dialog ref="unsavedChangesDialogRef"/>
    </v-dialog>
</template>

<script>
import ShiftDateForm from "../Forms/ShiftDateForm/ShiftDateForm";
import RecurrenceSettings from "../Forms/RecurrenceSettings/RecurrenceSettings";
import ShiftTimeForm from "../Forms/ShiftTimeForm/ShiftTimeForm";
import ShiftDetailsForm from "../Forms/ShiftDetailsForm/ShiftDetailsForm";
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";
import message from '../../../lib/toast'

export default {
    name: "RequestFormModal",
    components: { ShiftDateForm, RecurrenceSettings, ShiftTimeForm, ShiftDetailsForm, UnsavedChangesDialog },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            default: () => null
        },
        timezone: {
            type: String,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        shiftType: {
            type: String,
            default: 'single',
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
        authStaffingDetails: {
            type: Array,
            default: function() {
                return []
            }
        },
        requestFormIdsByDay: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        isCreating: false,
        duration: 0,
        innerWidth: window.innerWidth,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        title () {
            const { editMode, shiftType } = this;
            if (editMode) {
                return 'Edit Shift'
            }
            return shiftType === 'single' ? 'Create Shifts' : 'Create Repeating Shifts'
        },
        shiftDateFormRef() {
            return this.$refs.shiftDateFormRef
        },
        shiftTimeFormRef() {
            return this.$refs.shiftTimeFormRef
        },
        recurrenceSettingsFormRef () {
            return this.$refs.recurrenceSettingsFormRef
        },
        shiftDetailsFormRef() {
            return this.$refs.shiftDetailsFormRef
        },
        unsavedChangesDialogRef() {
            return this.$refs.unsavedChangesDialogRef
        },
        bodyParams () {
            const shiftDateFormBodyParams = this.shiftDateFormRef?.bodyParams || {}
            const shiftTimeFormBodyParams = this.shiftTimeFormRef?.bodyParams || {}
            const recurrenceSettingsFormBodyParams = this.recurrenceSettingsFormRef?.bodyParams || {}
            const shiftDetailsFormBodyParams = this.shiftDetailsFormRef?.bodyParams || {}
            const {
                location,
                timezone,
                shiftType,
                editMode,
            } = this
            return {
                timezone,
                location_id: location && location._id,
                ...(shiftType === 'single' || shiftType === 'multiple') && shiftDateFormBodyParams,
                ...shiftType === 'repeating' && recurrenceSettingsFormBodyParams,
                ...shiftTimeFormBodyParams,
                ...shiftDetailsFormBodyParams,
                ...editMode && ((shiftType === 'repeating' || shiftType === 'multiple') && { edit_recurring: true })
            }
        },
        rhsContainerStyle() {
            return {
                'margin-top': '32px',
                'padding-top': '0',
                ...this.innerWidth >= 960 && {
                    'border-left': '1px solid #000000'
                }
            }
        }
    },
    methods: {
        onAddJobTitle(scrollTo) {
            this.scrollTo(scrollTo)
        },
        scrollTo(ref) {
            this.$vuetify.goTo(ref, {
                container: this.$refs.requestFormContent,
                easing: 'easeInOutQuart',
                duration: 300,
            })
        },
        onClose () {
            const { shiftDateFormRef, recurrenceSettingsFormRef, shiftTimeFormRef, shiftDetailsFormRef, unsavedChangesDialogRef, shiftType } = this
            if (shiftType === 'single') {
                if (shiftDateFormRef && shiftDetailsFormRef && shiftTimeFormRef) {
                    if (shiftDateFormRef.isDirty || shiftTimeFormRef.isDirty || shiftDetailsFormRef.isDirty) {
                        return unsavedChangesDialogRef.open()
                            .then(() => {
                                this.modalValue = false
                                this.$emit('onClose')
                            })
                            .catch(() => {})
                    }
                }
            } else if (shiftType === 'repeating') {
                if (recurrenceSettingsFormRef && shiftDetailsFormRef && shiftTimeFormRef) {
                    if (recurrenceSettingsFormRef.isDirty || shiftTimeFormRef.isDirty || shiftDetailsFormRef.isDirty) {
                        return unsavedChangesDialogRef.open()
                            .then(() => {
                                this.modalValue = false
                                this.$emit('onClose')
                            })
                            .catch(() => {})
                    }
                }
            }
            this.modalValue = false
            this.$emit('onClose')
        },
        onNext () {
            const validateShiftDateForm = this.shiftDateFormRef?.validate
            const validateRecurrenceSettingsForm = this.recurrenceSettingsFormRef?.validate
            const validateShiftTimeForm = this.shiftTimeFormRef?.validate
            const validateShiftDetailsForm = this.shiftDetailsFormRef?.validate

            let isValid = false

            if (validateShiftDetailsForm) {
                let isLHSFormValid = false
                let isShiftTimeFormValid = false
                if (validateShiftTimeForm) {
                    isShiftTimeFormValid = validateShiftTimeForm()
                }
                const isShiftDetailsFormValid = validateShiftDetailsForm()
                const isShiftCountValid = !this.shiftDetailsFormRef.noJobTitlesWithShifts
                if (this.shiftType === 'repeating') {
                    if (validateRecurrenceSettingsForm) {
                        isLHSFormValid = validateRecurrenceSettingsForm()
                    }
                } else {
                    if (validateShiftDateForm) {
                        isLHSFormValid = validateShiftDateForm()
                    }
                }
                isValid = isLHSFormValid && isShiftTimeFormValid && isShiftDetailsFormValid && isShiftCountValid
            }

            if (isValid) {
                if (this.editMode) {
                    if (this.initialRequestForm && this.initialRequestForm._id) {
                        this.processUnavailableUsers(this.initialRequestForm._id, this.bodyParams)
                    }
                } else {
                    this.createRequestForms(this.bodyParams)
                }
            }
        },
        createRequestForms(bodyParams) {
            this.isCreating = true
            this.api.post('/request-forms/create-many', bodyParams)
                .then(data => {
                    if (data && data.data && Array.isArray(data.data.request_form_ids_by_day) && data.data.request_details) {
                        this.$emit('onNext', data.data)
                    } else {
                        this.$emit('onNext', null)
                    }
                    message('success', 'Shift(s) added to schedule.')
                }).catch(console.log)
                .finally(() => {
                    this.isCreating = false
                })
        },
        editRequestForms (requestFormId, bodyParams) {
            this.isCreating = true
            this.api.post(`/request-forms/edit-many/${requestFormId}`, bodyParams)
                .then(data => {
                    if (data && data.data && Array.isArray(data.data.request_form_ids_by_day) && data.data.request_details) {
                        this.$emit('onNext', data.data)
                    } else {
                        this.$emit('onNext', null)
                    }
                    message('success', 'Changes to shift saved.')
                }).catch(console.log).finally(() => {
                    this.isCreating = false
            })
        },
        processUnavailableUsers (requestFormId, bodyParams) {
            this.isCreating = true
            this.api.post('/request-forms/unavailable-users', {
                request_form_id: requestFormId,
                edit_recurring: bodyParams.hasOwnProperty('edit_recurring') ? bodyParams.edit_recurring : false,
                params: bodyParams
            }).then(data => {
                if (data && data.data && typeof data.data === 'object' && Object.keys(data.data).length > 0) {
                    this.$emit('openUnavailableUsersModal', data.data, bodyParams, requestFormId)
                    this.isCreating = false
                    this.modalValue = false
                    this.$emit('onClose')
                } else {
                    this.isCreating = false
                    this.editRequestForms(requestFormId, bodyParams)
                }
            }).catch(console.log)
        },
        onResize() {
            this.innerWidth = window.innerWidth
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>
    .request-form-content {
        padding: 0 12px;
    }
</style>
