<template>
    <div :class="doughnutContainerClass">
        <doughnut-chart
            :class="doughnutContainerClass"
            :chart-data="chartData"
            :chart-options="chartOptions"
        />
        <div :class="doughnutTextContainerClass">
            <template v-if="loading">
                <v-progress-circular
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </template>
            <template v-else>
                <div class="text--h3">{{ this.percentage }}%</div>
                <div class="text--caption">{{ this.description }}</div>
            </template>
        </div>
    </div>
</template>

<script>
import DoughnutChart from "../../../charts/doughnut/DoughnutChart";

export default {
    name: "DashboardDoughnutChart",
    components: { DoughnutChart },
    props: {
        percentage: {
            type: Number,
            default: 50,
        },
        description: {
            type: String,
            default: 'Filled'
        },
        half: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        tooltipsOptions: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        data: [
                            this.loading ? 0 : this.percentage,
                            this.loading ? 100 : 100 - this.percentage
                        ],
                        backgroundColor: [
                            this.color,
                            '#F6F6F6'
                        ],
                    }
                ]
            }
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                rotation: this.half ? Math.PI : 1/2 * Math.PI,
                ...this.half && { circumference: Math.PI },
                cutoutPercentage: 75,
                tooltips: {
                    enabled: this.half && !this.loading,
                    xAlign: 'center',
                    yAlign: 'bottom',
                    borderWidth: 4,
                    borderColor: '#F6F6F6',
                    backgroundColor: '#FFFFFF',
                    displayColors: false,
                    padding: 18,
                    bodyFont: {
                        family: 'Roboto',
                        weight: 400,
                        size: 14
                    },
                    bodyFontColor: '#000000',
                    ...this.tooltipsOptions
                }
            }
        },
        color() {
            if (this.percentage < 10) {
                return '#D13534'
            } else if (this.percentage < 20) {
                return '#D8523F'
            } else if (this.percentage < 30) {
                return '#DF6F49'
            } else if (this.percentage < 40) {
                return '#E68D53'
            } else if (this.percentage < 50) {
                return '#EDAA5D'
            } else if (this.percentage < 60) {
                return '#EBC366'
            } else if (this.percentage < 70) {
                return '#D4C468'
            } else if (this.percentage < 80) {
                return '#BAC569'
            } else if (this.percentage < 90) {
                return '#9FC76B'
            } else {
                return '#85C86D'
            }
        },
        doughnutContainerClass() {
            return {
                [this.half ? 'half-doughnut-container' : 'doughnut-container']: true,
            }
        },
        doughnutTextContainerClass() {
            return {
                [this.half ? 'half-doughnut-text-container' : 'doughnut-text-container']: true,
            }
        }
    }
}
</script>

<style scoped>
    .half-doughnut-container {
        position: relative;
        height: 240px;
        width: 240px;
    }

    .half-doughnut-text-container {
        position:absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .doughnut-container {
        position: relative;
        height: 180px;
        width: 180px;
    }

    .doughnut-text-container {
        position:absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
</style>
