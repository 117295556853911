<template>
    <v-list-item
        :disabled="disabled || presentational"
        :class="{ 'list-item-opacity': presentational, 'list-item-selected': user.selected }"
        style="padding-left: 0; padding-right: 8px;"
        @click="onClick">
        <v-list-item-icon style="align-self: center; flex-direction: column; margin-right: 0; padding-right: 4px; padding-left: 4px; min-width: 34px; pointer-events: auto;">
            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="available || rhs && directAssign ? 'success' : 'warning'"
                        small
                    >
                        mdi-circle
                    </v-icon>
                </template>
                <span>{{ iconTooltipText }}</span>
            </v-tooltip>
        </v-list-item-icon>
        <v-list-item-avatar>
            <custom-avatar
                :user="user"
                :size="40"
                user-chip>
            </custom-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="pl-2">
            <div v-if="!presentational && score && !user.available">
                <v-icon color="primary" small>mdi-auto-fix</v-icon>
                <span class="text--caption primary--text" style="font-weight: bold;">
                    Likely available
                </span>
            </div>
            <v-list-item-title class="text--paragraph text--darken-5">
                {{ title }}
            </v-list-item-title>
            <v-list-item-subtitle class="blue-grey--text text--darken-2">
                <v-tooltip v-if="overtime" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-small color="error">
                                mdi-alert
                            </v-icon>
                            <span class="text--caption error--text">
                                {{ subtitle }}
                            </span>
                        </div>
                    </template>
                    <span>Staff exceeds max work hours</span>
                </v-tooltip>
                <v-tooltip v-else-if="toBeOvertime" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-small color="error">
                                mdi-alert
                            </v-icon>
                            <span class="text--caption error--text">
                                {{ subtitle }}
                            </span>
                        </div>
                    </template>
                    <span>Staff will exceed max work hours</span>
                </v-tooltip>
                <span v-else class="text--caption">
                    {{ subtitle }}
                </span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-menu v-if="!presentational && hasDirectAssign" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="gray"
                    style="margin-top: 4px;"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon dark>mdi-dots-horizontal-circle-outline</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-dialog v-if="hasDirectAssign" v-model="showDirectAssignModal" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon>
                                <v-icon v-if="!directlyAssigned">mdi-arrow-right-bold-circle-outline</v-icon>
                                <v-icon v-else>mdi-arrow-left-bold-circle-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-if="!directlyAssigned">Directly Assign</v-list-item-title>
                            <v-list-item-title v-else>Cancel</v-list-item-title>
                        </v-list-item>
                    </template>

                    <direct-assign-modal v-model="showDirectAssignModal" :user="user" @userAssigned="handleDirectUserAssign"></direct-assign-modal>
                </v-dialog>
            </v-list>
        </v-menu>

        <v-list-item-action v-if="available || allowToBeOffered" style="padding-top: 16px; padding-bottom: 12px; margin-top: 0; margin-bottom: 0;">
            <div class="d-flex flex-row justify-start align-center" style="column-gap: 5px;">
                <v-tooltip
                    v-if="!presentational && invalidShiftOffer"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-clipboard-clock-outline
                        </v-icon>
                    </template>
                    <span>{{ invalidShiftOfferTooltipText }}</span>
                </v-tooltip>
                <v-checkbox
                    v-if="!presentational"
                    :input-value="user.selected"
                    color="#4253C4"
                    :disabled="disabled || directAssign"
                ></v-checkbox>
            </div>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import CustomAvatar from '../../../avatar/CustomAvatar'
import userDetails from '../../../../lib/mixins/userDetails'
import DirectAssignModal from "../../Modals/DirectAssignModal"

export default {
    name: "ActionableUserListItem",
    components: { CustomAvatar, DirectAssignModal },
    mixins: [userDetails],
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        score: {
            type: Number,
            default: () => null,
        },
        rhs: {
            type: Boolean,
            default: false,
        },
        canDirectAssign: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showTimeline: false,
        showDirectAssignModal: false,
    }),
    computed: {
        disabled () {
            if (this.user) {
                return this.user.disabled
            }
            return false
        },
        presentational () {
            if (this.user) {
                return this.user.presentational
            }
            return false
        },
        predictionsEnabled() {
            return this.$root.instancePredictionsEnabled && this.$root.organizationPredictionsEnabled && this.$store.getters.getPredictionsEnabled
        },
        iconTooltipText() {
            if (this.rhs) {
                if (this.directAssign) {
                    return 'Shift will be Directly Assigned'
                }
                if (this.available) {
                    return 'Shift will be Assigned'
                } else {
                    return 'Shift will be Offered'
                }
            } else {
                if (this.available) {
                    if (this.staffTypeWorkSchedule) {
                        return 'Available for Shift via Assigned Schedule'
                    }
                    return 'Available for Shift via Submitted Availability'
                } else if (this.allowToBeDirectAssigned && this.allowToBeOffered && this.hasDirectAssign) {
                    return 'Eligible for Shift Offer or Direct Assign'
                } else if (!this.allowToBeOffered && this.hasDirectAssign) {
                    return 'Eligible for Direct Assign'
                } else {
                    return 'Eligible for Shift Offer'
                }
            }
        },
        invalidShiftOfferTooltipText() {
            const { last_invalid_shift_offer_reason } = this.user
            const fullName = this.title
            if (last_invalid_shift_offer_reason === 'shift_in_past') {
                return `${fullName} attempted to accept the shift offer after the start of the shift.`
            } else if (last_invalid_shift_offer_reason === 'staff_type_not_allowed') {
                return `${fullName}\'s employment type does not allow shift offers.`
            } else if (last_invalid_shift_offer_reason === 'shift_already_filled') {
                return `${fullName} attempted to accept a shift offer for a shift that was already full.`
            } else if (last_invalid_shift_offer_reason === 'shift_overlap') {
                return `${fullName} was not approved due to overlapping with another scheduled shift.`
            } else if (last_invalid_shift_offer_reason === 'user_assigned_to_same_shift_request') {
                return `${fullName} was previously assigned to this shift.`
            } else if (last_invalid_shift_offer_reason === 'shift_conditions_no_longer_matching') {
                return `${fullName}\'s shift offer was invalidated because the conditions of the shift no longer matched their qualifications.`
            } else if (last_invalid_shift_offer_reason === 'unauthorized') {
                return `${fullName} did not have the necessary permissions to accept this shift offer.`
            } else if (last_invalid_shift_offer_reason === 'inactive_user') {
                return `${fullName}\'s account was inactive when attempting to accept this shift offer.`
            } else if (last_invalid_shift_offer_reason === 'invalid_shift_offer') {
                return `The shift offer was not valid at the time of acceptance. It may have been cancelled or updated.`
            } else if (last_invalid_shift_offer_reason === 'already_responded') {
                return `${fullName} has already responded to this shift offer.`
            } else if (last_invalid_shift_offer_reason === 'shift_starts_soon') {
                return `${fullName} tried to accept this shift offer too close to the start time.`
            } else {
                return 'Conflict with shift offer prevented accepting shift.'
            }
        },
        allowToBeDirectAssigned() {
            if (this.user) {
                return Boolean(this.user.staff_type?.working_hours_direct_assign_enabled)
            }
            return false
        },
        allowToBeOffered () {
            if (this.user) {
                return Boolean(this.user.staff_type?.out_of_work_hours_shift_offer_enabled)
            }
            return false
        },
        staffTypeWorkSchedule () {
            if (this.user) {
                return Boolean(this.user.staff_type?.work_schedule_config === 'staff_type_defined')
            }
            return false
        },
        directlyAssigned () {
            if (this.user) {
                return this.user.direct_assign
            }
            return false
        },
        hasDirectAssign() {
            // return this.canDirectAssign && this.allowToBeDirectAssigned && !this.user.available
            return false
        }
    },
    methods: {
        onClick () {
            if (!this.disabled && !this.presentational &&
            // Checks to make sure the shift is not being directly assigned
            !this.directAssign &&
            // disable selection when the user can not be offered or assigned shifts
            (this.allowToBeOffered || this.available)) {
                this.$emit('click', this.user, false);
            }
        },

        handleDirectUserAssign(user, direct_assign) {
            this.$emit('userAssignedDirectlyShift', user, direct_assign);
        },
    }
}
</script>

<style scoped>
.list-item-opacity {
    opacity: 0.6;
}

.list-item-selected {
    background-color: rgb(229, 229, 229);
}
</style>
