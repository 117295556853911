<template>
    <dashboard-doughnut-chart
        :percentage="percentage"
        description="Response Rate"
        :half="half"
        :tooltips-options="tooltipsOptions"
        :loading="loading"
    />
</template>

<script>
import DashboardDoughnutChart from "./DashboardDoughnutChart";

export default {
    name: "ShiftOffersDoughnutChart",
    components: { DashboardDoughnutChart },
    props: {
        accepted: {
            type: Number,
            default: 0,
        },
        rejected: {
            type: Number,
            default: 0,
        },
        pending: {
            type: Number,
            default: 0,
        },
        percentage: {
            type: Number,
            default: 50,
        },
        half: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        tooltipsOptions() {
            return {
                callbacks: {
                    label: () => {
                        return [
                            `Accepted    ${this.accepted}`,
                            `Rejected     ${this.rejected}`,
                            `Pending      ${this.pending}`
                        ]
                    }
                },
            }
        }
    }
}
</script>

<style scoped>

</style>
