<template>
    <div class="department-modal">
        <v-dialog
            v-model="show_modal"
            scrollable
            max-width="550px"
            persistent
            content-class="ds-dialog"
        >

            <v-card class="ds-modal-card" elevation="2" dusk="departmentModal">
                <modal-toolbar :title="editing_existing_department ? 'Edit Department' : 'Add Department'" @close="onClose"/>

                <v-card-text class="ds-modal-content">
                    <v-form
                        ref="departmentForm"
                        v-model="form_is_valid"
                        :lazy-validation="false"
                        class="pt-2"
                    >
                        <v-row>
                            <div v-show="show_loading_overlay" style="height:100%">
                                <loading-spinner :value="true"></loading-spinner>
                            </div>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="department-name"
                                    label="Department Name"
                                    v-model.trim="department.name"
                                    :error-messages="api.hasError('name')"
                                    :rules="rules.required"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="description"
                                    label="Brief Description (Optional)"
                                    v-model.trim="department.description"
                                    :error-messages="api.hasError('description')"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>
                        </v-row>

                        <v-row class="mt-0">
                            <v-col class="text-right">
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="save"
                                    :loading="api.busy"
                                    :disabled="isSaveDisabled"
                                >
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>

        </v-dialog>

        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </div>
</template>

<script>
import LoadingSpinner from "../../../components/loaders/LoadingSpinner";
import ModalToolbar from "../../../components/modals/components/ModalToolbar";
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";
import validationRules from "../../../lib/mixins/validationRules";

export default {
    name: "DepartmentModal",
    components: {LoadingSpinner, ModalToolbar, UnsavedChangesDialog},
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const user_can_edit = this.$userHasPermission(this.$user, this.$config.permissions.DEPARTMENTS.EDIT)

        return {
            resolve: null,
            reject: null,
            show_modal: this.value,
            api: new formHelper(),
            editing_existing_department: false,
            show_loading_overlay: false,
            form_is_valid: false,
            department: {},
            prev_department: {},
            editable: user_can_edit,
        }
    },
    methods: {
        open({department_id = null, location_id = null}) {
            if (department_id) {
                this.editing_existing_department = true
                this.loadExistingDepartment(department_id)
            } else {
                this.department.location_id = location_id
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
                this.show_modal = true
            })
        },

        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },

        closeModal() {
            this.show_modal = false
            this.resetModal()
        },

        resetModal() {
            this.editing_existing_department = false
            this.form_is_valid = false
            this.department = {}
            this.prev_department = {}
            this.$refs.departmentForm.resetValidation()
        },

        departmentCreated(department) {
            this.$snackNotify('success', 'New Department created.')
            this.resolve({action: 'created', department: department})
            this.closeModal()
        },

        departmentUpdated(department) {
            this.$snackNotify('success', 'Changes to Department saved.')
            this.resolve({action: 'updated'})
            this.closeModal()
        },

        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {})
        },

        loadExistingDepartment(department_id) {
            this.show_loading_overlay = true
            this.api.get('/department/' + department_id, {params: {basic_details: true}})
                .then(({data}) => {
                    this.department = data.department
                    this.prev_department = {...data.department}
                })
                .finally(() => {
                    this.show_loading_overlay = false
                })
        },

        save() {
            if (!this.$refs.departmentForm.validate()) {
                return false
            }

            this.api.post('/department/save', this.department)
                .then(({data}) => {
                    if (this.editing_existing_department) {
                        this.departmentUpdated(data)
                    } else {
                        this.departmentCreated(data)
                    }
                })
        },

        getCompareObject(department_object) {
            const { name, description } = department_object
            return { name, description }
        }
    },

    computed: {
        isDirty() {
            return !_.isEqual(this.getCompareObject(this.prev_department), this.getCompareObject(this.department))
        },
        isSaveDisabled() {
            return this.api.busy || !this.editable || !this.isDirty
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.show_modal = newVal
            }
        },

        show_modal: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.$emit('input', false)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
