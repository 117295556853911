<template>
    <v-autocomplete
        v-model="viewModel"
        :items="items"
        :item-value="itemValue"
        :item-text="itemText"
        :disabled="disabled"
        class="list-view-select-container"
        :menu-props="{ offsetY: true }"
        prepend-inner-icon="mdi-filter"
        append-icon="mdi-chevron-down"
        solo
        flat
        outlined
        @change="onChange"
    >

    </v-autocomplete>
</template>

<script>
export default {
    name: "ListViewSelect",
    props: {
        value: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        itemValue: {
            type: String,
            default: null,
        },
        itemText: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        viewModel: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        }
    },
    methods: {
        onChange () {
            this.$emit('onChange')
        }
    }
}
</script>

<style scoped>
    .list-view-select-container {
        flex: 0 0 170px;
    }

  /*  .list-view-select-item {
        font-size: 20px;
        color: #485FF8;
    }*/

    :deep(i.v-icon.mdi-chevron-down) {
        color: black;
    }

    :deep(i.v-icon.mdi-filter) {
        color: rgb(13, 10, 50);
    }

    :deep(div.v-input__slot) {
        margin-bottom: 0;
    }

    :deep(div.v-text-field__details) {
        display: none !important;
    }
</style>
