<template>
    <v-row style="margin-top: 16px; margin-bottom: 16px;">
        <v-col cols="12" md="9">
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        :value="shiftTimePreset"
                        label="Shift Time Preset"
                        autocomplete="off"
                        outlined
                        readonly
                        hide-details
                    >
                        <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-clock-outline</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :md="true">
                    <v-text-field
                        :value="start"
                        label="Start"
                        autocomplete="off"
                        outlined
                        readonly
                        hide-details
                    ></v-text-field>
                </v-col>

                <v-col cols="12" :sm="editMode ? true : 6" :md="true">
                    <v-text-field
                        :value="duration"
                        label="Duration"
                        autocomplete="off"
                        outlined
                        readonly
                        hide-details
                    ></v-text-field>
                </v-col>

                <v-col cols="12" :sm="editMode ? true : 6" :md="true">
                    <v-text-field
                        :value="end"
                        label="End"
                        autocomplete="off"
                        outlined
                        readonly
                        hide-details
                    ></v-text-field>
                </v-col>

                <v-col
                    v-if="editMode"
                    cols="auto"
                    class="d-flex flex-column justify-center align-center"
                    style="padding-left: 0;"
                >
                    <v-btn
                        style="color: #0D0A32;"
                        fab
                        small
                        elevation="1"
                        @click="onEditStaffingAutomation"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="3">
            <v-row>
                <v-col class="py-0 d-flex flex-column justify-center align-center bordered-col"
                       style="margin-top: 12px;">
                    <p class="text--caption">
                        Last Run:
                    </p>
                    <p class="text--h5" style="margin-bottom: 4px;">
                        {{ lastRun }}
                    </p>
                </v-col>
                <v-col class="py-0 d-flex flex-column justify-center align-center"
                       style="margin-top: 12px;">
                    <p class="text--caption">
                        Next Run:
                    </p>
                    <p class="text--h5" style="margin-bottom: 4px;">
                        {{ nextRun }}
                    </p>
                </v-col>
            </v-row>
        </v-col>
        <add-automated-shift-modal
            v-if="showAddAutomatedShiftModal"
            v-model="showAddAutomatedShiftModal"
            :staffing-automation="staffingAutomation"
            edit-mode
            @onUpdate="onUpdateStaffingAutomation"
        />
    </v-row>
</template>

<script>
import AddAutomatedShiftModal from "../AddAutomatedShift/AddAutomatedShiftModal";

export default {
    name: "AutomatedShiftDetailsOverview",
    components: {AddAutomatedShiftModal},
    props: {
        editMode: {
            type: Boolean,
            default: true,
        },
        staffingAutomation: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        showAddAutomatedShiftModal: false,
    }),
    computed: {
        shiftTimePreset() {
            return this.staffingAutomation?.shift_time?.label || 'N/A'
        },
        start() {
            const start = this.staffingAutomation?.start_time
            if (start) {
                return moment(moment().format('YYYY-MM-DD') + ' ' + start).format('h:mm A')
            }
            return 'N/A'
        },
        duration() {
            const duration = this.staffingAutomation?.duration
            if (duration) {
                return this.$options.filters.timeStringFromMinutes(duration) + ' hrs'
            }
            return 'N/A'
        },
        end() {
            const end = this.staffingAutomation?.end_time
            if (end) {
                return moment(moment().format('YYYY-MM-DD') + ' ' + end).format('h:mm A')
            }
            return 'N/A'
        },
        lastRun() {
            const lastRun = this.staffingAutomation?.last_run
            if (lastRun) {
                if (this.globalTimezone) {
                    return moment.tz(lastRun, this.globalTimezone).format('MM/DD/YYYY')
                }
                return moment(lastRun).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        nextRun() {
            const runDate = this.staffingAutomation?.run_date
            if (runDate) {
                if (this.globalTimezone) {
                    return moment.tz(runDate, this.globalTimezone).format('MM/DD/YYYY')
                }
                return moment(runDate).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        onEditStaffingAutomation() {
            this.showAddAutomatedShiftModal = true
        },
        onUpdateStaffingAutomation(staffingAutomation) {
            this.$emit('onUpdateStaffingAutomation', staffingAutomation)
        },
    }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
    .bordered-col {
        border-left: 2px solid lightgray;
    }
}
</style>
