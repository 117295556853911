const state = {
    scheduleViewType: 'department',
    dates: [],
    departmentView: {
        departmentId: null,
        departments: [],
        departmentsLoading: false,
        filters: {
            selectedStaffGroupIds: [],
            selectedJobTitleIds: [],
            selectedShiftTimeIds: [],
            selectedCredentialIds: [],
        },
        filtersApplied: false,
        events: [],
        eventsLoading: false,
        eventLoading: false,
        shouldReloadEvents: false,
    },
    staffGroupView: {
        staffGroupId: null,
        staffGroups: [],
        staffGroupsLoading: false,
        filters: {
            selectedDepartmentIds: [],
            selectedJobTitleIds: [],
            selectedShiftTimeIds: [],
            selectedCredentialIds: [],
        },
        filtersApplied: false,
        events: [],
        eventsLoading: false,
        eventLoading: false,
        shouldReloadEvents: false,
    },
    staffView: {
        staff: null,
        filters: {
            selectedJobTitleIds: [],
            selectedStaffMemberIds: [],
            selectedCredentialIds: [],
            selectedEmploymentTypeIds: [],
        },
        filtersApplied: false,
        events: {},
        eventsLoading: false,
        eventLoading: false,
        shouldReloadEvents: false,
    }
}

const getters = {
    schedulerGetDates (state) {
        return state.dates
    },
    schedulerGetDepartmentId (state) {
        return state.departmentView.departmentId
    },
    schedulerGetDepartments (state) {
        return state.departmentView.departments
    },
    schedulerGetDepartmentsLoading (state) {
        return state.departmentView.departmentsLoading
    },
    schedulerGetDepartmentFilters (state) {
        return state.departmentView.filters
    },
    schedulerGetDepartmentFiltersApplied (state) {
        return state.departmentView.filtersApplied
    },
    schedulerGetDepartmentEvents (state) {
        return state.departmentView.events
    },
    schedulerGetDepartmentEventsLoading (state) {
        return state.departmentView.eventsLoading
    },
    schedulerGetDepartmentEventLoading (state) {
        return state.departmentView.eventLoading
    },
    schedulerGetShouldReloadDepartmentEvents (state) {
        return state.departmentView.shouldReloadEvents
    },
    schedulerGetScheduleViewType (state) {
        return state.scheduleViewType
    },
    // Staff Group View
    schedulerGetStaffGroupId (state) {
        return state.staffGroupView.staffGroupId
    },
    schedulerGetStaffGroups (state) {
        return state.staffGroupView.staffGroups
    },
    schedulerGetStaffGroupsLoading (state) {
        return state.staffGroupView.staffGroupsLoading
    },
    schedulerGetStaffGroupFilters (state) {
        return state.staffGroupView.filters
    },
    schedulerGetStaffGroupFiltersApplied (state) {
        return state.staffGroupView.filtersApplied
    },
    schedulerGetStaffGroupEvents (state) {
        return state.staffGroupView.events
    },
    schedulerGetStaffGroupEventsLoading (state) {
        return state.staffGroupView.eventsLoading
    },
    schedulerGetStaffGroupEventLoading (state) {
        return state.staffGroupView.eventLoading
    },
    schedulerGetShouldReloadStaffGroupEvents (state) {
        return state.staffGroupView.shouldReloadEvents
    },
    // Staff View
    schedulerGetStaff (state) {
        return state.staffView.staff
    },
    schedulerGetStaffFilters (state) {
        return state.staffView.filters
    },
    schedulerGetStaffFiltersApplied (state) {
        return state.staffView.filtersApplied
    },
    schedulerGetStaffEvents (state) {
        return state.staffView.events
    },
    schedulerGetStaffEventsLoading (state) {
        return state.staffView.eventsLoading
    },
    schedulerGetStaffEventLoading (state) {
        return state.staffView.eventLoading
    },
    schedulerGetShouldReloadStaffEvents (state) {
        return state.staffView.shouldReloadEvents
    },
    schedulerGetIsDisabled (state) {
        if (state.scheduleViewType === 'department') {
            return !state.departmentView.departmentsLoading && state.departmentView.departments.length === 0
        } else {
            return !state.staffGroupView.staffGroupsLoading && state.staffGroupView.staffGroups.length === 0
        }
    }
}

const actions = {
    schedulerSetScheduleViewType ({ commit }, payload) {
        commit('schedulerSetScheduleViewType', payload)
    },
    schedulerSetDates ({ commit }, payload) {
        commit('schedulerSetDates', payload)
    },
    schedulerSetDepartmentId ({ commit }, payload) {
        commit('schedulerSetDepartmentId', payload)
    },
    schedulerSetDepartments ({ commit }, payload) {
        commit('schedulerSetDepartments', payload)
    },
    schedulerSetDepartmentsLoading ({ commit }, payload) {
        commit('schedulerSetDepartmentsLoading', payload)
    },
    schedulerSetDepartmentFilters ({ commit }, payload) {
        commit('schedulerSetDepartmentFilters', payload)
    },
    schedulerSetDepartmentFiltersApplied ({ commit }, payload) {
        commit('schedulerSetDepartmentFiltersApplied', payload)
    },
    schedulerSetDepartmentEvents ({ commit }, payload) {
        commit('schedulerSetDepartmentEvents', payload)
    },
    schedulerSetDepartmentEventsLoading ({ commit }, payload) {
        commit('schedulerSetDepartmentEventsLoading', payload)
    },
    schedulerSetDepartmentEventLoading ({ commit }, payload) {
        commit('schedulerSetDepartmentEventLoading', payload)
    },
    schedulerSetShouldReloadDepartmentEvents ({ commit }, payload) {
        commit('schedulerSetShouldReloadDepartmentEvents', payload)
    },
    // Staff Group
    schedulerSetStaffGroupId ({ commit }, payload) {
        commit('schedulerSetStaffGroupId', payload)
    },
    schedulerSetStaffGroups ({ commit }, payload) {
        commit('schedulerSetStaffGroups', payload)
    },
    schedulerSetStaffGroupsLoading ({ commit }, payload) {
        commit('schedulerSetStaffGroupsLoading', payload)
    },
    schedulerSetStaffGroupFilters ({ commit }, payload) {
        commit('schedulerSetStaffGroupFilters', payload)
    },
    schedulerSetStaffGroupFiltersApplied ({ commit }, payload) {
        commit('schedulerSetStaffGroupFiltersApplied', payload)
    },
    schedulerSetStaffGroupEvents ({ commit }, payload) {
        commit('schedulerSetStaffGroupEvents', payload)
    },
    schedulerSetStaffGroupEventsLoading ({ commit }, payload) {
        commit('schedulerSetStaffGroupEventsLoading', payload)
    },
    schedulerSetStaffGroupEventLoading ({ commit }, payload) {
        commit('schedulerSetStaffGroupEventLoading', payload)
    },
    schedulerSetShouldReloadStaffGroupEvents ({ commit }, payload) {
        commit('schedulerSetShouldReloadStaffGroupEvents', payload)
    },
    // Staff
    schedulerSetStaff ({ commit }, payload) {
        commit('schedulerSetStaff', payload)
    },
    schedulerSetStaffFilters ({ commit }, payload) {
        commit('schedulerSetStaffFilters', payload)
    },
    schedulerSetStaffFiltersApplied ({ commit }, payload) {
        commit('schedulerSetStaffFiltersApplied', payload)
    },
    schedulerSetStaffEvents ({ commit }, payload) {
        commit('schedulerSetStaffEvents', payload)
    },
    schedulerSetStaffEventsLoading ({ commit }, payload) {
        commit('schedulerSetStaffEventsLoading', payload)
    },
    schedulerSetStaffEventLoading ({ commit }, payload) {
        commit('schedulerSetStaffEventLoading', payload)
    },
    schedulerSetShouldReloadStaffEvents ({ commit }, payload) {
        commit('schedulerSetShouldReloadStaffEvents', payload)
    },
}

const mutations = {
    schedulerSetScheduleViewType (state, { scheduleViewType }) {
        state.scheduleViewType = scheduleViewType
    },
    schedulerSetDates (state, { dates }) {
        if (Array.isArray(dates)) {
            state.dates = Array.from(dates)
        } else {
            state.dates = dates
        }
    },
    schedulerSetDepartmentId (state, { departmentId }) {
        state.departmentView.departmentId = departmentId
    },
    schedulerSetDepartments (state, { departments }) {
        state.departmentView.departments = Array.from(departments)
    },
    schedulerSetDepartmentsLoading (state, { departmentsLoading }) {
        state.departmentView.departmentsLoading = departmentsLoading
    },
    schedulerSetDepartmentFilters (state, { filters }) {
        state.departmentView.filters = Object.assign({}, filters)
    },
    schedulerSetDepartmentFiltersApplied (state, { filtersApplied }) {
        state.departmentView.filtersApplied = filtersApplied
    },
    schedulerSetDepartmentEvents (state, { events }) {
        state.departmentView.events = Array.from(events)
    },
    schedulerSetDepartmentEventsLoading (state, { eventsLoading }) {
        state.departmentView.eventsLoading = eventsLoading
    },
    schedulerSetDepartmentEventLoading (state, { eventLoading }) {
        state.departmentView.eventLoading = eventLoading
    },
    schedulerSetShouldReloadDepartmentEvents (state, { shouldReloadEvents }) {
        state.departmentView.shouldReloadEvents = shouldReloadEvents
    },
    // Staff Group
    schedulerSetStaffGroupId (state, { staffGroupId}) {
        state.staffGroupView.staffGroupId = staffGroupId
        state.staffView.staffGroupId = staffGroupId
    },
    schedulerSetStaffGroups (state, { staffGroups }) {
        state.staffGroupView.staffGroups = Array.from(staffGroups)
    },
    schedulerSetStaffGroupsLoading (state, { staffGroupsLoading }) {
        state.staffGroupView.staffGroupsLoading = staffGroupsLoading
    },
    schedulerSetStaffGroupFilters (state, { filters }) {
        state.staffGroupView.filters = Object.assign({}, filters)
    },
    schedulerSetStaffGroupFiltersApplied (state, { filtersApplied }) {
        state.staffGroupView.filtersApplied = filtersApplied
    },
    schedulerSetStaffGroupEvents (state, { events }) {
        state.staffGroupView.events = Array.from(events)
    },
    schedulerSetStaffGroupEventsLoading (state, { eventsLoading }) {
        state.staffGroupView.eventsLoading = eventsLoading
    },
    schedulerSetStaffGroupEventLoading (state, { eventLoading }) {
        state.staffGroupView.eventLoading = eventLoading
    },
    schedulerSetShouldReloadStaffGroupEvents (state, { shouldReloadEvents }) {
        state.staffGroupView.shouldReloadEvents = shouldReloadEvents
    },
    // Staff
    schedulerSetStaff (state, { staff}) {
        state.staffView.staff = staff
    },
    schedulerSetStaffFilters (state, { filters }) {
        state.staffView.filters = Object.assign({}, filters)
    },
    schedulerSetStaffFiltersApplied (state, { filtersApplied }) {
        state.staffView.filtersApplied = filtersApplied
    },
    schedulerSetStaffEvents (state, { events }) {
        state.staffView.events = Object.assign({}, events)
    },
    schedulerSetStaffEventsLoading (state, { eventsLoading }) {
        state.staffView.eventsLoading = eventsLoading
    },
    schedulerSetStaffEventLoading (state, { eventLoading }) {
        state.staffView.eventLoading = eventLoading
    },
    schedulerSetShouldReloadStaffEvents (state, { shouldReloadEvents }) {
        state.staffView.shouldReloadEvents = shouldReloadEvents
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
