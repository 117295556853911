<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <div class="mb-4 text-center">
                    <a href="/login">
                        <img src="/images/login-reg-logo.png" alt="Logo">
                    </a>
                </div>

                <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="5"
                >
                    You have sucessfully logged out of Koroid. You may now continue to logout from your organization's
                    identity provider by clicking <strong>"LOGOUT FROM OKTA"</strong>. This will log you out from every application
                    connected to your organization's identity provider.
                </v-alert>

                <v-card class="elevation-12">
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    block
                                    large
                                    color="primary"
                                    href="/login"
                                >
                                    Back to Sign In
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn
                                    block
                                    large
                                    color="primary"
                                    :href="external_idp_signout_url"
                                    target="_blank"
                                    @click="redirectToLogin"
                                >
                                    Logout from Okta
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "PostIdpLogout",
        data() {
            return {
                external_idp_signout_url: this.$appData.external_idp_signout_url,
            }
        },
        methods: {
            redirectToLogin() {
                setInterval(function() {
                    window.location = '/login'
                    }, 1000);
            },
        }
    }
</script>

<style scoped>

</style>
