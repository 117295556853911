<template>
    <div class="nav-font">
        <nav-sidebar v-model="showSidebar"></nav-sidebar>
        <app-bar v-model="showSidebar" :impersonating="isImpersonating"></app-bar>
        <mobile-app-notice v-model="showMobileAppNotice" :device="mobileDevice"></mobile-app-notice>
        <session-timeout-modal
            v-model="showLogoutWarningModal"
            @keepLoggedIn="startLogoutTimerWorker"
        ></session-timeout-modal>
    </div>
</template>

<script>
    import AppBar from "./AppBar";
    import NavSidebar from "./NavSidebar";
    import MobileAppNotice from "./MobileAppNotice";
    import SessionTimeoutModal from "./SessionTimeoutModal";
    import { mapGetters } from 'vuex';

    export default {
        name: "AppNav",
        components: {
            NavSidebar,
            AppBar,
            SessionTimeoutModal,
            MobileAppNotice
        },
        props: {
            impersonating: {
                type: String,
                default: 'false'
            }
        },
        data() {
            return {
                showSidebar: false,
                showMobileAppNotice: false,
                mobileDevice: null,
                showLogoutWarningModal: false,
                logoutTimer: null,
                api: new formHelper(),
            }
        },
        computed: {
            isImpersonating() {
                return this.impersonating == 'true'
            },

            ...mapGetters({
                sessionExpiresAt: 'expiresAt'
            }),

            timeUntillLoggedOut() {
                return this.sessionExpiresAt.subtract(3, 'minutes').diff(moment(), 'milliseconds')
            }
        },
        methods: {
            detectMobile() {
                const values = [
                    {device: 'android', expr: /Android/i},
                    {device: 'ios', expr: /iPhone/i},
                    {device: 'ios', expr: /iPad/i}
                ]

                let matches = values.filter((item) => navigator.userAgent.match(item.expr))

                if (!matches.length) {
                    return false
                }

                this.mobileDevice = matches[0].device

                return true
            },

            showLogoutWarning() {
                this.api.post('/auth/check-session')
                    .then(({data}) => {
                        if (data.remaining_time < 5) {
                            this.showLogoutWarningModal = true
                        } else {
                            this.startLogoutTimerWorker()
                        }
                    }).catch(() => {})
            },

            startLogoutTimerWorker() {
                this.$logoutWarningTimerWorker.postMessage({startLogoutWarningTimer: true, timeUntilLogout: this.timeUntillLoggedOut})
            }

        },
        created() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.showSidebar = true;
            }

            if (this.detectMobile() && this.$user.role_name === 'Staff') {
                this.showMobileAppNotice = true
            }
        },
        mounted() {
            this.startLogoutTimerWorker()
            this.$logoutWarningTimerWorker.onmessage = ({data: { timeUntilLogoutExpired }}) => {
                if (timeUntilLogoutExpired) {
                    this.showLogoutWarning()
                }
            }
        },
        watch: {
            sessionExpiresAt() {
                this.startLogoutTimerWorker()
            }
        }
    }
</script>

<style scoped>

</style>
