<template>
    <v-container fluid class="pt-4 px-6">
        <v-row>
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    dark
                    grow
                    slider-color="#4253C4 !important"
                    slider-size="3"
                >
                    <v-tab>
                        Employment Types
                    </v-tab>
                    <v-tab>
                        Shift Times
                    </v-tab>
                    <v-tab>
                        Credentials
                    </v-tab>
                    <v-tab>
                        Custom Fields
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <employment-types-tab :tab="tab"/>
            </v-tab-item>
            <v-tab-item>
                <shift-times-tab :tab="tab"/>
            </v-tab-item>
            <v-tab-item>
                <credentials-tab :tab="tab"/>
            </v-tab-item>
            <v-tab-item>
                <custom-fields-tab :tab="tab"/>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import EmploymentTypesTab from "./EmploymentTypes/EmploymentTypesTab";
import ShiftTimesTab from "./ShiftTimes/ShiftTimesTab";
import CredentialsTab from "./Credentials/CredentialsTab";
import CustomFieldsTab from "./CustomFields/CustomFieldsTab";

export default {
    name: "ConfigurationOptionsTabs",
    components: {EmploymentTypesTab, ShiftTimesTab, CredentialsTab, CustomFieldsTab},
    data: () => ({
        tab: 0,
    })
}
</script>

<style scoped>
:deep(.v-tabs-bar) {
    background-color: transparent !important;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
    color: #74747B !important;
}

.theme--dark.v-tabs > .v-tabs-bar, .theme--dark.v-tabs > .v-tabs-bar .v-tab, .theme--dark.v-tabs > .v-tabs-bar .v-tab > .v-btn, .theme--dark.v-tabs > .v-tabs-bar .v-tab > .v-icon {
    color: #4253C4 !important;
}

.v-tab {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: none;
}
</style>
