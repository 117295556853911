<template>
    <v-row class="mt-4">
        <v-col cols="12" md="6">
            <v-card class="chips-list-card">
                <v-card-title class="chips-list-card-title">
                    <v-row >
                        <v-col cols="8">
                            <v-skeleton-loader boilerplate type="card-heading"/>
                        </v-col>
                        <v-spacer/>
                        <v-col class="text-right">
                            <v-btn
                                class="plus-button"
                                small
                                fab
                                disabled
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text class="chips-list-card-list-container">
                    <v-row>
                        <v-col>
                            <v-skeleton-loader boilerplate type="table-tbody"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="6">
            <v-card class="chips-list-card">
                <v-card-title class="chips-list-card-title">
                    <v-row >
                        <v-col cols="8">
                            <v-skeleton-loader boilerplate type="card-heading"/>
                        </v-col>
                        <v-spacer/>
                        <v-col class="text-right">
                            <v-btn
                                class="plus-button"
                                small
                                fab
                                disabled
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text class="chips-list-card-list-container">
                    <v-row>
                        <v-col>
                            <v-skeleton-loader boilerplate type="table-tbody"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "LoadingSkeleton"
}
</script>

<style scoped>
    .chips-list-card {
        border-radius: 16px !important;
        height: 60vh;
    }

    .plus-button {
        background-color: #fff !important;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30) !important;
    }
</style>
