

function parseArr(arr, depth = 3) {
    if(Array.isArray(arr)) {
        if(arr.length > 0) {
            if(arr.length > depth) {
                const remaining = arr.length - depth
                const joined = arr.slice(0, depth).join(', ')
                return `${joined}, (+${remaining})`
            }
            return arr.join(', ')
        }
        return 'N/A'
    }
    return 'N/A'
}

export { parseArr }
