<template>
    <v-toolbar class="ds-toolbar ds-modal-toolbar" elevation="0">
        <v-toolbar-title class="ds-h2">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ds-modal-close-button" @click="onClose">
            <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    name: "ModalToolbar",
    props: {
        title: {
            type: String,
            default: 'Title'
        }
    },
    methods: {
        onClose() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
