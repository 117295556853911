module.exports = {
    methods: {
        formatStaffGroups(user) {
            if ((typeof user.jobs === 'undefined') || (!user.jobs.length)) {
                return 'None';
            }

            let staff_groups = new Set();

            user.jobs.forEach(job => {
                if (typeof job.staff_group !== 'undefined') {
                    staff_groups.add(job.staff_group.name)
                }
            })

            staff_groups = [... staff_groups]

            return staff_groups.join(', ');
        },

        formatLocations(user) {
            if ((typeof user.jobs === 'undefined') || (!user.jobs.length)) {
                return 'N/A';
            }

            let locations = new Set();

            user.jobs.forEach(job => {
                if (typeof job.location !== 'undefined') {
                    locations.add(job.location.name)
                }
            })

            locations = [... locations];

            return locations;
        },

        formatJobTitles(user) {
            if ((typeof user.jobs === 'undefined') || (!user.jobs.length)) {
                return 'None';
            }

            let job_titles = new Set();

            user.jobs.forEach(job => {
                if (typeof job.job_title !== 'undefined' && typeof job.job_title.title !== 'undefined') {
                    job_titles.add(job.job_title.title)
                }
            })

            job_titles = [... job_titles];

            return job_titles.join(', ');
        },

        formatJobTitleForStaffGroup(user, searchParams) {
            let job_title = 'None'

            if (!searchParams || typeof searchParams.staff_group === 'undefined' || !searchParams.staff_group) {
                return job_title
            }

            let job = null

            if (typeof user.jobs !== 'undefined') {
                job = user.jobs.find(job => job.staff_group.id === searchParams.staff_group);
            }

            if (job) {
                job_title = job.job_title.title
            }

            return job_title
        },

        formatLastShift(user, timezone) {
            if (typeof user.last_shift !== 'undefined' && user.last_shift) {
                return moment(user.last_shift.start).tz(timezone).format('MM/DD/YYYY h:mm A')
            }

            return 'N/A'
        },

        formatLastLogin(user, timezone) {
            if (typeof user.last_login !== 'undefined' && user.last_login) {
                return moment(user.last_login).tz(timezone).format('MM/DD/YYYY h:mm A')
            }

            return 'N/A'
        }
    }
}
