<template>
    <div class="staff-event-wrapper" :style="eventContainerStyle" @click="onClick">
        <div v-if="loading" class="staff-event-loading-container">
            <v-progress-circular
                indeterminate
                :size="22"
            ></v-progress-circular>
        </div>
        <div v-else class="staff-event-container">
            <div class="staff-event-time-container">
                <div class="text--label">
                    {{ startTime }}
                </div>
                <div class="text--label">
                    -
                </div>
                <div class="text--label">
                    {{ endTime }}
                </div>
            </div>
            <div class="staff-event-department-container">
                <v-icon color="#000000" dense>mdi-domain</v-icon>
                <div class="staff-event-department-details text--caption">{{ department }}</div>
                <div v-if="isOverflow" class="staff-event-time-overflow-details">
                    {{ overflowDiff }}
                </div>
            </div>

        </div>
        <div v-if="multiple" class="staff-event-multiple-container">
            <v-icon dense>{{ chevronUp ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </div>
        <div v-if="hasIcon" class="staff-event-icon-container">
            <v-icon :color="iconColor">
                {{ icon }}
            </v-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: "Event",
    props: {
        event: {
            type: Object,
            default: () => ({})
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        chevronUp: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        apricot: { r: 242, g: 194, b: 102 },
        salad: { r: 107, g: 201, b: 110 },
        brick: { r: 209, g: 52, b: 52 },
        loading: false,
    }),
    computed: {
        eventContainerStyle () {
            return {
                border: `3px solid ${this.borderColor}`,
                ...!this.hasDifferentJobTitle && { backgroundColor: this.backgroundColor },
                ...this.hasDifferentJobTitle && { backgroundColor: '#FFFFFF' },
            }
        },
        color () {
            if (this.isNoShow || this.isCallOut) {
                return this.brick
            } else if (this.isAssigned) {
                return this.salad
            } else if (this.isOffer) {
                return this.apricot
            } else {
                return this.salad
            }
        },
        borderColor () {
            return this.toRgb(this.color)
        },
        backgroundColor () {
            return this.toRgba(this.color, 0.6)
        },
        start () {
            return moment(this.event.start).tz(this.globalTimezone)
        },
        startTime () {
            return this.start.format('h:mmA')
        },
        end () {
            return moment(this.event.end).tz(this.globalTimezone)
        },
        endTime () {
            return this.end.format('h:mmA')
        },
        department () {
            return this.event.department_name
        },
        isAssigned () {
            return this.event.type === 'shift'
        },
        isNoShow () {
            return this.event.no_show
        },
        isCallOut () {
            return this.event.call_out
        },
        isOffer () {
            return this.event.type === 'shiftOffer'
        },
        isOverflow () {
            return !!this.event.day_span_label
        },
        overflowDiff () {
            return `(${this.event.day_span_label})`
        },
        hasDifferentJobTitle () {
            return Boolean(this.event.has_different_job_title)
        },
        hasCancellationRequest () {
            return Boolean(this.event.cancellation_requested)
        },
        hasShiftTimeEdited () {
            return Boolean(this.event.time_edited)
        },
        hasIcon () {
            return this.hasCancellationRequest || this.hasShiftTimeEdited || this.isNoShow || this.isCallOut
        },
        icon () {
            if (this.hasCancellationRequest || this.isNoShow || this.isCallOut) {
                return 'mdi-alert-circle'
            } else if (this.hasShiftTimeEdited) {
                return 'mdi-clock'
            }
        },
        iconColor () {
            if (this.hasCancellationRequest || this.isNoShow) {
                return 'error'
            } else if (this.hasShiftTimeEdited || this.isCallOut) {
                return 'warning'
            }
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        eventLoading: {
            get () {
                return this.$store.getters.schedulerGetStaffEventLoading
            },
            set (eventLoading) {
                this.$store.dispatch('schedulerSetStaffEventLoading', { eventLoading })
            }
        }
    },
    methods: {
        toRgb (color) {
            return `rgb(${color.r}, ${color.g}, ${color.b})`
        },
        toRgba (color, opacity) {
            return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`
        },
        emitClickEvent () {
            window.EventBus.$emit('staffScheduler/onClickEvent', this.event)
        },
        onClick () {
            if (!this.loading && !this.multiple && !this.eventLoading) {
                if (this.event.type === 'shiftOffer') {
                    this.loading = true
                    this.eventLoading = true
                }
                this.emitClickEvent()
            }
        },
        listenOnClose () {
            window.EventBus.$on('staffScheduler/onCloseEvent', () => {
                this.loading = false
                this.eventLoading = false
            })
        }
    },
    created () {
        this.listenOnClose()
    }
}
</script>

<style scoped>
    .staff-event-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 10px 5px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
    }

    .staff-event-loading-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .staff-event-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-width: 0;
    }

    .staff-event-multiple-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .staff-event-time-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 2px;
    }

    .staff-event-time-details {
        font-size: 14px;
        font-weight: 600;
    }

    .staff-event-time-overflow-details {
        font-size: 12px;
    }

    .staff-event-department-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 3px;
        width: 100%;
    }

    .staff-event-department-details {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .staff-event-icon-container {
        position: absolute;
        top: -7px;
        right: -7px;
        border-radius: 50%;
        background-color: white;
    }
</style>
