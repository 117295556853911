<template>
    <v-row>
        <v-col cols="12" :sm="jobTitleColsSm" class="py-0">
            <v-autocomplete
                v-model="model.job_title_id"
                label="Job Title"
                class="required"
                :items="formattedJobTitles"
                :loading="loading"
                :disabled="disabled || editMode"
                :rules="requiredJobTitleMaxShiftDuration"
                hide-selected
                outlined
                item-value="_id"
                item-text="title"
            >
                <template v-slot:prepend-inner v-if="!removable">
                    <v-icon color="primary">
                        mdi-clipboard-account
                    </v-icon>
                </template>
                <template v-slot:selection="{ item }">
                    <div>
                        {{ getJobTitleText(item.title) }}
                    </div>
                </template>
                <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.disabled && item.disabled_message" class="autocomplete-item-disabled-subtitle">
                                <v-icon
                                    class="ml-1"
                                    small
                                >
                                    mdi-information-outline
                                </v-icon>
                                <span>{{ item.disabled_message }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col :cols="jobTitleCountCols" sm="4" class="py-0">
            <number-input
                :min="1"
                :max="99"
                controls
                v-model.number="model.amount_requested"
                required
                :disabled="disabled"
                :loading="loading"
            ></number-input>
        </v-col>
        <v-col cols="auto" v-if="removable" class="py-0">
            <v-btn outlined x-small fab color="error" @click="onRemoveJobTitle" style="margin-top: 15px;" :disabled="editMode">
                <v-icon>
                    mdi-minus
                </v-icon>
            </v-btn>
        </v-col>
        <v-col v-if="!editCredentials" cols="12" class="py-0 pr-0" style="padding-left: 30px;">
            <v-btn
                color="primary"
                text
                small
                class="cred-btn"
                :disabled="disabled"
                @click="onAddCredentials">
                Add Credentials
            </v-btn>
        </v-col>
        <v-col v-else cols="12" class="pt-0">
            <v-slide-y-transition group tag="div">
                <v-autocomplete
                    v-model="model.requirements"
                    key="credentialsKey"
                    label="Credentials"
                    append-icon="mdi-close"
                    :items="credentials"
                    :loading="loading"
                    :disabled="disabled"
                    outlined
                    dense
                    multiple
                    chips
                    deletable-chips
                    item-value="_id"
                    item-text="name"
                    @click:append="onRemoveCredentials"
                ></v-autocomplete>
            </v-slide-y-transition>
        </v-col>
    </v-row>
</template>

<script>
import NumberInput from "../../../common/NumberInput/NumberInput.vue";

export default {
    name: "JobTitleSelect",
    components: {NumberInput},
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        pKey: {
            type: Number,
            default: 0,
        },
        jobTitles: {
            type: Array,
            default: () => [],
        },
        credentials: {
            type: Array,
            default: () => [],
        },
        removable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        duration: {
            type: Number,
            default: null
        },
        shiftRequests: {
            type: Array,
            default: () => []
        }
    },
    data() {
        const hasCredentials = typeof this.value.requirements !== 'undefined' && this.value.requirements.length
        return {
            editCredentials: hasCredentials
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        jobTitleColsSm() {
            if (this.removable) {
                return 6
            }
            return 8
        },
        jobTitleCountCols() {
            if (this.removable) {
                return 8
            }
            return 12
        },
        requiredJobTitleMaxShiftDuration() {
            return [
                job_title_id => !!job_title_id && !!job_title_id.trim() || 'Required',
                job_title_id => {
                    if (!this.duration) {
                        return true
                    }

                    let job_title = this.jobTitles.find(jt => jt._id === job_title_id)

                    if (!job_title) {
                        return true
                    }

                    if (this.duration <= job_title.max_shift_duration) {
                        return true
                    }
                    return this.getJobTitleMaxShiftDurationLimitErrorMessage(job_title_id)
                }
            ]
        },
        formattedJobTitles() {
            return this.jobTitles.map(job_title => {
                job_title.disabled = !job_title.managed_by_auth_user
                job_title.disabled_message = ''

                if (!job_title.managed_by_auth_user) {
                    job_title.disabled_message = this.$authIsManager ?
                        "You are not authorized to manage this Job Title" :
                        "This Job Title is not part of any Staffing Detail"
                }

                return job_title
            })
        }
    },
    methods: {
        getJobTitleText (title) {
            if (title) {
                if (title.length > 23) {
                    return `${title.slice(0,23)}...`
                }
                return title
            }
            return ''
        },
        onAddCredentials() {
            this.editCredentials = true
        },
        onRemoveCredentials() {
            this.editCredentials = false
            this.model.requirements = []
        },
        onRemoveJobTitle() {
            this.$emit('onRemove', this.pKey)
        },
        getJobTitleMaxShiftDurationLimitErrorMessage(job_title_id) {
            let job_title = this.jobTitles.find(jt => jt._id === job_title_id)

            if (!job_title) {
                return ''
            }

            let duration = this.$options.filters.timeStringFromMinutes(job_title.max_shift_duration)

            return `Max shift duration is ${duration}`
        }
    }
}
</script>

<style scoped>
    .cred-btn {
        text-transform: unset !important;
    }
    :deep(div.v-messages__message) {
        color: #d13434 !important;
    }
</style>
