<template>
    <div>
        <main-report-filters
            :preselected-filters="preselectedStaffingDetails"
            :readonly="readonly"
            :report-type="reportType"
            :location="location"
            hide-credentials
            @onFiltersChange="onStaffingDetailsFiltersChange"
            @onChange="onChange"/>
        <user-search
            v-model="selectedEmployees"
            :key="employeesInputSelectKey"
            :readonly="readonly"
            :initial-entries="preselectedEmployees"
            :report-type="reportType"
            @onChange="onChange"/>
    </div>
</template>

<script>
import MainReportFilters from "./MainReportFilters";
import UserSearch from "../../common/Search/UserSearch";

export default {
    name: "DepSgJtStSmFilters",
    components: {MainReportFilters, UserSearch,},
    props: {
        preset: {
            type: Object,
            default: () => null
        },
        value: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedDepartments: null,
        selectedStaffGroups: null,
        selectedJobTitles: null,
        selectedShiftTimes: null,
        selectedEmployees: null,
        preselectedEmployees: null,
        employeesInputSelectKey: 8888,
    }),
    computed: {
        preselectedStaffingDetails() {
            const {preset} = this
            if (preset) {
                const {filters} = preset
                if (filters) {
                    return {
                        departmentIds: Array.isArray(filters.departments) ?
                            filters.departments.map(department => department.id) : [],
                        staffGroupIds: Array.isArray(filters.staff_groups) ?
                            filters.staff_groups.map(sg => sg.id) : [],
                        jobTitleIds: Array.isArray(filters.job_titles) ?
                            filters.job_titles.map(jt => jt.id) : [],
                        shiftTimeIds: Array.isArray(filters.shift_times) ?
                            filters.shift_times.map(st => st.id) : [],
                    }
                }
                return {
                    departmentIds: [],
                    staffGroupIds: [],
                    jobTitleIds: [],
                    shiftTimeIds: [],
                }
            }
            return null
        },
        filters() {
            return {
                departments: this.selectedDepartments,
                staffGroups: this.selectedStaffGroups,
                jobTitles: this.selectedJobTitles,
                shiftTimes: this.selectedShiftTimes,
                staffMembers: this.selectedEmployees,
            }
        }
    },
    methods: {
        onStaffingDetailsFiltersChange(filters) {
            if (filters) {
                this.selectedDepartments = Array.isArray(filters.departmentIds) ? filters.departmentIds : []
                this.selectedStaffGroups = Array.isArray(filters.staffGroupIds) ? filters.staffGroupIds : []
                this.selectedJobTitles = Array.isArray(filters.jobTitleIds) ? filters.jobTitleIds : []
                this.selectedShiftTimes = Array.isArray(filters.shiftTimeIds) ? filters.shiftTimeIds : []
            }
        },
        onChange() {
            this.emitOnChange()
        },
        emitOnChange() {
            this.$emit('onChange')
        },
        resetEmployeesInput() {
            this.selectedEmployees = null
            this.preselectedEmployees = null
            this.employeesInputSelectKey = this.employeesInputSelectKey + 1
        }
    },
    watch: {
        preset: {
            immediate: true,
            handler(preset) {
                if (preset) {
                    const {filters} = preset
                    if (filters) {
                        const {staff_members} = filters
                        if (Array.isArray(staff_members)) {
                            this.selectedEmployees = staff_members.map(sm => sm.id)
                            this.preselectedEmployees = Array.from(staff_members)
                            this.employeesInputSelectKey = this.employeesInputSelectKey + 1
                        } else {
                            this.resetEmployeesInput()
                        }
                    } else {
                        this.resetEmployeesInput()
                    }
                }
            }
        },
        filters(filters) {
            this.$emit('input', filters)
        }
    }
}
</script>

<style scoped>

</style>
