<template>
    <v-dialog
        v-model="modalValue"
        scrollable width="850px"
        content-class="ds-dialog"
        persistent
    >
        <v-card class="ds-modal-card">
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Introduction to Automated Shifts
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="ds-modal-close-button" @click="modalValue = false">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content" style="padding-bottom: 0 !important;">
                <p class="black--text">
                    This feature allows you to create and automate a group of shifts on a chosen schedule, simplifying
                    the scheduling process so you can focus on staff assignments.
                </p>
                <p class="black--text">
                    <span class="text--h3">
                        Setting Up Automated Shifts:
                    </span>
                </p>
                <ul class="black--text">
                    <li>
                        <b>Shift Date: </b>Start date for the shifts
                    </li>
                    <li style="margin-top: 5px;">
                        <b>Shift Span: </b>Number of weeks the shifts should cover
                    </li>
                    <li style="margin-top: 5px;">
                        <b>First Run Date: </b>Date to begin populating the schedule (must be at least 3 days before the
                        Shift Date)
                    </li>
                </ul>
                <p class="black--text" style="margin-top: 25px;">
                    Koroid will automatically populate new shifts weekly to maintain the set Shift Span.
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="d-flex flex-row justify-space-between align-center"
                           style="padding-left: 8px; padding-right: 8px;">
                        <v-checkbox v-model="dontShowAgain" label="Don't show again"></v-checkbox>
                        <v-btn class="ml-2" color="primary" outlined @click="onContinue">Continue</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "IntroductoryModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        dontShowAgain: true,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onContinue() {
            if (this.dontShowAgain) {
                this.api.get('/staffing-automation/dismiss-introductory-modal').then(() => {
                }).catch(() => {
                })
            }
            this.modalValue = false
            this.$emit('onContinue', this.dontShowAgain)
        }
    }
}
</script>

<style scoped>
:deep(label.v-label) {
    color: #000000;
}
</style>
