<template>
    <v-tooltip
        bottom
        min-width="130px"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                :class="eventClasses"
                :style="containerStyle"
                @click="onEventClick"
                v-on="on"
                v-bind="attrs"
                name="calendar-shift"
            >
                <v-progress-circular indeterminate v-if="eventLoading"></v-progress-circular>
                <template v-else>
                    <div
                        v-if="(eventSize.width > 1.1 && eventSize.width <= 3) && requirementsExist"
                        class="requirement">
                    </div>


                    <div
                        v-if="(eventSize.width > 3) && requirementsExist"
                        class="flex-grow-1"
                    >
                        <div class="requirement"></div>
                    </div>

                    <div
                        v-if="(eventSize.width > 3) && renderShiftNumber"
                        class="d-flex align-center justify-center h-100 flex-grow-1 white--text"
                    >
                        {{ event.calendar.numberOfShifts }}
                    </div>
                    <div
                        v-if="(eventSize.width > 3) && requirementsExist && renderShiftNumber"
                        class="flex-grow-1"
                    ></div>
                </template>
            </div>
        </template>
        <span>
            <strong>Start:</strong> {{ start_formatted }}
            <br>
            <strong>End:</strong> {{ end_formatted }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    name: "DepartmentEvent",
    props: {
        event: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    data: function() {
        return {
            end: 10079,
            colors: {
                green: 'rgb(107, 201, 110)',
                orange: 'rgb(242, 194, 102)',
                red: 'rgb(209, 52, 52)',
            }
        }
    },
    computed: {
        globalTimezone() {
            return this.$root.globalTimezone
        },
        start_formatted() {
            return moment(this.event.start).tz(this.globalTimezone).format('h:mm A')
        },
        end_formatted() {
            return moment(this.event.end).tz(this.globalTimezone).format('h:mm A')
        },
        containerStyle: function() {
            return {
                left: `${this.eventSize.left}%`,
                width: `${this.eventSize.width}%`,
                'background-color': this.eventColor
            }
        },
        eventClasses() {
            if (this.eventSize.width <= 1.1) {
                return {
                    'event': true
                }
            } else if (this.eventSize.width <= 3) {
                return {
                    'event': true,
                    'd-flex': true,
                    'align-center': true,
                    'justify-center': true
                }
            } else {
                return this.fullEventContainerClass
            }
        },
        fullEventContainerClass: function() {
            return {
                'event': true,
                'd-flex': true,
                'align-center': true,
                ...this.requirementsExist && {
                    'justify-space-between': true,
                    'px-1': true
                },
                ...!this.requirementsExist && {
                    'justify-center': true
                }
            }
        },
        eventSize: function() {
            const { end } = this
            let { startMinutes, endMinutes } = this.event.eventDetails
            const left = startMinutes/end * 100
            const width = (endMinutes - startMinutes)/end * 100
            return {
                left,
                width
            }
        },
        eventColor: function() {
            const { cancellation_requested, status } = this.event
            if(cancellation_requested) {
                return this.colors.red
            } else if(status === 'filled') {
                return this.colors.green
            } else {
                return this.colors.orange
            }
        },
        requirementsExist: function() {
            // temporarily disabling this indicatior
            return false
            const { requirements } = this.event
            return requirements && Array.isArray(requirements) && requirements.length > 0
        },
        renderShiftNumber: function() {
            const { calendar } = this.event
            return calendar && calendar.numberOfShifts !== 1
        },
        eventLoading () {
            return !!(this.event && this.event.eventDetails && this.event.eventDetails.loading);
        }
    },
    methods: {
        onEventClick: function() {
            this.$emit('onEventClick', this.event)
        }
    },
}
</script>

<style scoped>
    .event {
        height: 40px;
        position: absolute;
        border-radius: 2px;
        opacity: 1;
    }

    .event:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    .pending-event {
        background-image: repeating-linear-gradient(-75deg,
        transparent,
        transparent 25%,
        white 25%,
        white 29%);
    }

    .requirement {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: black;
    }


</style>
