<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
            >
                Select Report Filters
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="step > 2"
                step="2"
            >
                Select Report Data
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="step > 3"
                step="3"
            >
                Select Date Range
            </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-container fluid class="mt-3">
                <v-row>
                    <v-col>
                        <slot name="report-filters"></slot>
                    </v-col>
                    <v-col>
                        <slot name="report-data"></slot>
                    </v-col>
                    <v-col>
                        <slot name="report-date-range"></slot>
                    </v-col>
                </v-row>
            </v-container>
        </v-stepper-items>
        <div class="stepper-footer">
            <div>
                <v-btn color="primary" @click="onGoBack" outlined>
                    Back to Reports
                </v-btn>
            </div>
            <div>
                <v-btn
                    v-if="step > 1"
                    color="primary"
                    text
                    :disabled="loading"
                    @click="onPrevStep"
                >Previous</v-btn>
                <v-btn
                    v-if="step < 3"
                    color="secondary"
                    :disabled="loading"
                    @click="onNextStep"
                >Next</v-btn>
                <v-btn
                    v-if="step >= 3"
                    color="secondary"
                    :loading="loading"
                    @click="onGenerate"
                >Generate</v-btn>
            </div>
        </div>
    </v-stepper>
</template>

<script>
export default {
    name: "CreateReportStepper",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        quickReport: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        step: 1
    }),
    methods: {
        goToPrevStep() {
            this.step = this.step - 1
            this.$emit('onStepChange', this.step)
        },
        onPrevStep() {
            this.$emit('onPrevStep', this.step)
        },
        goToNextStep() {
            this.step = this.step + 1
            this.$emit('onStepChange', this.step)
        },
        onNextStep() {
            this.$emit('onNextStep', this.step)
        },
        onClear() {
            this.$emit('onClear')
        },
        onGenerate() {
            this.$emit('onGenerate')
        },
        onGoBack() {
            this.$emit('onGoBack')
        }
    },
    created() {
        if(this.quickReport) {
            this.step = 3
            this.$emit('onStepChange', this.step)
        }
    }
}
</script>

<style scoped>
    .stepper-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px 10px;
    }
</style>
