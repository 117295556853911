<template>
    <div id="organization-notice">
        <div class="row notice-title">
            <h4 class="ds-h4">Managing your Organization</h4>
            <v-btn small icon color="primary" @click="onDismiss" :disabled="api.busy">
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </div>

        <div class="row notice-content">
            <p>
                Use the <v-icon color="primary">mdi-cog</v-icon> to access Configuration Options and update your Organization details <br>
                Select an existing Location to view and update its Departments and Staff Groups. <br>
                Add a new Location, Department, or Staff Group to expand your Organization.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrganizationNotice",
    data() {
        return {
            api: new formHelper()
        }
    },
    methods: {
        onDismiss() {
            this.$emit('noticeDismissed')
            this.api.post('/organization/dismiss-notice')
        }
    }
}
</script>

<style scoped>
    #organization-notice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 12px;
        background-color: #F6FAFF;
        width: 840px;
        margin: 20px auto 0;
        padding: 24px 0;
    }

    #organization-notice .notice-title {
        justify-content: space-between;
        width: 95%;
    }

    #organization-notice h4 {
        flex: 1;
        text-align: center;
    }

    #organization-notice .notice-content {
        padding: 0 120px;
        text-align: center;
    }

    @media only screen and (max-width: 1100px) {
        #organization-notice {
            width: 100%;
        }
    }
</style>
