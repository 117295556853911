<template>
    <v-container grid-list-xl fluid>
        <v-row>
            <v-col cols="12">
                <v-window v-model="window">
                    <v-window-item value="staffing-automations">
                        <staffing-automations-view
                            ref="staffingAutomationsViewRef"
                            @onClickStaffingAutomation="onClickStaffingAutomation"
                        />
                    </v-window-item>
                    <v-window-item value="staffing-automation-details">
                        <staffing-automation-details
                            :key="staffingAutomationDetailsKey"
                            edit-mode
                            :staffing-automation-id="selectedStaffingAutomationId"
                            :can-edit-staffing-automation="canEditStaffingAutomation"
                            @onClickBackToShifts="onClickBackToShifts"
                            @onActivate="onActivateStaffingAutomation"
                            @onDeactivate="onDeactivateStaffingAutomation"
                            @onDelete="onDeleteStaffingAutomation"
                            @onUpdateStaffingAutomation="onUpdateStaffingAutomation"
                        />
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StaffingAutomationsView from "./StaffingAutomationsView";
import StaffingAutomationDetails from "./AddAutomatedShift/StaffingAutomationDetails";
import AutomatedShiftDetails from "./AutomatedShiftDetails";
import AutomatedShiftDetailsToolbar from "./AutomatedShiftDetails/AutomatedShiftDetailsToolbar";
import AddJobTitlesModal from "./AddAutomatedShift/AddJobTitlesModal";

export default {
    name: "index",
    components: {
        AddJobTitlesModal,
        StaffingAutomationsView,
        StaffingAutomationDetails,
        AutomatedShiftDetails,
        AutomatedShiftDetailsToolbar
    },
    data: () => ({
        window: 'staffing-automations',
        selectedStaffingAutomationId: null,
        staffingAutomationDetailsKey: 1,
        api: new formHelper()
    }),
    computed: {
        staffingAutomationsViewRef() {
            return this.$refs.staffingAutomationsViewRef
        },
        canEditStaffingAutomation() {
            return this.$userHasPermission(this.$user, this.$config.permissions.STAFFING_AUTOMATION.EDIT)
        }
    },
    methods: {
        onClickStaffingAutomation(staffingAutomationId) {
            this.window = 'staffing-automation-details'
            this.staffingAutomationDetailsKey = this.staffingAutomationDetailsKey + 1
            this.selectedStaffingAutomationId = staffingAutomationId
        },
        onClickBackToShifts() {
            this.window = 'staffing-automations'
        },
        onActivateStaffingAutomation() {
            this.fetchStaffingAutomations()
        },
        onDeactivateStaffingAutomation() {
            this.fetchStaffingAutomations()
        },
        onUpdateStaffingAutomation() {
            this.fetchStaffingAutomations()
        },
        onDeleteStaffingAutomation() {
            this.window = 'staffing-automations'
            this.fetchStaffingAutomations()
        },
        fetchStaffingAutomations() {
            if (this.staffingAutomationsViewRef && this.staffingAutomationsViewRef.fetchStaffingAutomations) {
                this.staffingAutomationsViewRef.fetchStaffingAutomations()
            }
        }
    },
}
</script>

<style scoped>

</style>
