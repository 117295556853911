<template>
    <v-card>
        <v-tabs
            v-model="tab"
            dark
            grow
            height="56"
        >
            <v-toolbar-title style="line-height: 56px; color: #000D60;" class="ml-3 mr-4 text--h2">
                Reports
            </v-toolbar-title>
            <v-tab>
                All Reports
            </v-tab>
            <v-tab>
                Report Presets
            </v-tab>
            <div class="menu-outer-container">
                <OptionsSelect
                    :key="reportTypeSelectKey"
                    title="New Report"
                    color="primary"
                    :items="reportTypes"
                    :disabled="isNewReportDisabled"
                    @onOptionChanged="onReportTypeSelect"
                />
            </div>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card v-if="!reportsAlertDismissed">
                        <v-card-actions>
                            <span class="ml-2">
                                Previous reports are all cataloged here. Click on a report to view the data.
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="onDismissReportsAlert">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <div class="my-3">
                        <ReportsTable :key="reportsTableKey" @onClickReport="onClickReport"/>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-card v-if="!reportPresetsAlertDismissed">
                        <v-card-actions>
                            <span class="ml-2">
                                Saved report presets organized by report type. Run quick reports, edit or delete presets.
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="onDismissReportPresetsAlert">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <div class="my-3">
                        <v-expansion-panels :key="panelsKey" v-model="panelsValue" multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="text--h3">
                                    Shift Details
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <ShiftDetailsPresetList
                                        :location="globalLocation"
                                        @onUpdatePreset="onUpdatePreset"
                                        @onDeletePreset="onDeletePreset"
                                        @onRunQuickReport="onRunQuickReport"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="text--h3">
                                    Shift Offers
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <ShiftOfferPresetList
                                        :location="globalLocation"
                                        @onUpdatePreset="onUpdatePreset"
                                        @onDeletePreset="onDeletePreset"
                                        @onRunQuickReport="onRunQuickReport"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="text--h3">
                                    User Availability
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <AvailabilityPresetList
                                        :location="globalLocation"
                                        @onUpdatePreset="onUpdatePreset"
                                        @onDeletePreset="onDeletePreset"
                                        @onRunQuickReport="onRunQuickReport"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="text--h3">
                                    Shift Cancellation
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <ShiftCancellationPresetList
                                        :location="globalLocation"
                                        @onUpdatePreset="onUpdatePreset"
                                        @onDeletePreset="onDeletePreset"
                                        @onRunQuickReport="onRunQuickReport"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="text--h3">
                                    Payroll Period
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <PayrollPeriodPresetList
                                        :location="globalLocation"
                                        @onUpdatePreset="onUpdatePreset"
                                        @onDeletePreset="onDeletePreset"
                                        @onRunQuickReport="onRunQuickReport"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <v-dialog v-model="allLocationsModal" max-width="400" persistent>
            <v-card>
                <v-toolbar
                    dark
                    color="error"
                >
                    <v-icon color="white" class="mr-3">mdi-alert</v-icon>
                    <v-toolbar-title>You must select a location first</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="allLocationsModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <p style="margin-top: 20px; font-size: 16px;">
                        Please select a location first before creating a new report.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="allLocationsModal = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import ReportsTable from "../Tables/ReportsTable";
import ShiftDetailsPresetList from "../Lists/ShiftDetails/ShiftDetailsPresetList";
import ShiftOfferPresetList from "../Lists/ShiftOffer/ShiftOfferPresetList";
import AvailabilityPresetList from "../Lists/Availability/AvailabilityPresetList";
import ShiftCancellationPresetList from "../Lists/ShiftCancellation/ShiftCancellationPresetList";
import PayrollPeriodPresetList from "../Lists/PayrollPeriod/PayrollPeriodPresetList";
import OptionsSelect from "../FormInputs/OptionsSelect";

export default {
    name: "ReportsView",
    components: { OptionsSelect, ReportsTable, ShiftDetailsPresetList, ShiftOfferPresetList, AvailabilityPresetList, ShiftCancellationPresetList, PayrollPeriodPresetList },
    props: {
        reportsAlertDismissed: {
            type: Boolean,
            default: false,
        },
        reportPresetsAlertDismissed: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        tab: 0,
        reportTypes: [
            { text: 'Shift Details', value: 'shift_details' },
            { text: 'Shift Offers', value: 'shift_offers' },
            { text: 'User Availability', value: 'user_availability' },
            { text: 'Shift Cancellation', value: 'shift_cancellation' },
            { text: 'Payroll Period', value: 'payroll_period' },
        ],
        reportsTableKey: 3333,
        reportTypeSelectKey: 5555,
        panelsKey: 8888,
        panelsValue: [],
        allLocationsModal: false,
    }),
    computed: {
        globalLocation() {
            return this.$root.globalLocation;
        },
        isNewReportDisabled() {
            return !this.$userHasPermission(this.$user, this.$config.permissions.REPORTS.GENERATE)
        }
    },
    methods: {
        onReportTypeSelect(reportType) {
            if(this.globalLocation) {
                this.$emit('onReportTypeSelect', reportType)
                this.reportTypeSelectKey = this.reportTypeSelectKey + 1
            } else {
                this.allLocationsModal = true
            }
        },
        onClickReport(report) {
            this.$emit('onClickReport', report)
        },
        onUpdatePreset() {
            this.reportsTableKey = this.reportsTableKey + 1
        },
        onDeletePreset() {
            this.reportsTableKey = this.reportsTableKey + 1
        },
        onRunQuickReport(preset, reportType) {
            this.$emit('onRunQuickReport', preset, reportType)
        },
        onDismissReportsAlert() {
            this.$emit('onDismissReportsAlert')
        },
        onDismissReportPresetsAlert() {
            this.$emit('onDismissReportPresetsAlert')
        }
    },
    watch: {
        globalLocation() {
            this.reportTypeSelectKey = this.reportTypeSelectKey + 1
            this.reportsTableKey = this.reportsTableKey + 1
            this.panelsKey = this.panelsKey + 1
        }
    }
}
</script>

<style scoped>
    .menu-outer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 10px;
    }
</style>
