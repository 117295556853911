<template>
    <v-row class="pa-3">
        <v-col cols="12" class="d-flex flex-row justify-space-between align-center">
            <span>
                Track skills and qualifications to improve staffing fulfillment when scheduling. Select a row to edit it.
            </span>
            <v-btn
                color="secondary"
                :disabled="!hasEditCredentialsPermission"
                @click="onAddCredential"
            >
                Add Credential
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table
                id="credentials-table"
                :height="tableHeight"
                :headers="headers"
                :items="credentials"
                :loading="credentialsLoading"
                disable-pagination
                fixed-header
                hide-default-footer
                @click:row="onEditCredential"
            >
                <template v-slot:item.locations="{ item }">
                    <truncating-chips-list :items="item.locations" v-if="item.locations.length > 0" view-only/>
                    <span v-else>N/A</span>
                </template>
                <template v-slot:item.staffGroups="{ item }">
                    <truncating-chips-list :items="item.staffGroups" v-if="item.staffGroups.length > 0" view-only/>
                    <span v-else>N/A</span>
                </template>
            </v-data-table>
        </v-col>
        <save-credential-modal
            v-model="credentialModalValue"
            :credential-id="selectedCredentialId"
            @onSave="onSaveCredential"
            @onDelete="onDeleteCredential"
        />
    </v-row>
</template>

<script>
import TruncatingChipsList from "../../../../../components/common/Lists/TruncatingChipsList";
import SaveCredentialModal from "./SaveCredentialModal";

export default {
    name: "CredentialsTab",
    components: {TruncatingChipsList, SaveCredentialModal},
    props: {
        tab: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            headers: [
                {text: 'Credential Name', value: 'name', width: '15%'},
                {text: 'Applied to Locations', value: 'locations', width: '55%', sortable: false},
                {text: 'Applied in Staff Groups', value: 'staffGroups', width: '30%', sortable: false},
            ],
            credentials: [],
            credentialModalValue: false,
            credentialsLoading: false,
            selectedCredentialId: null,
            api: new formHelper(),
        }
    },
    computed: {
        tableHeight() {
            return window.innerHeight - 320
        },
        hasEditCredentialsPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.REQUIREMENTS.EDIT)
        }
    },
    methods: {
        onAddCredential() {
            if (!this.hasEditCredentialsPermission) {
                return
            }

            this.credentialModalValue = true
            this.selectedCredentialId = null
        },
        onEditCredential(credential) {
            if (!this.hasEditCredentialsPermission) {
                return
            }

            this.credentialModalValue = true
            if (credential && credential._id) {
                this.selectedCredentialId = credential._id
            }
        },
        fetchCredentials() {
            this.credentialsLoading = true
            this.credentials = []
            this.api.get('/requirement/list-for-organization').then(result => {
                if (result && Array.isArray(result.data)) {
                    this.credentials = result.data.map(credential => ({
                        _id: credential._id,
                        name: credential.name,
                        locations: credential.locations.map(loc => loc.name),
                        staffGroups: credential.staff_groups.map(sg => sg.name)
                    }))
                }
            }).catch(console.log).finally(() => this.credentialsLoading = false)
        },
        onSaveCredential() {
            this.fetchCredentials()
        },
        onDeleteCredential() {
            this.fetchCredentials()
        }
    },
    watch: {
        tab: {
            immediate: true,
            handler(tab) {
                if (tab === 2) {
                    this.fetchCredentials()
                }
            }
        }
    },
}
</script>

<style scoped>
#credentials-table :deep(tr:hover) {
    cursor: pointer;
}
</style>
