<template>
    <div class="calendar-details-container">
        <div class="calendar-details-icon">
            <v-icon
                color="#0D0A32"
            >
                {{ icon }}
            </v-icon>
        </div>
        <div class="calendar-details-text">
            <typography
                :text="text"
                :font-size="fontSize"
                :font-weight="fontWeight"
                color="#0D0A32"
            ></typography>
        </div>
    </div>
</template>

<script>
import Typography from '../../../common/Typography/Typography'

export default {
    name: "CalendarDetails",
    components: { Typography },
    props: {
        icon: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        large: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        fontSize () {
            return 16
        },
        fontWeight () {
            return 400
        }
    }
}
</script>

<style scoped>
    .calendar-details-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 7px;
        width: 100%;
        background-color: white;
    }

    .calendar-details-icon {
        display: flex;
        flex-direction: row;
    }

    .calendar-details-text {
        display: flex;
        flex-direction: row;
        min-width: 0;
    }
</style>
