<template>
    <v-container fluid>
        <event-status
            v-if="!shiftTimeUpdating"
            type="primary"
            :status="primaryEventStatus"
            class="my-3" />
        <v-row>
            <v-col v-show="shiftTimeUpdating" style="height:400px">
                <loading-spinner :value="true"></loading-spinner>
            </v-col>
            <v-col v-show="!shiftTimeUpdating">
                <editable-shift-time-section
                    v-show="editMode"
                    :shift="event"
                    ref="editableSection"
                    @editCancelled="disableEditMode"
                ></editable-shift-time-section>

                <v-row v-if="employee" class="ml-5">
                    <event-details-row dusk-selector="eventEmployee" icon="mdi-account" :text="employee" :font-size="24"/>
                </v-row>

                <v-row v-show="!editMode" class="ml-5">
                    <event-details-row dusk-selector="eventDateLabel" icon="mdi-calendar" :text="date" :font-size="22"></event-details-row>
                </v-row>
                <v-row v-show="!editMode" class="ml-5">
                    <event-details-row dusk-selector="eventTimeLabel" icon="mdi-clock-outline" :text="time" :font-size="22"></event-details-row>
                    <v-btn
                        v-if="editableShiftTime && status !== 'pending cancellation' && status !== 'rejected' && authIsAuthorized"
                        @click="enableEditMode"
                        icon
                    >
                        <v-icon style="color: #1B1B1F;">mdi-pencil-outline</v-icon>
                    </v-btn>
                </v-row>
                <v-row class="space ml-5">
                    <event-details-row dusk-selector="eventJobTitleLabel" icon="mdi-clipboard-account" :text="jobTitle" :font-size="22"></event-details-row>
                </v-row>
                <v-row class="ml-5">
                    <event-details-row dusk-selector="eventLocationLabel" icon="mdi-city" :text="location" :font-size="16"></event-details-row>
                    <event-details-row dusk-selector="eventStaffGroupLabel" icon="mdi-account-group" :text="staffGroup" :font-size="16"></event-details-row>
                </v-row>
                <v-row class="ml-5">
                    <event-details-row dusk-selector="eventDepartmentLabel" icon="mdi-domain" :text="department" :font-size="16"></event-details-row>
                    <event-details-row dusk-selector="eventDurationLabel" icon="mdi-timer-outline" :text="duration" :font-size="16"></event-details-row>
                </v-row>
                <v-row class="space ml-5">
                    <event-details-row icon="mdi-map-marker" :text="address" :font-size="16"></event-details-row>
                    <event-details-row icon="mdi-account-supervisor" :text="requester" :font-size="16"></event-details-row>
                </v-row>
            </v-col>
        </v-row>
        <div
            v-if="!shiftTimeUpdating"
            class="d-flex flex-row align-center justify-center"
            style="column-gap: 15px;"
        >
            <event-status
                v-for="(status, index) in secondaryEventStatus"
                :key="index"
                type="secondary"
                :status="status" />
        </div>
    </v-container>
</template>

<script>
import EventDetailsRow from "./EventDetailsRow";
import EditableShiftTimeSection from "./EditableShiftTimeSection";
import LoadingSpinner from "../../loaders/LoadingSpinner";
import EventStatus from "../EventStatus/EventStatus";

export default {
    name: "EventDetails",
    components: {
        LoadingSpinner,
        EditableShiftTimeSection,
        EventDetailsRow,
        EventStatus,
    },
    props: {
        date: {
            type: String,
            default: 'None'
        },
        time: {
            type: String,
            default: 'None'
        },
        jobTitle: {
            type: String,
            default: 'None'
        },
        location: {
            type: String,
            default: 'None'
        },
        staffGroup: {
            type: String,
            default: 'None'
        },
        department: {
            type: String,
            default: 'None'
        },
        duration: {
            type: String,
            default: 'None'
        },
        address: {
            type: String,
            default: 'None'
        },
        requester: {
            type: String,
            default: 'None'
        },
        employee: {
            type: String,
            default: null
        },
        status: {
            type: String,
            default: ''
        },
        noShow: {
            type: Boolean,
            default: false
        },
        callOut: {
            type: Boolean,
            default: false
        },
        timeWasEdited: {
            type: Boolean,
            default: false
        },
        editableShiftTime: {
            type: Boolean,
            default: false
        },
        event: {
            type: Object,
            default: () => {}
        },
        authIsAuthorized: {
            type: Boolean,
            default: false,
        },
        primaryEventStatus: {
            type: String,
            default: ''
        },
        secondaryEventStatus: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            editMode: false,
            shiftTimeUpdating: false
        }
    },
    methods: {
        enableEditMode() {
            this.editMode = true
            this.$refs.editableSection.setProperties()
        },

        disableEditMode() {
            this.editMode = false
        },
    },

    mounted() {
        this.editMode = false
    },

    created() {
        window.EventBus.$on('shift-details-loading-status-change', (value) => {
            this.shiftTimeUpdating = value
        })
        window.EventBus.$on('shift-time-updated', () => {
            this.editMode = false
        })
    }
}
</script>

<style scoped>
    .space {
        margin-bottom: 40px;
    }
</style>
