<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <div class="mb-4 text-center">
                    <img src="/images/login-reg-logo.png" alt="Logo">
                </div>
                <v-card class="elevation-12">
                    <v-alert
                        v-if="login_error"
                        type="error"
                        text
                    >
                        {{ login_error }}
                    </v-alert>
                    <!--<v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>-->
                    <!--<v-card-text>
                        <v-text-field
                            label="Email or Username"
                            prepend-icon="person"
                            type="text"
                            v-model="login"
                            required
                            autofocus
                            :error-messages="api.hasError('login')"
                        >
                        </v-text-field>

                        <v-text-field
                            label="Password"
                            v-model="password"
                            prepend-icon="lock"
                            type="password"
                            required
                            @keyup.enter="postIt"
                        >
                        </v-text-field>

                        <div class="text-center">
                            <p><a class="btn btn-link" href="/password/reset">
                                    Forgot Your Password?
                                </a></p>
                            <p>Don't have an account? <a target="_blank" :href=supportUrl>Learn More</a></p>
                        </div>
                    </v-card-text>-->
                    <v-card-actions>
                        <v-row>
                            <!--<v-col cols="12">
                                <v-btn
                                    block
                                    large
                                    color="primary"
                                    @click="postIt"
                                    :loading="api.busy || api.successful"
                                    :disabled="api.busy || api.successful"
                                >
                                    Login
                                </v-btn>
                            </v-col>-->

                            <v-col cols="12">
                                <v-btn
                                    block
                                    large
                                    color="primary"
                                    href="/login/okta"
                                    class="mt-5 mb-5"
                                >
                                    Login with Okta
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="unsupportedBrowserModal" max-width="600px" persistent>
            <v-card>
                <v-toolbar dark color="primary" elevation="0">
                    <span style="font-size: 20px;">Unsupported browser detected</span>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div style="font-size: 16px; color: black; font-weight: 500;">
                                Koroid has detected that you are using an unsupported browser and cannot guarantee functionality.
                                Please use the latest version of the listed browsers below for the best experience.
                            </div>
                            <v-row class="mb-0 pb-0 mt-3 mb-2">
                                <v-col class="mb-0 pb-0">
                                    <div style="text-align: center; color: black;">
                                        <img src="/images/chrome_64x64.png" alt="Chrome"/>
                                        <p>Google Chrome</p>
                                    </div>
                                </v-col>
                                <v-col class="mb-0 pb-0">
                                    <div style="text-align: center; color: black;">
                                        <img src="/images/firefox_64x64.png" alt="Firefox"/>
                                        <p>Mozilla Firefox</p>
                                    </div>
                                </v-col>
                                <v-col class="mb-0 pb-0">
                                    <div style="text-align: center; color: black;">
                                        <img src="/images/safari_64x64.png" alt="Safari"/>
                                        <p>Safari</p>
                                    </div>
                                </v-col>
                                <v-col class="mb-0 pb-0">
                                    <div style="text-align: center; color: black;">
                                        <img src="/images/edge_64x64.png" alt="Edge"/>
                                        <p>Edge</p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="my-0 py-0">
                        <v-col class="text-right my-0 py-0">
                            <v-btn
                                color="primary"
                                @click="unsupportedBrowserModal = false"
                            >
                                I understand
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { detect } from 'detect-browser';
export default {
    name: 'AuthLogin',
    data() {
        return {
            api: new formHelper(),
            login: null,
            password: null,
            unsupportedBrowserModal: false,
        }
    },
    methods: {
        postIt() {
            this.api.post('/login', {
                login: this.login,
                password: this.password
            })
            .then( ({ data, message }) => {
                if(data.path)
                    window.location = data.path
            })
        }
    },
    computed: {
        login_error() {
            return this.$appData.login_error
        },
        supportUrl() {
            return this.$root.websiteUrl + '/support';
          }
    },
    created() {
        const browser = detect()
        switch (browser && browser.name) {
            case 'chrome':
                break
            case 'firefox':
                break
            case 'edge':
                break
            case 'edge-chromium':
                break
            case 'edge-ios':
                break
            case 'safari':
                break
            default:
                this.unsupportedBrowserModal = true
        }
    }
}
</script>
