<template>
    <div class="d-flex flex-column align-center px-8" style="width: 100%;">
        <div class="d-flex flex-column align-center px-15" style="width: 100%;">
            <p class="text--h1">
                Complete your profile
            </p>
            <p class="text--paragraph" style="margin-bottom: 32px;">
                This information will be used to complete your account in Koroid.
            </p>
            <v-form
                ref="profileForm"
                :lazy-validation="false"
            >
                <v-row>
                    <v-col cols="6" class="pb-0">
                        <v-text-field
                            name="first-name"
                            v-model.trim="profile.first_name"
                            label="First name"
                            :error-messages="api.hasError('first_name')"
                            required
                            outlined
                            :rules="requiredName"
                            autocomplete="off"
                            class="required"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <v-text-field
                            name="last-name"
                            v-model.trim="profile.last_name"
                            label="Last name"
                            :error-messages="api.hasError('last_name')"
                            required
                            outlined
                            :rules="requiredName"
                            autocomplete="off"
                            class="required"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-text-field
                            :key="mobilePhoneKey"
                            name="phone"
                            v-model="phoneNumber"
                            v-mask="{mask: ['+1 (N##) ###-####'], tokens: customTokens}"
                            label="Mobile phone"
                            :rules="mobilePhoneRules"
                            :error-messages="api.hasError('phone')"
                            required
                            outlined
                            autocomplete="off"
                            class="required"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-text-field
                            :key="workPhoneKey"
                            name="work-phone"
                            v-model="profile.work_phone"
                            v-mask="{mask: ['+1 (N##) ###-####'], tokens: customTokens}"
                            label="Work phone"
                            :error-messages="api.hasError('work_phone')"
                            :rules="workPhoneRules"
                            outlined
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-text-field
                            name="primary-address"
                            v-model="profile.primary_address"
                            label="Primary Address"
                            :error-messages="api.hasError('primary_address')"
                            outlined
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-text-field
                            name="secondary-address"
                            v-model.trim="profile.secondary_address"
                            label="Secondary Address"
                            :error-messages="api.hasError('secondary_address')"
                            outlined
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            name="city"
                            v-model.trim="profile.city"
                            label="City"
                            :error-messages="api.hasError('city')"
                            outlined
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-autocomplete
                            name="state"
                            v-model="profile.state"
                            label="State"
                            :items="states"
                            outlined
                            autocomplete="off"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            name="zip-code"
                            v-model.trim="profile.zip_code"
                            label="ZIP Code"
                            outlined
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div style="width: 100%; position: relative;">
            <div class="d-flex flex-row justify-space-between" style="width: 100%; position: absolute; top: 20px;">
                <v-btn
                    color="primary"
                    text
                    :disabled="api.busy"
                    @click="onClickPrevious"
                >
                    Previous
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    :loading="api.busy"
                    @click="onClickNext"
                >
                    Next
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mask} from 'vue-the-mask';

export default {
    name: "CompleteProfile",
    props: {
        user: {
            type: Object,
            default: () => {
            },
        },
    },
    directives: {mask},
    data() {
        return {
            profile: {},
            phoneNumber: null,
            mobilePhoneKey: 30000,
            workPhoneKey: 80000,
            states: this.$config.states,
            customTokens: {
                '#': {pattern: /[0-9]/},
                'N': {pattern: /[2-9]/}
            },
            api: new formHelper(),
        }
    },
    computed: {
        required() {
            return [
                v => !!v && !!v.trim() || 'Required'
            ]
        },
        requiredName() {
            return [
                v => !!v && !!v.trim() || 'Required',
                v => /^[a-zA-Z-']+(\s[a-zA-Z-']+)*\s?$/.test(v) || 'Letters and hyphen only'
            ]
        },
        mobilePhoneRules() {
            return [
                value => {
                    if (value === null || typeof value === 'undefined' || value.trim() === '') {
                        return 'Required'
                    }
                    return this.validateMobilePhoneNumber(value) || 'Mobile phone number is not valid'
                }
            ]
        },
        workPhoneRules() {
            return [
                value => {
                    if (value === null || typeof value === 'undefined' || value.trim() === '') {
                        return true
                    }
                    return this.validateWorkPhoneNumber(value) || 'Work phone number is not valid'
                }
            ]
        },
        workPhoneWatcher() {
            return this.profile.work_phone
        },
    },
    methods: {
        setInitialData(user) {
            this.profile = Object.assign({}, user)
            this.setPhoneNumber()
        },
        setPhoneNumber() {
            if (typeof this.user.phone !== 'undefined' && this.user.phone != null) {
                this.mobilePhoneKey = this.mobilePhoneKey + 1
                this.phoneNumber = this.user.phone
            } else {
                this.phoneNumber = null
            }
        },
        getPhoneNumber() {
            if (this.phoneNumber) {
                return this.parsePhoneNumber(this.phoneNumber)
            }
            return this.phoneNumber
        },
        getWorkPhoneNumber() {
            if (this.profile.work_phone) {
                return this.parsePhoneNumber(this.profile.work_phone)
            }
            return null
        },
        validateMobilePhoneNumber(value) {
            const pattern = /^\+1[2-9]([0-9]{2})([0-9]{3})([0-9]{4})$/;
            let phone = this.parsePhoneNumber(value)
            return !!phone.match(pattern)
        },
        validateWorkPhoneNumber(value) {
            const pattern = /^\+1[2-9]([0-9]{2})([0-9]{3})([0-9]{4})$/;
            let phone = this.parsePhoneNumber(value)
            return !!phone.match(pattern)
        },
        parsePhoneNumber(phoneNumber) {
            if (phoneNumber) {
                return '+' + phoneNumber.replace(/\D/g, '')
            }
            return null
        },
        onClickPrevious() {
            this.$emit('onClickPrevious')
        },
        onClickNext() {
            if (this.$refs.profileForm.validate()) {
                this.api.post('/onboarding/update-owner-profile', {
                    ...this.profile,
                    phone: this.getPhoneNumber(),
                    work_phone: this.getWorkPhoneNumber(),
                }).then(({data}) => {
                    this.$emit('onClickNext', data)
                })
            }
        }
    },
    watch: {
        phoneNumber() {
            this.workPhoneKey = this.workPhoneKey + 1
        },
        workPhoneWatcher() {
            this.mobilePhoneKey = this.mobilePhoneKey + 1
        },
        user(user) {
            this.setInitialData(user)
        }
    },
    mounted() {
        this.setInitialData(this.user)
    }
}
</script>

<style scoped>

</style>
