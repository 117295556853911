<template>
    <v-dialog v-model="model" max-width="750px" persistent scrollable>
        <v-form ref="editPresetForm">
            <v-card>
                <v-toolbar class="informational-modal" elevation="0">
                    <v-toolbar-title>
                        Edit Preset
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text style="height: 600px;">
                    <v-container fluid>
                        <v-row class="mt-1">
                            <v-col>
                                <div class="mb-2 text--h4 black--text">Report Details</div>
                                <v-text-field
                                    v-model="presetName"
                                    label="Preset Name"
                                    :rules="rules.required"
                                    outlined
                                />
                                <slot name="filters"></slot>
                            </v-col>
                            <v-col>
                                <div class="mb-2 text--h4 black--text">Report Data</div>
                                <report-data-filters
                                    v-model="selectedColumns"
                                    :report-type="preset ? preset.type : null"
                                    :preset="preset"
                                    :error="reportDataError"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        :disabled="isSaving"
                        @click="onClose"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        @click="onSave"
                        :loading="isSaving"
                        class="mr-3"
                    >Save</v-btn>
                </v-card-actions>
            </v-card>
            <unsaved-changes-dialog ref="unsavedChangesDialog"/>
        </v-form>
    </v-dialog>
</template>

<script>
import ReportDataFilters from "../../Filters/ReportDataFilters";
import UnsavedChangesDialog from "../../../modals/UnsavedChangesDialog";
import validationRules from "../../../../lib/mixins/validationRules";

export default {
    name: "EditPresetModal",
    components: {  ReportDataFilters, UnsavedChangesDialog },
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => ({})
        },
        filtersBodyParam: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        currentUserId() {
            if(this.$user) {
                return this.$user._id
            }
            return null
        },
        isDirty() {
            const bodyParams = this.getBodyParams()
            if (this.initialFiltersSet && this.initialColumnsSet) {
                return !_.isEqual(this.initialBodyParams.title, bodyParams.title) ||
                    !_.isEqual(this.initialBodyParams.filters, bodyParams.filters) ||
                    !_.isEqual(this.initialBodyParams.columns, bodyParams.columns)
            } else {
                return !_.isEqual(this.initialBodyParams.title, bodyParams.title)
            }
        },
        globalLocation() {
            return this.$root.globalLocation;
        }
    },
    data: () => ({
        presetName: null,
        isSaving: false,
        selectedColumns: [],
        reportDataError: false,
        initialBodyParams: {},
        countFiltersCalled: 0,
        initialFiltersSet: false,
        countColumnsCalled: 0,
        initialColumnsSet: false,
        api: new formHelper()
    }),
    methods: {
        onClose() {
            if (this.isDirty) {
                this.showUnsavedChangesModal()
            } else {
                this.closeModal()
            }
        },
        showUnsavedChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {})
        },
        onSave() {
            this.savePreset()
        },
        savePreset() {
            const reportDataError = Array.isArray(this.selectedColumns) && this.selectedColumns.length === 0
            const reportPresetNameError = !this.$refs.editPresetForm.validate()
            if(reportDataError || reportPresetNameError) {
                if(reportDataError) {
                    this.reportDataError = reportDataError
                }
            } else {
                this.isSaving = true
                this.api.post(`/report-preset/update/${this.preset._id}`, this.getBodyParams())
                    .then(({ data, message }) => {
                        this.$snackNotify('success', 'Report preset updated.')
                        this.closeModal()
                        this.$emit('onUpdatePreset', data.report_preset)
                    }).catch(({ data, message }) => {
                    if(data && data.message === 'Invalid parameters') {
                        this.$snackNotify('warning', 'You must select at least one field.')
                    }
                }).finally(() => {
                    this.isSaving = false
                })
            }
        },
        closeModal() {
            this.model = false
            this.$emit('onCloseModal')
        },
        setInitialData() {
            const { preset } = this
            if(preset) {
                const { title } = preset
                if(title) {
                    this.presetName = title
                }
            }
            this.initialBodyParams = this.getBodyParams()
        },
        getBodyParams() {
            return {
                title: this.presetName,
                type: this.preset.type,
                filters: this.filtersBodyParam,
                columns: this.selectedColumns,
                created_by_id: this.currentUserId
            }
        }
    },
    watch: {
        selectedColumns(columns) {
            if(Array.isArray(columns) && columns.length > 0 && this.reportDataError) {
                this.reportDataError = false
            }
            this.countColumnsCalled = this.countColumnsCalled + 1
            if (this.countColumnsCalled === 2) {
                this.initialColumnsSet = true
                this.initialBodyParams = this.getBodyParams()
            }
        },
        filtersBodyParam() {
            this.countFiltersCalled = this.countFiltersCalled + 1
            if (this.countFiltersCalled === 2) {
                this.initialFiltersSet = true
                this.initialBodyParams = this.getBodyParams()
            }
        }
    },
    created() {
        this.setInitialData()
    }
}
</script>

<style scoped>

</style>
