<template>
    <div class="calendar-text-event-container">
        <div class="calendar-text-event-times-container">
            <div class="calendar-text-event--label">
                {{ startTime }}
            </div>
            <div class="calendar-text-event-second-times-container">
                <div class="calendar-text-event--label">
                    -
                </div>
                <div class="calendar-text-event--label">
                    {{ endTime }}
                </div>
            </div>
        </div>
        <div class="calendar-text-event-divider-container">
            <div class="calendar-text-event-divider"></div>
        </div>
        <div class="calendar-text-event-details-container">
            <div class="calendar-text-event-progress-bar">
                <v-progress-linear
                    :value="progressBarValue"
                    :color="progressBarColor"
                    background-color="white"
                    height="5"
                    rounded
                ></v-progress-linear>
            </div>
            <div class="calendar-text-event-details">
                {{ filledCount }}/{{ totalCount }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextEvent",
    props: {
        event: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        globalTimezone () {
            return this.$root.globalTimezone
        },
        startTime () {
            return moment(this.event.start).tz(this.globalTimezone).format('h:mm')
        },
        endTime () {
            return moment(this.event.end).tz(this.globalTimezone).format('h:mm')
        },
        filledCount () {
            return this.event.filled_shifts_count
        },
        totalCount () {
            return this.event.total_shifts_count
        },
        progressBarValue () {
            return Math.ceil(this.filledCount / this.totalCount * 100)
        },
        progressBarColor () {
            return this.filledCount === this.totalCount ? '#3EAA41' : '#DF6F49'
        },
    }
}
</script>

<style scoped>
    .calendar-text-event-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .calendar-text-event-times-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 2px;
        font-size: 14px;
        flex: 1 0 auto;
    }

    .calendar-text-event-second-times-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .calendar-text-event-divider-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 5px;
    }

    .calendar-text-event-divider {
        border-top: 1px solid white;
        width: 100%;
    }

    .calendar-text-event-details-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 2px;
        min-width: 0;
        flex: 1 0 auto;
    }

    .calendar-text-event-progress-bar {
        flex: 1 0 auto;
    }

    .calendar-text-event-details {
        font-size: 12px;
        line-height: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .calendar-text-event--label {
        line-height: 12px;
    }
</style>
