<template>
    <div class="user-employment-type-container">
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="ds-modal-input-container d-flex justify-space-between">
                    <p class="text--h3">
                        Employment
                    </p>
                    <v-btn
                        color="primary"
                        text
                        :disabled="employmentTypesLoading || !prevEmploymentType"
                        @click="onViewEmploymentType"
                    >
                        Details
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="ds-modal-input-container">
                    <v-autocomplete
                        :key="employmentTypeKey"
                        v-model="employmentType"
                        :items="employmentTypes"
                        :loading="employmentTypesLoading"
                        :readonly="!canEdit"
                        :disabled="isSavingEmploymentType"
                        item-value="_id"
                        item-text="name"
                        :clearable="canEdit"
                        label="Employment Type"
                        return-object
                        outlined
                    >
                        <template v-slot:append>
                            <v-slide-y-reverse-transition>
                                <v-btn
                                    v-if="isDirty"
                                    icon
                                    small
                                    color="primary"
                                    class="employment-type-save-btn"
                                    :loading="isSavingEmploymentType"
                                    @click="onClickSave"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </v-slide-y-reverse-transition>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="ds-modal-input-container">
                    <v-text-field
                        :value="getPayrollPeriod(employmentType?.payroll_period)"
                        label="Payroll Period"
                        disabled
                        :loading="employmentTypesLoading"
                        outlined
                    />
                </v-col>
                <v-col class="ds-modal-input-container">
                    <v-text-field
                        :value="getTimeStringFromMinutes(employmentType?.max_work_hours)"
                        label="Max Hours"
                        disabled
                        :loading="employmentTypesLoading"
                        hint="HH:MM"
                        persistent-hint
                        outlined
                    />
                </v-col>
                <v-col class="ds-modal-input-container">
                    <v-text-field
                        :value="getTimeStringFromMinutes(employmentType?.overtime_hours)"
                        label="Overtime"
                        disabled
                        :loading="employmentTypesLoading"
                        hint="HH:MM"
                        persistent-hint
                        outlined
                    />
                </v-col>
                <v-col class="ds-modal-input-container">
                    <v-text-field
                        :value="getWorkingHours(employmentType?.work_schedule_config)"
                        label="Working Hours"
                        disabled
                        :loading="employmentTypesLoading"
                        outlined
                    />
                </v-col>
            </v-row>
        </v-container>
        <employment-type-in-use-modal
            v-model="changingEmploymentTypeModalValue"
            :user-id="userId"
            :employment-type-id="employmentType?._id || ''"
            @onSave="onSave"
        />
        <save-employment-type-modal
            v-if="employmentTypeModalValue"
            v-model="employmentTypeModalValue"
            :employment-type-id="employmentType?._id"
            readonly
        />
    </div>
</template>

<script>
import EmploymentTypeInUseModal from "./ChangeEmploymentTypeModal";
import SaveEmploymentTypeModal
    from "../../../pages/Organization/ConfigurationOptions/components/EmploymentTypes/SaveEmploymentTypeModal";


export default {
    name: "UserEmploymentType",
    components: {EmploymentTypeInUseModal, SaveEmploymentTypeModal},
    props: {
        userId: {
            type: String,
            default: null,
        },
        userEmploymentType: {
            type: Object,
            default: () => ({}),
        },
        employmentTypes: {
            type: Array,
            default: () => ([])
        },
        employmentTypesLoading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        employmentTypeKey: 8888,
        prevEmploymentType: null,
        employmentType: null,
        isSavingEmploymentType: false,
        changingEmploymentTypeModalValue: false,
        employmentTypeModalValue: false,
        api: new formHelper(),
    }),
    computed: {
        isDirty() {
            return !_.isEqual(this.prevEmploymentType, this.employmentType)
        },
        canEdit() {
            return this.$userHasPermission(this.$user, this.$config.permissions.USERS.EDIT_OTHERS_JOBS)
        }
    },
    methods: {
        onClickSave() {
            if (!this.prevEmploymentType && this.employmentType) {
                this.saveEmploymentType()
            } else {
                this.changingEmploymentTypeModalValue = true
                this.employmentTypeKey = this.employmentTypeKey + 1
            }
        },
        onSave() {
            this.employmentTypeKey = this.employmentTypeKey + 1
            this.prevEmploymentType = this.employmentType ? {...this.employmentType} : null
            this.changingEmploymentTypeModalValue = false
        },
        saveEmploymentType() {
            this.employmentTypeKey = this.employmentTypeKey + 1
            this.isSavingEmploymentType = true
            this.api.post(`/user/save-staff-type/${this.userId}`, {
                staff_type_id: this.employmentType?._id,
            })
                .then(() => {
                    this.$snackNotify('success', 'Employment updated')
                    this.onSave()
                })
                .catch(() => {
                    this.$snackNotify('warning', 'Unable to save changes to employment type. Please try again.')
                })
                .finally(() => {
                    this.isSavingEmploymentType = false
                })
        },
        getTimeStringFromMinutes(minutes) {
            if (typeof minutes === 'number') {
                return this.$options.filters.timeStringFromMinutes(minutes)
            }
            return null
        },
        getPayrollPeriod(payrollPeriod) {
            if (payrollPeriod) {
                const parts = payrollPeriod.split('_')
                return parts.map(this.$options.filters.capitalize).join('-')
            }
            return null
        },
        getWorkingHours(workingHours) {
            if (workingHours) {
                if (workingHours === 'staff_type_defined') {
                    return 'Assigned'
                } else if (workingHours === 'user_defined') {
                    return 'Submitted'
                } else {
                    return 'None'
                }
            }
            return null
        },
        onViewEmploymentType() {
            this.employmentTypeModalValue = true
        }
    },
    watch: {
        userEmploymentType: {
            immediate: true,
            handler(userEmploymentType) {
                if (userEmploymentType) {
                    this.prevEmploymentType = {...userEmploymentType}
                    this.employmentType = {...userEmploymentType}
                } else {
                    this.prevEmploymentType = null
                    this.employmentType = null
                }
            }
        }
    }
}
</script>

<style scoped>
.user-employment-type-container {
    border: 2px solid #777680;
    border-radius: 12px;
    padding: 16px !important;
    margin-bottom: 30px;
}

.employment-type-save-btn {
    width: 36px !important;
    height: 36px !important;
    margin-top: -6px;
}
</style>
