<template>
    <v-card :class="containerClass" :style="containerStyle" v-show="shown" :height="maxHeight < 560 ? maxHeight : 560" :elevation="2">
        <report-data-filters
            v-model="selectedColumns"
            :report-type="reportType"
            :preset="preset"
            :error="error"
            :readonly="readonly"
            :preselect-based-on-preset="preselectBasedOnPreset"
            offset-x
            @onDataLoading="onDataLoading"
            @onDataFetched="onDataFetched"
        />
    </v-card>
</template>

<script>
import ReportDataFilters from "../Filters/ReportDataFilters";

export default {
    name: "ReportData",
    components: { ReportDataFilters },
    props: {
        reportType: {
            type: String,
            default: null,
        },
        preset: {
            type: Object,
            default: () => null,
        },
        value: {
            type: Array,
            default: () => []
        },
        error: {
            type: Boolean,
            default: false,
        },
        preselectBasedOnPreset: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        shown: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        selectedColumns: [],
        loading: false,
    }),
    computed: {
        containerClass() {
            return {
                'report-data-container': true,
                'readonly-container': this.readonly,
                'report-data-loading-container': this.loading,
                'scrollbox': true,
                'rounded-card': true
            }
        },
        containerStyle() {
            if(this.error) {
                return {
                    border: '1px solid red'
                }
            }
            return {}
        },
        maxHeight() {
            return window.innerHeight - 288
        }
    },
    methods: {
        onDataLoading() {
            this.loading = true
            this.$emit('onDataLoading')
        },
        onDataFetched() {
            this.loading = false
            this.$emit('onDataFetched')
        }
    },
    watch: {
        selectedColumns: {
            immediate: true,
            handler(columns) {
                this.$emit('input', columns)
            }
        }
    }
}
</script>

<style scoped>
    .report-data-container {
        padding: 12px 0;
    }
    .report-data-loading-container {
        height: 100%;
    }
    .readonly-container {
        background-color: #DCDCDC;
    }
    .scrollbox {
        overflow: auto;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
    .rounded-card {
        border-radius: 12px !important;
    }
</style>
