<template>
    <v-container fluid class="location-view-container">
        <v-row>
            <div class="location-view-vertical-separator" v-if="innerWidth >= 1400"></div>
            <v-col md="6" cols="12">
                <slot name="left-location-view"></slot>
            </v-col>
            <v-col md="6" cols="12">
                <slot name="right-location-view"></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "LocationView",
    data: () => ({
        innerWidth: window.innerWidth - 64,
    }),
    methods: {
        onResize() {
            this.innerWidth = window.innerWidth - 64
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
    .location-view-container {
        background-color: #FFFFFF;
        border: 2px solid #777680;
        border-radius: 12px;
        padding: 16px !important;
    }
    .location-view-vertical-separator {
        position: absolute;
        left: 50%;
        margin-top: 100px;
        border-left: 2px solid black;
        height: 310px;
    }
</style>
