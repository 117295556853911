<template>
    <v-container fluid>
        <user-employment-type
            ref="userEmploymentType"
            :user-id="user._id"
            :user-employment-type="userEmploymentType"
            :employment-types="employmentTypes"
            :employment-types-loading="isLoadingData"
        />
        <user-jobs
            ref="userJobs"
            :user-id="user._id"
            :user-locations="userLocations"
            :jobs="userJobs"
            :locations-loading="isLoadingData"
            @onSaveJobTitle="onSaveJobTitle"
            @onDeleteJobTitle="onDeleteJobTitle"
        />
        <user-credentials
            ref="userCredentials"
            :user="user"
            :credentials="credentials"
            :credentials-loading="isLoadingData"
            @onSaveCredentials="onSaveCredentials"
        />
        <v-row v-if="newUser">
            <v-col cols="12" class="d-flex justify-space-between mt-5">
                <v-btn
                    color="primary"
                    text
                    @click="onClickPrevious"
                >
                    Previous
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    @click="onClickNext"
                >
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserEmploymentType from "./UserEmploymentType";
import UserJobs from "./UserJobs";
import UserCredentials from "./UserCredentials";

export default {
    name: "UserJobsTab",
    components: {UserEmploymentType, UserJobs, UserCredentials},
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        newUser: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        userEmploymentType: null,
        employmentTypes: [],
        userLocations: [],
        userJobs: [],
        credentials: [],
        isLoadingData: false,
        api: new formHelper()
    }),
    computed: {
        isEmploymentTypeDirty() {
            return Boolean(this.$refs?.userEmploymentType?.isDirty)
        },
        isUserJobsDirty() {
            return Boolean(this.$refs?.userJobs?.isDirty)
        },
        isUserCredentialsDirty() {
            return Boolean(this.$refs?.userCredentials.isDirty)
        }
    },
    methods: {
        onSaveJobTitle(jobs) {
            if (Array.isArray(jobs)) {
                this.$emit('user:saved', {
                    ...this.user,
                    jobs,
                })
            }
        },
        onDeleteJobTitle(jobs) {
            if (Array.isArray(jobs)) {
                this.$emit('user:saved', {
                    ...this.user,
                    jobs,
                })
            }
        },
        onSaveCredentials(credentials) {
            if (Array.isArray(credentials)) {
                this.$emit('user:saved', {
                    ...this.user,
                    requirements: credentials,
                })
            }
        },
        isDirty() {
            return this.isEmploymentTypeDirty || this.isUserJobsDirty || this.isUserCredentialsDirty
        },
        onClickPrevious() {
            this.$emit('onPreviousTab')
        },
        onClickNext() {
            this.$emit('onNextTab')
        }
    },
    created() {
        this.isLoadingData = true
        this.api.get(`/user-profile/jobs-tab-data/${this.user._id}`)
            .then(({data}) => {
                if (data) {
                    const {locations_data, user_jobs, requirements, staff_type, staff_types} = data

                    if (Array.isArray(locations_data)) {
                        this.userLocations = Array.from(locations_data)
                    }

                    if (Array.isArray(user_jobs)) {
                        this.userJobs = Array.from(user_jobs)
                    }

                    if (Array.isArray(requirements)) {
                        this.credentials = Array.from(requirements)
                    }

                    if (Array.isArray(staff_type) && staff_type.length > 0) {
                        this.userEmploymentType = staff_type[0]
                    }

                    if (Array.isArray(staff_types)) {
                        this.employmentTypes = Array.from(staff_types)
                    }
                }
            })
            .finally(() => {
                this.isLoadingData = false
            })
    }
}
</script>

<style scoped>

</style>
