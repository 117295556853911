<template>
    <v-dialog v-model="modalValue" max-width="500" scrollable>
        <v-card>
            <v-toolbar
                class="informational-modal"
                elevation="0"
            >
                <v-toolbar-title class="text--h2">Reactivate Shifts</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="onCancel" :disabled="isActivating">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="activationModalFormRef">
                    <v-row class="mt-4">
                        <v-col cols="12" class="ds-modal-input-container">
                            <time-picker-dialog
                                v-model="startDate"
                                :text-field-props="startDateTextFieldProps"
                                required
                                min-rule
                                max-rule
                                is-date-picker
                                :allowed-dates="allowedStartDates"
                            ></time-picker-dialog>
                        </v-col>
                        <v-col cols="12" class="ds-modal-input-container">
                            <time-picker-dialog
                                v-model="runDate"
                                :text-field-props="runDateTextFieldProps"
                                required
                                min-rule
                                max-rule
                                is-date-picker
                                :allowed-dates="allowedRunDates"
                            ></time-picker-dialog>
                        </v-col>
                        <v-col cols="12">
                            <div>
                                <v-icon color="primary">mdi-calendar</v-icon>
                                <span class="text--h5 ml-1">Next Run</span>
                                <div class="text--h6 black--text" style="margin-left: 33px;">
                                    {{ nextRun }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div>
                                <v-icon color="primary">mdi-calendar-refresh</v-icon>
                                <span class="text--h5 ml-1">Shifts Span</span>
                                <div class="text--h6 black--text" style="margin-left: 33px;">
                                    {{ shiftsSpan }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div>
                                <v-icon color="primary">mdi-earth</v-icon>
                                <span class="text--h5 ml-1">Timezone</span>
                                <div class="text--h6 black--text" style="margin-left: 33px;">
                                    {{ timezone }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn text color="primary" @click="onCancel" :disabled="isActivating">
                            No
                        </v-btn>
                        <v-btn class="ml-2" color="primary" outlined @click="onConfirm" :loading="isActivating">
                            Yes
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TimePickerDialog from "../../components/modals/TimePickerDialog";

export default {
    name: "ActivationModal",
    components: {TimePickerDialog},
    data: () => ({
        startDate: null,
        runDate: null,
        isActivating: false,
        api: new formHelper()
    }),
    props: {
        value: {
            type: Boolean,
            default: false
        },
        staffingAutomation: {
            type: Object,
            default: () => ({})
        },
        minStartDate: {
            type: String,
            default: null
        },
        dayOfWeek: {
            type: Number,
            default: null
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        activationModalFormRef() {
            return this.$refs.activationModalFormRef
        },
        startDateTextFieldProps() {
            let value = null
            if (this.startDate) {
                const endMoment = this.genMoment(this.startDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'Start Date',
                value,
                outlined: true,
            }
        },
        runDateTextFieldProps() {
            let value = null
            if (this.runDate) {
                const endMoment = this.genMoment(this.runDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'Run Date',
                value,
                outlined: true,
            }
        },
        nextRun() {
            const nextRunDate = this.staffingAutomation?.run_date
            if (nextRunDate) {
                if (this.globalTimezone) {
                    return moment.tz(nextRunDate, this.globalTimezone).format('MM/DD/YYYY')
                }
                return moment(nextRunDate).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        shiftsSpan() {
            const coverageWeeks = this.staffingAutomation?.coverage_weeks || 0
            return `${coverageWeeks} Weeks`
        },
        timezone() {
            const timezone = this.staffingAutomation?.timezone
            if (timezone) {
                return moment.tz(timezone).format('z Z')
            }
            return 'N/A'
        },
        allowedStartDates() {
            return date => {
                const now = moment()
                const startDate = this.genMoment(date)
                const minStartDate = moment(this.minStartDate).tz(this.staffingAutomation?.timezone)
                const minStartDateCondition = startDate.isSameOrAfter(minStartDate, 'days')
                const maxThreeYearsOut = startDate.isBefore(now.add(3, 'years'), 'days')
                const isSameDayOfWeek = startDate.day() === this.dayOfWeek
                if (this.runDate) {
                    const runDate = this.genMoment(this.runDate)
                    return minStartDateCondition && maxThreeYearsOut && isSameDayOfWeek && runDate.isSameOrBefore(startDate.subtract(2, 'days'), 'days')
                }
                return minStartDateCondition && maxThreeYearsOut && isSameDayOfWeek
            }
        },
        allowedRunDates() {
            return date => {
                const now = moment()
                const runDate = this.genMoment(date)
                const minStartDate = moment(this.minStartDate).tz(this.staffingAutomation?.timezone)
                const minRunDateCondition = runDate.isSameOrAfter(minStartDate.clone().subtract(2, 'days'), 'days')
                const maxThreeYearsOut = runDate.isBefore(now.add(3, 'years'), 'days')
                if (this.startDate) {
                    const startDate = this.genMoment(this.startDate)
                    return minRunDateCondition && maxThreeYearsOut && runDate.isSameOrBefore(startDate.subtract(2, 'days'), 'days')
                }
                return minRunDateCondition && maxThreeYearsOut
            }
        }
    },
    methods: {
        onActivate() {
            this.isActivating = true
            this.api.post(`/staffing-automation/activate/${this.staffingAutomation._id}`, {
                shift_start: this.startDate,
                run_date: this.runDate,
                timezone: this.staffingAutomation.timezone
            }).then(({data, message}) => {
                message('Staffing Automation Activated')
                this.modalValue = false
                this.$emit('onActivate', data)
            }).catch(console.log).finally(() => {
                this.isActivating = false
            })
        },
        genMoment(date) {
            return moment(date, 'YYYY-MM-DD')
        },
        onCancel() {
            this.modalValue = false
            this.$emit('onCancel')
        },
        onConfirm() {
            if (this.activationModalFormRef?.validate()) {
                this.onActivate()
            }
        },
    }
}
</script>

<style scoped>

</style>
