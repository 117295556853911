<template>
    <v-card-title class="department-card-title-container">
        <div class="d-flex flex-row justify-space-between flex-grow-1">
            <span class="text--h3" style="padding-top: 7px;">{{ title }}</span>
            <v-btn color="primary_text" icon elevation="2" :disabled="disabled" @click="$emit('onClick')">
                <v-icon class="department-card-list-icon">
                    mdi-view-list
                </v-icon>
            </v-btn>
        </div>
    </v-card-title>
</template>

<script>
export default {
    name: "DepartmentCardTitle",
    props: {
        title: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
    .department-card-title-container {
        padding-bottom: 0;
    }

    .department-card-list-icon {
        transform: rotate(180deg);
    }
</style>
