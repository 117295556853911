<template>
    <v-form
        dusk="userProfileForm"
        ref="profileForm"
        v-model="valid"
        :lazy-validation="false"
    >
        <v-container fluid>
            <v-row class="ma-0 pa-0 edit-row">
                <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-row no-gutters>
                        <v-col class="text-left d-flex justify-start align-center">
                            <v-btn
                                v-if="canImpersonate"
                                icon
                                elevation="2"
                                color="primary_text"
                                id="impersonate-button"
                                :href="'/impersonate/' + user._id"
                                :title="'Log in as ' + user.first_name + ' ' + user.last_name "
                            >
                                <v-icon>mdi-account-settings</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-row no-gutters>
                        <v-col class="text-right d-flex justify-end align-center">
                            <v-btn
                                title="Update data from Okta profile"
                                id="okta-sync-button"
                                v-if="canEdit && !this.newUser"
                                color="primary_text"
                                @click="onOktaSync"
                                :loading="api.busy"
                                icon
                                elevation="2"
                            >
                                <v-icon>mdi-sync</v-icon>
                            </v-btn>
                            <div class="ml-2 pl-2" style="color: #000000;">
                                Last Sync: {{ okta_sync_timestamp }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col></v-col>
                        <v-col>
                            <custom-avatar
                                class="text-center"
                                :editable="true"
                                :user="profile"
                                :size="198"
                                @avatarUpdated="avatarUpdated"
                                @avatarRemoved="avatarRemoved"
                            ></custom-avatar>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-autocomplete
                        dusk="roleDropdown"
                        :items="availableRoles"
                        :readonly="!canEdit || disabledRoleSection"
                        v-model="selectedRole"
                        item-value="_id"
                        item-text="name"
                        label="Role"
                        class="avatar-picker-input mt-14"
                        @change="onRoleChange"
                        :class="{required: newUser}"
                        outlined
                    >
                    </v-autocomplete>
                    <v-row v-if="showLocations">
                        <v-col cols="12">
                            <v-autocomplete
                                chips
                                small-chips
                                deletable-chips
                                outlined
                                dusk="locationsDropdown"
                                :items="selectableLocations"
                                :readonly="!canEdit || disabledRoleSection"
                                v-model="selectedLocations"
                                multiple
                                :disabled="locationsLoading || managerStaffingDetailsLocationsLoading || disabledLocations"
                                :loading="locationsLoading || managerStaffingDetailsLocationsLoading"
                                :rules="requiredLocations"
                                item-value="_id"
                                item-text="name"
                                required
                                label="Locations"
                                class="below-avatar-picker-input"
                                :class="{required: newUser && !disabledLocations}"
                                @change="filterCustomFieldsBySelectedLocations"
                            >
                                <template v-slot:selection="{ item, attrs, selected, select }">
                                    <v-chip
                                        v-bind="attrs"
                                        :input-value="selected"
                                        :close="!item.disabled"
                                        @click="select"
                                        @click:close="removeSelectedLocation(item)"
                                    >
                                        {{ item.name }}
                                        <v-tooltip v-if="item.disabled" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-1"
                                                    small
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ item.disabled_reason ?? "You are not allowed to make changes to this location" }}</span>
                                        </v-tooltip>
                                    </v-chip>
                                </template>

                                <template v-slot:item="{ on, attrs, item }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="attrs.inputValue"></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="item.disabled" class="autocomplete-item-disabled-subtitle">
                                                <v-icon
                                                    class="ml-1"
                                                    small
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                                <span>{{ item.disabled_reason ?? "You are not allowed to make changes to this location" }}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" style="padding-top: 28px;">
                    <v-text-field
                        name="first-name"
                        v-model.trim="profile.first_name"
                        label="First name"
                        :error-messages="api.hasError('first_name')"
                        :readonly="!newUserFirstStepOnly"
                        required
                        :rules="rules.requiredName"
                        autocomplete="off"
                        :class="{required: newUser}"
                        outlined
                    >
                    </v-text-field>
                    <v-text-field
                        name="last-name"
                        v-model.trim="profile.last_name"
                        label="Last name"
                        :error-messages="api.hasError('last_name')"
                        :readonly="!newUserFirstStepOnly"
                        required
                        :rules="rules.requiredName"
                        autocomplete="off"
                        :class="{required: newUser}"
                        outlined
                    >
                    </v-text-field>
                    <v-text-field
                        name="username"
                        v-model.trim="profile.username"
                        :label="usernameLabel + ' (Used for login)'"
                        :error-messages="api.hasError('username')"
                        :readonly="!newUserFirstStepOnly"
                        :loading="usernameSettingsLoading"
                        :rules="rules.required"
                        autocomplete="off"
                        :class="{required: newUser}"
                        outlined
                    >
                    </v-text-field>
                    <v-row v-if="newUserFirstStepOnly" no-gutters>
                        <v-col class="pr-md-5">
                            <v-autocomplete
                                label="Email Domain"
                                dusk="emailDomain"
                                v-model="selectedEmailDomain"
                                :items="emailDomains"
                                :rules="rules.required"
                                :class="{required: newUser}"
                                :readonly="!canEdit"
                                required
                                outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Email"
                                name="email"
                                v-model.trim="email"
                                required
                                :rules="rules.required"
                                :readonly="!canEdit"
                                :error-messages="api.hasError('email')"
                                :disabled="!selectedEmailDomain"
                                :suffix="selectedEmailDomain"
                                autocomplete="off"
                                @change="lowercaseEmail"
                                :class="{required: newUser}"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-if="!newUserFirstStepOnly"
                        name="email"
                        v-model.trim="profile.email"
                        label="Email"
                        required
                        readonly
                        :rules="rules.required"
                        :error-messages="api.hasError('email')"
                        autocomplete="off"
                        :class="{required: newUser}"
                        outlined
                    >
                    </v-text-field>
                    <v-row class="my-0 py-0">
                        <v-col class="my-0 py-0">
                            <v-text-field
                                :key="mobilePhoneKey"
                                name="phone"
                                v-model="phoneNumber"
                                v-mask="{mask: ['+1 (N##) ###-####'], tokens: customTokens}"
                                label="Mobile phone"
                                :rules="mobilePhoneRules"
                                :error-messages="api.hasError('phone')"
                                :readonly="!canEdit"
                                autocomplete="off"
                                outlined
                                validate-on-blur
                            >
                            </v-text-field>
                        </v-col>
                        <v-col class="my-0 py-0">
                            <v-text-field
                                :key="workPhoneKey"
                                name="work-phone"
                                v-model="profile.work_phone"
                                v-mask="{mask: ['+1 (N##) ###-####'], tokens: customTokens}"
                                label="Work phone"
                                :error-messages="api.hasError('work_phone')"
                                :rules="workPhoneRules"
                                :readonly="!canEdit"
                                autocomplete="off"
                                outlined
                                validate-on-blur
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-7">
                <v-col cols="12" md="6" class="pb-md-0">
                    <v-text-field
                        name="primary-address"
                        v-model="profile.primary_address"
                        label="Primary Address"
                        :error-messages="api.hasError('primary_address')"
                        :readonly="!canEdit"
                        autocomplete="off"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-md-0">
                    <v-text-field
                        name="secondary-address"
                        v-model.trim="profile.secondary_address"
                        label="Secondary Address"
                        :error-messages="api.hasError('secondary_address')"
                        :readonly="!canEdit"
                        autocomplete="off"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-md-0">
                    <v-text-field
                        name="city"
                        v-model.trim="profile.city"
                        label="City"
                        :error-messages="api.hasError('city')"
                        :readonly="!canEdit"
                        autocomplete="off"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-md-0">
                    <v-autocomplete
                        dusk="stateAutocomplete"
                        name="state"
                        v-model="profile.state"
                        label="State"
                        :readonly="!canEdit"
                        :items="states"
                        autocomplete="off"
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="pt-md-0">
                    <v-text-field
                        name="zip-code"
                        v-model.trim="profile.zip_code"
                        :readonly="!canEdit"
                        label="ZIP Code"
                        autocomplete="off"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-divider v-if="customFields.length || customFieldsLoading"></v-divider>

            <input type="hidden" name="no-custom-fields" :value="!customFields.length" />

            <v-row v-if="customFields.length && !customFieldsLoading" class="pt-7">
                <v-col v-for="(custom_field, index) in customFields" cols="12" md="6"  :key="custom_field._id">
                    <v-text-field
                        name="custom-field"
                        :label="custom_field.name"
                        v-model="custom_field.value"
                        :readonly="!canEdit"
                        autocomplete="off"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="customFieldsLoading">
                <v-col class="text-center pt-4">
                    <h5 :style="{ color: themePrimaryColor }">CUSTOM FIELDS LOADING</h5>
                    <v-progress-circular indeterminate size="50" color="primary" class="mt-3 mb-3"></v-progress-circular>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-left d-flex align-center">
                    <v-switch
                        :label="activity_status_label"
                        :loading="api.busy"
                        :disabled="api.busy"
                        :readonly="isOwnProfile || !canEdit"
                        v-model="status"
                        true-value="active"
                        false-value="inactive"
                        :color="userActiveColor"
                        :class="userActiveClass"
                        inset
                        @change="onStatusChange"
                        dusk="statusToggleButton"
                    ></v-switch>
                    <span class="ml-4">
                        <span>Since: {{ status_changed_timestamp }}</span>
                        <v-tooltip content-class="koroid-tooltip" :open-on-hover="false" right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    @click="on.click"
                                    @blur="on.blur"
                                    retain-focus-on-click
                                >
                                    <v-icon color="primary_text">mdi-alert-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span v-if="selected_role_name === 'Staff'">
                                <strong>Active</strong> Staff can be assigned to and offered shifts. They will receive
                                notifications and have full access to Koroid.
                                <br><br>
                                <strong>Inactive</strong> Staff will no longer appear when assigning or offering shifts.
                                They will not receive notifications but will have view-only access to their Profile and
                                Shifts pages.
                            </span>
                            <span v-if="selected_role_name === 'Manager'">
                                <strong>Active</strong> Managers can create, assign and offer shifts. They will receive
                                notifications and have full access to Koroid.
                                <br><br>
                                <strong>Inactive</strong> Managers will no longer have access to any scheduling.
                                They will not receive notifications but will maintain view-only access to the
                                Schedules, Users, Profile and Staffing Demand pages.
                            </span>
                            <span v-if="selected_role_name === 'Administrator'">
                                <strong>Active</strong> Administrators can manage departments and run reports, etc. They
                                will receive notifications and have full access to Koroid.
                                <br><br>
                                <strong>Inactive</strong> Administrators will no longer have access to any scheduling or
                                reports and can not edit or add Departments or Staff Groups. They will not receive
                                notifications but will maintain view-only access to their assigned pages.
                            </span>
                            <span v-if="selected_role_name === 'Owner'">
                                <strong>Active</strong> Owners have full access to Koroid.
                                <br><br>
                                <strong>Inactive</strong> Owners will no longer have access to any actions within Koroid.
                                They will not receive notifications however will maintain view-only access based on their
                                assigned pages.
                            </span>
                        </v-tooltip>
                    </span>
                </v-col>
                <v-col class="text-right">
                    <v-btn
                        name="delete-user"
                        v-if="canDelete"
                        color="error"
                        class="mr-2"
                        outlined
                        @click="showUserDeleteModal = true"
                        :disabled="api.busy"
                    >
                        Delete User
                    </v-btn>
                    <v-btn
                        dusk="profileNextButton"
                        v-if="newUser && !userHasAllPermissions"
                        color="primary"
                        outlined
                        @click="onSaveProfile"
                        :disabled="updatingUserProfile"
                        :loading="updatingUserProfile">
                        Next
                    </v-btn>
                    <v-btn
                        dusk="saveProfileButton"
                        v-else
                        color="secondary"
                        @click="onSaveProfile"
                        :disabled="!canEdit || updatingUserProfile"
                        :loading="updatingUserProfile">
                        Save
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="changeRoleDialog" persistent max-width="400">
                <v-card dusk="roleChangeDialog">
                    <v-toolbar
                        class="informational-modal"
                    >
                        <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                        <v-toolbar-title>Change role</v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col cols="12" style="font-size: 16px;" class="pt-5 px-5">
                                You are about to change this user's role. Do you want to save your changes ?
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                    dusk="cancelRoleChangeButton"
                                    color="primary"
                                    text
                                    :disabled="updatingUserProfile"
                                    @click="onCancelRoleChange">
                                    No
                                </v-btn>
                                <v-btn
                                    dusk="confirmRoleChangeButton"
                                    color="primary"
                                    outlined
                                    :disabled="updatingUserProfile"
                                    :loading="updatingUserProfile"
                                    @click="onSaveProfile">
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
            <v-dialog v-model="userIsManagerDialog" persistent max-width="500">
                <v-card>
                    <v-toolbar
                        class="warning-modal"
                    >
                        <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                        <v-toolbar-title>Can't change user role</v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col cols="12" style="font-size: 16px;" class="pt-5 px-5">
                                <p>
                                    This person is still a manager in the following departments:
                                </p>
                                <ul>
                                    <li v-for="department in managedDepartments">
                                        <b>{{ department.department_name }}</b>
                                    </li>
                                </ul>
                                <p class="mt-2">
                                    You must remove them from all of the departments before changing their user role.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-right">
                                <v-btn
                                    color="primary"
                                    text
                                    @click="onCancelRoleChange">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    outlined
                                    href="/departments">
                                    View departments
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>

            <v-dialog v-model="userHasShiftsDialog" persistent max-width="500">
                <v-card>
                    <v-toolbar
                        class="warning-modal"
                        elevation="0"
                    >
                        <v-icon color="primary" class="mr-3">mdi-alert</v-icon>
                        <v-toolbar-title class="text--h2">User Has Assigned Shifts</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="mt-6">
                        <p class="black--text text--paragraph">
                            This user can not be made inactive because they are assigned to upcoming shifts.
                        </p>
                        <p class="black--text text--paragraph mt-2">
                            They must complete these shifts, or be removed by a Manager, before they can be made inactive.
                        </p>
                    </v-card-text>

                    <v-card-actions>
                        <v-row no-gutters>
                            <v-col class="text-right">
                                <v-btn class="mr-2" text color="primary" @click="userHasShiftsDialog = false">Close</v-btn>
                                <v-btn class="ml-2" outlined color="primary" href="/schedules">View schedule ></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="showUserDeleteModal" persistent max-width="500">
                <v-card>
                    <v-toolbar
                        dark
                        color="error"
                        elevation="0"
                    >
                        <v-icon color="white" class="mr-3">mdi-alert</v-icon>
                        <v-toolbar-title class="text--white">Delete User</v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pt-4 px-6 primary--text">
                                <p>
                                    This will remove all of this user's data from Koroid.<br>
                                    This is a permanent action.
                                </p>
                                <p class="mt-2">
                                    Would you like to delete this user?
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-right">
                                <v-btn
                                    color="primary"
                                    class="mr-2"
                                    text
                                    @click="showUserDeleteModal = false"
                                    :disabled="api.busy"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    color="error"
                                    outlined
                                    @click="deleteUser"
                                    :disabled="api.busy"
                                    :loading="api.busy"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </v-form>
</template>

<script>
    import CustomAvatar from "../avatar/CustomAvatar";
    import validationRules from "../../lib/mixins/validationRules.js";
    import userFilters from "../../lib/mixins/userFilters";
    import {mask} from 'vue-the-mask';

    export default {
        name: "UserProfile",
        components: { CustomAvatar },
        mixins: [validationRules, userFilters],
        directives: {mask},
        props: {
            user: {
                type: Object,
                default: () => {},
            },
            profileView: {
                type: Boolean,
                default: true,
            },
            newUser: {
                type: Boolean,
                default: false,
            },
            newUserFirstStepOnly: {
                type: Boolean,
                default: false,
            },
            userHasAllPermissions: {
                type: Boolean,
                default: false,
            },
            roles: {
                type: Array,
                default: () => []
            },
            staffingDetails: {
                type: Array,
                default: () => []
            },
            emailDomains: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                valid: false,
                profile: Object.assign({}, this.user),
                api: new formHelper(),
                usernameLabel: 'Username',
                usernameSettings: {},
                usernameSettingsLoading: false,
                phoneNumber: null,
                email: null,
                status: 'active',
                customFields: [],
                profileCustomFields: [],
                customFieldsLoading: false,
                themePrimaryColor: this.$vuetify.theme.themes.light.primary,
                states: this.$config.states,
                updatingUserProfile: false,
                profileSaved: false,
                locations: [],
                locationsLoading: false,
                managerStaffingDetailsLocations: [],
                managerStaffingDetailsLocationsLoading: false,
                selectedLocations: null,
                PERMISSIONS: this.$config.permissions,
                prevState: {
                    selectedLocations: null,
                    selectedRole: null,
                    phoneNumber: null,
                    profile: Object.assign({}, this.user),
                    customFields: [],
                },
                selectedRole: null,
                customTokens: {
                    '#': {pattern: /[0-9]/},
                    'N': {pattern: /[2-9]/}
                },
                mobilePhoneKey: 30000,
                workPhoneKey: 80000,
                changeRoleDialog: false,
                rolesKey: 10000,
                userIsManagerDialog: false,
                managedDepartments: [],
                prevSelectedLocations: [],
                selectedEmailDomain: null,
                userHasShiftsDialog: false,
                showUserDeleteModal: false,
            }
        },
        computed: {
            globalTimezone() {
                return this.$root.globalTimezone
            },
            isOwnProfile() {
                if (this.newUser) {
                    return false
                }

                return this.profile._id === this.$user._id
            },
            showLocations() {
                if(this.newUser) {
                    return true
                }
                return !this.$userIsOwner(this.user)
            },
            selectableLocations() {
                let available_locations = this.locations

                // if locations are readonly, don't add disabled flag
                if (this.disabledLocations) {
                    return available_locations
                }

                // set disabled flag to locations in which the user has a job
                const job_locations = this.getUserJobLocations()
                available_locations = available_locations.map(location => ({
                    ...location,
                    disabled: job_locations.includes(location._id),
                    disabled_reason: "Staff member has a job in this location"
                }))

                if (this.$authIsAdministrator || this.$authIsManager) {
                    // if auth user is either an administrator or a manager, disable locations that they are not allowed in
                    const administrator_locations = this.$user.locations
                    available_locations = available_locations.map(location => {
                        if (location.disabled) {
                            return location
                        }

                        location.disabled = false

                        if (!administrator_locations.includes(location._id)) {
                            location.disabled = true
                            location.disabled_reason = "You are not authorized to make changes to this location"
                        }

                        if (this.$authIsManager && !location.disabled && !this.managerStaffingDetailsLocations.includes(location._id)) {
                            location.disabled = true
                            location.disabled_reason = "You are not authorized to manage this location"
                        }

                        return location
                    })
                }

                return available_locations
            },
            disabledLocations() {
                const role = this.roles.find(r => r._id === this.selectedRole)
                if(role && role.name) {
                    return role.name === 'Owner'
                }
                return false
            },
            disabledRoleSection() {
                const { profileView } = this
                return profileView || this.$userIsOwner(this.user) || !this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_PROFILE)
            },
            requiredLocations() {
                if(this.disabledLocations) {
                    return []
                }
                return [
                    v => Array.isArray(v) && v.length > 0 || 'Required'
                ]
            },
            isImpersonating() {
                return this.$isImpersonating
            },
            canDelete() {
                return (!this.profileView) && (this.canEdit && (this.status === 'inactive'))
            },
            canImpersonateRole() {
                if (typeof this.profile.role_id === 'undefined') {
                    return false
                }

                let auth_user_role = this.roles.find(role => role._id === this.$user.role_id)

                if (auth_user_role === -1 || typeof auth_user_role.rank === 'undefined') {

                    return false
                }

                let current_user_role = this.roles.find(role => role._id === this.profile.role_id)

                if (current_user_role === -1 || typeof current_user_role.rank === 'undefined') {
                    return false
                }

                return auth_user_role.rank > current_user_role.rank
            },
            canImpersonate() {

                if (this.newUser) {
                    return false
                }

                if (this.isImpersonating) {
                    return false
                }

                if (!this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.IMPERSONATE)) {
                    return false;
                }

                if (this.profileView) {
                    return false
                }

                if (!this.canImpersonateRole) {
                    return false
                }

                return true
            },
            canEdit() {
                return ((this.profileView && this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OWN_PROFILE)) ||
                    (!this.profileView && this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_PROFILE)))
            },
            availableRoles() {
                if(this.profileView) {
                    return this.roles
                }
                const { $authIsOwner, $authIsAdministrator, $authIsManager } = this
                if($authIsOwner) {
                    return this.roles.filter(role => role.name === 'Owner' || role.name === 'Administrator' || role.name === 'Manager' || role.name === 'Staff')
                }
                else if($authIsAdministrator) {
                    return this.roles.filter(role => role.name === 'Manager' || role.name === 'Staff')
                } else if($authIsManager) {
                    return this.roles.filter(role => role.name === 'Staff')
                } else {
                    return this.roles
                }
            },
            mobilePhoneRules() {
                return [
                    value => {
                        if(value === null || typeof value === 'undefined' || value.trim() === '') {
                            return true
                        }
                        return this.validateMobilePhoneNumber(value) || 'Mobile phone number is not valid'
                    }
                ]
            },
            workPhoneRules() {
                return [
                    value => {
                        if(value === null || typeof value === 'undefined' || value.trim() === '') {
                            return true
                        }
                        return this.validateWorkPhoneNumber(value) || 'Work phone number is not valid'
                    }
                ]
            },
            workPhoneWatcher() {
                return this.profile.work_phone
            },
            okta_sync_timestamp() {
                if (typeof this.profile.okta_sync_at === 'undefined' || !this.profile.okta_sync_at) {
                    return 'N/A'
                }

                return moment(this.profile.okta_sync_at).tz(this.globalTimezone).format('MMM. D, h:mm A')
            },
            activity_status_label() {
                if (typeof this.status === 'undefined' || !this.status) {
                    return 'Status: Inactive'
                }

                const status = this.status.charAt(0).toUpperCase() + this.status.slice(1)

                return `Status: ${status}`
            },

            status_changed_timestamp() {
                if (typeof this.profile.status_changed_at === 'undefined' || !this.profile.status_changed_at) {
                    return 'N/A'
                }

                return moment(this.profile.status_changed_at).tz(this.globalTimezone).format('MMM. D, YYYY')
            },

            selected_role_name() {
                if (this.selectedRole) {
                    return this.roles.find(role => role._id === this.selectedRole).name
                }

                return ''
            },
            userActiveColor () {
                if (this.api.busy) {
                    if (this.status === 'active') {
                        return 'success'
                    } else {
                        return 'error'
                    }
                }
                return 'success'
            },
            userActiveClass () {
                if (this.api.busy) {
                    return {}
                }
                return {
                    'status-switch': true
                }
            },
        },
        methods: {
            removeSelectedLocation(location) {
                const index = this.selectedLocations.indexOf(location._id)
                if (index >= 0) {
                    this.selectedLocations.splice(index, 1)
                }
            },
            saveProfile(callback, invalidCallback) {
                this.api.clearErrors()
                if (!this.validateForm()) {
                    if(invalidCallback && typeof invalidCallback === 'function') {
                        invalidCallback()
                    }
                    return false
                }
                this.updatingUserProfile = true
                this.api.post(this.profile._id ? '/user/update' : '/user/create', this.getUpdatedUser())
                    .then(({ data, message }) => {
                        this.onSaveProfileSuccess(data, message)
                        if(this.newUser) {
                            this.$emit('onNextTab', data, this.selectedRole)
                            if(this.userHasAllPermissions) {
                                this.$emit('user:saved', data)
                            } else {
                                this.$emit('loadUsers')
                            }
                        } else {
                            this.$emit('user:saved', data, callback && typeof callback === 'function' ? callback : false)
                        }
                    })
                    .catch(({ message, data }) => {
                        this.onSaveProfileFailure(message, data)
                        if(!this.newUser && callback && typeof callback === 'function') {
                            callback()
                        }
                    })
            },
            onSaveProfile(callback, invalidCallback) {
                this.saveProfile(callback, invalidCallback)
            },
            onOktaSync(callback) {
                this.updatingUserProfile = true
                this.api.get(`/okta-sync/${this.profile._id}`)
                    .then(({data, message}) => {
                        this.onSaveProfileSuccess(data, message)
                        this.$emit('user:saved', data, callback && typeof callback === 'function' ? callback : false)
                    })
            },
            validateForm() {
                return this.$refs.profileForm.validate()
            },
            onSaveProfileSuccess(user, message) {
                this.updatingUserProfile = false
                this.changeRoleDialog = false
                this.profileSaved = true
                if (this.profile._id) {
                    this.$snackNotify('success', `User ${this.profile.first_name} ${this.profile.last_name} updated.`)
                } else {
                    this.$snackNotify('success', `User ${this.profile.first_name} ${this.profile.last_name} created.`)
                }
            },
            onSaveProfileFailure(message, errorMessage) {
                this.updatingUserProfile = false
                if(errorMessage && errorMessage === 'Failed to update user in Okta') {
                    this.$snackNotify('warning', 'Unable to update user with Okta. Please try again.')
                } else {
                    this.$snackNotify('warning', 'Unable to save profile. Please try again.')
                }
            },
            onStatusChange() {
                // if new user, update property on profile object and let it be saved when saveProfile is called
                if (this.newUser && this.profile._id === 'undefined' || this.profile._id === null) {
                    this.profile.status = this.status
                    return
                }

                if (this.profile.status === this.status) {
                    return
                }

                // if existing user, hit status update endpoint
                this.api.post(`/user/update-status/${this.profile._id}`, {status: this.status})
                    .then(({data}) => {
                        if (data.has_upcoming_shifts) {
                            this.status = this.prevState.profile.status
                            this.profile.status = this.prevState.profile.status
                            this.userHasShiftsDialog = true
                            return
                        }

                        if (data.success) {
                            this.status = data.status
                            this.profile.status = data.status
                            this.profile.status_changed_at = data.timestamp
                            this.$emit('status:updated', {status: data.status, status_changed_at: data.timestamp})
                        }
                    })
            },
            deleteUser() {
                this.api.delete(`/user/${this.profile._id}`)
                    .then(({data, message}) => {
                        this.showUserDeleteModal = false
                        this.$snackNotify('success', `User ${this.profile.first_name} ${this.profile.last_name} deleted.`)
                        this.$emit('user:deleted')
                    })
            },
            avatarUpdated(avatar) {
                this.$set(this.profile, 'avatar', avatar)
                this.$emit('loadUsers')
            },
            avatarRemoved() {
                this.$delete(this.profile, 'avatar')
                this.$emit('loadUsers')
            },
            capitalize(value) {
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            getPhoneNumber() {
                if(this.phoneNumber) {
                    return this.parsePhoneNumber(this.phoneNumber)
                }
                return this.phoneNumber
            },
            getWorkPhoneNumber() {
                if(this.profile.work_phone) {
                    return this.parsePhoneNumber(this.profile.work_phone)
                }
                return null
            },
            onRoleChange() {
                const role = this.roles.find(r => r._id === this.selectedRole)
                if(role && role.name) {
                    if(role.name === 'Owner') {
                        this.prevSelectedLocations = Array.isArray(this.selectedLocations) ? Array.from(this.selectedLocations) : null
                        this.selectedLocations = null
                        this.prevState = {
                            ...this.prevState,
                            selectedLocations: null
                        }
                    }
                    if(!this.newUser) {
                        if(this.profile.role_name === 'Manager') {
                            const managedDepartments = this.staffingDetails.filter(sd => sd.managers.some(manager => manager.id === this.profile._id))
                            if(managedDepartments.length > 0) {
                                this.managedDepartments = managedDepartments
                                this.userIsManagerDialog = true
                            } else {
                                this.changeRoleDialog = true
                            }
                        } else {
                            this.changeRoleDialog = true
                        }
                    }
                }
            },
            onCancelRoleChange() {
                const role = this.roles.find(role => role._id === this.selectedRole)
                this.rolesKey = this.rolesKey + 1
                if(role.name === 'Owner') {
                    this.selectedLocations = Array.from(this.prevSelectedLocations)
                    this.prevState = {
                        ...this.prevState,
                        selectedLocations: Array.from(this.prevSelectedLocations)
                    }
                    this.prevSelectedLocations = []
                }
                this.setSelectedRole()
                this.changeRoleDialog = false
                this.userIsManagerDialog = false
                this.managedDepartments = []
            },
            loadUsernameSettings() {
                if(!this.newUser) {
                    if (this.$root.username_settings) {
                        this.usernameSettings = this.$root.username_settings
                        this.setUsernameSettings()
                    } else {
                        this.usernameSettingsLoading = true
                        this.api.get("/hospital-group/username-settings")
                            .then(({data, message}) => {
                                this.usernameSettingsLoading = false
                                this.usernameSettings = data
                                this.$root.username_settings = data
                                this.setUsernameSettings()
                            });
                    }
                }
            },
            setUsernameSettings() {
                if (typeof this.usernameSettings.label !== 'undefined' || this.usernameSettings.label) {
                    this.usernameLabel = this.usernameSettings.label
                }
            },
            setPhoneNumber() {
                if(typeof this.user.phone !== 'undefined' && this.user.phone != null) {
                    this.mobilePhoneKey = this.mobilePhoneKey + 1
                    this.phoneNumber = this.user.phone
                    this.prevState = {
                        ...this.prevState,
                        phoneNumber: this.user.phone
                    }
                } else {
                    this.phoneNumber = null
                    this.prevState = {
                        ...this.prevState,
                        phoneNumber: null,
                    }
                }
            },
            validateMobilePhoneNumber(value) {
                const pattern = /^\+1[2-9]([0-9]{2})([0-9]{3})([0-9]{4})$/;
                let phone = this.parsePhoneNumber(value)
                return !!phone.match(pattern)
            },
            validateWorkPhoneNumber(value) {
                const pattern = /^\+1[2-9]([0-9]{2})([0-9]{3})([0-9]{4})$/;
                let phone = this.parsePhoneNumber(value)
                return !!phone.match(pattern)
            },
            parsePhoneNumber(phoneNumber) {
                if(phoneNumber) {
                    return '+' + phoneNumber.replace(/\D/g, '')
                }
                return null
            },
            loadCustomFields() {
                if (Array.isArray(this.$root.profile_custom_fields)) {
                    this.profileCustomFields = this.$root.profile_custom_fields
                    this.setCustomFieldValues()
                } else {
                    this.customFieldsLoading = true
                    this.api.get("/custom-field/profile-fields")
                        .then(({data, message}) => {
                            this.customFieldsLoading = false
                            if (Array.isArray(data)) {
                                this.profileCustomFields = data
                                this.$root.profile_custom_fields = data
                            }
                            this.setCustomFieldValues()
                        });
                }
            },
            setCustomFieldValues() {
                this.customFields = this.profileCustomFields.map(cf => {
                    return { _id: cf._id, name: cf.name, value: null }
                })


                this.prevState = {
                    ...this.prevState,
                    customFields: _.cloneDeep(this.customFields)
                }

                if (typeof this.profileCustomFields !== 'undefined' && typeof this.profile.custom_fields !== 'undefined' && this.profile.custom_fields.length) {
                    this.profile.custom_fields.forEach(cf => {
                        if ((typeof cf.id !=='undefined' && cf.id) && typeof cf.value !== 'undefined') {
                            this.customFields = this.customFields.map(upcf => {
                                if (upcf._id === cf.id) {
                                    upcf.value = cf.value
                                }
                                return upcf
                            })
                            this.prevState = {
                                ...this.prevState,
                                customFields: _.cloneDeep(this.customFields)
                            }
                        }
                    })
                }

                this.filterCustomFieldsBySelectedLocations()
            },
            filterCustomFieldsBySelectedLocations() {
                let filled_custom_fields_for_user = []

                if (this.customFields) {
                    filled_custom_fields_for_user = this.customFields
                }

                let filtered_custom_fields = this.filterCustomFieldsByLocationIds(this.profileCustomFields, this.selectedLocations)

                this.customFields = filtered_custom_fields.map(custom_field => {
                    let value = custom_field.value

                    if (value === null || value === undefined) {
                        let filled_custom_field_for_user = filled_custom_fields_for_user.find(user_field => user_field._id === custom_field._id)

                        if (filled_custom_field_for_user) {
                            value = filled_custom_field_for_user.value
                        }
                    }

                    return {
                        _id: custom_field._id,
                        name: custom_field.name,
                        value: value
                    }
                })
            },
            filterCustomFieldsByLocationIds(custom_fields, location_ids) {
                if (!location_ids || !location_ids.length) {
                    return []
                }

                if (!custom_fields || !custom_fields.length) {
                    return []
                }

                return custom_fields.filter(custom_field => {
                    custom_field = this.profileCustomFields.find(cf => cf._id === custom_field._id)

                    if (!custom_field) {
                        return false
                    }

                    if (typeof custom_field.location_ids === 'undefined' || !custom_field.location_ids || !custom_field.location_ids.length) {
                        return false
                    }

                    return custom_field.location_ids.some(location_id => location_ids.includes(location_id))
                })
            },
            filterNullValueCustomFields() {
                if (!this.customFields.length) {
                    return []
                }
                return this.customFields.filter(cf => typeof cf.value !== 'undefined' && cf.value)
            },
            loadLocations() {
                if (this.$root.allLocations) {
                    this.locations = this.$root.allLocations
                } else {
                    this.locationsLoading = true
                    this.api.get("/list-locations")
                        .then(({data, message}) => {
                            this.locations = data
                            this.$root.allLocations = data
                            this.locationsLoading = false
                        })
                }
            },
            loadManagerStaffingDetailsLocations() {
                if (!this.$authIsManager) {
                    return []
                }

                if (this.$root.managerStaffingDetailsLocations) {
                    this.managerStaffingDetailsLocations = this.$root.managerStaffingDetailsLocations
                    return
                }

                this.managerStaffingDetailsLocationsLoading = true

                this.api.get('/staffing-details-locations-for-user/' + this.$user._id)
                    .then(({data}) => {
                        this.managerStaffingDetailsLocations = data
                        this.$root.managerStaffingDetailsLocations = data
                        this.managerStaffingDetailsLocationsLoading = false
                    })
            },
            setLocations() {
                if(this.user && this.user.locations && Array.isArray(this.user.locations)) {
                    this.selectedLocations = Array.from(this.user.locations)
                    this.prevState = {
                        ...this.prevState,
                        selectedLocations: Array.from(this.user.locations)
                    }
                } else {
                    this.selectedLocations = null
                    this.prevState = {
                        ...this.prevState,
                        selectedLocations: null
                    }
                }
            },
            setSelectedRole() {
                if(this.user && this.user.role_id) {
                    this.selectedRole = this.user.role_id
                } else {
                    this.selectedRole = this.roles.find(role => role.name === 'Staff')._id
                }
                this.prevState = {
                    ...this.prevState,
                    selectedRole: this.selectedRole
                }
            },
            getUpdatedUser() {
                const { _id, first_name, last_name, username, primary_address, secondary_address, city, state, zip_code } = this.profile

                let custom_fields = this.filterNullValueCustomFields()
                custom_fields = custom_fields.map(custom_field => {
                    return { id: custom_field._id, name: custom_field.name, value: custom_field.value }
                })

                return {
                    _id: _id,
                    first_name,
                    last_name,
                    username,
                    primary_address: primary_address,
                    secondary_address: secondary_address,
                    city: city,
                    state: state,
                    zip_code: zip_code,
                    role_id: this.selectedRole,
                    locations: Array.isArray(this.selectedLocations) ? Array.from(this.selectedLocations) : [],
                    custom_fields: custom_fields,
                    phone: this.getPhoneNumber(),
                    work_phone: this.getWorkPhoneNumber(),
                    email: this.getUserEmail(),
                    status: this.status,
                }
            },
            getUserEmail() {
                if (this.newUser) {
                    return `${this.email}${this.selectedEmailDomain}`
                }

                return this.profile.email
            },
            setInitialData() {
                this.setStatus()
                this.setPhoneNumber()
                this.setEmailDomain()
                this.loadUsernameSettings()
                this.setLocations()
                this.setSelectedRole()

                if (this.showLocations) {
                    this.loadLocations()
                    this.loadManagerStaffingDetailsLocations()
                }

                this.loadCustomFields()
            },
            setStatus() {
                this.status = 'active'

                if (typeof this.profile.status !== 'undefined') {
                    this.status = this.profile.status
                }
            },
            setEmailDomain() {
                if (this.newUser && this.emailDomains.length) {
                    this.selectedEmailDomain = this.emailDomains[0]
                }
            },
            isDirty() {
                let isWorkPhoneEqual
                const { work_phone } = this.prevState.profile
                const curr_work_phone = this.getWorkPhoneNumber()
                if((typeof work_phone === 'undefined' || work_phone === null) && (typeof curr_work_phone === 'undefined' || curr_work_phone === null)) {
                    isWorkPhoneEqual = true
                } else {
                    isWorkPhoneEqual = _.isEqual(work_phone, curr_work_phone)
                }

                const comparison_omit = ['avatar', 'work_phone', 'email', 'status', 'status_changed_at']

                let prev_custom_fields = this.filterCustomFieldsByLocationIds(this.prevState.customFields, this.selectedLocations)

                return !_.isEqual(this.prevState.selectedLocations, this.selectedLocations) ||
                    !_.isEqual(this.prevState.selectedRole, this.selectedRole) ||
                    !_.isEqual(this.prevState.phoneNumber, this.parsePhoneNumber(this.phoneNumber)) ||
                    !_.isEqual(_.omit(this.prevState.profile, comparison_omit), _.omit(this.profile, comparison_omit)) ||
                    !_.isEqual(prev_custom_fields, this.customFields) ||
                    !isWorkPhoneEqual
            },
            lowercaseEmail() {
                if (typeof this.email !== 'undefined' && this.email) {
                    this.email = this.email.toLowerCase()
                }
            },
            getUserJobLocations() {
                if (typeof this.profile.jobs === 'undefined' || !this.profile.jobs) {
                    return []
                }

                return [... new Set(this.profile.jobs.map(job => job.location.id))]
            },
        },
        watch: {
            phoneNumber() {
                this.workPhoneKey = this.workPhoneKey + 1
            },
            workPhoneWatcher() {
                this.mobilePhoneKey = this.mobilePhoneKey + 1
            },
            user(newUser) {
                this.profile = Object.assign({}, newUser)
                this.prevState = {
                    ...this.prevState,
                    profile: Object.assign({}, newUser)
                }
                this.profileSaved = false
                this.setInitialData()
            },
            selectedLocations(locations) {
                this.$emit('onUpdateUserLocations', locations)
            }
        },
        mounted() {
            this.setInitialData()
        }
    }
</script>

<style scoped>
    .avatar-picker-input {
        margin-top: 0;
        padding-top: 20px;
    }

    .below-avatar-picker-input {
        padding-top: 0;
        padding-bottom: 0;
    }

    .saving-loading-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.15);
        z-index: 2;
    }

    .saving-loading-overlay span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120px;
        height: 120px;
        background-color: white;
        border-radius: 50%;
    }

    .status-switch :deep(.v-input--switch__track) {
        color: #D13434;
        opacity: 1;
        border: 0;
    }

    .status-switch :deep(.v-input--switch__thumb) {
        color: #fff !important;
    }

    .koroid-tooltip {
        opacity: 0.9;
        top: 773px;
        width: 230px;
        font-size: 12px;
        padding: 10px 15px;
        background: #E5E5E5;
        border-radius: 6px;
        border: 1px solid #7B7F98;
        color: #000;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
    }
</style>
