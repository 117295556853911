<script>
import { Doughnut, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
    name: "DoughnutChart",
    extends: Doughnut,
    mixins: [reactiveProp],
    props: {
        chartOptions: {
            type: Object,
            default: () => ({})
        },
    },
    mounted () {
        this.renderChart(this.chartData, this.chartOptions)
    }
}
</script>

<style scoped>

</style>
