const state = {
    expiresAt: null
}

const getters = {
    expiresAt(state) {
        return state.expiresAt
    },
}

const actions = {
    setExpiresAt({commit}, expiresAt) {
        commit('setExpiresAt', expiresAt)
    },
}

const mutations = {
    setExpiresAt(state, expiresAt) {
        state.expiresAt = moment(parseInt(expiresAt))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
