<template>
    <v-dialog v-model="modalValue" max-width="400" persistent>
        <v-card>
            <v-toolbar
                class="warning-modal"
            >
                <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                <v-toolbar-title class="text--h2">Save Preset</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeModal">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <p class="preset-saved-content text--paragraph black--text">
                    This report already has an existing preset configuration.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="closeModal">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "PresetAlreadySavedModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        closeModal() {
            this.modalValue = false
        }
    }
}
</script>

<style scoped>
    .preset-saved-content {
        margin-top: 20px;
    }
</style>
