<template>
    <v-toolbar :class="{ 'ds-toolbar': isWindow, 'informational-modal': !isWindow}" elevation="0">
        <v-toolbar-title class="text--h2 d-flex flex-row align-center" style="column-gap: 25px;">
            <v-skeleton-loader
                v-if="loading"
                type="sentences"
                width="400px"
            ></v-skeleton-loader>
            <template v-else>
                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex flex-row align-center" style="column-gap: 15px;" v-bind="attrs" v-on="on">
                            <v-icon color="primary">mdi-domain</v-icon>
                            <span>{{ department }}</span>
                        </div>
                    </template>
                    <span>Department</span>
                </v-tooltip>
                <div style="border-left: 1px solid #000000; height: 32px;"></div>
                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex flex-row align-center" style="column-gap: 15px;" v-bind="attrs" v-on="on">
                            <v-icon color="primary">mdi-account-group</v-icon>
                            <span>{{ staffGroup }}</span>
                        </div>
                    </template>
                    <span>Staff Group</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="automatedShiftDetailsModalValue = true"
                        >
                            <v-icon color="primary">mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Click to view automated shift details</span>
                </v-tooltip>
            </template>
            <automated-shift-details
                v-model="automatedShiftDetailsModalValue"
                :created-by="createdBy"
                :start-date="startDate"
                :shifts-span="shiftsSpan"
                :timezone="timezone"
            />
        </v-toolbar-title>
        <template v-if="isWindow">
            <v-spacer/>
            <v-btn
                color="primary"
                outlined
                @click="$emit('onClickBack')"
            >
                Back to Shifts
            </v-btn>
        </template>
    </v-toolbar>
</template>

<script>
import AutomatedShiftDetails from "../AutomatedShiftDetails";

export default {
    name: "AutomatedShiftDetailsToolbar",
    components: {AutomatedShiftDetails},
    props: {
        isWindow: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        staffingAutomation: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        automatedShiftDetailsModalValue: false,
    }),
    computed: {
        department() {
            return this.staffingAutomation?.department?.name
        },
        staffGroup() {
            return this.staffingAutomation?.staff_group?.name
        },
        createdBy() {
            const createdBy = this.staffingAutomation?.created_by?.full_name
            return createdBy ?? 'N/A'
        },
        startDate() {
            const startDate = this.staffingAutomation?.start_date
            if (startDate) {
                if (this.globalTimezone) {
                    return moment.tz(startDate, this.globalTimezone).format('MM/DD/YYYY')
                }
                return moment(startDate).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        shiftsSpan() {
            const coverageWeeks = this.staffingAutomation?.coverage_weeks || 0
            return `${coverageWeeks} Weeks`
        },
        timezone() {
            const timezone = this.staffingAutomation?.timezone
            if (timezone) {
                return moment.tz(timezone).format('z Z')
            }
            return 'N/A'
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
}
</script>

<style scoped>

</style>
