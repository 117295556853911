<template>
    <div class="chart-counts-container">
        <div
            v-for="(count, index) in counts"
            :key="index"
            :class="{ 'chart-count-container': true, 'chart-view-blurred': count.shown }"
            @mouseenter="onMouseEnter(index)"
            @mouseleave="onMouseLeave(index)"
        >
            <div class="chart-count-wrapper" v-show="count.shown">
                <div class="chart-count-view">
                    <div class="chart-count-label-container">
                        <v-icon color="#000000">mdi-calendar</v-icon>
                        <span class="text-uppercase text--label">Total:</span>
                    </div>
                    <span class="text--label">{{ count.total }}</span>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; column-gap: 10px;">
                    <div style="display: flex; flex-direction: row; align-items: center; column-gap: 5px;">
                        <v-icon color="#000000">mdi-calendar-clock</v-icon>
                        <span class="text-uppercase text--label">Open:</span>
                    </div>
                    <span class="text--label">{{ count.open }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChartCounts",
    props: {
        counts: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onMouseEnter (index) {
            this.$emit('onMouseEnter', index)
        },
        onMouseLeave (index) {
            this.$emit('onMouseLeave', index)
        }
    }
}
</script>

<style scoped>
    .chart-counts-container {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        position: absolute;
        height: 120px;
        width: 100%;
        padding-left: 44px;
    }

    .chart-count-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .chart-view-blurred {
        backdrop-filter: blur(5px);
    }

    .chart-count-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .chart-count-view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }

    .chart-count-label-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 5px;
    }
</style>
