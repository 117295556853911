<template>
    <div class="bulk-upload-users-complete-container">
        <div class="bulk-upload-users-complete-info">
            <div class="text-h6 font-weight-bold text-center">
                Bulk Upload Complete
            </div>

            <div class="text--paragraph py-3">
                The bulk upload process has successfully added users to the Koroid database. <br>
                Creation of <strong>SSO profiles</strong> and final data syncing will now finish in the background. <br>
                New users will receive an email when the process is complete.
            </div>

            <div class="bulk-upload-users-complete-alert bulk-upload-users-complete-alert-success">
                {{ successCount }} {{ successCount === 1 ? 'user' : 'users' }} uploaded
            </div>
            <div class="bulk-upload-users-complete-alert bulk-upload-users-complete-alert-error">
                {{ errorCount }} {{ errorCount === 1 ? 'user' : 'users' }} not uploaded
            </div>

            <div>
                <v-btn
                    color="primary"
                    outlined
                    style="text-transform: none;"
                    @click="onGoBack"
                >Return to Users Page</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BulkUploadUsersComplete",
    props: {
        successCount: {
            type: Number,
            default: 0,
        },
        errorCount: {
            type: Number,
            default: 0,
        }
    },
    methods: {
        onGoBack() {
            this.$emit('onGoBack')
        }
    }
}
</script>

<style scoped>
    .bulk-upload-users-complete-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .bulk-upload-users-complete-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        padding: 24px;
    }

    .bulk-upload-users-complete-alert {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 440px;
        padding: 16px;
    }

    .bulk-upload-users-complete-alert-success {
        background-color: rgba(107, 201, 110, 0.12);
        border: 2px solid #6BC96E;
    }

    .bulk-upload-users-complete-alert-error {
        background-color: rgba(209, 52, 52, 0.12);
        border: 2px solid rgba(209, 52, 52, 0.6);
    }
</style>
