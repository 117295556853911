const FILTER_NAMES = {
    SHIFT_DETAILS: {
        LOCATIONS: 'locations',
        DEPARTMENTS: 'departments',
        STAFF_GROUPS: 'staff_groups',
        JOB_TITLES: 'job_titles',
        CREDENTIALS: 'requirements',
        SHIFT_TIMES: 'shift_times',
        STAFF_MEMBERS: 'staff_members',
        STATUS: 'statuses'
    },
}

const parseFilter = (filters, mapper, filterName) => {
    if (filters && filters.hasOwnProperty(filterName)) {
        const filterValue = filters[filterName]
        if (Array.isArray(filterValue)) {
            if (filterValue.length > 0) {
                if (filterValue.length > 3) {
                    const remaining = filterValue.length - 3
                    const joined = filterValue.slice(0, 3).map(mapper).join(', ')
                    return `${joined}, (+${remaining})`
                }
                return filterValue.map(mapper).join(', ')
            }
            return 'N/A'
        }
        return 'N/A'
    }
    return 'N/A'
}

const capitalize = value => {
    if (!value) {
        return ''
    }

    return value.toString().charAt(0).toUpperCase()
        + value.slice(1);
}

const parseLocations = filters => parseFilter(filters, loc => loc.name, FILTER_NAMES.SHIFT_DETAILS.LOCATIONS)

const parseReportLocations = report => {
    if (report) {
        return parseLocations(report.filters)
    }
    return 'N/A'
}

const parsePresetLocations = preset => {
    if (preset) {
        return parseLocations(preset.filters)
    }
    return 'N/A'
}

const parseStatus = filters => parseFilter(filters, status => capitalize(status), FILTER_NAMES.SHIFT_DETAILS.STATUS)

const parseReportStatus = report => {
    if (report) {
        return parseStatus(report.filters)
    }
    return 'N/A'
}

const parsePresetStatus = preset => {
    if (preset) {
        return parseStatus(preset.filters)
    }
    return 'N/A'
}

const parseDepartments = filters => parseFilter(filters, dep => dep.name, FILTER_NAMES.SHIFT_DETAILS.DEPARTMENTS)

const parseReportDepartments = report => {
    if (report) {
        return parseDepartments(report.filters)
    }
    return 'N/A'
}

const parsePresetDepartments = preset => {
    if (preset) {
        return parseDepartments(preset.filters)
    }
    return 'N/A'
}

const parseStaffGroups = filters => parseFilter(filters, sg => sg.name, FILTER_NAMES.SHIFT_DETAILS.STAFF_GROUPS)

const parseReportStaffGroups = report => {
    if (report) {
        return parseStaffGroups(report.filters)
    }
    return 'N/A'
}

const parsePresetStaffGroups = preset => {
    if (preset) {
        return parseStaffGroups(preset.filters)
    }
    return 'N/A'
}

const parseJobTitles = filters => parseFilter(filters, jt => jt.name, FILTER_NAMES.SHIFT_DETAILS.JOB_TITLES)

const parseReportJobTitles = report => {
    if (report) {
        return parseJobTitles(report.filters)
    }
    return 'N/A'
}

const parsePresetJobTitles = preset => {
    if (preset) {
        return parseJobTitles(preset.filters)
    }
    return 'N/A'
}

const parseCredentials = filters => parseFilter(filters, cred => cred.name, FILTER_NAMES.SHIFT_DETAILS.CREDENTIALS)

const parseReportCredentials = report => {
    if (report) {
        return parseCredentials(report.filters)
    }
    return 'N/A'
}

const parsePresetCredentials = preset => {
    if (preset) {
        return parseCredentials(preset.filters)
    }
    return 'N/A'
}

const parseShiftTimes = filters => parseFilter(filters, st => st.name, FILTER_NAMES.SHIFT_DETAILS.SHIFT_TIMES)

const parseReportShiftTimes = report => {
    if (report) {
        return parseShiftTimes(report.filters)
    }
    return 'N/A'
}

const parsePresetShiftTimes = preset => {
    if (preset) {
        return parseShiftTimes(preset.filters)
    }
    return 'N/A'
}

const parseStaffMembers = filters => parseFilter(filters, emp => `${emp.first_name} ${emp.last_name}`, FILTER_NAMES.SHIFT_DETAILS.STAFF_MEMBERS)

const parseReportStaffMembers = report => {
    if (report) {
        return parseStaffMembers(report.filters)
    }
    return 'N/A'
}

const parsePresetStaffMembers = preset => {
    if (preset) {
        return parseStaffMembers(preset.filters)
    }
    return 'N/A'
}

const parseType = type => {
    if (type) {
        return type.split('_').map(_.upperFirst).join(' ')
    }
    return 'N/A'
}

const parseReportType = report => {
    if (report) {
        return parseType(report.type)
    }
    return 'N/A'
}

const parsePresetType = preset => {
    if (preset) {
        return parseType(preset.type)
    }
    return 'N/A'
}

const parseColumns = (allColumns, columns) => {
    if (Array.isArray(allColumns) && Array.isArray(columns)) {
        return _.intersectionBy(allColumns, columns.map(col => ({
            column_name: col
        })), 'column_name').map(col => col.title)
    }
    return []
}

const parseReportColumns = (appData, report) => {
    if (appData && appData.all_column_details && report && report.columns) {
        return parseColumns(appData.all_column_details, report.columns)
    }
    return []
}

const parsePresetColumns = (appData, preset) => {
    if (appData && appData.all_column_details && preset && preset.columns) {
        return parseColumns(appData.all_column_details, preset.columns)
    }
    return []
}

const parseDateRange = (dateRange, timezone) => {
    if (Array.isArray(dateRange) && dateRange.length > 1) {
        let startMoment = moment(dateRange[0]).tz(timezone)
        let endMoment = moment(dateRange[1]).tz(timezone)

        const start = startMoment.format('MM/DD/YYYY')
        const end = endMoment.format('MM/DD/YYYY')

        if (start === end) {
            return start
        }

        if (startMoment.startOf('month').format('MM/DD/YYYY') === start &&
            startMoment.endOf('month').format('MM/DD/YYYY') === end) {
            return startMoment.format('MMMM YYYY')
        }

        return `${start} - ${end}`
    }
    return 'N/A'
}

const parseReportDateRange = (report, timezone) => {
    if (report) {
        if (report.start && report.end) {
            return parseDateRange([report.start, report.end], timezone)
        }
    }
    return 'N/A'
}

const parseReportPresetName = report => {
    if (report) {
        if (report.report_preset_name) {
            return report.report_preset_name
        }
        return 'N/A'
    }
    return 'N/A'
}

const parseCreatedBy = obj => {
    if (obj) {
        const {created_by} = obj
        if (created_by) {
            return `${created_by.first_name} ${created_by.last_name}`
        }
    }
    return 'N/A'
}

const parseReportCreatedBy = report => {
    return parseCreatedBy(report)
}

const parsePresetCreatedBy = preset => {
    return parseCreatedBy(preset)
}

const parseShiftStatus = report => {
    const status = report?.filters?.status
    if (status) {
        return status.toString().charAt(0).toUpperCase() + status.slice(1)
    }
    return 'N/A'
}

const parseReportShiftStatus = report => parseShiftStatus(report)

const parsePresetShiftStatus = preset => parseShiftStatus(preset)

const parseReportNoShow = report => {
    const noShow = report?.filters?.no_shows
    if (noShow) {
        if (noShow === 'only_no_shows') {
            return 'Only Shifts Marked \"No Show\"'
        } else if (noShow === 'exclude_no_shows') {
            return 'Exclude Shifts Marked \"No Show\"'
        }
    }
    return 'N/A'
}

const parseReportCallOut = report => {
    const callOut = report?.filters?.call_outs
    if (callOut) {
        if (callOut === 'only_call_outs') {
            return 'Only Shifts Marked \"Call Out\"'
        } else if (callOut === 'exclude_call_outs') {
            return 'Exclude Shifts Marked \"Call Out\"'
        }
    }
    return 'N/A'
}

const parseReportShiftCancellationStatus = report => {
    if (report) {
        return parseFilter(report.filters, status => status, 'status')
    }
    return 'N/A'
}

const parseReportShiftCancellationType = report => {
    const type = report?.filters?.type
    if (type) {
        if (type === 'requested') {
            return 'Cancellation Requested'
        } else if (type === 'not_requested') {
            return 'Cancelled Without Request'
        }
    }
    return 'N/A'
}

const parseEmploymentType = report => {
    const employmentType = report?.filters?.staff_type
    if (employmentType && employmentType.name) {
        return employmentType.name
    }
    return 'N/A'
}

const parseReportEmploymentType = parseEmploymentType

const parsePresetEmploymentType = parseEmploymentType

export {
    parseReportLocations,
    parsePresetLocations,
    parseReportStatus,
    parsePresetStatus,
    parseReportDepartments,
    parsePresetDepartments,
    parseReportStaffGroups,
    parsePresetStaffGroups,
    parseReportJobTitles,
    parsePresetJobTitles,
    parseReportCredentials,
    parsePresetCredentials,
    parseReportShiftTimes,
    parsePresetShiftTimes,
    parseReportStaffMembers,
    parsePresetStaffMembers,
    parseReportType,
    parsePresetType,
    parseReportColumns,
    parsePresetColumns,
    parseReportDateRange,
    parseReportPresetName,
    parseReportCreatedBy,
    parsePresetCreatedBy,
    parseReportShiftStatus,
    parsePresetShiftStatus,
    parseReportNoShow,
    parseReportCallOut,
    parseReportShiftCancellationStatus,
    parseReportShiftCancellationType,
    parseReportEmploymentType,
    parsePresetEmploymentType,
}
