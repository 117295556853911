<template>
    <div class="staff-schedule-container">
        <div class="staff-grouping-view-min-width pl-5">
            <div class="staff-grouping-view-wrapper">
                <user-details :user="user"></user-details>
            </div>
        </div>
        <div class="staff-schedule-row-outer">
            <div class="staff-schedule-row-inner">
                <div
                    v-if="currentDay.exists"
                    class="current-day-gradient"
                    :style="{ left: `${14.25 * currentDay.index}%` }"
                >

                </div>
                <div
                    v-for="event in events"
                    :key="event.index"
                    :style="getEventContainerStyle(event.index)"
                >
                    <v-menu
                        open-on-click
                        offset-y
                        :close-on-content-click="false"
                        :disabled="event.events.length <= 1"
                        :ref="`eventMenuRef-${event.index}`"
                    >
                        <template v-slot:activator="{ on, attrs, value }">
                            <div v-bind="attrs" v-on="on" style="height: 100%; width: 100%;">
                                <event
                                    :event="event.events[0]"
                                    :multiple="event.events.length > 1"
                                    :chevron-up="value"
                                ></event>
                            </div>
                        </template>
                        <v-list style="padding: 8px 4px 4px 4px;">
                            <div
                                v-for="e in event.events"
                                style="height: 67px; width: 100%; margin-bottom: 5px;"
                            >
                                <event
                                    :event="e"
                                ></event>
                            </div>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserDetails from './UserDetails'
import Event from './Event'

export default {
    name: "StaffSchedule",
    components: { UserDetails, Event },
    props: {
        user: {
            type: Object,
            default: () => ({}),
        },
        startOfWeek: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        events () {
            const { user } = this
            if (Array.isArray(user.shifts) && Array.isArray(user.shift_offers)) {
                const events = _.range(7).map(index => {
                    let events = []
                    if (index < user.shifts.length) {
                        events = user.shifts[index].map(shift => this.parseShift(shift, user.job_title_id, index))
                    }
                    if (index < user.shift_offers.length) {
                        events = [
                            ...events,
                            ...user.shift_offers[index].map(shiftOffer => this.parseShiftOffer(shiftOffer, user.job_title_id, index))
                        ]
                    }
                    return {
                        index,
                        events,
                    }
                })
                return events.filter(event => event.events.length > 0)
            }
            return []
        },
        currentDay () {
            let exists = false
            let index = 0
            let dayOfWeek = null
            let dayOfMonth = null
            if (this.startOfWeek) {
                const now = moment().tz(this.globalTimezone)
                const startOfWeek = this.startOfWeek.clone()
                _.range(7).forEach(i => {
                    const isToday = startOfWeek.isSame(now, 'day')
                    if (isToday) {
                        exists = true
                        index = i
                        dayOfWeek = startOfWeek.format('ddd').toUpperCase()
                        dayOfMonth = startOfWeek.format('D')
                    }
                    startOfWeek.add(1, 'days')
                })
            }
            return {
                exists,
                index,
                dayOfWeek,
                dayOfMonth,
            }
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        parseShift (shift, jobTitleId, index) {
            if (shift) {
                return {
                    _id: shift._id,
                    index,
                    shift_request_id: shift.shift_request_id,
                    request_form_id: shift.request_form_id,
                    department_name: shift.department_name,
                    start: shift.start,
                    end: shift.end,
                    timezone: shift.timezone,
                    cancellation_requested: !!shift.cancellation_requested,
                    no_show: shift.no_show,
                    call_out: shift.call_out,
                    time_edited: shift.time_edited,
                    has_different_job_title: shift.job_title_id !== jobTitleId,
                    day_span_label: shift.day_span_label,
                    type: 'shift'
                }
            }
            return {}
        },
        parseShiftOffer (shiftOffer, jobTitleId, index) {
            if (shiftOffer) {
                return {
                    _id: shiftOffer._id,
                    index,
                    job_title_id: shiftOffer.job_title_id,
                    shift_request_id: shiftOffer.shift_request_id,
                    request_form_id: shiftOffer.request_form_id,
                    department_name: shiftOffer.department_name,
                    start: shiftOffer.start,
                    end: shiftOffer.end,
                    timezone: shiftOffer.timezone,
                    has_different_job_title: shiftOffer.job_title_id !== jobTitleId,
                    day_span_label: shiftOffer.day_span_label,
                    type: 'shiftOffer',
                }
            }
            return {}
        },
        getEventContainerStyle (index) {
            return {
                display: 'inline-block',
                position: 'absolute',
                left: `calc(calc(14.25% * ${index}) + 4px)`,
                width: `calc(14.25% - 6px)`,
                height: '100%',
                paddingTop: '2px',
                paddingBottom: '2px',
            }
        },
        getRandomNumber (min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        randomize () {
            return this.getRandomNumber(
                this.getRandomNumber(23, 99),
                this.getRandomNumber(123, 999)
            ) * this.getRandomNumber(
                this.getRandomNumber(11, 88),
                this.getRandomNumber(111, 988)
            )
        }
    },
    created () {
        window.EventBus.$on('staffScheduler/onClickEvent', event => {
            if (event.type === 'shift') {
                const eventMenuRefs = this.$refs?.[`eventMenuRef-${event.index}`]
                if (Array.isArray(eventMenuRefs)) {
                    eventMenuRefs.forEach(eventMenuRef => {
                        const save = eventMenuRef?.save
                        if (typeof save === 'function') {
                            save()
                        }
                    })
                }
            }
        })
    }
}
</script>

<style scoped>
    .staff-schedule-container {
        display: flex;
        flex-direction: row;
        min-width: 1000px;
    }

    .staff-grouping-view-min-width {
        min-width: 15%;
        max-width: 15%;
        min-height: 40px;
    }

    .staff-grouping-view-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 2px solid #A9A9A9;
    }

    .staff-schedule-row-outer {
        min-width: auto;
        min-height: 70px;
        border-bottom: 2px solid #A9A9A9;
        width: 100%;
    }

    .staff-schedule-row-inner {
        height: 100%;
        width: 100%;
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-image: repeating-linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            transparent 1px 100%
        );
        background-repeat: repeat;
        background-size: 14.25% 71px;
    }

    .current-day-gradient {
        position: absolute;
        background-image: linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            rgba(240, 240, 240, 0.6) 1px 100%
        );
        width: 14.25%;
        height: 100%;
    }
</style>
