<template>
    <v-list-item
        :disabled="disabled"
        :class="{ 'list-item-selected': user.selected }"
        style="padding-left: 0; padding-right: 8px;"
        @click="onClick">
        <v-list-item-icon style="align-self: center; flex-direction: column; margin-right: 0; padding-right: 4px; padding-left: 4px; min-width: 34px; pointer-events: auto;">
            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="'success'"
                        small
                    >
                        mdi-circle
                    </v-icon>
                </template>
                <span>{{ iconTooltipText }}</span>
            </v-tooltip>
        </v-list-item-icon>
        <v-list-item-avatar>
            <custom-avatar
                :user="user"
                :size="40"
                user-chip>
            </custom-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="pl-2">
            <v-list-item-title class="primary--text text--darken-5">
                {{ title }}
            </v-list-item-title>
            <v-list-item-subtitle class="blue-grey--text text--darken-2">
                <v-tooltip v-if="overtime" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-small color="error">
                                mdi-alert
                            </v-icon>
                            <span class="error--text">
                                {{ subtitle }}
                            </span>
                        </div>
                    </template>
                    <span>Staff exceeds max work hours</span>
                </v-tooltip>
                <v-tooltip v-else-if="toBeOvertime" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-small color="error">
                                mdi-alert
                            </v-icon>
                            <span class="error--text">
                                {{ subtitle }}
                            </span>
                        </div>
                    </template>
                    <span>Staff will exceed max work hours</span>
                </v-tooltip>
                <span v-else class="blue-grey--text text--darken-2">
                    {{ subtitle }}
                </span>
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action style="padding-top: 16px; padding-bottom: 12px; margin-top: 0; margin-bottom: 0;">
            <v-checkbox
                :input-value="user.selected"
                color="#4253C4"
                :disabled="disabled"
            ></v-checkbox>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import CustomAvatar from '../../../avatar/CustomAvatar'
import userDetails from '../../../../lib/mixins/userDetails'

export default {
    name: "HistoricUserListItem",
    components: { CustomAvatar },
    mixins: [userDetails],
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        score: {
            type: Number,
            default: () => null,
        }
    },
    computed: {
        disabled () {
            if (this.user) {
                return this.user.disabled
            }
            return false
        },
        iconTooltipText() {
            return 'Shift will be Assigned'
        }
    },
    methods: {
        onClick () {
            if (!this.disabled) {
                this.$emit('click', this.user);
            }
        }
    }
}
</script>

<style scoped>
    .list-item-opacity {
        opacity: 0.6;
    }

    .list-item-selected {
        background-color: rgb(229, 229, 229);
    }
</style>
