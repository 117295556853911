export const fetchPredictedAvailability = async selectedShiftRequestId => {
    const { data } = await new formHelper().get(
        `/shift-request/predicted-availability/${selectedShiftRequestId}`
    );

    if (!data) return;

    const { predicted_availability } = data;
    if (!predicted_availability) return;

    return Object.fromEntries(
        predicted_availability
            .filter((entry) => entry.data.kind === 'staffMemberLikelyToAccept')
            .map((entry) => [entry.data.staffMember, entry.data.score]),
    );
};
