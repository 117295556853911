const state = {
    requestFormsFilters: {
        status: null,
        from_start_date: null,
        to_start_date: null,
        past: false
    },
}

const getters = {
    requestFormsFilters(state) {
        return state.requestFormsFilters
    },
}

const actions = {
    updateRequestFormsFilters({ commit }, searchParams) {
        commit('updateRequestFormsFilters', searchParams)
    },

    resetRequestFormsFilters_past({ commit, state }) {
        let requestFormsFilters = {... state.requestFormsFilters }
        requestFormsFilters.past = false
        commit('updateRequestFormsFilters', requestFormsFilters)
    }
}

const mutations = {
    updateRequestFormsFilters(state, searchParams) {
        state.requestFormsFilters = { ...searchParams }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
