<template>
    <v-dialog v-model="modalValue" max-width="500" scrollable>
        <v-card>
            <v-toolbar
                class="informational-modal"
                elevation="0"
            >
                <v-toolbar-title class="text--h2">{{ reportPreset }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeModal">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text style="height: 500px;">
                <div class="mb-3 mt-5">
                    <v-icon color="primary">mdi-calendar</v-icon>
                    <span class="primary--text ml-1">Date Created</span>
                    <div class="text-h6 black--text" style="margin-left: 33px;">
                        {{ report.created_at }}
                    </div>
                </div>
                <div class="mb-3 mt-5">
                    <v-icon color="primary">mdi-calendar-range</v-icon>
                    <span class="primary--text ml-1">Date Range</span>
                    <div class="text-h6 black--text" style="margin-left: 33px;">
                        Start: {{ reportStartDateInUserTimezone }}
                    </div>
                    <div class="text-h6 black--text" style="margin-left: 33px;">
                        End: {{ reportEndDateInUserTimezone }}
                    </div>
                </div>
                <div class="mb-3 mt-5">
                    <v-icon color="primary">mdi-calendar-range</v-icon>
                    <span class="primary--text ml-1">Original Date Range</span>
                    <div class="text-h6 black--text" style="margin-left: 33px;">
                        Start: {{ reportStartDateInOriginalTimezone }}
                    </div>
                    <div class="text-h6 black--text" style="margin-left: 33px;">
                        End: {{ reportEndDateInOriginalTimezone }}
                    </div>
                </div>
                <div v-for="(filter, name, index) in reportFilters" :key="index" class="mb-3">
                    <v-icon color="primary">{{ filter.icon }}</v-icon>
                    <span class="primary--text ml-1">{{ filter.name }}</span>
                    <div v-for="filterName in filter.values" class="text-h6 black--text" style="margin-left: 33px;">
                        {{ filterName }}
                    </div>
                </div>
                <div v-if="viewReport" class="d-flex justify-end">
                    <v-btn
                        color="primary"
                        outlined
                        :href="reportHref"
                    >
                        View Report
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    parseReportDateRange,
    parseReportPresetName
} from "../../../lib/helpers/reports";

export default {
    name: "ReportDetailsModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({})
        },
        viewReport: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        reportHref() {
            return `/reports/${this.report._id}`
        },
        reportPreset() {
            const presetName = parseReportPresetName(this.report)
            if(presetName === 'N/A') {
                return 'Report Details'
            }
            return presetName
        },
        reportDate() {
            return parseReportDateRange(this.report, this.globalTimezone)
        },
        reportStartDateInUserTimezone () {
            const start = this.report?.start
            if (start) {
                return moment(start).tz(this.globalTimezone).format('MM/DD/YYYY hh:mm A')
            }
            return null
        },
        reportEndDateInUserTimezone () {
            const end = this.report?.end
            if (end) {
                return moment(end).tz(this.globalTimezone).format('MM/DD/YYYY hh:mm A')
            }
            return null
        },
        reportStartDateInOriginalTimezone () {
            const start = this.report?.start
            if (start) {
                return moment(start).tz(this.report.timezone).format('MM/DD/YYYY hh:mm A (z)')
            }
            return null
        },
        reportEndDateInOriginalTimezone () {
            const end = this.report?.end
            if (end) {
                return moment(end).tz(this.report.timezone).format('MM/DD/YYYY hh:mm A (z)')
            }
            return null
        },
        reportFilters() {
            const filters = Object.keys(this.report.filters).reduce((filters, key) => {
                const filterValue = this.report.filters[key]
                if(filterValue) {
                    if(Array.isArray(filterValue)) {
                        if(key === 'staff_members') {
                            return {
                                ...filters,
                                [key]: filterValue.map(staffMember => ({
                                    _id: staffMember._id,
                                    name: `${staffMember.first_name} ${staffMember.last_name}`
                                }))
                            }
                        } else if(key === 'statuses' || key === 'status') {
                            return {
                                ...filters,
                                [key]: filterValue.map((status, index) => ({
                                    _id: index,
                                    name: status
                                }))
                            }
                        } else {
                            return {
                                ...filters,
                                [key]: filterValue
                            }
                        }
                    }
                    return {
                        ...filters,
                        [key]: 'N/A'
                    }
                }
                return {
                    ...filters,
                    [key]: 'N/A'
                }
            }, {})
            const result = {}
            if(filters.hasOwnProperty('locations')) {
                result['locations'] = {
                    name: 'Locations',
                    icon: 'mdi-city',
                    values: this.getFilterValues(filters.locations)
                }
            }
            if(filters.hasOwnProperty('departments')) {
                result['departments'] = {
                    name: 'Departments',
                    icon: 'mdi-domain',
                    values: this.getFilterValues(filters.departments)
                }
            }
            if(filters.hasOwnProperty('staff_groups')) {
                result['staff_groups'] = {
                    name: 'Staff Groups',
                    icon: 'mdi-account-group',
                    values: this.getFilterValues(filters.staff_groups)
                }
            }
            if(filters.hasOwnProperty('job_titles')) {
                result['job_titles'] = {
                    name: 'Job Titles',
                    icon: 'mdi-clipboard-account',
                    values: this.getFilterValues(filters.job_titles)
                }
            }
            if(filters.hasOwnProperty('requirements')) {
                result['requirements'] = {
                    name: 'Credentials',
                    icon: 'mdi-license',
                    values: this.getFilterValues(filters.requirements)
                }
            }
            if(filters.hasOwnProperty('shift_times')) {
                result['shift_times'] = {
                    name: 'Shift Times',
                    icon: 'mdi-clock-outline',
                    values: this.getFilterValues(filters.shift_times)
                }
            }
            if(filters.hasOwnProperty('staff_members')) {
                result['staff_members'] = {
                    name: 'Staff Members',
                    icon: 'mdi-account',
                    values: this.getFilterValues(filters.staff_members)
                }
            }
            if(filters.hasOwnProperty('statuses')) {
                result['statuses'] = {
                    name: 'Status',
                    icon: 'mdi-reload',
                    values: this.getFilterValues(filters.statuses)
                }
            }
            if(filters.hasOwnProperty('status')) {
                result['status'] = {
                    name: 'Status',
                    icon: 'mdi-reload',
                    values: this.getFilterValues(filters.status)
                }
            }
            return result
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        closeModal() {
            this.modalValue = false
        },
        getFilterValues(filter) {
            if(Array.isArray(filter) && filter.length > 0) {
                return filter.map(f => f.name)
            }
            return ['N/A']
        },
    }
}
</script>

<style scoped>

</style>
