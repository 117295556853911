<template>
    <v-row>
        <v-col cols="auto" class="ds-modal-input-container">
            <v-switch
                v-model="enabled"
                color="primary"
                inset
                :readonly="readonly"
                @change="onChange"
            />
        </v-col>
        <v-col cols="3" class="d-flex flex-column justify-center ds-modal-input-container">
            <span class="text--h4" :style="dayStyle">
                {{ day }}
            </span>
        </v-col>
        <v-col class="ds-modal-input-container">
            <time-picker
                v-model="startTime"
                :key="startKey"
                label="Start"
                :disabled="!enabled || readonly"
                :max="endTime"
            ></time-picker>
        </v-col>
        <v-col class="ds-modal-input-container">
            <time-picker
                v-model="endTime"
                :key="endKey"
                label="End"
                :disabled="!enabled || readonly"
                :min="startTime"
            ></time-picker>
        </v-col>
    </v-row>
</template>

<script>
import TimePicker from "./TimePicker";

export default {
    name: "EmploymentTypeAssignedWorkingHoursDay",
    components: { TimePicker },
    props: {
        value: {
            type: Object,
            default: () => ({
                enabled: false,
                startTime: null,
                endTime: null,
            })
        },
        day: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        startKey: 1111,
        endKey: 2222,
    }),
    computed: {
        enabled: {
            get() {
                return this.value.enabled
            },
            set(enabled) {
                this.$emit('input', {
                    ...this.value,
                    enabled,
                })
            }
        },
        startTime: {
            get() {
                return this.value.startTime
            },
            set(startTime) {
                this.$emit('input', {
                    ...this.value,
                    startTime,
                })
            }
        },
        endTime: {
            get() {
                return this.value.endTime
            },
            set(endTime) {
                this.$emit('input', {
                    ...this.value,
                    endTime,
                })
            }
        },
        dayStyle() {
            return {
                position: 'relative',
                top: '-12px',
                ...!this.enabled && { color: 'rgba(0,0,0,0.38) !important' }
            }
        }
    },
    methods: {
        onChange() {
            this.startKey = this.startKey + 1
            this.endKey = this.endKey + 1
        }
    }
}
</script>

<style scoped>

</style>
