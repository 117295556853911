<template>
    <div>
        <div class="time-container" v-if="weekly">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="d-flex flex-column"
            >
                <div>{{ item.initial }}</div>
                <v-checkbox
                    color="#4152C5"
                    :value="item.selected"
                    :disabled="item.disabled"
                    @click="onClick(index)"
                />
            </div>
        </div>
        <div class="time-container" v-else>
            <time-circle
                v-for="(item, index) in items"
                :key="index"
                :initial="item.initial"
                :selected="item.selected"
                :disabled="item.disabled"
                @onClick="onClick(index)"
            ></time-circle>
        </div>
    </div>
</template>

<script>
import TimeCircle from './TimeCircle'

export default {
    name: "TimeContainer",
    components: { TimeCircle },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        weekly: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onClick (index) {
            this.$emit('onClick', index)
        }
    }
}
</script>

<style scoped>
    .time-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 2px;
        column-gap: 5px;
    }
</style>
