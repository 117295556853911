import { render, staticRenderFns } from "./LocationsView.vue?vue&type=template&id=5fb12164&scoped=true"
import script from "./LocationsView.vue?vue&type=script&lang=js"
export * from "./LocationsView.vue?vue&type=script&lang=js"
import style0 from "./LocationsView.vue?vue&type=style&index=0&id=5fb12164&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb12164",
  null
  
)

export default component.exports