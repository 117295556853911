<template>
    <div class="calendar-counts-container">
        <div class="calendar-counts-grouping-view px-2">
            <calendar-details
                :icon="icon"
                :text="text"
                large
            ></calendar-details>
        </div>
        <div class="calendar-counts-view">
            <div class="calendar-counts-view-inner">
                <div
                    v-if="currentDay.exists"
                    class="current-day-gradient"
                    :style="{ left: `${14.25 * currentDay.index}%` }"
                >
                    <div class="current-day-container px-3">
                        <div class="calendar-counts-value-container">
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        class="d-flex align-center"
                                    >
                                        <v-icon color="#0D0A32">mdi-calendar-check</v-icon>
                                        <span class="ml-1 text--label">
                                        {{  isStaffView ? counts[currentDay.index].assigned : (counts[currentDay.index].total - counts[currentDay.index].open) }}
                                    </span>
                                    </div>
                                </template>
                                <span>{{ isStaffView ? `Filled ${text} shifts` : `Filled shifts in ${text}` }}</span>
                            </v-tooltip>
                        </div>
                        <div class="calendar-counts-value-container">
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        class="d-flex align-center"
                                    >
                                        <v-icon color="#0D0A32">mdi-calendar</v-icon>
                                        <span class="ml-1 text--label">
                                        {{ counts[currentDay.index].total }}
                                    </span>
                                    </div>
                                </template>
                                <span>{{ isStaffView ? `Total ${text} shifts` : `Total shifts in ${text}` }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <div
                    v-for="(item, index) in counts"
                    :key="index"
                    class="calendar-counts-daily-container px-3"
                >
                    <div class="calendar-counts-value-container" v-if="!(currentDay.exists && currentDay.index === index)">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="d-flex align-center"
                                >
                                    <v-icon color="#0D0A32">mdi-calendar-check</v-icon>
                                    <span class="ml-1 text--label">
                                        {{ isStaffView ? item.assigned : (item.total - item.open) }}
                                    </span>
                                </div>
                            </template>
                            <span>{{ isStaffView ? `Filled ${text} shifts` : `Filled shifts in ${text}` }}</span>
                        </v-tooltip>
                    </div>
                    <div class="calendar-counts-value-container" v-if="!(currentDay.exists && currentDay.index === index)">
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="d-flex align-center"
                                >
                                    <v-icon color="#0D0A32">mdi-calendar</v-icon>
                                    <span class="ml-1 text--label">
                                        {{ item.total }}
                                    </span>
                                </div>
                            </template>
                            <span>{{ isStaffView ? `Total ${text} shifts` : `Total shifts in ${text}` }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CalendarDetails from './CalendarDetails'

export default {
    name: "CalendarCounts",
    components: { CalendarDetails },
    props: {
        icon: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        counts: {
            type: Array,
            default: () => [],
        },
        startOfWeek: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        scheduleViewType () {
            return this.$store.getters.schedulerGetScheduleViewType
        },
        isStaffView () {
            return this.scheduleViewType === 'staff'
        },
        currentDay () {
            let exists = false
            let index = 0
            let dayOfWeek = null
            let dayOfMonth = null
            if (this.startOfWeek) {
                const now = moment().tz(this.globalTimezone)
                const startOfWeek = this.startOfWeek.clone()
                _.range(7).forEach(i => {
                    const isToday = startOfWeek.isSame(now, 'day')
                    if (isToday) {
                        exists = true
                        index = i
                        dayOfWeek = startOfWeek.format('ddd').toUpperCase()
                        dayOfMonth = startOfWeek.format('D')
                    }
                    startOfWeek.add(1, 'days')
                })
            }
            return {
                exists,
                index,
                dayOfWeek,
                dayOfMonth,
            }
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    }
}
</script>

<style scoped>
    .calendar-counts-container {
        display: flex;
        flex-direction: row;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 6;
    }

    .calendar-counts-grouping-view {
        min-width: 15%;
        max-width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 2px solid #A9A9A9;
    }

    .calendar-counts-view {
        min-width: auto;
        border-bottom: 2px solid #A9A9A9;
        width: 100%;
        min-height: 45px;
    }

    .calendar-counts-view-inner {
        height: 100%;
        width: 100%;
        margin: 0;
        display: flex;
        background-image: repeating-linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            transparent 1px 100%
        );
        background-repeat: repeat;
        background-size: 14.25% 71px;
        position: relative;
    }

    .calendar-counts-daily-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex: 1;
    }

    .calendar-counts-value-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .calendar-counts-text {
        color: #0D0A32;
    }

    .current-day-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .current-day-gradient {
        position: absolute;
        background-image: linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            rgba(240, 240, 240, 0.6) 1px 100%
        );
        width: 14.25%;
        height: 100%;
    }
</style>
