<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-progress-linear indeterminate></v-progress-linear>
                <v-skeleton-loader
                    type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    type="image"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PresetListLoading"
}
</script>

<style scoped>

</style>
