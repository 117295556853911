<template>
    <generic-report-filters
        v-model="selectedPreset"
        :preset-key="presetKey"
        :report-type="reportType"
        :readonly="readonly"
        :location="location"
        @onEditPreset="onEditPreset"
    >
        <template v-slot:report-filters>
            <shift-cancellation-filters
                v-model="selectedFilters"
                :key="filtersKey"
                :readonly="readonly"
                :preset="selectedPreset"
                :report-type="reportType.value"
                :location="location"
                @onChange="onChangeFilters"/>
        </template>
        <template v-slot:report-modal>
            <edit-shift-cancellation-preset-modal
                v-if="modalValue"
                v-model="modalValue"
                :key="modalKey"
                :preset="selectedPreset"
                :report-type="reportType.value"
                :location="location"
                @onUpdatePreset="onUpdatePreset"
            />
        </template>
    </generic-report-filters>
</template>

<script>
import GenericReportFilters from "../GenericReportFilters";
import ShiftCancellationFilters from "../../Filters/ShiftCancellationFilters";
import EditShiftCancellationPresetModal from "../../Modals/Edit/ShiftCancellation/EditShiftCancellationPresetModal";


export default {
    name: "ShiftCancellationReportFilters",
    components: {GenericReportFilters, ShiftCancellationFilters, EditShiftCancellationPresetModal},
    props: {
        value: {
            type: Object,
            default: () => null,
        },
        reportType: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        isQuickReport: {
            type: Boolean,
            default: false,
        },
        quickReportPreset: {
            type: Object,
            default: () => null
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedPreset: null,
        presetKey: 6666,
        selectedFilters: {},
        filtersKey: 7777,
        modalKey: 8888,
        modalValue: false,
    }),
    computed: {
        filters() {
            return {
                preset: this.selectedPreset,
                ...this.selectedFilters
            }
        },
    },
    methods: {
        onEditPreset() {
            this.modalKey = this.modalKey + 1
            this.modalValue = true
        },
        onUpdatePreset(preset) {
            this.selectedPreset = {...preset}
            this.presetKey = this.presetKey + 1
            this.filtersKey = this.filtersKey + 1
            this.$emit('onUpdatePreset')
        },
        onChangeFilters() {
            this.selectedPreset = null
        }
    },
    watch: {
        filters(filters) {
            this.$emit('input', filters)
        },
        value(filters) {
            if (filters && !filters.preset && this.selectedPreset) {
                this.selectedPreset = null
            }
        }
    },
    created() {
        if (this.isQuickReport && this.quickReportPreset) {
            this.selectedPreset = {...this.quickReportPreset}
        }
    }
}
</script>

<style scoped>

</style>
