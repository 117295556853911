<template>
    <v-dialog v-model="modalValue" scrollable width="500px" persistent>
        <v-card>
            <v-toolbar class="destructive-modal" elevation="0">
                <v-toolbar-title>
                    <div class="d-flex">
                        <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                        <div class="mt-1 text--h2">
                            {{ title }}
                        </div>
                    </div>
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-6 black--text text--paragraph">
                <p>
                    {{ subtitle }}
                </p>
                <p v-if="recurrent">
                    Would you like to delete:
                    <v-radio-group v-model="recurrentOption" mandatory>
                        <v-radio value="solo">
                            <template v-slot:label>
                                <div class="black--text text--label">
                                    This shift
                                    <span v-if="date">
                                        ({{ date }})
                                    </span>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio value="group" v-if="isFromStaffingAutomation">
                            <template v-slot:label>
                                <div class="black--text text--label">
                                    This shift group
                                    <span v-if="dateRange">
                                        ({{ dateRange }})
                                    </span>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio value="recurrent">
                            <template v-slot:label>
                                <div class="black--text text--label">
                                    This and following shifts
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </p>
                <p>
                    This action can't be reversed.
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onGoBack">Cancel</v-btn>
                        <v-btn class="ml-2" color="error" outlined @click="onDelete">Delete</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteShiftModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        recurrent: {
            type: Boolean,
            default: false,
        },
        date: {
            type: String,
            default: null,
        },
        isFromStaffingAutomation: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        recurrentOption: 'solo',
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        title() {
            if (this.recurrent) {
                if (this.isFromStaffingAutomation) {
                    return 'Delete Automated Shift'
                }
                return 'Delete Repeating Shift'
            }
            return 'Delete Shift'
        },
        subtitle() {
            if (this.recurrent) {
                if (this.isFromStaffingAutomation) {
                    return 'You are deleting an automated shift.'
                }
                return 'You are deleting a repeating shift.'
            }
            return 'Would you like to delete this shift?'
        },
        datesById() {
            return this.$store.getters.getDatesById
        },
        dateRange() {
            const dates = this.datesById?.values
            if (Array.isArray(dates) && dates.length > 1) {
                const sorted = Array.from(dates).sort((a, b) => a.date - b.date)
                return `${sorted[0].date.format('MMMM D, YYYY')} - ${sorted[sorted.length - 1].date.format('MMMM D, YYYY')}`
            }
            return null
        },
    },
    methods: {
        onGoBack() {
            this.modalValue = false
        },
        onDelete() {
            this.modalValue = false
            this.$emit('onDelete', this.recurrentOption !== 'solo', this.recurrentOption === 'group')
        },
    }
}
</script>

<style scoped>
.label-text {
    margin-top: 3px;
}
</style>
