<template>
    <v-dialog v-model="showModal" scrollable max-width="800px" min-width="260px" persistent>

        <v-card dusk="availabilityEventModal">
            <v-toolbar elevation="0" class="informational-modal">
                <v-toolbar-title class="text--h2">Availability Period</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text v-if="details_loading">
                <div class="text-center ma-10">
                    <v-progress-circular dusk="loadingSpinner" :size="60" indeterminate color="primary"></v-progress-circular>
                </div>
            </v-card-text>

            <v-card-text v-if="!details_loading" class="pl-8 pr-8 pb-0">
                <div v-if="api.busy" id="saving-loading-overlay">
                    <span><v-progress-circular color="primary" indeterminate></v-progress-circular></span>
                </div>

                <v-form
                    ref="availabilityPeriodForm"
                    v-model="valid"
                >

                    <v-row class="pt-4">

                        <v-col cols="12" class="pb-2">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span :style="{ color: themePrimaryColor }" class="text--h4">Duration</span>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-col>

                        <v-col cols="12" class="pt-0">
                            <v-dialog
                                ref="startDatePicker"
                                v-model="startDatePickerModal"
                                :return-value.sync="availability_period.start_date"
                                persistent
                                width="290px"
                                :disabled="!editable || isInPast"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="formatted_start_date"
                                        label="Date"
                                        prepend-inner-icon="mdi-calendar-blank"
                                        readonly
                                        :clearable="editable && !isInPast"
                                        @click:clear="clearStartDate"
                                        :rules="rules.required"
                                        :error-messages="api.hasError('start_date')"
                                        v-on="on"
                                        dusk="availabilityStartDate"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-if="startDatePickerModal"
                                    v-model="availability_period.start_date"
                                    full-width
                                    locale="en-US"
                                    color="secondary"
                                    :min="minimum_start_date"
                                    :picker-date.sync="date_picker_start_date"
                                    :readonly="!editable || isInPast"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="startDatePickerModal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="updateStartDate(availability_period.start_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>

                        <v-col cols="12" md="4" class="pt-0">
                            <time-picker
                                v-model="availability_period.start_time"
                                label="Start Time"
                                :disabled="!editable || !start_date"
                                :readonly="!editable || isInPast"
                                :error-messages="api.hasError('start_time')"
                                :hint="start_time_hint"
                                @onClearTime="clearStartTime"
                                @onUpdateTime="updateStartTime" />
                        </v-col>

                        <v-col cols="12" md="4" class="pt-0">
                            <duration-input
                                v-model="duration"
                                :disabled="!start_date || !start_time"
                                :readonly="!editable || isInPast"
                                :error-messages="api.hasError('duration')"
                                @onChangeDuration="durationChanged"
                            />
                        </v-col>

                        <v-col cols="12" md="4" class="pt-0">
                            <time-picker
                                v-model="availability_period.end_time"
                                label="End Time"
                                :disabled="!editable || !start_date || !start_time"
                                :readonly="!editable || isInPast"
                                :error-messages="api.hasError('end_time')"
                                :hint="end_time_hint"
                                @onClearTime="clearEndTime"
                                @onUpdateTime="updateEndTime" />
                        </v-col>


                        <v-col cols="12" class="pb-0">
                            <v-row no-gutters>
                                <v-col cols="10" md="2">
                                    <span :style="{ color: themePrimaryColor }" class="text--h4">Recurrence</span>
                                </v-col>
                                <v-col cols="2" md="10">
                                    <v-checkbox
                                        dusk="recurrenceCheckbox"
                                        :readonly="!editable || isInPast || !!original_recurrence_settings"
                                        :error-messages="api.hasError('recurring')"
                                        v-model="availability_period.should_repeat"
                                        class="pt-0 mt-0"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                    <v-expand-transition>

                        <v-row v-if="should_repeat" no-gutters dusk="recurrenceSection">
                            <v-row>
                                <v-col cols="12" md="3" class="pl-3 pt-6">
                                    <label>Repeat every</label>
                                </v-col>

                                <v-col cols="6" md="3" class="pt-0 pb-0">
                                    <number-input
                                        label="Number"
                                        controls
                                        :min="minimum_repeat_interval"
                                        v-model.number="availability_period.repeat_interval"
                                        :rules="required_repeat_interval"
                                        :readonly="!editable || isInPast"
                                        :error-messages="api.hasError('repeat_interval')"
                                    />
                                </v-col>

                                <v-col cols="6" md="3" class="pt-0 pb-0">
                                    <v-autocomplete
                                        dusk="repeatPeriod"
                                        label="Timeframe"
                                        v-model="availability_period.repeat_period"
                                        :items="repeat_periods"
                                        :rules="rules.required"
                                        :readonly="!editable || isInPast"
                                        :error-messages="api.hasError('repeat_period')"
                                        @change="repeatPeriodChanged"
                                        outlined
                                    >
                                        <template v-slot:selection="item">
                                            {{ item.item | capitalize | pluralize(repeat_interval) }}
                                        </template>

                                        <template v-slot:item="item">
                                            {{ item.item | capitalize | pluralize(repeat_interval) }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row v-if="repeat_period === 'week'">
                                <v-col cols="12" md="3" class="pl-3">
                                    <label>Repeat on</label>
                                </v-col>

                                <v-col cols="12" md="7" class="pt-0 pb-0">
                                    <v-btn
                                        v-for="day in week_days"
                                        :key="day.day_index"
                                        class="repeat-day"
                                        :data-day="day.short_name"
                                        :class="{'repeat-day-selected': day.repeat}"
                                        :disabled="!editable || isInPast || !start_date_day_index || !repeat_interval || day.disabled"
                                        @click="toggleDayRepeat(day.day_index)"
                                        elevation="0"
                                        small
                                        fab
                                    >
                                        {{ day.initial }}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="3" class="pt-5 pl-3">
                                    <label>Ends</label>
                                </v-col>

                                <v-col cols="12" md="9" class="pt-0 pb-0">
                                    <v-radio-group :readonly="!editable || isInPast" v-model="end_option">
                                    <v-row >
                                        <v-col cols="3" md="2" class="pt-4 pb-0">
                                            <v-radio dusk="recurrenceEndsOnDateOption" label="On" value="date" class="pt-0"></v-radio>
                                        </v-col>
                                        <v-col cols="6" class="pt-0 pb-0">
                                            <v-dialog
                                                ref="endsOnDatePicker"
                                                v-model="endsOnDatePickerModal"
                                                :return-value.sync="availability_period.recurrence_end_date"
                                                :disabled="!editable || isInPast || end_option === 'occurrences'"
                                                persistent
                                                width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        dusk="recurrenceEndDate"
                                                        :value="formatted_recurrence_end_date"
                                                        :rules="required_end_date"
                                                        :disabled="!editable || end_option === 'occurrences'"
                                                        :error-messages="api.hasError('recurrence_end_date')"
                                                        label="End Date"
                                                        prepend-inner-icon="mdi-calendar-blank"
                                                        :clearable="editable && !isInPast"
                                                        @click:clear="clearEndsOnDate"
                                                        readonly
                                                        outlined
                                                        v-on="on"
                                                        class="pt-0"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-if="endsOnDatePickerModal"
                                                    v-model="availability_period.recurrence_end_date"
                                                    full-width
                                                    locale="en-US"
                                                    color="secondary"
                                                    :min="minimum_recurrence_end_date"
                                                    :max="maximum_recurrence_end_date"
                                                    :picker-date.sync="date_picker_start_date"
                                                    :readonly="!editable || isInPast"
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="endsOnDatePickerModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="updateEndsOnTime(availability_period.recurrence_end_date)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-col>
                                        <v-col cols="2" md="4" class="pt-0 pb-0"></v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="3" md="2" class="pt-4 pb-0">
                                            <v-radio dusk="recurrenceEndsAfterOccurrencesOption" label="After" value="occurrences" class="pt-0"></v-radio>
                                        </v-col>

                                        <v-col cols="5" md="6" class="pt-0 pb-0">
                                            <number-input
                                                :min="1"
                                                :max="100"
                                                controls
                                                v-model.number="availability_period.occurrences"
                                                :rules="required_end_occurrences"
                                                :disabled="end_option === 'date'"
                                                :readonly="!editable || isInPast"
                                                :error-messages="api.hasError('occurrences')"
                                                @change="occurrencesNumberChanged"
                                                @input="occurrencesNumberChanged"
                                            ></number-input>
                                        </v-col>
                                        <v-col cols="4" id="occurrencesText">
                                            <label class="grey--text">occurrences</label>
                                        </v-col>
                                        <v-col cols="0" md="3" class="pt-0 pb-0"></v-col>
                                    </v-row>

                                    </v-radio-group>

                                </v-col>
                            </v-row>
                        </v-row>
                    </v-expand-transition>

                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-row v-if="!details_loading" no-gutters>
                    <v-col>
                        <v-btn
                            dusk="deleteAvailabilityEventButton"
                            v-if="(!isNewEntry && editable) && (!isInPast)"
                            color="error"
                            text
                            :loading="api.busy"
                            @click="attemptDelete"
                        >
                            <v-icon>delete</v-icon> Delete
                        </v-btn>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col class="text-right">
                        <v-btn
                            v-if="editable && (!isInPast)"
                            color="secondary"
                            :loading="api.busy"
                            :disabled="saving_is_disabled"
                            @click="attemptSave"
                            dusk="saveAvailabilityButton"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>

        </v-card>

        <delete-availability-modal
            v-if="!isNewEntry"
            v-model="showDeleteModal"
            :availability-event="availability_period"
            @delete="deleteEvent"
        ></delete-availability-modal>

        <update-availability-modal
            v-if="!isNewEntry"
            v-model="showUpdateModal"
            :availability-event="availability_period"
            :pre-selected-option="update_modal_option"
            @confirm="confirmSave"
        ></update-availability-modal>

        <overlapping-events-modal
            v-model="showOverlappingEventsModal"
            :events="overlapping_events"
            :timezone="timezone_name"
            @merge="confirmMerge"
        ></overlapping-events-modal>

        <duration-exceeded-modal
            v-model="showMaxDurationExceededModal"
        ></duration-exceeded-modal>

        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-dialog>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";
import timeRangePickerMethods from "../../../lib/mixins/timeRangePickerMethods";
import TimePicker from "../../common/TimeRangePicker/TimePicker";
import DurationInput from "../../common/TimeRangePicker/DurationInput";
import DeleteAvailabilityModal from "./DeleteAvailabilityModal";
import OverlappingEventsModal from "./OverlappingEventsModal";
import UpdateAvailabilityModal from "./UpdateAvailabilityModal";
import DurationExceededModal from "./DurationExceededModal";
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";
import NumberInput from "../../common/NumberInput/NumberInput.vue";

export default {
    name: "AvailabilityEventModal",
    components: {
        NumberInput,
        UnsavedChangesDialog,
        DurationExceededModal,
        UpdateAvailabilityModal, OverlappingEventsModal, DeleteAvailabilityModal, TimePicker, DurationInput},
    mixins: [validationRules, timeRangePickerMethods],
    props: {
        value: {
            type: Boolean,
            default: false
        },

        editable: {
            type: Boolean,
            default: false
        },

        availabilityPeriod: {
            type: Object,
            default: () => {}
        },

        user: {
            type: Object,
            default: () => {}
        },

        availabilityCalendarDate: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            themePrimaryColor: this.$vuetify.theme.themes.light.primary,

            showModal: this.value,
            showDeleteModal: false,
            showUpdateModal: false,

            valid: false,
            api: new formHelper(),
            details_loading: false,

            startDatePickerModal: false,
            endsOnDatePickerModal: false,
            startTimePickerModal: false,
            endTimePickerModal: false,

            repeat_periods: ['day', 'week'],
            week_days: [
                {short_name: 'Sun', initial: 'S', repeat: false, day_index: 7, allow_deselect: true, disabled: false},
                {short_name: 'Mon', initial: 'M', repeat: false, day_index: 1, allow_deselect: true, disabled: false},
                {short_name: 'Tue', initial: 'T', repeat: false, day_index: 2, allow_deselect: true, disabled: false},
                {short_name: 'Wed', initial: 'W', repeat: false, day_index: 3, allow_deselect: true, disabled: false},
                {short_name: 'Thu', initial: 'T', repeat: false, day_index: 4, allow_deselect: true, disabled: false},
                {short_name: 'Fri', initial: 'F', repeat: false, day_index: 5, allow_deselect: true, disabled: false},
                {short_name: 'Sat', initial: 'S', repeat: false, day_index: 6, allow_deselect: true, disabled: false},
            ],

            end_option: 'date',
            availability_period: {},

            customTokens: {
                'h': {pattern: /[0-3]/},
                'H': {pattern: /[0-9]/},
                'm': {pattern: /[0-5]/},
                'M': {pattern: /[0-9]/}
            },
            duration: null,

            showMaxDurationExceededModal: false,
            showOverlappingEventsModal: false,
            merge_overlapping: false,
            overlapping_events: [],

            authUser: this.$user,
            data_refresh_required: false,

            original_recurrence_settings: null,
            original_availability_event: null,
            update_modal_option: 'single',
            minimum_repeat_interval: 1,

            save_type: 'single',
            details_were_changed: false,

            date_picker_start_date: this.availabilityCalendarDate ? moment(this.availabilityCalendarDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        }
    },

    methods: {
        close() {
            if (this.isDirty) {
                this.showUnsavedChangesModal()
            } else {
                this.closeModal()
            }
        },

        showUnsavedChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {})
        },

        closeModal() {
            if (this.data_refresh_required) {
                this.data_refresh_required = false
                this.$emit('refreshRequired', true)
            }
            this.showModal = false
            this.clearData()
        },

        clearData() {
            this.availability_period = {}
            this.end_option = 'date'
            this.clearDuration()
        },

        attemptSave() {
            if (!this.$refs.availabilityPeriodForm.validate()) {
                return
            }

            if (!this.availability_period.should_repeat || this.isNewEntry) {
                this.save_type = 'single'
                this.save()
            } else if (this.availability_period.should_repeat && !this.original_recurrence_settings) {
                this.save_type = 'recurring'
                this.save()
            } else {
                this.update_modal_option = this.recurrenceWasChanged() ? 'recurring' : 'single'
                this.showUpdateModal = true
            }
        },

        confirmSave(save_type = 'single') {
            this.save_type = save_type
            this.save()
        },

        save() {
            if (!this.$refs.availabilityPeriodForm.validate()) {
                return
            }

            this.formatDataForSaving()

            let merge_overlapping = this.merge_overlapping
            this.merge_overlapping = false
            this.overlapping_events = []

            this.api.post('/availability/save/' + this.user._id,
                {availability_data: this.availability_period, merge_overlapping: merge_overlapping, save_type: this.save_type})
                .then(({data, message}) => {
                    if (typeof data.status === 'undefined') {
                        this.$snackNotify('warning', 'An unknown error has occurred. Please contact Koroid support: support@koroid.com.')
                        return
                    }

                    if (data.status === 'max_duration_exceeded') {
                        this.showMaxDurationExceededModal = true
                        return
                    }

                    if (data.status === 'overlap_found') {
                        this.showOverlapModal(data.overlapping_events)
                        return
                    }

                    if (data.status === 'success') {

                        if (typeof data.reload_required !== 'undefined' && data.reload_required) {
                            this.$emit('availabilityMerged', data.event)
                        } else {
                            this.$emit('availabilityUpdated', data.event)
                        }

                        if (typeof data.has_upcoming_availability !== 'undefined') {
                            this.$emit('upcomingAvailabilityInfoReceived', data.has_upcoming_availability)
                        }

                        //if modal should stay open after save
                        // this.loadExisting(data.event)
                        this.$snackNotify('success', 'Availability updated.')
                        this.closeModal()
                    }
                })
                .catch(() => {
                    this.data_refresh_required = true
                })
        },

        formatDataForSaving() {
            this.availability_period.user_id = this.user._id
            this.availability_period.timezone = this.globalTimezone
            this.availability_period.recurrence_end_type = this.end_option
            this.availability_period.duration = this.duration
            this.availability_period.recurring = !!this.availability_period.should_repeat

            if (!this.availability_period.should_repeat) {
                this.$delete(this.availability_period, 'repeat_interval')
                this.$delete(this.availability_period, 'repeat_period')
                this.$delete(this.availability_period, 'recurrence_end_type')
                this.$delete(this.availability_period, 'recurrence_end_date')
                this.$delete(this.availability_period, 'occurrences')
            } else {
                if (this.availability_period.repeat_period === 'day') {
                    this.$delete(this.availability_period, 'repeat_weekdays')
                    // delete specific month properties when added
                } else if (this.availability_period.repeat_period === 'week') {
                    this.availability_period.repeat_weekdays = this.repeating_weekdays
                    // delete specific month properties when added
                } else if (this.availability_period.repeat_period === 'month') {
                    this.$delete(this.availability_period, 'repeat_weekdays')
                }

                if (this.availability_period.recurrence_end_type === 'date') {
                    this.$delete(this.availability_period, 'occurrences')
                } else if (this.availability_period.recurrence_end_type === 'occurrences') {
                    this.$delete(this.availability_period, 'recurrence_end_date')
                }
            }
        },

        confirmMerge() {
            this.merge_overlapping = true
            this.save()
        },

        attemptDelete() {
            if (this.isNewEntry) {
                this.closeModal()
                return
            }

            this.showDeleteModal = true
        },

        deleteEvent(delete_type = 'single') {
            this.api.post('/availability/delete/' + this.availability_period._id, {delete_recurring: delete_type === 'recurring'})
                .then(({data, message}) => {
                    this.$snackNotify('success', 'Availability removed.')

                    if (typeof data.deleted_events !== 'undefined') {
                        this.$emit('availabilityRemoved', data.deleted_events)
                    } else {
                        this.data_refresh_required = true
                    }

                    if (typeof data.has_upcoming_availability !== 'undefined') {
                        this.$emit('upcomingAvailabilityInfoReceived', data.has_upcoming_availability)
                    }

                    this.closeModal()
                })
        },

        clearStartDate() {
            this.$set(this.availability_period, 'start_date', null)
            this.setSelectedWeekdayFromStartDate()
        },

        clearStartTime() {
            this.$set(this.availability_period, 'start_time', null)
            this.clearDuration()
            this.clearEndTime()
        },

        clearEndTime() {
            this.$set(this.availability_period, 'end_time', null)
        },

        clearEndsOnDate() {
            this.$set(this.availability_period, 'recurrence_end_date', null)
        },

        clearDuration() {
            this.duration = null
        },

        updateStartDate(data) {
            this.$refs.startDatePicker.save(data)
            if (!this.is_valid_recurrence_end_date()) {
                this.clearEndsOnDate()
            }

            this.setSelectedWeekdayFromStartDate()
        },

        updateStartTime(data) {
            if (this.end_time) {
                this.setDuration(data, this.end_time)
            } else if (this.duration) {
                this.setEndTime(data, this.duration)
            }
        },

        updateEndTime(data) {
            this.setDuration(this.start_time, data)
        },

        durationChanged(duration) {
            if (!duration) {
                this.clearEndTime()
            } else {
                this.setEndTime(this.start_time, duration)
            }
        },

        repeatPeriodChanged() {
            this.setSelectedWeekdayFromStartDate()
        },

        setDuration(start_time, end_time) {
            this.duration = this.getUpdatedDuration(start_time, end_time)
        },

        setEndTime(start_time, duration) {
            const end = this.getUpdatedEndTime(start_time, duration)
            if (end) {
                this.$set(this.availability_period, 'end_time', end)
            }
        },

        is_valid_recurrence_end_date() {
            if (!this.start_date || !this.recurrence_end_date) {
                return false
            }

            let start = moment(this.start_date)
            let end = moment(this.recurrence_end_date)

            return end.isAfter(start, 'd') && !end.diff(start, 'y')
        },

        downloadDetails(availability_event) {
            if (typeof availability_event._id === 'undefined') {
                console.log('Availability Event ID is missing')
                return
            }

            this.details_loading = true

            this.api.get('/availability/details/' + availability_event._id)
                .then(({data, message}) => {
                    this.details_loading = false
                    this.loadExisting(data)
                })
        },

        loadExisting(availability) {
            this.clearData()
            this.duration = availability.duration

            let start_tz = moment(availability.start_date).tz(this.timezone_name)
            let end_tz = moment(availability.end_date).tz(this.timezone_name)
            let availability_period = Object.assign({}, availability)

            availability_period.start_date = start_tz.format('YYYY-MM-DD')
            availability_period.end_date = end_tz.format('YYYY-MM-DD')
            availability_period.start_time = start_tz.format('HH:mm')
            availability_period.end_time = end_tz.format('HH:mm')

            let is_recurring = typeof availability.availability_event_group_id !== 'undefined' && availability.availability_event_group_id
                && typeof availability.availability_event_group !== 'undefined'

            // recurrence data here
            if (is_recurring) {
                const group = availability.availability_event_group
                availability_period.availability_event_group_id = availability.availability_event_group_id
                availability_period.should_repeat = true
                availability_period.repeat_interval = group.repeat_interval
                availability_period.repeat_period = group.repeat_period
                availability_period.recurrence_end_type = group.recurrence_end_type
                this.end_option = group.recurrence_end_type

                if (typeof group.recurrence_end_date !== 'undefined') {
                    group.recurrence_end_date = moment(group.recurrence_end_date).tz(this.timezone_name).format('YYYY-MM-DD')
                    availability_period.recurrence_end_date = group.recurrence_end_date
                }

                if (typeof group.occurrences !== 'undefined') {
                    availability_period.occurrences = group.occurrences
                    if (typeof group.remaining_occurrences !== 'undefined') {
                        availability_period.occurrences = group.remaining_occurrences
                    }
                }

                if (typeof group.repeat_weekdays !== 'undefined' && group.repeat_weekdays && group.repeat_weekdays.length) {
                    this.week_days = this.week_days.map(day => {
                        if (group.repeat_weekdays.includes(day.day_index)) {
                            day.repeat = true
                        }
                        return day
                    })
                }

                // save original recurrence settings for comparison whether they were changed

                this.original_recurrence_settings = {
                    should_repeat: is_recurring,
                    repeat_interval: group.repeat_interval || null,
                    repeat_period: group.repeat_period || null,
                    recurrence_end_type: group.recurrence_end_type || null,
                    recurrence_end_date: group.recurrence_end_date || null,
                    occurrences: group.remaining_occurrences || null,
                    repeat_weekdays: group.repeat_weekdays || null,
                }
            }

            this.availability_period = Object.assign({}, availability_period)

            this.original_availability_event = {
                start_date: availability_period.start_date || null,
                start_time: availability_period.start_time || null,
                duration: availability_period.duration || null,
                end_time: availability_period.end_time || null
            }

            let start_day = moment(availability_period.start_date).isoWeekday()
            //this.setDayRepeat(start_day, true, false)
            this.setDayRepeat(start_day, true, true)
        },

        showOverlapModal(events) {
            this.overlapping_events = events
            this.showOverlappingEventsModal = true
        },

        occurrencesNumberChanged() {
            this.end_option = 'occurrences'
        },

        updateEndsOnTime(data) {
            this.$refs.endsOnDatePicker.save(data)
            this.$set(this.availability_period, 'recurrence_end_date', data)
            this.end_option = 'date'
        },

        getCurrentRecurrenceSettings() {
            const { availability_period } = this

            if (!availability_period.should_repeat) {
                return null
            }

            return {
                should_repeat: availability_period.should_repeat || false,
                repeat_interval: availability_period.repeat_interval || null,
                repeat_period: availability_period.repeat_period || null,
                recurrence_end_type: this.end_option,
                recurrence_end_date: this.end_option === 'date' ? (availability_period.recurrence_end_date || null) : null,
                occurrences: this.end_option === 'occurrences' ? (availability_period.occurrences || null) : null,
                repeat_weekdays: availability_period.repeat_period === 'week' ? this.repeating_weekdays : null
            }
        },

        recurrenceWasChanged() {
            return (!!this.original_recurrence_settings !== !!this.getCurrentRecurrenceSettings()) ||
                !_.isEqual(this.original_recurrence_settings, this.getCurrentRecurrenceSettings())
        },

        eventDetailsWereChanged() {
            if (!this.original_availability_event) {
                return false
            }

            let event_details = {
                start_date: this.availability_period.start_date,
                start_time: this.availability_period.start_time,
                duration: this.availability_period.duration,
                end_time: this.availability_period.end_time
            }

            return !_.isEqual(event_details, this.original_availability_event)
        },

        detailsWereChanged() {
            return this.eventDetailsWereChanged() || this.recurrenceWasChanged()
        },

        toggleDayRepeat(day_index) {
            this.week_days = this.week_days.map(day => {
                if (day.day_index === day_index) {
                    if (day.repeat && (!day.allow_deselect || this.repeating_weekdays.length === 1)) {
                        return day
                    }

                    day.repeat = !day.repeat
                }

                return day
            })

            this.limitSelectableWeekdays()
        },

        setDayRepeat(day_index, value, allow_deselect = true, single_unselectable_allowed = true) {
            this.week_days = this.week_days.map(day => {
                if (day.day_index === day_index) {
                    day.repeat = value
                    day.allow_deselect = allow_deselect
                } else if (single_unselectable_allowed && !allow_deselect) {
                    day.allow_deselect = true
                }

                return day
            })

            this.limitSelectableWeekdays()
        },

        setSelectedWeekdayFromStartDate() {
            if (this.repeat_period !== 'week') {
                return
            }

            this.resetWeekDays()

            if (!this.start_date) {
                return
            }

            let start_day = moment(this.start_date).isoWeekday()

            this.setDayRepeat(start_day, true, false)
        },

        resetWeekDays() {
            this.week_days = this.week_days.map(day => {
                day.repeat = false
                day.allow_deselect = true
                day.disabled = false

                return day
            })
        },

        limitSelectableWeekdays() {
            if (this.repeat_period !== 'week' || !this.start_date_day_index || !this.duration) {
                return
            }

            // 1440 = minutes in 24 hours
            if (this.duration > 1440) {
                let days_length = this.week_days.length

                for (let i = 0; i < days_length; i++) {
                    let touches_repeating_day = false

                    // look forwards 1 day
                    if (i < 6) {
                        touches_repeating_day = this.week_days[i + 1].repeat
                    } else if (this.repeat_interval === 1) {
                        touches_repeating_day = this.week_days[0].repeat
                    }

                    // Look backwards 1 day
                    if (i > 0) {
                        touches_repeating_day = touches_repeating_day || this.week_days[i - 1].repeat
                    } else if (this.repeat_interval === 1) {
                        touches_repeating_day = touches_repeating_day || this.week_days[6].repeat
                    }

                    this.week_days[i].disabled = touches_repeating_day

                    if (touches_repeating_day) {
                        this.week_days[i].repeat = false
                    }
                }
            } else {
                this.week_days = this.week_days.map(day => {
                    day.disabled = false
                    return day
                })
            }

            if (this.start_date_day_index) {
                this.week_days = this.week_days.map(day => {
                    if (day.day_index === this.start_date_day_index) {
                        day.allow_deselect = false
                    }

                    return day
                })
            }

        },

        setMinimumRepeatInterval() {
            if (!this.repeat_period || this.repeat_period !== 'day' || !this.duration || this.duration <= (24 * 60)) {
                this.minimum_repeat_interval = 1
                return
            }

            if (this.repeat_interval && this.repeat_interval < 2) {
                this.$set(this.availability_period, 'repeat_interval', 2)
            }

            this.minimum_repeat_interval = 2
        },

    },

    computed: {
        isNewEntry() {
            return !this.availabilityPeriod
        },

        isInPast() {
            if (this.full_start_date) {
                return this.full_start_date.isBefore(moment(), 'd')
            }
        },

        globalTimezone() {
            return this.$root.globalTimezone
        },

        timezone_name() {
            if (typeof this.globalTimezone){
                return this.globalTimezone
            }

            return 'utc'
        },

        minimum_start_date() {
            return moment().format()
        },

        /* Rules */

        required_repeat_interval() {
            return [
                v => !!v || 'Required',
                v => v > 0 || 'Must be greater than 0',
                v => v <= 100 || 'Can not be greater than 100'
            ]
        },

        required_end_date() {
            if (this.end_option === 'date') {
                return [
                    v => !!v || 'Required',
                ]
            } else {
                return []
            }
        },

        required_end_occurrences() {
            if (this.end_option === 'occurrences') {
                return [
                    v => !!v || 'Required',
                    v => v > 0 || 'Must be greater than 0',
                    v => v <= 100 || 'Can not be greater than 100'
                ]
            } else {
                return []
            }
        },

        /* End Rules */

        /* BEGIN Null Checked Values from object */

        start_date() {
            return typeof this.availability_period.start_date === 'undefined' ? null : this.availability_period.start_date
        },

        end_date() {
            return !this.full_end_date ? null : this.full_end_date.format('YYYY-MM-DD')
        },

        start_time() {
            return typeof this.availability_period.start_time === 'undefined' ? null : this.availability_period.start_time
        },

        end_time() {
            return typeof this.availability_period.end_time === 'undefined' ? null : this.availability_period.end_time
        },

        full_start_date() {
            if (!this.start_date || !this.start_time) {
                return null
            }

            return moment(moment(this.start_date).format('YYYY-MM-DD') + ' ' + this.start_time)
        },

        full_end_date() {
            if (!this.full_start_date || !this.duration) {
                return null
            }

            let end = this.full_start_date.clone().add(this.duration, 'm')

            if (end.isBefore(this.full_start_date)) {
                end = end.add(1, 'd')
            }

            return end
        },

        start_date_day_index() {
            return this.start_date ? moment(this.start_date).isoWeekday() : null
        },

        recurrence_end_date() {
            return typeof this.availability_period.recurrence_end_date === 'undefined' ? null : this.availability_period.recurrence_end_date
        },

        should_repeat() {
            return typeof this.availability_period.should_repeat === 'undefined' ? null : this.availability_period.should_repeat
        },

        repeat_interval() {
            return typeof this.availability_period.repeat_interval === 'undefined' ? null : this.availability_period.repeat_interval
        },

        repeat_period() {
            return typeof this.availability_period.repeat_period === 'undefined' ? null : this.availability_period.repeat_period
        },

        /* END Null Checked Values from object */

        formatted_start_date() {
            return this.start_date ?  moment(this.start_date).format('MM/DD/YYYY') : null
        },

        formatted_start_time() {
            return this.start_time ? moment(moment().format('YYYY-MM-DD') + ' ' + this.start_time).format('h:mm A') : null
        },

        formatted_end_time() {
            return this.end_time ? moment(moment().format('YYYY-MM-DD') + ' ' + this.end_time).format('h:mm A') : null
        },

        minimum_recurrence_end_date() {
            return this.start_date ? this.start_date : moment().format()
        },

        maximum_recurrence_end_date() {
            if (!this.start_date) {
                return null
            }

            return moment(this.start_date).add(1, 'y').format()
        },

        formatted_recurrence_end_date() {
            return this.recurrence_end_date ? moment(this.recurrence_end_date).format('MM/DD/YYYY') : null
        },

        end_time_hint() {
            if (!this.start_date) {
                return 'Select a start date'
            }

            if (!this.start_time) {
                return 'Select a start time'
            }

            if (!this.full_start_date || !this.full_end_date) {
                return ''
            }

            if (this.full_end_date.isAfter(this.full_start_date, 'day')) {
                let diff = this.full_end_date.clone().startOf('day').diff(this.full_start_date.clone().startOf('day'), 'd')
                return this.full_end_date.format('MM/DD/YYYY') + ' (+' + diff + ' ' + this.$options.filters.pluralize('Day', diff) + ')'
            }

            return ''
        },

        start_time_hint() {
            if (!this.start_date) {
                return 'Select a start date'
            }

            return ''
        },

        repeating_weekdays() {
            return this.week_days.filter(day => day.repeat).map(day => day.day_index).sort((a, b) => a - b)
        },

        saving_is_disabled() {
            return (!!this.availabilityPeriod) && !this.details_were_changed
        },

        isDirty() {
            if(this.isNewEntry) {
                if(_.isEmpty(this.availability_period)) {
                    return false
                } else {
                    return Object.keys(this.availability_period)
                        .some(key => {
                            const value = this.availability_period[key]
                            return value !== null && typeof value !== 'undefined'
                        })
                }
            } else {
                return !this.saving_is_disabled
            }
        },
    },

    watch: {
        value(showModal) {
            this.showModal = showModal
        },

        showModal(showModal) {
            if (!showModal) {
                this.$emit('input', false)
            } else {
                if (this.availabilityPeriod) {
                    this.downloadDetails(this.availabilityPeriod)
                }
            }
        },

        availabilityCalendarDate(value) {
            this.date_picker_start_date = value ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        },

        start_date(start_date) {
            if ((start_date && this.recurrence_end_date) && (moment(start_date).isAfter(moment(this.recurrence_end_date)))) {
                this.clearEndsOnDate()
            }
        },

        duration() {
            this.limitSelectableWeekdays()
            this.setMinimumRepeatInterval()
        },

        repeat_interval() {
            this.setMinimumRepeatInterval()
        },

        repeat_period() {
            this.setMinimumRepeatInterval()
        },

        availability_period: {
            deep: true,
            handler: function() {
                this.details_were_changed = this.detailsWereChanged()
            }
        },

        repeating_weekdays() {
            this.details_were_changed = this.detailsWereChanged()
        },

        end_option() {
            this.details_were_changed = this.detailsWereChanged()
        }
    },
}
</script>

<style scoped>
    .repeat-day {
        background-color: #F4F6F7 !important;
        border-color: #F4F6F7;
        margin: 0 2px;
    }

    .repeat-day:first-child, .repeat-day:last-child {
        margin: 0;
    }

    .repeat-day-selected {
        background-color: #1B1464 !important;
        border-color: #1B1464;
        color: #FFFFFF;
    }

    #saving-loading-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.15);
        z-index: 2;
    }

    #saving-loading-overlay span {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    #occurrencesText {
        padding-top: 16px;
        padding-left: 0px;
        padding-bottom: 0px;
    }

</style>
