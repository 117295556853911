<template>
    <div class="onboarding-container ma-n5">
        <div class="onboarding-wrapper">
            <v-card height="100%" min-width="700px">
                <div class="onboarding-card">
                    <div class="onboarding-nav">
                        <div class="onboarding-stepper-container">
                            <stepper :active="active"/>
                        </div>
                        <div class="onboarding-person-crane-container">
                            <div class="person-crane-container">
                                <img
                                    src="/images/person-crane.png"
                                    alt="Person Crane Image"
                                    class="responsive-image">
                            </div>
                        </div>
                    </div>
                    <div class="onboarding-content">
                        <div class="koroid-logo-container">
                            <img
                                src="/images/koroid-logo.png"
                                alt="Koroid Logo Image"
                                class="responsive-image">
                        </div>
                        <div class="onboarding-content-wrapper">
                            <error-view
                                v-if="isError"
                                :error-message="errorMessage"
                            />
                            <get-started
                                v-else-if="active === 0"
                                @onGetStarted="active = active + 1"
                            />
                            <sync-with-sso
                                v-else-if="active === 1"
                                @onClickPrevious="active = active - 1"
                            />
                            <enter-organization
                                v-else-if="active === 2"
                                v-model="organization"
                                @onClickNext="onEnterOrganizationName"
                            />
                            <add-location
                                v-else-if="active === 3"
                                v-model="location"
                                @onClickPrevious="active = active - 1"
                                @onClickNext="onAddLocation"
                            />
                            <complete-profile
                                v-else-if="active === 4"
                                :user="user"
                                @onClickPrevious="active = active - 1"
                                @onClickNext="onCompleteProfile"
                            />
                            <all-set
                                v-else-if="active === 5"
                            />
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import Stepper from "./Stepper/Stepper";
import ErrorView from "./ErrorView";
import GetStarted from "./GetStarted";
import SyncWithSso from "./SyncWithSso";
import EnterOrganization from "./EnterOrganization";
import AddLocation from "./AddLocation";
import CompleteProfile from "./CompleteProfile";
import AllSet from "./AllSet";

export default {
    name: "index",
    components: {Stepper, GetStarted, SyncWithSso, EnterOrganization, ErrorView, AddLocation, CompleteProfile, AllSet},
    data: () => ({
        active: 0,
        isError: false,
        errorMessage: null,
        organization: null,
        location: {},
        user: {},
    }),
    methods: {
        setOrganization(data) {
            if (data.hospital_group && data.hospital_group.name) {
                this.organization = data.hospital_group.name
            }
        },
        setLocation(data) {
            if (data.location) {
                this.location = {
                    ...Boolean(data.location._id) && {_id: data.location._id},
                    ...Boolean(data.location.name) && {name: data.location.name},
                    ...Boolean(data.location.primary_address) && {primary_address: data.location.primary_address},
                    ...Boolean(data.location.secondary_address) && {secondary_address: data.location.secondary_address},
                    ...Boolean(data.location.city) && {city: data.location.city},
                    ...Boolean(data.location.state) && {state: data.location.state},
                    ...Boolean(data.location.zip_code) && {zip_code: data.location.zip_code},
                }
            }
        },
        setUser(data) {
            if (data.owner_user) {
                this.user = {
                    ...Boolean(data.owner_user._id) && {_id: data.owner_user._id},
                    ...Boolean(data.owner_user.first_name) && {first_name: data.owner_user.first_name},
                    ...Boolean(data.owner_user.last_name) && {last_name: data.owner_user.last_name},
                    ...Boolean(data.owner_user.phone) && {phone: data.owner_user.phone},
                    ...Boolean(data.owner_user.work_phone) && {work_phone: data.owner_user.work_phone},
                    ...Boolean(data.owner_user.primary_address) && {primary_address: data.owner_user.primary_address},
                    ...Boolean(data.owner_user.secondary_address) && {secondary_address: data.owner_user.secondary_address},
                    ...Boolean(data.owner_user.city) && {city: data.owner_user.city},
                    ...Boolean(data.owner_user.state) && {state: data.owner_user.state},
                    ...Boolean(data.owner_user.zip_code) && {zip_code: data.owner_user.zip_code},
                }
            }
        },
        onEnterOrganizationName(data) {
            this.setOrganization(data)
            this.active = this.active + 1
        },
        onAddLocation(data) {
            this.setLocation(data)
            this.active = this.active + 1
        },
        onCompleteProfile(data) {
            this.setUser(data)
            this.active = this.active + 1
        }
    },
    created() {
        const data = this.$appData
        if (data) {
            const {mode, onboarding_status} = data
            if (mode === 'error') {
                this.isError = true
                if (data.error_message) {
                    this.errorMessage = data.error_message
                } else {
                    this.errorMessage = 'Something went wrong. Please try again later.'
                }
            } else {
                if (onboarding_status === 'onboarding_invite_sent') {
                    this.active = 0
                } else {
                    if (mode === 'onboarding') {
                        if (onboarding_status === 'owner_synced_okta') {
                            this.active = 2
                        } else if (onboarding_status === 'organization_named') {
                            this.active = 3
                        } else if (onboarding_status === 'location_created') {
                            this.active = 4
                        } else if (onboarding_status === 'owner_profile_completed') {
                            this.active = 5
                        } else {
                            this.active = 1
                        }
                    } else {
                        this.active = 1
                    }
                }
                if (mode === 'onboarding') {
                    if (onboarding_status !== 'owner_synced_okta') {
                        this.setOrganization(data)
                    }
                }
                this.setLocation(data)
                this.setUser(data)
            }
        }
    }
}
</script>

<style scoped>
.onboarding-container {
    width: 100vw;
    height: 100vh;
    background-color: #DFE0FF;
    padding: 50px 170px;
}

.onboarding-wrapper {
    width: 100%;
    height: 100%;
}

.onboarding-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.onboarding-nav {
    display: flex;
    flex-direction: column;
    flex: 0 0 300px;
}

.onboarding-content {
    position: relative;
    flex: 1;
}

.onboarding-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.onboarding-stepper-container {
    position: relative;
    padding-left: 40px;
    background-color: #4253C4;
    flex: 1;
}

.onboarding-person-crane-container {
    flex: 0 0 350px;
    min-height: 0;
    background-color: #4253C4;
}

.person-crane-container {
    width: 300px;
    height: 100%;
    overflow: hidden;
    position: relative;
    top: 50px;
}

.responsive-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.koroid-logo-container {
    width: 300px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    top: 15px;
    left: -96px;
}
</style>
