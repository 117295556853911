<template>
    <v-skeleton-loader
        type="list-item, list-item, list-item"
        boilerplate
    ></v-skeleton-loader>
</template>

<script>
export default {
    name: "JobTitlesLoadingSkeleton"
}
</script>

<style scoped>

</style>
