<template>
    <v-menu open-on-click bottom offset-y>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" :color="color" :disabled="disabled" :outlined="outlined" :loading="loading" :dusk="duskSelector">
                {{ title }}
                <v-icon
                    v-if="showIcon"
                    right
                    dark
                >
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list flat>
                <v-list-item v-for="(item, index) in items" :key="index" @click="onClickOption(index)">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "OptionsSelect",
    props: {
        title: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'primary'
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        duskSelector: {
            type: String,
            default: 'optionsSelectButton'
        }
    },
    methods: {
        onClickOption (index) {
            this.$emit('onOptionChanged', this.items[index])
        },
    }
}
</script>

<style scoped>
    .v-list-item:hover {
        background-color: #F6F6F6;
    }
</style>
