export * from "./predictions"

const moment = require('moment-timezone')

function eventEndsOnly(start, end, startOfWeek, endOfWeek) {
    return start.isBefore(startOfWeek) && end.isSameOrAfter(startOfWeek) && end.isSameOrBefore(endOfWeek)
}

function eventStartsOnly(start, end, startOfWeek, endOfWeek) {
    return end.isAfter(endOfWeek) && start.isSameOrAfter(startOfWeek) && start.isSameOrBefore(endOfWeek)
}

function getMinutes(fromDate, toDate) {
    return toDate.diff(fromDate, 'minutes')
}

export const EVENT_DURATION_TYPE = {
    ENDS_ONLY: 'endsOnly',
    STARTS_ONLY: 'startsOnly',
    STARTS_AND_ENDS: 'startsAndEnds',
    COMPLETE: 'complete'
}


export function splitEvents(events, startOfWeek, endOfWeek) {
    return events
        .reduce((newEvents, event) => {
            const start = event.start
            const end = event.end
            if(eventEndsOnly(start, end, startOfWeek, endOfWeek)) {
                return [
                    ...newEvents,
                    {
                        ...event,
                        start: moment(startOfWeek),
                        end,
                        startMinutes: 0,
                        endMinutes: getMinutes(moment(startOfWeek), end),
                        type: EVENT_DURATION_TYPE.ENDS_ONLY
                    }
                ]
            } else if(eventStartsOnly(start, end, startOfWeek, endOfWeek)) {
                return [
                    ...newEvents,
                    {
                        ...event,
                        start,
                        end: moment(endOfWeek),
                        startMinutes: getMinutes(moment(start).startOf('day'), start),
                        endMinutes: 1439,
                        type: EVENT_DURATION_TYPE.STARTS_ONLY
                    }
                ]
            } else {
                if(start.isSame(end, 'day')) {
                    const startOfDay = moment(start).startOf('day')
                    const endOfDay = moment(start).endOf('day')
                    return [
                        ...newEvents,
                        {
                            ...event,
                            start,
                            end,
                            startMinutes: getMinutes(startOfDay, start),
                            endMinutes: getMinutes(startOfDay, end),
                            type: start.isSame(startOfDay, 'minute') && end.isSame(endOfDay, 'minute')
                                ? EVENT_DURATION_TYPE.COMPLETE
                                : EVENT_DURATION_TYPE.STARTS_AND_ENDS
                        }
                    ]
                } else {
                    return [
                        ...newEvents,
                        {
                            ...event,
                            start,
                            end: moment(start).endOf('day'),
                            startMinutes: getMinutes(moment(start).startOf('day'), start),
                            endMinutes: 1439,
                            type: EVENT_DURATION_TYPE.STARTS_ONLY
                        },
                        {
                            ...event,
                            start: moment(end).startOf('day'),
                            startMinutes: 0,
                            endMinutes: getMinutes(moment(end).startOf('day'), end),
                            end,
                            type: EVENT_DURATION_TYPE.ENDS_ONLY
                        }
                    ]
                }
            }
        }, [])
        .filter(event => event.endMinutes - event.startMinutes > 0)
}

export function groupEventsWeekly(events, startOfWeek) {
    return events
        .reduce((weeklyEvents, event) => {
            const eventStartDay = event.start.diff(startOfWeek, 'day') + 1
            return {
                ...weeklyEvents,
                [eventStartDay]: weeklyEvents.hasOwnProperty(eventStartDay) ? [
                    ...weeklyEvents[eventStartDay],
                    event
                ] : [event]
            }
        }, {})
}

export function groupEventsByUser() {
    const { events, startOfWeek, endOfWeek, users } = dummyData
    const newEvents = splitEvents(events, startOfWeek, endOfWeek)
    return users
        .reduce((userEventsWeekly, user) => {
            const userEvents = newEvents.filter(event => event.employee_id === user.id)
            return {
                ...userEventsWeekly,
                [user.id]: groupEventsWeekly(userEvents, startOfWeek, endOfWeek)
            }
        }, {})
}

function logger(message,start, end, startMinutes, endMinutes, resultStart, resultEnd, type) {
    const format = 'LLL'
    console.log({
        message,
        start: start.format(format),
        end: end.format(format),
        startMinutes,
        endMinutes,
        resultStart: resultStart.format(format),
        resultEnd: resultEnd.format(format),
        type
    })
}

const fromFormat = 'DD/MM/YYYY HH:mm'
export const dummyData = {
    events: [
        {
            start: moment('24/05/2020 21:00', fromFormat),
            end: moment('25/05/2020 02:00', fromFormat),
            employee_id: 1,
        },
        {
            start: moment('24/05/2020 22:00', fromFormat),
            end: moment('25/05/2020 22:00', fromFormat),
            employee_id: 1,
        },
        {
            start: moment('24/05/2020 23:00', fromFormat),
            end: moment('25/05/2020 18:00', fromFormat),
            employee_id: 2,
        },
        {
            start: moment('24/05/2020 21:00', fromFormat),
            end: moment('25/05/2020 02:00', fromFormat),
            employee_id: 2,
        },
        {
            start: moment('25/05/2020 12:00', fromFormat),
            end: moment('25/05/2020 20:00', fromFormat),
            employee_id: 2,
        },
        {
            start: moment('26/05/2020 00:00', fromFormat),
            end: moment('27/05/2020 00:00', fromFormat),
            employee_id: 3,
        },
        {
            start: moment('27/05/2020 22:00', fromFormat),
            end: moment('28/05/2020 08:00', fromFormat),
            employee_id: 3,
        },
        {
            start: moment('28/05/2020 20:00', fromFormat),
            end: moment('29/05/2020 04:00', fromFormat),
            employee_id: 4,
        },
        {
            start: moment('29/05/2020 23:00', fromFormat),
            end: moment('30/05/2020 11:00', fromFormat),
            employee_id: 3,
        },
        {
            start: moment('31/05/2020 20:00', fromFormat),
            end: moment('01/06/2020 11:00', fromFormat),
            employee_id: 1,
        },
        {
            start: moment('31/05/2020 22:00', fromFormat),
            end: moment('01/06/2020 11:00', fromFormat),
            employee_id: 2
        },
    ],
    users: [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        }
    ],
    startOfWeek: moment('25/05/2020 00:00', fromFormat),
    endOfWeek: moment('31/05/2020 23:59', fromFormat)
}
