<template>
    <v-form ref="shiftDateFormRef">
        <v-container fluid class="pt-0">
            <v-row>
                <v-col cols="12" class="pb-0">
                    <span class="text--h4" style="color: #000000;">Shift Date</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" class="pt-6 pb-10">
                    <time-picker-dialog
                        v-model="startDate"
                        :text-field-props="startDateTextFieldProps"
                        :time-picker-props="startDateCalendarProps"
                        required
                        min-rule
                        max-rule
                        is-date-picker
                    ></time-picker-dialog>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import TimeContainer from '../RecurrenceSettings/TimeContainer'
import TimePickerDialog from '../../../modals/TimePickerDialog'

export default {
    name: "HistoricalShiftDateForm",
    components: { TimeContainer, TimePickerDialog },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => {
        return {
            startDate: null,
            prevStartDate: null,
        }
    },
    computed: {
        startDateTextFieldProps() {
            let value = null
            if (this.startDate) {
                const endMoment = this.genMoment(this.startDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'Start Date',
                class: 'pt-0 mt-0',
                value,
            }
        },
        startDateCalendarProps() {
            return {
                min: this.startDateMinValue,
                max: this.dateMaxValue,
            }
        },
        // Historical shifts can only be created up to 2 weeks in the past
        // TODO: Add setting for this
        startDateMinValue() {
            return this.genMoment(moment().format(this.dateFormat))
                .subtract(2, 'weeks')
                .format(this.dateFormat)
        },
        dateMaxValue() {
            return this.genMoment(moment().format(this.dateFormat)).format(this.dateFormat)
        },
        dateFormat () {
            return 'YYYY-MM-DD'
        },
        // converts to an array on return to conform to the current validation
        bodyParams () {
            return {
                dates: [this.startDate]
            }
        },
        isDirty () {
            if (!_.isEqual(this.prevStartDate, this.startDate)) {
                return true
            }
        },
    },
    methods: {
        genMoment (date) {
            return moment(date, 'YYYY-MM-DD')
        },
        validate() {
            return this.$refs.shiftDateFormRef.validate()
        },
    },
    created () {
        const { editMode, initialRequestForm } = this
        if (editMode && initialRequestForm) {
            if (initialRequestForm.selected_start_date) {
                this.startDate = initialRequestForm.selected_start_date
                this.prevValues = {
                    ...this.prevValues,
                    startDate: initialRequestForm.selected_start_date,
                }
            }
        }
    }
}
</script>

<style scoped>
    .input-gutter {
        margin-top: 8px;
    }

    @media only screen and (max-width: 59.99375em) {
        .no-top-gutter {
            padding-top: 0;
            margin-top: 0;
        }
    }
</style>
