<template>
    <div>
        <div class="d-flex flex-column" style="overflow-x: auto;">
            <div class="staff-scheduler-container">
                <scheduler-loader v-if="schedulerLoading"></scheduler-loader>
                <div v-else-if="hasEvents">
                    <calendar-days
                        :start-of-week="startOfWeek"
                    >
                        <template v-slot:sort-by-menu>
                            <sort-by-menu
                                v-model="sortOption"
                                :items="sortByItems"
                            ></sort-by-menu>
                        </template>
                    </calendar-days>
                    <template v-for="(schedule, index) in schedules">
                        <calendar-counts
                            v-if="schedule.type === 'counts'"
                            :key="index"
                            icon="mdi-clipboard-account"
                            :text="schedule.name"
                            :counts="schedule.counts"
                            :start-of-week="startOfWeek"
                        ></calendar-counts>
                        <staff-schedule
                            v-else
                            :key="index"
                            :user="schedule"
                            :start-of-week="startOfWeek"
                        ></staff-schedule>
                    </template>
                </div>
                    <div v-else>
                        <calendar-days
                            :start-of-week="startOfWeek"
                        >
                            <template v-slot:sort-by-menu>
                                <sort-by-menu
                                    v-model="sortOption"
                                    :items="sortByItems"
                                ></sort-by-menu>
                            </template>
                        </calendar-days>
                        <div class="staff-no-events-container text--secondary" v-if="isSchedulerDisabled">
                            You must be authorized to manage a <span style="font-weight: bold; padding-left: 4px; padding-right: 4px;">Department</span> to begin scheduling.
                        </div>
                        <div class="staff-no-events-container text--secondary" v-else>
                            No Staff in this Staff Group. <span style="font-weight: bold; padding-left: 4px; padding-right: 4px;"> Add Staff & Shifts </span> to begin scheduling.
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import SchedulerLoader from '../../Misc/SchedulerLoader'
import CalendarDays from '../Common/CalendarDays'
import SortByMenu from '../SortByMenu'
import CalendarCounts from '../Common/CalendarCounts'
import StaffSchedule from './StaffSchedule'
import moment from 'moment-timezone'

export default {
    name: "StaffScheduler",
    components: { SchedulerLoader, CalendarDays, SortByMenu, CalendarCounts, StaffSchedule },
    data: () => ({
        sortByItems: [
            {
                _id: 'lastNameAsc',
                text: 'Last Name',
                sortText: 'A',
                asc: true,
            },
            {
                _id: 'lastNameDesc',
                text: 'Last Name',
                sortText: 'Z',
                asc: false,
            },
            {
                _id: 'hoursDesc',
                text: 'Hours',
                sortText: 'High',
                asc: false,
            },
            {
                _id: 'hoursAsc',
                text: 'Hours',
                sortText: 'Low',
                asc: true,
            },
            {
                _id: 'shiftsDesc',
                text: 'Shifts',
                sortText: 'High',
                asc: false,
            },
            {
                _id: 'shiftsAsc',
                text: 'Shifts',
                sortText: 'Low',
                asc: true,
            },
        ],
        sortOption: 'lastNameAsc',
        api: new formHelper(),
    }),
    computed: {
        events: {
            get () {
                return this.$store.getters.schedulerGetStaffEvents
            },
            set (events) {
                this.$store.dispatch('schedulerSetStaffEvents', { events })
            }
        },
        eventsLoading: {
            get () {
                return this.$store.getters.schedulerGetStaffEventsLoading
            },
            set (eventsLoading) {
                this.$store.dispatch('schedulerSetStaffEventsLoading', { eventsLoading })
            }
        },
        isSchedulerDisabled() {
            return this.$store.getters.schedulerGetIsDisabled
        },
        staffGroupsLoading() {
            return this.$store.getters.schedulerGetStaffGroupsLoading
        },
        schedulerLoading() {
            return this.staffGroupsLoading || this.eventsLoading
        },
        hasEvents () {
            if (this.events) {
                return Object.keys(this.events).length > 0
            }
            return false
        },
        eventsTree () {
            if (this.hasEvents) {
                const { events, sortOption } = this
                return Object.keys(events).reduce((accumulator, eventKey) => {
                    const event = events[eventKey]
                    return {
                        ...accumulator,
                        [eventKey]: {
                            ...event,
                            users: Array.isArray(event.users) ?
                                this.sortUsers(sortOption, event.users) : []
                        }
                    }
                }, {})
            }
            return {}
        },
        schedules () {
            if (this.hasEvents) {
                const { eventsTree } = this
                return _.sortBy(Object.keys(eventsTree).map(eventKey => ({
                    eventKey,
                    job_title_name: eventsTree[eventKey].job_title_name
                })), 'job_title_name').map(event => event.eventKey).reduce((accumulator, eventKey) => {
                    const event = eventsTree[eventKey]
                    return [
                        ...accumulator,
                        {
                            type: 'counts',
                            name: event.job_title_name,
                            counts: event.counts,
                        },
                        ...Array.isArray(event.users) ? event.users.map(user => ({ job_title_id: eventKey, ...user })) : []
                    ]
                }, [])
            }
            return []
        },
        staffGroupId () {
            return this.$store.getters.schedulerGetStaff
        },
        filters () {
            return this.$store.getters.schedulerGetStaffFilters
        },
        filtersApplied () {
            return this.$store.getters.schedulerGetStaffFiltersApplied
        },
        dates () {
            return this.$store.getters.schedulerGetDates
        },
        startOfWeek () {
            if (Array.isArray(this.dates) && this.dates.length > 1) {
                return moment(this.dates[0]).tz(this.globalTimezone, true)
            }
            return null
        },
        endOfWeek () {
            if (Array.isArray(this.dates) && this.dates.length > 1) {
                return moment(this.dates[1]).tz(this.globalTimezone, true)
            }
            return null
        },
        globalLocation () {
            return this.$root.globalLocation
        },
        globalTimezone () {
            return this.$root.globalTimezone
        },
    },
    methods: {
        fetchEvents () {
            const {
                staffGroupId,
                startOfWeek,
                endOfWeek,
                filters,
                filtersApplied,
                globalLocation,
                globalTimezone
            } = this

            if (staffGroupId && startOfWeek && endOfWeek && globalLocation && globalTimezone) {
                this.eventsLoading = true
                this.api.post('/schedules/get-staff-events', {
                    start: startOfWeek.format('YYYY-MM-DD'),
                    end: endOfWeek.format('YYYY-MM-DD'),
                    timezone: globalTimezone,
                    staff_group_id: staffGroupId,
                    ...filtersApplied && {
                        filters: {
                            job_title_ids: Array.isArray(filters.selectedJobTitleIds) ? filters.selectedJobTitleIds : [],
                            staff_member_ids: Array.isArray(filters.selectedStaffMemberIds) ? filters.selectedStaffMemberIds : [],
                            requirement_ids: Array.isArray(filters.selectedCredentialIds) ? filters.selectedCredentialIds : [],
                            staff_type_ids: Array.isArray(filters.selectedEmploymentTypeIds) ? filters.selectedEmploymentTypeIds : [],
                        }
                    }
                }).then(({ data }) => {
                    this.events = data
                }).catch(console.log).finally(() => { this.eventsLoading = false })
            }
        },
        sortUsers (sortBy, users) {
            switch (sortBy) {
                case 'lastNameAsc':
                    return _.sortBy(users, 'last_name')
                case 'lastNameDesc':
                    return _.reverse(_.sortBy(users, 'last_name'))
                case 'hoursDesc':
                    return _.reverse(_.sortBy(users, 'total_shift_duration'))
                case 'hoursAsc':
                    return _.sortBy(users, 'total_shift_duration')
                case 'shiftsDesc':
                    return _.reverse(_.sortBy(users, user => _.flatten(user.shifts).length))
                case 'shiftsAsc':
                    return _.sortBy(users, user => _.flatten(user.shifts).length)
                default:
                    return users
            }
        },
        getDateRange() {
            return {
                startOfWeek: this.startOfWeek,
                endOfWeek: this.endOfWeek,
            };
        },
    },
    watch: {
        staffGroupId () {
            this.fetchEvents()
        },
        dates () {
            this.fetchEvents()
        },
        globalTimezone () {
            this.fetchEvents()
        },
        globalLocation () {
            this.eventsLoading = true
        }
    },
    created () {
        window.EventBus.$on('staffScheduler/fetchEvents', () => {
            this.fetchEvents()
        })
    }
}
</script>

<style scoped>
    .staff-scheduler-container {
        display: block;
        width: 100%;
        max-height: calc(100vh - 297px);
        min-width: 1200px;
        padding-right: 21px;
        padding-left: 1px;
        overflow-y: auto;
    }

    .staff-no-events-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        font-size: 22px;
    }
</style>
