<template>
    <v-form ref="shiftDetailsFormRef" lazy-validation>
        <v-container fluid class="pt-0">
            <v-row>
                <v-col cols="12" class="pb-0">
                    <span class="text--h4" style="color: #000000;">Shift Details</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pb-0">
                    <v-autocomplete
                        v-model="selectedDepartmentId"
                        label="Department"
                        class="required"
                        :items="departments"
                        :loading="departmentsLoading"
                        :disabled="departmentsDisabled"
                        :rules="rules.required"
                        item-value="_id"
                        item-text="name"
                        clearable
                        outlined
                        @change="onChangeDepartmentInteractively"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-domain</v-icon>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-autocomplete
                        v-model="selectedStaffGroupId"
                        label="Staff Group"
                        class="required"
                        :items="staffGroups"
                        :loading="staffGroupsLoading"
                        :disabled="staffGroupsDisabled"
                        :rules="rules.required"
                        :hint="staffGroupsHint"
                        persistent-hint
                        item-value="_id"
                        item-text="name"
                        outlined
                        clearable
                        @change="onChangeStaffGroupInteractively"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-account-group</v-icon>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-if="noJobTitlesWithShifts" class="mt-4" no-gutters>
                <v-col>
                    <span class="error--text">Shift must have at least 1 active Job Title</span>
                </v-col>
            </v-row>
            <job-title-select
                v-for="(shiftRequest, index) in shiftRequests"
                v-bind:value="shiftRequest"
                :key="shiftRequest.key"
                :p-key="index"
                :job-titles="getJobTitles(shiftRequest.job_title_id)"
                :credentials="credentials"
                :loading="staffGroupsLoading"
                :disabled="jobTitlesDisabled"
                :edit-mode="editMode"
                :removable="!shiftRequest.persisted"
                :duration="duration"
                @onRemove="onRemoveJobTitle"
            ></job-title-select>
            <v-row style="margin-top: 24px;">
                <v-col cols="12" class="py-0">
                    <v-btn
                        ref="addJobTitleBtn"
                        color="primary"
                        text
                        class="add-job-title-btn"
                        :disabled="editMode"
                        @click="onAddJobTitle"
                    >
                        Add Job Title
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import JobTitleSelect from "./JobTitleSelect";
export default {
    name: "ShiftDetailsForm",
    components: { JobTitleSelect },
    props: {
        location: {
            type: Object,
            default: () => null
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
        authStaffingDetails: {
            type: Array,
            default: function() {
                return []
            }
        },
        duration: {
            type: Number,
            default: 0,
        }
    },
    data: () => ({
        departments: [],
        departmentsMapping: {},
        selectedDepartmentId: null,
        departmentsLoading: false,
        staffGroups: [],
        staffGroupsMapping: {},
        selectedStaffGroupId: null,
        staffGroupsLoading: false,
        jobTitles: [],
        credentials: [],
        shiftRequests: [{ key: 0, job_title_id: null, amount_requested: 1, requirements: [], persisted: true }],
        rules: {
            required: [
                v => !!v || 'Required'
            ],
        },
        matchAgainst: {
            selectedDepartmentId: null,
            selectedStaffGroupId: null,
            shiftRequests: [{job_title_id: null, amount_requested: 1, requirements: [], persisted: true }]
        },
        api: new formHelper(),
    }),
    computed: {
        selectedTimezone () {
            return this.$root.globalTimezone
        },
        departmentsDisabled() {
            return this.departmentsLoading || this.staffGroupsLoading || this.editMode
        },
        staffGroupsDisabled() {
            return this.staffGroupsLoading || !this.selectedDepartmentId || this.editMode
        },
        staffGroupsHint() {
            if(this.selectedDepartmentId) {
                return ''
            }
            return '(Select a Department to View)'
        },
        shiftTimesDisabled() {
            return this.shiftTimesLoading || !this.selectedStaffGroupId
        },
        jobTitlesDisabled() {
            return this.staffGroupsLoading || !this.selectedStaffGroupId
        },
        filteredJobTitles () {
            return _.sortBy(_.differenceBy(
                this.jobTitles,
                this.selectedJobTitleIds,
                '_id'
            ), 'title')
        },
        selectedJobTitleIds () {
            if (Array.isArray(this.shiftRequests)) {
                return this.shiftRequests
                    .filter(shiftRequest => shiftRequest.job_title_id)
                    .map(shiftRequest => ({ _id: shiftRequest.job_title_id }))
            }
            return []
        },
        noJobTitlesWithShifts() {
            const {shiftRequests} = this
            if (shiftRequests.length === 1 && typeof shiftRequests[0].job_title_id === 'undefined' || !shiftRequests[0].job_title_id) {
                return false
            }

            return !shiftRequests.some(shift_request => shift_request.amount_requested > 0)
        },
        now () {
            return moment().format('YYYY-MM-DD')
        },
        bodyParams () {
            return {
                department_id: this.selectedDepartmentId,
                staff_group_id: this.selectedStaffGroupId,
                shift_requests: this.shiftRequests.filter(req => {
                    if (req.persisted) {
                        return true
                    }

                    return req.amount_requested > 0
                }).map(req => ({
                    job_title_id: req.job_title_id,
                    amount_requested: req.amount_requested,
                    requirement_ids: req.requirements,
                })),
            }
        },
        isDirty () {
            const { matchAgainst } = this

            return !_.isEqual(this.selectedDepartmentId, matchAgainst.selectedDepartmentId) ||
                !_.isEqual(this.selectedStaffGroupId, matchAgainst.selectedStaffGroupId) ||
                !_.isEqual(this.shiftRequests.map(req => _.omit(req, ['key'])), matchAgainst.shiftRequests)
        }
    },
    methods: {
        postFetch(uri, payload, callbacks = {}) {
            const {
                onStart,
                onSuccess,
                onError,
                onEnd
            } = callbacks

            if(onStart) {
                onStart()
            }
            this.api.post(uri, payload).then(data => {
                if(onSuccess) {
                    onSuccess(data)
                }
            }).catch(data => {
                if(onError) {
                    onError(data)
                }
            }).finally(() => {
                if(onEnd) {
                    onEnd()
                }
            })
        },
        fetchDepartments(locationId) {
            this.postFetch('/request-forms/filters/departments', { location_id: locationId }, {
                onStart: () => {
                    this.departmentsLoading = true
                },
                onSuccess: ({ data }) => {
                    if(data && Array.isArray(data.data)) {
                        if (this.$authIsManager && Array.isArray(this.authStaffingDetails)) {
                            this.departments = _.intersectionBy(
                                data.data,
                                this.authStaffingDetails.map(sd => ({ '_id': sd.department_id })),
                                '_id'
                            )
                        } else {
                            this.departments = Array.from(data.data)
                        }
                        this.departmentsMapping = this.genMapping(this.departments)
                        this.tryPrefillDepartments()
                        if(this.keyExistsNot(this.selectedDepartmentId, this.departmentsMapping)) {
                            this.selectedDepartmentId = null
                        }
                    }
                },
                onError: error => console.log(error),
                onEnd: () => {
                    this.departmentsLoading = false
                }
            })
        },
        fetchStaffGroups(departmentId) {
            this.postFetch('/request-forms/filters/staff_groups', { department_id: departmentId }, {
                onStart: () => {
                    this.staffGroupsLoading = true
                },
                onSuccess: ({ data }) => {
                    if (this.$authIsManager && Array.isArray(this.authStaffingDetails)) {
                      this.staffGroups = _.intersectionBy(
                          data.data,
                          this.authStaffingDetails.map(sd => ({ '_id': sd.staff_group_id })),
                          '_id'
                      )
                    } else {
                      this.staffGroups = Array.from(data.data)
                    }
                    this.staffGroupsMapping = this.genMapping(this.staffGroups)
                    this.tryPrefillStaffGroups()
                    if (this.editMode && this.initialRequestForm) {
                        this.selectedStaffGroupId = this.initialRequestForm.staff_group_id
                        this.onChangeStaffGroup(this.selectedStaffGroupId)
                        this.shiftRequests = this.initialRequestForm.shift_requests.map(req => ({
                            persisted: typeof req._id !== 'undefined' && !!req._id,
                            job_title_id: req.job_title_id,
                            amount_requested: req.amount_requested,
                            requirements: req.requirements.map(r => r.id)
                        }))
                        this.matchAgainst = {
                            ...this.matchAgainst,
                            selectedStaffGroupId: this.selectedStaffGroupId,
                            shiftRequests: Array.from(this.shiftRequests).map(req => ({...req})),
                        }
                    }
                    if(this.keyExistsNot(this.selectedStaffGroupId, this.staffGroupsMapping)) {
                        this.selectedStaffGroupId = null
                    }
                },
                onError: error => console.log(error),
                onEnd: () => {
                    this.staffGroupsLoading = false
                }
            })
        },
        onChangeDepartment(departmentId) {
            if(departmentId) {
                this.fetchStaffGroups(departmentId)
            } else {
                this.staffGroups = []
                this.staffGroupsMapping = {}
                this.selectedStaffGroupId = null
            }
        },
        onChangeDepartmentInteractively() {
            this.clearShiftRequests()
        },
        onChangeStaffGroup(staffGroupId) {
            const staffGroup = this.staffGroupsMapping[staffGroupId]


            if (staffGroup && Array.isArray(staffGroup.job_titles)) {
                this.jobTitles = Array.from(staffGroup.job_titles)
                // if (this.$authIsManager && Array.isArray(this.authStaffingDetails)) {
                //
                //     const jobTitleIds = _.uniqBy(
                //         this.authStaffingDetails.map(staffingDetail => {
                //             if (staffingDetail.job_title_id) {
                //                 return {_id: staffingDetail.job_title_id, name: staffingDetail.job_title_name}
                //             }
                //
                //             return null
                //         }).filter(job_title => job_title !== null),
                //         '_id'
                //     )
                //
                //     this.jobTitles = _.intersectionBy(
                //         staffGroup.job_titles,
                //         jobTitleIds,
                //         '_id'
                //     )
                // } else {
                //     this.jobTitles = Array.from(staffGroup.job_titles)
                // }
            } else {
                this.jobTitles = []
            }
            if (staffGroup && Array.isArray(staffGroup.requirements)) {
                this.credentials = Array.from(staffGroup.requirements)
            } else {
                this.credentials = []
            }
        },
        onChangeStaffGroupInteractively() {
            this.clearShiftRequests()
        },
        onRemoveJobTitle(index) {
            this.shiftRequests = this.shiftRequests.filter((req, idx) => idx !== index)
        },
        onAddJobTitle() {
            if(Array.isArray(this.shiftRequests)) {
                this.shiftRequests = [
                    ...this.shiftRequests,
                    {
                        key: this.shiftRequests.length,
                        persisted: false,
                        job_title_id: null,
                        amount_requested: 1,
                        requirements: [],
                    }
                ]
                this.$emit('onAddJobTitle', this.$refs.addJobTitleBtn)
            }
        },
        clearShiftRequests() {
            let newKey = 0
            if(this.shiftRequests.length > 0) {
                const shiftRequest = this.shiftRequests[0]
                if(shiftRequest.hasOwnProperty('key')) {
                    newKey = (shiftRequest.key + 1) * 123
                }
            }
            this.shiftRequests = [{ key: newKey, persisted: true, job_title_id: null, amount_requested: 1, requirements: [], }]
        },
        genMapping(fromArr) {
            if(Array.isArray(fromArr)) {
                return fromArr.reduce((mapping, obj) => ({
                    ...mapping,
                    [obj._id]: {...obj}
                }), {})
            }
            return {}
        },
        keyExistsNot(key, mapping) {
            return key && !mapping.hasOwnProperty(key)
        },
        validate() {
            return this.$refs.shiftDetailsFormRef.validate()
        },
        getJobTitles (id) {
            const jobTitle = this.jobTitles.find(jobTitle => jobTitle._id === id)
            if (jobTitle) {
                if (Array.isArray(this.filteredJobTitles)) {
                    return _.sortBy([
                        ...this.filteredJobTitles,
                        {...jobTitle},
                    ], 'title')
                }
                return []
            }
            if (Array.isArray(this.filteredJobTitles)) {
                return this.filteredJobTitles
            }
            return []
        },
        tryPrefillDepartments () {
            if (!this.editMode && Array.isArray(this.departments) && this.departments.length === 1) {
                this.selectedDepartmentId = this.departments[0]._id
            }
        },
        tryPrefillStaffGroups () {
            if (!this.editMode && Array.isArray(this.departments) && this.departments.length === 1 &&
                Array.isArray(this.staffGroups) && this.staffGroups.length === 1) {
                this.selectedStaffGroupId = this.staffGroups[0]._id
            }
        },
    },
    watch: {
        selectedDepartmentId: {
            immediate: true,
            handler(departmentId) {
                this.onChangeDepartment(departmentId)
            }
        },
        selectedStaffGroupId: {
            immediate: true,
            handler(staffGroupId) {
                this.onChangeStaffGroup(staffGroupId)
            }
        },
        location: {
            immediate: true,
            handler(location) {
                if(location && location._id) {
                    this.fetchDepartments(location._id)
                }
            }
        }
    },
    created () {
        if (this.editMode && this.initialRequestForm) {
            this.selectedDepartmentId = this.initialRequestForm.department_id
            this.matchAgainst = {
                selectedDepartmentId: this.initialRequestForm.department_id,
                selectedStaffGroupId: null,
                shiftRequests: [{job_title_id: null, amount_requested: 1, requirements: []}]
            }
        }
    }
}
</script>

<style scoped>
    .add-job-title-btn {
        text-transform: unset !important;
    }
</style>
