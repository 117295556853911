const state = {
    current_department: {},
    staff_groups_in_current_location: [],
    managers_in_current_location: [],
    persisted_staffing_details_by_staff_group: {},
    staffing_details_by_staff_group: {}
}

const getters = {
    managersInCurrentLocation(state) {
        return state.managers_in_current_location
    },

    getStaffGroupById: (state) => (staff_group_id) => {
        return state.staff_groups_in_current_location.find(staff_group => staff_group._id === staff_group_id)
    },

    staffingDetailsByStaffGroup(state) {
        return state.staffing_details_by_staff_group
    },

    persistedStaffingDetailsByStaffGroup(state) {
        return state.persisted_staffing_details_by_staff_group
    },

    persistedStaffingDetailForStaffGroup: (state) => ({staff_group_id, staffing_detail_index}) => {
        const staffing_details_for_staff_group = state.persisted_staffing_details_by_staff_group.find(item => item.staff_group_id === staff_group_id)

        if (!staffing_details_for_staff_group) {
            return null
        }

        return staffing_details_for_staff_group.staffing_details[staffing_detail_index]
    },

    availableStaffGroupsForSelection(state) {
        const selected_staff_group_ids = state.staffing_details_by_staff_group.map(item => item.staff_group_id)

        return state.staff_groups_in_current_location.filter(staff_group => !selected_staff_group_ids.includes(staff_group._id))
    },

    jobTitlesForSelectionForStaffGroupWithDisabledFlag: (state) => (staff_group_id) => {
        const staff_group = state.staff_groups_in_current_location.find(staff_group => staff_group._id === staff_group_id)
        const staffing_detail_data_for_staff_group = state.staffing_details_by_staff_group.find(item => item.staff_group_id === staff_group_id)

        let staffing_details_for_staff_group = []
        if (staffing_detail_data_for_staff_group && typeof staffing_detail_data_for_staff_group.staffing_details !== 'undefined') {
            staffing_details_for_staff_group = staffing_detail_data_for_staff_group.staffing_details
        }

        const selected_job_title_ids = staffing_details_for_staff_group.map(staffing_detail => staffing_detail.job_title_id)

        return staff_group.job_titles.map(job_title => {
            job_title.disabled = selected_job_title_ids.includes(job_title._id);
            return job_title
        })
    },
}

const actions = {
    setCurrentDepartment({commit}, department) {
        commit('setCurrentDepartment', department)
    },

    setManagersInCurrentLocation({commit}, managers) {
        commit('setManagersInCurrentLocation', managers)
    },

    setStaffGroupsInCurrentLocation({commit}, staff_groups) {
        commit('setStaffGroupsInCurrentLocation', staff_groups)
    },

    setStaffingDetailsByStaffGroup({commit}, staffing_details) {
        commit('setStaffingDetailsByStaffGroup', staffing_details)
    },

    addEmptyStaffingDetailGroup({commit}) {
        commit('addEmptyStaffingDetailGroup')
    },

    setStaffingDetailStaffGroup({commit}, params) {
        commit('setStaffingDetailStaffGroup', params)
    },

    addNewStaffingDetail({commit}, staff_group_id) {
        commit('addNewStaffingDetail', staff_group_id)
    },

    changeStaffingDetailJobTitle({commit}, params) {
        commit('changeStaffingDetailJobTitle', params)
    },

    changeStaffingDetailManagers({commit}, params) {
        commit('changeStaffingDetailManagers', params)
    },

    replaceStaffingDetail({commit}, staffing_detail) {
        commit('replaceStaffingDetail', staffing_detail)
    },

    removeStaffingDetail({commit}, params) {
        commit('removeStaffingDetail', params)
    }
}

const mutations = {
    setCurrentDepartment(state, department) {
        state.current_department = department
    },

    setManagersInCurrentLocation(state, managers) {
        state.managers_in_current_location = managers
    },

    setStaffGroupsInCurrentLocation(state, staff_groups) {
        state.staff_groups_in_current_location = staff_groups
    },

    setStaffingDetailsByStaffGroup(state, staffing_details) {
        state.staffing_details_by_staff_group = groupStaffingDetailsByStaffGroup(staffing_details)
        state.persisted_staffing_details_by_staff_group = groupStaffingDetailsByStaffGroup(staffing_details)
    },

    addEmptyStaffingDetailGroup(state) {
        if (state.staffing_details_by_staff_group.find(item => !item.staff_group_id)) {
            return
        }

        state.staffing_details_by_staff_group.push({
            staff_group_id: null,
            staff_group_name: null,
            staffing_details: [
                {
                    department_id: state.current_department._id,
                    staff_group_id: null,
                    staff_group_name: null,
                    job_title_id: null,
                    job_title_name: null,
                    managers: []
                }
            ]
        })
    },

    setStaffingDetailStaffGroup(state, {index, staff_group_id}) {
        const staff_group = state.staff_groups_in_current_location.find(staff_group => staff_group._id === staff_group_id)

        state.staffing_details_by_staff_group = state.staffing_details_by_staff_group.map((item, item_index) => {
            if (item_index !== index) {
                return item
            }

            item.staff_group_id = staff_group._id
            item.staff_group_name = staff_group.name

            if (item.staffing_details.length) {
                item.staffing_details = item.staffing_details.map(staffing_detail => {
                    return {
                        ...staffing_detail,
                        staff_group_id: staff_group._id,
                        staff_group_name: staff_group.name
                    }
                })
            }

            return item
        })
    },

    addNewStaffingDetail(state, staff_group_id) {
        state.staffing_details_by_staff_group = state.staffing_details_by_staff_group.map(item => {
            if (item.staff_group_id === staff_group_id) {
                item.staffing_details.push({
                    department_id: state.current_department._id,
                    staff_group_id: staff_group_id,
                    staff_group_name: item.staff_group_name,
                    job_title_id: null,
                    job_title_name: null,
                    managers: []
                })
            }

            return item
        })
    },

    changeStaffingDetailJobTitle(state, { index, staffing_detail, new_job_title_id }) {
        const { staff_group_id, job_title_id } = staffing_detail
        const staff_group = state.staff_groups_in_current_location.find(staff_group => staff_group._id === staff_group_id)
        let new_job_title = staff_group.job_titles.find(job_title => job_title._id === new_job_title_id)

        if (!new_job_title) {
            new_job_title = {_id: null, title: null}
        }

        state.staffing_details_by_staff_group = state.staffing_details_by_staff_group.map(item => {
            if (item.staff_group_id !== staff_group_id) {
                return item
            }

            item.staffing_details = item.staffing_details.map((staffing_detail, sd_index) => {
                if (sd_index === index) {
                    return {
                        ...staffing_detail,
                        job_title_id: new_job_title._id,
                        job_title_name: new_job_title.title
                    }
                }

                return staffing_detail
            })

            return item
        })
    },

    changeStaffingDetailManagers(state, { staffing_detail, new_managers }) {
        const { staff_group_id, job_title_id } = staffing_detail

        state.staffing_details_by_staff_group = state.staffing_details_by_staff_group.map(item => {
            if (item.staff_group_id !== staff_group_id) {
                return item
            }

            item.staffing_details = item.staffing_details.map(staffing_detail => {
                if (staffing_detail.job_title_id === job_title_id) {
                    return {
                        ...staffing_detail,
                        managers: new_managers
                    }
                }

                return staffing_detail
            })

            return item
        })
    },

    replaceStaffingDetail(state, new_staffing_detail) {
        state.staffing_details_by_staff_group = replaceStaffingDetailInGroupedArray(state.staffing_details_by_staff_group, new_staffing_detail)
        state.persisted_staffing_details_by_staff_group = replaceStaffingDetailInGroupedArray(state.persisted_staffing_details_by_staff_group, new_staffing_detail)
    },

    removeStaffingDetail(state, {staff_group_id, staffing_detail_index, is_last_staffing_detail_for_staff_group}) {
        state.staffing_details_by_staff_group = removeStaffingDetailFromGroupedArray(state.staffing_details_by_staff_group, staff_group_id, staffing_detail_index, is_last_staffing_detail_for_staff_group)
        state.persisted_staffing_details_by_staff_group = removeStaffingDetailFromGroupedArray(state.persisted_staffing_details_by_staff_group, staff_group_id, staffing_detail_index, is_last_staffing_detail_for_staff_group)
    }
}

function groupStaffingDetailsByStaffGroup(staffing_details) {
    return Object.values(staffing_details.reduce((acc, item) => {
        const {staff_group_id, staff_group_name, ...rest} = item;

        if (!acc[staff_group_id]) {
            acc[staff_group_id] = {staff_group_id, staff_group_name, staffing_details: []};
        }

        acc[staff_group_id].staffing_details.push({ staff_group_id, staff_group_name, ...rest });

        return acc;
    }, {}));
}

function replaceStaffingDetailInGroupedArray(staffing_details_by_staff_group, new_staffing_detail) {
    const { staff_group_id, job_title_id } = new_staffing_detail

    if (!staffing_details_by_staff_group.some(item => item.staff_group_id === staff_group_id)) {
        staffing_details_by_staff_group.push({
            staff_group_id: new_staffing_detail.staff_group_id,
            staff_group_name: new_staffing_detail.staff_group_name,
            staffing_details: [new_staffing_detail]
        });

        return staffing_details_by_staff_group
    }

    return staffing_details_by_staff_group.map(item => {
        if (item.staff_group_id !== staff_group_id) {
            return item
        }

        if (!item.staffing_details.some(staffing_detail => staffing_detail.job_title_id === job_title_id)) {
            item.staffing_details.push(new_staffing_detail)
            return item
        }

        item.staffing_details = item.staffing_details.map(staffing_detail => {
            if (staffing_detail.job_title_id === job_title_id) {
                return new_staffing_detail
            }

            return staffing_detail
        })

        return item
    })
}

function removeStaffingDetailFromGroupedArray(staffing_details_by_staff_group, staff_group_id, staffing_detail_index, is_last_staffing_detail_for_staff_group) {
    if (is_last_staffing_detail_for_staff_group) {
        return staffing_details_by_staff_group.filter(item => item.staff_group_id !== staff_group_id)
    }

    return staffing_details_by_staff_group.map(item => {
        if (item.staff_group_id !== staff_group_id) {
            return item
        }

        item.staffing_details.splice(staffing_detail_index, 1)

        return item
    })
}

export default {
    state,
    getters,
    actions,
    mutations
}
