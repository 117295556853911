<template>
    <v-dialog
        v-model="modalValue"
        scrollable width="500px"
        content-class="ds-dialog"
        persistent
    >
        <v-card class="ds-modal-card">
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Remove Job Title
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content">
                <p class="black--text">
                    Removing a Job Title from this Automated Shift will only effect shifts created via this automation’s
                    next run.
                </p>
                <p class="black--text">
                    Would you like to remove this Job Title from this Automated Shift?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel">No</v-btn>
                        <v-btn class="ml-2" color="primary" outlined @click="onDelete">Yes
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "RemoveJobTitleModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCancel() {
            this.modalValue = false
        },
        onDelete() {
            this.modalValue = false
            this.$emit('onDelete')
        }
    }
}
</script>

<style scoped>

</style>
