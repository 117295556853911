import Vue from 'vue'

export function formatEmployeeName(value) {
    if(value) {
        if(value.first_name && value.last_name) {
            return `${value.first_name} ${value.last_name}`
        }
        return value
    }
    return 'N/A'
}

export function formatDisplayProperty(header_type, value, tz) {
    switch (header_type) {
        case 'date':
            return moment(value).tz(tz).format('MM/DD/YYYY')
        case 'datetime':
            return moment(value).tz(tz).format('ddd, MM/DD/YYYY, h:mm A')
        case 'hour':
            return Vue.filter('timeStringFromMinutes')(value)
        default:
            return value
    }
}
