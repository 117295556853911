<template>
    <v-card class="elevation-0">
        <v-toolbar class="ds-toolbar" elevation="0">
            <v-icon color="primary" class="mr-3">mdi-account-group</v-icon>
            <v-toolbar-title>{{ staff_group_name }}</v-toolbar-title>
            <v-btn
                class="ds-toolbar-fab-button"
                fab
                small
                elevation="1"
                :disabled="staff_group_details_loading || !hasEditStaffGroupPermission"
                @click="onEditStaffGroup"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                @click="onBackToLocation"
            >
                Back to Location
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pt-4 px-6">
            <v-row>
                <v-col cols="12">
                    <p>{{ staff_group_description }}</p>
                    <h4 class="ds-h4">Job Titles</h4>
                    <p class="ds-caption mt-2">Create and manage the Job Titles for Staff Members in this group.</p>
                </v-col>
            </v-row>

            <v-row v-if="staff_group_details_loading">
                <v-col cols="12" md="6">
                    <job-titles-loading-skeleton/>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="12">
                    <job-title
                        v-for="(job_title, index) in job_titles"
                        :key="job_title._id"
                        :job-title="job_title"
                        :ref="'jobTitleComponent_' + index"
                        @saved="onJobTitleSaved(index, $event)"
                        @deleted="onJobTitleDeleted(index)"
                    />
                </v-col>

                <v-col cols="12">
                    <v-btn
                        text
                        color="primary"
                        :disabled="!hasEditJobTitlePermission"
                        @click="onAddJobTitle"
                    >
                        Add Job Title
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="my-4"/>

            <v-row>
                <v-col cols="12">
                    <h4 class="ds-h4">Custom Fields</h4>
                    <p class="ds-caption mt-2">These fields are populated from the Configuration Options in your Organization.</p>
                </v-col>
            </v-row>

            <v-row v-if="staff_group_details_loading">
                <v-col cols="12" md="6">
                    <custom-fields-loading-skeleton/>
                </v-col>
            </v-row>

            <v-row v-else-if="selectedStaffGroup">
                <v-col
                    cols="12"
                    md="6"
                    v-for="(custom_field, index) in custom_fields"
                    :key="index"
                >
                    <custom-field
                        :custom-field="custom_field"
                        :owner-entity-id="selectedStaffGroup._id"
                        context="staff-group"
                        :has-permission="hasEditStaffGroupPermission"
                        @update="onCustomFieldUpdate"
                        :ref="'customFieldComponent_' + index"
                    />
                </v-col>
            </v-row>

        </v-container>

        <staff-group-modal ref="editStaffGroupModal"/>
        <unsaved-changes-dialog ref="unsavedChangesModal"/>
    </v-card>
</template>

<script>
import JobTitlesLoadingSkeleton from "./components/JobTitlesLoadingSkeleton";
import CustomFieldsLoadingSkeleton from "../../../components/custom_fields/CustomFieldsLoadingSkeleton";
import CustomField from "../../../components/custom_fields/CustomField";
import JobTitle from "./components/JobTitle";
import StaffGroupModal from "./StaffGroupModal";
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";

export default {
    name: "StaffGroupWindow",
    components: {
        UnsavedChangesDialog,
        StaffGroupModal,
        JobTitle,
        CustomField,
        CustomFieldsLoadingSkeleton,
        JobTitlesLoadingSkeleton
    },
    props: {
        selectedStaffGroup: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            api: new formHelper,
            staff_group_details_loading: false,
            staff_group: {},
            staff_group_updated: false,
            custom_fields: [],
            job_titles: [],
        }
    },
    methods: {
        resetWindowData() {
            this.staff_group_updated = false
        },

        onBackToLocation() {
            if (this.hasUnsavedData()) {
                this.showUnsavedChangesDialog()
            } else {
                this.goBackToLocationWindow()
            }
        },

        goBackToLocationWindow() {
            this.$emit('back:location', {staff_group_updated: this.staff_group_updated})
            this.resetWindowData()
        },

        onEditStaffGroup() {
            if (!this.hasEditStaffGroupPermission) {
                return
            }

            this.$refs.editStaffGroupModal.open({staff_group_id: this.selectedStaffGroup._id})
                .then(({action}) => {
                    if (action === 'updated') {
                        this.staff_group_updated = true
                        this.loadStaffGroupDetails()
                    }
                })
                .catch(() => {})
        },

        loadStaffGroupDetails() {
            this.staff_group_details_loading = true

            this.api.get('/staff-group/' + this.selectedStaffGroup._id, )
                .then(({data}) => {
                    const { staff_group, job_titles, custom_fields } = data
                    this.staff_group = staff_group

                    this.job_titles = job_titles
                    this.custom_fields = custom_fields
                })
                .finally(() => {
                    this.staff_group_details_loading = false
                })
        },

        onAddJobTitle() {
            if (!this.hasEditJobTitlePermission) {
                return
            }

            this.job_titles.push({title: null, max_shift_duration: 0, staff_group_id: this.staff_group._id})

            this.staff_group_updated = true
        },

        onJobTitleSaved(index, event_data) {
            const {job_title} = event_data
            this.job_titles.splice(index, 1, job_title)

            this.staff_group_updated = true
        },

        onJobTitleDeleted(index) {
            this.job_titles.splice(index, 1)

            this.staff_group_updated = true
        },

        onCustomFieldUpdate({custom_field_id, value}) {
            if (!this.hasEditStaffGroupPermission) {
                return
            }

            this.custom_fields = this.custom_fields.map(custom_field => {
                if (custom_field._id === custom_field_id) {
                    return {...custom_field, value: value}
                }

                return custom_field
            })

            this.staff_group_updated = true
        },

        hasUnsavedJobTitles() {
            let unsaved_job_titles = 0
            for (let i = 0; i < this.job_titles.length; i++) {
                let reference = this.$refs['jobTitleComponent_' + i][0]
                if (reference.unsaved_changes) {
                    unsaved_job_titles++
                }
            }

            return unsaved_job_titles
        },

        hasUnsavedCustomFields() {
            let unsaved_custom_fields = 0
            for (let i = 0; i < this.custom_fields.length; i++) {
                let reference = this.$refs['customFieldComponent_' + i][0]

                if (reference.unsaved_changes) {
                    unsaved_custom_fields++
                }
            }

            return unsaved_custom_fields
        },

        hasUnsavedData() {
            return this.hasUnsavedJobTitles() || this.hasUnsavedCustomFields()
        },

        showUnsavedChangesDialog() {
            this.$refs.unsavedChangesModal.open()
                .then(() => {
                    this.loadStaffGroupDetails()
                    this.goBackToLocationWindow()
                })
                .catch(() => {})
        },

    },
    computed: {
        staff_group_name() {
            if (!this.selectedStaffGroup) {
                return 'Staff Group'
            }

            if (typeof this.staff_group.name === 'undefined' || !this.staff_group.name) {
                return this.selectedStaffGroup.name
            }

            return this.staff_group.name
        },

        staff_group_description() {
            if (typeof this.staff_group.description === 'undefined' || !this.staff_group.description) {
                return 'Brief description of the Staff Group if you want it.'
            }

            return this.staff_group.description
        },
        hasEditStaffGroupPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.STAFF_GROUPS.EDIT)
        },
        hasEditJobTitlePermission() {
            return this.$userHasPermission(this.$user, this.$config.permissions.JOB_TITLES.EDIT)
        },
    },
    watch: {
        selectedStaffGroup: {
            immediate: true,
            handler(staff_group) {
                if (staff_group && typeof staff_group._id !== 'undefined') {
                    this.loadStaffGroupDetails()
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
