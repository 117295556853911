<template>
    <v-dialog v-model="modalValue" max-width="400" persistent>
        <v-card>
            <v-toolbar
                class="destructive-modal"
                elevation="0"
            >
                <v-toolbar-title class="text--h2">Delete Report</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p class="report-content text--paragraph black--text">
                    Are you sure you want to delete this report?
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeModal" :disabled="loading">
                    Cancel
                </v-btn>
                <v-btn color="error" outlined @click="onDeleteReport" :loading="loading">
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteReportModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        api: new formHelper(),
        loading: false,
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        closeModal() {
            this.modalValue = false
        },
        onDeleteReport() {
            if(this.report && this.report._id) {
                this.loading = true
                this.api.delete(`/reports/${this.report._id}`).then(({ message }) => {
                    this.loading = false
                    this.closeModal()
                    this.$snackNotify('success', 'Report deleted.')
                    this.$emit('onDeleteReport')
                }).catch(() => { this.loading = false })
            }
        }
    }
}
</script>

<style scoped>
    .report-content {
        margin-top: 20px;
    }
</style>
