import store from './store/index.js';
import axios from 'axios';
import snackNotification from './toast';
import * as Sentry from '@sentry/browser';

window.axios = axios

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//Next we will register the CSRF Token as a common header
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: process.env.MIX_SENTRY_SAMPLE_RATE,
    });
}

const snackUp = snackNotification

//set default error messages
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // Handling network errors
    if (!error.response) {
        snackUp('error', 'Unable to connect. Please check your internet connection.');
        return Promise.reject(error);
    }
    const errorMessage = error?.response?.data?.error_message
    switch (error.response.status) {
        case 401:
            // Redirect or force reload on authentication error
            window.location.reload(true);
            break;
        case 403:
            snackUp('error', errorMessage || 'You are not authorized to perform this action.');
            break;
        case 409:
            // Handling conflict errors (could be more specific based on context)
            snackUp('error', errorMessage || 'A conflict occurred with your request.');
            break;
        case 419:
            // Session timeout or CSRF token mismatch
            window.location.reload(true);
            break;
        case 422:
            if (errorMessage) {
                snackUp('error', errorMessage)
            }
            break;
        case 423:
            snackUp('error', errorMessage || 'Your account has been locked. Contact Support for help.');
            break;
        case 429:
            // Rate limit exceeded
            snackUp('error', errorMessage || 'Too many requests. Please try again later.');
            break;
        case 500:
            snackUp('error', errorMessage || 'An unexpected error has occurred. Our team has been notified.');
            Sentry.captureException(error?.response)
            break;
        case 502:
            snackUp('error', errorMessage || 'Bad Gateway.');
            break;
        case 503:
            snackUp('error', errorMessage || 'Service temporarily unavailable. Please try again later.');
            break;
        default:
            snackUp('error', errorMessage || 'An unexpected error has occurred. Our team has been notified.')
            Sentry.captureException(error?.response)
            break;
    }
    return Promise.reject(error);
});

/* formHelper wraper axios to provide:
*   - busy: Boolean if the action is in progress
*   - sucessful: If the action was a 200
*   - data and message()
*       - data is the server response
*       - messsage set the text for the SnackBar and actives it
 */
window.formHelper = function () {
    var form = this;
    //_.extend(this, data);

    this.busy = false;
    this.successful = false;
    this.errors = {};

    this.get = function (uri, form) {
        return this.sendForm('get', uri, form);
    };

    this.post = function (uri, form) {
        return this.sendForm('post', uri, form);
    };

    this.put = function (uri, form) {
        return this.sendForm('put', uri, form);
    },

    this.patch = function (uri, form) {
        return this.sendForm('patch', uri, form);
    }

    this.delete = function (uri, form) {
        return this.sendForm('delete', uri, form);
    }

    this.sendForm = function (method, uri, form) {
        return new Promise((resolve, reject) => {
            this.busy = true;

            let data = form ? JSON.parse(JSON.stringify(form)) : null

            axios[method](uri, data)
                .then(response => {
                    this.busy = false;
                    this.successful = true

                    if (typeof response.headers['session-expiry'] !== 'undefined') {
                        store.dispatch('setExpiresAt', response.headers['session-expiry'])
                    }

                    resolve({
                        data: response.data,
                        message: (m) => {
                            snackUp('success', m)
                        }
                    })
                })
                .catch(errors => {
                    this.busy = false;
                    this.successful = false;

                    this.errors = errors?.response?.data?.errors ?? "An error has occurred.";

                    reject({
                        data: errors?.response?.data ?? {message: "Error in response data."},
                        message: (m) => snackUp('error', m)
                    });
                });
        });
    }

    this.hasError = function (key) {
        if(this.errors) {
            if(key in this.errors)
                return (0 in this.errors[key]) ? this.errors[key][0] : this.errors[key]
        }
        return null
    }

    this.clearErrors = function () {
        this.errors = {};
    }
};
