const Pages = {
    Auth: [
        'Login',
        'PostIdpLogout'
    ],
    Dashboard: [
        'index'
    ],
    Users: [
        'index'
    ],
    Profile: [
        'index'
    ],
    Schedules: [
        'index'
    ],
    Shifts: [
        'index',
    ],
    Notifications: [
        'index'
    ],
    Reporting: [
        'index'
    ],
    Organization: [
        'index'
    ],
    Onboarding: [
        'index'
    ],
    StaffingAutomations: [
        'index'
    ]
}

const Plugin = {
    install(Vue) {
        for (const page in Pages) {
            Pages[page].forEach(component => {
                Vue.component(toKebabCase(page) + '-' + component.toLowerCase(), require('./pages/' + page + '/' + component).default)
            })
        }
    }
}

function toKebabCase(string) {
    return string.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase()
}

export {Plugin};
