<template>
    <v-dialog v-model="modalValue" scrollable persistent max-width="450px">
        <v-card>
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title>
                    <div class="d-flex">
                        <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                        <div class="mt-1 text--h2">
                            Removing Assigned Staff
                        </div>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="black--text mt-5 text--paragraph">
                You are removing a Staff Member from an assigned shift. Would you like to continue ?
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel" :disabled="loading">Cancel</v-btn>
                        <v-btn class="ml-2" color="primary" outlined @click="onConfirm" :loading="loading">Confirm</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "RemoveUserModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: null,
        },
        shiftRequestId: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        loading: false,
        api: new formHelper(),
    }),
    computed: {
        modalValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        onCancel () {
            this.$emit('onCancel')
        },
        onConfirm () {
            this.loading = true
            this.api.post('/shift-request/unassign/' + this.shiftRequestId, { user_id: this.userId })
                .then(({ data }) => {
                    this.$snackNotify('success', 'Staff member removed from shift')
                    if (typeof data.shift.sms_failed !== 'undefined' && data.shift.sms_failed) {
                        this.$snackNotify('warning', 'Staff member removed from shift and in-app notification sent. SMS notification could not be sent.')
                    }
                    this.$emit('onConfirm')
                })
                .catch(({data, message}) => {
                    if (typeof data?.message !== 'undefined') {
                        message(data.message)
                    }
                    this.$emit('onCancel')
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style scoped>

</style>
