<template>
    <div class="bulk-upload-users-info-container">
        <div class="bulk-upload-users-info-wrapper">
            <div class="text--h3 text-center">
                Before Starting:
            </div>
            <ul class="text--paragraph">
                <li class="pb-2">
                    We recommend creating at least one Department, Staff Group and Job Title. This will allow you to organize your users immediately after uploading is complete.
                </li>
                <li class="pb-2">
                    Only email domains associated with your organization can be used. Please ensure that the file you upload contains the appropriate email addresses.
                </li>
                <li>
                    If you have any questions visit our <a target="_blank" :href=supportUrl>Support page</a> for documentation and contact information.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "BulkUploadUsersInfo",
    computed: {
        supportUrl() {
            return this.$root.websiteUrl + '/support';
        }
    },
}
</script>

<style scoped>
    .bulk-upload-users-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .bulk-upload-users-info-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 24px;
        background-color: #F6F6F6;
        max-width: 800px;
    }
</style>
