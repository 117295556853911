<template>
    <div>
        <main-report-filters
            ref="mainReportFiltersRef"
            :preselected-filters="preselectedStaffingDetails"
            :readonly="readonly"
            :report-type="reportType"
            :location="location"
            :hide-employment-type="false"
            hide-departments
            hide-shift-times
            hide-credentials
            @onFiltersChange="onStaffingDetailsFiltersChange"
            @onChange="onChange"/>
        <user-search
            v-model="selectedEmployees"
            :key="employeesInputSelectKey"
            :readonly="readonly"
            :initial-entries="preselectedEmployees"
            :report-type="reportType"
            @onChange="onChange"/>
    </div>
</template>

<script>
import MainReportFilters from "./MainReportFilters";
import UserSearch from "../../common/Search/UserSearch";

export default {
    name: "PayrollPeriodFilters",
    components: { MainReportFilters, UserSearch },
    props: {
        preset: {
            type: Object,
            default: () => null
        },
        value: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedEmploymentType: null,
        selectedStaffGroups: null,
        selectedJobTitles: null,
        selectedEmployees: null,
        preselectedEmployees: null,
        employeesInputSelectKey: 8888,
    }),
    computed: {
        preselectedStaffingDetails() {
            const { preset } = this
            if(preset) {
                const { filters } = preset
                if(filters) {
                    return {
                        employmentTypeId: Boolean(!!filters.staff_type) ? filters.staff_type.id : null,
                        staffGroupIds: Array.isArray(filters.staff_groups) ?
                            filters.staff_groups.map(sg => sg.id) : [],
                        jobTitleIds: Array.isArray(filters.job_titles) ?
                            filters.job_titles.map(jt => jt.id) : [],
                    }
                }
                return {
                    employmentTypeId: null,
                    staffGroupIds: [],
                    jobTitleIds: [],
                }
            }
            return null
        },
        filters() {
            return {
                employmentType: this.selectedEmploymentType,
                staffGroups: this.selectedStaffGroups,
                jobTitles: this.selectedJobTitles,
                staffMembers: this.selectedEmployees,
            }
        },
        mainReportFiltersRef() {
            return this.$refs.mainReportFiltersRef
        },
    },
    methods: {
        onStaffingDetailsFiltersChange(filters) {
            if(filters) {
                this.selectedEmploymentType = Boolean(!!filters.employmentTypeId) ? filters.employmentTypeId : null
                this.selectedStaffGroups = Array.isArray(filters.staffGroupIds) ? filters.staffGroupIds : []
                this.selectedJobTitles = Array.isArray(filters.jobTitleIds) ? filters.jobTitleIds : []
            }
        },
        onChange() {
            this.emitOnChange()
        },
        emitOnChange() {
            this.$emit('onChange')
        },
        resetEmployeesInput() {
            this.selectedEmployees = null
            this.preselectedEmployees = null
            this.employeesInputSelectKey = this.employeesInputSelectKey + 1
        },
        validateFilters() {
            if (this.mainReportFiltersRef) {
                return this.mainReportFiltersRef.validateFilters()
            }
            return true
        }
    },
    watch: {
        preset: {
            immediate: true,
            handler(preset) {
                if(preset) {
                    const { filters } = preset
                    if(filters) {
                        const { staff_members } = filters
                        if(Array.isArray(staff_members)) {
                            this.selectedEmployees = staff_members.map(sm => sm.id)
                            this.preselectedEmployees = Array.from(staff_members)
                            this.employeesInputSelectKey = this.employeesInputSelectKey + 1
                        } else {
                            this.resetEmployeesInput()
                        }
                    } else {
                        this.resetEmployeesInput()
                    }
                }
            }
        },
        filters(filters) {
            this.$emit('input', filters)
        }
    }
}
</script>

<style scoped>

</style>
