<template>
    <v-dialog v-model="modalValue" max-width="400" persistent>
        <v-card>
            <v-toolbar
                class="destructive-modal"
            >
                <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                <v-toolbar-title class="text--h2">Delete Preset</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p class="preset-content text--paragraph black--text">
                    Are you sure you want to delete the "{{ presetTitle }}" preset?
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeModal" :disabled="loading">
                    Cancel
                </v-btn>
                <v-btn color="error" outlined @click="onDeletePreset" :loading="loading">
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeletePresetModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        api: new formHelper(),
        loading: false,
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        presetTitle() {
            const { preset } = this
            if(preset) {
                return preset.title
            }
            return null
        }
    },
    methods: {
        closeModal() {
            this.modalValue = false
        },
        onDeletePreset() {
            if(this.preset && this.preset._id) {
                this.loading = true
                this.api.delete(`/report-preset/${this.preset._id}`).then(({ message }) => {
                    this.loading = false
                    this.closeModal()
                    this.$snackNotify('success', 'Report preset deleted.')
                    this.$emit('onDeletePreset')
                }).catch(() => { this.loading = false })
            }
        }
    }
}
</script>

<style scoped>
    .preset-content {
        margin-top: 20px;
    }
</style>
