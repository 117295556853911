<template>
    <div class="summary-view-container">
        <div class="first-summary-view-container">
            <div>
                <div class="summary-view-title-container">
                    <slot name="title-content"/>
                </div>
                <div class="summary-view-text-container">
                    <slot name="text-content"/>
                </div>
            </div>
            <div>
                <slot name="doughnut-chart"/>
            </div>
        </div>
        <div class="second-summary-view-container">
            <slot name="bar-chart"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "SummaryView",
}
</script>

<style scoped>
    .summary-view-container {
        display: grid;
        grid-template-columns: 240px minmax(0, 1fr);
    }

    .first-summary-view-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 240px;
    }

    .second-summary-view-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 28px;
    }

    .summary-view-title-container {
        height: 30px;
        margin-left: 15px;
        margin-top: 15px;
        white-space: nowrap;
    }

    .summary-view-text-container {
        margin-top: 50px;
        text-align: center;
    }
</style>
