<template>
    <v-card :class="{ 'presentational-user-list-container': hasMaxHeight }" style="padding-top: 8px; padding-bottom: 8px;">
        <div v-if="loading">
            <div v-for="index in 6" :key="(index + 1) * 123">
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-divider v-if="index < 6"></v-divider>
            </div>
        </div>
        <template v-else>
            <div v-if="showNoAssignedUsers" class="text-body-1 d-flex justify-center align-center py-8" style="color: #6c757d;">
                No Staff assigned.
            </div>
            <div v-if="showNoAssignedUsers && !inThePast" style="border-bottom: 2px solid black;">

            </div>
            <v-list v-if="usersCount > 0" style="padding-top: 0; padding-bottom: 0;">
                <v-slide-y-transition group tag="div">
                    <template v-for="(user, index) in users">
                        <presentational-user-list-item
                            v-if="user.assigned || user.offered || user.rejected"
                            :key="user.rejected ? `user-item-${user._id}-rejected_${index}` : `user-item-${user._id}_${index}`"
                            :user="user"
                            :has-permission="hasPermission"
                            :in-the-past="inThePast"
                            :is-shift-starting-soon="isShiftStartingSoon"
                            @onEditUser="onEditUser"
                            @onRemoveUser="onRemoveUser"
                        ></presentational-user-list-item>
                        <actionable-user-list-item
                            v-else
                            :key="`user-item-${user._id}`"
                            :user="user"
                            :score="predictedAvailability && predictedAvailability[user._id]"
                            rhs
                        ></actionable-user-list-item>
                        <div :key="`user-divider-${user._id}`" v-if="!showNoAssignedUsers && index === assignedUsersCount + availableUsersCount + directAssignUsersCount - 1 && !inThePast" style="border-bottom: 2px solid black;">

                        </div>
                    </template>
                </v-slide-y-transition>
            </v-list>
            <div v-if="showNoOfferedUsers && !inThePast" class="text-body-1 d-flex justify-center align-center py-8" style="color: #6c757d;">
                No shift offers sent.
            </div>
        </template>
    </v-card>
</template>

<script>
import PresentationalUserListItem from './PresentationalUserListItem'
import ActionableUserListItem from './ActionableUserListItem'

export default {
    name: "PresentationalUserLIst",
    components: { PresentationalUserListItem, ActionableUserListItem },
    props: {
        users: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasPermission: {
            type: Boolean,
            default: true,
        },
        inThePast: {
            type: Boolean,
            default: false,
        },
        predictedAvailability: {
            type: Object,
            default: null,
        },
        isShiftStartingSoon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        usersCount () {
            if (Array.isArray(this.users)) {
                return this.users.length
            }
            return 0
        },
        assignedUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.assigned).length
            }
            return 0
        },
        availableUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.available).length
            }
            return 0
        },
        offeredUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.offered).length
            }
            return 0
        },
        eligibleUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.eligible).length
            }
            return 0
        },
        rejectedUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.rejected).length
            }
            return 0
        },
        directAssignUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.direct_assign).length
            }
            return 0
        },
        hasMaxHeight () {
            return !this.showNoAssignedUsers || !this.showNoOfferedUsers
        },
        showNoAssignedUsers () {
            return this.assignedUsersCount + this.availableUsersCount + this.directAssignUsersCount <= 0

        },
        showNoOfferedUsers () {
            return this.offeredUsersCount + this.eligibleUsersCount + this.rejectedUsersCount <= 0
        },
    },
    methods: {
        onEditUser (shiftId) {
            this.$emit('onEditUser', shiftId)
        },
        onRemoveUser (userId) {
            this.$emit('onRemoveUser', userId)
        }
    }
}
</script>

<style scoped>
    .presentational-user-list-container {
        height: 99%;
    }
</style>
