<template>
    <div class="d-flex flex-row align-center">
        <v-icon v-if="icon" color="primary" class="mr-2" :small="credential">{{ icon }}</v-icon>
        <span :class="textClass">{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: "StaffSchedulerLabel",
    props: {
        icon: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        title: {
            type: Boolean,
            default: false,
        },
        credential: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        textClass () {
            let textClass = 'text--paragraph'
            if (this.title) {
                textClass = 'text--paragraph'
            } else if (this.credential) {
                textClass = 'text--paragraph'
            }
            return {
                [textClass]: true,
                'black--text': true,
            }
        }
    }
}
</script>

<style scoped>
    .text-title-1 {
        font-size: 18px;
    }
</style>
