<template>
    <div class="bulk-upload-users-table-container">
        <div class="bulk-upload-users-table-info">
            <div class="text-h6 font-weight-bold text-center">
                Review File Data
            </div>
            <div>
                Correct any errors in the table below. Deselect a row to remove it from the upload. You must select a Location for all users before continuing.
            </div>
        </div>
        <div class="bulk-upload-users-table-wrapper">
            <v-data-table
                id="bulk-upload-users-table"
                :headers="headers"
                :items="items"
                class="bulk-upload-users-table elevation-0"
                fixed-header
                disable-pagination
                disable-filtering
                disable-sort
                hide-default-footer
                height="100%"
            >
                <template v-slot:header.checked>
                    <v-checkbox
                        color="#4253c4"
                        input-value="1"
                        @change="onSelectAll"
                    />
                </template>
                <template v-slot:item.checked="{ item }">
                    <v-checkbox
                        v-model="item.checked"
                        color="#4253c4"
                    />
                </template>
                <template v-slot:item.firstName="{ item, index }">
                    <bulk-upload-users-table-input
                        v-model="item.firstName.value"
                        :index="index"
                        :valid="item.firstName.valid"
                        :disabled="!item.checked"
                        :error-message="item.firstName.errorMessage"
                        @onChange="onChangeFirstName"
                    />
                </template>
                <template v-slot:item.lastName="{ item, index }">
                    <bulk-upload-users-table-input
                        v-model="item.lastName.value"
                        :index="index"
                        :valid="item.lastName.valid"
                        :disabled="!item.checked"
                        :error-message="item.lastName.errorMessage"
                        @onChange="onChangeLastName"
                    />
                </template>
                <template v-slot:item.username="{ item, index }">
                    <bulk-upload-users-table-input
                        v-model="item.username.value"
                        :index="index"
                        :valid="item.username.valid"
                        :disabled="!item.checked"
                        :error-message="item.username.errorMessage"
                        @onChange="onChangeUsername"
                    />
                </template>
                <template v-slot:item.email="{ item, index }">
                    <bulk-upload-users-table-input
                        v-model="item.email.value"
                        :index="index"
                        :valid="item.email.valid"
                        :disabled="!item.checked"
                        :error-message="item.email.errorMessage"
                        @onChange="onChangeEmail"
                    />
                </template>
                <template v-slot:item.locations="{ item }">
                    <bulk-upload-users-locations
                        v-model="item.locations"
                        :locations="locations"
                        :disabled="!item.checked"
                        @onApplyAll="onApplyAll"
                    />
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import BulkUploadUsersTableInput from './BulkUploadUsersTableInput'
import BulkUploadUsersLocations from './BulkUploadUsersLocations'

export default {
    name: "BulkUploadUsersTable",
    components: { BulkUploadUsersTableInput, BulkUploadUsersLocations },
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        locations: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        headers: [
            {
                sortable: false,
                value: 'checked',
                width: '5%',
            },
            {
                text: 'First Name',
                align: 'start',
                sortable: false,
                value: 'firstName',
            },
            {
                text: 'Last Name',
                align: 'start',
                sortable: false,
                value: 'lastName',
            },
            {
                text: 'Username',
                align: 'start',
                sortable: false,
                value: 'username',
            },
            {
                text: 'Email Address',
                align: 'start',
                sortable: false,
                value: 'email',
            },
            {
                text: 'Locations',
                align: 'start',
                sortable: false,
                value: 'locations',
                width: '25%',
            }
        ]
    }),
    computed: {
        items: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onSelectAll(value) {
            this.$emit('onSelectAll', value)
        },
        onChangeFirstName(value, index) {
            this.$emit('onChangeFirstName', value, index)
        },
        onChangeLastName(value, index) {
            this.$emit('onChangeLastName', value, index)
        },
        onChangeUsername(value, index) {
            this.$emit('onChangeUsername', value, index)
        },
        onChangeEmail(value, index) {
            this.$emit('onChangeEmail', value, index)
        },
        onApplyAll(locations) {
            this.$emit('onApplyAll', locations)
        }
    }
}
</script>

<style scoped>
    .bulk-upload-users-table-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .bulk-upload-users-table-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        padding: 24px;
    }

    .bulk-upload-users-table-wrapper {
        flex: 1 0 0;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 12px;
        height: 100%;
        overflow-y: hidden;
    }

    .bulk-upload-users-table {
        height: 100%;
        overflow-y: auto;
    }

    #bulk-upload-users-table :deep(tr:hover) {
        background-color: inherit;
    }
</style>
