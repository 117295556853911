<template>
    <div class="error-view-container">
        <p class="text--h1 error--text text-center">
            {{ errorMessage }}
        </p>
        <p v-if="isLinkExpired" class="text--paragraph text-center px-16">
            For your security this link has expired and a new one will need to be created. Please contact us directly at <a :href=supportUrl class="text-decoration-underline">Koroid Support</a> to have a new link sent.
        </p>
    </div>
</template>

<script>
export default {
    name: "ErrorView",
    props: {
        errorMessage: {
            type: String,
            default: ''
        }
    },
    computed: {
        isLinkExpired() {
            return this.errorMessage === 'Onboarding Link Expired'
        },
        supportUrl() {
            return this.$root.websiteUrl + '/support';
        }
    }
}
</script>

<style scoped>
.error-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
