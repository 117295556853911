import Vue from 'vue'
import Vuex from 'vuex'
import requestForms from "./modules/requestForms";
import sessions from "./modules/sessions";
import schedule from "./modules/schedule";
import scheduler from "./modules/scheduler";
import departmentStaffingDetails from "./modules/departmentStaffingDetails";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        requestForms,
        sessions,
        schedule,
        scheduler,
        departmentStaffingDetails
    },
    strict: debug,
})
