<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        max-width="550px"
        persistent
        content-class="ds-dialog"
    >
        <v-card class="ds-modal-card" elevation="2" dusk="saveCustomFieldModal">
            <modal-toolbar
                :title="customFieldId ? 'Custom Field Details' : 'New Custom Field'"
                @close="onClose"/>

            <v-card-text class="ds-modal-content">
                <v-form
                    ref="customFieldForm"
                    :lazy-validation="false"
                    class="pt-2"
                >
                    <v-row>
                        <div v-show="isFetchingDetails" style="height:100%">
                            <loading-spinner :value="true"></loading-spinner>
                        </div>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-text-field
                                name="custom-field-name"
                                label="Field Name"
                                v-model.trim="customField.name"
                                :error-messages="api.hasError('name')"
                                :rules="rules.required"
                                class="ds-modal-input"
                                autocomplete="off"
                                outlined
                                clearable
                            />
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-select
                                name="custom-field-tracked-in"
                                label="Tracked In"
                                :items="trackedIn"
                                item-value="_id"
                                item-text="name"
                                v-model="customField.trackedIn"
                                :error-messages="api.hasError('context')"
                                :rules="rules.required"
                                :menu-props="{ 'offset-y': true }"
                                class="ds-modal-input"
                                autocomplete="off"
                                outlined
                                clearable
                            />
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-select
                                name="custom-field-locations"
                                label="Locations"
                                :items="locations"
                                item-value="_id"
                                item-text="name"
                                v-model="customField.locations"
                                :error-messages="api.hasError('locations')"
                                :rules="rules.requiredArrayNotEmpty"
                                :menu-props="{ 'offset-y': true, 'bottom': true, 'content-class': 'fixed-menu-content-class'}"
                                class="ds-modal-input"
                                autocomplete="off"
                                chips
                                deletable-chips
                                outlined
                                multiple
                                clearable
                            >
                                <template v-slot:prepend-item>
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-btn
                                            color="primary"
                                            text
                                            block
                                            @click="toggleSelectAllLocations"
                                        >
                                            {{ allLocationsSelected ? 'Deselect All' : 'Select All' }}
                                        </v-btn>
                                    </div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col
                            :class="{ 'd-flex flex-row justify-space-between': Boolean(customFieldId), 'd-flex flex-row justify-end': Boolean(!customFieldId) }">
                            <v-btn
                                v-if="Boolean(customFieldId)"
                                text
                                color="error"
                                @click="onClickDeleteCustomField"
                            >
                                Delete Custom Field
                            </v-btn>
                            <v-btn
                                outlined
                                color="primary"
                                :disabled="isSaveDisabled"
                                :loading="isSaving"
                                @click="onSave"
                            >
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <delete-modal
            v-model="deleteCustomFieldModalValue"
            title="Delete Custom Field"
            :message="deleteMessage"
            :is-deleting="isDeleting"
            @onDelete="onDeleteCustomField"
        />
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-dialog>
</template>

<script>
import UnsavedChangesDialog from "../../../../../components/modals/UnsavedChangesDialog";
import ModalToolbar from "../../../../../components/modals/components/ModalToolbar";
import LoadingSpinner from "../../../../../components/loaders/LoadingSpinner";
import DeleteModal from "../DeleteModal";
import validationRules from "../../../../../lib/mixins/validationRules";

export default {
    name: "SaveCustomFieldModal",
    components: {UnsavedChangesDialog, ModalToolbar, LoadingSpinner, DeleteModal},
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        customFieldId: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            prevCustomField: {},
            customField: {},
            deleteCustomFieldModalValue: false,
            isFetchingDetails: false,
            isSaving: false,
            isDeleting: false,
            deleteMessage: '',
            trackedIn: [],
            locations: [],
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        customFieldFormRef() {
            return this.$refs.customFieldForm
        },
        isDirty() {
            return !_.isEqual(this.prevCustomField, this.customField)
        },
        isSaveDisabled() {
            return this.isSaving || this.isDeleting || !this.isDirty
        },
        isDeleteDisabled() {
            return this.isSaving || this.isDeleting
        },
        allLocationsSelected() {
            if (this.$authIsAdministrator) {
                return this.customField &&
                    Array.isArray(this.customField.locations) &&
                    Array.isArray(this.locations) &&
                    this.customField.locations.filter(location => !this.disabledLocationsMap.hasOwnProperty(location)).length === this.locations.filter(location => !location.disabled).length
            } else {
                return this.customField && Array.isArray(this.customField.locations) && Array.isArray(this.locations) && this.customField.locations.length === this.locations.length
            }
        },
        authUserLocationsMap() {
            if (this.$user && Array.isArray(this.$user.locations)) {
                return this.$user.locations.reduce((locations, _id) => ({
                    ...locations,
                    [_id]: true
                }), {})
            }
            return {}
        },
        disabledLocationsMap() {
            if (Array.isArray(this.locations)) {
                return this.locations.reduce((locations, location) => {
                    if (location.disabled) {
                        return {
                            ...locations,
                            [location._id]: true
                        }
                    }
                    return locations
                }, {})
            }
            return {}
        }
    },
    methods: {
        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },
        closeModal() {
            this.modalValue = false
            this.resetModal()
        },
        resetModal() {
            this.prevCustomField = {}
            this.customField = {}
            this.deleteMessage = ''
            this.trackedIn = []
            this.locations = []
            if (this.customFieldFormRef && this.customFieldFormRef.resetValidation) {
                this.customFieldFormRef.resetValidation()
            }
            this.api.clearErrors()
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        onClickDeleteCustomField() {
            this.deleteCustomFieldModalValue = true
            this.deleteMessage = `
                <p>
                    You are about to delete <b>${this.prevCustomField.name}</b> from all Locations and pages where it is currently tracked.
                </p>
            `
        },
        onDeleteCustomField() {
            this.isDeleting = true
            this.api.delete(`/custom-field/${this.customFieldId}`).then(() => {
                this.$snackNotify('success', 'Custom Field deleted.')
                this.deleteCustomFieldModalValue = false
                this.closeModal()
                this.$emit('onDelete')
            }).catch(console.log).finally(() => this.isDeleting = false)
        },
        fetchDetails(customFieldId) {
            this.isFetchingDetails = true
            this.api.get(customFieldId ? `/custom-field/details-for-modal/${customFieldId}` : '/custom-field/details-for-modal')
                .then(result => {
                    if (result && result.data) {
                        if (Array.isArray(result.data.locations)) {
                            if (this.$authIsAdministrator) {
                                this.locations = result.data.locations.map(location => ({
                                    ...location,
                                    disabled: !this.authUserLocationsMap.hasOwnProperty(location._id)
                                }))
                            } else {
                                this.locations = Array.from(result.data.locations)
                            }
                        }
                        if (Array.isArray(result.data.contexts)) {
                            this.trackedIn = Array.from(result.data.contexts)
                        }
                        if (this.customFieldId && result.data.custom_field) {
                            const customField = {
                                name: result.data.custom_field.name,
                                trackedIn: result.data.custom_field.context,
                                locations: _.intersectionBy(
                                    result.data.custom_field.locations,
                                    this.locations,
                                    '_id'
                                ).map(location => location._id)
                            }
                            this.prevCustomField = {...customField}
                            this.customField = {...customField}
                        }
                    }
                }).catch(console.log).finally(() => this.isFetchingDetails = false)
        },
        onSave() {
            if (!this.customFieldFormRef.validate()) {
                return false
            }

            this.isSaving = true
            this.api.post('/custom-field/save', {
                ...Boolean(this.customFieldId) && {_id: this.customFieldId},
                name: this.customField.name,
                context: this.customField.trackedIn,
                location_ids: this.customField.locations,
            }).then(() => {
                if (this.customFieldId) {
                    this.$snackNotify('success', 'Changes to Custom Field saved.')
                } else {
                    this.$snackNotify('success', 'New Custom Field created.')
                }
                this.closeModal()
                this.$emit('onSave')
            }).catch(console.log).finally(() => this.isSaving = false)
        },
        toggleSelectAllLocations() {
            this.$nextTick(() => {
                if (this.$authIsAdministrator) {
                    const disabledLocations = this.customField && Array.isArray(this.customField.locations) ? this.customField.locations.filter(location => this.disabledLocationsMap.hasOwnProperty(location)) : []
                    this.customField = {
                        ...this.customField,
                        locations: this.allLocationsSelected ? disabledLocations :
                            [
                                ...disabledLocations,
                                ...this.locations.filter(location => !location.disabled).map(location => location._id)
                            ],
                    }
                } else {
                    this.customField = {
                        ...this.customField,
                        locations: this.allLocationsSelected ? [] : this.locations.map(location => location._id),
                    }
                }
            })
        }
    },
    watch: {
        value(open) {
            if (open) {
                this.fetchDetails(this.customFieldId)
            }
        }
    }
}
</script>

<style scoped>

</style>
