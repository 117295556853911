<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :readonly="readonly"
        :clearable="!readonly"
        :search-input.sync="search"
        @input="stopSearch"
        @change="emitOnChange"
        hide-no-data
        hide-selected
        item-text="full_name"
        item-value="_id"
        no-data-text="No Staff Members in Location"
        label="Staff Members"
        multiple
        cache-items
        outlined
        placeholder="Start typing to search"
    >
            <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-account-search</v-icon>
            </template>

            <template v-slot:selection="{ item, index }">
                <div v-if="index < maxDisplay" class="v-select__selection v-select__selection--comma">
                    {{ index === maxDisplay ? `${item.full_name}, ` : index === model.length - 1 ? item.full_name : `${item.full_name}, ` }}
                </div>
                <div v-if="index === maxDisplay" class="v-select__selection v-select__selection--comma">
                    (+{{ model.length - maxDisplay }})
                </div>
            </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "UserSearch",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        initialEntries: {
            type: Array,
            default: () => null
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        }
    },
    data: ()  => ({
        api: new formHelper(),
        descriptionLimit: 60,
        entries: [],
        isLoading: false,
        search: null,
        preventSearch: false,
        maxDisplay: 3,
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        items () {
            return this.entries.map(entry => {
                const full_name = entry.full_name.length > this.descriptionLimit
                    ? entry.full_name.slice(0, this.descriptionLimit) + '...'
                    : entry.full_name

                return Object.assign({}, entry, { full_name })
            })
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalLocationId() {
            if (!this.globalLocation) {
                return null
            }

            return this.globalLocation._id
        }
    },
    watch: {
        search: _.debounce(function(name) {
            // Do not search if query string is empty
            if(!name || (typeof name === 'string' && name.trim().length === 0)) {
                return
            }

            // Do not search if item has been selected from the menu
            if(this.preventSearch) {
                this.preventSearch = false
                if(this.entries.some(user => user.full_name === name)) {
                    return
                }
            }

            this.queryByName(name)
        }, 500)
    },
    methods: {
        stopSearch() {
            this.preventSearch = true
        },
        queryByName(name_query) {
            this.isLoading = true
            this.api.post('/reports/filters/staff_members', {
                name_query,
                report_type: this.reportType,
                location_id: this.globalLocationId
            }).then(({ data }) => {
                if(Array.isArray(data.data)) {
                    this.count = data.data.length
                    this.entries = Array.from(data.data)
                }
            }).catch(() => {})
                .finally(() => {
                    this.isLoading = false
                })
        },
        emitOnChange() {
            this.search = null
            this.$emit('onChange')
        }
    },
    mounted() {
        if(this.model && this.initialEntries) {
            this.entries = this.initialEntries.map(entry => ({
                ...entry,
                _id: entry.id,
                full_name: `${entry.first_name} ${entry.last_name}`
            }))
        }
    }
}
</script>

<style scoped>

</style>
