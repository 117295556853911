<template>
    <div :style="styleObj">
        <v-icon color="black">
            {{ icon }}
        </v-icon>
    </div>
</template>

<script>
export default {
    name: "CustomIcon",
    props: {
        icon: {
            type: String,
            default: 'mdi-account'
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    data() {
        return {
            styleObj: {
                backgroundColor: this.color,
                border: '2px solid black',
                borderRadius: '50%',
                padding: '2px'
            }
        }
    }
}
</script>

<style scoped>

</style>
