<template>
    <v-tooltip
        v-model="isTooltipActive"
        top
        color="primary"
    >
        <template v-slot:activator="{ attrs }">
            <v-text-field
                v-bind="attrs"
                v-model="text"
                :error="error"
                :disabled="disabled"
                :background-color="backgroundColor"
                outlined
                hide-details
                @input="onChange"
                @mouseenter="onMouseEnter"
                @mouseleave="onMouseLeave"
                class="mt-2 mb-2"
            />
        </template>
        <span>{{ errorMessage }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "BulkUploadUsersTableInput",
    props: {
        value: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0,
        },
        valid: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isTooltipActive: false,
        isHovered: false,
    }),
    computed: {
        text: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        error() {
            return !this.valid
        },
        backgroundColor() {
            if (this.error) {
                return '#FFDAD6'
            }
            return undefined
        }
    },
    methods: {
        onChange(value) {
            this.$emit('onChange', value, this.index)
        },
        onMouseEnter() {
            this.isHovered = true
            if (this.error && !this.disabled) {
                this.isTooltipActive = true
            }
        },
        onMouseLeave() {
            this.isHovered = false
            this.isTooltipActive = false
        }
    },
    watch: {
        error: {
            immediate: true,
            handler(error) {
                this.isTooltipActive = !!(error && this.isHovered && !this.disabled)
            }
        }
    }
}
</script>

<style scoped>

</style>
