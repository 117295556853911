<template>
    <v-row class="row-separator">
        <v-col class="m-0 p-0" cols="12">
            <div class="fixed-column-container">
                <p class="column-label white--text text-uppercase">
                    {{ title }}
                </p>
                <p class="column-label white--text">
                    {{ content }}
                </p>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "HeaderRow",
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
    .fixed-column-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
    }
    .row-separator {
        border-bottom: 2px solid white;
    }
    .column-label {
        font-size: 14px;
        margin-bottom: 0;
    }
</style>
