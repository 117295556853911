<template>
    <v-dialog v-model="showModal" scrollable width="500px" persistent>
        <v-card>
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title class="text--h2">Unsaved Changes</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-6">
                <p class="black--text text--paragraph">
                    You have unsaved changes on this page. Do you want to discard them?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="cancel">Cancel</v-btn>
                        <v-btn class="ml-2" outlined color="primary" @click="confirm" name="confirm">Continue</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "UnsavedChangesDialog",

        data() {
            return {
                showModal: false,
                resolve: null,
                reject: null,
                api: new formHelper(),
            }
        },

        methods: {
            open() {
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                    this.showModal = true
                })
            },

            confirm() {
                this.closeModal()
                this.resolve()
            },

            cancel() {
                this.closeModal()
                this.reject()
            },

            closeModal() {
                this.showModal = false
            },
        },
    }
</script>

<style scoped>

</style>
