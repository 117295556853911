<template>
    <div class="departments-view-container">
        <div class="departments-title-container">
            <span class="text--h2">Departments</span>
            <div v-if="departments" class="sort-container">
                <v-select
                    v-model="sortBy"
                    :items="sortByItems"
                    :disabled="loading"
                    label="Sort By"
                    :menu-props="{ 'offset-y': true }"
                ></v-select>
            </div>
        </div>
        <div v-if="departments" class="departments-container" :style="departmentsContainer">
            <div v-for="(department, index) in departments" :key="index" class="d-flex justify-center department-container">
                <department-card
                    :department="department"
                    :loading="loading">
                </department-card>
            </div>
        </div>
        <div v-if="!departments.length" class="d-flex justify-center align-center text-center text--secondary">
            There are currently no Departments in this Location.
        </div>
        <div class="show-all-departments-container" v-if="!viewAll">
            <v-btn
                v-if="showViewAll"
                color="primary"
                outlined
                :disabled="loading"
                @click="onViewAll"
            >View All</v-btn>
        </div>
    </div>
</template>

<script>
import DepartmentCard from "../cards/department/DepartmentCard";
export default {
    name: "DepartmentsView",
    components: { DepartmentCard },
    props: {
        location: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        innerWidth: window.innerWidth - 64,
        sortByItems: [
            { text: '% Filled Shifts, Low to High', value: 'shifts.ratio-shifts.total_count-asc' },
            { text: '% Filled Shifts, High to Low', value: 'shifts.ratio-shifts.total_count-desc' },
            { text: '% Response Rate, Low to High', value: 'offers.ratio-offers.total_count-asc' },
            { text: '% Response Rate, High to Low', value: 'offers.ratio-offers.total_count-desc' },
            { text: 'Name, A-Z', value: 'name-asc' },
            { text: 'Name, Z-A', value: 'name-desc' }
        ],
        sortBy: 'shifts.ratio-shifts.total_count-desc',
        viewAll: false,
        cardsNumber: 4,
    }),
    computed: {
        departments() {
            const { departments_summary } = this.location
            if(Array.isArray(departments_summary)) {
                if(this.viewAll) {
                    return departments_summary
                }
                return departments_summary.slice(0, this.cardsNumber)
            }
            return []
        },
        departmentsContainer() {
            let gridColumns
            if(this.innerWidth > 1600) {
                gridColumns = '1fr 1fr 1fr 1fr'
            } else if(this.innerWidth > 1200) {
                gridColumns = '1fr 1fr 1fr'
            } else if(this.innerWidth > 800) {
                gridColumns = '1fr 1fr'
            } else {
                gridColumns = '1fr'
            }
            return {
                'grid-template-columns': gridColumns
            }
        },
        showViewAll() {
            const { departments_summary } = this.location
            if(Array.isArray(departments_summary)) {
                return departments_summary.length > this.cardsNumber
            }
            return false
        }
    },
    watch: {
        sortBy: {
            immediate: true,
            handler() {
                this.onSortByChange()
            }
        }
    },
    methods: {
        onResize() {
            this.innerWidth = window.innerWidth - 64
        },
        onSortByChange() {
            this.$emit('onSortByChange', this.sortBy)
        },
        onViewAll() {
            this.viewAll = true
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    created () {
        if (this.innerWidth > 1600) {
            this.cardsNumber = 4
        } else if (this.innerWidth > 1200) {
            this.cardsNumber = 3
        } else if (this.innerWidth > 800) {
            this.cardsNumber = 2
        } else {
            this.cardsNumber = 1
        }
    }
}
</script>

<style scoped>
    .departments-view-container {
        margin-top: 50px;
    }
    .departments-container {
        display: grid;
    }
    .department-container {
        margin-bottom: 50px;
    }
    .departments-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .sort-container {
        width: 250px;
    }
    .show-all-departments-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
</style>
