<template>
    <v-radio-group v-model="selectedWorkingHours" :readonly="readonly">
        <v-row style="padding: 0 32px !important;">
            <v-col class="ds-modal-input-container">
                <v-radio value="assigned">
                    <template v-slot:label>
                        <employment-type-working-hours-label
                            title="Assigned"
                            subtitle="Availability is preset and staff can be assigned by default during these hours."
                        />
                    </template>
                </v-radio>
            </v-col>
            <v-col class="ds-modal-input-container">
                <v-radio value="submitted">
                    <template v-slot:label>
                        <employment-type-working-hours-label
                            title="Submitted"
                            subtitle="Staff member must submit availability in order to be assigned to a shift."
                        />
                    </template>
                </v-radio>
            </v-col>
            <v-col class="ds-modal-input-container">
                <v-radio value="none">
                    <template v-slot:label>
                        <employment-type-working-hours-label
                            title="None"
                            subtitle="Staff member can be offered shifts or self-assign shifts as needed."
                        />
                    </template>
                </v-radio>
            </v-col>
        </v-row>
    </v-radio-group>
</template>

<script>
import EmploymentTypeWorkingHoursLabel from "./EmploymentTypeWorkingHoursLabel";

export default {
    name: "EmploymentTypeWorkingHours",
    components: { EmploymentTypeWorkingHoursLabel },
    props: {
        value: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        selectedWorkingHours: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>

<style scoped>

</style>
