<script>
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
    name: "BarChart",
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartOptions: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        chartOptions: {
            handler() {
                this.$data._chart.destroy()
                this.renderChart(this.chartData, this.chartOptions)
            },
            deep: true,
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.chartOptions)
    }
}
</script>

<style scoped>

</style>
