<template>
    <v-form ref="addJobTitleFormRef">
        <v-row :style="{'margin-top': showDetails ? '25px' : 0}" style="position: relative;">
            <v-col cols="12" md="3" sm="6" class="pt-0">
                <p class="text--h4" v-if="showDetails">Job Title</p>
                <v-autocomplete
                    :value="selectedJobTitleId"
                    @input="onChangeJobTitle"
                    :rules="rules.required"
                    label="Job Title"
                    outlined
                    :items="jobTitles"
                    :loading="jobTitlesLoading"
                    item-value="_id"
                    item-text="title"
                    :clearable="canEditStaffingAutomationRequest"
                    :readonly="!canEditStaffingAutomationRequest"
                >
                    <template v-slot:prepend-inner>
                        <v-icon color="primary">
                            mdi-clipboard-account
                        </v-icon>
                    </template>

                    <template v-slot:item="{ on, item, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.over_max_hours || !item.managed_by_auth_user" class="disabled-reason-subtitle">
                                    <v-icon
                                        class="ml-1"
                                        small
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                    <span v-if="!item.managed_by_auth_user">You are not authorized to make changes to this Job Title</span>
                                    <span v-else-if="item.over_max_hours">Staffing Automation duration exceeds max hours for this job title</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="pt-0">
                <p class="text--h4" v-if="showDetails">Credentials</p>
                <v-autocomplete
                    :value="selectedCredentialsIds"
                    @input="onChangeCredentials"
                    label="Credentials"
                    multiple
                    outlined
                    :items="credentials"
                    :loading="credentialsLoading"
                    item-value="_id"
                    item-text="name"
                    :clearable="canEditStaffingAutomationRequest"
                    :readonly="!canEditStaffingAutomationRequest"
                >

                </v-autocomplete>
            </v-col>
            <v-col cols="12" :sm="true" class="d-flex pt-0" style="column-gap: 15px;">
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Sun</p>
                    <v-text-field
                        :value="selectedSchedule[0]"
                        @input="onChangeDay($event, 0)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Mon</p>
                    <v-text-field
                        :value="selectedSchedule[1]"
                        @input="onChangeDay($event, 1)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Tue</p>
                    <v-text-field
                        :value="selectedSchedule[2]"
                        @input="onChangeDay($event, 2)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Wed</p>
                    <v-text-field
                        :value="selectedSchedule[3]"
                        @input="onChangeDay($event, 3)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Thu</p>
                    <v-text-field
                        :value="selectedSchedule[4]"
                        @input="onChangeDay($event, 4)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Fri</p>
                    <v-text-field
                        :value="selectedSchedule[5]"
                        @input="onChangeDay($event, 5)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
                <div class="d-flex flex-column">
                    <p class="text--h4" v-if="showDetails">Sat</p>
                    <v-text-field
                        :value="selectedSchedule[6]"
                        @input="onChangeDay($event, 6)"
                        :rules="dayRules"
                        type="number"
                        hide-spin-buttons
                        outlined
                        :readonly="!canEditStaffingAutomationRequest"
                    />
                </div>
            </v-col>
            <v-col cols="auto" class="pt-0 px-0">
                <v-btn
                    v-if="isDirty"
                    icon
                    :style="{ 'margin-top': showDetails ? '48px' : '8px'}"
                    class="action-icon"
                    @click="onSave"
                    :loading="isSaving"
                    :disabled="!canEditStaffingAutomationRequest"
                >
                    <v-icon color="primary">mdi-check</v-icon>
                </v-btn>
                <div v-else style="width: 40px; height: 40px;"></div>
            </v-col>
            <v-col cols="auto" class="pt-0 px-0">
                <v-btn
                    icon
                    :style="{ 'margin-top': showDetails ? '48px' : '8px'}"
                    class="action-icon"
                    @click="onDelete"
                    :loading="isDeleting"
                    :disabled="!canEditStaffingAutomationRequest"
                >
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </v-col>
            <v-slide-y-transition>
                <p
                    v-if="validationErrorExists"
                    class="error--text"
                    style="position: absolute; bottom: 0; left: 16px; font-size: 12px; min-height: 14px;"
                >
                    At least one day should be filled
                </p>
            </v-slide-y-transition>
        </v-row>
    </v-form>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";

export default {
    name: "AddJobTitle",
    mixins: [validationRules],
    props: {
        showDetails: {
            type: Boolean,
            default: false,
        },
        jobTitles: {
            type: Array,
            default: () => ([])
        },
        jobTitlesLoading: {
            type: Boolean,
            default: false,
        },
        credentials: {
            type: Array,
            default: () => ([])
        },
        credentialsLoading: {
            type: Boolean,
            default: false,
        },
        staffingAutomationScheduleIndex: {
            type: Number,
            default: 0,
        },
        selectedJobTitleId: {
            type: String,
            default: null
        },
        selectedCredentialsIds: {
            type: Array,
            default: () => ([])
        },
        selectedSchedule: {
            type: Array,
            default: () => ([])
        },
        isDirty: {
            type: Boolean,
            default: false
        },
        isSaving: {
            type: Boolean,
            default: false,
        },
        isDeleting: {
            type: Boolean,
            default: false,
        },
        canEditStaffingAutomation: {
            type: Boolean,
            default: true,
        },
        managedByAuthUser: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        validationErrorExists: false,
    }),
    computed: {
        dayRules() {
            return [
                v => !!v || v === 0 || '',
                v => v >= 0 || '',
                v => v <= 20 || '<=20'
            ]
        },
        addJobTitleFormRef() {
            return this.$refs.addJobTitleFormRef
        },
        canEditStaffingAutomationRequest() {
            return this.canEditStaffingAutomation && this.managedByAuthUser
        }
    },
    methods: {
        onChangeJobTitle(jobTitleId) {
            this.validationErrorExists = false
            this.$emit('onChangeJobTitle', this.staffingAutomationScheduleIndex, jobTitleId)
        },
        onChangeCredentials(credentialsIds) {
            this.validationErrorExists = false
            this.$emit('onChangeCredentials', this.staffingAutomationScheduleIndex, credentialsIds)
        },
        onChangeDay(value, index) {
            this.validationErrorExists = false
            const intValue = parseInt(value)
            this.$emit(
                'onChangeDay',
                this.staffingAutomationScheduleIndex,
                isNaN(intValue) ? null : intValue,
                index
            )
        },
        onSave() {
            if (this.addJobTitleFormRef?.validate()) {
                if (this.selectedSchedule.some(dayValue => dayValue > 0)) {
                    this.$emit('onSave', this.staffingAutomationScheduleIndex)
                } else {
                    this.validationErrorExists = true
                }
            }
        },
        onDelete() {
            this.$emit('onDelete', this.staffingAutomationScheduleIndex)
        }
    }
}
</script>

<style scoped>
@media only screen and (max-width: 599px) {
    .action-icon {
        margin-top: 0 !important;
        position: relative;
        top: -20px;
    }
}
</style>
