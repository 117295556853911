<template>
    <v-card class="elevation-0">
        <v-toolbar class="ds-toolbar" elevation="0">
            <v-toolbar-title>Automated Shifts</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                class="ds-toolbar-action-button"
                elevation="1"
                @click="onAddNewAutomatedShift"
                :disabled="!canEditStaffingAutomation || !globalLocation"
            >
                New Automated Shift
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="selectedStatusId"
                            :items="statuses"
                            item-value="_id"
                            item-text="name"
                            label="Status"
                            :menu-props="{ 'offset-y': true }"
                            outlined
                            clearable
                            :disabled="filtersDisabled"
                            :loading="filtersLoading"
                            :no-data-text="filtersNoDataText"
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="selectedDepartmentId"
                            :items="departments"
                            item-value="_id"
                            item-text="name"
                            label="Department"
                            :menu-props="{ 'offset-y': true }"
                            outlined
                            clearable
                            :disabled="filtersDisabled"
                            :loading="filtersLoading"
                            :no-data-text="filtersNoDataText"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon color="primary">mdi-account-group</v-icon>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="selectedStaffGroupId"
                            :items="staffGroups"
                            item-value="_id"
                            item-text="name"
                            label="Staff Group"
                            :menu-props="{ 'offset-y': true }"
                            outlined
                            clearable
                            :disabled="filtersDisabled"
                            :loading="filtersLoading"
                            :no-data-text="filtersNoDataText"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon color="primary">mdi-account-group</v-icon>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="selectedJobTitleId"
                            :items="jobTitles"
                            item-value="_id"
                            item-text="title"
                            label="Job Title"
                            :menu-props="{ 'offset-y': true }"
                            outlined
                            clearable
                            :disabled="filtersDisabled"
                            :loading="filtersLoading"
                            :no-data-text="filtersNoDataText"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon color="primary">mdi-clipboard-account</v-icon>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            id="staffing-automations-table"
                            :headers="headers"
                            :items="staffingAutomations"
                            :loading="staffingAutomationsLoading"
                            :server-items-length="staffingAutomationsCount"
                            :options.sync="options"
                            @update:options="onOptionsUpdate"
                            @click:row="onClickStaffingAutomation"
                            no-data-text="Create Automated Shifts for effortless schedule creation."
                        >
                            <template v-slot:item.status="{ item }">
                                <v-chip
                                    :class="{ 'ds-active-chip' : item.status === 'active', 'ds-inactive-chip': item.status !== 'active' }">
                                    {{ item.status | capitalize }}
                                </v-chip>
                            </template>
                            <template v-slot:item.job_titles="{ item }">
                                <truncating-chips-list
                                    :items="item.job_titles"
                                />
                            </template>
                            <template v-slot:item.shift_time="{ item }">
                                {{ parseShiftTime(item.shift_time) }}
                            </template>
                            <template v-slot:item.last_run="{ item }">
                                {{ parseDate(item.last_run) }}
                            </template>
                            <template v-slot:item.run_date="{ item }">
                                {{ parseDate(item.run_date) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <introductory-modal
            v-if="showIntroductoryModal"
            v-model="showIntroductoryModal"
            @onContinue="onContinueFromIntroductoryModal"
        />
        <add-automated-shift-modal
            v-if="showAddAutomatedShiftModal"
            v-model="showAddAutomatedShiftModal"
            @onNext="onAddAutomatedShiftNext"
        />
        <add-job-titles-modal
            v-if="staffingAutomationModalValue"
            v-model="staffingAutomationModalValue"
            :staffing-automation-id="staffingAutomationModalId"
            :start-date="staffingAutomationModalStartDate"
            :run-date="staffingAutomationModalRunDate"
            @onActivate="onActivate"
        />
    </v-card>
</template>

<script>
import TruncatingChipsList from "../../components/common/Lists/TruncatingChipsList";
import IntroductoryModal from "./IntroductoryModal";
import AddAutomatedShiftModal from "./AddAutomatedShift/AddAutomatedShiftModal";
import AddJobTitlesModal from "./AddAutomatedShift/AddJobTitlesModal";

export default {
    name: "StaffingAutomationsView",
    components: {TruncatingChipsList, IntroductoryModal, AddAutomatedShiftModal, AddJobTitlesModal},
    data: () => ({
        staffingAutomations: [],
        staffingAutomationsLoading: false,
        staffingAutomationsCount: 0,
        options: {sortDesc: ['desc']},
        statuses: [],
        selectedStatusId: null,
        departments: [],
        selectedDepartmentId: null,
        staffGroups: [],
        selectedStaffGroupId: null,
        jobTitles: [],
        selectedJobTitleId: null,
        filtersLoading: false,
        headers: [
            {text: 'Status', value: 'status', sortable: false},
            {text: 'Department', value: 'department', sortable: false},
            {text: 'Staff Group', value: 'staff_group', sortable: false},
            {text: 'Job Titles', value: 'job_titles', sortable: false},
            {text: 'Shift Time', value: 'shift_time', sortable: false},
            {text: 'Last Run', value: 'last_run'},
            {text: 'Next Run', value: 'run_date'}
        ],
        showIntroductoryModal: false,
        showAddAutomatedShiftModal: false,
        staffingAutomationModalValue: false,
        staffingAutomationModalId: null,
        staffingAutomationModalStartDate: null,
        staffingAutomationModalRunDate: null,
        isLocationChanged: false,
        api: new formHelper()
    }),
    computed: {
        selectedFilters() {
            return {
                status: this.selectedStatusId,
                departments: this.selectedDepartmentId ? [this.selectedDepartmentId] : null,
                staff_groups: this.selectedStaffGroupId ? [this.selectedStaffGroupId] : null,
                job_titles: this.selectedJobTitleId ? [this.selectedJobTitleId] : null,
                ...(this.globalLocation?._id && {
                    locations: [this.globalLocation._id]
                })
            }
        },
        filtersDisabled() {
            return this.staffingAutomationsLoading || this.filtersLoading
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        canEditStaffingAutomation() {
            return this.$userHasPermission(this.$user, this.$config.permissions.STAFFING_AUTOMATION.EDIT)
        },
        filtersNoDataText() {
            if (this.globalLocation) {
                return 'No data available'
            }
            return 'You must select a location to use the filters'
        }
    },
    methods: {
        fetchFilters() {
            this.filtersLoading = true
            this.api.get('/staffing-automation/filter-data').then(data => {
                const filters = data?.data
                if (Array.isArray(filters.statuses)) {
                    this.statuses = Array.from(filters.statuses).map(status => ({
                        _id: status,
                        name: status.toString().charAt(0).toUpperCase() + status.slice(1)
                    }))
                }
                if (Array.isArray(filters.departments)) {
                    this.departments = Array.from(filters.departments)
                }
                if (Array.isArray(filters.staff_groups)) {
                    this.staffGroups = Array.from(filters.staff_groups)
                }
                if (Array.isArray(filters.job_titles)) {
                    this.jobTitles = Array.from(filters.job_titles)
                }
            }).catch(console.log).finally(() => {
                this.filtersLoading = false
            })
        },
        resetFilters() {
            if (!this.selectedStatusId && !this.selectedDepartmentId && !this.selectedStaffGroupId && !this.selectedJobTitleId) {
                this.fetchStaffingAutomations()
            }
            this.selectedStatusId = null
            this.selectedDepartmentId = null
            this.selectedStaffGroupId = null
            this.selectedJobTitleId = null
        },
        fetchStaffingAutomations() {
            this.staffingAutomationsLoading = true
            this.api.post('/staffing-automation/list', {
                ...this.selectedFilters,
                ...this.getOptions(),
            }).then(data => {
                if (Array.isArray(data?.data?.data)) {
                    this.staffingAutomations = Array.from(data.data.data)
                    this.staffingAutomationsCount = data.data.total
                }
            }).catch(console.log).finally(() => {
                this.staffingAutomationsLoading = false
            })
        },
        onOptionsUpdate() {
            if (this.isLocationChanged) {
                this.isLocationChanged = false
            } else {
                this.fetchStaffingAutomations()
            }
        },
        getOptions() {
            const {page, itemsPerPage, sortBy, sortDesc} = this.options
            return {
                page,
                perPage: itemsPerPage < 0 ? 100 : itemsPerPage,
                sortBy: sortBy.length > 0 ? sortBy[0] : null,
                sortDesc: sortDesc.length > 0 ? !!sortDesc[0] : true
            }
        },
        onClickStaffingAutomation(staffingAutomation) {
            if (staffingAutomation) {
                this.$emit('onClickStaffingAutomation', staffingAutomation._id)
            }
        },
        parseShiftTime(shiftTime) {
            if (shiftTime) {
                const {start_time, end_time} = shiftTime
                return `${this.getTime(start_time)} - ${this.getTime(end_time)}`
            }
            return 'N/A'
        },
        parseDate(date) {
            if (date) {
                if (this.globalTimezone) {
                    return moment.tz(date, this.globalTimezone).format('MM/DD/YYYY')
                }
                return moment(date).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        getTime(time) {
            return moment(moment().format('YYYY-MM-DD') + ' ' + time).format('h:mm A')
        },
        onAddAutomatedShiftNext(staffingAutomationId, startDate, runDate) {
            this.staffingAutomationModalValue = true
            this.staffingAutomationModalId = staffingAutomationId
            this.staffingAutomationModalStartDate = startDate
            this.staffingAutomationModalRunDate = runDate
            this.fetchStaffingAutomations()
        },
        onActivate() {
            this.fetchStaffingAutomations()
        },
        onAddNewAutomatedShift() {
            if (this.$appData && !this.$appData.staffing_automation_introductory_modal_dismissed) {
                this.showIntroductoryModal = true
            } else {
                this.showAddAutomatedShiftModal = true
            }
        },
        onContinueFromIntroductoryModal(dontShowAgain) {
            if (dontShowAgain) {
                this.$appData.staffing_automation_introductory_modal_dismissed = true
            }
            this.showAddAutomatedShiftModal = true
        }
    },
    watch: {
        selectedFilters() {
            this.onOptionsUpdate()
        },
        globalLocation(globalLocation) {
            const findLocationIndex = this.headers.findIndex(header => header.value === 'location')
            if (globalLocation?._id) {
                if (findLocationIndex !== -1) {
                    this.headers.splice(findLocationIndex, 1)
                }
            } else {
                if (findLocationIndex === -1) {
                    this.headers.splice(1, 0, {
                        text: 'Location',
                        value: 'location',
                        sortable: false
                    })
                }
            }
            this.resetFilters()
            this.fetchFilters()
        }
    },
    mounted() {
        this.fetchFilters()
        window.EventBus.$on('changingLocation', () => {
            this.isLocationChanged = true
        })
    },
    created() {
        if (!this.globalLocation?._id) {
            this.headers.splice(1, 0, {
                text: 'Location',
                value: 'location',
                sortable: false
            })
        }
    }
}
</script>

<style scoped>
#staffing-automations-table :deep(tr:hover) {
    cursor: pointer;
}
</style>
