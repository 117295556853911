<template>
    <v-dialog
        v-model="showModal"
        persistent
        max-width="600"
    >
        <v-card>
            <v-toolbar color="primary" dark>
                <v-icon color="yellow" class="mr-3">mdi-alert</v-icon>
                <v-toolbar-title>Inactive Session</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pt-8 logout-text">
                <p>Your session is about to expire due to inactivity.</p>
                <p>You will be logged out automatically in <strong>{{ logoutCountdown }}</strong> seconds.</p>
            </v-card-text>

            <v-card-actions class="pb-4">
                <v-row no-gutters>
                    <v-spacer></v-spacer>

                    <v-col class="text-right">
                        <v-btn
                            outlined
                            color="error"
                            title="Logout"
                            @click="logout"
                            :loading="loading"
                        >
                            Logout
                        </v-btn>
                    </v-col>

                    <v-col class="text-right">
                        <v-btn
                            color="success"
                            @click="keepLoggedIn"
                            :loading="loading"
                        >
                            Stay logged in
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SessionTimeoutModal",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showModal: this.value,
            loading: false,
            logoutCountdown: 60,
            disableTimer: false,
        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.showModal = false
        },

        logout(event) {
            this.loading = true

            if (typeof event !== 'undefined') {
                event.preventDefault()
            }

            document.getElementById('logout-form').submit()
        },

        keepLoggedIn() {
            this.disableTimer = true
            this.$emit('keepLoggedIn')
            this.$logoutTimerCountdownWorker.postMessage({clearTimer: true})
            this.closeModal()
        },

        startCountdownWorker() {
            this.logoutCountdown = 60

            if (!this.disableTimer) {
                this.$logoutTimerCountdownWorker.postMessage({startLogoutTimer: true, timeUntilLogout: this.logoutCountdown})
            }
        },

        countdownWorkerCallback(time_left) {
            this.logoutCountdown = time_left

            if (time_left === 0 && !this.disableTimer) {
                this.logout()
            }
        }
    },
    mounted() {
        this.$logoutTimerCountdownWorker.onmessage = ({data: {timeUntilLogout}}) => {
            this.countdownWorkerCallback(timeUntilLogout)
        }
    },
    watch: {
        value(val) {
            this.showModal = val
        },

        showModal(val) {
            if (!val) {
                this.$emit('input', false)
            } else {
                this.disableTimer = false
                this.logoutCountdown = 60
                this.startCountdownWorker()
            }
        }
    }
}
</script>

<style scoped>
    .logout-text {
        color: black !important;
        font-size: 15px;
    }
</style>
