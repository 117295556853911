<template>
    <div class="bulk-upload-users-select-role-container">
        <div class="bulk-upload-users-select-role-instructions">
            <bulk-upload-users-instructions :email-domains="emailDomains"/>
        </div>
        <div class="bulk-upload-users-select-role-content">
            <div class="bulk-upload-users-select-role-wrapper">
                <div class="text--h3 text-center">
                    Select a User Role
                </div>
                <div class="text-center text--paragraph">
                    <div>
                        This role will be applied to all users uploaded in this flow.
                    </div>
                    <div>
                        Visit our <a target="_blank" :href=supportUrl>support page</a> for more information on the different <a target="_blank" :href='supportUrl + "/user-roles"'>User Roles</a>.
                    </div>
                </div>
                <div class="bulk-upload-users-select-role-input-container">
                    <div class="bulk-upload-users-select-role-input-wrapper">
                        <v-autocomplete
                            v-model="role"
                            label="User Role"
                            outlined
                            item-value="_id"
                            item-text="name"
                            :items="roles"
                            clearable
                        ></v-autocomplete>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BulkUploadUsersInstructions from './BulkUploadUsersInstructions'

export default {
    name: "BulkUploadUsersSelectRole",
    components: { BulkUploadUsersInstructions },
    props: {
        value: {
            type: String,
            default: null,
        },
        emailDomains: {
            type: Array,
            default: () => [],
        },
        roles: {
            type: Array,
            default: () => [],
        }
    },
    computed: {
        role: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        supportUrl() {
            return this.$root.websiteUrl + '/support';
        }
    }
}
</script>

<style scoped>
    .bulk-upload-users-select-role-container {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .bulk-upload-users-select-role-instructions {
        flex: 0 0 auto;
    }

    .bulk-upload-users-select-role-content {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bulk-upload-users-select-role-wrapper {
        display: flex;
        flex-direction: column;
        padding: 24px;
        row-gap: 16px;
    }

    .bulk-upload-users-select-role-input-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .bulk-upload-users-select-role-input-wrapper {
        width: 360px;
    }
</style>
