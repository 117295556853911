<template>
    <div>
        <div class="d-flex flex-row align-center" style="column-gap: 20px; margin-bottom: 10px;">
            <div style="flex: 15%;"></div>
            <v-skeleton-loader
                style="flex: 85%"
                class="d-flex flex-row justify-space-between"
                type="table-cell@7"
            ></v-skeleton-loader>
        </div>
        <div v-for="i in 8" :key="i">
            <div class="d-flex flex-row align-center" style="column-gap: 20px; margin-bottom: 20px;">
                <v-skeleton-loader
                    v-if="i % 3 === 1"
                    style="flex: 15%"
                    type="text"
                ></v-skeleton-loader>
                <div v-else style="flex: 15%;"></div>
                <v-skeleton-loader
                    style="flex: 85%"
                    class="d-flex flex-row justify-space-between"
                    type="chip@7"
                ></v-skeleton-loader>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SchedulerLoader"
}
</script>

<style scoped>

</style>
