<template>
    <div class="department-card-content-wrapper">
        <div class="text-center">
            <span class="text-capitalize text--h4">
                {{ title }}
            </span>
            <span v-if="subtitle && !loading" class="text--h4 text--secondary">
                ({{ subtitle }})
            </span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DepartmentCardContentWrapper",
    props: {
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
    .department-card-content-wrapper {
        width: 180px;
        height: 196px;
    }
</style>
