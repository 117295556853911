<template>
    <v-dialog v-model="showModal" scrollable width="500px" persistent>
        <v-card>
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title class="text--h2">Remove Automation</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-6">
                <p class="black--text text--paragraph">
                    This Automated Report will be deactivated. All Automation Schedule settings and sharing will be
                    cleared.
                </p>
                <p class="black--text text--paragraph">
                    Do you want to continue?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn
                            class="mr-2"
                            text
                            color="primary"
                            :disabled="isRemoving"
                            @click="onCancel"
                        >No
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            outlined
                            color="primary"
                            :loading="isRemoving"
                            @click="onConfirm"
                        >Yes
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "RemoveAutomationModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        reportAutomationId: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        isRemoving: false,
        api: new formHelper()
    }),
    computed: {
        showModal: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCancel() {
            this.showModal = false
        },
        onConfirm() {
            this.isRemoving = true
            this.api.delete(`/report-automation/${this.reportAutomationId}`)
                .then(({message}) => {
                    message('Report Automation removed.')
                    this.showModal = false
                    this.$emit('onRemove')
                })
                .catch(console.log)
                .finally(() => {
                    this.isRemoving = false
                })
        }
    },
}
</script>

<style scoped>

</style>
