<template>
    <v-window class="pa-4" v-model="window">
        <v-window-item value="reports" :key="reportsWindowKey">
            <ReportsView
                :key="reportsViewKey"
                :reports-alert-dismissed="reportsAlertDismissed"
                :report-presets-alert-dismissed="reportPresetsAlertDismissed"
                @onDismissReportsAlert="onDismissReportsAlert"
                @onDismissReportPresetsAlert="onDismissReportPresetsAlert"
                @onReportTypeSelect="onReportTypeSelect"
                @onRunQuickReport="onRunQuickReport"
                @onClickReport="onClickReport"/>
        </v-window-item>
        <v-window-item value="new-report">
            <CreateReport
                :key="createReportKey"
                :report-type="reportType"
                :quick-report="shouldRunQuickReport"
                :preset="preset"
                :location="globalLocation"
                @onGoBack="onGoBack"
                @onPresetUpdate="onPresetUpdate"
                @onGenerateReport="onGenerateReport"
            />
        </v-window-item>
        <v-window-item value="report-details">
            <ReportDetails
                :key="reportDetailsKey"
                :report="selectedReport"
                @onSavePreset="onPresetSave"
                @onGoBack="onGoBack"
            />
        </v-window-item>
    </v-window>
</template>

<script>
import {
    parseReportType,
    parseReportPresetName,
    parseReportDateRange,
    parseReportLocations,
    parseReportCreatedBy
} from "../../../lib/helpers/reports";
import ReportsView from "./ReportsView";
import CreateReport from "../CreateReport/CreateReport";
import ReportDetails from "./ReportDetails";

export default {
    name: "ReportsWindow",
    components: { ReportDetails, CreateReport, ReportsView },
    data() {
        return {
            window: 'reports',
            reportType: null,
            reportsViewKey: 2222,
            reportsWindowKey: 7777,
            createReportKey: 3333,
            reportDetailsKey: 4444,
            selectedReport: null,
            shouldRunQuickReport: false,
            preset: null,
            reportsAlertDismissed: this.$appData.reports_page_notice_dismissed,
            reportPresetsAlertDismissed: this.$appData.reports_preset_page_notice_dismissed,
            api: new formHelper()
        }
    },
    computed: {
        globalLocation() {
            return this.$root.globalLocation;
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        onReportTypeSelect(reportType) {
            this.createReportKey = this.createReportKey + 1
            this.reportType = reportType
            this.shouldRunQuickReport = false
            this.preset = null
            this.window = 'new-report'
        },
        onGoBack() {
            this.window = 'reports'
        },
        onPresetSave(preset) {
            this.reportsWindowKey = this.reportsWindowKey + 1
            this.selectedReport = {
                ...this.selectedReport,
                report_preset_id: preset._id,
                report_preset_name: preset.title,
            }
        },
        onPresetUpdate() {
            this.reportsWindowKey = this.reportsWindowKey + 1
        },
        onClickReport(report) {
            this.reportDetailsKey = this.reportDetailsKey + 1
            this.selectedReport = {...report}
            this.window = 'report-details'
        },
        onGenerateReport(report) {
            this.reportDetailsKey = this.reportDetailsKey + 1
            this.reportsWindowKey = this.reportsWindowKey + 1
            this.selectedReport = {...report}
            this.window = 'report-details'
        },
        onRunQuickReport(preset, reportType) {
            this.createReportKey = this.createReportKey + 1
            this.reportType = reportType
            this.shouldRunQuickReport = true
            this.preset = {...preset}
            this.window = 'new-report'
        },
        onDismissReportsAlert() {
            this.reportsAlertDismissed = true
            this.api.post('/reports/dismiss-notice')
        },
        onDismissReportPresetsAlert() {
            this.reportPresetsAlertDismissed = true
            this.api.post('/report-preset/dismiss-notice')
        },
        parseReport(report) {
            return {
                ...report,
                report_type_name: parseReportType(report),
                preset_name: parseReportPresetName(report),
                date_range: parseReportDateRange(
                    report,
                    report.type === 'payroll_period' && report.hospital_group?.timezone ?
                        report.hospital_group.timezone : this.globalTimezone),
                displayed_created_by: parseReportCreatedBy(report),
                displayedFilters: {
                    displayedLocations: parseReportLocations(report)
                },
                created_at: moment(report.created_at).tz(this.globalTimezone).format('LLL')
            }
        },
    },
    created() {
        if (this.$appData.preselected_report) {
            this.reportDetailsKey = this.reportDetailsKey + 1
            this.selectedReport = this.parseReport(this.$appData.preselected_report)
            this.window = 'report-details'
        }
    }
}
</script>

<style scoped>

</style>
