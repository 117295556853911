<template>
    <div class="d-flex flex-row justify-center align-center" :style="containerStyle">
        <v-icon class="mr-3" color="black">{{ statusInfo.icon }}</v-icon>
        <span :class="statusNameClass" class="text-center">{{ statusInfo.name }}</span>
    </div>
</template>

<script>
export default {
    name: "EventStatus",
    props: {
        type: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            statuses: {
                [this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_ASSIGNED]: {
                    name: 'Shift Assigned',
                    icon: 'mdi-check-circle',
                    backgroundColor: 'rgba(107, 201, 110, 0.4)',
                    borderColor: 'rgba(107, 201, 110, 1)'
                },
                [this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_IN_PROGRESS]: {
                    name: 'Shift In Progress',
                    icon: 'mdi-timer-sand',
                    backgroundColor: 'rgba(247, 219, 163, 1)',
                    borderColor: 'rgba(213, 191, 64, 1)'
                },
                [this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_COMPLETED]: {
                    name: 'Shift Completed',
                    icon: 'mdi-check-circle',
                    backgroundColor: 'rgba(107, 201, 110, 0.4)',
                    borderColor: 'rgba(107, 201, 110, 1)'
                },
                [this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_CANCELLED]: {
                    name: 'Shift Cancelled',
                    icon: 'mdi-close-circle',
                    backgroundColor: 'rgba(255, 218, 214, 1)',
                    borderColor: 'rgba(186, 26, 26, 1)'
                },
                [this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_OFFER]: {
                    name: 'Shift Offer',
                    icon: 'mdi-timer-sand',
                    backgroundColor: 'rgba(247, 219, 163, 1)',
                    borderColor: 'rgba(213, 191, 64, 1)'
                },
                [this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_AVAILABLE]: {
                    name: 'Shift Available',
                    icon: 'mdi-timer-sand',
                    backgroundColor: 'rgba(223, 224, 255, 1)',
                    borderColor: 'rgba(66, 83, 196, 1)'
                },
                [this.$config.SECONDARY_SHIFT_STATUSES.PENDING_CANCELLATION_REQUEST]: {
                    name: 'Cancellation Request Pending',
                    icon: 'mdi-timer-sand',
                    backgroundColor: 'rgba(247, 219, 163, 1)',
                    borderColor: 'rgba(213, 191, 64, 1)'
                },
                [this.$config.SECONDARY_SHIFT_STATUSES.DENIED_CANCELLATION_REQUEST]: {
                    name: 'Cancellation Request Denied',
                    icon: 'mdi-close-circle',
                    backgroundColor: 'rgba(255, 218, 214, 1)',
                    borderColor: 'rgba(186, 26, 26, 1)'
                },
                [this.$config.SECONDARY_SHIFT_STATUSES.NO_SHOW]: {
                    name: 'Marked No Show',
                    icon: 'mdi-calendar-remove',
                    backgroundColor: 'rgba(255, 218, 214, 1)',
                    borderColor: 'rgba(186, 26, 26, 1)'
                },
                [this.$config.SECONDARY_SHIFT_STATUSES.CALL_OUT]: {
                    name: 'Marked Call Out',
                    icon: 'mdi-calendar-remove',
                    backgroundColor: 'rgba(255, 218, 214, 1)',
                    borderColor: 'rgba(186, 26, 26, 1)'
                },
                [this.$config.SECONDARY_SHIFT_STATUSES.SHIFT_TIME_EDITED]: {
                    name: 'Shift Time Edited',
                    icon: 'mdi-clock-outline',
                    backgroundColor: 'rgba(247, 219, 163, 1)',
                    borderColor: 'rgba(213, 191, 64, 1)'
                },
            }
        }
    },
    computed: {
        statusInfo() {
            if (this.statuses.hasOwnProperty(this.status)) {
                return this.statuses[this.status]
            }
            if (this.type === 'primary') {
                return this.statuses[this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_ASSIGNED]
            } else {
                return this.statuses[this.$config.SECONDARY_SHIFT_STATUSES.PENDING_CANCELLATION_REQUEST]
            }
        },
        containerStyle() {
            return {
                backgroundColor: this.statusInfo.backgroundColor,
                border: `2px solid ${this.statusInfo.borderColor}`,
                padding: '8px 24px',
                borderRadius: '8px'
            }
        },
        statusNameClass() {
            return {
                'text--h3': this.type === 'primary',
                'text--paragraph': this.type === 'secondary'
            }
        }
    }

}
</script>

<style scoped>

</style>
