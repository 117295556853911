<template>
    <v-card :class="containerClass" :height="containerHeight" :elevation="2">
        <div>
            <v-container fluid>
                <v-row>
                    <v-col class="filter-row-container">
                        <div class="text--h4 mb-2">{{ reportType.text }} Report</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="filter-row-container">
                        <preset-select-input
                            v-model="selectedPreset"
                            :key="presetKey"
                            :location="location"
                            :readonly="readonly"
                            :report-type="reportType.value"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="filter-row-container preset-container mt-3">
                        <v-btn
                            color="primary"
                            text
                            :disabled="!selectedPreset || readonly"
                            @click="onEditPreset"
                        >
                            Preset Details
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="filter-row-container">
                        <v-divider />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div>
            <v-container fluid>
                <v-row>
                    <v-col class="filter-row-container">
                        <v-text-field
                            label="Location"
                            :value="location.name"
                            outlined
                            readonly/>
                        <slot name="report-filters"></slot>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <slot name="report-modal"></slot>
    </v-card>
</template>

<script>
import PresetSelectInput from "../FormInputs/PresetSelectInput";
export default {
    name: "GenericReportFilters",
    components: { PresetSelectInput },
    props: {
        value: {
            type: Object,
            default: () => null,
        },
        reportType: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        presetKey: {
            type: Number,
            default: 6666,
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        selectedPreset: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        containerClass() {
            return {
                'filters-container': true,
                'readonly-container': this.readonly,
                'rounded-card': true,
                'scrollbox': true
            }
        },
        containerHeight() {
            return this.maxHeight < 560 ? this.maxHeight : 560
        },
        maxHeight() {
            return window.innerHeight - 288
        }
    },
    methods: {
        onEditPreset() {
            this.$emit('onEditPreset')
        }
    }
}
</script>

<style scoped>
    .filters-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px 0;
    }
    .readonly-container {
        background-color: #DCDCDC;
    }
    .rounded-card {
        border-radius: 12px !important;
    }
    .filter-row-container {
        padding: 0 12px;
    }
    .preset-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .preset-button {
        text-transform: none !important;
    }
    .scrollbox {
        overflow: auto;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
</style>
