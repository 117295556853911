<template>
    <v-dialog v-model="open"  max-width="580px" persistent>
        <v-card elevation="2">
            <v-toolbar class="warning-modal" elevation="0" height="60">
                    <v-toolbar-title class="text--h2">Retroactive Assign</v-toolbar-title>
                </v-toolbar>

            <v-container fluid style="padding: 12px;">
                <v-card-text class="black--text mt-5 body-1">
                    You are assigning Staff directly to a past shift for historical purposes.
                </v-card-text>

                <v-progress-circular v-if="isLoading" indeterminate color="primary" :size="20"></v-progress-circular>

                <v-col class="m-4">
                    <v-text-field
                        v-model="searchBy"
                        :disabled="isLoading || isSaving"
                        prepend-icon="mdi-account-search"
                        clearable
                        placeholder="Search Staff"
                        style="padding-top: 0;"
                    ></v-text-field>
                </v-col>

                <v-col class="m-4 scrollable">
                    <historic-user-list
                        :users="filteredHistoricActionableUsers"
                        :loading="isLoading || isSaving"
                        @click="onHistoricActionableUserClick"
                    ></historic-user-list>
                </v-col>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-end" style="column-gap: 5px;">
                            <v-btn text color="primary" @click="onClose" :disabled="isLoading || isSaving">Cancel</v-btn>
                            <v-btn :disabled="selectedHistoricalAssignIds >= 0" dusk="confirmHistoricAssign" color="primary" @click="onConfirm" :loading="isLoading || isSaving">Confirm</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-container>
        </v-card>
        <unsaved-changes-dialog ref="unsavedChangesDialogRef"/>
    </v-dialog>
</template>

<script>
import HistoricUserList from '../Lists/UserList/HistoricUserList'
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog.vue";

export default {
    name: "HistoricalAssignModal",
    components: { HistoricUserList, UnsavedChangesDialog },
    model: {
        prop: "open",
        event: "change",
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        shiftRequestId: {
            type: String,
            default: null,
        },
        amountRequested: {
            type: Number,
            default: 0,
        },
        authIsAuthorized: {
            type: Boolean,
            default: false,
        },
        isInPast: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        api: new formHelper(),
        isSaving: false,
    }),
    computed: {
        historicActionableUsers: {
            get () {
                return this.$store.getters.getHistoricActionableUsers
            },
            set (historicActionableUsers) {
                this.$store.dispatch('setHistoricActionableUsers', { historicActionableUsers })
            },
        },
        prevSelectedIds: {
            get () {
                return this.$store.getters.getPrevSelectedIds
            },
            set (prevSelectedIds) {
                this.$store.dispatch('setPrevSelectedIds', { prevSelectedIds })
            },
        },
        searchBy: {
            get () {
                return this.$store.getters.getSearchBy
            },
            set (searchBy) {
                this.$store.dispatch('setSearchBy', { searchBy })
            },
        },
        filledShiftsCount () {
            return this.$store.getters.getAssignedCount
        },
        availableShiftsCount () {
            return this.totalShiftsCount - this.filledShiftsCount
        },
        canHistoricAssign () {
            return this.authIsAuthorized || (this.$authIsManager || this.$authIsAdministrator && this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.HISTORICAL_ASSIGN)) && this.availableShiftsCount > 0
        },
        unsavedChangesDialogRef() {
            return this.$refs.unsavedChangesDialogRef
        },
        totalShiftsCount () {
            if (this.amountRequested) {
                return this.amountRequested
            }
            return 0
        },
        loading () {
            return this.$store.getters.getUsersLoading
        },
        isLoading () {
            return this.loading
        },
        filteredHistoricActionableUsers () {

            const { historicActionableUsers, searchBy } = this
            if (searchBy) {
                return historicActionableUsers.filter(user => {
                    const name = `${user.first_name} ${user.last_name}`.toLowerCase()
                    return name.includes(searchBy.trim().toLowerCase())
                })
            }

            return historicActionableUsers
        },
        selectedHistoricalAssignIds () {
            return this.historicActionableUsers.filter(user => user.selected).map(user => user._id)
        },
        isDirty () {
            let isDirty = false
            isDirty = (Array.isArray(this.selectedHistoricalAssignIds) && this.selectedHistoricalAssignIds.length > 0)

            return isDirty
        }
    },
    watch: {
        shiftRequestId: {
            immediate: true,
            handler (shiftRequestId) {
                if (shiftRequestId) {
                    this.searchBy = null
                    this.fetchUsers(shiftRequestId)
                }
            }
        },
        selectedHistoricalAssignIds: {
            immediate: true,
            handler (toBeHistoricAssignedIds) {
                this.dispatchSetToBeHistoricAssignedIds(toBeHistoricAssignedIds)
            },
        },
    },
    methods: {
        close() {
            this.$emit('change', false);
        },
        onClose () {
            if (this.isDirty) {
                return this.unsavedChangesDialogRef.open()
                    .then(() => {
                        this.dispatchSetHistoricActionableUsers(
                            this.historicActionableUsers.map(user => {
                                return {
                                    ...user,
                                    selected: false,
                                    disabled: false
                                }

                            })
                        )
                        this.close();
                    })
                    .catch(() => {})

            } else {
                this.close();
            }
        },
        dispatchSetUsersLoading (usersLoading) {
            this.$store.dispatch('setUsersLoading', { usersLoading })
        },
        dispatchSetToBeHistoricAssignedIds (toBeHistoricAssignedIds) {
            this.$store.dispatch('setToBeHistoricAssignedIds', { toBeHistoricAssignedIds })
        },
        dispatchSetSelectedShiftRequestId (selectedShiftRequestId) {
            this.$store.dispatch('setSelectedShiftRequestId', { selectedShiftRequestId })
        },
        dispatchSetAssignedCount (assignedCount) {
            this.$store.dispatch('setAssignedCount', { assignedCount })
        },
        dispatchSetHistoricActionableUsers (historicActionableUsers) {
            this.$store.dispatch('setHistoricActionableUsers', { historicActionableUsers })
        },
        onConfirm() {
            const { selectedHistoricalAssignIds } = this
            if (selectedHistoricalAssignIds.length <= 0) {
                this.$snackNotify('warning', 'No changes have been made.')
                return
            }
            if (this.shiftRequest && selectedHistoricalAssignIds.length > 0) {
                if (this.filledShiftsCount + selectedHistoricalAssignIds.length >= this.totalShiftsCount) {
                    this.$snackNotify('warning', 'Unable to offer shift. You have selected more Staff Members than open shifts.')
                    this.dispatchSetHistoricActionableUsers(
                        this.historicActionableUsers.map(user => {
                            if (user.selected) {
                                return {
                                    ...user,
                                    selected: false,
                                    disabled: false
                                }
                            }
                            return {
                                    ...user,
                                    disabled: false
                                }
                        })
                    )
                    return
                }
            }
            this.historicAssign(this.shiftRequestId, this.selectedHistoricalAssignIds);
        },
        historicAssign (shiftRequestId, selectedHistoricalAssignIds) {
            this.isSaving = true
            this.api.post(`/shift-request/historical-assign/${shiftRequestId}`, {
                user_ids_to_historic_assign: selectedHistoricalAssignIds,
            }).then(({ data }) => new Promise((resolve, reject) => {
                if (data) {
                    const { historic_assign_statuses } = data
                    if (historic_assign_statuses && historic_assign_statuses.success) {
                        this.$emit('onConfirm')
                        resolve()
                    } else {
                        reject({
                            customMessage: historic_assign_statuses.error || 'Unable to assign shift. Please try again.'
                        })
                    }
                } else {
                    reject({
                        customMessage: 'No data.'
                    })
                }
            })).catch(error => {
                this.$emit('onCancel')
                if (error && error.customMessage) {
                    this.$snackNotify('error', error.customMessage)
                }
            }).finally(() => {
                this.isSaving = false
            })
        },
        onHistoricActionableUserClick(selectedUser) {

            // Calculate the count of selected users who are available or directly assigned.
            const currentSelectedCount = this.historicActionableUsers.filter(user => user.selected).length;

            const UserSelected = !selectedUser.selected;

            // Adjust the count based on the action (select or deselect) that is about to be performed.
            const upcomingSelectedCount = currentSelectedCount + (UserSelected ? 1 : -1) + this.filledShiftsCount;
            const exceedsAvailableShifts = upcomingSelectedCount >= this.totalShiftsCount;

            // Update the actionable users
            this.historicActionableUsers = this.historicActionableUsers.map(user => {

                const isCurrentlySelectedUser = user._id === selectedUser._id;
                const disabledUser = exceedsAvailableShifts && !user.selected;

                if (isCurrentlySelectedUser) {
                    return {
                        ...user,
                        selected: !user.selected,
                        disabled: false // A user shouldn't be disabled when it's being acted upon.
                    };
                } else {
                    // No more assignments or offers can be selected once the limit is reached
                    return {
                        ...user,
                        disabled: disabledUser
                    };
                }
            });
        },
        fetchUsers (shiftRequestId) {
            // Only load if event is in the past
            if (this.isInPast) {
                this.dispatchSetUsersLoading(true)
                this.api.get(`/shift-request/get-historical-users/${shiftRequestId}`)
                    .then(response => {
                        if (response && response.data) {
                            const {
                                all_users_except_assigned,
                                assigned_users
                            } = response.data
                            if (assigned_users) {
                                this.dispatchSetAssignedCount(assigned_users.length)
                            }
                            const availableUsers = Array.isArray(all_users_except_assigned) ?
                            all_users_except_assigned.map(user => ({
                                    ...user,
                                    available: true,
                                    selected: false,
                                    disabled: !this.canHistoricAssign,
                                })) : []

                            this.historicActionableUsers = [
                                ...availableUsers,
                            ]

                            if (this.prevselectedHistoricalAssignIds && Array.isArray(this.prevSelectedIds[type])) {
                                const mapping = this.prevSelectedIds[type].reduce((accum, id) => ({
                                    ...accum,
                                    [id]: true,
                                }), {})
                                this.historicActionableUsers = this.historicActionableUsers.map(user => ({
                                    ...user,
                                    selected: mapping.hasOwnProperty(user._id)
                                }))
                                if (this.selectedHistoricalAssignIds.length >= this.totalShiftsCount) {
                                    this.historicActionableUsers = this.historicActionableUsers.map(user => {
                                        if (user.available) {
                                            return {
                                                ...user,
                                                disabled: !user.selected,
                                            }
                                        }
                                        return user
                                    })
                                }
                            }
                        }
                    })
                    .catch(console.log)
                    .finally(() => {
                        this.dispatchSetUsersLoading(false)
                    })
            }
        }
    },
}
</script>

<style scoped>
    .scrollable {
        max-height: 300px;
        overflow-y: auto;
    }

</style>
