<template>
    <v-radio-group v-model="selectedPayrollPeriod" :readonly="readonly">
        <v-row style="padding: 0 32px !important;">
            <v-col class="ds-modal-input-container">
                <v-radio value="weekly">
                    <template v-slot:label>
                        <payroll-period-radio-label
                            title="Weekly"
                            subtitle="Weekly payroll period starting on the selected day of week. Hours worked beyond Max Hours in each period are considered overtime."
                        />
                    </template>
                </v-radio>
                <PayrollPeriodStartsOn
                    v-if="isWeekly"
                    v-model="weeklyStartsOn"
                    :readonly="readonly"
                />
            </v-col>
            <v-col class="ds-modal-input-container">
                <v-radio value="bi_weekly">
                    <template v-slot:label>
                        <payroll-period-radio-label
                            title="Bi-weekly"
                            subtitle="Bi-weekly (14 days) payroll period starting on the selected day of week. Hours worked beyond Max Hours in each period are considered overtime."
                        />
                    </template>
                </v-radio>
                <PayrollPeriodStartsOn
                    v-if="isBiWeekly"
                    v-model="biWeeklyStartsOn"
                    :readonly="readonly"
                />
            </v-col>
        </v-row>
        <v-row :style="secondContainerStyle">
            <v-col>
                <v-radio value="bi_monthly">
                    <template v-slot:label>
                        <payroll-period-radio-label
                            title="Bi-Monthly"
                            subtitle="Payroll period between the 1st-15th, and 16th-EoM, of each month. Hours worked beyond Max Hours in either period are considered overtime."
                        />
                    </template>
                </v-radio>
            </v-col>
            <v-col>
                <v-radio value="monthly">
                    <template v-slot:label>
                        <payroll-period-radio-label
                            title="Monthly"
                            subtitle="Payroll period per calendar month. Hours worked beyond Max Hours in each month are considered overtime."
                        />
                    </template>
                </v-radio>
            </v-col>
        </v-row>
    </v-radio-group>
</template>

<script>
import PayrollPeriodRadioLabel from "./PayrollPeriodRadioLabel";
import PayrollPeriodStartsOn from "./PayrollPeriodStartsOn";

export default {
    name: "PayrollPeriod",
    components: {PayrollPeriodRadioLabel, PayrollPeriodStartsOn},
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            weeklyStartsOn: this.value.period === 'weekly' ? this.value.startsOn : 0,
            biWeeklyStartsOn: this.value.period === 'bi_weekly' ? this.value.startsOn : 0,
        }
    },
    computed: {
        selectedPayrollPeriod: {
            get() {
                return this.value.period
            },
            set(value) {
                this.$emit('input', {
                    ...this.value,
                    period: value,
                })
            }
        },
        isWeekly() {
            return this.selectedPayrollPeriod === 'weekly'
        },
        isBiWeekly() {
            return this.selectedPayrollPeriod === 'bi_weekly'
        },
        secondContainerStyle() {
            if (this.isWeekly || this.isBiWeekly) {
                return {
                    padding: '0 32px !important'
                }
            }
            return {
                padding: '14px 32px !important'
            }
        }
    },
    watch: {
        selectedPayrollPeriod(period) {
            if (period === 'weekly') {
                this.$emit('input', {
                    ...this.value,
                    startsOn: this.weeklyStartsOn,
                })
            } else if (period === 'bi_weekly') {
                this.$emit('input', {
                    ...this.value,
                    startsOn: this.biWeeklyStartsOn,
                })
            }
        },
        weeklyStartsOn(startsOn) {
            this.$emit('input', {
                ...this.value,
                startsOn
            })
        },
        biWeeklyStartsOn(startsOn) {
            this.$emit('input', {
                ...this.value,
                startsOn
            })
        }
    }
}
</script>

<style scoped>

</style>
