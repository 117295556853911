<template>
    <v-tooltip
        v-model="tooltipActive"
        bottom
        color="primary"
    >
        <template v-slot:activator="{ attrs }">
            <div
                v-bind="attrs"
                class="typography-text-container"
                :style="styleContainer"
                ref="typographyRef"
                @mouseenter="onMouseEnter"
                @mouseleave="onMouseLeave"
            >
                {{ text }}
            </div>
        </template>
        <span>{{ text }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "Typography",
    props: {
        text: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '#000000',
        },
        fontSize: {
            type: Number,
            default: 16,
        },
        fontWeight: {
            type: Number,
            default: 400,
        }
    },
    data: () => ({
        tooltipActive: false,
    }),
    computed: {
        styleContainer () {
            return {
                color: this.color,
                fontSize: `${this.fontSize}px`,
                fontWeight: this.fontWeight,
            }
        }
    },
    methods: {
        onMouseEnter () {
            const ref = this.$refs.typographyRef
            if (ref) {
                if (ref.scrollWidth > ref.clientWidth) {
                    this.tooltipActive = true
                }
            }
        },
        onMouseLeave () {
            this.tooltipActive = false
        },
    }
}
</script>

<style scoped>
    .typography-text-container {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
