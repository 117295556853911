<template>
    <v-checkbox
        :input-value="value"
        :disabled="disabled || checked"
        :readonly="readonly"
        @change="onChange"
        hide-details
    >
        <template v-slot:label>
            <div class="d-flex flex-column ml-2">
                <div class="text--h5" :style="titleStyle">
                    {{ title }}
                </div>
                <div class="text--body-sm" :style="subtitleStyle">
                    {{ subtitle }}
                </div>
            </div>
        </template>
    </v-checkbox>
</template>

<script>
export default {
    name: "EmploymentTypeOpenShiftsCheckbox",
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        titleStyle() {
            return {
                'margin-bottom': '2px !important',
                ...this.disabled && {
                    color: 'rgba(0,0,0,0.38) !important'
                }
            }
        },
        subtitleStyle() {
            if (this.disabled) {
                return {
                    color: 'rgba(0,0,0,0.38) !important'
                }
            }
            return {}
        }
    },
    methods: {
        onChange(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style scoped>

</style>
