<template>
    <div>
        <dep-sg-jt-st-sm-filters
            v-model="selectedFilters"
            :readonly="readonly"
            :preset="preset"
            :report-type="reportType"
            :location="location"
            @onChange="onChange"
        />
        <v-autocomplete
            v-model="selectedType"
            :items="types"
            item-value="id"
            item-text="text"
            :readonly="readonly"
            :clearable="!readonly"
            label="Cancellation Type"
            @change="onChange"
            outlined
        />
    </div>
</template>

<script>
import DepSgJtStSmFilters from "./DepSgJtStSmFilters";

export default {
    name: "ShiftCancellationFilters",
    components: {DepSgJtStSmFilters},
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => null,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedFilters: {},
        selectedType: null,
        types: [{
            id: 'requested',
            text: 'Cancellation Requested',
        }, {
            id: 'not_requested',
            text: 'Cancelled Without Request',
        }],
    }),
    computed: {
        filters() {
            return {
                ...this.selectedFilters,
                type: this.selectedType,
            }
        }
    },
    methods: {
        onChange() {
            this.$emit('onChange')
        }
    },
    watch: {
        filters(filters) {
            this.$emit('input', filters)
        },
        preset: {
            immediate: true,
            handler(preset) {
                if (preset) {
                    const {filters} = preset
                    if (filters) {
                        const {type} = filters
                        if (type) {
                            this.selectedType = type
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
