<template>
    <v-card class="elevation-0">
        <v-toolbar class="ds-toolbar" elevation="0">
            <v-toolbar-title>Configuration Options</v-toolbar-title>
            <v-spacer/>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                @click="onGoBackToOrganizationPage"
            >
                Back to Organization
            </v-btn>
        </v-toolbar>
        <configuration-options-tabs />
    </v-card>
</template>

<script>
import ConfigurationOptionsTabs from "./components/ConfigurationOptionsTabs";

export default {
    name: "ConfigurationOptionsWindow",
    components: { ConfigurationOptionsTabs },
    methods: {
        onGoBackToOrganizationPage() {
            this.$emit('back:organization')
        }
    }
}
</script>

<style scoped>

</style>
