<template>
        <v-container fluid>
            <dashboard-view v-if="showDashboardView"></dashboard-view>
            <v-row v-else>
                <v-col v-if="!isStaff" cols="12" sm="6">
                    <v-card dark flat>
                        <v-img src="https://cdn.vuetifyjs.com/images/cards/forest.jpg" gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)">
                            <v-container fill-height fluid>
                                <v-row align="center">
                                    <v-col>
                                        <strong class="display-4 font-weight-regular mr-4">{{ dayOfMonth }}</strong>
                                        <div class="headline font-weight-light">{{ dayOfWeek }}</div>
                                        <div class="text-uppercase font-weight-light">{{ year }}</div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" v-if="isStaff">
                    <v-card height="100%" color="grey lighten-4" class="pa-5">
                        <v-card-title>
                            <v-icon color="primary_text" class="mr-5" size="64">
                                mdi-calendar-question
                            </v-icon>
                            <v-row>
                                <v-col>
                                    <div class="caption grey--text text-uppercase">
                                        Total Shifts
                                    </div>
                                    <div>
                                        <span class="display-2 font-weight-black">{{ total_shifts_for_user }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text>
                                <v-sheet color="transparent">
                                    <v-sparkline
                                        :smooth="16"
                                        :gradient="['#1feaea', '#ffd200',  '#f72047']"
                                        :line-width="3"
                                        :value="users_shifts_for_week.values"
                                        :labels="users_shifts_for_week.labels"
                                        auto-draw
                                        stroke-linecap="round"
                                    ></v-sparkline>
                                </v-sheet>
                        </v-card-text>

                        <v-card-text>
                            <div class="display-1 font-weight-thin text-center">Weekly shifts</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" v-if="isStaff">
                    <v-card height="100%" color="grey lighten-4" class="pa-5">
                        <v-card-title>
                            <v-icon color="primary_text" class="mr-5" size="64">
                                mdi-calendar-question
                            </v-icon>
                            <v-row>
                                <v-col>
                                    <div class="caption grey--text text-uppercase">
                                        Total Available Hours
                                    </div>
                                    <div>
                                        <span class="display-2 font-weight-black">{{ total_availability_for_user }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text>
                                <v-sheet color="transparent">
                                    <v-sparkline
                                        :smooth="16"
                                        :gradient="['#1feaea', '#ffd200',  '#f72047']"
                                        :line-width="3"
                                        :value="users_availability_for_week.values"
                                        :labels="users_availability_for_week.labels"
                                        auto-draw
                                        stroke-linecap="round"
                                    ></v-sparkline>
                                </v-sheet>
                        </v-card-text>

                        <v-card-text>
                            <div class="display-1 font-weight-thin text-center">Weekly availability</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" v-if="!isStaff">
                    <v-card  height="100%" class="mx-auto text-xs-center" dark>
                        <v-card-text>
                            <v-sheet color="rgba(0, 0, 0, .12)">
                                <v-sparkline
                                    :value="all_shifts_for_week.values"
                                    :labels="all_shifts_for_week.labels"
                                    color="rgba(255, 255, 255, .7)"
                                    height="120"
                                    type="bar"
                                    padding="24"
                                    stroke-linecap="round"
                                    smooth
                                >
                                    <template v-slot:label="item">
                                        {{ item.value }}
                                    </template>
                                </v-sparkline>
                            </v-sheet>
                        </v-card-text>

                        <v-card-text>
                            <div class="display-1 font-weight-thin text-center">All weekly shifts</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" v-if="!isStaff">
                    <v-card height="100%" color="grey lighten-4" class="pa-5">
                        <v-card-title>
                            <v-icon color="primary_text" class="mr-5" size="64">
                                mdi-calendar-question
                            </v-icon>
                            <v-row>
                                <v-col>
                                    <div class="caption grey--text text-uppercase">
                                        Shifts
                                    </div>
                                    <div>
                                        <span class="display-2 font-weight-black">{{ total_open_shifts }}</span>
                                        <strong>Pending</strong>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-sheet color="transparent">
                            <v-sparkline
                                :smooth="16"
                                :gradient="['#1feaea', '#ffd200',  '#f72047']"
                                :line-width="3"
                                :value="open_shifts_for_week.values"
                                :labels="open_shifts_for_week.labels"
                                auto-draw
                                stroke-linecap="round"
                            ></v-sparkline>
                        </v-sheet>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" v-if="!isStaff">
                    <v-card height="100%" color="grey lighten-4" class="pa-5">
                        <v-card-title>
                            <v-icon color="primary_text" class="mr-5" size="64">
                                mdi-calendar-question
                            </v-icon>
                            <v-row>
                                <v-col>
                                    <div class="caption grey--text text-uppercase">
                                        Shifts
                                    </div>
                                    <div>
                                        <span class="display-2 font-weight-black">{{ total_filled_shifts }}</span>
                                        <strong>filled</strong>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-sheet color="transparent">
                            <v-sparkline
                                :smooth="16"
                                :gradient="['#1feaea', '#ffd200',  '#f72047']"
                                :line-width="3"
                                :value="filled_shifts_for_week.values"
                                :labels="filled_shifts_for_week.labels"
                                auto-draw
                                stroke-linecap="round"
                            ></v-sparkline>
                        </v-sheet>
                    </v-card>
                </v-col>

            </v-row>
    </v-container>
</template>

<script>
import DashboardView from "../../components/dashboard/views/DashboardView";
export default {
    name: 'index',
    components: {DashboardView},
    data() {
        const { all_shifts_for_week, open_shifts_for_week, filled_shifts_for_week, users_shifts_for_week, users_availability_for_week } = this.$appData

        const shortDays = [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat'
        ]

        const dayHours = ['12 AM', '06 AM', '12 PM', '06 PM', '12 AM']

        const allShifts = {
            labels: shortDays,
            values: shortDays.map(day => typeof all_shifts_for_week[day] !== 'undefined' ? all_shifts_for_week[day] : 0)
        }

        const openShifts = {
            labels: shortDays,
            values: shortDays.map(day => typeof open_shifts_for_week[day] !== 'undefined' ? open_shifts_for_week[day] : 0)
        }

        const filledShifts = {
            labels: shortDays,
            values: shortDays.map(day => typeof filled_shifts_for_week[day] !== 'undefined' ? filled_shifts_for_week[day] : 0)
        }

        const userShifts = {
            labels: shortDays,
            values: shortDays.map(day => typeof users_shifts_for_week[day] !== 'undefined' ? users_shifts_for_week[day] : 0)
        }

        const userAvailability = {
            labels: shortDays,
            values: shortDays.map(day => typeof users_availability_for_week[day] !== 'undefined' ? users_availability_for_week[day] : 0)
        }

        return {
            isStaff: this.$authIsStaff,
            currentDate: moment(),
            all_shifts_for_week: allShifts,
            open_shifts_for_week: openShifts,
            filled_shifts_for_week: filledShifts,
            users_shifts_for_week: userShifts,
            users_availability_for_week: userAvailability
        }
    },

    computed: {
        dayOfMonth: function() {
            return this.currentDate.format('D')
        },
        dayOfWeek: function () {
            return this.currentDate.format('dddd')
        },
        year: function () {
            return this.currentDate.format('MMMM YYYY')
        },

        total_open_shifts() {
            return this.open_shifts_for_week.values.reduce((sum, value) => sum + value)
        },
        total_filled_shifts() {
            return this.filled_shifts_for_week.values.reduce((sum, value) => sum + value)
        },
        total_shifts_for_user () {
            return this.users_shifts_for_week.values.reduce((sum, value) => sum + value)
        },
        total_availability_for_user () {
            return Math.round(this.users_availability_for_week.values.reduce((sum, value) => sum + value))
        },
        showDashboardView() {
            return this.$authIsOwner || this.$authIsAdministrator
        }
      }
}
</script>
