<template>
    <custom-icon icon="mdi-check" color="rgb(90, 172, 94)"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";
export default {
    name: "GreenCheckIcon",
    components: { CustomIcon }
}
</script>

<style scoped>

</style>
