<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        width="500px"
        content-class="ds-dialog"
        persistent
    >
        <v-card class="ds-modal-card">
            <v-toolbar class="destructive-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Delete Shift Automation
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content">
                <p class="black--text">
                    Deleting this Shift Automation will not delete shifts that have already been created via this
                    automation.
                </p>
                <p class="black--text">
                    Would you still like to delete this Shift Automation?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel" :disabled="isDeleting">Cancel</v-btn>
                        <v-btn class="ml-2" color="error" outlined @click="onDelete" :loading="isDeleting">Delete
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteStaffingAutomation",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        staffingAutomationId: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isDeleting: false,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCancel() {
            this.modalValue = false
        },
        onDelete() {
            this.isDeleting = true
            this.api.delete(`/staffing-automation/${this.staffingAutomationId}`)
                .then(({message}) => {
                    message('Staffing Automation Deleted')
                    this.modalValue = false
                    this.$emit('onDelete')
                })
                .catch(console.log)
                .finally(() => {
                    this.isDeleting = false
                })
        }
    }
}
</script>

<style scoped>

</style>
