<template>
    <v-tooltip
        v-model="showTooltip"
        bottom
        color="primary"
    >
        <template v-slot:activator="{ attrs }">
            <div
                v-bind="attrs"
                :class="[
                    'staff-group-event-container',
                    {'pulse-animation-start': isNearWeekStart},
                    {'pulse-animation-end': isNearWeekEnd},
                    {'pulse-animation': hoverOpacity}
                ]"
                :style="containerStyle"
                @mouseenter="onMouseEnter"
                @mouseleave="onMouseLeave"
                @click="onClick"
            >
                <template v-if="isEventLoading">
                    <div v-if="eventWidth >= 40" class="staff-group-event-loading-container">
                        <v-progress-circular
                            indeterminate
                            :size="22"
                        ></v-progress-circular>
                    </div>
                </template>
                <template v-else>
                    <template v-if="isEdgeEvent">
                        <edge-event v-if="eventWidth >= 32" :event="event"></edge-event>
                    </template>
                    <template v-else>
                        <text-event v-if="eventWidth >= 55" :event="event"></text-event>
                        <icon-event v-else-if="eventWidth >= 30"></icon-event>
                    </template>
                </template>
                <div v-if="showPendingCancellation" class="staff-group-event-icon-container">
                    <v-icon color="error" small style="font-size: 18px;">
                        mdi-alert-circle
                    </v-icon>
                </div>
            </div>
        </template>
        <div class="staff-group-event-tooltip-container">
            <div class="staff-group-event-tooltip-times">
                <span>
                    {{ startTime }}
                </span>
                <span>
                    &nbsp;-&nbsp;
                </span>
                <span>
                    {{ endTime }}
                </span>
            </div>
            <div class="staff-group-event-tooltip-ratio">
                <v-icon small color="white">mdi-account</v-icon>
                <span>
                    {{ filledCount }}/{{ totalCount }}
                </span>
            </div>
        </div>
    </v-tooltip>
</template>

<script>
import TextEvent from '../Common/Events/TextEvent'
import IconEvent from '../Common/Events/IconEvent'
import EdgeEvent from '../Common/Events/EdgeEvent'

export default {
    name: "Event2",
    components: { TextEvent, IconEvent, EdgeEvent },
    props: {
        event: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        showTooltip: false,
        innerWidth: window.innerWidth,
        lightApricot: { r: 250, g: 231, b: 194 },
        apricot: { r: 242, g: 194, b: 102 },
        salad: { r: 107, g: 201, b: 110 },
        midnight: { r:27, g:20, b:100 },
        isEventLoading: false,
        hoverOpacity: 0,
    }),
    computed: {
        eventLoading: {
            get () {
                return this.$store.getters.schedulerGetStaffGroupEventLoading
            },
            set (eventLoading) {
                this.$store.dispatch('schedulerSetStaffGroupEventLoading', { eventLoading })
            }
        },
        globalTimezone () {
            return this.$root.globalTimezone
        },
        containerStyle () {
            return {
                left: `${this.eventSize.left}%`,
                width: `${this.eventSize.widthPercentage}%`,
                border: this.border,
                backgroundColor: this.backgroundColor,
            }
        },
        border () {
            if (this.isInactive) {
                return `1.1px dashed ${this.toRgb(this.midnight)}`
            } else if (this.isActive) {
                return `1.1px solid ${this.toRgb(this.apricot)}`
            } else if (this.isFull) {
                return `1.1px solid ${this.toRgb(this.salad)}`
            } else {
                return `1.1px solid ${this.toRgb(this.apricot)}`
            }
        },
        backgroundColor () {
            const opacity = this.hoverOpacity;

            if (this.isInactive) {
                return this.toRgba(this.lightApricot, opacity || 0.7)
            } else if (this.isActive) {
                return this.toRgba(this.apricot, opacity || 0.7)
            } else if (this.isFull) {
                return this.toRgba(this.salad, opacity || 0.7)
            } else {
                return this.toRgba(this.apricot, opacity || 0.7)
            }
        },
        isInactive () {
            return this.event.filled_shifts_count === 0 && !this.event.has_pending_offers
        },
        isActive () {
            return this.event.filled_shifts_count < this.event.total_shifts_count
        },
        isFull () {
            return this.event.filled_shifts_count === this.event.total_shifts_count
        },
        eventSize () {
            const end = 10079
            const { startMinutes, endMinutes } = this.event.eventDetails
            const left = startMinutes/end * 100
            const width = (endMinutes - startMinutes)/end
            return {
                left,
                width,
                widthPercentage: width * 100
            }
        },
        totalWidth () {
            return Math.ceil(0.85 * (this.innerWidth - 164))
        },
        availableWidth () {
            return this.totalWidth > 1200 ? this.totalWidth : 1200
        },
        eventWidth () {
            const { startMinutes, endMinutes } = this.event.eventDetails
            return Math.ceil((endMinutes - startMinutes) / 10079 * this.availableWidth)
        },
        isEdgeEvent () {
            return this.event.eventDetails.type !== 'normal'
        },
        startTime () {
            return moment(this.event.start).tz(this.globalTimezone).format('h:mm A')
        },
        endTime () {
            return moment(this.event.end).tz(this.globalTimezone).format('h:mm A')
        },
        filledCount () {
            return this.event.filled_shifts_count
        },
        totalCount () {
            return this.event.total_shifts_count
        },
        showPendingCancellation () {
            return Boolean(this.event.has_pending_cancellations)
        },
        isNearWeekStart() {
            return this.event.eventDetails.endMinutes <= 240;
        },
        isNearWeekEnd() {
            return (10079 - this.event.eventDetails.startMinutes) <= 240;
        }
    },
    methods: {
        onResize () {
            this.innerWidth = window.innerWidth
        },
        toRgb (color) {
            return `rgb(${color.r}, ${color.g}, ${color.b})`
        },
        toRgba (color, opacity) {
            return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`
        },
        toLinearGradient (color) {
            return `linear-gradient(${color}, ${color}), linear-gradient(${color}, ${color})`
        },
        onMouseEnter () {
            this.hoverOpacity = 1;
            if (!this.isEventLoading && (this.isEdgeEvent || this.eventWidth < 55)) {
                this.showTooltip = true;
            }
        },
        onMouseLeave () {
            this.hoverOpacity = 0;
            if (this.showTooltip) {
                this.showTooltip = false;
            }
        },
        emitClickEvent () {
            // TODO: Update event
            window.EventBus.$emit('staffGroupScheduler/onClickEvent', this.event)
        },
        onClick () {
            if (!this.isEventLoading && !this.eventLoading) {
                this.isEventLoading = true
                this.eventLoading = true
                this.emitClickEvent()
            }
        },
        listenOnClose () {
            // TODO: Update event
            window.EventBus.$on('staffGroupScheduler/onCloseEvent', () => {
                this.isEventLoading = false
                this.eventLoading = false
            })
        }
    },
    created () {
        this.listenOnClose()
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.onResize)
    },
}

</script>

<style scoped>
    .staff-group-event-container {
        display: inline-block;
        position: absolute;
        padding: 4px 5px;
        height: 55px;
        cursor: pointer;
    }

    .staff-group-event-loading-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .staff-group-event-tooltip-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .staff-group-event-tooltip-times {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
    }

    .staff-group-event-tooltip-ratio {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 3px;
        font-size: 12px;
    }

    .staff-group-event-icon-container {
        position: absolute;
        top: -10px;
        right: -5px;
        background-color: white;
        border-radius: 9px;
    }

    @keyframes pulseGrowRight {
        0%, 100% {
            transform: scale(1) translateX(0);
        }
        50% {
            transform: scale(1.2) translateX(30%);
        }
    }

    @keyframes pulseGrowLeft {
        0%, 100% {
            transform: scale(1) translateX(0);
        }
        50% {
            transform: scale(1.2) translateX(-30%);
        }
    }

    @keyframes pulseGrow {
        0%, 100% {
            transform: scale(1) translateX(0);
        }
        50% {
            transform: scale(1.1) translateY(5%);
        }
    }

    .staff-group-event-container:hover.pulse-animation {
        animation: pulseGrow 1.5s infinite;
    }

    .staff-group-event-container:hover.pulse-animation-start {
        animation: pulseGrowRight 1.5s infinite;
    }

    .staff-group-event-container:hover.pulse-animation-end {
        animation: pulseGrowLeft 1.5s infinite;
    }
</style>
