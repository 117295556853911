const toast_container = document.getElementById('koroid-toast-container')

export default function message(type, message) {
    const toast_element = document.createElement('div')
    toast_element.classList.add('toast-flex-row')
    toast_element.classList.add('toast-element')
    toast_element.classList.add('toast--show')

    switch (type) {
        case "success":
            toast_element.classList.add('toast--success')
            break;
        case "warning":
            toast_element.classList.add('toast--warning')
            break;
        case "error":
            toast_element.classList.add('toast--error')
            break;
        case "info":
            toast_element.classList.add('toast--info')
    }

    const message_element = document.createElement('div')
    message_element.classList.add('toast-flex-column')
    message_element.classList.add('toast-message')
    toast_element.append(message_element)

    message_element.innerText = message

    addCloseButton(toast_element)

    toast_container.prepend(toast_element)

    addTimeout(toast_element, type === 'success' ? 3000 : 4500)
}

function addCloseButton(toast_element) {
    const close_button_element = document.createElement('div')
    close_button_element.classList.add('toast-flex-column')
    close_button_element.classList.add('toast-close-btn')
    close_button_element.innerText = 'Close'
    toast_element.append(close_button_element)

    close_button_element.addEventListener("click", function(){
        toast_element.remove()
    });
}

function addTimeout(toast_element, ms) {
    setTimeout(() => {
        toast_element.remove()
    }, ms);
}
