<template>
    <v-card>
        <v-card-text class="bulk-upload-users-container">
            <v-stepper v-model="step" class="bulk-upload-users-wrapper" flat>
                <v-stepper-header class="bulk-upload-users-steps">
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                        Select User Role
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                    >
                        Upload File
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="step > 3"
                        step="3"
                    >
                        Match Fields
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="step > 4"
                        step="4"
                    >
                        Review Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="step > 5"
                        step="5"
                    >
                        Complete
                    </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items class="bulk-upload-users-content">
                    <bulk-upload-users-info
                        v-show="step === 0" />
                    <bulk-upload-users-select-role
                        v-show="step === 1"
                        v-model="selectedRole"
                        :email-domains="emailDomains"
                        :roles="roles" />
                    <bulk-upload-users-upload-file
                        v-show="step === 2"
                        v-model="selectedFile"
                        :error="fileError"
                        :error-messages="fileErrorMessages"
                        :email-domains="emailDomains" />
                    <bulk-upload-users-match-fields
                        v-show="step === 3"
                        v-model="matchFields"
                        :headers="headers"
                        :errors="matchFieldsErrors"
                        :email-domains="emailDomains" />
                    <bulk-upload-users-table
                        v-if="step === 4"
                        v-model="validatedUsers"
                        :locations="locations"
                        @onChangeFirstName="syncFirstName"
                        @onChangeLastName="syncLastName"
                        @onChangeUsername="syncUsername"
                        @onChangeEmail="syncEmail"
                        @onApplyAll="onApplyAllLocations"
                        @onSelectAll="onSelectAllUsers"
                    />
                    <bulk-upload-users-complete
                        v-show="step === 5"
                        :success-count="successCount"
                        :error-count="errorCount"
                        @onGoBack="onGoBackToUsersPage"
                    />
                </v-stepper-items>
                <div class="bulk-upload-users-actions">
                    <div>
                        <v-btn
                            v-if="step < 5"
                            color="primary"
                            outlined
                            :disabled="loading"
                            @click="onExit"
                        >
                            Exit
                        </v-btn>
                    </div>
                    <div class="bulk-upload-users-actions-stacked-buttons" v-if="step > 0">
                        <v-btn
                            v-if="step < 5"
                            color="primary"
                            text
                            @click="goPreviousStep"
                            :disabled="loading"
                        >
                            Previous
                        </v-btn>
                        <v-btn
                            v-if="step < 5"
                            color="secondary"
                            @click="onNext"
                            :disabled="nextDisabled"
                            :loading="loading"
                        >
                            Next
                        </v-btn>
                    </div>
                    <v-btn
                        v-else
                        color="primary"
                        @click="onStart"
                        :disabled="loading"
                        :loading="loading"
                    >Start</v-btn>
                </div>
            </v-stepper>
        </v-card-text>
    </v-card>
</template>

<script>
import BulkUploadUsersInfo from './BulkUploadUsersInfo'
import BulkUploadUsersSelectRole from './BulkUploadUsersSelectRole'
import BulkUploadUsersUploadFile from './BulkUploadUsersUploadFile'
import BulkUploadUsersMatchFields from './BulkUploadUsersMatchFields'
import BulkUploadUsersTable from './BulkUploadUsersTable'
import BulkUploadUsersComplete from './BulkUploadUsersComplete'

export default {
    name: "BulkUploadUsers",
    components: { BulkUploadUsersInfo, BulkUploadUsersSelectRole, BulkUploadUsersUploadFile, BulkUploadUsersMatchFields, BulkUploadUsersTable, BulkUploadUsersComplete },
    data: () => ({
        step: 0,
        isOrganizationDataFetched: false,
        emailDomains: [],
        roles: [],
        locations: [],
        selectedFile: null,
        fileError: false,
        fileErrorMessages: [],
        apiBusy: false,
        headers: [],
        selectedRole: null,
        matchFields: {
            firstName: null,
            lastName: null,
            username: null,
            email: null,
        },
        matchFieldsErrors: {
            firstName: false,
            lastName: false,
            username: false,
            email: false,
        },
        validatedUsers: [],
        successCount: 0,
        errorCount: 0,
        api: new formHelper()
    }),
    computed: {
        loading () {
            return this.api.busy || this.apiBusy
        },
        nextDisabled () {
            if (this.loading) {
                return true
            }
            if (this.step === 1) {
                return !this.selectedRole
            }
            else if (this.step === 2) {
                if (this.selectedFile) {
                    return this.selectedFile.type !== 'text/csv' && !this.selectedFile.name.endsWith('.csv')
                }
                return true
            } else if (this.step === 3) {
                const { firstName, lastName, username, email } = this.matchFields
                if (!firstName || !lastName || !username || !email) {
                    return true
                }
                return Object.values(this.matchFieldsErrors).some(value => value)
            } else if (this.step === 4) {
                return this.validatedCheckedUsers.some(user => {
                    return !user.firstName.valid ||
                        !user.lastName.valid ||
                        !user.username.valid ||
                        !user.email.valid ||
                        (!user.locations || (Array.isArray(user.locations) && user.locations.length === 0))
                })
            } else {
                return false
            }
        },
        matchFieldsMap () {
            return {
                'first_name': this.matchFields.firstName,
                'last_name': this.matchFields.lastName,
                'username': this.matchFields.username,
                'email': this.matchFields.email
            }
        },
        emailAddressRegex() {
            const domainPattern = this.emailDomains
                .map(domain => domain.replace('.', '\\.'))
                .join('|')

            return new RegExp('^[\\w+\\-.]+(' + domainPattern + ')$')
        },
        validatedCheckedUsers() {
            return this.validatedUsers.filter(user => user.checked)
        }
    },
    methods: {
        goPreviousStep () {
            this.step = this.step - 1
        },
        goNextStep () {
            this.step = this.step + 1
        },
        onStart () {
            if (!this.isOrganizationDataFetched) {
                this.api.get('/user-import/organization-data').then(data => {
                    const emailDomains = data?.data?.email_domains
                    const roles = data?.data?.selectable_roles
                    const locations = data?.data?.selectable_locations
                    this.emailDomains = Array.isArray(emailDomains) ? emailDomains : []
                    this.roles = Array.isArray(roles) ? roles : []
                    this.locations = Array.isArray(locations) ? locations : []
                }).finally(() => {
                    this.isOrganizationDataFetched = true
                    this.goNextStep()
                })
            } else {
                this.goNextStep()
            }
        },
        onNext () {
            if (this.step === 2) {
                this.validateFile()
            } else if (this.step === 3) {
                this.mapAndValidateFields()
            } else if (this.step === 4) {
                this.bulkUploadUsers()
            } else {
                this.goNextStep()
            }
        },
        validateFile () {
            const formData = new FormData()
            formData.append('import_file', this.selectedFile)

            this.apiBusy = true

            axios.post('/user-import/validate', formData)
                .then(data => {
                    this.apiBusy = false
                    const headers = data.data.headers
                    this.headers = Array.isArray(headers) ? headers : []
                    this.matchFields = {
                        firstName: null,
                        lastName: null,
                        username: null,
                        email: null,
                    }
                    this.matchFieldsErrors = {
                        firstName: null,
                        lastName: null,
                        username: null,
                        email: null,
                    }
                    this.goNextStep()
                })
                .catch(error => {
                    this.fileError = true
                    const errors = error?.response?.data?.errors
                    const errorMessages = errors?.header || errors?.row_count || errors?.import_file
                    this.fileErrorMessages = Array.isArray(errorMessages) && errorMessages.length === 1 ? errorMessages : [
                        'Please review the required fields in the upload instructions.'
                    ]
                    this.apiBusy = false
                })
        },
        mapAndValidateFields () {
            const formData = new FormData()
            formData.append('import_file', this.selectedFile)
            formData.append('ordered_columns', JSON.stringify(this.matchFieldsMap))

            this.apiBusy = true

            axios.post('/user-import/map-validate-columns', formData)
                .then(data => {
                    this.initUsers(data)
                    this.goNextStep()
                })
                .catch(console.log)
                .finally(() => {
                    this.apiBusy = false
                })
        },
        bulkUploadUsers() {
            this.api.post('/user-import/finalize', {
                user_data: this.validatedUsers.map(user => ({
                    checked: user.checked,
                    first_name: user.firstName.value,
                    last_name: user.lastName.value,
                    username: user.username.value,
                    email: user.email.value,
                    locations: user.locations,
                })),
            }).then(data => {
                const hasErrors = data?.data?.has_errors
                if (hasErrors) {
                    this.initUsers(data)
                } else {
                    const successCount = data?.data?.data?.uploaded || 0
                    this.successCount = successCount
                    this.errorCount = this.validatedUsers.length - successCount
                    this.goNextStep()
                    this.$emit('onBulkUpload')
                }
            }).catch(console.log)
        },
        parseFieldValue(field) {
            const messages = field.messages
            return {
                value: field.value,
                valid: field.valid,
                errorMessage: Array.isArray(messages) && messages.length > 0 ? messages[0] : null,
                duplicate: false,
            }
        },
        initUsers(data) {
            const users = data?.data?.data
            if (Array.isArray(users)) {
                this.validatedUsers = users.map(user => ({
                    firstName: this.parseFieldValue(user.first_name),
                    lastName: this.parseFieldValue(user.last_name),
                    username: this.parseFieldValue(user.username),
                    email: this.parseFieldValue(user.email),
                    locations: Array.isArray(user.locations?.value) ? Array.from(user.locations.value) : [],
                    checked: user.checked,
                }))
            }
        },
        syncFirstName(value, index) {
            this.setValidatedUsers(
                this.getNameErrorMessage(value),
                index,
                'firstName'
            )
        },
        syncLastName(value, index) {
            this.setValidatedUsers(
                this.getNameErrorMessage(value),
                index,
                'lastName'
            )
        },
        syncUsername(value, index) {
            this.setValidatedUsers(
                this.getUsernameErrorMessage(value),
                index,
                'username'
            )
            this.validatedUsers = this.validateDuplicateValues(
                this.validatedUsers,
                'username',
                'There is another user with this username in this list',
            )
        },
        syncEmail(value, index) {
            this.setValidatedUsers(
                this.getEmailAddressErrorMessage(value),
                index,
                'email'
            )
            this.validatedUsers = this.validateDuplicateValues(
                this.validatedUsers,
                'email',
                'There is another user with this email in this list',
            )
        },
        getNameErrorMessage(v) {
            if (!!v && !!v.trim()) {
                if (/^[a-zA-Z]+(?:[-\'\s]?[a-zA-Z]+)*$/.test(v)) {
                    return null
                }
                return 'This field can only contain text'
            }
            return 'This field can not be empty'
        },
        getUsernameErrorMessage(v) {
            if (!!v && !!v.trim()) {
                return null
            }
            return 'This field can not be empty'
        },
        getEmailAddressErrorMessage(v) {
            if (!!v && !!v.trim()) {
                if (/^[\w-\+\.]+@([\w-]+\.)+[\w-]+$/.test(v)) {
                    if (this.emailAddressRegex.test(v)) {
                        return null
                    }
                    return 'Email addresses must use an approved domain ending'
                }
                return 'This is not a valid email address'
            }
            return 'This field can not be empty'
        },
        setValidatedUsers(errorMessage, index, key) {
            if (errorMessage) {
                this.validatedUsers = this.setValidatedUsersValue(
                    this.validatedUsers,
                    index,
                    key,
                    false,
                    errorMessage,
                )
            } else {
                const isValid = this.validatedUsers[index][key].valid
                if (!isValid) {
                    this.validatedUsers = this.setValidatedUsersValue(
                        this.validatedUsers,
                        index,
                        key,
                        true,
                        null,
                    )
                }
            }
        },
        setValidatedUsersValue(validatedUsers, index, key, valid, errorMessage) {
            const users = Array.from(validatedUsers)
            if (index < users.length) {
                users[index] = {
                    ...users[index],
                    [key]: {
                        ...users[index][key],
                        valid,
                        errorMessage,
                        duplicate: false,
                    }
                }
            }
            return users
        },
        validateDuplicateValues(validatedUsers, key, errorMessage) {
            const valueCountMap = {}
            validatedUsers.forEach(user => {
                const { value } = user[key]
                valueCountMap[value] = (valueCountMap[value] || 0) + 1
            })

            return validatedUsers.map(user => {
                let valid = valueCountMap[user[key].value] === 1

                // if empty string, don't trigger duplicate error
                if (!user[key].value) {
                    valid = true
                }

                return {
                    ...user,
                    [key]: {
                        ...user[key],
                        valid: valid ? !user[key].valid && !user[key].duplicate ? user[key].valid : true : false,
                        errorMessage: valid ? !user[key].valid && !user[key].duplicate ? key === 'email' ? this.getEmailAddressErrorMessage(user[key].value) : this.getUsernameErrorMessage(user[key].value) : null : errorMessage,
                        duplicate: valid ? false : user[key].valid ? true : user[key].duplicate,
                    }
                }
            })
        },
        onApplyAllLocations(locations) {
            this.validatedUsers = this.validatedUsers.map(user => ({
                ...user,
                locations,
            }))
        },
        onSelectAllUsers(checked) {
            this.validatedUsers = this.validatedUsers.map(user => ({
                ...user,
                checked,
            }))
        },
        onExit() {
            this.$emit('onExit')
        },
        onGoBackToUsersPage() {
            this.$emit('onExit')
        }
    },
    watch: {
        selectedFile (file) {
            if (file) {
                if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
                    this.fileError = true
                    this.fileErrorMessages = ['This is not a valid file type. Please select another file.']
                } else {
                    this.fileError = false
                    this.fileErrorMessages = []
                }
            } else {
                this.fileError = true
                this.fileErrorMessages = ['Please upload a file.']
            }
        },
        matchFields (matchFields) {
            this.matchFieldsErrors = {
                firstName: false,
                lastName: false,
                username: false,
                email: false,
            }
            const keys = Object.keys(matchFields)
            for (let i=0;i < keys.length - 1; ++i) {
                for (let j=i+1;j < keys.length; ++j) {
                    const firstKey = keys[i]
                    const secondKey = keys[j]

                    const firstValue = matchFields[firstKey]
                    const secondValue = matchFields[secondKey]

                    if (firstValue && secondValue && firstValue === secondValue) {
                        this.matchFieldsErrors = {
                            ...this.matchFieldsErrors,
                            [firstKey]: true,
                            [secondKey]: true,
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

    .bulk-upload-users-container {
        padding: 0;
        height: calc(100vh - 160px);
    }

    .bulk-upload-users-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .bulk-upload-users-steps {
        flex: 0 0 auto;
    }

    .bulk-upload-users-content {
        flex: 1 0 0;
        padding-top: 2px;
    }

    .bulk-upload-users-actions {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
    }

    .bulk-upload-users-actions-stacked-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
    }

    .bulk-upload-users-btn {
        text-transform: none;
    }
</style>
