module.exports = {
    methods: {
        getUpdatedDuration(start_time, end_time) {
            if (!start_time || !end_time) {
                return
            }

            let start = moment(moment().format('YYYY-MM-DD') + ' ' + start_time)
            let end = moment(moment().format('YYYY-MM-DD') + ' ' + end_time)

            if ((start_time === end_time) || end.isBefore(start)) {
                end.add(1, 'd')
            }

            return end.diff(start, 'm')
        },
        getUpdatedEndTime(start_time, duration) {
            if (!start_time || !(duration || duration === 0)) {
                return null
            }

            return moment(moment().format('YYYY-MM-DD') + ' ' + start_time).add(duration, 'm').format('HH:mm')
        },
    }
}
