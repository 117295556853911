module.exports = {
    computed: {
        title () {
            const { user } = this
            if(user.first_name && user.last_name) {
                return `${user.first_name} ${user.last_name}`
            }
            return ''
        },
        subtitle () {
            let subtitle = `${this.formatMinutesInUserDetails(this.hours)}/${this.formatMinutesInUserDetails(this.maxHours)} for ${this.formatDateInUserDetails(this.payrollPeriodStart, this.payrollPeriodTimezone)} - ${this.formatDateInUserDetails(this.payrollPeriodEnd, this.payrollPeriodTimezone)}`
            if (this.globalLocationInUserDetails && this.globalLocationInUserDetails.timezone !== this.payrollPeriodTimezone) {
                const timezone = moment().tz(this.payrollPeriodTimezone).format('z')
                subtitle = `${subtitle} (${timezone})`
            }
            return subtitle
        },
        payrollPeriodStart() {
            const { user } = this

            if (!user.staff_type || typeof user.staff_type === 'undefined') {
                return null
            }

            return user.staff_type.payroll_period_start
        },
        payrollPeriodEnd() {
            const { user } = this

            if (!user.staff_type || typeof user.staff_type === 'undefined') {
                return null
            }

            return user.staff_type.payroll_period_end
        },
        payrollPeriodTimezone() {
            const { user } = this

            if (!user.staff_type || typeof user.staff_type === 'undefined') {
                return null
            }

            return user.staff_type.payroll_period_timezone
        },
        hours () {
            const { user } = this
            if(user && user.total_shift_duration_in_payroll_period) {
                return user.total_shift_duration_in_payroll_period
            }
            return 0
        },
        maxHours () {
            const { user } = this

            if (!user.staff_type || typeof user.staff_type === 'undefined') {
                return 0
            }

            return user.staff_type.max_work_hours ?? 0
        },
        available () {
            if (this.user) {
                return this.user.available
            }
            return false
        },
        eligible () {
            if (this.user) {
                return this.user.eligible
            }
            return false
        },
        assigned () {
            if (this.user) {
                return this.user.assigned
            }
            return false
        },
        directAssign () {
            if (this.user) {
                return this.user.direct_assign
            }
            return false
        },
        offered () {
            if (this.user) {
                return this.user.offered
            }
            return false
        },
        invalidShiftOffer() {
            if (this.user) {
                return !!this.user.last_invalid_shift_offer_reason
            }
            return false
        },
        rejected () {
            if (this.user) {
                return this.user.rejected
            }
            return false
        },
        requestedCancellation () {
            if (this.user) {
                return this.user.cancellation_requested
            }
            return false
        },
        noShow () {
            if (this.user) {
                return this.user.no_show
            }
            return false
        },
        callOut () {
            if (this.user) {
                return this.user.call_out
            }
            return false
        },
        timeUpdated () {
            if (this.user) {
                return this.user.time_updated
            }
            return false
        },
        overtime () {
            return this.hours > this.maxHours
        },
        toBeOvertime () {
            if (this.user) {
                return this.user.toBeOvertime
            }
            return false
        },
        shiftId () {
            if (this.user) {
                return this.user.shift_id
            }
            return null
        },
        globalLocationInUserDetails() {
            return this.$root.globalLocation
        }
    },
    methods: {
        formatMinutesInUserDetails(minutes) {
            const hours = Math.floor(minutes / 60)
            const remainingMinutes = minutes % 60
            if (remainingMinutes > 0) {
                return `${hours}h${remainingMinutes}m`
            }
            return `${hours}h`
        },
        formatDateInUserDetails(date, tz) {
            if (tz) {
                return moment(date).tz(tz).format('MMM D')
            }
            return moment(date).format('MMM D')
        }
    }
}
