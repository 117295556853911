<template>
    <v-card class="location-overview-card ds-elevation-2">
        <div class="location-details-section">
            <div class="location-details">
                <h3 class="location-name">{{ location.name }}</h3>
                <div>{{ location.primary_address ?? "&nbsp;" }}</div>
            </div>
            <div class="button-container">
                <v-btn
                    color="secondary"
                    :disabled="!hasViewLocationsPermissions"
                    @click="onViewLocationDetails"
                >
                    View
                </v-btn>
            </div>
        </div>
        <hr class="separator">
        <div class="location-counts-section">
            <div class="count-section">
                <h4 class="count-heading">Departments</h4>
                <h3 v-if="location.departments_count" class="ds-h3 count-number">{{ location.departments_count }}</h3>
                <div v-else class="count-number">No Departments in this Location</div>
            </div>
            <div class="count-section">
                <h4 class="count-heading">Staff Groups</h4>
                <h3 v-if="location.staff_groups_count" class="ds-h3 count-number">{{ location.staff_groups_count }}</h3>
                <div v-else class="count-number">No Staff Groups in this Location</div>
            </div>
        </div>

    </v-card>
</template>

<script>
export default {
    name: "LocationOverviewCard",
    props: {
        location: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        hasViewLocationsPermissions () {
            return this.$userHasPermission(this.$user, this.$config.permissions.LOCATIONS.VIEW)
        }
    },
    methods: {
        onViewLocationDetails() {
            if (!this.hasViewLocationsPermissions) {
                return
            }

            this.$emit('onViewLocationDetails', this.location)
        }
    }
}
</script>

<style scoped>
    .location-overview-card {
        padding: 16px 16px 24px 16px;
        border-radius: 12px;
        gap: 16px;
    }

    .location-details-section {
        display: flex;
        justify-content: space-between;
    }

    .location-details {
        max-width: 75%;
    }

    .location-name {
        margin-bottom: 16px;
    }

    .button-container {
        align-self: center;
    }

    .separator {
        margin: 16px auto;
        border-color: #0D0A32;
    }

    .location-counts-section {
        display: flex;
        justify-content: space-around;
        height: 90px;
    }

    .count-section {
        width: 50%;
        display: grid;
    }

    .count-heading {
        text-align: center;
        margin-bottom: 16px;
    }

    .count-number {
        text-align: center;
        justify-self: center;
        width: 50%;
    }

    @media only screen and (max-width: 1750px) {
        .count-number {
            width: 100%;
        }
    }
</style>
