<template>
    <v-card>
        <automated-shift-details-toolbar
            :staffing-automation="staffingAutomation"
            :loading="isStaffingAutomationLoading"
            :is-window="editMode"
            @onClickBack="onClickBackToShifts"
        />
        <v-card-text :style="contentStyle">
            <div v-if="isStaffingAutomationLoading" class="mt-5">
                <v-skeleton-loader
                    v-for="i in 6"
                    :key="i"
                    type="sentences"
                    width="100%"
                    class="pb-5"
                ></v-skeleton-loader>
            </div>
            <template v-else>
                <automated-shift-details-overview
                    :edit-mode="editMode"
                    :staffing-automation="staffingAutomation"
                    @onUpdateStaffingAutomation="onUpdateStaffingAutomation"
                />
                <div style="border-bottom: 1px solid lightgray; padding: 0 12px;"></div>
                <add-job-title
                    v-for="(schedule, index) in staffingAutomationsSchedules"
                    :key="index"
                    :show-details="index < 1"
                    :job-titles="availableJobTitles"
                    :job-titles-loading="jobTitlesLoading"
                    :credentials="credentials"
                    :credentials-loading="credentialsLoading"
                    :staffing-automation-schedule-index="index"
                    :selected-job-title-id="schedule.jobTitleId"
                    :selected-credentials-ids="schedule.credentialsIds"
                    :selected-schedule="schedule.dailySchedule"
                    :is-dirty="schedule.isDirty"
                    :is-saving="schedule.isSaving"
                    :is-deleting="schedule.isDeleting"
                    :can-edit-staffing-automation="canEditStaffingAutomation"
                    :managed-by-auth-user="schedule.isManagedByAuthUser"
                    @onChangeJobTitle="onChangeJobTitle"
                    @onChangeCredentials="onChangeCredentials"
                    @onChangeDay="onChangeDay"
                    @onSave="onSaveStaffingAutomationSchedule"
                    @onDelete="onDeleteStaffingAutomationSchedule"
                ></add-job-title>
                <v-row :class="{'mt-5': staffingAutomationsSchedules.length < 1}">
                    <v-col cols="12" class="pt-0">
                        <v-btn
                            color="primary"
                            text
                            @click="onAddJobTitle"
                            :disabled="!canEditStaffingAutomation"
                        >
                            Add Job Title
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
        <v-card-actions>
            <template v-if="!editMode">
                <v-spacer></v-spacer>
                <div style="padding-bottom: 8px; padding-left: 4px;">
                    <v-btn
                        color="primary"
                        text
                        :disabled="isActivating"
                        @click="onClose"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="secondary"
                        class="ml-2"
                        :loading="isActivating"
                        :disabled="isStaffingAutomationLoading || isStaffingAutomationDetailsDirty"
                        @click="onActivate"
                    >
                        Activate
                    </v-btn>
                </div>
            </template>
            <template v-else>
                <div class="d-flex flex-row align-center" v-if="!isStaffingAutomationLoading">
                    <div class="d-flex flex-row">
                        <v-switch
                            v-model="staffingAutomation.enabled"
                            class="ml-4"
                            color="primary"
                            inset
                            :loading="isDeactivating || isLoadingActivationDetails"
                            :disabled="isDeactivating || isLoadingActivationDetails"
                            :readonly="!canEditStaffingAutomation"
                            @change="onChangeStatus"
                        ></v-switch>
                        <div class="text--paragraph" style="margin-top: 22px;">
                            Status: <b>{{ activeStatus }}</b>
                        </div>
                    </div>
                    <v-btn
                        v-if="!isStaffingAutomationLoading && !staffingAutomation?.enabled"
                        color="error"
                        outlined
                        class="ml-2"
                        @click="deleteStaffingAutomationModalValue = true"
                    >Delete Automated Shift
                    </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div style="border: 1px solid lightgrey; border-radius: 15px; padding: 15px;">
                    <p class="text--paragraph mb-0 text-center">
                        Changes to an Automated Shift will not take effect until the next run.
                    </p>
                    <p class="text--paragraph mb-0 text-center">
                        Automated Shifts currently on the Schedule will not be effected.
                    </p>
                </div>
            </template>
        </v-card-actions>
        <activation-modal
            v-if="activationModalValue"
            v-model="activationModalValue"
            :staffing-automation="staffingAutomation"
            :min-start-date="activationModalMinStartDate"
            :day-of-week="activationModalDayOfWeek"
            @onCancel="onCancelActivate"
            @onActivate="onConfirmActivate"
        />
        <remove-job-title-modal
            v-model="removeJobTitleModalValue"
            @onDelete="deleteStaffingAutomationSchedule"
        />
        <delete-staffing-automation
            v-if="deleteStaffingAutomationModalValue"
            v-model="deleteStaffingAutomationModalValue"
            :staffing-automation-id="staffingAutomationId"
            @onDelete="onDeleteStaffingAutomation"
        />
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-card>
</template>

<script>
import AddJobTitle from "./AddJobTitle";
import TimeInput from "../../../components/common/TimeInput/TimeInput";
import AutomatedShiftDetailsToolbar from "../AutomatedShiftDetails/AutomatedShiftDetailsToolbar";
import AutomatedShiftDetailsOverview from "../AutomatedShiftDetails/AutomatedShiftDetailsOverview";
import RemoveJobTitleModal from "../RemoveJobTitleModal";
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";
import ActivationModal from "../ActivationModal";
import DeleteStaffingAutomation from "../DeleteStaffingAutomation";

export default {
    name: "StaffingAutomationDetails",
    components: {
        AddJobTitle,
        TimeInput,
        AutomatedShiftDetailsToolbar,
        AutomatedShiftDetailsOverview,
        RemoveJobTitleModal,
        UnsavedChangesDialog,
        ActivationModal,
        DeleteStaffingAutomation
    },
    mixins: [],
    props: {
        editMode: {
            type: Boolean,
            default: true,
        },
        staffingAutomationId: {
            type: String,
            default: null,
        },
        startDate: {
            type: String,
            default: null
        },
        runDate: {
            type: String,
            default: null,
        },
        canEditStaffingAutomation: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        modalValue: true,
        staffingAutomation: {},
        isStaffingAutomationLoading: false,
        jobTitles: [],
        jobTitlesLoading: false,
        credentials: [],
        credentialsLoading: false,
        staffingAutomationsSchedules: [],
        prevStaffingAutomationsSchedules: [],
        removeJobTitleModalValue: false,
        staffingAutomationRequestToBeDeletedId: null,
        staffingAutomationRequestToBeDeletedIndex: null,
        isActivating: false,
        isDeactivating: false,
        isLoadingActivationDetails: false,
        activationModalValue: false,
        activationModalMinStartDate: null,
        activationModalDayOfWeek: null,
        deleteStaffingAutomationModalValue: false,
        api: new formHelper()
    }),
    computed: {
        innerHeight() {
            return window.innerHeight
        },
        contentStyle() {
            return {
                ...(!this.editMode && {height: `${this.innerHeight}px`}),
                ...(this.editMode && {'min-height': `${this.innerHeight - 280}px`})
            }
        },
        activeStatus() {
            const enabled = this.staffingAutomation?.enabled
            return enabled ? 'Active' : 'Inactive'
        },
        selectedJobTitleIdsMap() {
            if (Array.isArray(this.staffingAutomationsSchedules)) {
                return this.staffingAutomationsSchedules.reduce((jobTitles, schedule) => ({
                    ...jobTitles,
                    [schedule.jobTitleId]: true
                }), {})
            }
            return {}
        },
        availableJobTitles() {
            return this.jobTitles.map(jobTitle => {
                jobTitle.over_max_hours = jobTitle.max_shift_duration === 'undefined' || !jobTitle.max_shift_duration || (this.staffingAutomation.duration > jobTitle.max_shift_duration);
                jobTitle.disabled = Boolean(this.selectedJobTitleIdsMap[jobTitle._id]) || jobTitle.over_max_hours || !jobTitle.managed_by_auth_user

                return jobTitle
            })
        },
        isStaffingAutomationDetailsDirty() {
            return this.staffingAutomationsSchedules.some(schedule => schedule.isDirty)
        },
    },
    methods: {
        fetchStaffingAutomation(staffingAutomationId) {
            this.isStaffingAutomationLoading = true
            this.api.get(`/staffing-automation/details/${staffingAutomationId}`).then(data => {
                if (data?.data) {
                    this.staffingAutomation = data.data
                    const {department_id, staff_group_id} = this.staffingAutomation
                    this.fetchJobTitles(department_id, staff_group_id)
                    this.fetchCredentials()
                    if (Array.isArray(this.staffingAutomation.staffing_automation_requests) && this.staffingAutomation.staffing_automation_requests.length > 0) {
                        this.staffingAutomationsSchedules = this.staffingAutomation.staffing_automation_requests
                            .map(staffingAutomationSchedule => ({
                                _id: staffingAutomationSchedule._id,
                                jobTitleId: staffingAutomationSchedule.job_title_id,
                                credentialsIds: staffingAutomationSchedule.requirements.map(r => r._id),
                                dailySchedule: staffingAutomationSchedule.daily_amounts,
                                isSaving: false,
                                isDeleting: false,
                                isDirty: false,
                                isManagedByAuthUser: staffingAutomationSchedule.managed_by_auth_user
                            }))
                        this.initPrevStaffingAutomationsSchedules()
                    } else {
                        if (!this.editMode) {
                            this.staffingAutomationsSchedules = [
                                {
                                    _id: null,
                                    jobTitleId: null,
                                    credentialsIds: [],
                                    dailySchedule: [0, 0, 0, 0, 0, 0, 0],
                                    isSaving: false,
                                    isDeleting: false,
                                    isDirty: false,
                                    isManagedByAuthUser: true
                                },
                            ]
                            this.initPrevStaffingAutomationsSchedules()
                        }
                    }
                }
            }).catch(console.log).finally(() => {
                this.isStaffingAutomationLoading = false
            })
        },
        fetchJobTitles(departmentId, staffGroupId) {
            this.jobTitlesLoading = true

            const params = {
                'department_id': departmentId,
                'staff_group_id': staffGroupId,
                'staffing_automation_id': this.staffingAutomationId
            }

            this.api.get(`/staffing-automation/modal-options-data/job_title`, {params})
                .then(data => {
                    const jobTitles = data?.data
                    if (Array.isArray(jobTitles)) {
                        this.jobTitles = Array.from(jobTitles)
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.jobTitlesLoading = false
                })
        },
        fetchCredentials() {
            this.credentialsLoading = true
            this.api.get(`/staffing-automation/modal-options-data/requirements`)
                .then(data => {
                    const credentials = data?.data
                    if (Array.isArray(credentials)) {
                        this.credentials = Array.from(credentials)
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.credentialsLoading = false
                })
        },
        onChangeJobTitle(staffingAutomationScheduleIndex, jobTitleId) {
            this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                this.staffingAutomationsSchedules,
                staffingAutomationScheduleIndex,
                {
                    jobTitleId,
                    ...(staffingAutomationScheduleIndex < this.prevStaffingAutomationsSchedules.length && {
                        isDirty: !_.isEqual(
                            jobTitleId,
                            this.prevStaffingAutomationsSchedules[staffingAutomationScheduleIndex].jobTitleId
                        )
                    })
                }
            )
        },
        onChangeCredentials(staffingAutomationScheduleIndex, credentialsIds) {
            this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                this.staffingAutomationsSchedules,
                staffingAutomationScheduleIndex,
                {
                    credentialsIds,
                    ...(staffingAutomationScheduleIndex < this.prevStaffingAutomationsSchedules.length && {
                        isDirty: !_.isEqual(
                            credentialsIds,
                            this.prevStaffingAutomationsSchedules[staffingAutomationScheduleIndex].credentialsIds
                        )
                    })
                }
            )
        },
        onChangeDay(staffingAutomationScheduleIndex, dayValue, dayIndex) {
            if (staffingAutomationScheduleIndex < this.staffingAutomationsSchedules.length) {
                const staffingAutomationsSchedules = Array.from(this.staffingAutomationsSchedules)
                const dailySchedule = staffingAutomationsSchedules[staffingAutomationScheduleIndex].dailySchedule
                if (dayIndex < dailySchedule.length) {
                    if (staffingAutomationScheduleIndex < this.prevStaffingAutomationsSchedules.length) {
                        if (dayIndex < this.prevStaffingAutomationsSchedules[staffingAutomationScheduleIndex].dailySchedule.length) {
                            const newDailySchedule = Array.from(staffingAutomationsSchedules[staffingAutomationScheduleIndex].dailySchedule)
                            newDailySchedule[dayIndex] = dayValue
                            staffingAutomationsSchedules[staffingAutomationScheduleIndex] = {
                                ...staffingAutomationsSchedules[staffingAutomationScheduleIndex],
                                isDirty: !_.isEqual(
                                    newDailySchedule,
                                    this.prevStaffingAutomationsSchedules[staffingAutomationScheduleIndex].dailySchedule
                                )
                            }
                        }
                    }
                    staffingAutomationsSchedules[staffingAutomationScheduleIndex].dailySchedule[dayIndex] = dayValue
                    this.staffingAutomationsSchedules = Array.from(staffingAutomationsSchedules)
                }
            }
        },
        onSaveStaffingAutomationSchedule(staffingAutomationScheduleIndex) {
            if (staffingAutomationScheduleIndex < this.staffingAutomationsSchedules.length) {
                const {
                    _id,
                    jobTitleId,
                    credentialsIds,
                    dailySchedule
                } = this.staffingAutomationsSchedules[staffingAutomationScheduleIndex]
                this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                    this.staffingAutomationsSchedules,
                    staffingAutomationScheduleIndex,
                    {isSaving: true}
                )
                this.api.post('/staffing-automation-request/save', {
                    ...(_id && {_id}),
                    staffing_automation_id: this.staffingAutomation._id,
                    job_title_id: jobTitleId,
                    requirement_ids: credentialsIds,
                    daily_amounts: dailySchedule
                }).then(({data, message}) => {
                    message(_id ? 'Staffing Automation Request Changes Updated' : 'Staffing Automation Request Changes Saved')
                    if (data._id) {
                        this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                            this.staffingAutomationsSchedules,
                            staffingAutomationScheduleIndex,
                            {_id: data._id, isDirty: false}
                        )
                        this.prevStaffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                            this.prevStaffingAutomationsSchedules,
                            staffingAutomationScheduleIndex,
                            this.staffingAutomationsSchedules[staffingAutomationScheduleIndex]
                        ).map(staffingAutomation => ({
                            ...staffingAutomation,
                            dailySchedule: staffingAutomation.dailySchedule.map(ds => ds)
                        }))
                    }
                }).catch(console.log).finally(() => {
                    this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                        this.staffingAutomationsSchedules,
                        staffingAutomationScheduleIndex,
                        {isSaving: false}
                    )
                })
            }
        },
        onDeleteStaffingAutomationSchedule(staffingAutomationScheduleIndex) {
            if (staffingAutomationScheduleIndex < this.staffingAutomationsSchedules.length) {
                const {_id} = this.staffingAutomationsSchedules[staffingAutomationScheduleIndex]
                if (_id) {
                    this.removeJobTitleModalValue = true
                    this.staffingAutomationRequestToBeDeletedId = _id
                    this.staffingAutomationRequestToBeDeletedIndex = staffingAutomationScheduleIndex
                } else {
                    this.staffingAutomationsSchedules = this.staffingAutomationsSchedules.filter((_, index) => index !== staffingAutomationScheduleIndex)
                    this.prevStaffingAutomationsSchedules = this.prevStaffingAutomationsSchedules.filter((_, index) => index !== staffingAutomationScheduleIndex)
                }
            }
        },
        deleteStaffingAutomationSchedule() {
            this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                this.staffingAutomationsSchedules,
                this.staffingAutomationRequestToBeDeletedIndex,
                {isDeleting: true}
            )
            this.api.delete(`/staffing-automation-request/${this.staffingAutomationRequestToBeDeletedId}`)
                .then(({message}) => {
                    message('Staffing Automation Request Deleted')
                    this.staffingAutomationsSchedules = this.staffingAutomationsSchedules.filter((_, index) => index !== this.staffingAutomationRequestToBeDeletedIndex)
                    this.prevStaffingAutomationsSchedules = this.prevStaffingAutomationsSchedules.filter((_, index) => index !== this.staffingAutomationRequestToBeDeletedIndex)
                })
                .catch(console.log)
                .finally(() => {
                    this.staffingAutomationsSchedules = this.getUpdatedStaffingAutomationsAtIndex(
                        this.staffingAutomationsSchedules,
                        this.staffingAutomationRequestToBeDeletedIndex,
                        {isDeleting: false}
                    )
                })
        },
        getUpdatedStaffingAutomationsAtIndex(staffingAutomations, index, mergeObj) {
            if (index < staffingAutomations.length) {
                const newStaffingAutomations = Array.from(staffingAutomations)
                newStaffingAutomations[index] = {
                    ...newStaffingAutomations[index],
                    ...mergeObj,
                }
                return newStaffingAutomations
            }
            return staffingAutomations
        },
        initPrevStaffingAutomationsSchedules() {
            this.prevStaffingAutomationsSchedules = this.staffingAutomationsSchedules.map(schedule => ({
                jobTitleId: schedule.jobTitleId,
                credentialsIds: Array.from(schedule.credentialsIds),
                dailySchedule: Array.from(schedule.dailySchedule)
            }))
        },
        onClose() {
            if (this.isStaffingAutomationDetailsDirty) {
                this.$refs.unsavedChangesDialog.open()
                    .then(() => {
                        this.$emit('onClose')
                    })
                    .catch(() => {
                    })
            } else {
                this.$emit('onClose')
            }
        },
        onActivate() {
            this.isActivating = true
            this.api.post(`/staffing-automation/activate/${this.staffingAutomationId}`, {
                shift_start: this.startDate,
                run_date: this.runDate,
                timezone: this.staffingAutomation.timezone
            }).then(({data, message}) => {
                message('Staffing Automation Activated')
                this.$emit('onActivate')
            }).catch(console.log).finally(() => {
                this.isActivating = false
            })
        },
        onClickBackToShifts() {
            if (this.isStaffingAutomationDetailsDirty) {
                this.$refs.unsavedChangesDialog.open()
                    .then(() => {
                        this.$emit('onClickBackToShifts')
                    })
                    .catch(() => {
                    })
            } else {
                this.$emit('onClickBackToShifts')
            }
        },
        onAddJobTitle() {
            this.staffingAutomationsSchedules = [
                ...this.staffingAutomationsSchedules,
                {
                    _id: null,
                    jobTitleId: null,
                    credentialsIds: [],
                    dailySchedule: [0, 0, 0, 0, 0, 0, 0],
                    isSaving: false,
                    isDeleting: false,
                    isDirty: true,
                    isManagedByAuthUser: true
                },
            ]
            this.initPrevStaffingAutomationsSchedules()
        },
        loadActivationDetails() {
            this.isLoadingActivationDetails = true
            this.api.get(`/staffing-automation/activation-constraints/${this.staffingAutomation._id}`)
                .then(data => {
                    this.activationModalValue = true
                    if (data?.data) {
                        this.activationModalMinStartDate = data.data.minimum_shift_start
                        this.activationModalDayOfWeek = data.data.day_of_week
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.isLoadingActivationDetails = false
                })
        },
        deactivateStaffingAutomation() {
            this.isDeactivating = true
            this.api.post(`/staffing-automation/deactivate/${this.staffingAutomation._id}`)
                .then(({data, message}) => {
                    if (data) {
                        this.staffingAutomation = {...data}
                    }
                    message('Staffing Automation Deactivated')
                    this.$emit('onDeactivate')
                })
                .catch(() => {
                    this.staffingAutomation = {
                        ...this.staffingAutomation,
                        enabled: !this.staffingAutomation.enabled
                    }
                })
                .finally(() => {
                    this.isDeactivating = false
                })
        },
        onChangeStatus(enabled) {
            if (enabled) {
                this.loadActivationDetails()
            } else {
                this.deactivateStaffingAutomation()
            }
        },
        onCancelActivate() {
            this.staffingAutomation = {
                ...this.staffingAutomation,
                enabled: !this.staffingAutomation.enabled
            }
        },
        onConfirmActivate(staffingAutomation) {
            if (staffingAutomation) {
                this.staffingAutomation = {...staffingAutomation}
            }
            this.$emit('onActivate')
        },
        onDeleteStaffingAutomation() {
            this.$emit('onDelete')
        },
        onUpdateStaffingAutomation(staffingAutomation) {
            this.staffingAutomation = {
                ...this.staffingAutomation,
                shift_time: staffingAutomation.shift_time,
                shift_time_id: staffingAutomation.shift_time_id,
                start_time: staffingAutomation.start_time,
                duration: staffingAutomation.duration,
                end_time: staffingAutomation.end_time,
            }
            this.$emit('onUpdateStaffingAutomation')
        }
    },
    created() {
        if (this.staffingAutomationId) {
            this.fetchStaffingAutomation(this.staffingAutomationId)
        }
    }
}
</script>

<style scoped>

</style>
