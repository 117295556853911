<template>
    <v-dialog
        ref="timePickerDialog"
        v-model="timePickerModal"
        :return-value.sync="time"
        persistent
        width="290px"
        :disabled="readonly"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="formattedTime"
                :disabled="disabled"
                :rules="rules.required"
                :error-messages="errorMessages"
                @click:clear="onClearTime"
                :label="label"
                prepend-inner-icon="access_time"
                :hint="hint"
                persistent-hint
                readonly
                outlined
                :clearable="!readonly"
                v-on="on"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="timePickerModal"
            v-model="time"
            full-width
            ampm-in-title
            scrollable
            color="primary"
            :readonly="readonly"
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="timePickerModal = false"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="onUpdateTime(time)"
            >
                OK
            </v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>
import validationRules from '../../../lib/mixins/validationRules'

export default {
    name: "TimePicker",
    mixins: [validationRules],
    props: {
        value: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: [String, Array],
            default: null
        },
        hint: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        timePickerModal: false,
    }),
    computed: {
        time: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        formattedTime() {
            if (this.time) {
                return moment(moment().format('YYYY-MM-DD') + ' ' + this.time).format('h:mm A')
            }
            return null
        }
    },
    methods: {
        onUpdateTime(time) {
            this.$refs?.timePickerDialog?.save(time)
            this.$emit('onUpdateTime', time)
        },
        onClearTime() {
            this.$emit('onClearTime')
        }
    }
}
</script>

<style scoped>

</style>
