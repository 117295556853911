<template>
    <dashboard-doughnut-chart
        :percentage="percentage"
        description="Filled"
        :half="half"
        :tooltips-options="tooltipsOptions"
        :loading="loading"
    />
</template>

<script>
import DashboardDoughnutChart from "./DashboardDoughnutChart";

export default {
    name: "ShiftsDoughnutChart",
    components: { DashboardDoughnutChart },
    props: {
        filled: {
            type: Number,
            default: 0,
        },
        open: {
            type: Number,
            default: 0,
        },
        percentage: {
            type: Number,
            default: 50,
        },
        half: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        tooltipsOptions() {
            return {
                callbacks: {
                    label: () => {
                        return [
                            `Filled    ${this.filled}`,
                            `Open    ${this.open}`
                        ]
                    }
                },
            }
        }
    }
}
</script>

<style scoped>

</style>
