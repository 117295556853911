<template>
    <div class="d-flex flex-column align-center px-8" style="width: 100%;">
        <div class="d-flex flex-column align-center px-15" style="width: 100%;">
            <p class="text--h1">
                Add your first location
            </p>
            <p class="text--paragraph" style="margin-bottom: 32px;">
                You will be able to add more locations in Koroid after setup.
            </p>
            <v-form
                ref="locationForm"
                :lazy-validation="false"
            >
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-text-field
                            name="location-name"
                            label="Location Name"
                            v-model.trim="location.name"
                            :error-messages="api.hasError('name')"
                            :rules="required"
                            class="required"
                            autocomplete="off"
                            outlined
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <v-text-field
                            name="primary-address"
                            label="Address (Line 1)"
                            v-model.trim="location.primary_address"
                            :error-messages="api.hasError('primary_address')"
                            :rules="required"
                            class="required"
                            autocomplete="off"
                            outlined
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <v-text-field
                            name="secondary-address"
                            label="Address (Line 2)"
                            v-model.trim="location.secondary_address"
                            :error-messages="api.hasError('secondary_address')"
                            autocomplete="off"
                            outlined
                        />
                    </v-col>

                    <v-col cols="4" class="py-0">
                        <v-text-field
                            name="city"
                            label="City"
                            v-model.trim="location.city"
                            :error-messages="api.hasError('city')"
                            :rules="required"
                            class="required"
                            autocomplete="off"
                            outlined
                        />
                    </v-col>

                    <v-col cols="4" class="py-0">
                        <v-autocomplete
                            name="state"
                            label="State"
                            v-model="location.state"
                            :error-messages="api.hasError('state')"
                            :items="states"
                            :rules="required"
                            class="required"
                            autocomplete="off"
                            outlined
                        />
                    </v-col>

                    <v-col cols="4" class="py-0">
                        <v-text-field
                            name="postal"
                            label="ZIP Code"
                            v-model.trim="location.zip_code"
                            :error-messages="api.hasError('zip_code')"
                            :rules="required"
                            class="required"
                            autocomplete="off"
                            outlined
                        />
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div style="width: 100%; position: relative;">
            <div class="d-flex flex-row justify-space-between" style="width: 100%; position: absolute; top: 20px;">
                <v-btn
                    color="primary"
                    text
                    :disabled="api.busy"
                    @click="onClickPrevious"
                >
                    Previous
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    :loading="api.busy"
                    @click="onClickNext"
                >
                    Next
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddLocation",
    props: {
        value: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            states: this.$config.states,
            api: new formHelper(),
        }
    },
    computed: {
        location: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        required() {
            return [
                v => !!v && !!v.trim() || 'Required'
            ]
        },
    },
    methods: {
        onClickPrevious() {
            this.$emit('onClickPrevious')
        },
        onClickNext() {
            if (this.$refs.locationForm.validate()) {
                this.api.post('/onboarding/store-location', this.location)
                    .then(({ data }) => {
                        this.$emit('onClickNext', data)
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
