<template>
    <div class="bulk-upload-users-instructions-container">
        <div class="text--h3 text-center">
            Upload Instructions:
        </div>
        <ul class="text--paragraph">
            <li class="pb-3">
                Only .CSV files are accepted
            </li>
            <li class="pb-3">
                Bulk upload is limited to 1000 rows
            </li>
            <li class="pb-3">
                Each column must be labeled with a header in the top row. Individual columns must contain the following data:
                <ul>
                    <li>
                        First Name
                        <ul>
                            <li>
                                Text only
                            </li>
                        </ul>
                    </li>
                    <li>
                        Last Name
                        <ul>
                            <li>
                                Text only
                            </li>
                        </ul>
                    </li>
                    <li>
                        Username
                    </li>
                    <li>
                        Email Address
                        <ul>
                            <li v-for="emailDomain in emailDomains">
                                {{ emailDomain }}
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "BulkUploadUsersInstructions",
    props: {
        emailDomains: {
            type: Array,
            default: () => [],
        }
    }
}
</script>

<style scoped>
    .bulk-upload-users-instructions-container {
        display: flex;
        flex-direction: column;
        padding: 24px;
        row-gap: 16px;
        background-color: #F6F6F6;
        max-width: 338px;
        height: 100%;
        overflow-y: auto;
    }

    ul {
        list-style-type: disc;
    }

    ul ul {
        list-style-type: disc;
    }
</style>
