<template>
    <v-container grid-list-xl fluid>
        <v-row>
            <v-col>
                <v-window class="pa-4">
                    <UserProfileTabs
                        :user="profile"
                        :profile-view="true"
                        :user-has-all-permissions="isAdmin"
                        :roles="roles"
                        :email-domains="emailDomains"
                        :job-titles-dict="jobTitlesDict"
                        @upcomingAvailabilityInfoReceived="userUpdated"
                        :new-user="false"
                        :preselected-tab="preselectedTab"
                    ></UserProfileTabs>
                </v-window>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import UserProfileTabs from "../../components/profile/UserProfileTabs";

    export default {
        name: "ProfilePage",
        components: {
            UserProfileTabs
        },
        data() {
            return {
                profile: this.$appData.profile,
                roles: this.$appData.roles,
                hospitalGroup: this.$appData.hospital_group,
                jobTitles: this.$appData.job_titles,
                preselectedTab: this.$appData.preselected_tab,
                isAdmin: this.$authIsOwner,
                jobTitlesDict: null,
            }
        },
        methods: {
            userUpdated(user) {
                this.profile = Object.assign({}, user)
            }
        },
        computed: {
            emailDomains() {
                if(this.hospitalGroup && Array.isArray(this.hospitalGroup.email_domain)) {
                    return this.hospitalGroup.email_domain
                }
                return []
            }
        },
        created() {
            this.jobTitlesDict = this.jobTitles.reduce((jobTitles, jobTitle) => ({
                ...jobTitles,
                [jobTitle._id]: jobTitle
            }), {})
        }
    }
</script>

<style scoped>

</style>
