module.exports = {
    data() {
        return {
            rules: {
                required: [
                    v => !!v && !!v.trim() || 'Required'
                ],
                requiredNumber: [
                    v => typeof v === 'number' || 'Required'
                ],
                requiredNoTrim: [
                    v => !!v || 'Required'
                ],
                requiredArrayNotEmpty: [
                    v => (!!v && Array.isArray(v) && v.length > 0) || 'Required'
                ],
                phone_number_no_dots: [
                    v => !!v && !!v.trim() || 'Required',
                    v => !!v && v.indexOf('.') === -1 || 'Invalid phone number'
                ],
                requiredEmail: [
                    v => !!v && !!v.trim() || 'Required',
                    v => /^[\w-\+\.]+@([\w-]+\.)+[\w-]+$/.test(v) || 'Invalid e-mail format',
                ],
                requiredString: [
                    v => !!v && !!v.trim() || 'Required',
                    v => /^[a-zA-Z]+$/.test(v) || 'Letters only'
                ],
                requiredName: [
                    v => !!v && !!v.trim() || 'Required',
                    v => /^[a-zA-Z-']+(\s[a-zA-Z-']+)*\s?$/.test(v) || 'Letters and hyphen only'
                ],
            }
        }
    }
}
