<template>
    <div class="calendar-edge-event-container">
        <v-icon
            color="#000000"
            class="calendar-edge-event-icon"
            :style="iconContainerStyle"
        >mdi-calendar-end</v-icon>
    </div>
</template>

<script>
export default {
    name: "EdgeEvent",
    props: {
        event: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        iconContainerStyle () {
            if (this.isEndsEvent) {
                return {
                    transform: 'scaleX(-1)'
                }
            }
            return {}
        },
        isEndsEvent () {
            return this.event?.eventDetails?.type === 'ends'
        },
    }
}
</script>

<style scoped>
    .calendar-edge-event-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .calendar-edge-event-icon {
        font-size: 20px;
    }
</style>
