<template>
    <div class="chart-view-scroll-container">
        <div class="chart-view-container">
            <div class="chart-view-label-container">
                <div class="d-flex align-center text--paragraph" style="column-gap: 5px;">
                    <span>Total</span>
                    <span>Percent</span>
                </div>
                <div class="d-flex align-center text--paragraph" style="column-gap: 5px;">
                    <span>Shifts</span>
                    <span>Filled</span>
                </div>
            </div>
            <div class="chart-view-bar-chart-container">
                <chart-counts
                    :counts="internalCounts"
                    @onMouseEnter="onMouseEnter"
                    @onMouseLeave="onMouseLeave"
                ></chart-counts>
                <shifts-bar-chart
                    :data-source="dataSource"
                ></shifts-bar-chart>
            </div>
        </div>
    </div>
</template>

<script>
import ChartCounts from './ChartCounts'
import ShiftsBarChart from './ShiftsBarChart'

export default {
    name: "ChartView",
    components: { ChartCounts, ShiftsBarChart },
    props: {
        dataSource: {
            type: Array,
            default: () => []
        },
        counts: {
            type: Array,
            default: () => {}
        },
    },
    data: () => ({
        internalCounts: [],
    }),
    methods: {
        onMouseEnter (index) {
            this.internalCounts = this.toggleCountsVisibility(
                this.internalCounts,
                index,
                true
            )
        },
        onMouseLeave (index) {
            this.internalCounts = this.toggleCountsVisibility(
                this.internalCounts,
                index,
                false
            )
        },
        toggleCountsVisibility (counts, index, visible = false) {
            return counts.map((count, countIndex) => {
                if (countIndex === index) {
                    return {
                        ...count,
                        shown: visible,
                    }
                }
                return count
            })
        },
    },
    watch: {
        counts: {
            immediate: true,
            handler (counts) {
                this.internalCounts = counts.map(item => ({
                    ...item,
                    shown: false,
                }))
            }
        }
    }
}
</script>

<style scoped>
    .chart-view-scroll-container {
        display: flex;
        flex-direction: row;
        background-color: white;
        z-index: 8;
    }

    .chart-view-container {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
    }

    .chart-view-label-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: calc(15% - 44px);
        max-width: calc(15% - 44px);
    }

    .chart-view-bar-chart-container {
        position: relative;
        min-width: 0;
        width: 100%;
    }
</style>
