<template>
    <div class="calendar-days-scroll-container">
        <div class="calendar-days-container">
            <div class="calendar-days-grouping-view">
                <slot name="sort-by-menu"></slot>
            </div>
            <div class="calendar-days-view">
                <div class="calendar-days-view-inner">
                    <div
                        v-if="currentDay.exists"
                        class="current-day-gradient"
                        :style="{ left: `${14.25 * currentDay.index}%` }"
                    >
                        <div class="current-day-container">
                            <div class="calendar-days-text-container">
                                <span class="text--h4">
                                    {{ currentDay.dayOfWeek | capitalize }}
                                </span>
                                <span class="text--h3">
                                    {{ currentDay.dayOfMonth }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(unit, index) in units"
                        :key="index"
                        class="calendar-days-daily-container"
                    >
                        <div class="calendar-days-text-container" v-if="!(currentDay.exists && currentDay.index === index)">
                            <span class="text--h4">
                                {{ unit.dayOfWeek | capitalize }}
                            </span>
                            <span class="text--h3">
                                {{ unit.dayOfMonth }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CalendarDays",
    props: {
        startOfWeek: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        units () {
            if (this.startOfWeek) {
                const startOfWeek = this.startOfWeek.clone()
                return _.range(7).map(() => {
                    const dayOfWeek = startOfWeek.format('ddd')
                    const dayOfMonth = startOfWeek.format('D')
                    startOfWeek.add(1, 'days')
                    return {
                        dayOfWeek,
                        dayOfMonth,
                    }
                })
            }
            return []
        },
        currentDay () {
            let exists = false
            let index = 0
            let dayOfWeek = null
            let dayOfMonth = null
            if (this.startOfWeek) {
                const now = moment().tz(this.globalTimezone)
                const startOfWeek = this.startOfWeek.clone()
                _.range(7).forEach(i => {
                    const isToday = startOfWeek.isSame(now, 'day')
                    if (isToday) {
                        exists = true
                        index = i
                        dayOfWeek = startOfWeek.format('ddd')
                        dayOfMonth = startOfWeek.format('D')
                    }
                    startOfWeek.add(1, 'days')
                })
            }
            return {
                exists,
                index,
                dayOfWeek,
                dayOfMonth,
            }
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    }
}
</script>

<style scoped>
    .calendar-days-scroll-container {
        display: flex;
        flex-direction: row;
    }

    .calendar-days-container {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
    }

    .calendar-days-grouping-view {
        min-width: 15%;
        max-width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .calendar-days-view {
        min-width: auto;
        width: 100%;
        min-height: 60px;
    }

    .calendar-days-view-inner {
        border-top: 3px solid #A9A9A9;
        border-bottom: 2px solid #A9A9A9;
        height: 100%;
        width: 100%;
        margin: 0;
        display: flex;
        background-image: repeating-linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            transparent 1px 100%
        );
        background-repeat: repeat;
        background-size: 14.25% 71px;
        position: relative;
    }

    .calendar-days-daily-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .calendar-days-text-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 7px;
        color: #0D0A32;
    }

    .calendar-days-day-text {
        font-size: 18px;
        font-weight: bold;
    }

    .current-day-gradient {
        position: absolute;
        background-image: linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            rgba(240, 240, 240, 0.6) 1px 100%
        );
        width: 14.25%;
        height: 100%;
    }

    .current-day-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .calendar-days-day-number {
        font-size: 24px;
        font-weight: bold;
    }
</style>
