<template>
    <v-dialog v-model="showModal" max-width="450px" elevation="10" scrollable persistent>
        <v-card>
            <v-toolbar dark color="primary" elevation="0" height="40">
                <v-toolbar-title class="white--text toolbar-title">Maximum Duration Exceeded</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pt-4 pb-0">
                A single availability event may not exceed more than 36 hours in duration. Please review your existing availability.
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12" class="mt-4 mb-4">
                        <v-btn block color="primary" @click="closeModal">OK</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    name: "DurationExceededModal",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
        },
    },
    watch: {
        value(newVal) {
            this.showModal = newVal
        },
        showModal(newVal) {
            if (!newVal) {
                this.$emit('input', false)
            }
        }
    }
}
</script>

<style scoped>

</style>
