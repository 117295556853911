<template>
    <div>
        <v-card>
            <report-details-table-header
                :report="report"
                @onSavePreset="onSavePreset"
                @onGoBack="onGoBack"/>
            <report-details-table :report="report"/>

            <v-btn color="primary" @click="onGoBack" class="ml-3 my-3" outlined>
                Back to Reports
            </v-btn>
        </v-card>
    </div>
</template>

<script>
import ReportDetailsTableHeader from "../Tables/Headers/ReportDetailsTableHeader";
import ReportDetailsTable from "../Tables/ReportDetailsTable";

export default {
    name: "ReportDetails",
    components: { ReportDetailsTableHeader, ReportDetailsTable },
    props: {
        report: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        onGoBack() {
            this.$emit('onGoBack')
        },
        onSavePreset(preset) {
            this.$emit('onSavePreset', preset)
        }
    }
}
</script>

<style scoped>

</style>
