<template>
    <v-form ref="recurrenceSettingsFormRef">
        <v-container fluid style="margin-top: 20px; padding-top: 0;">
            <v-row>
                <v-col cols="12">
                    <span class="text--h4" style="color: #000000;">Shift Dates</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3" class="pt-3">
                    <label class="text--label black--text">Starts on</label>
                </v-col>
                <v-col cols="12" md="9" class="pt-0 pb-0">
                    <time-picker-dialog
                        v-model="startDate"
                        :text-field-props="startDateTextFieldProps"
                        :time-picker-props="startDateCalendarProps"
                        required
                        min-rule
                        max-rule
                        is-date-picker
                        @onConfirmTime="onStartDateSelect"
                    ></time-picker-dialog>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3" class="pt-6">
                    <label class="text--label black--text">Every</label>
                </v-col>
                <v-col cols="6" md="4" class="pt-0 pb-0">
                    <number-input
                        v-model.number="interval"
                        class="no-top-gutter"
                        label="Number"
                        :min="1"
                        :max="99"
                        :rules="intervalRules"
                        controls
                    ></number-input>
                </v-col>

                <v-col cols="6" md="5" class="pt-0 pb-0">
                    <v-autocomplete
                        v-model="timeframe"
                        class="no-top-gutter"
                        label="Timeframe"
                        :items="timeframes"
                        :rules="timeframeRules"
                        :disabled="editMode"
                        outlined
                    >
                        <template v-slot:selection="item">
                            {{ item.item.value | capitalize | pluralize(interval) }}
                        </template>

                        <template v-slot:item="item">
                            {{ item.item.value | capitalize | pluralize(interval) }}
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row v-show="isTimeframeWeekly">
                    <v-col cols="12" md="auto" class="pt-5">
                        <label class="text--label black--text">On</label>
                    </v-col>
                    <v-col class="no-top-gutter">
                        <time-container
                            :items="weeks"
                            weekly
                            @onClick="onWeekdayClick"
                        ></time-container>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <v-expand-transition>
                <v-row v-show="isTimeframeMonthly">
                    <v-col cols="12" md="auto" class="pt-5">
                        <label class="text--label black--text">On</label>
                    </v-col>
                    <v-col class="no-top-gutter">
                        <time-container
                            :items="days"
                            @onClick="onMonthdayClick"
                        ></time-container>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <v-row style="margin-top: 0;">
                <v-col cols="12" md="auto" class="pt-5">
                    <label class="text--label black--text">Ends</label>
                </v-col>

                <v-col class="pt-1">
                    <v-radio-group v-model="endOption" class="no-top-gutter">
                        <v-row >
                            <v-col cols="auto" class="py-0">
                                <v-radio label="On" :value="END_OPTION_VALUES.DATE" class="input-gutter"></v-radio>
                            </v-col>
                            <v-col class="py-0">
                                <time-picker-dialog
                                    v-model="endDate"
                                    :text-field-props="endDateTextFieldProps"
                                    :time-picker-props="endDateCalendarProps"
                                    :required="isEndOptionDate"
                                    :min-rule="isEndOptionDate"
                                    :max-rule="isEndOptionDate"
                                    is-date-picker
                                ></time-picker-dialog>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="auto" class="py-0">
                                <v-radio label="After" :value="END_OPTION_VALUES.OCCURRENCES" class="input-gutter"></v-radio>
                            </v-col>

                            <v-col class="py-0">
                                <number-input
                                    v-model.number="occurrences"
                                    label="Number"
                                    class="pt-0 mt-0"
                                    :min="1"
                                    :max="endOccurrencesMax"
                                    :disabled="isEndOptionDate"
                                    :rules="endOccurrencesRules"
                                    controls
                                ></number-input>
                            </v-col>
                            
                            <v-col cols="auto" class="py-0">
                                <div class="text--paragraph input-gutter">occurrences</div>
                            </v-col>
                        </v-row>

                    </v-radio-group>

                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import TimeContainer from './TimeContainer'
import TimePickerDialog from '../../../modals/TimePickerDialog'
import NumberInput from "../../../common/NumberInput/NumberInput.vue";

export default {
    name: "RecurrenceSettings",
    components: {NumberInput, TimeContainer, TimePickerDialog },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => {
        const TIMEFRAME_VALUES = {
            DAY: 'day',
            WEEK: 'week',
            MONTH: 'month',
        }
        const END_OPTION_VALUES = {
            DATE: 'date',
            OCCURRENCES: 'occurrences',
        }
        return {
            startDate: null,
            interval: null,
            timeframe: null,
            endDate: null,
            occurrences: null,
            endOption: END_OPTION_VALUES.DATE,
            timeframes: [{
                text: 'day',
                value: TIMEFRAME_VALUES.DAY,
            }, {
                text: 'week',
                value: TIMEFRAME_VALUES.WEEK,
            }, {
                text: 'month',
                value: TIMEFRAME_VALUES.MONTH,
            }],
            TIMEFRAME_VALUES,
            END_OPTION_VALUES,
            weeks: [
                {
                    initial: 'Sun',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
                {
                    initial: 'Mon',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
                {
                    initial: 'Tue',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
                {
                    initial: 'Wed',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
                {
                    initial: 'Thu',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
                {
                    initial: 'Fri',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
                {
                    initial: 'Sat',
                    selected: false,
                    disabled: false,
                    immutable: false,
                },
            ],
            days: _.range(31).map(index => ({
                initial: `${index + 1}`,
                selected: false,
                disabled: false,
                immutable: false,
            })),
            prevValues: {
                startDate: null,
                interval: null,
                timeframe: null,
                endDate: null,
                occurrences: null,
                endOption: END_OPTION_VALUES.DATE,
                weeks: [],
                days: [],
            },
        }
    },
    computed: {
        intervalRules () {
            return [
                v => !!v || 'Required',
                v => v > 0 || 'Must be greater than 0',
                v => v <= 100 || 'Can not be greater than 100'
            ]
        },
        timeframeRules () {
            return [
                v => !!v || 'Required',
            ]
        },
        endOccurrencesRules() {
            if (this.isEndOptionOccurrences) {
                if (this.isTimeframeDaily) {
                    return [
                        v => !!v || 'Required',
                        v => v > 0 || 'Must be greater than 0',
                        v => v <= 365 || 'Can not be greater than 365'
                    ]
                } else if (this.isTimeframeWeekly) {
                    return [
                        v => !!v || 'Required',
                        v => v > 0 || 'Must be greater than 0',
                        v => v <= 52 || 'Can not be greater than 52'
                    ]
                } else if (this.isTimeframeMonthly) {
                    return [
                        v => !!v || 'Required',
                        v => v > 0 || 'Must be greater than 0',
                        v => v <= 12 || 'Can not be greater than 12'
                    ]
                } else {
                    return [
                        v => !!v || 'Required',
                    ]
                }
            }
            return []
        },
        endOccurrencesMax () {
            if (this.isTimeframeDaily) {
                return 365
            } else if (this.isTimeframeWeekly) {
                return 52
            } else if (this.isTimeframeMonthly) {
                return 12
            } else {
                return 100
            }
        },
        isTimeframeDaily () {
            return this.timeframe === this.TIMEFRAME_VALUES.DAY
        },
        isTimeframeWeekly () {
            return this.timeframe === this.TIMEFRAME_VALUES.WEEK
        },
        isTimeframeMonthly () {
            return this.timeframe === this.TIMEFRAME_VALUES.MONTH
        },
        isEndOptionDate () {
            return this.END_OPTION_VALUES.DATE === this.endOption
        },
        isEndOptionOccurrences () {
            return this.END_OPTION_VALUES.OCCURRENCES === this.endOption
        },
        startDateTextFieldProps() {
            let value = null
            if (this.startDate) {
                const endMoment = this.genMoment(this.startDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'Start Date',
                class: 'pt-0 mt-0',
                value,
            }
        },
        endDateTextFieldProps() {
            let value = null
            if (this.endDate) {
                const endMoment = this.genMoment(this.endDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'End Date',
                class: 'pt-0 mt-0',
                value,
                disabled: this.isEndOptionOccurrences,
            }
        },
        startDateCalendarProps() {
            return {
                min: this.startDateMinValue,
                max: this.dateMaxValue,
            }
        },
        endDateCalendarProps() {
            return {
                min: this.endDateMinValue,
                max: this.dateMaxValue,
            }
        },
        startDateMinValue() {
            return this.genMoment(moment().format(this.dateFormat)).format(this.dateFormat)
        },
        endDateMinValue() {
            if (this.startDate) {
                return this.startDate
            }
            return this.genMoment(moment().format(this.dateFormat)).format(this.dateFormat)
        },
        dateMaxValue() {
            return this.genMoment(moment().format(this.dateFormat))
                .add(1, 'years')
                .format(this.dateFormat)
        },
        dateFormat () {
            return 'YYYY-MM-DD'
        },
        weekIndices () {
            return this.getSelectedIndices(this.weeks)
        },
        monthIndices () {
            return this.getSelectedIndices(this.days, false)
        },
        dayOfWeek () {
            if (this.startDate) {
                return this.genMoment(this.startDate).day()
            }
            return null
        },
        dayOfMonth () {
            if (this.startDate) {
                return this.genMoment(this.startDate).date()
            }
            return null
        },
        recurrenceSettingsFormRef () {
            return this.$refs.recurrenceSettingsFormRef
        },
        bodyParams () {
            return {
                dates: [this.startDate],
                recurrence_settings: {
                    interval: this.interval,
                    period: this.timeframe,
                    end_type: this.endOption,
                    end_value: this.isEndOptionDate ? this.endDate : this.occurrences,
                    ...this.isTimeframeWeekly && { weekdays: this.weekIndices },
                    ...this.isTimeframeMonthly && { month_days: this.monthIndices },
                }
            }
        },
        isDirty () {
            if (!_.isEqual(this.startDate, this.prevValues.startDate) ||
                !_.isEqual(this.interval, this.prevValues.interval) ||
                !_.isEqual(this.timeframe, this.prevValues.timeframe) ||
                !_.isEqual(this.endOption, this.prevValues.endOption)) {
                return true
            }
            if (this.isEndOptionDate && !_.isEqual(this.endDate, this.prevValues.endDate)) {
                return true
            }
            if (this.isEndOptionOccurrences && !_.isEqual(this.occurrences, this.prevValues.occurrences)) {
                return true
            }
            if (this.isTimeframeWeekly && !_.isEqual(this.weekIndices, this.prevValues.weeks)) {
                return true
            }
            return this.isTimeframeMonthly && !_.isEqual(this.monthIndices, this.prevValues.days)
        },
    },
    methods: {
        onWeekdayClick (index) {
            this.weeks = this.selectValues(this.weeks, index)
        },
        onMonthdayClick (index) {
            this.days = this.selectValues(this.days, index)
        },
        onStartDateSelect () {
            this.weeks = this.makeValuesImmutable(this.weeks, this.dayOfWeek, true, !this.isTimeframeWeekly)
            this.days = this.makeValuesImmutable(this.days, this.dayOfMonth, false, !this.isTimeframeMonthly)
        },
        selectValues (values, index) {
            return values.map((value, idx) => {
                if (idx === index && !value.immutable) {
                    return {
                        ...value,
                        selected: !value.selected,
                    }
                }
                return value
            })
        },
        makeValuesImmutable (values, index, zeroBased = true, deselectAll = false) {
            return values.map((value, idx) => {
                const currIndex = zeroBased ? idx : idx + 1
                const immutable = currIndex === index
                return {
                    ...value,
                    immutable,
                    ...immutable && { selected: true },
                    ...!immutable && deselectAll && { selected: false },
                }
            })
        },
        getSelectedIndices (values, zeroBased = true) {
            return values.reduce((indices, value, index) => {
                if (value.selected) {
                    return [
                        ...indices,
                        zeroBased ? index : index + 1,
                    ]
                }
                return indices
            }, [])
        },
        validate () {
            if (this.recurrenceSettingsFormRef) {
                return this.recurrenceSettingsFormRef.validate()
            }
            return false
        },
        genMoment (date) {
            return moment(date, 'YYYY-MM-DD')
        },
    },
    created () {
        const { editMode, initialRequestForm } = this
        if (editMode && initialRequestForm) {
            if (initialRequestForm.selected_start_date) {
                this.startDate = initialRequestForm.selected_start_date
                this.prevValues = {
                    ...this.prevValues,
                    startDate: initialRequestForm.selected_start_date,
                }
            }
            const requestFormGroup = initialRequestForm.request_form_group
            if (requestFormGroup) {
                const settings = requestFormGroup.recurrence_settings
                if (settings) {
                    this.interval = settings.interval
                    this.timeframe = settings.period
                    this.endOption = settings.end_type
                    this.prevValues = {
                        ...this.prevValues,
                        interval: settings.interval,
                        timeframe: settings.period,
                        endOption: settings.end_type,
                    }
                    if (settings.end_type === this.END_OPTION_VALUES.DATE) {
                        this.endDate = settings.end_value
                        this.prevValues = {
                            ...this.prevValues,
                            endDate: settings.end_value,
                        }
                    } else if (settings.end_type === this.END_OPTION_VALUES.OCCURRENCES) {
                        if (requestFormGroup.hasOwnProperty('remaining_occurrences')) {
                            this.occurrences = requestFormGroup.remaining_occurrences
                            this.prevValues = {
                                ...this.prevValues,
                                occurrences: requestFormGroup.remaining_occurrences
                            }
                        } else {
                            this.occurrences = settings.end_value
                            this.prevValues = {
                                ...this.prevValues,
                                occurrences: settings.end_value,
                            }
                        }
                    }
                    if (settings.period === this.TIMEFRAME_VALUES.WEEK) {
                        if (Array.isArray(settings.weekdays)) {
                            const weeksMapping = settings.weekdays.reduce((accumulator, weekIndex) => ({
                                ...accumulator,
                                [weekIndex]: true,
                            }), {})
                            this.weeks = this.weeks.map((week, index) => {
                                if (weeksMapping.hasOwnProperty(index)) {
                                    return {
                                        ...week,
                                        selected: true,
                                    }
                                }
                                return week
                            })
                            this.prevValues = {
                                ...this.prevValues,
                                weeks: Array.from(settings.weekdays)
                            }
                            this.onStartDateSelect()
                        }
                    } else if (settings.period === this.TIMEFRAME_VALUES.MONTH) {
                        if (Array.isArray(settings.month_days)) {
                            const daysMapping = settings.month_days.reduce((accumulator, dayIndex) => ({
                                ...accumulator,
                                [dayIndex]: true,
                            }), {})
                            this.days = this.days.map((day, index) => {
                                if (daysMapping.hasOwnProperty(index + 1)) {
                                    return {
                                        ...day,
                                        selected: true,
                                    }
                                }
                                return day
                            })
                            this.prevValues = {
                                ...this.prevValues,
                                days: Array.from(settings.month_days)
                            }
                            this.onStartDateSelect()
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
    .input-gutter {
        margin-top: 8px;
    }

    @media only screen and (max-width: 59.99375em) {
        .no-top-gutter {
            padding-top: 0;
            margin-top: 0;
        }

    }
</style>
