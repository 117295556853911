<template>
    <v-autocomplete
        v-model="selectedPreset"
        :items="presets"
        :loading="presetsLoading"
        :readonly="readonly"
        :clearable="!readonly"
        return-object
        item-value="_id"
        item-text="title"
        no-data-text="No saved Presets for report type"
        label="Preset"
        @change="emitOnChange"
        outlined
        hide-details
    />
</template>

<script>
export default {
    name: "PresetSelectInput",
    props: {
        value: {
            type: Object,
            default: () => null,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        presets: [],
        presetsLoading: false,
        api: new formHelper(),
    }),
    computed: {
        selectedPreset: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        loadPresets() {
            this.presetsLoading = true
            this.api.post('/report-preset/list-all', { filters: { report_type: this.reportType, location_id: this.location ? this.location._id : null } })
                .then(({ data }) => {
                    if(Array.isArray(data.data)) {
                        this.presets = Array.from(data.data)
                    }
                })
                .catch(() => {})
                .finally(() => {
                    this.presetsLoading = false
                })
        },
        emitOnChange() {
            this.$emit('onChange')
        }
    },
    mounted() {
        this.loadPresets()
    }
}
</script>

<style scoped>

</style>
