<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="800px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Automated Report</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="reportAutomationFormRef">
                    <v-container fluid>
                        <v-row class="mt-5">
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-select
                                    label="Preset Name"
                                    :value="reportAutomation.report_preset_id"
                                    @input="onInputPresetName"
                                    item-value="_id"
                                    item-text="title"
                                    :items="reportPresets"
                                    :rules="rules.required"
                                    :error-messages="api.hasError('report_preset_id')"
                                    :loading="reportPresetsLoading || isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                    :menu-props="{ 'offset-y': true }"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    readonly
                                />
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-divider/>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8">
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--h4">
                                    Automation Schedule
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container d-flex" style="column-gap: 12px;">
                                <p class="text--paragraph" style="margin-top: 18px;">
                                    Run a new report every
                                </p>
                                <v-select
                                    label="Frequency"
                                    :value="reportAutomation.repeat_period"
                                    @input="onInputFrequency"
                                    item-value="_id"
                                    item-text="name"
                                    :items="frequencyOptions"
                                    :rules="rules.required"
                                    :error-messages="api.hasError('repeat_period')"
                                    :menu-props="{ 'offset-y': true }"
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    clearable
                                    @change="onChangeFrequency"
                                />
                            </v-col>
                            <v-col
                                v-if="dayPeriodType"
                                cols="12"
                                md="6"
                                class="ds-modal-input-container d-flex"
                                :style="{ 'column-gap': '12px', ...(innerWidth > 960 && { 'padding-right': '0 !important'})}">
                                <p class="text--paragraph" style="margin-top: 18px;">
                                    on
                                </p>
                                <v-select
                                    v-if="dayPeriodType === 'weekly'"
                                    label="Day of Week"
                                    :value="reportAutomation.day_of_period"
                                    @input="onInputDayPeriod"
                                    item-value="_id"
                                    item-text="name"
                                    :items="dayOfWeekOptions"
                                    :rules="rules.requiredNumber"
                                    :error-messages="api.hasError('day_of_period')"
                                    :menu-props="{ 'offset-y': true }"
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    clearable
                                    @change="onChangeDayPeriod"
                                />
                                <day-of-month-picker
                                    v-if="dayPeriodType === 'monthly'"
                                    v-model="reportAutomation"
                                    :error-messages="api.hasError('day_of_period')"/>
                            </v-col>
                            <v-col cols="12" :md="dayPeriodType ? 6 : 12" class="ds-modal-input-container d-flex"
                                   style="column-gap: 12px;">
                                <p class="text--paragraph" style="margin-top: 18px;">
                                    at
                                </p>
                                <time-picker
                                    :value="reportAutomation.time"
                                    @input="onInputTime"
                                    label="Time"
                                    hide-required-class
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                />
                            </v-col>
                            <v-col cols="auto" class="ds-modal-input-container d-flex"
                                   :style="{ ...(innerWidth > 960 && { 'padding-right': '0 !important'})}">
                                <p class="text--paragraph" style="margin-top: 18px;">
                                    for data from the
                                </p>
                            </v-col>
                            <v-col cols class="ds-modal-input-container"
                                   :style="{ ...(innerWidth > 960 && { 'padding-right': '0 !important'})}">
                                <v-select
                                    label="Timeframe"
                                    :value="reportAutomation.timeframe"
                                    @input="onInputTimeframe"
                                    item-value="_id"
                                    item-text="name"
                                    :items="timeframeOptions"
                                    :rules="rules.required"
                                    :error-messages="api.hasError('timeframe')"
                                    :menu-props="{ 'offset-y': true }"
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" md class="ds-modal-input-container"
                                   :style="{ ...(innerWidth > 960 && { 'padding-right': '0 !important'})}">
                                <number-input
                                    ref="timeframeNumberRef"
                                    :key="timeframeNumberKey"
                                    label="Number"
                                    :value="reportAutomation.timeframe_length"
                                    @input="onInputTimeframeNumber"
                                    :error-messages="api.hasError('timeframe_length')"
                                    :min="timeframeNumberMin"
                                    :max="timeframeNumberMax"
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails || reportAutomation.timeframe === 'current'"
                                    controls
                                    required
                                    clearable
                                    class="ds-modal-input"
                                />
                            </v-col>
                            <v-col cols="12" md class="ds-modal-input-container">
                                <v-select
                                    label="Interval"
                                    :value="reportAutomation.timeframe_interval"
                                    @input="onInputTimeframeInterval"
                                    item-value="_id"
                                    item-text="name"
                                    :items="timeframeIntervalOptions"
                                    :rules="rules.required"
                                    :error-messages="api.hasError('timeframe_interval')"
                                    :menu-props="{ 'offset-y': true }"
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    clearable
                                    @change="onChangeTimeframeInterval"
                                />
                            </v-col>
                            <v-col :cols="reportAutomationId ? 9 : 12" class="ds-modal-input-container">
                                <time-picker-dialog
                                    :key="startDateKey"
                                    :value="reportAutomation.start_date"
                                    @input="onInputStartDate"
                                    :text-field-props="startDateTextFieldProps"
                                    :time-picker-props="startDateCalendarProps"
                                    :allowed-dates="allowedStartDates"
                                    allowed-dates-rule
                                    required
                                    min-rule
                                    max-rule
                                    is-date-picker
                                ></time-picker-dialog>
                            </v-col>
                            <v-col v-if="reportAutomationId" cols="3"
                                   class="ds-modal-input-container d-flex flex-column">
                                <p class="text--h5" style="margin-bottom: 6px; margin-top: 5px;">
                                    Last Run: {{ lastRunDate }}
                                </p>
                                <p class="text--h5">
                                    Next Run: {{ nextRunDate }}
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-divider/>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8">
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--h4">
                                    Share Report
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--paragraph">
                                    Select any users (including yourself) to receive an email with a copy of the report
                                    when
                                    generated via this automation:
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <search-shareable-users
                                    :value="Array.isArray(reportAutomation.shared_with) ? reportAutomation.shared_with : []"
                                    @input="onInputShareableUsers"
                                    :key="shareableUsersKey"
                                    :loading="isFetchingDetails"
                                    :disabled="isFetchingDetails"
                                    :initial-entries="initialShareableUsers"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="reportAutomationId"
                    color="error"
                    text
                    @click="onDelete"
                >
                    Remove Automation
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="onClose"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    :loading="isCreatingOrUpdating"
                    :disabled="isFetchingDetails || !isDirty"
                    @click="onSave"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <remove-automation-modal
            v-if="showRemoveAutomationModal"
            v-model="showRemoveAutomationModal"
            :report-automation-id="reportAutomationId"
            @onRemove="onRemoveReportAutomation"/>
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-dialog>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";
import DayOfMonthPicker from "./DayOfMonthPicker";
import TimePicker
    from "../../../pages/Organization/ConfigurationOptions/components/EmploymentTypes/WorkingHours/TimePicker";
import NumberInput from "../../common/NumberInput/NumberInput";
import TimePickerDialog from "../../modals/TimePickerDialog";
import SearchShareableUsers from "./SearchShareableUsers";
import RemoveAutomationModal from "./RemoveAutomationModal";
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";

export default {
    name: "AutomatedReportModal",
    components: {
        DayOfMonthPicker,
        TimePicker,
        NumberInput,
        TimePickerDialog,
        SearchShareableUsers,
        RemoveAutomationModal,
        UnsavedChangesDialog
    },
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        reportAutomationId: {
            value: String,
            default: null
        },
        presetId: {
            value: String,
            default: null,
        }
    },
    data: () => ({
        reportAutomation: {
            report_preset_id: null,
            repeat_period: null,
            day_of_period: null,
            time: null,
            timeframe: null,
            timeframe_length: null,
            timeframe_interval: null,
            start_date: null,
            shared_with: []
        },
        prevReportAutomation: {
            report_preset_id: null,
            repeat_period: null,
            day_of_period: null,
            time: null,
            timeframe: null,
            timeframe_length: null,
            timeframe_interval: null,
            start_date: null,
            shared_with: []
        },
        frequencyOptions: [
            {
                _id: 'day',
                name: 'Day',
            },
            {
                _id: 'week',
                name: 'Week',
            },
            {
                _id: 'other_week',
                name: 'Other Week',
            },
            {
                _id: 'month',
                name: 'Month',
            },
            {
                _id: '3_months',
                name: 'Quarter',
            },
        ],
        dayOfWeekOptions: [
            {
                _id: 0,
                name: 'Sunday'
            },
            {
                _id: 1,
                name: 'Monday'
            },
            {
                _id: 2,
                name: 'Tuesday'
            },
            {
                _id: 3,
                name: 'Wednesday'
            },
            {
                _id: 4,
                name: 'Thursday'
            },
            {
                _id: 5,
                name: 'Friday'
            },
            {
                _id: 6,
                name: 'Saturday'
            }
        ],
        timeframeOptions: [
            {
                _id: 'previous',
                name: 'Past',
            },
            {
                _id: 'current',
                name: 'Current',
            },
            {
                _id: 'next',
                name: 'Next',
            },
        ],
        timeframeIntervalOptions: [
            {
                _id: 'days',
                name: 'Days',
            },
            {
                _id: 'weeks',
                name: 'Weeks',
            },
            {
                _id: 'months',
                name: 'Months',
            },
        ],
        timeframeNumberKey: 1111,
        shareableUsersKey: 2222,
        startDateKey: 3333,
        reportPresetsLoading: false,
        reportPresets: [],
        initialShareableUsers: [],
        isFetchingDetails: false,
        showRemoveAutomationModal: false,
        isCreatingOrUpdating: false,
        innerWidth: window.innerWidth,
        api: new formHelper(),
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        reportAutomationFormRef() {
            return this.$refs?.reportAutomationFormRef
        },
        dayPeriodType() {
            const period = this.reportAutomation.repeat_period
            if (period === 'week' || period === 'other_week') {
                return 'weekly'
            } else if (period === 'month' || period === '3_months') {
                return 'monthly'
            } else {
                return null
            }
        },
        timeframeNumberMin() {
            return 1
        },
        timeframeNumberMax() {
            if (this.reportAutomation.timeframe_interval === 'days') {
                return 365
            } else if (this.reportAutomation.timeframe_interval === 'weeks') {
                return 52
            } else if (this.reportAutomation.timeframe_interval === 'months') {
                return 12
            } else {
                return 0
            }
        },
        startDateTextFieldProps() {
            let value = null
            if (this.reportAutomation.start_date) {
                const startMoment = this.genMoment(this.reportAutomation.start_date)
                value = startMoment.format('MM/DD/YY')
            }
            return {
                label: this.startDateLabel,
                class: 'pt-0 mt-0',
                outlined: true,
                loading: this.isFetchingDetails,
                disabled: this.isStartDateDisabled || this.isFetchingDetails,
                'error-messages': this.api.hasError('start_date'),
                value,
            }
        },
        startDateLabel() {
            if (!this.reportAutomation.repeat_period) {
                return 'Start Date (Please select frequency first)'
            }
            if (this.dayPeriodType === 'weekly' || this.dayPeriodType === 'monthly') {
                if (typeof this.reportAutomation.day_of_period !== 'number') {
                    if (this.dayPeriodType === 'weekly') {
                        return 'Start Date (Please select day of week first)'
                    }
                    return 'Start Date (Please select day of month first)'
                }
            }
            return 'Start Date'
        },
        isStartDateDisabled() {
            if (!this.reportAutomation.repeat_period) {
                return true
            }
            if (this.dayPeriodType === 'weekly' || this.dayPeriodType === 'monthly') {
                return typeof this.reportAutomation.day_of_period !== 'number'
            }
            return false
        },
        startDateCalendarProps() {
            return {
                min: this.genMoment(moment().format(this.startDateFormat))
                    .add(2, 'hours')
                    .format(this.startDateFormat),
            }
        },
        startDateFormat() {
            return 'YYYY-MM-DD'
        },
        allowedStartDates() {
            return date => {
                if (typeof this.reportAutomation.day_of_period === 'number' && this.dayPeriodType === 'weekly') {
                    return moment(date, 'YYYY-MM-DD').day() === this.reportAutomation.day_of_period
                } else if (typeof this.reportAutomation.day_of_period === 'number' && this.dayPeriodType === 'monthly') {
                    const currDate = moment(date, 'YYYY-MM-DD')
                    const endOfMonthDate = currDate.clone().endOf('month')
                    if (this.reportAutomation.day_of_period > endOfMonthDate.date()) {
                        return currDate.date() === endOfMonthDate.date()
                    }
                    return currDate.date() === this.reportAutomation.day_of_period
                }
                return true
            }
        },
        timezone() {
            const location = this.$root.globalLocation
            if (location) {
                return location.timezone
            }
            return 'America/New_York'
        },
        lastRunDate() {
            if (this.reportAutomation?.last_run) {
                return moment(this.reportAutomation.last_run).tz(this.reportAutomation.timezone).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        nextRunDate() {
            if (this.reportAutomation?.next_run) {
                return moment(this.reportAutomation.next_run).tz(this.reportAutomation.timezone).format('MM/DD/YYYY')
            }
            return 'N/A'
        },
        isDirty() {
            return !_.isEqual(this.reportAutomation, this.prevReportAutomation)
        }
    },
    methods: {
        onInputPresetName(report_preset_id) {
            this.reportAutomation = {
                ...this.reportAutomation,
                report_preset_id,
            }
        },
        onInputFrequency(repeat_period) {
            this.reportAutomation = {
                ...this.reportAutomation,
                repeat_period,
            }
        },
        onInputDayPeriod(day_of_period) {
            this.reportAutomation = {
                ...this.reportAutomation,
                day_of_period,
            }
        },
        onInputTime(time) {
            this.reportAutomation = {
                ...this.reportAutomation,
                time,
            }
        },
        onInputTimeframe(timeframe) {
            this.reportAutomation = {
                ...this.reportAutomation,
                timeframe,
            }

            if (timeframe === 'current') {
                this.onInputTimeframeNumber(1)
            }
        },
        onInputTimeframeNumber(timeframe_length) {
            this.reportAutomation = {
                ...this.reportAutomation,
                timeframe_length,
            }
            if (timeframe_length === 1) {
                this.timeframeIntervalOptions = [
                    {
                        _id: 'days',
                        name: 'Day',
                    },
                    {
                        _id: 'weeks',
                        name: 'Week',
                    },
                    {
                        _id: 'months',
                        name: 'Month',
                    },
                ]
            } else {
                this.timeframeIntervalOptions = [
                    {
                        _id: 'days',
                        name: 'Days',
                    },
                    {
                        _id: 'weeks',
                        name: 'Weeks',
                    },
                    {
                        _id: 'months',
                        name: 'Months',
                    },
                ]
            }
        },
        onInputTimeframeInterval(timeframe_interval) {
            this.reportAutomation = {
                ...this.reportAutomation,
                timeframe_interval,
            }
        },
        onInputStartDate(start_date) {
            this.reportAutomation = {
                ...this.reportAutomation,
                start_date,
            }
        },
        onInputShareableUsers(shared_with) {
            this.reportAutomation = {
                ...this.reportAutomation,
                shared_with,
            }
        },
        onResize() {
            this.innerWidth = window.innerWidth
        },
        getAvailableReportPresets() {
            this.reportPresetsLoading = true
            this.api.post('/report-preset/list-for-automation').then(data => {
                if (Array.isArray(data?.data)) {
                    this.reportPresets = Array.from(data.data)
                }
            }).finally(() => {
                this.reportPresetsLoading = false
            })
        },
        getReportAutomationDetails() {
            this.isFetchingDetails = true
            this.api.get(`/report-automation/${this.reportAutomationId}`)
                .then(data => {
                    if (data.data) {
                        this.reportAutomation = {
                            ...data.data,
                            start_date: moment(data.data.start_date).tz(data.data.timezone).format('YYYY-MM-DD')
                        }
                        this.startDateKey = this.startDateKey + 1
                        this.prevReportAutomation = {...this.reportAutomation}
                        this.initialShareableUsers = Array.isArray(this.reportAutomation.shared_users) ? this.reportAutomation.shared_users : []
                        this.shareableUsersKey = this.shareableUsersKey + 1
                    }
                })
                .catch(console.log).finally(() => {
                this.isFetchingDetails = false
            })
        },
        onChangeTimeframeInterval() {
            this.timeframeNumberKey = this.timeframeNumberKey + 1
        },
        onChangeFrequency() {
            this.reportAutomation = {
                ...this.reportAutomation,
                day_of_period: null,
                start_date: null,
            }
        },
        onChangeDayPeriod() {
            this.reportAutomation = {
                ...this.reportAutomation,
                start_date: null,
            }
        },
        onDelete() {
            this.showRemoveAutomationModal = true
        },
        onRemoveReportAutomation() {
            this.modalValue = false
            this.$emit('onSave')
        },
        onSave() {
            if (this.reportAutomationFormRef?.validate()) {
                this.isCreatingOrUpdating = true
                this.api.post(this.reportAutomationId ? `/report-automation/update/${this.reportAutomationId}` : '/report-automation/create', {
                    ...this.reportAutomation,
                    timezone: this.timezone,
                }).then(({message}) => {
                    message(this.reportAutomationId ? 'Report automation updated.' : 'Report automation created.')
                    this.modalValue = false
                    this.$emit('onSave')
                }).catch(console.log).finally(() => {
                    this.isCreatingOrUpdating = false
                })
            }
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.modalValue = false
                })
                .catch(() => {
                })
        },
        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }
            this.modalValue = false
        },
        genMoment(date) {
            return moment(date, 'YYYY-MM-DD')
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
        this.getAvailableReportPresets()
        if (this.presetId) {
            this.reportAutomation = {
                ...this.reportAutomation,
                report_preset_id: this.presetId,
            }
            this.prevReportAutomation = {
                ...this.prevReportAutomation,
                report_preset_id: this.presetId,
            }
        }
        if (this.reportAutomationId) {
            this.getReportAutomationDetails()
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>

</style>
