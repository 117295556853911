<template>
    <div class="staff-group-modal">
        <v-dialog
            v-model="show_modal"
            scrollable
            max-width="550px"
            persistent
            content-class="ds-dialog"
        >

            <v-card class="ds-modal-card" elevation="2" dusk="staffGroupModal">
                <modal-toolbar :title="editing_existing_staff_group ? 'Edit Staff Group' : 'Add Staff Group'" @close="onClose"/>

                <v-card-text class="ds-modal-content">
                    <v-form
                        ref="staffGroupForm"
                        v-model="form_is_valid"
                        :lazy-validation="false"
                        class="pt-2"
                    >
                        <v-row>
                            <div v-show="show_loading_overlay" style="height:100%">
                                <loading-spinner :value="true"></loading-spinner>
                            </div>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="staff-group-name"
                                    label="Staff Group Name"
                                    v-model.trim="staff_group.name"
                                    :error-messages="api.hasError('name')"
                                    :rules="rules.required"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="description"
                                    label="Brief Description (Optional)"
                                    v-model.trim="staff_group.description"
                                    :error-messages="api.hasError('description')"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>
                        </v-row>

                        <v-row class="mt-0">
                            <v-col class="text-right">
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="save"
                                    :loading="api.busy"
                                    :disabled="isSaveDisabled"
                                >
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>

        </v-dialog>

        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </div>
</template>

<script>
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";
import ModalToolbar from "../../../components/modals/components/ModalToolbar";
import LoadingSpinner from "../../../components/loaders/LoadingSpinner";
import validationRules from "../../../lib/mixins/validationRules";

export default {
    name: "StaffGroupModal",
    components: {LoadingSpinner, ModalToolbar, UnsavedChangesDialog},
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const user_can_edit = this.$userHasPermission(this.$user, this.$config.permissions.STAFF_GROUPS.EDIT)

        return {
            resolve: null,
            reject: null,
            show_modal: this.value,
            api: new formHelper(),
            editing_existing_staff_group: false,
            show_loading_overlay: false,
            form_is_valid: false,
            staff_group: {},
            prev_staff_group: {},
            editable: user_can_edit,
        }
    },
    methods: {
        open({staff_group_id = null, location_id = null}) {
            if (staff_group_id) {
                this.editing_existing_staff_group = true
                this.loadExistingStaffGroup(staff_group_id)
            } else {
                this.staff_group.location_id = location_id
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
                this.show_modal = true
            })
        },

        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },

        closeModal() {
            this.show_modal = false
            this.resetModal()
        },

        resetModal() {
            this.editing_existing_staff_group = false
            this.form_is_valid = false
            this.staff_group = {}
            this.prev_staff_group = {}
            this.$refs.staffGroupForm.resetValidation()
        },

        staffGroupCreated(staff_group) {
            this.$snackNotify('success', 'New Staff Group created.')
            this.resolve({action: 'created', staff_group: staff_group})
            this.closeModal()
        },

        staffGroupUpdated(staff_group) {
            this.$snackNotify('success', 'Changes to Staff Group saved.')
            this.resolve({action: 'updated'})
            this.closeModal()
        },

        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {})
        },

        loadExistingStaffGroup(staff_group_id) {
            this.show_loading_overlay =  true
            this.api.get('/staff-group/' + staff_group_id, {params: {basic_details: true}})
                .then(({data}) => {
                    this.staff_group = data.staff_group
                    this.prev_staff_group = {...data.staff_group}
                })
                .finally(() => {
                    this.show_loading_overlay = false
                })
        },

        save() {
            if (!this.$refs.staffGroupForm.validate()) {
                return false
            }

            this.api.post('/save-staff-group', this.staff_group)
                .then(({data}) => {
                    if (this.editing_existing_staff_group) {
                        this.staffGroupUpdated(data)
                    } else {
                        this.staffGroupCreated(data)
                    }
                })
        },

        getCompareObject(staff_group_object) {
            const { name, description } = staff_group_object

            return { name, description }
        },
    },
    computed: {
        isDirty() {
            return !_.isEqual(this.getCompareObject(this.prev_staff_group), this.getCompareObject(this.staff_group))
        },
        isSaveDisabled() {
            return this.api.busy || !this.editable || !this.isDirty
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.show_modal = newVal
            }
        },

        show_modal: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.$emit('input', false)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
