<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap row>
            <v-flex md12>
                <ReportsWindow></ReportsWindow>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import ReportsWindow from "../../components/reporting/Reports/ReportsWindow";
export default {
    name: "index",
    components: { ReportsWindow },
}
</script>

<style scoped>

</style>
