<template>
    <div>
        <template v-if="userChip">
            <v-avatar :color="computedColor" :size="size">
                <img v-if="avatar" :style="imgStyle" :src="avatar" :alt="user | initials">
                <span v-else class="white--text" :class="[ (size > 40) ? 'headline' : 'body-2' ]">
                {{ user | initials }}
            </span>
            </v-avatar>
        </template>

        <template v-else-if="chip">
            <v-chip pill class="clickable" style="background: transparent !important;">
                <v-avatar left :color="computedColor">
                    <img v-if="avatar" :style="imgStyle" :src="avatar" :alt="user | initials">
                    <span v-else class="white--text body-2">{{ user | initials }}</span>
                </v-avatar>
                <span class="text--paragraph">{{ user.last_name + ', ' + user.first_name }}</span>
            </v-chip>
        </template>

        <template v-else>
            <div class="custom-avatar-container">
                <v-avatar class="custom-avatar-element ma-auto" :color="computedColor" :size="size" >
                    <img v-if="avatar" :style="imgStyle" :src="avatar" :alt="user | initials">
                    <div v-else class="custom-avatar-initials" :class="[ (size > 40) ? 'headline' : 'body-2' ]">
                        {{ user | initials }}
                    </div>
                </v-avatar>

                <div v-if="canEdit">
                    <span class="custom-avatar-overlay-buttons">
                        <v-btn v-if="user_has_avatar" color="error" icon plain @click="deleteButtonClicked">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <div class="custom-avatar-spacer"></div>
                        <v-btn color="primary" icon plain @click="uploadButtonClicked">
                            <v-icon>mdi-image-plus</v-icon>
                        </v-btn>
                    </span>
                </div>
            </div>

        </template>

        <update-avatar-modal
            v-model="showUpdateModal"
            :user="user"
            @avatarUpdated="avatarUpdated"
        ></update-avatar-modal>

        <remove-avatar-modal
            v-model="showDeleteModal"
            :user="user"
            @avatarRemoved="avatarRemoved"
        ></remove-avatar-modal>

    </div>
</template>

<script>
    import UpdateAvatarModal from "./UpdateAvatarModal";
    import RemoveAvatarModal from "./RemoveAvatarModal";
    export default {
        name: "CustomAvatar",
        components: {RemoveAvatarModal, UpdateAvatarModal},
        props: {
            user: {
                default: () => {},
                type: Object
            },
            size: {
                default: 64,
                type: Number
            },
            editable: {
                default: false,
                type: Boolean
            },
            chip: {
                default: false,
                type: Boolean
            },
            color: {
                default: null,
                type: String
            },
            userChip: {
                default: false,
                type: Boolean,
            }
        },

        data() {
            return {
                colors: ['primary'],
                showUpdateModal: false,
                showDeleteModal: false,
                avatar: this.user.avatar || null
            }
        },

        computed: {
            computedColor() {
                return this.color || this.colors[Math.floor(Math.random() * this.colors.length)];
            },

            imgStyle() {
                return {
                    'line-height': this.size + 'px'
                }
            },

            user_has_avatar() {
                return typeof this.user.avatar !== 'undefined' && !!this.user.avatar
            },

            is_own_profile() {
                return this.$user._id === this.user._id
            },

            is_new_user() {
                return typeof this.user._id === 'undefined' || !this.user._id
            },

            canEdit() {
                const PERMISSIONS = this.$config.permissions
                return this.editable && !this.is_new_user && !this.$isImpersonating &&
                    (this.is_own_profile || this.$userHasPermission(this.$user, PERMISSIONS.USERS.EDIT_OTHERS_PROFILE))
            }
        },

        methods: {
            uploadButtonClicked() {
                this.showUpdateModal = true;
            },

            deleteButtonClicked() {
                this.showDeleteModal = true;
            },

            avatarUpdated(avatar) {
                this.avatar = avatar
                this.$emit('avatarUpdated', avatar)
            },

            avatarRemoved() {
                this.avatar = null
                this.$emit('avatarRemoved')
            }
        },
    }
</script>

<style scoped>
    .custom-avatar-container {
        position: relative;
    }

    .custom-avatar-element {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .custom-avatar-overlay-buttons {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }

    .custom-avatar-container:hover .custom-avatar-element {
        opacity: 0.3;
    }

    .custom-avatar-container:hover .custom-avatar-overlay-buttons {
        opacity: 1;
    }

    .custom-avatar-spacer {
        width: 5px;
        height: 5px;
        display: inline-block;
    }

    .custom-avatar-initials {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }

    .clickable:hover {
        cursor: pointer;
    }
</style>
