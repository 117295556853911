<template>
    <time-picker-dialog
        v-model="time"
        :text-field-props="textFieldProps"
        :time-picker-props="timePickerProps"
        :required="!disabled"
    ></time-picker-dialog>
</template>

<script>
import TimePickerDialog from "../../../../../../components/modals/TimePickerDialog";

export default {
    name: "TimePicker",
    components: { TimePickerDialog },
    props: {
        value: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
        hideRequiredClass: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        time: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        minExists() {
            return this.strExists(this.min)
        },
        maxExists() {
            return this.strExists(this.max)
        },
        timePickerProps() {
            return {
                ...this.minExists  && { min: this.min },
                ...this.maxExists && { max: this.max },
            }
        },
        textFieldProps() {
            return {
                label: this.label,
                ...!this.disabled && !this.hideRequiredClass && { class: 'required' },
                value: this.formattedTime,
                outlined: true,
                loading: this.loading,
                disabled: this.disabled,
            }
        },
        formattedTime() {
            const { time } = this
            if(time) {
                const now = moment().format('YYYY-MM-DD')
                return moment.tz(`${now} ${time}`, this.globalTimezone).format('h:mm A')
            }
            return null
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        strExists(value) {
            return !!value && value.trim().length > 0
        }
    }
}
</script>

<style scoped>

</style>
