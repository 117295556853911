<template>
    <v-sheet elevation="15" class="sheet-container">
        <div class="time-container">
            <time-circle
                v-for="day in days"
                :key="day"
                :initial="day"
                :selected="day === dayOfMonth"
                @onClick="dayOfMonth = day"
            ></time-circle>
        </div>
    </v-sheet>
</template>

<script>
import TimeCircle from "../../scheduling/Forms/RecurrenceSettings/TimeCircle";

export default {
    name: "DayOfMonthContent",
    components: {TimeCircle},
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data: () => ({
        days: _.range(31).map(index => index + 1),
    }),
    computed: {
        dayOfMonth: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    }
}
</script>

<style scoped>
.sheet-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.time-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2px;
    column-gap: 5px;
    padding: 12px 12px 8px 16px;
}
</style>
