<template>
    <v-data-table
        :height="tableHeight"
        :headers="headers"
        :items="items"
        :loading="itemsLoading"
        :options.sync="options"
        @update:options="onOptionsUpdate"
        disable-pagination
        fixed-header
        hide-default-footer
        :server-items-length="itemsCount"
    >
        <template v-slot:item.status="{ header, item }">
            {{ item.status | capitalize }}
        </template>

        <template v-slot:item.employee_name="{ header, item }">
            {{ formatEmployeeName(item.employee_name) }}
        </template>

        <template v-slot:item.staff_member="{ header, item }">
            {{ formatEmployeeName(item.staff_member) }}
        </template>

        <template v-slot:item.staff_groups="{ header, item }">
            {{ formatArr(item.staff_groups) }}
        </template>

        <template v-slot:item.job_titles="{ header, item }">
            {{ formatArr(item.job_titles) }}
        </template>

        <template v-slot:item.requirements="{ header, item }">
            {{ formatCredentials(item.requirements) }}
        </template>

        <template v-slot:item.shift_time="{ header, item }">
            {{ formatShiftTime(item.shift_time) }}
        </template>

        <template v-slot:item.start="{ header, item }">
            {{ formatDisplayProperty(header.type, item.start) }}
        </template>

        <template v-slot:item.end="{ header, item }">
            {{ formatDisplayProperty(header.type, item.end) }}
        </template>

        <template v-slot:item.start_date="{ header, item }">
            {{ formatDisplayProperty(header.type, item.start_date) }}
        </template>

        <template v-slot:item.end_date="{ header, item }">
            {{ formatDisplayProperty(header.type, item.end_date) }}
        </template>

        <template v-slot:item.duration="{ header, item }">
            {{ formatDisplayProperty(header.type, item.duration) }}
        </template>

        <template v-slot:item.created_at="{ header, item }">
            {{ formatDisplayProperty(header.type, item.created_at) }}
        </template>

        <template v-slot:item.updated_at="{ header, item }">
            {{ formatDisplayProperty(header.type, item.updated_at) }}
        </template>

        <template v-slot:item.date_sent="{ header, item }">
            {{ formatDisplayProperty(header.type, item.date_sent) }}
        </template>

        <template v-slot:item.last_interaction="{ header, item }">
            {{ formatDisplayProperty(header.type, item.last_interaction) }}
        </template>

        <template v-slot:item.response_date="{ header, item }">
            {{ formatNullableDate(header.type, item.response_date) }}
        </template>

        <template v-slot:item.cancelled_at="{ header, item }">
            {{ formatNullableDate(header.type, item.cancelled_at) }}
        </template>

        <template v-slot:item.no_show="{ header, item }">
            {{ item.no_show ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.call_out="{ header, item }">
            {{ item.call_out ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.cancellation_requested="{ header, item }">
            {{ item.cancellation_requested ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.payroll_period_dates="{ header, item }">
            {{ formatPayrollPeriodDates(item.payroll_period_dates) }}
        </template>
    </v-data-table>
</template>

<script>
import { parseReportCredentials } from '../../../lib/helpers/reports'
import { formatEmployeeName, formatDisplayProperty } from '../../../lib/helpers/reports'

export default {
    name: "ReportDetailsTable",
    props: {
        report: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        headers: [],
        items: [],
        options: {},
        itemsLoading: false,
        itemsCount: 0,
        api: new formHelper(),
    }),
    computed: {
        globalLocation() {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        tableHeight() {
            return window.innerHeight - 277
        }
    },
    methods: {
        loadData() {
            this.itemsLoading = true
            this.api.get(`/reports/details/${this.report._id}`).then(({ data }) => {
                if(data.report) {
                    this.items = data.report.data
                    this.itemsCount = this.items.length
                }
                if(Array.isArray(data.ordered_columns)) {
                    this.headers = data.ordered_columns.map(({ title, column_name, type }) => ({
                        text: title,
                        value: column_name,
                        type,
                        ...data.ordered_columns.length > 6 && { width : '190px' }
                    }))
                }
            }).catch(() => {}).finally(() => { this.itemsLoading = false })
        },
        formatDisplayProperty(header_type, value) {
            return formatDisplayProperty(header_type, value, this.globalTimezone)
        },
        formatNullableDate(header_type, value) {
            if (value) {
                return formatDisplayProperty(header_type, value, this.globalTimezone)
            }
            return 'N/A'
        },
        onOptionsUpdate() {
            const { sortBy, sortDesc } = this.options
            if(Array.isArray(this.items)) {
                if(this.items.length > 0) {
                    if(Array.isArray(sortBy)) {
                        if(sortBy.length > 0) {
                            const sortByArg = sortBy[0] === 'employee_name' ? 'employee_name.first_name' : sortBy[0]
                            const sortedAsc = _.sortBy(this.items, sortByArg)
                            if(Array.isArray(sortDesc)) {
                                if(sortDesc.length > 0) {
                                    if(sortDesc[0]) {
                                        this.items = _.reverse(sortedAsc)
                                    } else {
                                        this.items = Array.from(sortedAsc)
                                    }
                                } else {
                                    this.items = _.reverse(sortedAsc)
                                }
                            } else {
                                this.items = _.reverse(sortedAsc)
                            }
                        }
                    }
                }
            }
        },
        formatEmployeeName(value) {
            return formatEmployeeName(value)
        },
        formatShiftTime (shiftTime) {
            if (shiftTime) {
                return shiftTime
            }
            return 'N/A'
        },
        formatCredentials (credentials) {
            return parseReportCredentials({
                filters: {
                    requirements: credentials ? credentials.map(name => ({ name })) : [],
                }
            })
        },
        formatArr(values) {
            if (Array.isArray(values) && values.length > 0) {
                return values.join(', ')
            }
            return 'N/A'
        },
        formatPayrollPeriodDates(payrollPeriodDates) {
            if (payrollPeriodDates) {
                const {start,end,timezone} = payrollPeriodDates
                if (start && end && timezone) {
                    return [
                        moment(start).tz(timezone).format('MM/DD/YYYY'),
                        moment(end).tz(timezone).format('MM/DD/YYYY'),
                    ].join(' - ')
                }
            }
            return 'N/A'
        }
    },
    mounted() {
        this.loadData()
    }
}
</script>

<style scoped>

</style>
