<template>
    <div>
        <dashboard-header
            :disabled="loading"
            @onChange="onRangeOrTimezoneChange">
        </dashboard-header>
        <v-window v-model="currWindow" :key="windowKey">
            <v-window-item value="locations">
                <locations-view
                    :loading="loading"
                    :locations="orderedLocations"
                    :is-monthly-view="isMonthlyView"
                    @onViewDepartments="onViewDepartments"
                    @onUpdateLocation="onUpdateLocation"
                    @onSortByChange="onSortByLocationsChange">
                </locations-view>
            </v-window-item>
            <v-window-item value="location">
                <location-view
                    :location="selectedLocation"
                    :is-monthly-view="isMonthlyView"
                    :is-single-location="isSingleLocation"
                    :loading="selectedLocation.loading"
                    @onViewLocations="onViewLocations"
                    @onUpdateLocation="onUpdateSingleLocation"
                    @onSortByChange="onSortByDepartmentsChange">
                </location-view>
            </v-window-item>
        </v-window>
    </div>
</template>

<script>
import DashboardHeader from "../misc/DashboardHeader";
import LocationsView from "./LocationsView";
import LocationView from "./LocationView";
export default {
    name: "DashboardView",
    components: { DashboardHeader, LocationsView, LocationView },
    data: () => ({
        currWindow: 'locations',
        windowKey: 9999,
        loading: false,
        locations: [],
        selectedLocation: {},
        isMonthlyView: false,
        isSingleLocation: false,
        range: null,
        locationsSortBy: null,
        api: new formHelper(),
    }),
    computed: {
        orderedLocations() {
            return this.sortItems(this.locations, this.locationsSortBy)
        }
    },
    methods: {
        onRangeOrTimezoneChange(range, isMonthlyView) {
            this.range = range
            this.isMonthlyView = isMonthlyView
            const fetchLocations = () => {
                this.windowKey = this.windowKey + 1
                this.fetchLocations(range, () => {
                    this.loading = true
                }, locations => {
                    this.locations = Array.from(locations)
                    if(this.locations.length <= 1) {
                        this.setSingleLocation()
                    }
                }, () => {
                    this.loading = false
                })
            }
            const fetchLocation = () => {
                this.fetchLocations(range, () => {
                    this.loading = true
                    this.selectedLocation = {
                        ...this.selectedLocation,
                        loading: true,
                    }
                }, locations => {
                    this.locations = Array.from(locations)
                    if(this.locations.length <= 1) {
                        this.setSingleLocation()
                    } else {
                        const location = this.locations.find(loc => loc._id === this.selectedLocation._id)
                        if(location) {
                            this.selectedLocation = { ...location }
                        } else {
                            this.currWindow = 'locations'
                            this.windowKey = this.windowKey + 1
                        }
                    }
                }, () => {
                    this.loading = false
                    this.selectedLocation = {
                        ...this.selectedLocation,
                        loading: false,
                    }
                })
            }
            if(this.currWindow === 'locations') {
                fetchLocations()
            } else {
                if(this.selectedLocation && this.selectedLocation._id) {
                    fetchLocation()
                } else {
                    fetchLocations()
                }
            }
        },
        fetchLocations(range, onFetch, onFetchSuccess, onFetchFinished) {
            onFetch()
            this.api.post('/summary-by-locations', {
                range,
                invalidate_cache: false,
            }).then(({ data }) => {
                if(data && Array.isArray(data.location_summaries)) {
                    onFetchSuccess(data.location_summaries)
                }
            }).catch(console.log).finally(onFetchFinished)
        },
        fetchLocation(locationId, range, onFetch, onFetchSuccess, onFetchFinished) {
            onFetch()
            this.api.post('/summary-by-locations', {
                range,
                invalidate_cache: true,
                location_ids: [locationId],
            }).then(({ data }) => {
                if(data && Array.isArray(data.location_summaries)) {
                    const { location_summaries } = data
                    if(location_summaries.length > 0) {
                        onFetchSuccess(location_summaries[0])
                    }
                }
            }).catch(console.log).finally(onFetchFinished)
        },
        onViewDepartments(locationId) {
            const location = this.locations.find(loc => loc._id === locationId)
            if(location) {
                this.selectedLocation = {...location}
                window.scrollTo(0,0)
                this.currWindow = 'location'
            }
        },
        onViewLocations() {
            this.currWindow = 'locations'
        },
        onUpdateLocation(locationId) {
            if(locationId) {
                this.fetchLocation(locationId, this.range, () => {
                    this.toggleLocation(locationId, true)
                }, location => {
                    this.locations = this.updateLocation(this.locations, locationId, location)
                }, () => {
                    this.toggleLocation(locationId, false)
                })
            }
        },
        onUpdateSingleLocation(locationId) {
            const { selectedLocation } = this
            if(selectedLocation && locationId && selectedLocation._id === locationId) {
                this.fetchLocation(locationId, this.range, () => {
                    this.selectedLocation = {
                        ...this.selectedLocation,
                        loading: true,
                    }
                }, location => {
                    this.locations = this.updateLocation(this.locations, locationId, location)
                    this.selectedLocation = { ...location }
                }, () => {
                    this.selectedLocation = {
                        ...this.selectedLocation,
                        loading: false,
                    }
                })
            }
        },
        onSortByDepartmentsChange(sortBy) {
            if(sortBy && this.selectedLocation && Array.isArray(this.selectedLocation.departments_summary)) {
                this.selectedLocation = {
                    ...this.selectedLocation,
                    departments_summary: this.sortItems(this.selectedLocation.departments_summary, sortBy)
                }
            }
        },
        onSortByLocationsChange(sortBy) {
            this.locationsSortBy = sortBy
        },
        toggleLocation(locationId, loading) {
            this.locations = this.updateLocation(this.locations, locationId, { loading })
        },
        setSingleLocation() {
            this.currWindow = 'location'
            this.selectedLocation = {...this.locations[0]}
            this.isSingleLocation = true
            this.windowKey = this.windowKey + 1
        },
        updateLocation(locations, locationId, updateObj) {
            if(locationId && Array.isArray(locations)) {
                return locations.map(location => {
                    if(location._id === locationId) {
                        return {
                            ...location,
                            ...updateObj,
                        }
                    }
                    return location
                })
            }
            return locations
        },
        sortByName(a, b, desc) {
            const result = a.name.replace(/\s/g,'').toLowerCase().localeCompare(b.name.replace(/\s/g,'').toLowerCase())
            return desc ? result * -1 : result
        },
        sortItems(items, sortBy) {
            if(sortBy) {
                const parts = sortBy.split('-')
                if(parts.length > 2) {
                    const [firstSortBy, secondSortBy, orderBy] = sortBy.split('-')
                    return Array.from(items).sort((a, b) => {
                        const firstCond = _.get(a, firstSortBy, 0) - _.get(b, firstSortBy, 0)
                        const secondCond = _.get(b, secondSortBy, 0) - _.get(a, secondSortBy, 0)
                        return (orderBy === 'desc' ? firstCond * -1 : firstCond) || secondCond
                    })
                } else if(parts.length > 1) {
                    const [sortByParam, orderByParam] = sortBy.split('-')
                    if(sortByParam === 'name') {
                        return Array.from(items).sort((a, b) => {
                            return this.sortByName(a, b, orderByParam === 'desc')
                        })
                    }
                    return items
                } else {
                    return items
                }
            }
            return items
        }
    }
}
</script>

<style scoped>

</style>
