<template>
    <v-row>
        <v-col cols="12" md="7" class="py-0">
            <v-text-field
                name="job-title"
                label="Job Title"
                v-model.trim="title"
                outlined
                :clearable="has_edit_job_titles_permission"
                required
                :rules="rules.required"
                :readonly="!has_edit_job_titles_permission"
            />
        </v-col>

        <v-col cols="8" md="3" class="py-0">
            <time-input
                label="Max. Shift Duration"
                v-model="max_shift_duration"
                placeholder="HH:MM"
                :min="60"
                :max="2160"
                :readonly="!has_edit_job_titles_permission"
                controls
                required
            />
        </v-col>

        <v-col cols="4" md="2">
            <v-btn
                small
                icon
                color="primary"
                @click="onDelete"
                :loading="deleting_in_progress"
                :disabled="!has_edit_job_titles_permission || api.busy"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-slide-y-transition>
                <v-btn
                    v-if="save_enabled"
                    small
                    icon
                    color="primary"
                    @click="onSave"
                    :loading="saving_in_progress"
                    :disabled="!has_edit_job_titles_permission || api.busy"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-slide-y-transition>
        </v-col>

        <v-divider class="d-md-none my-4"/>

        <confirmation-dialog ref="confirmationDialog"/>
    </v-row>
</template>

<script>
import TimeInput from "../../../../components/common/TimeInput/TimeInput";
import validationRules from "../../../../lib/mixins/validationRules";
import ConfirmationDialog from "../../../../components/modals/ConfirmationDialog";

export default {
    name: "JobTitle",
    components: {ConfirmationDialog, TimeInput},
    mixins: [validationRules],
    props: {
        jobTitle: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            api: new formHelper,
            title: this.jobTitle.title,
            max_shift_duration: this.jobTitle.max_shift_duration,
            deleting_in_progress: false,
            saving_in_progress: false,
        }
    },
    methods: {
        isNewJobTitle() {
            return typeof this.jobTitle._id === 'undefined' || !this.jobTitle._id
        },
        onSave() {
            const job_title_data = {
                ...this.jobTitle,
                title: this.title,
                max_shift_duration: this.max_shift_duration
            }

            this.saving_in_progress = true
            this.api.post('/job-title', job_title_data)
                .then(({data}) => {
                    const job_title = {
                        _id: data._id,
                        title: data.title,
                        max_shift_duration: data.max_shift_duration,
                        staff_group_id: data.staff_group_id
                    }

                    if (this.isNewJobTitle()) {
                        this.$snackNotify('success', 'New Job Title created.')
                    } else {
                        this.$snackNotify('success', 'Changes to Job Title saved.')
                    }

                    this.$emit('saved', {job_title: job_title})

                    this.saving_in_progress = false
                })
        },
        onDelete() {
            if (this.isNewJobTitle()) {
                this.$emit('deleted')
                this.deleting_in_progress = false
                return
            }

            const title = this.hasTitle ? this.title : 'this job title'

            const dialog_options = {
                title: 'Delete Job Title',
                message: `
                  You are about to delete <strong>${title}</strong>. Would you like to proceed?
                  <br><br>Deleting this Job Title will remove it from Staffing Details in all Departments and any ongoing
                  Workforce Automations.
                `
            }

            this.$refs.confirmationDialog.open(dialog_options)
                .then(() => {
                    this.deleting_in_progress = true
                    this.api.delete('/job-title/' + this.jobTitle._id)
                        .then(({data}) => {
                            if (typeof data.has_upcoming_shifts !== 'undefined' && data.has_upcoming_shifts) {
                                this.deleting_in_progress = false

                                const dialog_options = {
                                    title: 'Delete Job Title',
                                    message: `This Job Title can't be deleted as it currently has scheduled shifts.`,
                                    confirmText: 'Close',
                                    showCancel: false
                                }

                                this.$refs.confirmationDialog.open(dialog_options)
                                    .then(() => {})
                                    .catch(() => {})

                                return
                            }

                            this.$emit('deleted', {job_title: this.jobTitle})
                            this.$snackNotify('warning', this.hasTitle ?
                                `${this.title} Job Title deleted.` : 'Job Title deleted.')
                            this.deleting_in_progress = false
                        })
                }).catch(() => {
            })
        },
    },
    computed: {
        unsaved_changes() {
            return this.isNewJobTitle() ||
                (this.title !== this.jobTitle.title) ||
                (this.max_shift_duration !== this.jobTitle.max_shift_duration)
        },
        has_edit_job_titles_permission() {
            return this.$userHasPermission(this.$user, this.$config.permissions.JOB_TITLES.EDIT)
        },
        hasTitle() {
            return !!this.title && this.title.trim().length > 0
        },
        save_enabled() {
            if (!this.has_edit_job_titles_permission) {
                return false
            }

            return Boolean(this.unsaved_changes && this.hasTitle && this.max_shift_duration)
        },
    },

    watch: {}
}
</script>

<style scoped>

</style>
