<template>
    <filters-picker :disabled="disabled" @onOpen="onOpen" ref="filtersPicker">
        <template v-slot:filters>
            <v-card :width="500" class="pa-3" elevation="2">
                <v-container fluid>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedStaffGroupIds"
                                label="Staff Group"
                                no-data-text="No active Staff Groups in Department"
                                item-value="_id"
                                item-text="name"
                                :items="staffGroups"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="staffGroupsSearchInput"
                                @change="staffGroupsSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-account-group</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedJobTitleIds"
                                label="Job Title"
                                no-data-text="No active Job Titles in Department"
                                item-value="_id"
                                item-text="title"
                                :items="jobTitles"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="jobTitlesSearchInput"
                                @change="jobTitlesSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-clipboard-account</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedShiftTimeIds"
                                label="Shift Time"
                                no-data-text="No Shift Times connected to Location"
                                item-value="_id"
                                item-text="label"
                                :items="shiftTimes"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="shiftTimesSearchInput"
                                @change="shiftTimesSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-clock-outline</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedCredentialIds"
                                label="Credential"
                                :no-data-text="Array.isArray(selectedStaffGroupIds) && selectedStaffGroupIds.length > 0 ? 'No Credentials connected to Staff Group' : 'No Credentials connected to Location'"
                                item-value="_id"
                                item-text="name"
                                :items="credentials"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="credentialsSearchInput"
                                @change="credentialsSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-license</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <v-btn
                                color="primary"
                                outlined
                                :disabled="filtersDisabled"
                                @click="onApplyFilters"
                            >
                                Apply Filters
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </template>
    </filters-picker>
</template>

<script>
import FiltersPicker from '../Inputs/FiltersPicker'

export default {
    name: "DepartmentFilters",
    components: { FiltersPicker },
    props: {
        departmentId: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        filters: {},
        shouldFetch: false,
        staffGroupsSearchInput: '',
        jobTitlesSearchInput: '',
        shiftTimesSearchInput: '',
        credentialsSearchInput: '',
        api: new formHelper(),
    }),
    computed: {
        selectedValues: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFilters
            },
            set (filters) {
                this.dispatchSchedulerSetDepartmentFilters(filters)
            },
        },
        selectedStaffGroupIds: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFilters.selectedStaffGroupIds
            },
            set (selectedStaffGroupIds) {
                this.dispatchSchedulerSetDepartmentFilters({
                    ...this.selectedValues,
                    selectedStaffGroupIds,
                })
            }
        },
        selectedJobTitleIds: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFilters.selectedJobTitleIds
            },
            set (selectedJobTitleIds) {
                this.dispatchSchedulerSetDepartmentFilters({
                    ...this.selectedValues,
                    selectedJobTitleIds,
                })
            }
        },
        selectedShiftTimeIds: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFilters.selectedShiftTimeIds
            },
            set (selectedShiftTimeIds) {
                this.dispatchSchedulerSetDepartmentFilters({
                    ...this.selectedValues,
                    selectedShiftTimeIds,
                })
            }
        },
        selectedCredentialIds: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFilters.selectedCredentialIds
            },
            set (selectedCredentialIds) {
                this.dispatchSchedulerSetDepartmentFilters({
                    ...this.selectedValues,
                    selectedCredentialIds,
                })
            }
        },
        filtersApplied: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFiltersApplied
            },
            set (filtersApplied) {
                this.$store.dispatch('schedulerSetDepartmentFiltersApplied', { filtersApplied })
            },
        },
        staffGroups () {
            return this.filters.staff_groups || []
        },
        jobTitles () {
            return this.filters.job_titles || []
        },
        shiftTimes () {
            return this.filters.shift_times || []
        },
        credentials () {
            return this.filters.requirements || []
        },
        filtersDisabled () {
            return this.api.busy || (
                this.selectedStaffGroupIds.length === 0 &&
                    this.selectedJobTitleIds.length === 0 &&
                    this.selectedShiftTimeIds.length === 0 &&
                    this.selectedCredentialIds.length === 0
            )
        }
    },
    methods: {
        onOpen () {
            if (!this.shouldFetch) {
                this.shouldFetch = true
            }
        },
        onApplyFilters () {
            this.filtersApplied = true
            window.EventBus.$emit('departmentScheduler/fetchEvents')
            this.$refs?.filtersPicker.closePicker()
        },
        clearData () {
            this.selectedValues = {
                selectedStaffGroupIds: [],
                selectedJobTitleIds: [],
                selectedShiftTimeIds: [],
                selectedCredentialIds: [],
            }
            this.filtersApplied = false
            this.shouldFetch = false
        },
        dispatchSchedulerSetDepartmentFilters (filters) {
            this.$store.dispatch('schedulerSetDepartmentFilters', { filters })
        }
    },
    watch: {
        departmentId: {
            immediate: true,
            handler () {
                this.clearData()
            },
        },
        shouldFetch: {
            immediate: true,
            handler (shouldFetch) {
                if (shouldFetch) {
                    const { departmentId } = this
                    if (departmentId) {
                        this.api.get(`/schedules/filters/department/${departmentId}`).then(({ data }) => {
                            if (data) {
                                this.filters = data
                            }
                        }).catch(console.log)
                    }
                }
            }
        }
    },
    created () {
        window.EventBus.$on('scheduler/onClearDepartmentFilters', () => {
            this.dispatchSchedulerSetDepartmentFilters({
                selectedStaffGroupIds: [],
                selectedJobTitleIds: [],
                selectedShiftTimeIds: [],
                selectedCredentialIds: [],
            })
        })
    }
}
</script>

<style scoped>

</style>
