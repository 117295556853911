<template>
    <div>
        <preset-list-loading v-if="presetsLoading"/>
        <slot name="preset-list-item" v-else-if="presetsCount > 0"/>
        <div v-else class="shift-details-presets-no-data text--secondary">
            {{ noDataText }}
        </div>
        <v-pagination
            v-model="page"
            :length="numberOfPages"
            :page="page"
            :total-visible="totalVisible"
            :disabled="presetsLoading || numberOfPages <= 1"
            @input="onChangePagination"
        />
        <slot name="edit-preset-modal"/>
        <slot name="delete-preset-modal"/>
    </div>
</template>

<script>
import PresetListLoading from "./PresetListLoading";

export default {
    name: "PresetList",
    components: { PresetListLoading },
    props: {
        presetsLoading: {
            type: Boolean,
            default: false,
        },
        presetsCount: {
            type: Number,
            default: 0,
        },
        numberOfPages: {
            type: Number,
            default: 1,
        },
        totalVisible: {
            type: Number,
            default: 1,
        },
        value: {
            type: Number,
            default: 1,
        },
        noDataText: {
            type: String,
            default: "No Reports found. Please try again"
        }
    },
    computed: {
        page: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onChangePagination() {
            this.$emit('onChangePagination')
        }
    }
}
</script>

<style scoped>
    .shift-details-presets-no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }
</style>
