<template>
    <location-view>
        <template v-slot:left-location-view>
            <shifts-summary-view
                :loading="loading"
                :location="location"
                :monthly="isMonthlyView"
                @onUpdateLocation="onUpdateLocation">
            </shifts-summary-view>
        </template>
        <template v-slot:right-location-view>
            <shift-offers-summary-view
                :loading="loading"
                :location="location"
                :monthly="isMonthlyView"
                :is-single-view="isSingleView"
                :is-single-location="isSingleLocation"
                @onViewDepartments="onViewDepartments"
                @onViewLocations="onViewLocations">
            </shift-offers-summary-view>
        </template>
    </location-view>
</template>

<script>
import LocationView from "./LocationView";
import ShiftsSummaryView from "./ShiftsSummaryView";
import ShiftOffersSummaryView from "./ShiftOffersSummaryView";
export default {
    name: "LocationCard",
    components: { LocationView, ShiftsSummaryView, ShiftOffersSummaryView },
    props: {
        location: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isMonthlyView: {
            type: Boolean,
            default: false,
        },
        isSingleView: {
            type: Boolean,
            default: false,
        },
        isSingleLocation: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onViewDepartments(locationId) {
            this.$emit('onViewDepartments', locationId)
        },
        onViewLocations() {
            this.$emit('onViewLocations')
        },
        onUpdateLocation(locationId) {
            this.$emit('onUpdateLocation', locationId)
        }
    }
}
</script>

<style scoped>

</style>
