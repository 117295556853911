<template>
    <v-expansion-panels
        v-model="panel"
        accordion
        elevation="1"
        class="ds-expansion-panels"
        mandatory
    >
        <staff-group-staffing-detail-panel
            v-for="(staff_group_staffing_detail, index) in staffing_details_by_staff_group"
            :key="index"
            :index="index"
            :staff-group-staffing-detail="staff_group_staffing_detail"
        />
    </v-expansion-panels>
</template>

<script>
import StaffGroupStaffingDetailPanel from "./StaffGroupStaffingDetailPanel";

export default {
    name: "StaffingDetailsPanels",
    components: {StaffGroupStaffingDetailPanel},
    data() {
        return {
            panel: null,
        }
    },
    methods: {
        expandLastPanel() {
            if (this.staffing_details_by_staff_group.length) {
                this.panel = this.staffing_details_by_staff_group.length - 1
            }
        }
    },
    computed: {
        staffing_details_by_staff_group: {
            get() {
                return this.$store.getters.staffingDetailsByStaffGroup
            }
        }
    }
}
</script>

<style scoped>

</style>
