<template>
    <v-row justify="center">
        <v-dialog :value="open" persistent width="500px">
            <v-card>
                <v-toolbar class="informational-modal" elevation="0">
                    <v-toolbar-title class="text--h2">
                        Change Location
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon class="ds-modal-close-button" @click="closeDialog" :disabled="saving">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <div class="text-center">
                        <v-progress-circular indeterminate color="primary" v-if="locationsLoading"></v-progress-circular>
                    </div>
                    <v-radio-group v-if="!locationsLoading && selectableLocations.length" v-model="selectedLocationIndex">
                        <v-radio
                            name="locations"
                            v-for="(location, index) in selectableLocations"
                            :key="index"
                            :value="index"
                        >
                            <template v-slot:label>
                                <div class="black--text text--paragraph">
                                    {{ location.name }}
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <div v-if="!locationsLoading && !selectableLocations.length" class="text-center pt-4 pb-4">
                        <h3>No locations available</h3>
                    </div>
                </v-card-text>
                <v-card-actions class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn name="change-location" color="primary" outlined @click="confirmChange" :disabled="saving">Apply</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: "LocationPickerModal",
        props: {
            open: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                locations: null,
                selectedLocationIndex: null,
                show: this.open,
                saving: false,
                locationsLoading: false,
                isAdmin: this.$authIsOwner,
                api: new formHelper()
            }
        },
        computed: {
            selectedLocation() {
                return this.selectableLocations[this.selectedLocationIndex]
            },

            selectableLocations() {
                if (!this.locations) {
                    return []
                }

                let allLocations = [...this.locations];

                if (this.isAdmin && allLocations.length > 1) {
                    allLocations.unshift({_id: 0, name: "All Locations"})
                }

                return allLocations
            }
        },
        methods: {
            closeDialog() {
                this.setSelectedLocation();
                this.show = false;
                this.$emit('update:open', this.show);
            },

            confirmChange() {
                this.saveSelectedLocation().then(() => this.closeDialog())
            },

            setSelectedLocation() {
                if (!this.selectableLocations.length) {
                    return
                }

                if (!this.$root.globalLocation) {
                    if (this.isAdmin) {
                        this.selectedLocationIndex = 0
                        return
                    } else {
                        return
                    }
                }

                this.selectedLocationIndex = this.selectableLocations.findIndex(location => location._id === this.$root.globalLocation._id)
            },

            saveSelectedLocation() {
                return new Promise((resolve, reject) => {
                    if ((!this.$root.globalLocation && this.selectedLocation._id == '0') || (this.$root.globalLocation && this.$root.globalLocation._id === this.selectedLocation._id)) {
                        resolve()
                        return;
                    }
                    window.EventBus.$emit('changingLocation')
                    let location = this.selectedLocation._id != '0' ? {_id: this.selectedLocation._id, name: this.selectedLocation.name} : 'all';
                    this.saving = true;
                    this.api.post("/user/change-selected-location", {selected_location: location})
                        .then(response => {
                            if (this.selectedLocation._id == '0') {
                                this.$root.globalLocation = null;
                            } else {
                                this.$root.globalLocation = this.selectedLocation
                            }
                            this.saving = false
                            resolve()
                        }).catch(err => {
                            reject(err)
                    })
                });
            },

            loadLocations() {
                if (this.isAdmin && this.$root.allLocations) {
                    this.locations = this.$root.allLocations
                    this.setSelectedLocation()
                } else if (this.$root.userLocations) {
                    this.locations = this.$root.userLocations
                    this.setSelectedLocation()
                } else {
                    this.saving = true
                    this.locationsLoading = true

                    this.api.get("/list-user-locations")
                        .then(({data, message}) => {
                            this.locations = data
                            this.$root.userLocations = data
                            this.saving = false
                            this.locationsLoading = false
                            this.setSelectedLocation()
                        })
                }
            }
        },
        watch: {
            open(newValue) {
                if (newValue) {
                    this.loadLocations()
                }
            }
        }
    }
</script>

<style scoped>

</style>
