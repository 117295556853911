<template>
    <v-dialog v-model="showModal" max-width="450px" elevation="10" scrollable persistent>
        <v-card>
            <v-toolbar dark color="primary" elevation="0" height="40">
                <v-toolbar-title class="white--text toolbar-title">Upload Profile Picture</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pt-3 pb-1">
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn
                            block
                            color="primary"
                            @click="triggerFilePicker"
                            :loading="uploading"
                        >
                            Select photo
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <v-row v-if="imagePreview">
                    <v-spacer></v-spacer>
                    <v-col>
                        <img :src="imagePreview" class="avatar-preview" width="200" height="200" alt="Your new profile image">
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <v-row v-if="imagePreview" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="text-center">Preview</v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12" class="mt-4">
                        <v-btn
                            block
                            color="primary"
                            @click="upload"
                            :loading="uploading"
                            :disabled="!canSave"
                        >
                            Confirm
                        </v-btn>
                    </v-col>

                    <v-col cols="12" class="mt-4 mb-4">
                        <v-btn
                            block
                            text
                            color="primary"
                            elevation="1"
                            @click="closeModal"
                            :loading="uploading"
                        >
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <input type="file" ref="fileUpload" style="display:none"  @change="fileChanged"/>

        <v-dialog v-if="imagePreview" v-model="showAvatarCropper" max-width="480px" elevation="10" persistent>
            <v-card class="pt-5">
                <v-card-text class="text-center">
                    <cropper
                        alt="Source Image"
                        ref="cropper"
                        class="avatar-cropper ma-auto"
                        :src="imagePreview"
                        :stencil-props="{ aspectRatio: 1 }"
                        :canvas="{
                            width: 380,
                            height: 380,
                        }"
                        stencil-component="circle-stencil"
                        backgroundClass="avatar-background"
                    ></cropper>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="showAvatarCropper = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary" @click="crop">Crop</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
    name: "UpdateAvatarModal",
    components: {Cropper},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            showModal: false,
            imagePreview: null,
            file: null,
            uploading: false,
            showAvatarCropper: false,
            mime_type: null,
            canSave: false,
        }
    },

    methods: {
        closeModal() {
            this.showModal = false
        },

        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            if (canvas) {
                canvas.toBlob(blob => {
                    this.file = blob
                    this.imagePreview = canvas.toDataURL()
                    this.canSave = true
                    this.showAvatarCropper = false
                }, this.mimeType)
            }

        },

        triggerFilePicker() {
            this.$refs.fileUpload.value = ''
            this.$refs.fileUpload.click()
        },

        fileChanged(event) {
            if (event.target.files && event.target.files[0]) {
                this.file = event.target.files[0]
                this.mime_type = event.target.files[0].type
                this.createDataURLFromFile(event.target.files[0])
                this.showAvatarCropper = true
            }
        },

        createDataURLFromFile(file) {
            let reader = new FileReader()
            reader.onload = (e) => this.imagePreview = e.target.result
            reader.readAsDataURL(file)
        },

        upload() {
            if (!this.file || !this.user) {
                return
            }

            let formData = new FormData();
            formData.append('avatar', this.file);
            formData.append('user_id', this.user._id)

            this.uploading = true

            axios.post('/user/avatar', formData)
                .then(({data}) => {
                    if (data.avatar) {
                        this.$emit('avatarUpdated', data.avatar)
                    }
                    this.uploading = false
                    this.closeModal()
                })
                .catch(error => {
                    if (error.response && error.response.status == 413) {
                        this.$snackNotify('warning', 'Unable to update profile image. Image file is too large.');
                    } else {
                        this.$snackNotify('warning', 'Unable to save profile image. Please try again.');
                    }
                    this.uploading = false
                    this.closeModal()
                })
        }
    },

    watch: {
        value(newVal) {
            this.showModal = newVal
        },
        showModal(newVal) {
            if (!newVal) {
                this.$emit('input', false)
            } else {
                this.file = null
                this.imagePreview = null
                this.canSave = false
            }
        }
    }
}
</script>

<style scoped>
    .avatar-cropper {
        border: solid 1px #EEE;
        background-color: white;
        height: 380px;
        width: 380px;
    }

    :deep(.avatar-background) {
        background: white;
    }

    .avatar-preview {
        border-radius: 50%;
    }
</style>
