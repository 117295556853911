<template>
    <v-expand-transition>
        <div class="d-flex flex-row" style="column-gap: 15px; margin-left: 44px;">
            <label class="text--label black--text" style="margin-top: 36px;">On</label>
            <v-radio-group v-model="startsOn" :readonly="readonly">
                <div class="starts-on-container">
                    <div
                        v-for="(week, index) in weeks"
                        :key="index"
                        class="d-flex flex-column"
                    >
                        <div>{{ week }}</div>
                        <v-radio :value="index"/>
                    </div>
                </div>
            </v-radio-group>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    name: "PayrollPeriodStartsOn",
    props: {
        value: {
            type: Number,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        weeks: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    }),
    computed: {
        startsOn: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>

<style scoped>
.starts-on-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2px;
    column-gap: 6px;
}
</style>
