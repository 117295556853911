<template>
    <v-dialog v-model="value" scrollable max-width="550px" persistent>
        <v-card>
            <v-toolbar dark color="primary" elevation="0">
                <v-toolbar-title>
                    Events for {{ current_date }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeModal">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pa-4">
                <v-list>
                    <v-list-item
                        v-for="event in events"
                        :key="event.id"
                        @click="showEventDetails(event)"
                        class="elevation-2 py-1 my-3"
                    >
                        <v-list-item-icon>
                            <green-check-icon v-if="event.type === 'shift'"></green-check-icon>
                            <yellow-pending-icon v-else></yellow-pending-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-icon>mdi-home-outline</v-icon>
                                        {{ event.department.name }}
                                    </v-col>
                                </v-row>
                            </v-list-item-title>
                            <v-list-item-title>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-icon>mdi-account-group</v-icon>
                                        {{ event.staff_group.name }}
                                    </v-col>
                                </v-row>
                            </v-list-item-title>
                            <v-list-item-title>
                                <v-row no-gutters>
                                    <v-col>
                                        <strong>Start:</strong> {{ event.start | date12hrNoYear}}
                                    </v-col>
                                    <v-col>
                                        <strong>End:</strong> {{ event.end | date12hrNoYear }}
                                    </v-col>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import GreenCheckIcon from "../../components/common/CustomIcon/GreenCheckIcon";
import YellowPendingIcon from "../../components/common/CustomIcon/YellowPendingIcon";
export default {
    name: "DailyShiftsList",
    components: {YellowPendingIcon, GreenCheckIcon},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        events: {
            type: Array,
            default: () => []
        },
        date: {
            type: String,
            default: null
        }
    },
    data() {
        return {

        }
    },
    methods: {
        closeModal: function() {
            this.$emit('closeDailyShiftsListModal')
        },

        showEventDetails: function(event) {
            this.$emit('eventClicked', {event: event})
        }
    },
    computed: {
        current_date() {
            if (!this.date) {
                return 'N/A'
            }

            return moment(this.date).tz(this.$root.globalTimezone).format('dddd, MM/DD/YYYY')
        }
    },
}
</script>

<style scoped>

</style>
