<template>
    <div>
        <v-data-table
            id="reports-table"
            :headers="computedHeaders"
            :items="formattedReports"
            :loading="reportsLoading"
            :server-items-length="reportsCount"
            :options.sync="options"
            @update:options="onOptionsUpdate"
            @click:row="onClickReport"
            no-data-text="There are no previous reports. Start a New Report to get access to the data you need from Koroid."
            >
            <template v-slot:item.type="{ item }">
                {{ item.report_type_name | capitalize }}
            </template>
            <template v-slot:item.preset="{ item }">
                {{ item.preset_name }}
            </template>
            <template v-slot:item.created_by="{ item }">
                {{ item.displayed_created_by }}
            </template>
            <template v-slot:item.date="{ item }">
                {{ item.date_range }}
            </template>
            <template v-slot:item.filters.locations="{ item }">
                {{ item.displayedFilters.displayedLocations }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon title="Delete" @click="onClickDeleteReport($event, item)" :disabled="isEditReportsDisabled" style="color: #0D0A32;">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <delete-report-modal
            v-model="deleteReportModalValue"
            :report="selectedReport"
            @onDeleteReport="onDeleteReport"/>
    </div>
</template>

<script>
import {
    parseReportType,
    parseReportPresetName,
    parseReportDateRange,
    parseReportLocations,
    parseReportCreatedBy
} from "../../../lib/helpers/reports";
import DeleteReportModal from "../Modals/Delete/DeleteReportModal";

export default {
    name: "ReportsTable",
    components: { DeleteReportModal  },
    data: () => ({
        headers: [
            { text: 'Created By', value: 'created_by' },
            { text: 'Date Created', value: 'created_at' },
            { text: 'Report Type', value: 'type' },
            { text: 'Report Preset', value: 'preset' },
            { text: 'Date Range', value: 'date' },
            { text: '', value: 'actions', sortable: false, }
        ],
        reports: [],
        reportsLoading: false,
        reportsCount: 0,
        options: { sortBy: ['created_at'], sortDesc: ['desc'] },
        api: new formHelper(),
        deleteReportModalValue: false,
        selectedReport: null,
    }),
    computed: {
        formattedReports() {
            return this.reports.map(this.parseReport)
        },
        computedHeaders() {
            if(this.globalLocation) {
                return this.headers
            }
            const headers = Array.from(this.headers)
            headers.splice(headers.length - 1, 0, { text: 'Location', value: 'filters.locations', sortable: false, })
            return headers
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        isEditReportsDisabled() {
            return !this.$userHasPermission(this.$user, this.$config.permissions.REPORTS.GENERATE)
        }
    },
    methods: {
        onOptionsUpdate: _.debounce(function() {
            this.loadReports()
        }, 500),
        loadReports() {
            this.reportsLoading = true
            this.api.post('/reports/list', this.getOptions())
                .then(({ data }) => {
                    if(Array.isArray(data.data.data)) {
                        this.reports = Array.from(data.data.data)
                        this.reportsCount = data.data.total
                    }
                })
                .catch(() =>{})
                .finally(() => {
                    this.reportsLoading = false
                })
        },
        getOptions() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options
            return {
                page,
                perPage: itemsPerPage,
                sortBy: sortBy.length > 0 ? sortBy[0] : null,
                sortDesc: sortDesc.length > 0 ? sortDesc[0] ? 'desc' : 'asc' : 'desc'
            }
        },
        parseReport(report) {
            return {
                ...report,
                report_type_name: parseReportType(report),
                preset_name: parseReportPresetName(report),
                date_range: parseReportDateRange(
                    report,
                    report.type === 'payroll_period' && report.hospital_group?.timezone ?
                        report.hospital_group.timezone : this.globalTimezone),
                displayed_created_by: parseReportCreatedBy(report),
                displayedFilters: {
                    displayedLocations: parseReportLocations(report)
                },
                created_at: moment(report.created_at).tz(this.globalTimezone).format('LLL')
            }
        },
        onClickReport(report) {
            this.$emit('onClickReport', report)
        },
        onClickDeleteReport(event, report) {
            if(event && event.stopPropagation && typeof event.stopPropagation === 'function') {
                event.stopPropagation()
            }
            this.selectedReport = {...report}
            this.deleteReportModalValue = true
        },
        onDeleteReport() {
            this.reports = []
            this.reportsCount = 0
            this.loadReports()
        }
    }
}
</script>

<style scoped>
    #reports-table :deep(tr:hover) {
        cursor: pointer;
    }
</style>
