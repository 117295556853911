<template>
    <v-dialog
        v-model="modalValue"
        scrollable width="500px"
        content-class="ds-dialog"
        persistent
    >
        <v-card class="ds-modal-card">
            <v-toolbar class="destructive-modal" elevation="0">
                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content">
                <div class="black--text" v-html="message">
                </div>
                <p class="black--text">
                    This action can't be reversed.
                </p>
                <p class="black--text">
                    Would you like to continue?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel" :disabled="isDeleting">Cancel</v-btn>
                        <v-btn class="ml-2" color="error" outlined @click="onDelete" :loading="isDeleting">Delete</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        isDeleting: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCancel() {
            this.modalValue = false
        },
        onDelete() {
            this.$emit('onDelete')
        }
    }
}
</script>

<style scoped>

</style>
