<template>
    <div v-if="value" class="spinner-overlay">
        <span><v-progress-circular color="primary" indeterminate></v-progress-circular></span>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
    .spinner-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 2;
    }

    .spinner-overlay span {
        position: absolute;
        top: 50%;
        left: 50%;
    }
</style>
