<template>
    <div>
        <template v-if="loading">
            <location-card v-for="index in 3"
               :key="index"
               :loading="true"
               class="location-card-container">
            </location-card>
        </template>
        <template v-else>
            <div>
                <div class="locations-title-container">
                    <div class="sort-container">
                        <v-select
                            v-model="sortBy"
                            :items="sortByItems"
                            :disabled="loading"
                            label="Sort By"
                            :menu-props="{ 'offset-y': true }"
                        ></v-select>
                    </div>
                </div>
                <location-card v-for="(location, index) in locations"
                   :key="index"
                   :location="location"
                   :is-monthly-view="isMonthlyView"
                   :loading="location.loading"
                   @onViewDepartments="onViewDepartments"
                   @onUpdateLocation="onUpdateLocation"
                   class="location-card-container">
                </location-card>
            </div>
        </template>
    </div>
</template>

<script>
import LocationCard from "../cards/location/LocationCard";
export default {
    name: "LocationsView",
    components: { LocationCard },
    props: {
        locations: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isMonthlyView: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        sortByItems: [
            { text: '% Filled Shifts, Low to High', value: 'location_summary.shifts.ratio-location_summary.shifts.total_count-asc' },
            { text: '% Filled Shifts, High to Low', value: 'location_summary.shifts.ratio-location_summary.shifts.total_count-desc' },
            { text: '% Response Rate, Low to High', value: 'location_summary.offers.ratio-location_summary.offers.total_count-asc' },
            { text: '% Response Rate, High to Low', value: 'location_summary.offers.ratio-location_summary.offers.total_count-desc' },
            { text: 'Name, A-Z', value: 'name-asc' },
            { text: 'Name, Z-A', value: 'name-desc' }
        ],
        sortBy: 'name-asc',
    }),
    methods: {
        onViewDepartments(locationId) {
            this.$emit('onViewDepartments', locationId)
        },
        onUpdateLocation(locationId) {
            this.$emit('onUpdateLocation', locationId)
        },
        onSortByChange() {
            this.$emit('onSortByChange', this.sortBy)
        },
    },
    watch: {
        sortBy: {
            immediate: true,
            handler() {
                this.onSortByChange()
            }
        }
    },
}
</script>

<style scoped>
    .location-card-container {
        margin-bottom: 30px;
    }
    .locations-title-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .sort-container {
        width: 250px;
    }
</style>
