<template>
    <summary-view>
        <template v-slot:text-content>
            <div class="text--h4">
                Total Offers
            </div>
            <v-progress-circular
                v-if="loading"
                color="primary"
                indeterminate
                class="mt-2"
            ></v-progress-circular>
            <div v-else class="text--h3 mt-2">
                {{ totalCount }}
            </div>
        </template>
        <template v-slot:doughnut-chart>
            <shift-offers-doughnut-chart
                :key="doughnutChartKey"
                :percentage="percentage"
                :accepted="acceptedCount"
                :rejected="rejectedCount"
                :pending="pendingCount"
                :loading="loading"
            />
        </template>
        <template v-slot:bar-chart>
            <div class="d-flex justify-end shift-offers-summary-view-title-container">
                <template v-if="isSingleView">
                    <v-btn
                        v-if="!isSingleLocation"
                        color="primary"
                        outlined
                        :disabled="loading"
                        class="ml-5 shift-offers-summary-view-title-wrapper"
                        @click="onViewLocations">
                        Back to Locations
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        color="primary"
                        outlined
                        :disabled="loading"
                        class="ml-5 shift-offers-summary-view-title-wrapper"
                        @click="onViewDepartments">
                        View Departments
                    </v-btn>
                </template>
            </div>
            <dashboard-bar-chart
                :key="barChartKey"
                :data-source="barDataSource"
                :loading="loading"
                style="height: 400px;"
            />
            <div class="shift-offers-summary-view-loading-container">
                <div class="shift-offers-summary-view-loading-wrapper">
                    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
                </div>
            </div>
        </template>
    </summary-view>
</template>

<script>
import SummaryView from "./SummaryView";
import ShiftOffersDoughnutChart from "../../charts/doughnut/ShiftOffersDoughnutChart";
import DashboardBarChart from "../../charts/bar/DashboardBarChart";
export default {
    name: "ShiftOffersSummaryView",
    components: { SummaryView, ShiftOffersDoughnutChart, DashboardBarChart },
    props: {
        location: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false,
        },
        monthly: {
            type: Boolean,
            default: true,
        },
        isSingleView: {
            type: Boolean,
            default: false,
        },
        isSingleLocation: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        doughnutChartKey: 1111,
        barChartKey: 9999,
    }),
    computed: {
        summary() {
            const { location } = this
            return ((location || {}).location_summary || {}).offers || {}
        },
        acceptedCount() {
            return this.summary.accepted_count || 0
        },
        rejectedCount() {
            return this.summary.rejected_count || 0
        },
        pendingCount() {
            return this.summary.pending_count || 0
        },
        totalCount() {
            return this.summary.total_count || 0
        },
        percentage() {
            return this.summary.ratio || 0
        },
        barDataSource() {
            if(this.loading) {
                return this.getDefaultBarDataSource()
            } else {
                if(this.summary.daily_count) {
                    return this.getBarDataSource(this.summary.daily_count, this.monthly)
                }
                return this.getDefaultBarDataSource()
            }
        }
    },
    methods: {
        getBarDataSource(dailyCount, monthly) {
            const dates = Object.keys(dailyCount)
            return dates.map((date, index) => {
                const summary = dailyCount[date] || {}
                const percentage = summary.ratio || 0
                const acceptedCount = summary.accepted_count || 0
                const rejectedCount = summary.rejected_count || 0
                const pendingCount = summary.pending_count || 0
                const totalCount = summary.total_count || 0
                const shouldNotBeSkipped = !monthly || index === 0 ||
                    index === dates.length - 1 ||
                    index === (Math.floor(dates.length / 2) - 1)
                return {
                    x: !shouldNotBeSkipped ? '' : date,
                    y: totalCount,
                    percentage,
                    label: [
                        `Accepted   ${acceptedCount}`,
                        `Rejected    ${rejectedCount}`,
                        `Pending     ${pendingCount}`
                    ],
                    title: monthly ? `DAY           ${date}` : '',
                    footer: `R.R.           ${percentage}%`
                }
            })
        },
        getDefaultBarDataSource() {
            return [
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
            ]
        },
        onViewDepartments() {
            const locationId = (this.location || {})._id
            if(locationId) {
                this.$emit('onViewDepartments', locationId)
            }
        },
        onViewLocations() {
            this.$emit('onViewLocations')
        },
    },
    watch: {
        loading() {
            this.doughnutChartKey = this.doughnutChartKey + 1
            this.barChartKey = this.barChartKey + 1
        }
    }
}
</script>

<style scoped>
    .shift-offers-summary-view-loading-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
    .shift-offers-summary-view-loading-wrapper {
        width: 91%;
    }
    .shift-offers-summary-view-title-container {
        position: relative;
    }
    .shift-offers-summary-view-title-wrapper {
        position: absolute;
        margin-top: 15px;
    }
</style>
