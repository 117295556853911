<template>
    <v-form ref="shiftTimeFormRef" lazy-validation>
        <v-container fluid class="pt-0">
            <v-row>
                <v-col cols="12" class="ds-modal-input-container">
                    <v-divider style="border-color: #000000;"></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="ds-modal-input-container mt-5">
                    <p class="text--h4">
                        Shift Time
                    </p>
                </v-col>
                <v-col cols="12" class="ds-modal-input-container">
                    <v-autocomplete
                        v-model="selectedShiftTimeId"
                        label="Shift Time Preset"
                        :items="shiftTimes"
                        :loading="shiftTimesLoading"
                        :disabled="shiftTimesLoading"
                        item-value="_id"
                        item-text="label"
                        clearable
                        outlined
                        @change="onChangeShiftTime"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-clock-outline</v-icon>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" :lg="true" class="ds-modal-input-container">
                    <time-picker
                        v-model="startTime"
                        label="Start Time"
                        :error-messages="api.hasError('start_time')"
                        :key="startTimeKey"
                        @onClearTime="onClearStartTime"
                        @onUpdateTime="onConfirmStartTime"/>
                </v-col>
                <v-col cols="12" lg="5" class="ds-modal-input-container">
                    <duration-input
                        v-model="durationValue"
                        :disabled="!start_time"
                        :error-messages="api.hasError('duration')"
                        :key="durationKey"
                        @onChangeDuration="onChangeDuration"
                    />
                </v-col>
                <v-col cols="12" :lg="true" class="ds-modal-input-container">
                    <time-picker
                        v-model="endTime"
                        label="End Time"
                        :disabled="!start_time"
                        :error-messages="api.hasError('end_time')"
                        :hint="end_time_hint"
                        :key="endTimeKey"
                        @onClearTime="onClearEndTime"
                        @onUpdateTime="onConfirmEndTime"/>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import TimePicker from "../../../common/TimeRangePicker/TimePicker";
import DurationInput from "../../../common/TimeRangePicker/DurationInput";
import timeRangePickerMethods from "../../../../lib/mixins/timeRangePickerMethods";

export default {
    name: "ShiftTimeForm",
    components: {TimePicker, DurationInput},
    mixins: [timeRangePickerMethods],
    props: {
        value: {
            type: Number,
            default: 0,
        },
        location: {
            type: Object,
            default: () => null
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
        selectedShiftTimeId: null,
        shiftTimesLoading: false,
        shiftTimes: [],
        shiftTimesMapping: {},
        startTime: null,
        durationValue: null,
        endTime: null,
        matchAgainst: {
            startTime: null,
            durationValue: null,
            endTime: null,
            selectedShiftTimeId: null,
        },
        startTimeKey: 1111,
        durationKey: 2222,
        endTimeKey: 3333,
        api: new formHelper()
    }),
    computed: {
        bodyParams() {
            return {
                shift_time_id: this.selectedShiftTimeId,
                duration: this.duration,
                start_time: this.startTime,
            }
        },
        isDirty() {
            const {matchAgainst} = this

            return !_.isEqual(this.startTime, matchAgainst.startTime) ||
                !_.isEqual(this.endTime, matchAgainst.endTime) ||
                !_.isEqual(this.selectedShiftTimeId, matchAgainst.selectedShiftTimeId)
        },
        shiftTimesDisabled() {
            return this.shiftTimesLoading
        },
        selectedTimezone() {
            return this.$root.globalTimezone
        },
        start_time() {
            return typeof this.startTime !== 'undefined' ? this.startTime : null
        },
        duration() {
            return typeof this.durationValue !== 'undefined' ? this.durationValue : null
        },
        start_date_time() {
            if (!this.start_time) {
                return null
            }

            return moment(moment().format('YYYY-MM-DD') + ' ' + this.start_time)
        },
        end_date_time() {
            if (!this.start_date_time || !this.duration) {
                return null
            }

            return this.start_date_time.clone().add(this.duration, 'm')
        },
        end_time() {
            if (!this.end_date_time) {
                return null
            }

            return this.end_date_time.format('h:mm A')
        },
        end_time_hint() {
            if (!this.start_time) {
                return 'Set a start time'
            }

            if (!this.duration) {
                return ''
            }

            if (!this.end_date_time.isSame(this.start_date_time, 'd')) {
                let diff = this.end_date_time.clone().startOf('day').diff(this.start_date_time.clone().startOf('day'), 'd')
                return diff > 0 ? ('+ ' + diff + ' ' + this.$options.filters.pluralize('Day', diff)) : ''
            }

            return ''
        },
    },
    watch: {
        selectedShiftTimeId: {
            immediate: true,
            handler(shiftTimeId) {
                this.onChangeShiftTime(shiftTimeId)
            }
        },
        location: {
            immediate: true,
            handler(location) {
                if (location && location._id) {
                    this.fetchShiftTimes(location._id)
                }
            }
        },
        duration: {
            immediate: true,
            handler(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        validate() {
            return this.$refs.shiftTimeFormRef.validate()
        },
        postFetch(uri, payload, callbacks = {}) {
            const {
                onStart,
                onSuccess,
                onError,
                onEnd
            } = callbacks

            if (onStart) {
                onStart()
            }
            this.api.post(uri, payload).then(data => {
                if (onSuccess) {
                    onSuccess(data)
                }
            }).catch(data => {
                if (onError) {
                    onError(data)
                }
            }).finally(() => {
                if (onEnd) {
                    onEnd()
                }
            })
        },
        fetchShiftTimes(location_id) {
            this.postFetch('/request-forms/filters/shift_times', {location_id: location_id}, {
                onStart: () => {
                    this.shiftTimesLoading = true
                },
                onSuccess: ({data}) => {
                    this.shiftTimes = data.data
                    this.shiftTimesMapping = this.genMapping(this.shiftTimes)

                    if (this.editMode && this.initialRequestForm && typeof this.initialRequestForm.shift_time_id !== 'undefined' && this.initialRequestForm.shift_time_id) {
                        this.selectedShiftTimeId = this.initialRequestForm.shift_time_id
                        this.matchAgainst = {
                            ...this.matchAgainst,
                            selectedShiftTimeId: this.selectedShiftTimeId,
                        }
                    }

                    if (this.editMode && this.initialRequestForm) {
                        if (this.selectedTimezone) {
                            this.startTime = moment(this.initialRequestForm.start).tz(this.selectedTimezone).format('HH:mm')
                            this.endTime = moment(this.initialRequestForm.end).tz(this.selectedTimezone).format('HH:mm')
                        } else {
                            this.startTime = moment(this.initialRequestForm.start).format('HH:mm')
                            this.endTime = moment(this.initialRequestForm.end).format('HH:mm')
                        }
                        this.durationValue = this.initialRequestForm.duration
                        this.matchAgainst = {
                            ...this.matchAgainst,
                            startTime: this.startTime,
                            durationValue: this.durationValue,
                            endTime: this.endTime,
                        }
                    }
                },
                onError: error => console.log(error),
                onEnd: () => {
                    this.shiftTimesLoading = false
                }
            })
        },
        genMapping(fromArr) {
            if (Array.isArray(fromArr)) {
                return fromArr.reduce((mapping, obj) => ({
                    ...mapping,
                    [obj._id]: {...obj}
                }), {})
            }
            return {}
        },
        onChangeShiftTime(shiftTimeId) {
            if (shiftTimeId) {
                const shiftTime = this.shiftTimesMapping[shiftTimeId]
                if (shiftTime) {
                    this.setTimes(shiftTime.start_time, shiftTime.duration, shiftTime.end_time)
                }
            } else {
                this.setTimes(null, null, null)
            }
        },
        onClearStartTime() {
            this.startTime = null
            this.durationValue = null
            this.endTime = null
            this.selectedShiftTimeId = null
        },
        onConfirmStartTime(data) {
            if (this.end_time) {
                this.setDuration(data, this.end_time)
            } else if (this.duration) {
                this.setEndTime(data, this.duration)
            }
            this.selectedShiftTimeId = null
        },
        onChangeDuration(duration) {
            if (!duration) {
                this.endTime = null
            } else {
                this.setEndTime(this.start_time, duration)
            }
            this.selectedShiftTimeId = null
        },
        onClearEndTime() {
            this.endTime = null
            this.selectedShiftTimeId = null
        },
        onConfirmEndTime(data) {
            this.setDuration(this.start_time, data)
            this.selectedShiftTimeId = null
        },
        setTimes(startTime, duration, endTime) {
            this.startTime = startTime
            this.durationValue = duration
            this.endTime = endTime
            this.startTimeKey = this.startTimeKey + 1
            this.durationKey = this.durationKey + 1
            this.endTimeKey = this.endTimeKey + 1
        },
        setDuration(start_time, end_time) {
            this.durationValue = this.getUpdatedDuration(start_time, end_time)
        },
        setEndTime(start_time, duration) {
            const end = this.getUpdatedEndTime(start_time, duration)
            if (end) {
                this.endTime = end
            }
        },
    },
    created() {
        if (this.editMode && this.initialRequestForm) {
            this.matchAgainst = {
                ...this.matchAgainst,
                startTime: null,
                durationValue: null,
                endTime: null,
                selectedShiftTimeId: null,
            }
        }
    }
}
</script>

<style scoped>

</style>
