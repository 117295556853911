<template>
    <v-dialog v-model="modalValue" max-width="600px" persistent>
        <v-card>
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Save Preset
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="onClose" :disabled="isSaving">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-form ref="savePresetForm" @submit.prevent="onSave">
                                <v-text-field
                                    v-model="presetName"
                                    label="Preset Name"
                                    :rules="rules.required"/>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-row class="mx-1">
                        <v-col>
                            <div>
                                <p class="preset-field-title">Report Type</p>
                                <p class="preset-field-content">{{ type }}</p>
                            </div>
                            <div v-for="(filterValue, filterName) in filters">
                                <p class="preset-field-title">{{ filterName }}</p>
                                <p class="preset-field-content">{{ filterValue }}</p>
                            </div>
                        </v-col>
                        <v-col>
                            <div>
                                <p class="preset-field-title">Report Data</p>
                                <p class="preset-field-content mb-1" v-for="column in columns" v-if="columns.length > 0">
                                    {{ column }}
                                </p>
                                <p class="preset-field-content mb-1" v-else>
                                    N/A
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    @click="onSave"
                    :loading="isSaving"
                >Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import validationRules from '../../../../lib/mixins/validationRules'
import { parseReportType, parseReportColumns } from '../../../../lib/helpers/reports'

export default {
    name: "SavePresetModal",
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        report: {
            type: Object,
            default: () => ({})
        },
        filtersBodyParam: {
            type: Object,
            default: () => ({})
        },
        filters: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        presetName: null,
        isSaving: false,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        type() {
            return parseReportType(this.report)
        },
        columns() {
            return parseReportColumns(this.$appData, this.report)
        }
    },
    methods: {
        onClose() {
            this.modalValue = false
        },
        onSave() {
            if (!this.$refs.savePresetForm.validate()) {
                return
            }
            this.isSaving = true
            this.api.post('/report-preset/create', this.getBodyParams()).then(({ data, message }) => {
                this.isSaving = false
                this.$snackNotify('success', 'Report preset saved.')
                this.onClose()
                this.$emit('onSavePreset', data.report_preset)
            }).catch(() => {
                this.isSaving = false
            })
        },
        getBodyParams() {
            return {
                title: this.presetName,
                type: this.report.type,
                filters: this.filtersBodyParam,
                columns: this.report.columns,
                created_from_report_id: this.report._id
            }
        }
    }
}
</script>

<style scoped>
    .preset-field-title {
        color: #1B1464;
        margin-bottom: 0;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
    }
    .preset-field-content {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10px;
        color: black;
    }
</style>
