<template>
    <v-row style="padding-left: 20px; padding-right: 20px; margin-bottom: 5px;">
        <v-col class="d-flex flex-column">
            <div class="text--h4">
                With Availability
            </div>
            <div class="d-flex flex-column" style="padding-left: 15px;">
                <employment-type-open-shifts-checkbox
                    :value="selectedValues.whDirectAssign"
                    @input="onChangeWhDirectAssign"
                    title="Shifts Can Be Assigned"
                    subtitle="Admins and managers can assign staff members to shifts."
                    :checked="isDirectAssignChecked"
                    :disabled="isDuringWorkingHoursDisabled"
                    :readonly="readonly"
                />
                <employment-type-open-shifts-checkbox
                    :value="selectedValues.whSelfAssign"
                    @input="onChangeWhSelfAssign"
                    title="Shifts Can Be Self Assigned"
                    subtitle="Staff can select from open shifts and assign themselves."
                    :disabled="isDuringWorkingHoursDisabled"
                    :readonly="readonly"
                />
            </div>
        </v-col>
        <v-col class="d-flex flex-column">
            <div class="text--h4">
                Without Availability
            </div>
            <div class="d-flex flex-column" style="padding-left: 15px;">
                <employment-type-open-shifts-checkbox
                    :value="selectedValues.owhOffer"
                    @input="onChangeOwhOffer"
                    title="Shifts Can Be Offered"
                    subtitle="Admins and managers can offer shifts to staff members."
                    :readonly="readonly"
                />
                <employment-type-open-shifts-checkbox
                    :value="selectedValues.owhSelfAssign"
                    @input="onChangeOwhSelfAssign"
                    title="Shifts Can Be Self Assigned"
                    subtitle="Staff can select from open shifts and assign themselves."
                    :readonly="readonly"
                />
            </div>
        </v-col>
    </v-row>
</template>

<script>
import EmploymentTypeOpenShiftsCheckbox from "./EmploymentTypeOpenShiftsCheckbox";

export default {
    name: "EmploymentTypeOpenShifts",
    components: {EmploymentTypeOpenShiftsCheckbox},
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        workingHours: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        selectedValues: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        isDirectAssignChecked() {
            return this.workingHours === 'assigned' || this.workingHours === 'submitted'
        },
        isDuringWorkingHoursDisabled() {
            return this.workingHours === 'none'
        }
    },
    methods: {
        onChangeWhDirectAssign(whDirectAssign) {
            this.selectedValues = {
                ...this.selectedValues,
                whDirectAssign,
            }
        },
        onChangeWhSelfAssign(whSelfAssign) {
            this.selectedValues = {
                ...this.selectedValues,
                whSelfAssign,
            }
        },
        onChangeOwhOffer(owhOffer) {
            this.selectedValues = {
                ...this.selectedValues,
                owhOffer,
            }
        },
        onChangeOwhSelfAssign(owhSelfAssign) {
            this.selectedValues = {
                ...this.selectedValues,
                owhSelfAssign,
            }
        }
    },
    watch: {
        workingHours: {
            immediate: true,
            handler(workingHours) {
                if (workingHours === 'assigned' || workingHours === 'submitted') {
                    this.selectedValues = {
                        ...this.selectedValues,
                        whDirectAssign: true,
                    }
                } else if (workingHours === 'none') {
                    this.selectedValues = {
                        ...this.selectedValues,
                        whDirectAssign: false,
                        whSelfAssign: false,
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
