<template>
    <v-row class="pa-3">
        <v-col cols="12" class="d-flex flex-row justify-space-between align-center">
            <span>
                Add unique data points to help make Koroid work for you. Select a row to edit it.
            </span>
            <v-btn
                color="secondary"
                :disabled="!hasEditCustomFieldsPermission"
                @click="onAddCustomField"
            >
                Add Custom Field
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table
                id="custom-fields-table"
                :height="tableHeight"
                :headers="headers"
                :items="customFields"
                :loading="customFieldsLoading"
                disable-pagination
                fixed-header
                hide-default-footer
                @click:row="onEditCustomField"
            >
                <template v-slot:item.trackedIn="{ item }">
                    {{ item.trackedIn | snakeToTitle }}
                </template>

                <template v-slot:item.locations="{ item }">
                    <truncating-chips-list :items="item.locations"
                                           v-if="item.locations.length > 0" view-only></truncating-chips-list>
                    <span v-else>N/A</span>
                </template>
            </v-data-table>
        </v-col>
        <save-custom-field-modal
            v-model="customFieldModalValue"
            :custom-field-id="selectedCustomFieldId"
            @onSave="onSaveCustomField"
            @onDelete="onDeleteCustomField"
        />
    </v-row>
</template>

<script>
import TruncatingChipsList from "../../../../../components/common/Lists/TruncatingChipsList";
import SaveCustomFieldModal from "./SaveCustomFieldModal";

export default {
    name: "CustomFieldsTab",
    components: {TruncatingChipsList, SaveCustomFieldModal},
    props: {
        tab: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            headers: [
                {text: 'Field Name', value: 'name', width: '18%'},
                {text: 'Tracked In', value: 'trackedIn', width: '18%'},
                {text: 'Applied to Locations', value: 'locations', width: '64%', sortable: false},
            ],
            customFields: [],
            customFieldModalValue: false,
            customFieldsLoading: false,
            selectedCustomFieldId: null,
            api: new formHelper(),
        }
    },
    computed: {
        tableHeight() {
            return window.innerHeight - 320
        },
        hasEditCustomFieldsPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.CUSTOM_FIELDS.EDIT)
        }
    },
    methods: {
        onAddCustomField() {
            if (!this.hasEditCustomFieldsPermission) {
                return
            }

            this.customFieldModalValue = true
            this.selectedCustomFieldId = null
        },
        onEditCustomField(customField) {
            if (!this.hasEditCustomFieldsPermission) {
                return
            }

            this.customFieldModalValue = true
            if (customField && customField._id) {
                this.selectedCustomFieldId = customField._id
            }
        },
        fetchCustomFields() {
            this.customFieldsLoading = true
            this.customFields = []
            this.api.get('/custom-field/list-for-organization').then(result => {
                if (result && Array.isArray(result.data)) {
                    this.customFields = result.data.map(customField => ({
                        _id: customField._id,
                        name: customField.name,
                        trackedIn: customField.context,
                        locations: customField.locations.map(loc => loc.name),
                    }))
                }
            }).catch(console.log).finally(() => this.customFieldsLoading = false)
        },
        onSaveCustomField() {
            this.fetchCustomFields()
        },
        onDeleteCustomField() {
            this.fetchCustomFields()
        }
    },
    watch: {
        tab: {
            immediate: true,
            handler(tab) {
                if (tab === 3) {
                    this.fetchCustomFields()
                }
            }
        }
    },
}
</script>

<style scoped>
#custom-fields-table :deep(tr:hover) {
    cursor: pointer;
}
</style>
