<template>
    <div class="preset-list-item-container">
        <div class="preset-list-item-title-container">
            <div class="d-flex flex-row justify-start align-center flex-wrap">
                <span class="text--h4 mr-5">
                    {{ preset.title }}
                 </span>
                <v-btn icon title="Edit" @click="onEditPreset" :disabled="isEditPresetDisabled">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon title="Delete" @click="onDeletePreset" :disabled="isDeletePresetDisabled"
                       style="color: #0D0A32;">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </div>
            <div class="d-flex flex-row justify-start align-center flex-wrap">
                <v-btn
                    v-if="showAutomation"
                    color="primary"
                    text
                    @click="isAutomatedReportModalOpen = true"
                    :disabled="!canCreateOrEditAutomations"
                >
                    <v-icon class="mr-2">
                        mdi-creation
                    </v-icon>
                    {{ reportAutomationId ? 'Edit Automation' : 'Automated Report' }}
                </v-btn>
                <v-btn color="primary" class="ml-2" outlined @click="onRunQuickReport"
                       :disabled="isRunQuickReportDisabled">
                    Quick Report
                </v-btn>
            </div>
        </div>
        <v-container class="preset-list-item-columns-container" fluid>
            <v-data-table
                id="preset-list-item-table"
                :headers="headers"
                :items="items"
                disable-pagination
                disable-sort
                disable-filtering
                hide-default-footer
            ></v-data-table>
        </v-container>
        <automated-report-modal
            v-if="isAutomatedReportModalOpen"
            v-model="isAutomatedReportModalOpen"
            :preset-id="preset._id"
            :report-automation-id="reportAutomationId"
            @onSave="onSaveReportAutomation"/>
    </div>
</template>

<script>
import AutomatedReportModal from "../AutomatedReports/AutomatedReportModal";

export default {
    name: "PresetListItem",
    components: {AutomatedReportModal},
    props: {
        preset: {
            type: Object,
            default: () => ({}),
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        items: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        isAutomatedReportModalOpen: false,
    }),
    computed: {
        reportAutomationId() {
            return this.preset.report_automation?._id
        },
        isRunQuickReportDisabled() {
            return !this.$userHasPermission(this.$user, this.$config.permissions.REPORTS.GENERATE) || !this.globalLocation
        },
        isEditPresetDisabled() {
            return !this.$userHasPermission(this.$user, this.$config.permissions.REPORT_PRESETS.EDIT) || !this.globalLocation
        },
        isDeletePresetDisabled() {
            return !this.$userHasPermission(this.$user, this.$config.permissions.REPORT_PRESETS.EDIT)
        },
        showAutomation() {
            if (this.preset) {
                return this.preset.type !== 'payroll_period'
            }
            return false
        },
        canCreateOrEditAutomations() {
            return this.$userHasPermission(this.$user, this.$config.permissions.REPORTS.GENERATE) && this.$userHasPermission(this.$user, this.$config.permissions.REPORT_PRESETS.EDIT)
        },
        globalLocation() {
            return this.$root.globalLocation
        }
    },
    methods: {
        onEditPreset() {
            this.$emit('onEditPreset', this.preset)
        },
        onDeletePreset() {
            this.$emit('onDeletePreset', this.preset)
        },
        onRunQuickReport() {
            this.$emit('onRunQuickReport', this.preset)
        },
        onSaveReportAutomation() {
            this.$emit('onSaveReportAutomation')
        }
    }
}
</script>

<style scoped>
.preset-list-item-container {
    background-color: #FFFFFF;
    border: 2px solid #777680;
    border-radius: 12px;
    padding: 16px !important;
    margin-bottom: 10px;
    min-width: 180px;
}

.preset-list-item-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.preset-list-item-columns-container {
    font-size: 16px;
    padding: 0;
}

#preset-list-item-table :deep(tr:hover) {
    background-color: transparent;
}
</style>
