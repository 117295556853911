<template>
    <v-row class="pt-4">
        <v-col cols="12" class="ds-modal-input-container d-flex">
            <v-autocomplete
                name="job-title"
                label="Job Title"
                v-model="job_title_id"
                item-value="_id"
                item-text="title"
                :error-messages="api.hasError('job_title_id')"
                :items="job_titles"
                :readonly="!hasAuthStaffGroupPermission"
                :rules="rules.required"
                autocomplete="off"
                outlined
                @change="onJobTitleChange"
                class="staffing-detail-job-title-input"
            />

            <v-autocomplete
                name="managers"
                label="Managers"
                v-model="managers"
                item-value="_id"
                item-text="full_name"
                return-object
                multiple
                :error-messages="api.hasError('manager_ids')"
                :items="selectableManagers"
                :readonly="!hasAuthStaffGroupPermission"
                :rules="rules.requiredArrayNotEmpty"
                validate-on-blur
                autocomplete="off"
                outlined
                chips
                small-chips
                deletable-chips
                @change="onManagersChange"
                class="staffing-detail-managers-input"
            />

            <div class="staffing-detail-buttons-container">
                <v-btn
                    class="mt-3 ml-3"
                    icon
                    @click="onDeleteStaffingDetail"
                    :loading="deleting_staffing_detail"
                    :disabled="api.busy || !hasAuthStaffGroupPermission"
                >
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>

                <v-slide-y-reverse-transition>
                    <v-btn
                        v-if="save_enabled"
                        class="mt-3"
                        icon
                        @click="onSaveStaffingDetail"
                        :loading="saving_staffing_detail"
                        :disabled="api.busy || !hasAuthStaffGroupPermission"

                    >
                        <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                </v-slide-y-reverse-transition>
            </div>
        </v-col>

        <confirmation-dialog ref="confirmationDialog"/>

    </v-row>
</template>

<script>
import validationRules from "../../../../lib/mixins/validationRules";
import ConfirmationDialog from "../../../../components/modals/ConfirmationDialog";

export default {
    name: "StaffingDetail",
    components: {ConfirmationDialog},
    mixins: [validationRules],
    props: {
        staffingDetail: {
            type: Object,
            default: () => {}
        },
        selectableManagers: {
            type: Array,
            default: () => []
        },
        index: {
            type: Number,
            default: null
        },
        staffingDetailsCount: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            api: new formHelper,
            staffing_detail: this.staffingDetail,
            job_title_id: this.staffingDetail.job_title_id,
            managers: this.reformatManagersId(this.staffingDetail.managers),
            saving_staffing_detail: false,
            deleting_staffing_detail: false,
        }
    },
    computed: {
        unsaved_changes() {
            const staffing_detail = {
                job_title_id: this.staffing_detail.job_title_id,
                staff_group_id: this.staffing_detail.staff_group_id,
                manager_ids: this.managers.map(manager => manager.id)
            }

            const persisted_staffing_detail = this.$store.getters.persistedStaffingDetailForStaffGroup({
                staff_group_id: this.staffing_detail.staff_group_id,
                staffing_detail_index: this.index
            })

            if (!persisted_staffing_detail) {
                return true
            }

            const prev_staffing_detail = {
                job_title_id: persisted_staffing_detail.job_title_id,
                staff_group_id: persisted_staffing_detail.staff_group_id,
                manager_ids: persisted_staffing_detail.managers ? persisted_staffing_detail.managers.map(manager => manager.id) : []
            }

            return !_.isEqual(staffing_detail, prev_staffing_detail)
        },
        hasAuthStaffGroupPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.DEPARTMENTS.AUTHORIZE_STAFF_GROUP)
        },
        save_enabled() {
            if (!this.hasAuthStaffGroupPermission) {
                return false
            }

            return this.unsaved_changes && this.job_title_id && Array.isArray(this.managers) && this.managers.length
        },

        job_titles: {
            get() {
                if (!this.staffing_detail.staff_group_id) {
                    return []
                }

                return this.$store.getters.jobTitlesForSelectionForStaffGroupWithDisabledFlag(this.staffing_detail.staff_group_id)
            }
        },
    },
    methods: {
        reformatManagersId(managers) {
            if (!managers) {
                return []
            }

            return managers.map(manager => {
                return {_id: manager.id, ...manager}
            })
        },

        onJobTitleChange(job_title_id) {
            this.$store.dispatch('changeStaffingDetailJobTitle', {index: this.index, staffing_detail: this.staffing_detail, new_job_title_id: job_title_id})
        },

        onManagersChange() {
            const selected_managers_ids = this.managers.map(manager => manager._id)
            const new_managers = this.selectableManagers
                .filter(manager => selected_managers_ids.includes(manager._id))
                .map(manager => {
                    return {
                        id: manager._id,
                        first_name: manager.first_name,
                        last_name: manager.last_name
                    }
                })

            this.$store.dispatch('changeStaffingDetailManagers', {staffing_detail: this.staffing_detail, new_managers: new_managers})
        },

        onSaveStaffingDetail() {
            const request_data = {
                _id: this.staffing_detail._id,
                department_id: this.staffing_detail.department_id,
                staff_group_id: this.staffing_detail.staff_group_id,
                job_title_id: this.job_title_id,
                manager_ids: this.managers.map(manager => manager._id)
            }

            this.saving_staffing_detail = true

            this.api.post('/department-staffing-detail/save', request_data)
                .then(({data}) => {
                    if (this.isNewEntry()) {
                        this.$snackNotify('success', 'New Staffing Detail created.')
                    } else {
                        this.$snackNotify('success', 'Changes to Staffing Detail saved.')
                    }

                    this.$store.dispatch('replaceStaffingDetail', data)
                })
                .finally(() => {
                    this.saving_staffing_detail = false
                })
        },

        isNewEntry() {
            return typeof this.staffing_detail._id === 'undefined' || this.staffing_detail._id === null
        },

        onDeleteStaffingDetail() {
            const is_last_staffing_detail_for_staff_group = this.staffingDetailsCount === 1

            if (this.isNewEntry() && !is_last_staffing_detail_for_staff_group) {
                this.onDeleteConfirmed()
                this.$snackNotify('success', 'Staffing Detail deleted.')
                return
            }

            let dialog_options = {
                title: 'Remove Job Title',
                titleClass: 'text--primary',
                toolbarColor: 'warning',
                message: "You are removing an authorized Job Title and its Managers from this Staffing Detail.",
                confirmText: 'Remove'
            }

            if (!this.isNewEntry()) {
                dialog_options.message += "<br><br>Staff Members from " + this.staffing_detail.staff_group_name +  " with this Job Title will no longer be eligible to work in this Department."
            }

            if (is_last_staffing_detail_for_staff_group) {
                dialog_options.message += "<br><br>Removing this Job Title will remove the Staff Group since it is the only one in it."
            }

            this.$refs.confirmationDialog.open(dialog_options)
                .then(() => {
                    if (this.isNewEntry()) {
                        this.onDeleteConfirmed(is_last_staffing_detail_for_staff_group)
                        this.$snackNotify('success', 'Staffing Detail deleted.')
                        return
                    }

                    this.deleting_staffing_detail = true
                    this.api.delete('/department-staffing-detail/' + this.staffing_detail._id)
                        .then(() => {
                            this.onDeleteConfirmed(is_last_staffing_detail_for_staff_group)
                            this.$snackNotify('success', 'Staffing Detail deleted.')
                        })
                        .finally(() => {
                            this.deleting_staffing_detail = false
                        })
                })
                .catch(() => {})
        },

        onDeleteConfirmed(is_last_staffing_detail_for_staff_group = false) {
            const payload = {
                staff_group_id: this.staffing_detail.staff_group_id,
                staffing_detail_index: this.index,
                is_last_staffing_detail_for_staff_group: is_last_staffing_detail_for_staff_group
            }

            this.$store.dispatch('removeStaffingDetail', payload)
        },
    },
    watch: {
        staffingDetail: {
            immediate: true,
            handler: function(staffing_detail) {
                this.staffing_detail = staffing_detail
                this.managers = this.reformatManagersId(staffing_detail.managers)
                this.job_title_id = staffing_detail.job_title_id
            }
        },
    }
}
</script>

<style scoped>
    .staffing-detail-buttons-container {
        width: 105px;
    }

    .staffing-detail-job-title-input {
        flex: 1;
        margin-right: 16px;
    }

    .staffing-detail-managers-input {
        flex: 1
    }
</style>
