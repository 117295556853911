<template>
    <v-card>
        <RemoveNotificationToolbar
            ref="removeNotificationToolbar"
            :notification-id="notification.id"
            :is-removing="isRemoving"
            :title="notification.title"
            @onBackPressed="onBackPressed"
            @onDeleteNotification="deleteNotification"
        ></RemoveNotificationToolbar>
        <v-card-text>
            <v-container class="pa-0" fluid>
                <ShiftNotificationDetails
                    :shift="notification.shift"
                ></ShiftNotificationDetails>
                <v-row class="pl-4 pr-4 mt-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        :href="notification.shift.url"
                        @click="onViewSchedule"
                        :loading="isLoading"
                        :disabled="isLoading"
                    >
                        View schedule
                    </v-btn>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import RemoveNotificationToolbar from '../../components/notification/RemoveNotificationToolbar'
    import ShiftNotificationDetails from '../../components/notification/ShiftNotificationDetails'

    export default {
        name: "ShiftDetails",
        components: {RemoveNotificationToolbar, ShiftNotificationDetails},
        props: {
            notification: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        data: function() {
            return {
                isRemoving: false,
                isLoading: false
            }
        },
        methods: {
            onBackPressed: function() {
                this.$emit('onBackPressed')
            },
            deleteNotification: function(notificationId) {
                this.isRemoving = true
                setTimeout(() => {
                    this.isRemoving = false
                    this.$refs.removeNotificationToolbar.closeDialog()
                    this.$emit('onDeleteNotification', notificationId)
                }, 2000)
            },
            onViewSchedule: function() {
                this.isLoading = true
            }
        }
    }
</script>

<style scoped>

</style>
