<template>
    <v-text-field
        class="custom-field-input"
        :name="`custom-field-${customField.name}`"
        :label="customField.name"
        v-model="value"
        outlined
        :clearable="hasPermission && !api.busy"
        :readonly="api.busy || !hasPermission"
        @click:clear="onClear"
        @change="onChange"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
    >
        <template v-slot:append>
            <v-slide-y-reverse-transition>
                <v-btn
                    v-if="show_save_button"
                    icon
                    small
                    color="primary"
                    class="custom-field-save-btn"
                    :disabled="api.busy || !hasPermission"
                    :loading="api.busy"
                    @click="onSave"
                >
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-slide-y-reverse-transition>
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: "CustomField",
    props: {
        customField: {
            type: Object,
            default: () => {}
        },
        ownerEntityId: {
            type: String,
            default: null
        },
        context: {
            type: String,
            default: null
        },
        hasPermission: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            api: new formHelper,
            value: this.customField.value,
            show_save_button: false,
        }
    },
    methods: {
        showSaveButton() {
            if (this.hasPermission && this.unsaved_changes) {
                this.show_save_button = true
            }
        },
        hideSaveButton() {
            this.show_save_button = false
        },
        onFocus() {
            if (this.unsaved_changes) {
                this.showSaveButton()
            }
        },
        onBlur() {
            if (!this.unsaved_changes) {
                this.hideSaveButton()
            }
        },
        onInput() {
            if (this.unsaved_changes) {
                this.showSaveButton()
            } else {
                this.hideSaveButton()
            }
        },
        onChange() {
            if (this.value === '') {
                this.value = null
            }
            this.showSaveButton()
        },
        onClear() {
            if (!this.hasPermission) {
                this.value = null; // Clearing the value if no permission
            }
        },
        onSave() {
            if (this.hasPermission) {
                this.update()
            }
        },
        update() {
            if (!this.context) {
                return
            }

            const url = this.getEndpointUrl()

            if (!url) {
                return
            }

            const custom_field_data = {
                id: this.customField._id,
                name: this.customField.name,
                value: this.value
            }

            this.api.post(url, custom_field_data)
                .then(({data}) => {
                    this.value = data.value
                    this.$emit('update', {custom_field_id: this.customField._id, value: data.value})
                    this.hideSaveButton()
                    this.$snackNotify('success', 'Custom Field updated.')
                })
        },
        getEndpointUrl() {
            switch (this.context) {
                case 'staff-group':
                    return `/staff-group/custom-field/${this.ownerEntityId}`
                case 'department':
                    return `/department/custom-field/${this.ownerEntityId}`
                default:
                    return null;
            }
        },
    },
    computed: {
        unsaved_changes() {
            return this.value !== this.customField.value
        }
    },
}
</script>

<style scoped>

.custom-field-save-btn {
    width: 24px !important;
    height: 24px !important;
    margin-top: -1px;
}
</style>
