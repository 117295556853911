<template>
    <v-card-text>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field
                        name="username-search"
                        @keyup.enter="onSearch"
                        v-model="searchParams.name"
                        label="Search by Name"
                        class="pt-0"
                        prepend-inner-icon="mdi-magnify"
                        @click:prepend-inner="onSearch"
                        outlined
                        clearable
                        @click:clear="nameCleared"
                        :loading="api.busy"
                        :disabled="api.busy || loadingUsers"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" md="2" class="pt-0 pb-0">
                    <v-autocomplete
                        dusk="statusFilterDropdown"
                        v-if="isEmployeeRole || isManagerRole"
                        v-model="searchParams.status"
                        :items="active_fields"
                        item-text="name"
                        item-value="id"
                        label="Status"
                        class="pt-0"
                        :loading="api.busy"
                        :disabled="api.busy || loadingUsers"
                        clearable
                        outlined
                        @change="onSearch"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-autocomplete
                        v-if="isEmployeeRole || isManagerRole"
                        v-model="searchParams.staff_group"
                        :items="staff_groups"
                        item-text="name"
                        item-value="_id"
                        label="Staff Group"
                        class="pt-0"
                        :loading="api.busy"
                        :disabled="api.busy || loadingUsers"
                        clearable
                        outlined
                        @change="onChangeStaffGroup"
                        dusk="staffGroupDropdown"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-autocomplete
                        v-if="isEmployeeRole"
                        v-model="searchParams.job_title"
                        :items="jobTitlesForStaffGroup"
                        item-value="_id"
                        item-text="title"
                        label="Job Title"
                        class="pt-0"
                        :loading="api.busy"
                        :disabled="api.busy || loadingUsers"
                        clearable
                        outlined
                        :no-data-text="this.searchParams.staff_group ? 'Staff group has no job titles' : 'Select a staff group'"
                        @change="onSearch"
                        dusk="jobTitleDropdown"
                    ></v-autocomplete>
                    <v-select
                        v-if="isManagerRole"
                        v-model="searchParams.department"
                        :items="filteredDepartments"
                        item-value="_id"
                        item-text="name"
                        label="Departments"
                        class="pt-0"
                        :loading="api.busy"
                        :disabled="api.busy || loadingUsers"
                        :menu-props="{ 'offset-y': true }"
                        outlined
                        clearable
                        @change="onSearch"
                        dusk="departmentDropdown"
                    ></v-select>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
</template>

<script>
    import userFilters from "../../lib/mixins/userFilters";

    export default {
        name: "UserSearch",
        mixins: [userFilters],
        props: {
            loadingUsers: {
                type: Boolean,
                default: false,
            },
            role: {
                type: String,
                default: null,
            },
            departments: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                searchParams: {
                    name: null,
                    status: null,
                    department: null,
                    staff_group: null,
                    job_title: null,
                    managers: null,
                },
                api: new formHelper(),
                staff_groups: [],
                active_fields: [
                    {
                        id: 'active',
                        name: 'Active'
                    },
                    {
                        id: 'inactive',
                        name: 'Inactive'
                    },
                ],
            }
        },
        methods: {
            resetFilters() {
                this.searchParams.status = null;
                this.searchParams.name = null;
                this.searchParams.department = null;
                this.searchParams.staff_group = null;
                this.searchParams.job_title = null;
                this.searchParams.managers = null;
            },
            loadStaffGroups() {
                this.api.get("/staff-groups/list-for-location")
                    .then(({data, message}) => {
                        this.staff_groups = data
                    });
            },
            nameCleared() {
                this.$set(this.searchParams, 'name', null)
                this.onSearch()
            },
            onSearch() {
                this.$emit('onSearch', this.searchParams)
            },
            onChangeStaffGroup() {
                this.searchParams = {
                    ...this.searchParams,
                    job_title: null,
                }
                this.onSearch()
            },
        },

        computed: {
            globalLocation() {
                return this.$root.globalLocation;
            },

            staffGroupFilter() {
                return this.searchParams.staff_group
            },

            jobTitlesForStaffGroup() {
                if (!this.staff_groups.length) {
                    return [];
                }

                let staff_group = this.staff_groups.find(staff_group => staff_group._id === this.searchParams.staff_group)

                if (staff_group && typeof staff_group.job_titles !== 'undefined' && staff_group.job_titles.length) {
                    return staff_group.job_titles
                }

                return []
            },

            filteredDepartments() {
                const { globalLocation, departments } = this
                if(globalLocation && globalLocation._id) {
                    return departments.filter(department => department.location_id === globalLocation._id)
                }
                return departments
            },

            isEmployeeRole() {
                return this.role === 'Staff'
            },

            isManagerRole() {
                return this.role === 'Manager'
            }
        },

        watch: {
            globalLocation() {
                this.resetFilters()
                this.loadStaffGroups()
            },

            staffGroupFilter(newValue) {
                if (newValue && this.searchParams.job_title) {
                    this.searchParams.job_title = null
                }
            }
        },

        created() {
          this.loadStaffGroups()
        }
    }
</script>

<style scoped>
  @media only screen and (min-width: 450px) {
    .radio-grp-no-wrap :deep(.v-input--radio-group__input) {
      flex-wrap: nowrap;
    }
  }
</style>
