<template>
    <v-window v-model="windowModel" class="department-card-window-container">
        <v-window-item value="front" style="padding: 5px 0;">
            <v-card :width="392" elevation="2" style="border-radius: 12px !important;">
                <div class="department-card-title-skeleton-container" v-if="loading">
                    <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                </div>
                <department-card-title
                    v-else
                    :title="title"
                    :disabled="loading"
                    @onClick="windowModel = 'rear'">
                </department-card-title>
                <div class="department-card-content-container">
                    <department-card-content-wrapper title="Shifts" :subtitle="totalShifts.toString()" :loading="loading">
                        <shifts-doughnut-chart
                            :half="false"
                            :loading="loading"
                            :percentage="shiftsPercentage"
                        />
                    </department-card-content-wrapper>
                    <department-card-content-wrapper title="Offers" :subtitle="totalOffers.toString()" :loading="loading">
                        <shift-offers-doughnut-chart
                            :half="false"
                            :loading="loading"
                            :percentage="offersPercentage"
                        />
                    </department-card-content-wrapper>
                </div>
                <department-card-actions :loading="loading" :department-href="departmentHref"></department-card-actions>
            </v-card>
        </v-window-item>
        <v-window-item value="rear" style="padding: 5px 0;">
            <v-card :width="392" elevation="2" style="border-radius: 12px !important;">
                <div class="department-card-title-skeleton-container" v-if="loading">
                    <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                </div>
                <department-card-title
                    v-else
                    :title="title"
                    :disabled="loading"
                    @onClick="windowModel = 'front'">
                </department-card-title>
                <div class="department-card-content-container">
                    <department-card-content-wrapper title="Shifts" class="mr-5">
                        <div class="department-card-text-container text--paragraph mt-2">
                            <span>Filled</span>
                            <span>{{ filledShifts }}</span>
                        </div>
                        <div class="department-card-text-container text--paragraph mt-2">
                            <span>Open</span>
                            <span>{{ openShifts }}</span>
                        </div>
                        <div class="department-card-text-divider mt-2">

                        </div>
                        <div class="department-card-text-container text--paragraph mt-2 font-weight-bold">
                            <span>Total</span>
                            <span>{{ totalShifts }}</span>
                        </div>
                    </department-card-content-wrapper>
                    <department-card-content-wrapper title="Offers" class="ml-5">
                        <div class="department-card-text-container text--paragraph mt-2">
                            <span>Accepted</span>
                            <span>{{ acceptedOffers }}</span>
                        </div>
                        <div class="department-card-text-container text--paragraph mt-2">
                            <span>Rejected</span>
                            <span>{{ rejectedOffers }}</span>
                        </div>
                        <div class="department-card-text-container text--paragraph mt-2">
                            <span>Pending</span>
                            <span>{{ pendingOffers }}</span>
                        </div>
                        <div class="department-card-text-divider mt-2">

                        </div>
                        <div class="department-card-text-container text--paragraph mt-2 font-weight-bold">
                            <span>Total</span>
                            <span>{{ totalOffers }}</span>
                        </div>
                    </department-card-content-wrapper>
                </div>
                <department-card-actions :loading="loading" :department-href="departmentHref"></department-card-actions>
            </v-card>
        </v-window-item>
    </v-window>
</template>

<script>
import DepartmentCardTitle from "./DepartmentCardTitle";
import DepartmentCardContentWrapper from "./DepartmentCardContentWrapper";
import DepartmentCardActions from "./DepartmentCardActions";
import ShiftsDoughnutChart from "../../charts/doughnut/ShiftsDoughnutChart";
import ShiftOffersDoughnutChart from "../../charts/doughnut/ShiftOffersDoughnutChart";
export default {
    name: "DepartmentCard",
    components: {
        DepartmentCardTitle,
        DepartmentCardContentWrapper,
        DepartmentCardActions,
        ShiftsDoughnutChart,
        ShiftOffersDoughnutChart
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        department: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        windowModel: 'front'
    }),
    computed: {
        title() {
            return (this.department || {}).name || ''
        },
        shiftsSummary() {
            return (this.department || {}).shifts || {}
        },
        shiftsPercentage() {
            return this.shiftsSummary.ratio || 0
        },
        filledShifts() {
            return this.shiftsSummary.filled_count || 0
        },
        openShifts() {
            return this.totalShifts - this.filledShifts
        },
        totalShifts() {
            return this.shiftsSummary.total_count || 0
        },
        offersSummary() {
            return (this.department || {}).offers || {}
        },
        offersPercentage() {
            return this.offersSummary.ratio || 0
        },
        acceptedOffers() {
            return this.offersSummary.accepted_count || 0
        },
        rejectedOffers() {
            return this.offersSummary.rejected_count || 0
        },
        pendingOffers() {
            return this.offersSummary.pending_count || 0
        },
        totalOffers() {
            return this.offersSummary.total_count || 0
        },
        departmentHref() {
            const depId = (this.department || {})._id
            const locId = (this.department || {}).location_id
            if(depId && locId) {
                return `/schedules/?department_id=${depId}&location_id=${locId}`
            }
            return null
        }
    }
}
</script>

<style scoped>
    .department-card-window-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 396px;
    }

    .department-card-content-container {
        display: flex;
        flex-direction: row;
        padding: 16px;
    }

    .department-card-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .department-card-text-divider {
        width: 100%;
        border-top: 2px solid black;
    }

    .department-card-title-skeleton-container {
        padding: 15px 25px 0;
    }
</style>
