<template>
    <div class="d-flex flex-column align-center px-8" style="width: 100%;">
        <p class="text--h1">
            Sync with SSO
        </p>
        <p class="text--paragraph text-center px-16">
            Koroid connects you to your private and secure instance of our application using your SSO credentials.
            Koroid does not store this information.
        </p>
        <p class="text--paragraph text-center mt-5">
            Use the button below to sign in and sync your information with Koroid.
        </p>
        <div class="d-flex justify-center align-center" style="margin-bottom: 32px; margin-top: 16px;">
            <v-btn
                color="secondary"
                href="/authorize-onboarding-owner"
            >
                Sync with SSO
            </v-btn>
        </div>
        <p class="text--paragraph text-center">
            This link will redirect you to a new page.<br />
            After signing in you will return here automatically.
        </p>
        <div class="d-flex flex-row justify-space-between" style="width: 100%; position: relative; top: 40px;">
            <v-btn
                color="primary"
                text
                @click="onClickPrevious"
            >
                Previous
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: "SyncWithSso",
    methods: {
        onClickPrevious() {
            this.$emit('onClickPrevious')
        },
    }
}
</script>

<style scoped>

</style>
