<template>
    <v-list-item
        :disabled="notClickable"
        :class="{ 'call-out-background': callOut, 'no-show-background': noShow }"
        @click="onClick"
        style="padding-left: 8px; padding-right: 8px;"
    >
        <v-list-item-icon :style="iconContainerStyle">
            <v-tooltip v-if="requestedCancellation" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <div
                        v-bind="attrs"
                        v-on="on"
                        style="pointer-events: auto;"
                    >
                        <v-badge
                            color="error"
                            icon="mdi-exclamation"
                            bottom
                            :offset-x="18"
                            :offset-y="12"
                        >
                        
                        <v-tooltip v-if="noShow || callOut" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon  color="error" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                                    mdi-close-circle
                                </v-icon>
                            </template>
                                <span>No Show Recorded</span>
                            </v-tooltip>
                                <v-icon v-else color="success" style="font-size: 28px;">
                                    mdi-check-circle
                                </v-icon>
                        </v-badge>
                    </div>
                </template>
                <span>Cancellation Request Pending</span>
            </v-tooltip>
            <v-tooltip v-else-if="timeUpdated" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <div
                        v-bind="attrs"
                        v-on="on"
                        style="pointer-events: auto;"
                    >
                        <v-badge
                            color="warning"
                            icon="mdi-clock-alert-outline"
                            bottom
                            :offset-x="18"
                            :offset-y="12"
                        >
                            
                            <v-tooltip v-if="noShow || callOut" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                                        mdi-close-circle
                                    </v-icon>
                                </template>
                                <span>{{noShow ? 'No Show Recorded' : 'Call Out Recorded'}}</span>
                            </v-tooltip>
                            <v-icon v-else color="success" style="font-size: 28px;">
                                mdi-check-circle
                            </v-icon>
                        </v-badge>
                    </div>
                </template>
                <span>Shift Time Edited</span>
            </v-tooltip>
            <v-tooltip v-else-if="noShow" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                        mdi-close-circle
                    </v-icon>
                </template>
                <span>No Show Recorded</span>
            </v-tooltip>
            <v-tooltip v-else-if="callOut" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                        mdi-close-circle
                    </v-icon>
                </template>
                <span>Call Out Recorded</span>
            </v-tooltip>
            <v-tooltip v-else-if="assigned" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="success" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                        mdi-check-circle
                    </v-icon>
                </template>
                <span>Assigned to Shift</span>
            </v-tooltip>
            <v-tooltip v-else-if="offered" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                        mdi-timer-sand
                    </v-icon>
                </template>
                <span>Shift Offer Pending</span>
            </v-tooltip>
            <v-tooltip v-else-if="rejected" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" style="font-size: 28px; pointer-events: auto;" v-bind="attrs" v-on="on">
                        mdi-close
                    </v-icon>
                </template>
                <span>Shift Offer Declined</span>
            </v-tooltip>
        </v-list-item-icon>
        <v-list-item-avatar>
            <custom-avatar
                :user="user"
                :size="40"
                user-chip>
            </custom-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="pl-2">
            <v-list-item-title class="text--paragraph text--darken-5">
                {{ title }}
            </v-list-item-title>
            <v-list-item-subtitle class="blue-grey--text text--darken-2">
                <v-tooltip v-if="overtime" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-small color="error">
                                mdi-alert
                            </v-icon>
                            <span class="error--text text--caption">
                                    {{ subtitle }}
                            </span>
                        </div>
                    </template>
                    <span>Staff exceeds max work hours</span>
                </v-tooltip>
                <v-tooltip v-else-if="toBeOvertime" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-small color="error">
                                mdi-alert
                            </v-icon>
                            <span class="error--text text--caption">
                                    {{ subtitle }}
                            </span>
                        </div>
                    </template>
                    <span>Staff will exceed max work hours</span>
                </v-tooltip>
                <span v-else class="text--caption">
                        {{ subtitle }}
                </span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action style="padding-top: 16px; padding-bottom: 12px; margin-top: 0; margin-bottom: 0;">
            <v-btn
                v-if="removeExists"
                color="error"
                text
                :disabled="removeDisabled"
                @click="onRemove"
            >Remove</v-btn>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import CustomAvatar from '../../../avatar/CustomAvatar'
import userDetails from '../../../../lib/mixins/userDetails'

export default {
    name: "PresentationalUserListItem",
    components: { CustomAvatar },
    mixins: [userDetails],
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        inThePast: {
            type: Boolean,
            default: false,
        },
        hasPermission: {
            type: Boolean,
            default: true,
        },
        isShiftStartingSoon: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        iconContainerStyle () {
            return {
                'margin-right': 0,
                'padding-top': this.requestedCancellation || this.timeUpdated ? '16px' : '20px',
                'padding-bottom': '12px',
                'margin-top': 0,
                'margin-bottom': 0,
                'min-width': '34px',
            }
        },
        notClickable () {
            return this.offered || !this.hasPermission || this.rejected
        },
        removeExists () {
            return !this.noShow && !this.callOut && !this.offered && !this.inThePast && !this.rejected
        },
        removeDisabled() {
            const removeDisabled = this.requestedCancellation || !this.hasPermission

            // If the user is a manager, check the 24-hour buffer
            if (this.$authIsManager) {
                return this.isShiftStartingSoon;
            }

            return removeDisabled;

        },
    },
    methods: {
        stopPropagation (event) {
            if(event && event.stopPropagation && typeof event.stopPropagation === 'function') {
                event.stopPropagation()
            }
        },
        onClick () {
            if (!this.notClickable) {
                this.$emit('onEditUser', this.shiftId)
            }
        },
        onRemove (event) {
            this.stopPropagation(event)
            this.$emit('onRemoveUser', this.user._id)
        }
    }
}
</script>

<style scoped>
    .remove-btn {
        text-transform: none !important;
    }
    .no-show-background {
        background-color: rgba(209, 52, 52, 0.2);
    }

    /* TODO: Update color */
    .call-out-background {
        background-color: rgba(241, 184, 59, 0.2);
    }
    .content-not-clickable {
        pointer-events: none;
    }
</style>
