<template>
    <v-container grid-list-xl fluid>
        <v-row>
            <v-col cols="12">

                <v-window v-model="window">
                    <v-window-item value="organization">
                        <organization-window
                            ref="organizationWindow"
                            @location:view="openLocationWindow"
                            @configuration-options:view="onViewConfigurationOptions"
                        />
                    </v-window-item>

                    <v-window-item value="location" eager>
                        <location-window
                            ref="locationWindow"
                            :selected-location="selected_location"
                            @back:organization="onBackFromLocationWindow"
                            @view:staff_group="onViewStaffGroup"
                            @view:department="onViewDepartment"
                        />
                    </v-window-item>

                    <v-window-item value="staff_group" eager>
                        <staff-group-window
                            ref="staffGroupWindow"
                            :selected-staff-group="selected_staff_group"
                            @back:location="onBackFromStaffGroupWindow"
                        />
                    </v-window-item>

                    <v-window-item value="department" eager>
                        <department-window
                            ref="departmentWindow"
                            :selected-department="selected_department"
                            @back:location="onBackFromDepartmentWindow"
                        />
                    </v-window-item>

                    <v-window-item value="configuration_options" eager>
                        <configuration-options-window
                            @back:organization="onBackFromConfigurationOptionsWindow"
                        />
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
    import OrganizationWindow from "./Organization/OrganizationWindow";
    import LocationWindow from "./Location/LocationWindow";
    import StaffGroupWindow from "./StaffGroup/StaffGroupWindow";
    import ConfigurationOptionsWindow from "./ConfigurationOptions/ConfigurationOptionsWindow";
    import DepartmentWindow from "./Department/DepartmentWindow";

    export default {
        name: "index",
        components: {
            DepartmentWindow,
            ConfigurationOptionsWindow,
            StaffGroupWindow,
            LocationWindow,
            OrganizationWindow,
        },
        data() {
            return {
                window: 'organization',
                selected_location: null,
                selected_staff_group: null,
                selected_department: null
            }
        },
        methods: {
            openLocationWindow({location, location_created}) {
                this.selected_location = location
                this.$refs.locationWindow.setLocationUpdated(location_created)
                this.changeWindow('location')
            },

            openOrganizationWindow() {
                this.changeWindow('organization')
            },

            changeWindow(window) {
                this.window = window
            },

            onBackFromLocationWindow({location_updated}) {
                this.selected_location = null

                if (location_updated) {
                    this.triggerLocationsRefresh()
                }

                this.openOrganizationWindow()
            },

            triggerLocationsRefresh() {
                this.$refs.organizationWindow.loadLocations()
            },

            onViewStaffGroup({staff_group}) {
                this.selected_staff_group = staff_group
                this.changeWindow('staff_group')
            },

            onBackFromStaffGroupWindow({staff_group_updated}) {
                if (staff_group_updated) {
                    this.selected_staff_group = null
                    this.selected_department = null
                    this.$refs.locationWindow.loadLocationDetails()
                }

                this.changeWindow('location')
            },

            onViewDepartment({department}) {
                this.selected_department = department
                this.changeWindow('department')
            },

            onBackFromDepartmentWindow({department_updated}) {
                if (department_updated) {
                    this.selected_department = null
                    this.$refs.locationWindow.loadLocationDetails()
                }

                this.changeWindow('location')
            },

            onViewConfigurationOptions() {
                this.changeWindow('configuration_options')
            },

            onBackFromConfigurationOptionsWindow() {
                this.openOrganizationWindow()
            }
        }
    }
</script>

<style scoped>

</style>
