<template>
    <v-dialog
        v-model="modalValue"
        scrollable width="500px"
        content-class="ds-dialog"
        persistent
    >
        <v-card class="ds-modal-card">
            <v-toolbar class="destructive-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Delete Job Title
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content">
                <p class="black--text">
                    You are attempting to delete a Job Title from this User.
                </p>
                <p class="black--text">
                    This action can not be reversed. Would you like to delete this Job Title?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel" :disabled="isDeleting">Cancel</v-btn>
                        <v-btn class="ml-2" color="error" outlined @click="onDelete" :loading="isDeleting">Delete
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteJobTitleModal",
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        userId: {
            type: String,
            default: null,
        },
        jobId: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        isDeleting: false,
        api: new formHelper(),
    }),
    computed: {
        modalValue: {
            get() {
                return Boolean(this.value)
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCancel() {
            this.modalValue = null
        },
        onDelete() {
            if (this.value.isSavedInDb) {
                this.isDeleting = true
                this.api.delete(`/user/remove-job/${this.userId}/${this.jobId}`)
                    .then(({data}) => {
                        this.$snackNotify('success', 'Job Deleted')
                        this.deleteJobTitle(data?.user_jobs)
                    })
                    .finally(() => {
                        this.isDeleting = false
                    })
            } else {
                this.deleteJobTitle()
            }
        },
        deleteJobTitle(jobs) {
            this.modalValue = null
            this.$emit('onDelete', this.value.job_id, this.value.prevJobId, jobs)
        }
    }
}
</script>

<style scoped>

</style>
