<template>
    <v-dialog v-model="value" max-width="550px" persistent>
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title>
                    <span v-if="(!event || event.cancellation_requested) && !eventDetailsLoading">
                        Shift Cancellation Request Details
                    </span>
                    <span v-else>
                        Shift Details
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="entity_id" icon @click="copy_entity_id" title="Copy Shift ID">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon @click="onModalClose" :disabled="isLoading">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>

                <input type="hidden" :value="entity_id" :id="'id-to-copy-' + entity_id">
            </v-toolbar>

            <v-card-text v-if="eventDetailsLoading" style="height:500px;">
                <loading-spinner :value="true"></loading-spinner>
            </v-card-text>

            <v-card-text v-if="event && !eventDetailsLoading">
                <event-details
                    ref="eventDetailsRef"
                    :date="event_date"
                    :time="event_time"
                    :job-title="event.job_title_name"
                    :location="event.location_name"
                    :staff-group="event.staff_group_name"
                    :department="event.department_name"
                    :duration="event_duration"
                    :address="event.location.primary_address"
                    :requester="requested_by"
                    :status="eventStatus"
                    :primary-event-status="primaryEventStatus"
                    :secondary-event-status="secondaryEventStatus"
                    :no-show="user_was_no_show"
                    :call-out="user_was_call_out"
                    :time-was-edited="shift_time_was_edited"
                    :editable-shift-time="shouldShowEditButton"
                    :event="event"
                    :employee="event.employee_name"
                    :auth-is-authorized="authIsAuthorized"
                ></event-details>

                <v-row v-if="event && authIsAuthorized" class="text-center">
                    <v-col cols="12" v-if="event.cancellation_requested && !isEventStarted">
                        <v-btn
                            name="decline-cancellation"
                            color="error"
                            text
                            :disabled="!canHandleCancellation || isDecliningDisabled"
                            :loading="isDeclining"
                            @click="onDeclineShiftCancelationRequest"
                        >
                            Reject
                        </v-btn>
                        <v-btn
                            name="approve-cancellation"
                            color="primary"
                            outlined
                            :disabled="!canHandleCancellation || isApprovingDisabled"
                            :loading="isApproving"
                            class="ml-3"
                            @click="onApproveShiftCancelationRequest"
                        >
                            Approve
                        </v-btn>
                    </v-col>

                    <v-col class="text-left">
                        <v-btn
                            v-if="!event.cancellation_requested && !isEventStarted && !user_was_call_out"
                            name="remove-user"
                            color="error"
                            text
                            :disabled="!canInteractWithShift || isRemovingDisabled || (isShiftStartInXHours && this.$authIsManager)"
                            :loading="isRemoving"
                            @click="onRemoveUserClicked"
                        >
                            Remove User
                        </v-btn>
                        <v-btn
                            v-if="shouldShowNoShowButton"
                            outlined
                            color="error"
                            :disabled="api.loading || isRemoving"
                            @click="showMarkNoShowModal"
                        >
                            No show
                        </v-btn>
                        <v-btn
                            v-if="shouldShowCallOutButton"
                            outlined
                            color="error"
                            :disabled="api.loading || isRemoving"
                            @click="showMarkCallOutModal"
                        >
                            Call Out
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <call-out-confirmation-dialog ref="callOutConfirmationDialog"/>
        <no-show-confirmation-dialog ref="noShowConfirmationDialog"/>
        <confirmation-dialog ref="confirmationDialog"/>
        <unsaved-changes-dialog ref="unsavedChangesDialogRef"/>
    </v-dialog>
</template>

<script>
    import moment from 'moment-timezone'
    import EventDetails from "../../common/EventDetails/EventDetails";
    import LoadingSpinner from "../../loaders/LoadingSpinner";
    import NoShowConfirmationDialog from "./NoShowConfirmationDialog";
    import CallOutConfirmationDialog from "./CallOutConfirmationDialog";
    import ConfirmationDialog from "../../modals/ConfirmationDialog";
    import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";

    export default {
        name: "AssignedEventModal",
        components: {
            ConfirmationDialog,
            NoShowConfirmationDialog,
            LoadingSpinner,
            EventDetails,
            UnsavedChangesDialog,
            CallOutConfirmationDialog
        },
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
            eventProp: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            currentLocation: {
                type: Object,
                default: function() {
                    return null
                }
            },
            authStaffingDetails: {
                type: Array,
                default: function() {
                    return []
                }
            },
        },
        data() {
            return {
                value: false,
                isRemoving: false,
                isRemovingDisabled: false,
                isDeclining: false,
                isDecliningDisabled: false,
                isApproving: false,
                isApprovingDisabled: false,
                api: new formHelper(),
                PERMISSIONS: this.$config.permissions,
                eventStatus: null,
                primaryEventStatus: null,
                secondaryEventStatus: [],
                event: null,
                eventDetailsLoading: false,
            }
        },
        methods: {
            loadEventDetails() {
                this.eventDetailsLoading = true
                this.api.get('/shifts/details/' + this.eventProp._id)
                    .then(({data, message}) => {
                        this.setEventData(data)
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.eventDetailsLoading = false
                    })
            },

            setEventData(data) {
                this.event = data

                this.event.employee_name = null
                if (typeof data.employee !== 'undefined' && data.employee) {
                    this.event.employee_name = data.employee.first_name + " " + data.employee.last_name
                }

                this.primaryEventStatus = this.getAssignedShiftPrimaryStatus(this.event)

                if (this.event.cancellation_requested) {
                    this.setSecondaryEventStatus(this.$config.SECONDARY_SHIFT_STATUSES.PENDING_CANCELLATION_REQUEST)
                }
                if (this.user_was_call_out) {
                    this.setSecondaryEventStatus(this.$config.SECONDARY_SHIFT_STATUSES.CALL_OUT)
                }
                if (this.user_was_no_show) {
                    this.setSecondaryEventStatus(this.$config.SECONDARY_SHIFT_STATUSES.NO_SHOW)
                }
                if (this.shift_time_was_edited) {
                    this.setSecondaryEventStatus(this.$config.SECONDARY_SHIFT_STATUSES.SHIFT_TIME_EDITED)
                }
            },

            parseEventTime: function(date) {
                let newDate = moment(date).tz(this.globalTimezone)

                let start = newDate.format('dddd, MMMM D')
                let end = newDate.format('LT')
                return `${start} at ${end}`
            },
            onModalClose: function() {
                if (this.$refs) {
                    const eventDetailsRef = this.$refs.eventDetailsRef
                    if (eventDetailsRef) {
                        if (eventDetailsRef.$refs) {
                            const editableSectionRef = eventDetailsRef.$refs.editableSection
                            if (editableSectionRef) {
                                if (editableSectionRef.is_save_button_enabled) {
                                    if (this.unsavedChangesDialogRef) {
                                        this.unsavedChangesDialogRef.open()
                                            .then(() => {
                                                this.closeModal()
                                            })
                                            .catch(() => {})
                                        return
                                    }
                                }
                            }
                        }
                    }
                }
                this.closeModal()
            },
            closeModal () {
                this.event = null
                this.isRemovingDisabled = false
                this.isDecliningDisabled = false
                this.isApprovingDisabled = false
                this.$emit('onModalClose')
            },
            onDeclineShiftCancelationRequest: function() {
                this.isDeclining = true
                this.api.post('/shifts/decline-cancellation-request/' + this.getPendingCancellationRequestId(this.event))
                    .then(({data, message}) => {
                        this.isDeclining = false
                        this.isApprovingDisabled = true
                        this.isDecliningDisabled = true
                        this.eventStatus = 'cancellation denied'
                        this.secondaryEventStatus = this.secondaryEventStatus.filter(
                            status => status !== this.$config.SECONDARY_SHIFT_STATUSES.PENDING_CANCELLATION_REQUEST &&
                                status !== this.$config.SECONDARY_SHIFT_STATUSES.DENIED_CANCELLATION_REQUEST
                        )
                        this.secondaryEventStatus = [
                            ...this.secondaryEventStatus,
                            this.$config.SECONDARY_SHIFT_STATUSES.DENIED_CANCELLATION_REQUEST
                        ]
                        const { first_name, last_name } = this.event.employee
                        this.$snackNotify('success', `${first_name} ${last_name}'s cancelation request rejected.`)
                        this.$emit('onDeclineShiftCancelationRequest', this.event)
                    })
                    .catch(({data, message}) => {
                        if (typeof data.message !== 'undefined') {
                            message(data.message)
                            this.isDeclining = false
                        }
                    })
            },
            onApproveShiftCancelationRequest: function() {
                this.isApproving = true
                this.api.post('/shifts/approve-cancellation-request/' + this.getPendingCancellationRequestId(this.event))
                    .then(({data, message}) => {
                        this.isApproving = false
                        this.isApprovingDisabled = true
                        this.isDecliningDisabled = true
                        this.eventStatus = 'rejected'
                        this.primaryEventStatus = this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_CANCELLED
                        this.secondaryEventStatus = []
                        const { first_name, last_name } = this.event.employee
                        message(`Cancelation request approved. ${first_name} ${last_name} removed from shift.`)
                        this.$emit('onApproveShiftCancelationRequest')
                    })
                    .catch(({data, message}) => {
                        if (typeof data.message !== 'undefined') {
                            message(data.message)
                            this.isApproving = false
                        }
                    })
            },

            onRemoveUserClicked() {
                const options = {
                    title: 'Removing Assigned Staff',
                    titleClass: 'primary--text',
                    message: 'You are removing a Staff Member from an assigned shift. Would you like to continue?',
                    cancelText: 'No',
                    cancelColor: 'secondary',
                    confirmText: 'Yes',
                    toolbarColor: 'warning',
                    icon: 'mdi-alert',
                }

                this.$refs.confirmationDialog
                    .open(options)
                    .then(() => {
                        this.unassignShift()
                    })
                    .catch(err => {})
            },

            unassignShift: function() {
                this.isRemoving = true
                this.api.post('/shift-request/unassign/' + this.event.shift_request_id, {user_id: this.event.employee_id})
                    .then(({data, message}) => {
                        this.isRemoving = false
                        this.isRemovingDisabled = true
                        this.eventStatus = 'rejected'
                        this.primaryEventStatus = this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_CANCELLED
                        this.secondaryEventStatus = []
                        const { first_name, last_name } = this.event.employee
                        this.$snackNotify('success', `${first_name} ${last_name} removed from shift.`)

                        if (typeof data.shift.sms_failed !== 'undefined' && data.shift.sms_failed) {
                            this.$snackNotify('warning', `${first_name} ${last_name} removed from shift and in-app notification sent. SMS notification could not be sent.`)
                        }
                        this.$emit('onUnassignShift')
                    })
                    .catch(({data, message}) => {
                        if (typeof data.message !== 'undefined') {
                            message(data.message)
                            this.isRemoving = false
                        }
                    })
            },

            copy_entity_id() {
                if (typeof this.event._id === 'undefined') {
                    return false
                }

                let copy_field = document.querySelector('#id-to-copy-' + this.event._id)
                copy_field.setAttribute('type', 'text')
                copy_field.focus()
                copy_field.select()

                try {
                    let success = document.execCommand('copy')
                    if (success) {
                        this.$snackNotify('success', `Shift ID copied to your clipboard`)
                    }
                } catch (err) {
                    this.$snackNotify('warning', 'Unable to copy the shift ID. Please try again.')
                }

                copy_field.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
            },

            showMarkNoShowModal() {
                let staff_member = 'this employee'
                if (typeof this.event.employee !== 'undefined') {
                    staff_member = this.event.employee.full_name
                }

                this.$refs.noShowConfirmationDialog
                    .open(staff_member)
                    .then(() => {
                        this.markNoShow()
                    })
            },

            markNoShow() {
                this.api.post('/shifts/no-show/' + this.event._id)
                    .then(({data, message}) => {
                        this.$emit('onMarkNoShow')
                        this.setEventData(data)
                        this.setSecondaryEventStatus(this.$config.SECONDARY_SHIFT_STATUSES.NO_SHOW)
                    }).catch(() => {})
            },

            showMarkCallOutModal() {
                let staff_member = 'this employee'
                if (typeof this.event.employee !== 'undefined') {
                    staff_member = this.event.employee.full_name
                }

                this.$refs.callOutConfirmationDialog
                    .open(staff_member)
                    .then(() => {
                        this.markCallOut()
                    })
            },

            markCallOut() {
                this.api.post('/shifts/call-out/' + this.event._id)
                    .then(({data, message}) => {
                        this.$emit('onMarkCallOut')
                        this.setEventData(data)
                        this.secondaryEventStatus(this.$config.SECONDARY_SHIFT_STATUSES.CALL_OUT)
                    }).catch(() => {})
            },

            getPendingCancellationRequestId(event) {
                if (event) {
                    if (event.pending_cancellation_request) {
                        return event.pending_cancellation_request._id
                    }
                    return event._id
                }
                return null
            },

            getIsShiftCompleted(event) {
                if (event && event.end) {
                    const now = moment()
                    const eventEnd = moment(event.end).tz(this.globalTimezone)
                    return now.isAfter(eventEnd)
                }
                return false
            },

            getAssignedShiftPrimaryStatus(event) {
                if (this.getIsShiftCompleted(event)) {
                    return this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_COMPLETED
                } else {
                    return this.$config.PRIMARY_SHIFT_STATUSES.SHIFT_ASSIGNED
                }
            },

            setSecondaryEventStatus(secondaryStatus) {
                if (!this.secondaryEventStatus.some(status => status === secondaryStatus)) {
                    this.secondaryEventStatus = [
                        ...this.secondaryEventStatus,
                        secondaryStatus
                    ]
                }
            }
        },
        computed: {
            globalTimezone() {
                return this.$root.globalTimezone
            },
            isLoading () {
                return this.eventDetailsLoading || this.isRemoving || this.isDeclining || this.isApproving || this.api.loading
            },
            event_date() {
                if(this.event) {
                    return moment(this.event.start).tz(this.globalTimezone).format('dddd, MMMM D')
                }
                return ''
            },
            event_time() {
                if (this.event) {
                    let start = moment(this.event.start).tz(this.globalTimezone)
                    let end = moment(this.event.end).tz(this.globalTimezone)
                    return `${start.format('LT')} - ${end.format('LT')}`
                }

                return ''
            },
            event_duration() {
                if(this.event) {
                    return this.$options.filters.timeStringFromMinutes(this.event.duration)
                }
                return ''
            },
            requested_by: function() {
                if(this.event && this.event.created_by) {
                    const { first_name, last_name } = this.event.created_by
                    return `${first_name} ${last_name}`
                }
                return ''
            },

            isEventStarted: function() {
                if (this.event) {
                    const eventDate = moment(this.event.start).tz(this.globalTimezone)
                    const now = moment().tz(this.globalTimezone)
                    return eventDate.isBefore(now)
                }

                return true
            },
            isShiftStartInXHours: function() {
                if (this.event) {
                    const eventDate = moment(this.event.start).tz(this.globalTimezone);
                    const twentyFourHoursFromNow = moment().tz(this.globalTimezone).add(24, 'hours');
                    const result = eventDate.isBefore(twentyFourHoursFromNow);
                    return result;
                }

                return false;
            },
            isEventEnded: function() {
                if (this.event) {
                    const eventDate = moment(this.event.end).tz(this.globalTimezone)
                    const now = moment().tz(this.globalTimezone)
                    return eventDate.isBefore(now)
                }

                return true
            },

            entity_id() {
                if (!this.event || typeof this.event._id === 'undefined') {
                    return false
                }

                return this.event._id
            },
            canInteractWithShift() {
                return this.$userHasPermission(this.$user, this.PERMISSIONS.SHIFTS.UNASSIGN)
            },
            canHandleCancellation() {
                return this.$userHasPermission(this.$user, this.PERMISSIONS.SHIFTS.HANDLE_CANCELLATION)
            },
            canEditShiftTime() {
                return this.$userHasPermission(this.$user, this.PERMISSIONS.REQUEST_FORMS.EDIT)
            },

            shouldShowEditButton() {
                if (!this.canEditShiftTime && !this.user_was_no_show && !this.user_was_call_out) {
                    return false
                }

                return true
            },

            shouldShowNoShowButton() {
                return this.canInteractWithShift && this.isEventEnded && !this.user_was_no_show && !this.user_was_call_out
            },

            shouldShowCallOutButton() {
                return this.canInteractWithShift && !this.user_was_no_show && !this.user_was_call_out && !this.event.cancellation_requested && !this.user_was_unassiged
            },

            authIsAuthorized() {
                if (this.$authIsOwner || this.$authIsAdministrator) {
                    return true
                }

                if (!this.authStaffingDetails) {
                    return false
                }

                const { event } = this

                if (!event) {
                    return false
                }

                const { staff_group_id, department_id, job_title_id } = event

                return this.authStaffingDetails.some(staffing_detail => {
                    return (staffing_detail.staff_group_id === staff_group_id) &&
                        (staffing_detail.department_id === department_id) &&
                        (staffing_detail.job_title_id === job_title_id)
                })
            },

            user_was_unassiged() {
                return this.eventStatus === 'rejected'
            },

            user_was_no_show() {
                if (this.event.status !== 'filled' || !this.event.employee_id) {
                    return false
                }

                if (typeof this.event.no_shows === 'undefined' || !Array.isArray(this.event.no_shows) || !this.event.no_shows.length) {
                    return false
                }

                return this.event.no_shows.some(no_show => no_show.employee_id === this.event.employee_id)
            },

            user_was_call_out() {
                if (this.event.status !== 'filled' || !this.event.employee_id) {
                    return false
                }

                if (typeof this.event.call_outs === 'undefined' || !Array.isArray(this.event.call_outs) || !this.event.call_outs.length) {
                    return false
                }

                return this.event.call_outs.some(call_out => call_out.employee_id === this.event.employee_id)
            },

            shift_time_was_edited() {
                if (!this.event || typeof this.event.time_updated === 'undefined') {
                    return false
                }

                return this.event.time_updated
            },
            unsavedChangesDialogRef() {
                return this.$refs.unsavedChangesDialogRef
            }
        },

        created() {
            window.EventBus.$on('shift-time-updated', (data) => {
                this.$emit('onUpdateShiftTime')
                this.setEventData(data)
            })
        },

        watch: {
            showModal: function(newValue) {
                this.value = newValue

                if (newValue) {
                    this.primaryEventStatus = null
                    this.secondaryEventStatus = []
                    this.loadEventDetails()
                }
            },
        },
    }
</script>

<style scoped>
    .event-detail-row {
        padding: 0;
    }

    .event-detail-label {
        font-size: 12px;
        margin-bottom: 0;
    }

    .event-detail-value {
        color: black;
        margin-bottom: 15px;
        font-size: 16px;
    }
</style>
