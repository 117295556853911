<template>
    <v-card>
        <v-toolbar dark color="warning" elevation="0">
            <v-toolbar-title class="primary--text">
                <div class="d-flex">
                    <v-icon color="primary" class="mr-3">mdi-alert</v-icon>
                    <div class="mt-1">
                        Direct Assign
                    </div>
                </div>
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid style="padding: 12px;">
            <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>

            <v-card-text v-if="!user.direct_assign && !(user.eligible && user.selected)" class="black--text mt-5 body-1">
                You are assigning a Staff Member directly without availability. Would you like to continue?
            </v-card-text>
            <v-card-text v-if="(user.eligible && user.selected)" class="black--text mt-5 body-1">
                Please remove the shift offer before directly assigning shift.
            </v-card-text>
            <v-card-text v-if="user.direct_assign" class="black--text mt-5 body-1">
                You are removing the direct assignment of a Staff Member. Would you like to continue?
            </v-card-text>


            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn v-if="!(user.eligible && user.selected)" class="mr-2" text color="primary" @click="close" :disabled="isLoading">No</v-btn>
                        <v-btn v-if="!(user.eligible && user.selected)" class="ml-2" outlined color="primary" @click="onConfirm" :loading="isLoading">Yes</v-btn>
                        <v-btn v-else class="mr-2" text color="primary" @click="close" :disabled="isLoading">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>

export default {
    name: "DirectAssignModal",
    model: {
        prop: "open",
        event: "change",
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: () => {}
        },
    },
    data: () => ({
        api: new formHelper(),
    }),
    computed: {
        isLoading() {
            return this.user == null;
        },
    },
    methods: {
        close() {
            this.$emit('change', false);
        },
        onConfirm() {
            this.$emit('userAssigned', this.user, true);
            this.close();
        }
    },
}
</script>
