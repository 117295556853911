<template>
    <v-list height="100%" :class="containerClass">
        <div v-if="columnsLoading" class="report-data-loading-container">
            <v-progress-circular color="primary" indeterminate/>
        </div>
        <div v-else>
            <div style="text-align: center;" v-if="error">
                <v-icon
                    color="error"
                    dark
                    small
                >
                    mdi-information-outline
                </v-icon>
                <span class="red--text">You must select at least one field</span>
            </div>
            <div :style="fieldsContainerStyle">
                <div class="select-all-container mb-2">
                    <v-checkbox
                        v-model="selectAll"
                        :readonly="readonly"
                        class="my-0 py-0"
                        color="success"
                        @change="onSelectAllChange"
                    >
                        <template v-slot:label>
                        <span class="text--paragraph black--text">
                            Select All
                        </span>
                        </template>
                    </v-checkbox>
                </div>
                <v-checkbox
                    v-for="({ title, column_name }, key) in columns"
                    v-model="selectedColumns[column_name]"
                    :key="key"
                    :readonly="readonly"
                    class="my-0 py-0 ml-5"
                    @change="onColumnsChange"
                >
                    <template v-slot:label>
                        <span class="text--paragraph black--text">
                            {{ title }}
                        </span>
                    </template>
                </v-checkbox>
            </div>
        </div>
    </v-list>
</template>

<script>
export default {
    name: "ReportDataFilters",
    props: {
        reportType: {
            type: String,
            default: null,
        },
        preset: {
            type: Object,
            default: () => null,
        },
        value: {
            type: Array,
            default: () => []
        },
        error: {
            type: Boolean,
            default: false,
        },
        preselectBasedOnPreset: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        offsetX: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        columns: [],
        columnsLoading: true,
        selectedColumns: {},
        selectAll: false,
        api: new formHelper(),
    }),
    computed: {
        selectedColumnsArr() {
            const { selectedColumns } = this
            return Object.keys(selectedColumns).filter(key => selectedColumns[key])
        },
        containerClass() {
            if(this.readonly) {
                return {
                    'readonly-container': true
                }
            }
            return {}
        },
        fieldsContainerStyle() {
            if(this.offsetX) {
                return {
                    'margin-left': '10px'
                }
            }
            return {}
        }
    },
    methods: {
        loadColumns(reportType) {
            if(reportType) {
                this.columnsLoading = true
                this.$emit('onDataLoading')
                this.api.get(`/reports/columns/${reportType}`)
                    .then(({ data }) => {
                        if(Array.isArray(data.columns)) {
                            this.columns = Array.from(data.columns)
                            this.preselectColumns(this.preset)
                        }
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.columnsLoading = false
                        this.$emit('onDataFetched')
                    })
            }
        },
        selectColumns(columns, select) {
            if(Array.isArray(columns)) {
                columns.forEach(column => {
                    const { column_name } = column
                    if(column_name) {
                        this.selectedColumns = {
                            ...this.selectedColumns,
                            [column_name]: select,
                        }
                    }
                })
            }
        },
        preselectColumns(preset) {
            this.selectColumns(this.columns, false)
            if(preset) {
                const { columns } = preset
                if(Array.isArray(columns)) {
                    columns.forEach(column => {
                        if(this.selectedColumns.hasOwnProperty(column)) {
                            this.selectedColumns = {
                                ...this.selectedColumns,
                                [column]: true,
                            }
                        }
                    })
                }
            }
        },
        onColumnsChange() {
            this.$emit('input', this.selectedColumnsArr)
        },
        onSelectAllChange() {
            this.selectColumns(this.columns, this.selectAll)
            this.$emit('input', this.selectedColumnsArr)
        }
    },
    watch: {
        preset(preset) {
            if(this.preselectBasedOnPreset) {
                this.preselectColumns(preset)
            }
        },
        selectedColumns: {
            immediate: true,
            handler() {
                this.$emit('input', this.selectedColumnsArr)
            }
        }
    },
    mounted() {
        this.loadColumns(this.reportType)
    }
}
</script>

<style scoped>
    .report-data-loading-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .readonly-container {
        background-color: #DCDCDC;
    }
    .select-all-container {
        border-bottom: 1px solid gainsboro;
    }
</style>
