<template>
    <v-dialog v-model="modalValue" scrollable width="500px" persistent>
        <v-card>
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Unsaved Changes
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-6 black--text text--paragraph">
                <p>
                    In order to notify your Staff of any shift assignments and offers you must save this page before exiting.
                </p>
                <p>
                    Would you still like to discard your changes?
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel">Cancel</v-btn>
                        <v-btn class="ml-2" color="primary" outlined @click="onConfirm">Continue</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "UnsavedSchedulingChangesModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        modalValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        onCancel () {
            this.modalValue = false
        },
        onConfirm () {
            this.modalValue = false
            this.$emit('onConfirm')
        },
    }
}
</script>

<style scoped>

</style>
