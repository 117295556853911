<template>
    <time-input
        name="duration"
        :clearable="!readonly"
        required
        controls
        label="Duration"
        placeholder="HH:MM"
        hint="Max: 36hrs"
        v-model="duration"
        :max="2160"
        :mask-tokens="customTokens"
        :error-messages="errorMessages"
        :disabled="disabled"
        :readonly="readonly"
        @change="onChangeDuration"
        @fieldInput="onChangeDuration"
    ></time-input>
</template>

<script>
import TimeInput from '../TimeInput/TimeInput'

export default {
    name: "DurationInput",
    components: {TimeInput},
    props: {
        value: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: [String, Array],
            default: null
        },
    },
    data: () => ({
        customTokens: {
            'h': {pattern: /[0-3]/},
            'H': {pattern: /[0-9]/},
            'm': {pattern: /[0-5]/},
            'M': {pattern: /[0-9]/}
        },
    }),
    computed: {
        duration: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        onChangeDuration(duration) {
            this.$emit('onChangeDuration', duration)
        }
    }
}
</script>

<style scoped>

</style>
