<template>
    <v-card style="padding-top: 8px; padding-bottom: 8px;">
        <div v-if="loading">
            <div v-for="index in 6" :key="(index + 1) * 123">
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-divider v-if="index < 6"></v-divider>
            </div>
        </div>
        <template v-else>
            <v-list v-if="usersCount > 0" style="padding-top: 0; padding-bottom: 0;">
                <v-slide-y-transition group tag="div">
                    <template v-for="(user, index) in users">
                        <historic-user-list-item
                            :key="`historical-user-item-${user._id}-${index}`"
                            :user="user"
                            @click="onUserClick"
                        ></historic-user-list-item>
                    </template>
                </v-slide-y-transition>
            </v-list>
            <div v-else class="text-body-1 d-flex justify-center align-center py-8" style="color: #6c757d;">
                No eligible staff found for this shift.
            </div>
        </template>
    </v-card>
</template>

<script>
import HistoricUserListItem from './HistoricUserListItem'
export default {
    name: "HistoricUserList",
    components: { HistoricUserListItem },
    props: {
        users: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        usersCount () {
            if (Array.isArray(this.users)) {
                return this.users.length
            }
            return 0
        },
        showNoUsers () {
            const { usersCount } = this
            if (usersCount > 0) {
                return false
            }
        },
    },
    methods: {
        onUserClick(user) {
            this.$emit('click', user, false);
        }
    }
}
</script>
