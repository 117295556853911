<template>
    <v-row style="padding-left: 60px; padding-right: 60px;">
        <v-col cols="12" class="d-flex flex-column">
            <div class="text--h4">
                Assigned Working Hours
            </div>
        </v-col>
        <v-col cols="12" class="px-15">
            <employment-type-assigned-working-hours-day
                v-for="(day, index) in value"
                :key="index"
                :value="day"
                :readonly="readonly"
                @input="onChange($event, index)"
                :day="days[index]"
            />
        </v-col>
    </v-row>
</template>

<script>
import EmploymentTypeAssignedWorkingHoursDay from "./EmploymentTypeAssignedWorkingHoursDay";

export default {
    name: "EmploymentTypeAssignedWorkingHours",
    components: {EmploymentTypeAssignedWorkingHoursDay},
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        days() {
            return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        }
    },
    methods: {
        onChange(value, index) {
            this.$emit('input', this.value.map((v, i) => {
                if (i === index) {
                    return value
                }
                return v
            }))
        }
    }
}
</script>

<style scoped>

</style>
