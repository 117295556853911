<template>
    <v-menu
        v-model="menu"
        open-on-click
        bottom
        :close-on-content-click="false"
        max-width="298px"
        :nudge-bottom="60"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                label="Day of Month"
                :value="dayOfMonth"
                outlined
                readonly
                :rules="rules.required"
                v-bind="attrs"
                v-on="on"
                class="ds-modal-input"
                clearable
                @click:clear="onChangeDayOfMonth(null)"
            />
        </template>
        <day-of-month-content
            :value="reportAutomation.day_of_period"
            @input="onChangeDayOfMonth"
        />
    </v-menu>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";
import DayOfMonthContent from "./DayOfMonthContent";

export default {
    name: "DayOfMonthPicker",
    components: {DayOfMonthContent},
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        errorMessages: {
            type: [String, Array],
            default: () => ([])
        }
    },
    mixins: [validationRules],
    data: () => ({
        menu: false,
    }),
    computed: {
        reportAutomation: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        dayOfMonth() {
            if (typeof this.reportAutomation.day_of_period === 'number') {
                const day = this.reportAutomation.day_of_period

                if (day > 3 && day < 21) {
                    return day + 'th'
                }

                const lastDigit = day % 10;

                if (lastDigit === 1) {
                    return day + 'st'
                } else if (lastDigit === 2) {
                    return day + 'nd'
                } else if (lastDigit === 3) {
                    return day + 'rd'
                } else {
                    return day + 'th'
                }
            }
            return null
        },
    },
    methods: {
        onChangeDayOfMonth(dayOfMonth) {
            this.reportAutomation = {
                ...this.reportAutomation,
                day_of_period: dayOfMonth
            }
            this.menu = false
        }
    }

}
</script>

<style scoped>

</style>
