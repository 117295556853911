<template>
    <v-dialog v-model="modalValue" scrollable persistent max-width="800px">
        <v-card>
            <v-toolbar dark color="warning" elevation="0">
                <v-toolbar-title class="primary--text">
                    <div class="d-flex">
                        <v-icon color="primary" class="mr-3">mdi-alert</v-icon>
                        <div class="mt-1">
                            Assigned Staff Changes
                        </div>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="black--text mt-5 body-1">
                <v-row>
                    <v-col cols="5">
                        <v-date-picker
                            v-model="selectedDate"
                            :allowed-dates="allowedDates"
                            locale="en-US"
                            elevation="15"
                            color="secondary"
                            full-width
                        ></v-date-picker>
                    </v-col>
                    <v-col cols="7">
                        {{ title }}
                        <ul class="mt-4 pl-4">
                            <div v-for="(job_title_data, job_title) in unavailableUsersByJobTitle" :key="job_title">
                                <div class="mt-4">
                                    <strong>{{ job_title }}</strong>
                                    <span v-if="job_title_data.job_title_delete_pending" class="error--text">(Removed)</span>
                                </div>
                                <li class="ml-4" v-for="user in job_title_data.users" :key="user._id">
                                    {{ user.first_name }} {{ user.last_name }}
                                </li>
                            </div>
                        </ul>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p v-if="hasNotPermission" style="text-align: center;">
                            You do not have permission to make shift edits that unassign users.<br/>
                            Contact an Administrator to update your permissions or make these shift edits.
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn
                            class="mr-2"
                            text
                            color="primary_text"
                            @click="onGoBack"
                        >
                            Go Back
                        </v-btn>
                        <v-btn color="primary" @click="onContinue" :loading="isEditing" :disabled="hasNotPermission">Continue</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import message from "../../../lib/toast";
export default {
    name: "UnavailableUsersModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        usersByDate: {
            type: Object,
            default: () => ({})
        },
        bodyParams: {
            type: Object,
            default: () => ({}),
        },
        requestFormId: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        selectedDate: null,
        isEditing: false,
        api: new formHelper(),
    }),
    computed: {
        modalValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        dates () {
            if (this.usersByDate && typeof this.usersByDate === 'object') {
                return Object.keys(this.usersByDate)
            }
            return []
        },
        allowedDates () {
            if (Array.isArray(this.dates)) {
                return val => this.dates.indexOf(val) !== -1
            }
            return null
        },
        unavailableUsersByJobTitle () {
            if (this.selectedDate && this.usersByDate && this.usersByDate.hasOwnProperty(this.selectedDate)) {
                return _.omit(this.usersByDate[this.selectedDate], 'date_removed')
            }
            return []
        },
        date () {
            if (this.selectedDate) {
                return moment(this.selectedDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
            }
            return 'N/A'
        },
        title () {
            if (this.selectedDate && this.usersByDate && this.usersByDate.hasOwnProperty(this.selectedDate)) {
                if (this.usersByDate[this.selectedDate].date_removed) {
                    return `The following staff will be unassigned from the updated shift on ${this.date}:`
                }
            }
            return `The following staff are not available for the updated shift on ${this.date}:`
        },
        hasUsers () {
            if (this.usersByDate) {
                if (Object.keys(this.usersByDate).length > 0) {
                    return Object.keys(this.usersByDate).some(date => {
                        return Object.keys(this.usersByDate[date]).some(jt => {
                            const jobTitle = this.usersByDate[date][jt]
                            return jobTitle && Array.isArray(jobTitle.users) && jobTitle.users.length > 0
                        })
                    })
                }
            }
            return false
        },
        hasNotPermission () {
            return this.hasUsers && !this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.UNASSIGN)
        }
    },
    watch: {
        dates: {
            immediate: true,
            handler (dates) {
                if (Array.isArray(dates) && dates.length > 0) {
                    this.selectedDate = dates[0]
                } else {
                    this.selectedDate = null
                }
            }
        }
    },
    methods: {
        onContinue () {
            if (this.requestFormId && this.bodyParams) {
                const { requestFormId, bodyParams } = this
                this.isEditing = true
                this.api.post(`/request-forms/edit-many/${requestFormId}`, bodyParams)
                    .then(data => {
                        if (data && data.data && Array.isArray(data.data.request_form_ids_by_day) && data.data.request_details) {
                            this.$emit('onContinue', data.data)
                        } else {
                            this.$emit('onContinue', null)
                        }
                        this.$snackNotify('success', 'Shifts updated.')
                    }).catch(console.log).finally(() => {
                    this.isEditing = false
                })
            }
        },
        onGoBack () {
            this.$emit('onGoBack')
        }
    }
}
</script>

<style scoped>

</style>
