<template>
    <div class="stepper-container">
        <step
            v-for="(step, index) in steps"
            :key="index"
            :name="step.name"
            :active="step.active"
            :complete="step.complete"/>
    </div>
</template>

<script>
import Step from "./Step";

export default {
    name: "Stepper",
    components: {Step},
    props: {
        active: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            steps: [
                {name: 'Sync with SSO', active: this.active === 0 || this.active > 0, complete: this.active > 1},
                {name: 'Name Organization', active: this.active === 0 || this.active > 1, complete: this.active > 2},
                {name: 'Add Location', active: this.active === 0 || this.active > 2, complete: this.active > 3},
                {name: 'Complete Profile', active: this.active === 0 || this.active > 3, complete: this.active > 4}
            ]
        }
    },
    watch: {
        active(active) {
            this.steps = this.steps.map((step, index) => ({
                ...step,
                active: index < active,
                complete: index + 1 < active
            }))
        }
    }
}
</script>

<style scoped>
.stepper-container {
    position: absolute;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}
</style>
