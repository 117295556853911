<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <div>
                    <scheduler
                        v-show="globalLocation"
                        :auth-staffing-details="authStaffingDetails"
                        :preselected-options="preselectedOptions"
                        @exportToPDF="exportAsPDF"
                    ></scheduler>

                    <v-card v-show="!globalLocation">
                        <v-card-title class="d-flex justify-center headline">
                            Please select a location
                        </v-card-title>

                        <v-card-text class="text-center">
                            In order to see the schedules, you must have a location selected using the <strong>Location picker</strong>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Scheduler from '../../components/scheduling/Scheduler/Scheduler';

    export default {
        name: 'index',
        components: { Scheduler },
        data() {
            return {
                api: new formHelper(),
                currentFilter: null,
                currentView: null,
            }
        },
        computed: {
            authStaffingDetails () {
                const authStaffingDetails = this.$appData?.currentUserStaffingDetails;
                if (Array.isArray(authStaffingDetails)) {
                    return authStaffingDetails;
                }
                return [];
            },
            preselectedOptions () {
                return this?.$appData?.preselectedOptions ?? {};
            },
            globalLocation () {
                return this.$root.globalLocation;
            }
        },
        mounted () {
            if (this.preselectedOptions.preselected_event_not_found) {
                const { $snackNotify } = this;
                if ($snackNotify && typeof $snackNotify === 'function') {
                    $snackNotify(
                        'error',
                        'This shift is no longer available'
                    );
                }
            }
        },
        methods: {
            async exportAsPDF(data, dateRange) {

                const { startOfWeek, endOfWeek } = dateRange;

                const scheduleViewType = data.scheduleViewType;

                if (scheduleViewType === 'staff_group' || scheduleViewType === 'staff') {
                    await this.loadPrintData(data.selectedStaffGroupId, 'staff_group')
                } else {
                    await this.loadPrintData(data.selectedDepartmentId, 'department')
                }
 
                const originalNode = document.getElementById('pdf-content');
                const clonedNode = originalNode.cloneNode(true);

                let containerClass = null
                let displayViewType = null

                if (scheduleViewType === 'department') {
                    containerClass = '.department-scheduler-container'
                    displayViewType = 'Department'
                } else if (scheduleViewType === 'staff_group') {
                   containerClass = '.staff-group-scheduler-container'
                   displayViewType = 'Staff Group'
                } else if (scheduleViewType === 'staff') {
                    containerClass = '.staff-scheduler-container'
                    displayViewType = 'Staff Group'
                }

                clonedNode.querySelectorAll(containerClass).forEach(node => {
                        node.style.maxHeight = '100%';
                    }); 

                clonedNode.querySelectorAll('.print-only').forEach(node => {
                    node.style.display = 'flex';
                });

                const elementsToRemove = clonedNode.querySelectorAll('.hide-during-print-view, #hide-during-print-view');
                elementsToRemove.forEach(node => {
                    node.parentNode.removeChild(node);
                });

                const innerNode = clonedNode.innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                    stylesHtml += node.outerHTML;
                }

                const printHeader = `
                    <text> ${displayViewType}: ${this.currentFilter}</text>
                    <text>${startOfWeek.format('dddd, MMMM D')} - ${endOfWeek.format('dddd, MMMM D, YYYY')}</text>
                `;
                
                // Open the print window
                const WinPrint = window.open('', '', 'left=0,top=0,width=1400,height=800,toolbar=0,scrollbars=0,status=0');

                // Write the initial loading indicator
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                    <style>
                        body {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                            font-family: Arial, sans-serif;
                            font-size: 24px;
                        }
                        #loadingMessage {
                            text-align: center;
                            color: #555;
                        }
                    </style>
                </head>
                <body>
                    <div id="loadingMessage">Loading content, please wait...</div>
                </body>
                </html>`);
                WinPrint.document.close();

                setTimeout(() => {
                    // Set the window URL
                    WinPrint.location.href = '/schedules-print-view';
                    setTimeout(() => {
                        WinPrint.document.open();
                        WinPrint.document.write(`<!DOCTYPE html>
                        <html>
                        <head>
                            ${stylesHtml}
                            <style>
                                @media print {
                                    #buttonContainer {
                                        display: none !important;
                                    }
                                }
                                #headerContainer {
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: 24px;
                                    align-items: center;
                                    padding-top: 10px;
                                    padding-left: 25px;
                                    padding-right: 25px;
                                    padding-bottom: 20px;
                                }
                                #buttonContainer {
                                    position: fixed;
                                    bottom: 20px;
                                    right: 20px;
                                    display: flex;
                                    justify-content: center;
                                    z-index: 1000;
                                }
                                button {
                                    margin: 0 10px;
                                    padding: 10px 20px;
                                    border: 2px solid #000;
                                    background-color: #fff;
                                    cursor: pointer;
                                    outline: none;
                                }
                                button:hover {
                                    background-color: #f0f0f0;
                                }
                                #pageContainer {
                                    margin-bottom: 80px;
                                }
                            </style>
                        </head>
                        <body>
                            <div id="pageContainer">
                                <div id="buttonContainer">
                                    <button id="printButton" onclick="window.print();">Print</button>
                                    <button id="closeButton" onclick="window.close();">Close</button>
                                </div>
                                <div id="headerContainer">${printHeader}</div>
                                ${innerNode}
                            </div>
                        </body>
                        </html>`);       

                        WinPrint.document.close();
                        WinPrint.focus();
                    }, 250);
                }, 100);


            },
            async loadPrintData(id, type) {
                const response =  await this.api.get('/schedules/print-view-data/' + id, {params:{type}})
                if (type === 'staff_group') {
                    this.currentFilter = response.data.staff_group.name
                } else {
                    this.currentFilter = response.data.department.name
                }
                return
            },
        }
    };
</script>

<style scoped>
</style>