<template>
    <v-menu
        ref="filtersPicker"
        open-on-click
        bottom
        offset-y
        :close-on-content-click="false"
        @input="onInput"
        nudge-bottom="10"
    >
        <template v-slot:activator="{ on, value }">
            <div class="d-flex justify-start align-center">
                <v-btn
                    v-on="on"
                    color="primary_text"
                    icon
                    elevation="2"
                    :disabled="disabled"
                >
                    <v-icon
                    >
                        mdi-filter
                    </v-icon>
                </v-btn>
            </div>
        </template>
        <slot name="filters"></slot>
    </v-menu>
</template>

<script>
export default {
    name: "FiltersPicker",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onInput (value) {
            if (value) {
                this.$emit('onOpen')
            } else {
                this.$emit('onClose')
            }
        },
        closePicker () {
            this.$refs?.filtersPicker.save()
        }
    },
}
</script>

<style scoped>
    .v-menu__content {
        border-radius: 12px !important;
    }
</style>
