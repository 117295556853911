<template>
    <ShiftDetails
        v-if="renderShiftDetails"
        :notification="shiftNotification"
        @onBackPressed="onBackPressed"
        @onDeleteNotification="onDeleteNotification"
    >
    </ShiftDetails>
</template>

<script>
    import ShiftDetails from './ShiftDetails'
    import { getNotificationDetails } from '../../lib/helpers/getNotificationDetails'

    export default {
        name: "NotificationDetails",
        components: { ShiftDetails },
        props: {
            notification: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        computed: {
            type: function() {
                return this.notification.data.type
            },
            assign_shift: function() {
                return this.type === 'assign_shift'
            },
            shift_offer: function() {
                return this.type === 'shift_offer'
            },
            renderShiftDetails: function() {
                return this.assign_shift || this.shift_offer
            },
            shiftNotification: function() {
                const { title } = getNotificationDetails(this.notification)
                return {
                    id: this.notification.id,
                    title,
                    shift: this.shift
                }
            },
            shift: function() {
                const id = this.notification.shift ? this.notification.shift._id : this.notification.shift_offer._id
                const url = `/shifts/${id}`
                if(this.assign_shift) {
                    return {
                        id,
                        url,
                        ...this.notification.shift
                    }
                }else if(this.shift_offer) {
                    return {
                        id,
                        url,
                        ...this.notification.shift_offer.shift_request
                    }
                }
            }
        },
        methods: {
            onBackPressed: function() {
                this.$emit('onBackPressed')
            },
            onDeleteNotification: function(notificationId) {
                this.$emit('onDeleteNotification', notificationId)
            }
        }
    }
</script>

<style scoped>

</style>
