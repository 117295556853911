<template>
    <save-preset-modal
        v-model="modalValue"
        :report="report"
        :filters="filters"
        :filters-body-param="filtersBodyParam"
        @onSavePreset="onSavePreset"
    />
</template>

<script>
import SavePresetModal from '../SavePresetModal'
import {
    parseReportEmploymentType,
    parseReportStaffGroups,
    parseReportJobTitles,
    parseReportStaffMembers,
} from '../../../../../lib/helpers/reports'

export default {
    name: "SavePayrollPeriodPresetModal",
    components: {SavePresetModal},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        report: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        filtersBodyParam() {
            return {
                ...this.report.filters && Boolean(this.report.filters?.staff_type?.id) && {staff_type: this.report.filters.staff_type.id},
                ...this.report.filters && Array.isArray(this.report.filters.staff_groups) && {staff_groups: this.report.filters.staff_groups.map(sg => sg.id)},
                ...this.report.filters && Array.isArray(this.report.filters.job_titles) && {job_titles: this.report.filters.job_titles.map(jt => jt.id)},
                ...this.report.filters && Array.isArray(this.report.filters.staff_members) && {staff_members: this.report.filters.staff_members.map(sm => sm.id)},
                locations: this.globalLocation ? [this.globalLocation._id] : []
            }
        },
        filters() {
            return {
                'Employment Type': parseReportEmploymentType(this.report),
                'Staff Groups': parseReportStaffGroups(this.report),
                'Job Titles': parseReportJobTitles(this.report),
                'Staff Members': parseReportStaffMembers(this.report),
            }
        },
        globalLocation() {
            return this.$root.globalLocation
        },
    },
    methods: {
        onSavePreset(preset) {
            this.$emit('onSavePreset', preset)
        }
    }
}
</script>

<style scoped>
</style>
