<template>
    <v-menu
        open-on-hover
        :close-on-click="false"
        :close-on-content-click="false"
        content-class="user-details-tooltip"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="staff-user-details-container pr-2" v-bind="attrs" v-on="on">
                <div class="staff-user-details-avatar-container">
                    <v-avatar color="primary" :size="36">
                        <img v-if="avatar" :src="avatar" :alt="user | initials"/>
                        <span v-else class="white--text">{{ user | initials }}</span>
                    </v-avatar>
                </div>
                <div class="staff-user-details-user-container">
                    <div class="staff-user-details-name-container text--paragraph">
                        {{ fullName }}
                    </div>
                    <div class="staff-user-details-shift-container text--caption">
                        <div class="staff-user-details-text-details-container">
                            <v-icon class="staff-user-details-shift-icon">mdi-calendar</v-icon>
                            <span class="staff-user-details-count">{{ shiftsCount }}</span>
                        </div>
                        <div class="staff-user-details-text-details-container">
                            <v-icon class="staff-user-details-shift-icon">mdi-calendar-clock</v-icon>
                            <span class="staff-user-details-hours">{{ shiftOffersCount }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <v-list>
            <div class="staff-user-details-tooltip-container">
                <div class="staff-user-details-tooltip-user-container">
                    <v-avatar color="primary" :size="46">
                        <img v-if="avatar" :src="avatar" :alt="user | initials"/>
                        <span v-else class="white--text">{{ user | initials }}</span>
                    </v-avatar>
                    <div class="staff-user-details-tooltip-details">
                        <div class="text--h4">
                            {{ fullName }}
                        </div>
                        <div class="text--paragraph" v-if="phone">
                            {{ formattedPhone }}
                        </div>
                        <div class="text--paragraph">
                            {{ email }}
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                    <div class="d-flex align-center justify-start text--h5">
                        Current Week
                    </div>
                    <div class="staff-user-details-shift-container justify-center text--paragraph">
                        <div class="d-flex flex-row align-center">
                            <v-icon class="tooltip-icon">mdi-calendar</v-icon>
                            <span class="ml-2">{{ shiftsCount }} Assigned</span>
                        </div>
                        <div class="d-flex flex-row align-center">
                            <v-icon class="tooltip-icon">mdi-calendar-clock</v-icon>
                            <span class="ml-2">{{ shiftOffersCount }} Offered</span>
                        </div>
                    </div>
                <v-divider></v-divider>
                <div class="d-flex align-center justify-start text--h5">
                    Payroll Period
                </div>
                <template v-for="(payrollPeriod, index) in payrollPeriods">
                    <div class="d-flex flex-column px-2 py-1" style="row-gap: 3px;">
                        <div class="d-flex align-center justify-start">
                            {{ getPayPeriod(payrollPeriod) }}
                        </div>
                        <div class="d-flex align-center justify-center" style="column-gap: 10px;">
                            <div class="d-flex align-center" style="column-gap: 5px;">
                                <v-icon class="tooltip-icon">mdi-calendar</v-icon>
                                <span>{{ getShiftCountInPayrollPeriod(payrollPeriod) }} Assigned</span>
                            </div>
                            <div class="d-flex align-center" style="column-gap: 5px;">
                                <v-icon class="tooltip-icon" :color="getExceedsColor(payrollPeriod)">
                                    {{ getIcon(payrollPeriod) }}
                                </v-icon>
                                <span :style="getHoursStyle(payrollPeriod)">
                                    {{ formatMinutes(getTotalShiftDurationInPayrollPeriod(payrollPeriod)) }}/{{
                                        formatMinutes(maxWorkHours)
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-divider v-if="index >= payrollPeriods.length - 1"></v-divider>
                </template>
                <div>
                    <v-btn class="staff-user-details-tooltip-button d-flex align-center justify-center" color="primary" text :href="`/users/${userId}`">
                        View Profile
                    </v-btn>
                </div>
            </div>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "UserDetails",
    props: {
        user: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        userId() {
            return this.user._id
        },
        fullName() {
            return this.user.full_name
        },
        avatar() {
            return this.user.avatar
        },
        phone() {
            return this.user.phone
        },
        formattedPhone() {
            const cleaned = ('' + this.phone).replace(/\D/g, '')
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                const intlCode = (match[1] ? '+1 ' : '')
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            }
            return this.phone
        },
        email() {
            return this.user.email
        },
        shiftsCount() {
            return this.user.shift_count_in_week || 0
        },
        shiftOffersCount() {
            return this.user.shift_offer_count_in_week || 0
        },
        payrollPeriods() {
            const payrollPeriods = this.user.payroll_periods
            return Array.isArray(payrollPeriods) ? payrollPeriods : []
        },
        maxWorkHours() {
            if (!this.user.staff_type || typeof this.user.staff_type === 'undefined') {
                return 0
            }

            return this.user.staff_type.max_work_hours ? this.user.staff_type.max_work_hours : 0
        },
        globalLocation() {
            return this.$root.globalLocation
        },
    },
    methods: {
        getPayPeriod(payrollPeriod) {
            const start = this.getPayrollPeriodDate(payrollPeriod.start, payrollPeriod.timezone)
            const end = this.getPayrollPeriodDate(payrollPeriod.end, payrollPeriod.timezone)
            let payPeriod = `${start} - ${end}:`
            if (this.globalLocation && this.globalLocation.timezone !== payrollPeriod.timezone) {
                const timezone = moment().tz(payrollPeriod.timezone).format('z')
                payPeriod = `${payPeriod} (${timezone})`
            }
            return payPeriod
        },
        getPayrollPeriodDate(date, tz) {
            return moment(date).tz(tz).format('MMMM Do')
        },
        getShiftCountInPayrollPeriod(payrollPeriod) {
            return payrollPeriod.total_shift_count || 0
        },
        getTotalShiftDurationInPayrollPeriod(payrollPeriod) {
            return payrollPeriod.total_shift_duration || 0
        },
        formatMinutes(minutes) {
            const hours = Math.floor(minutes / 60)
            const remainingMinutes = minutes % 60
            if (remainingMinutes > 0) {
                return `${hours}h${remainingMinutes}m`
            }
            return `${hours}h`
        },
        getExceeds(payrollPeriod) {
            return this.getTotalShiftDurationInPayrollPeriod(payrollPeriod) > this.maxWorkHours
        },
        getIcon(payrollPeriod) {
            return this.getExceeds(payrollPeriod) ? 'mdi-alert' : 'mdi-clock-outline'
        },
        getExceedsColor(payrollPeriod) {
            return this.getExceeds(payrollPeriod) ? '#FF0000' : 'rgba(0, 0, 0, 0.87)'
        },
        getHoursStyle(payrollPeriod) {
            if (this.getExceeds(payrollPeriod)) {
                return {
                    color: this.getExceedsColor(payrollPeriod)
                }
            }
            return {}
        }
    }
}
</script>

<style scoped>
.staff-user-details-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    height: 100%;
    width: 100%;
}

.staff-user-details-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.staff-user-details-user-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
}

.staff-user-details-name-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.staff-user-details-shift-container {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.staff-user-details-text-details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.staff-user-details-shift-icon {
    font-size: 14px;
}

.staff-user-details-count {
    margin-left: 2px;
}

.staff-user-details-hours {
    margin-left: 2px;
}

.staff-user-details-tooltip-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 0 10px;
    z-index: 30;
}

.staff-user-details-tooltip-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.staff-user-details-tooltip-details {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.staff-user-details-tooltip-user-name {
    font-size: 16px;
}

.staff-user-details-tooltip-user-details {
    font-size: 14px;
}

.staff-user-details-tooltip-button {
    text-transform: none !important;
}

.v-menu__content.user-details-tooltip {
    border-radius: 15px;
    border: 1px solid #33415C;
}

.tooltip-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
}
</style>
