<template>
    <v-row class="pl-4 pr-4 pt-4 pb-4" no-gutters justify="space-between">
        <v-col cols="12" sm="auto" class="custom-detail-row">
            <div class="custom-detail-label">Date</div>
            <div class="custom-detail-value">{{ date }}</div>
        </v-col>

        <v-col cols="12" sm="auto" class="custom-detail-row">
            <div class="custom-detail-label">Shift Time</div>
            <div class="custom-detail-value">{{ time }}</div>
        </v-col>

        <v-col cols="12" sm="auto" class="custom-detail-row">
            <div class="custom-detail-label">Duration</div>
            <div class="custom-detail-value">{{ shift.duration }} Hours</div>
        </v-col>

        <v-col cols="12" sm="auto" class="custom-detail-row">
            <div class="custom-detail-label">Location</div>
            <div class="custom-detail-value">{{ shift.location_name }}</div>
        </v-col>

        <v-col cols="12" sm="auto" class="custom-detail-row">
            <div class="custom-detail-label">Department</div>
            <div class="custom-detail-value">{{ shift.department_name }}</div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "ShiftNotificationDetails",
        props: {
            shift: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        computed: {
            date: function() {
                return moment(this.shift.start).format('MMMM D, YYYY')
            },
            time: function() {
                const start = moment(this.shift.start).format('LT')
                const end = moment(this.shift.end).format('LT')
                return `${start} - ${end}`
            }
        }
    }
</script>

<style scoped>
    .custom-detail-row {
        padding: 0;
        margin-top: 5px
    }
    .custom-detail-label {
        color: #7B7B7B;
        font-size: 11px;
        margin-bottom: 0;
    }

    .custom-detail-value {
        color: black;
        font-size: 16px;
    }
</style>
