<template>
    <div class="bulk-upload-users-upload-file-container">
        <div class="bulk-upload-users-upload-file-instructions">
            <bulk-upload-users-instructions :email-domains="emailDomains" />
        </div>
        <div class="bulk-upload-users-upload-file-content">
            <div class="bulk-upload-users-upload-file-wrapper">
                <div class="text--h3 text-center">
                    Upload User Data
                </div>
                <div class="bulk-upload-users-upload-file-input-container">
                    <div class="bulk-upload-users-upload-file-input-wrapper">
                        <v-file-input
                            v-model="file"
                            outlined
                            chips
                            :error="error"
                            :error-messages="errorMessages"
                            accept=".csv" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BulkUploadUsersInstructions from './BulkUploadUsersInstructions'

export default {
    name: "BulkUploadUsersUploadFile",
    components: { BulkUploadUsersInstructions },
    props: {
        value: {
            type: File,
            default: null,
        },
        emailDomains: {
            type: Array,
            default: () => [],
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: Array,
            default: () => [],
        }
    },
    computed: {
        file: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    }
}
</script>

<style scoped>
    .bulk-upload-users-upload-file-container {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .bulk-upload-users-upload-file-instructions {
        flex: 0 0 auto;
    }

    .bulk-upload-users-upload-file-content {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bulk-upload-users-upload-file-wrapper {
        display: flex;
        flex-direction: column;
        padding: 24px;
        row-gap: 16px;
    }

    .bulk-upload-users-upload-file-input-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .bulk-upload-users-upload-file-input-wrapper {
        width: 360px;
    }
</style>
