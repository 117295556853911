<template>
    <v-dialog v-model="show" width="500px" persistent scrollable>
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Select a timezone
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close" :disabled="api.busy">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="py-4">
                <div v-if="timezones_loading" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <div v-else>
                    <v-radio-group v-if="formatted_timezones.length" v-model="selected_timezone">
                        <v-radio
                            name="timezones"
                            v-for="timezone in formatted_timezones"
                            :key="timezone.name"
                            :value="timezone.name"
                        >
                            <template v-slot:label>
                                <div class="black--text text--paragraph">
                                    {{ timezone.offset_name }}
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <div v-else class="text-center py-4">
                        <h3>No timezones available</h3>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="text-right">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="confirm" :disabled="api.busy">Apply</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "TimezonePickerModal",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: this.type,
            api: new formHelper(),
            timezones_loading: false,
            selected_timezone: this.$root.globalTimezone,
            timezones: [],
        }
    },

    methods: {
        close() {
            this.show = false
        },

        confirm() {
            this.api.post('/set-timezone', {timezone: this.selected_timezone})
                .then(({data, message}) => {
                    this.$root.globalTimezone = this.selected_timezone
                    this.$user.timezone = this.selected_timezone
                    this.close()
                })
        },

        loadTimezones() {
            this.timezones_loading = true
            this.api.get(`/timezones/${this.$user._id}`)
                .then(({data, message}) => {
                    if (typeof data.timezones !== 'undefined') {
                        this.timezones = data.timezones
                    }

                    this.timezones_loading = false
                })
        },
    },

    computed: {
        formatted_timezones() {
            if (!this.timezones) {
                return []
            }

            return this.timezones.map(timezone => {
                return {
                    name: timezone,
                    offset_name: moment.tz(timezone).format('z (Z)')
                }
            })
        }
    },

    watch: {
        value(val) {
            this.show = val

            if (val) {
                this.loadTimezones()
            }
        },

        show(val) {
            if (!val) {
                this.$emit('input', false)
            }
        }
    }
}
</script>

<style scoped>

</style>
