<template>
    <v-dialog v-model="showModal" max-width="450px" elevation="10" scrollable persistent>
        <v-card dusk="updateAvailabilityConfirmationModal">
            <v-toolbar dark color="primary" elevation="0" height="40">
                <v-toolbar-title class="white--text toolbar-title">Edit Recurring Availability</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pa-6">
                <v-radio-group v-model="update_type">
                    <v-radio
                        dusk="thisPeriodButton"
                        label="This Period"
                        value="single"
                    ></v-radio>
                    <v-radio
                        dusk="thisAndFollowingPeriodsButton"
                        label="This and Following Periods"
                        value="recurring"
                    ></v-radio>
                </v-radio-group>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-btn dusk="confirmButton" block color="primary" @click="confirmUpdate">Confirm</v-btn>
                    </v-col>

                    <v-col cols="12" class="mt-4 mb-4">
                        <v-btn block text color="primary" elevation="1" @click="closeModal">Cancel</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "UpdateAvailabilityModal",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        availabilityEvent: {
            type: Object,
            default: null
        },
        preSelectedOption: {
            type: String,
            default: 'single'
        }
    },
    data() {
        return {
            showModal: false,
            update_type: this.preSelectedOption,
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
        },

        confirmUpdate() {
            this.$emit('confirm', this.update_type)
            this.closeModal()
        }
    },
    watch: {
        value(newVal) {
            this.showModal = newVal
        },
        showModal(newVal) {
            if (!newVal) {
                this.$emit('input', false)
            }
        },
        preSelectedOption(value) {
            this.update_type = value
        }
    }
}
</script>

<style scoped>
    .toolbar-title {
        font-size: 1rem;
    }
</style>
