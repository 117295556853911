<template>
    <create-report-stepper
        ref="stepperRef"
        :key="stepperKey"
        :loading="generatingReporting || reportDataLoading"
        :quick-report="quickReport"
        @onGenerate="onGenerateReport"
        @onGoBack="onGoBack"
        @onPrevStep="onPrevStep"
        @onNextStep="onNextStep"
        @onStepChange="onStepChange"
    >
        <template v-slot:report-filters>
            <shift-details-report-filters
                v-if="reportType.value === 'shift_details'"
                v-model="reportFilters"
                :report-type="reportType"
                :location="staticLocation"
                :readonly="step !== 1"
                :is-quick-report="quickReport"
                :quick-report-preset="preset"
                @onUpdatePreset="onPresetUpdate"
            />
            <shift-offer-report-filters
                v-else-if="reportType.value === 'shift_offers'"
                v-model="reportFilters"
                :report-type="reportType"
                :location="staticLocation"
                :readonly="step !== 1"
                :is-quick-report="quickReport"
                :quick-report-preset="preset"
                @onUpdatePreset="onPresetUpdate"
            />
            <availability-report-filters
                v-else-if="reportType.value === 'user_availability'"
                v-model="reportFilters"
                :report-type="reportType"
                :location="staticLocation"
                :readonly="step !== 1"
                :is-quick-report="quickReport"
                :quick-report-preset="preset"
                @onUpdatePreset="onPresetUpdate"
            />
            <shift-cancellation-report-filters
                v-if="reportType.value === 'shift_cancellation'"
                v-model="reportFilters"
                :report-type="reportType"
                :location="staticLocation"
                :readonly="step !== 1"
                :is-quick-report="quickReport"
                :quick-report-preset="preset"
                @onUpdatePreset="onPresetUpdate"
            />
            <payroll-period-report-filters
                v-if="reportType.value === 'payroll_period'"
                ref="payrollPeriodFiltersRef"
                v-model="reportFilters"
                :report-type="reportType"
                :location="staticLocation"
                :readonly="step !== 1"
                :is-quick-report="quickReport"
                :quick-report-preset="preset"
                @onUpdatePreset="onPresetUpdate"
            />
        </template>
        <template v-slot:report-data>
            <ReportData
                v-model="reportData"
                :error="reportDataError"
                :report-type="reportType.value"
                :preset="reportFilters.preset"
                :preselect-based-on-preset="shouldPreselectReportData"
                :readonly="step !== 2"
                :shown="step > 1"
                @onDataLoading="onReportDataLoading"
                @onDataFetched="onReportDataFetched"
            />
        </template>
        <template v-slot:report-date-range>
            <PayrollPeriodReportDatePicker
                v-if="isPayrollPeriodReport"
                v-model="reportDate"
                :readonly="step !== 3"
                :shown="step > 2"
                :location="location"
                :employment-type-id="reportFilters.employmentType"
                ref="reportDatePickerRef" />
            <ReportDatePicker
                v-else
                v-model="reportDate"
                :readonly="step !== 3"
                :shown="step > 2"
                ref="reportDatePickerRef" />
        </template>
    </create-report-stepper>
</template>

<script>
import CreateReportStepper from "./CreateReportStepper";
import ShiftDetailsReportFilters from "./ShiftDetails/ShiftDetailsReportFilters";
import ShiftOfferReportFilters from "./ShiftOffer/ShiftOfferReportFilters";
import AvailabilityReportFilters from "./Availability/AvailabilityReportFilters";
import ShiftCancellationReportFilters from "./ShiftCancellation/ShiftCancellationReportFilters";
import PayrollPeriodReportFilters from "./PayrollPeriod/PayrollPeriodReportFilters";
import ReportData from "./ReportData";
import ReportDatePicker from "./ReportDatePicker";
import PayrollPeriodReportDatePicker from "./PayrollPeriod/PayrollPeriodReportDatePicker";

export default {
    name: "CreateReport",
    components: {
        CreateReportStepper,
        ShiftDetailsReportFilters,
        ShiftOfferReportFilters,
        AvailabilityReportFilters,
        ShiftCancellationReportFilters,
        PayrollPeriodReportFilters,
        ReportData,
        ReportDatePicker,
        PayrollPeriodReportDatePicker,
    },
    props: {
        reportType: {
            type: Object,
            default: () => ({})
        },
        quickReport: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => null
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        step: 1,
        stepperKey: 5555,
        reportFilters: {},
        reportData: [],
        reportDataLoading: false,
        reportDataFetched: false,
        reportDataError: false,
        shouldPreselectReportData: true,
        reportDate: null,
        generatingReporting: false,
        api: new formHelper(),
        staticLocation: {},
    }),
    computed: {
        globalTimezone() {
            return this.$root.globalTimezone
        },
        payrollPeriodFiltersRef() {
            return this.$refs.payrollPeriodFiltersRef
        },
        isPayrollPeriodReport() {
            return Boolean(this.reportType) && this.reportType.value === 'payroll_period'
        }
    },
    methods: {
        onGenerateReport() {
            if (this.$refs.reportDatePickerRef && !this.$refs.reportDatePickerRef.validateForm()) {
                return
            }
            this.generatingReporting = true
            this.api.post('/reports/create', {
                type: this.reportType.value,
                filters: this.getFilters(),
                columns: this.reportData,
                ...!this.isPayrollPeriodReport && {
                    start: this.reportDate[0],
                    end: this.reportDate[1],
                },
                ...this.isPayrollPeriodReport && {
                    date: this.$refs.reportDatePickerRef.getSelectedDate()
                },
                timezone: this.globalTimezone,
                report_preset_id: this.reportFilters.preset ? this.reportFilters.preset._id : null,
            }).then(({data, message}) => {
                this.generatingReporting = false
                this.$snackNotify('success', 'Report generated.')
                this.$emit('onGenerateReport', data.report)
            }).catch(({data, message}) => {
                this.generatingReporting = false
                if (data && data.message) {
                    if (data.errors && Array.isArray(data.errors.date_range) && data.errors.date_range.length > 0) {
                        message(data.errors.date_range[0])
                    } else {
                        message(data.message)
                    }
                } else {
                    this.$snackNotify('warning', 'Unable to generate the report. Please try again.')
                }
            })
        },
        validatePayrollPeriodReportFilters() {
            if (this.payrollPeriodFiltersRef) {
                return this.payrollPeriodFiltersRef.validateFilters()
            }
            return true
        },
        onGoBack() {
            this.$emit('onGoBack')
        },
        onPresetUpdate() {
            this.$emit('onPresetUpdate')
        },
        resetStepper() {
            this.step = 1
            this.stepperKey = this.stepperKey + 1
        },
        onPrevStep() {
            this.$refs.stepperRef.goToPrevStep()
        },
        onNextStep(step) {
            if (step === 1) {
                if (this.isPayrollPeriodReport) {
                    if (this.validatePayrollPeriodReportFilters()) {
                        this.$refs.stepperRef.goToNextStep()
                    }
                } else {
                    this.$refs.stepperRef.goToNextStep()
                }
            }
            else if (step === 2) {
                if (Array.isArray(this.reportData) && this.reportData.length === 0) {
                    this.reportDataError = true
                } else {
                    this.$refs.stepperRef.goToNextStep()
                }
            } else {
                this.$refs.stepperRef.goToNextStep()
            }
        },
        onReportDataLoading() {
            this.reportDataLoading = true
        },
        onReportDataFetched() {
            this.reportDataLoading = false
            this.reportDataFetched = true
        },
        checkReportDataErrorOnReportDataChange(data) {
            if (Array.isArray(data) && data.length > 0 && this.reportDataError) {
                this.reportDataError = false
            }
        },
        checkPresetOnReportDataChange(data) {
            this.shouldPreselectReportData = true
            if (this.reportDataFetched) {
                if (this.reportFilters && this.reportFilters.preset && Array.isArray(this.reportFilters.preset.columns) && Array.isArray(data)) {
                    if (!_.isEqual(_.sortBy(this.reportFilters.preset.columns), _.sortBy(data))) {
                        this.shouldPreselectReportData = false
                        this.reportFilters = {
                            ...this.reportFilters,
                            preset: null,
                        }
                    }
                }
            }
        },
        onStepChange(step) {
            this.step = step
        },
        getFilters() {
            const {value} = this.reportType
            if (value === 'shift_details') {
                return this.getShiftDetailsFilters()
            } else if (value === 'shift_offers') {
                return this.getShiftOfferFilters()
            } else if (value === 'user_availability') {
                return this.getAvailabilityFilters()
            } else if (value === 'shift_cancellation') {
                return this.getShiftCancellationFilters()
            } else if (value === 'payroll_period') {
                return this.getPayrollPeriodFilters()
            }
        },
        getShiftDetailsFilters() {
            return {
                departments: this.reportFilters.departments,
                staff_groups: this.reportFilters.staffGroups,
                job_titles: this.reportFilters.jobTitles,
                requirements: this.reportFilters.credentials,
                shift_times: this.reportFilters.shiftTimes,
                staff_members: this.reportFilters.staffMembers,
                status: this.reportFilters.status,
                no_shows: this.reportFilters.no_shows,
                call_outs: this.reportFilters.call_outs,
                locations: this.staticLocation ? [this.staticLocation._id] : []
            }
        },
        getShiftOfferFilters() {
            return {
                departments: this.reportFilters.departments,
                staff_groups: this.reportFilters.staffGroups,
                job_titles: this.reportFilters.jobTitles,
                requirements: this.reportFilters.credentials,
                shift_times: this.reportFilters.shiftTimes,
                staff_members: this.reportFilters.staffMembers,
                statuses: this.reportFilters.status,
                locations: this.staticLocation ? [this.staticLocation._id] : []
            }
        },
        getAvailabilityFilters() {
            return {
                staff_groups: this.reportFilters.staffGroups,
                job_titles: this.reportFilters.jobTitles,
                requirements: this.reportFilters.credentials,
                staff_members: this.reportFilters.staffMembers,
                locations: this.staticLocation ? [this.staticLocation._id] : []
            }
        },
        getShiftCancellationFilters() {
            return {
                departments: this.reportFilters.departments,
                staff_groups: this.reportFilters.staffGroups,
                job_titles: this.reportFilters.jobTitles,
                shift_times: this.reportFilters.shiftTimes,
                staff_members: this.reportFilters.staffMembers,
                type: this.reportFilters.type,
                locations: this.staticLocation ? [this.staticLocation._id] : []
            }
        },
        getPayrollPeriodFilters() {
            return {
                staff_type: this.reportFilters.employmentType,
                staff_groups: this.reportFilters.staffGroups,
                job_titles: this.reportFilters.jobTitles,
                staff_members: this.reportFilters.staffMembers,
                locations: this.staticLocation ? [this.staticLocation._id] : []
            }
        }
    },
    watch: {
        reportData(data) {
            this.checkReportDataErrorOnReportDataChange(data)
            this.checkPresetOnReportDataChange(data)
        }
    },
    created() {
        if (this.location) {
            this.staticLocation = {...this.location}
        }
    }
}
</script>

<style scoped>

</style>
