<template>
    <v-dialog
        ref="timePickerDialog"
        v-model="modalValue"
        :return-value.sync="time"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-if="textFieldProps?.readonly"
                v-bind="{...attrs, ...textFieldProps}"
                readonly
            ></v-text-field>
            <v-text-field
                v-else
                readonly
                clearable
                @click:clear="onClearTime"
                v-bind="{...attrs, ...textFieldProps}"
                v-on="on"
                :rules="rulesProp"
                @click="onClick"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="modalValue && !isDatePicker"
            v-model="time"
            v-bind="timePickerProps"
            full-width
            scrollable
            color="primary"
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="onClosePicker"
            >
                Cancel
            </v-btn>
            <v-btn
                outlined
                color="primary"
                @click="onConfirmTime"
            >
                OK
            </v-btn>
        </v-time-picker>
        <v-date-picker
            v-if="modalValue && isDatePicker"
            v-model="time"
            v-bind="timePickerProps"
            :allowed-dates="allowedDates"
            full-width
            scrollable
            color="secondary"
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="onClosePicker"
            >
                Cancel
            </v-btn>
            <v-btn
                outlined
                color="primary"
                @click="onConfirmTime"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    name: "TimePickerDialog",
    props: {
        value: {
            type: String,
            default: '',
        },
        textFieldProps: {
            type: Object,
            default: () => ({})
        },
        timePickerProps: {
            type: Object,
            default: () => ({})
        },
        required: {
            type: Boolean,
            default: false,
        },
        minRule: {
            type: Boolean,
            default: false,
        },
        maxRule: {
            type: Boolean,
            default: false,
        },
        isDatePicker: {
            type: Boolean,
            default: false,
        },
        allowedDates: {
            type: Function,
            default: null,
        },
        allowedDatesRule: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        modalValue: false,
        shouldValidate: true,
        rules: {
            required: [
                v => !!v || 'Required'
            ],
        },
    }),
    computed: {
        time: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        minRules() {
            if (this.minRule && this.timePickerProps && this.timePickerProps.min) {
                return [
                    v => {
                        const endDate = this.genMoment(v, 'MM/DD/YYYY')
                        const minDate = this.genMoment(this.timePickerProps.min, 'YYYY-MM-DD')
                        return endDate.isSameOrAfter(minDate) || `Must be >= ${minDate.format('MM/DD/YYYY')}`
                    }
                ]
            }
            return []
        },
        maxRules() {
            if (this.maxRule && this.timePickerProps && this.timePickerProps.max) {
                return [
                    v => {
                        const endDate = this.genMoment(v, 'MM/DD/YYYY')
                        const maxDate = this.genMoment(this.timePickerProps.max, 'YYYY-MM-DD')
                        return endDate.isSameOrBefore(maxDate) || `Must be <= ${maxDate.format('MM/DD/YYYY')}`
                    }
                ]
            }
            return []
        },
        allowedDatesRules() {
            if (this.allowedDates) {
                return [
                    v => {
                        const endDate = this.genMoment(v, 'MM/DD/YYYY')
                        return this.allowedDates(endDate) || 'Invalid end date'
                    }
                ]
            }
            return []
        },
        rulesProp() {
            if (this.shouldValidate) {
                return [
                    ...(this.required && this.rules.required) || [],
                    ...(this.minRule && this.minRules) || [],
                    ...(this.maxRule && this.maxRules) || [],
                    ...(this.allowedDatesRule && this.allowedDatesRules) || [],
                ]
            }
            return []
        }
    },
    methods: {
        onClick() {
            this.shouldValidate = false
        },
        onClearTime() {
            this.time = null
            this.$emit('onClearTime')
        },
        onClosePicker() {
            this.shouldValidate = true
            this.modalValue = false
            this.$emit('onClose')
        },
        onConfirmTime() {
            this.shouldValidate = true
            this.$refs.timePickerDialog.save(this.time)
            this.$emit('onConfirmTime')
        },
        genMoment(date, format) {
            return moment(date, format)
        }
    }
}
</script>

<style scoped>

</style>
