<template>
    <div class="user-jobs-container">
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="ds-modal-input-container">
                    <p class="text--h3">
                        Job Titles
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <template v-if="locationsLoading">
            <location
                v-for="i in 5"
                :key="i"
                loading
            />
        </template>
        <template v-else>
            <location
                v-for="location in locations"
                :key="location._id"
                :user-id="userId"
                :location="location"
                @onAddStaffGroup="onAddStaffGroup"
                @onChangeStaffGroup="onChangeStaffGroup"
                @onAddJobTitle="onAddJobTitle"
                @onChangeJobTitle="onChangeJobTitle"
                @onSaveJobTitle="onSaveJobTitle"
                @onDeleteJobTitle="onDeleteJobTitle"
            />
        </template>
    </div>
</template>

<script>
import Location from "./Location";

export default {
    name: "UserJobs",
    components: {Location},
    props: {
        userId: {
            type: String,
            default: null,
        },
        userLocations: {
            type: Array,
            default: () => ([])
        },
        jobs: {
            type: Array,
            default: () => ([])
        },
        locationsLoading: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        prevUserJobs: [],
        userJobs: [],
    }),
    computed: {
        locations() {
            const {
                selectedStaffGroupsByLocation,
                availableStaffGroupsByLocation,
                selectedJobTitlesByStaffGroup,
                availableJobTitlesByStaffGroup,
            } = this
            return this.userLocations.map(location => {
                const available_staff_groups = availableStaffGroupsByLocation[location._id] || []
                const staff_groups = selectedStaffGroupsByLocation[location._id] || []
                return {
                    _id: location._id,
                    name: location.name,
                    available_staff_groups,
                    staff_groups: staff_groups.map(sg => {
                        const available_job_titles = availableJobTitlesByStaffGroup[sg._id] || []
                        const job_titles = selectedJobTitlesByStaffGroup[sg._id] || []
                        return {
                            _id: sg._id,
                            name: sg.name,
                            available_job_titles,
                            job_titles,
                        }
                    })
                }
            })
        },
        selectedStaffGroupsByLocation() {
            return this.userJobs.reduce((staffGroups, userJob) => {
                const currStaffGroups = staffGroups.hasOwnProperty(userJob.location.id) ? staffGroups[userJob.location.id] : []
                if (currStaffGroups.findIndex(sg => sg._id === userJob.staff_group.id) > -1) {
                    return staffGroups
                }
                return {
                    ...staffGroups,
                    [userJob.location.id]: [
                        ...currStaffGroups,
                        {_id: userJob.staff_group.id, name: userJob.staff_group.name}
                    ]
                }
            }, {})
        },
        availableStaffGroupsByLocation() {
            if (Array.isArray(this.userLocations) && this.userLocations.length > 0) {
                const {selectedStaffGroupsByLocation} = this
                return this.userLocations.reduce((staffGroups, location) => {
                    const selectedStaffGroups = selectedStaffGroupsByLocation[location._id] || []
                    return {
                        ...staffGroups,
                        [location._id]: location.staff_groups.map(sg => ({
                            _id: sg._id,
                            name: sg.name,
                            disabled: !!selectedStaffGroups.find(ssg => ssg._id === sg._id)
                        }))
                    }
                }, {})
            }
            return {}
        },
        selectedJobTitlesByStaffGroup() {
            return this.userJobs.reduce((jobTitles, userJob) => ({
                ...jobTitles,
                [userJob.staff_group.id]: [
                    ...jobTitles.hasOwnProperty(userJob.staff_group.id) ? jobTitles[userJob.staff_group.id] : [],
                    {
                        _id: userJob.job_title.id,
                        prevId: userJob.job_title.prevId,
                        title: userJob.job_title.title,
                        max_shift_duration: userJob.job_title.max_shift_duration,
                        isSavedInDb: userJob.job_title.isSavedInDb,
                        job_id: userJob.job_id,
                        prevJobId: userJob.prevJobId,
                    }
                ]
            }), {})
        },
        availableJobTitlesByStaffGroup() {
            if (Array.isArray(this.userLocations) && this.userLocations.length > 0) {
                const jobTitles = _.flatMap(this.userLocations, location =>
                    _.flatMap(location.staff_groups, 'job_titles')
                )
                const jobTitlesByStaffGroup = jobTitles.reduce((jobTitles, jobTitle) => ({
                    ...jobTitles,
                    [jobTitle.staff_group_id]: [
                        ...jobTitles.hasOwnProperty(jobTitle.staff_group_id) ? jobTitles[jobTitle.staff_group_id] : [],
                        {
                            _id: jobTitle._id,
                            title: jobTitle.title,
                            max_shift_duration: jobTitle.max_shift_duration,
                        }
                    ]
                }), {})
                const {selectedJobTitlesByStaffGroup} = this
                return Object.keys(jobTitlesByStaffGroup).reduce((jobTitles, staffGroupId) => {
                    const currJobTitles = jobTitlesByStaffGroup[staffGroupId]
                    const selectedJobTitles = selectedJobTitlesByStaffGroup[staffGroupId] || []
                    return {
                        ...jobTitles,
                        [staffGroupId]: currJobTitles.map(jt => ({
                            ...jt,
                            disabled: !!selectedJobTitles.find(sjt => sjt._id === jt._id)
                        }))
                    }
                }, {})
            }
            return {}
        },
        isDirty() {
            return !_.isEqual(this.prevUserJobs, this.userJobs)
        }
    },
    methods: {
        onAddStaffGroup({locationId, locationName, staffGroupIndex, jobTitleIndex}) {
            const staffGroupId = this.getIdFromIndex(staffGroupIndex)
            const jobTitleId = this.getIdFromIndex(jobTitleIndex)
            const jobId = this.getJobId(locationId, staffGroupId, jobTitleId)
            this.userJobs = this.getSortedUserJobs([
                ...this.userJobs,
                {
                    job_id: jobId,
                    prevJobId: jobId,
                    location: {
                        id: locationId,
                        name: locationName,
                    },
                    staff_group: {
                        id: staffGroupId,
                        name: null,
                    },
                    job_title: {
                        id: jobTitleId,
                        prevId: jobTitleId,
                        title: null,
                        max_shift_duration: null,
                        isSavedInDb: false,
                        job_id: jobId,
                        prevJobId: jobId,
                    }
                }
            ])
        },
        onChangeStaffGroup({_id, name}, staffGroupIndex) {
            const staffGroupId = this.getIdFromIndex(staffGroupIndex)
            this.userJobs = this.getSortedUserJobs(this.userJobs.map(userJob => {
                if (userJob.staff_group.id === staffGroupId) {
                    const [locId, _, jtId] = this.getJobIdComponents(userJob.job_id)
                    const jobId = this.getJobId(locId, _id, jtId)
                    return {
                        ...userJob,
                        job_id: jobId,
                        prevJobId: jobId,
                        staff_group: {
                            id: _id,
                            name,
                        },
                        job_title: {
                            ...userJob.job_title,
                            job_id: jobId,
                            prevJobId: jobId,
                        }
                    }
                }
                return userJob
            }))
        },
        onAddJobTitle({location, staffGroup, jobTitleIndex}) {
            const jobTitleId = this.getIdFromIndex(jobTitleIndex)
            const jobId = this.getJobId(location._id, staffGroup._id, jobTitleId)
            this.userJobs = this.getSortedUserJobs([
                ...this.userJobs,
                {
                    job_id: jobId,
                    prevJobId: jobId,
                    location: {
                        id: location._id,
                        name: location.name,
                    },
                    staff_group: {
                        id: staffGroup._id,
                        name: staffGroup.name,
                    },
                    job_title: {
                        id: jobTitleId,
                        prevId: jobTitleId,
                        title: null,
                        max_shift_duration: null,
                        isSavedInDb: false,
                        job_id: jobId,
                        prevJobId: jobId,
                    },
                }
            ])
        },
        onChangeJobTitle({_id, title, max_shift_duration}, jobTitleId) {
            this.userJobs = this.getSortedUserJobs(this.userJobs.map(userJob => {
                if (userJob.job_title.id === jobTitleId) {
                    const [locId, sgId, _] = this.getJobIdComponents(userJob.job_id)
                    const jobId = this.getJobId(locId, sgId, _id)
                    return {
                        ...userJob,
                        job_id: jobId,
                        job_title: {
                            ...userJob.job_title,
                            id: _id,
                            title,
                            max_shift_duration,
                            job_id: jobId,
                        }
                    }
                }
                return userJob
            }))
        },
        onDeleteJobTitle(jobId, prevJobId, jobs) {
            this.userJobs = this.getSortedUserJobs(
                this.userJobs.filter(userJob => userJob.job_id !== jobId)
            )
            this.prevUserJobs = this.getSortedUserJobs(
                this.prevUserJobs.filter(userJob => userJob.job_id !== prevJobId)
            )
            this.$emit('onDeleteJobTitle', jobs)
        },
        onSaveJobTitle(jobId, prevJobId, jobs) {
            let updatedUserJob = null
            this.userJobs = this.getSortedUserJobs(
                this.userJobs.map(userJob => {
                    if (userJob.job_id === jobId) {
                        updatedUserJob = {
                            ...userJob,
                            prevJobId: userJob.job_id,
                            job_title: {
                                ...userJob.job_title,
                                prevId: userJob.job_title.id,
                                prevJobId: userJob.job_title.job_id,
                                isSavedInDb: true,
                            }
                        }
                        return updatedUserJob
                    }
                    return userJob
                })
            )
            const findIndex = this.prevUserJobs.findIndex(userJob => userJob.job_id === prevJobId)
            if (findIndex > -1) {
                this.prevUserJobs = this.getSortedUserJobs(
                    this.prevUserJobs.map(userJob => {
                        if (userJob.job_id === prevJobId) {
                            if (updatedUserJob) {
                                return updatedUserJob
                            }
                        }
                        return userJob
                    })
                )
            } else {
                if (updatedUserJob) {
                    this.prevUserJobs = this.getSortedUserJobs(
                        [...this.prevUserJobs, updatedUserJob]
                    )
                }
            }
            this.$emit('onSaveJobTitle', jobs)
        },
        getIdFromIndex(index) {
            return `${index}`
        },
        getJobId(locationId, staffGroupId, jobTitleId) {
            return `${locationId}|${staffGroupId}|${jobTitleId}`
        },
        getJobIdComponents(jobId) {
            return jobId.split('|')
        },
        getSortedUserJobs(userJobs) {
            const userJobsByStaffGroup = userJobs.reduce((userJobsByStaffGroup, userJob) => {
                const key = userJob.staff_group.id
                return {
                    ...userJobsByStaffGroup,
                    [key]: [...userJobsByStaffGroup.hasOwnProperty(key) ?
                        userJobsByStaffGroup[key] : [], userJob]
                }
            }, {})
            return _.flattenDeep(
                _.uniq(userJobs.map(userJob => userJob.staff_group.id)).map(id => userJobsByStaffGroup[id])
            )
        }
    },
    watch: {
        jobs: {
            immediate: true,
            handler(jobs) {
                this.userJobs = this.getSortedUserJobs(
                    Array.from(jobs).map(job => ({
                        ...job,
                        prevJobId: job.job_id,
                        job_title: {
                            ...job.job_title,
                            prevId: job.job_title.id,
                            isSavedInDb: true,
                            job_id: job.job_id,
                            prevJobId: job.job_id,
                        },
                    }))
                )
                this.prevUserJobs = Array.from(this.userJobs)
            }
        }
    }
}
</script>

<style scoped>
.user-jobs-container {
    border: 2px solid #777680;
    border-radius: 12px;
    padding: 16px !important;
}
</style>
