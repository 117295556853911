/*
    Provide a user and a list of permissions to search for. If the user has ALL of the permissions in the list,
    the result will be true. Unless an explicit flag is provided, the function will return true if the user has the
    'admin admin' permission without checking for the rest of the permissions. With a true explicit flag, the function will
    check for each of the permissions even if the user is admin.
 */
function userHasPermissions(user, permissions, explicit = false) {
    if (typeof user.permissions === 'undefined' || !user.permissions.length) {
        return false
    }

    if (!explicit && userIsOwner(user)) {
        return true
    }

    // If the user is inactive, filter out permissions that don't contain 'view'.
    if (!userIsActive(user)) {
        permissions = permissions.filter(permission => permission.includes('view'));
    }

    return permissions.every(permission => user.permissions.includes(permission))
}

/*
    Provide a user and a permission to search for. If the user has the permission, the result will be true.
    Unless an explicit flag is provided, the function will return true if the user has the
    'admin admin' permission without checking for the other permissions. With a true explicit flag, the function will
    check for the permission even if the user is admin.
 */
function userHasPermission(user, permission, explicit = false) {
    if (typeof user.permissions === 'undefined' || !user.permissions.length) {
        return false
    }

    if (!explicit && userIsOwner(user)) {
        return true
    }

    // If the user is inactive and the permission doesn't contain 'view', return false.
    if (!userIsActive(user) && !permission.includes('view')) {
        return false;
    }

    return user.permissions.includes(permission)
}

function userIsOwner(user) {
    if (typeof user === 'undefined' || !user) {
        return false
    }

    if (typeof user.is_admin !== "undefined") {
        return user.is_admin
    }

    if (typeof user.permissions === 'undefined' || !user.permissions.length) {
        return false
    }

    return user.permissions.includes('admin admin')
}

function userIsAdministrator(user) {
    return hasRole(user, 'Administrator')
}

function userIsManager(user) {
    return hasRole(user, 'Manager')
}

function userIsStaff(user) {
    return hasRole(user, 'Staff')
}

function hasRole(user, role) {
    if(user) {
        const { role_name } = user
        if(role_name) {
            return role_name === role
        }
        return false
    }
    return false
}

function userIsActive(user) {
    if (typeof user === 'undefined' || !user) {
        return false
    }

    if (typeof user.status === "undefined") {
        return false
    }

    return user.status === 'active'
}

export {
    userHasPermission,
    userHasPermissions,
    userIsOwner,
    userIsAdministrator,
    userIsManager,
    userIsStaff
}
