<template>
    <div class="get-started-container">
        <p class="text--h1">
            Welcome to Koroid
        </p>
        <p class="text--paragraph text-center">
            Follow these steps to set up your organization and complete your profile.
        </p>
        <v-btn
            class="mt-3"
            color="primary"
            @click="onGetStarted"
        >
            Get Started
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "GetStarted",
    methods: {
        onGetStarted() {
            this.$emit('onGetStarted')
        }
    }
}
</script>

<style scoped>
.get-started-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
