<template>
    <v-dialog v-model="modalValue" scrollable width="300px" persistent>
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title>Automated Shift Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="modalValue = false">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="mt-6">
                <div class="mb-3">
                    <v-icon color="primary">mdi-account</v-icon>
                    <span class="text--h5 ml-1">Created By</span>
                    <div class="text--h6 black--text" style="margin-left: 33px;">
                        {{ createdBy }}
                    </div>
                </div>

                <div class="mb-3">
                    <v-icon color="primary">mdi-calendar</v-icon>
                    <span class="text--h5 ml-1">Start Date</span>
                    <div class="text--h6 black--text" style="margin-left: 33px;">
                        {{ startDate }}
                    </div>
                </div>

                <div class="mb-3">
                    <v-icon color="primary">mdi-calendar-refresh</v-icon>
                    <span class="text--h5 ml-1">Shifts Span</span>
                    <div class="text--h6 black--text" style="margin-left: 33px;">
                        {{ shiftsSpan }}
                    </div>
                </div>

                <div>
                    <v-icon color="primary">mdi-earth</v-icon>
                    <span class="text--h5 ml-1">Timezone</span>
                    <div class="text--h6 black--text" style="margin-left: 33px;">
                        {{ timezone }}
                    </div>
                </div>
            </v-card-text>


        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "AutomatedShiftDetails",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        createdBy: {
            type: String,
            default: 'N/A'
        },
        startDate: {
            type: String,
            default: 'N/A'
        },
        shiftsSpan: {
            type: String,
            default: 'N/A'
        },
        timezone: {
            type: String,
            default: 'N/A'
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },

}
</script>

<style scoped>
</style>
