<template>
    <div>
        <dep-sg-jt-sm-filters
            v-model="selectedFilters"
            :readonly="readonly"
            :preset="preset"
            :report-type="reportType"
            :location="location"
            @onChange="onChange"
        />
        <v-autocomplete
            v-model="selectedStatus"
            :items="statuses"
            item-value="id"
            item-text="text"
            :readonly="readonly"
            :clearable="!readonly"
            label="Shift Status"
            @change="onChange"
            outlined
        />
        <v-autocomplete
            v-model="selectedNoShow"
            :items="noShows"
            item-value="id"
            item-text="text"
            :readonly="readonly"
            :clearable="!readonly"
            label="No Show"
            @change="onChange"
            outlined
        />
        <v-autocomplete
            v-model="selectedCallOut"
            :items="callOuts"
            item-value="id"
            item-text="text"
            :readonly="readonly"
            :clearable="!readonly"
            label="Call Out"
            @change="onChange"
            outlined
        />
    </div>
</template>

<script>
import DepSgJtSmFilters from "./DepSgJtSmFilters";

export default {
    name: "DepSgJtSmStatusNoShowFilters",
    components: { DepSgJtSmFilters },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => null,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedFilters: {},
        selectedStatus: null,
        statuses: [{
            id: 'filled',
            text: 'Filled',
        }, {
            id: 'open',
            text: 'Open',
        }],
        selectedNoShow: null,
        selectedCallOut: null,
        noShows: [{
            id: 'only_no_shows',
            text: 'Only Shifts Marked \"No Show\"',
        }, {
            id: 'exclude_no_shows',
            text: 'Exclude Shifts Marked \"No Show\"',
        }],
        callOuts: [{
            id: 'only_call_outs',
            text: 'Only Shifts Marked \"Call Out\"',
        }, {
            id: 'exclude_call_outs',
            text: 'Exclude Shifts Marked \"Call Out\"',
        }],
    }),
    computed: {
        filters() {
            return {
                ...this.selectedFilters,
                status: this.selectedStatus,
                no_shows: this.selectedNoShow,
                call_outs: this.selectedCallOut,
            }
        }
    },
    methods: {
        onChange() {
            this.$emit('onChange')
        }
    },
    watch: {
        filters(filters) {
            this.$emit('input', filters)
        },
        preset: {
            immediate: true,
            handler (preset) {
                if (preset) {
                    const { filters } = preset
                    if (filters) {
                        const { status, no_shows, call_outs } = filters
                        if (status) {
                            this.selectedStatus = status
                        }
                        if (no_shows) {
                            this.selectedNoShow = no_shows
                        }
                        if (call_outs) {
                            this.selectedCallOut = call_outs
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
