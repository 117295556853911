<template>
    <v-tooltip
        content-class="employment-type-tooltip"
        right>
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
                <v-btn
                    icon
                    style="margin-top: 10px;"
                >
                <v-icon color="primary_text">mdi-information</v-icon>
                </v-btn>
            </div>
        </template>
        <div>
            <span class="text--h3">
                Max Hours/Day
            </span>
            <p class="text--paragraph">
                Maximum number of hours a Staff Member can work in a 24-hour period.
            </p>
            <span class="text--h3">
                Max Consecutive Hours
            </span>
            <p class="text--paragraph">
                Number of hours employees can work in a row, across any number of shifts.
            </p>
            <span class="text--h3">
                Required Break
            </span>
            <p class="text--paragraph">
                Number of hours employees need before they can be scheduled for another shift after reaching their Max Consecutive Hours.
            </p>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    name: "EmploymentTypeTooltip"
}
</script>

<style scoped>
    .employment-type-tooltip {
        opacity: 1;
        font-size: 12px;
        width: 364px;
        padding: 10px 15px;
        background: #DFE0FF;
        border-radius: 6px;
        border: 1px solid #000000;
        color: #000;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
    }
</style>
