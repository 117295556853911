<template>
    <div>
        <dep-sg-jt-sm-filters
            v-model="selectedFilters"
            :readonly="readonly"
            :preset="preset"
            :report-type="reportType"
            :location="location"
            @onChange="onChange"
        />
        <status-select-input
            v-model="selectedStatus"
            :readonly="readonly"
            :preset="preset"
            @onChangeStatus="onChange"
        />
    </div>
</template>

<script>
import DepSgJtSmFilters from "./DepSgJtSmFilters";
import StatusSelectInput from "../FormInputs/StatusSelectInput";

export default {
    name: "DepSgJtSmStatusFilters",
    components: { DepSgJtSmFilters, StatusSelectInput },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        preset: {
            type: Object,
            default: () => null,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedFilters: {},
        selectedStatus: [],
    }),
    computed: {
        filters() {
            return {
                ...this.selectedFilters,
                status: this.selectedStatus,
            }
        }
    },
    methods: {
        onChange() {
            this.$emit('onChange')
        }
    },
    watch: {
        filters(filters) {
            this.$emit('input', filters)
        },
        preset: {
            immediate: true,
            handler(preset) {
                if(preset) {
                    const { filters } = preset
                    if(filters) {
                        const { statuses } = filters
                        if(Array.isArray(statuses)) {
                            this.selectedStatus = Array.from(statuses)
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
