<template>
    <div>
        <location-card
            :location="location"
            :is-monthly-view="isMonthlyView"
            :is-single-view="true"
            :is-single-location="isSingleLocation"
            :loading="loading"
            class="location-card-container"
            @onViewLocations="onViewLocations"
            @onUpdateLocation="onUpdateLocation">
        </location-card>
        <departments-view
            :key="departmentsViewKey"
            :location="location"
            :loading="loading"
            @onSortByChange="onSortByChange">
        </departments-view>
    </div>
</template>

<script>
import LocationCard from "../cards/location/LocationCard";
import DepartmentsView from "./DepartmentsView";

export default {
    name: "LocationView",
    components: { LocationCard, DepartmentsView },
    props: {
        location: {
            type: Object,
            default: () => ({})
        },
        isMonthlyView: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isSingleLocation: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        departmentsViewKey: 3333,
    }),
    methods: {
        onViewLocations() {
            this.$emit('onViewLocations')
        },
        onUpdateLocation(locationId) {
            this.$emit('onUpdateLocation', locationId)
        },
        onSortByChange(sortBy) {
            this.$emit('onSortByChange', sortBy)
        }
    },
    watch: {
        location(newLoc, oldLoc) {
            if(oldLoc && newLoc) {
                if(oldLoc.name !== newLoc.name) {
                    this.departmentsViewKey = this.departmentsViewKey + 1
                }
            }
        },
        loading(newLoading, oldLoading) {
            if(oldLoading && !newLoading) {
                this.departmentsViewKey = this.departmentsViewKey + 1
            }
        }
    }
}
</script>

<style scoped>
    .location-card-container {
        margin-top: 30px;
    }
</style>
