<template>
    <v-expansion-panel class="ds-expansion-panel">
        <v-expansion-panel-header class="ds-expansion-panel-header">
            <span v-if="has_staff_group && has_staffing_details">
                {{ staff_group_staffing_detail.staff_group_name }}
            </span>

            <v-autocomplete
                v-else
                name="staff-group"
                label="Staff Group"
                class="staff-group-select"
                v-model="staff_group_id"
                :items="available_staff_groups"
                item-value="_id"
                item-text="name"
                autocomplete="off"
                hide-details
                outlined
                dense
                @change="onStaffGroupChange"
            >
                <template v-slot:prepend-inner>
                    <v-icon color="primary">mdi-account-group</v-icon>
                </template>
            </v-autocomplete>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="ds-expansion-panel-content mt-4">
            <staffing-detail
                v-for="(staffing_detail, index) in staffing_details"
                :key="index"
                :index="index"
                :staffing-detail="staffing_detail"
                :selectable-managers="managers_data"
                :staffing-details-count="staffing_details_count"
            />

            <v-row>
                <v-col cols="12">
                    <v-btn
                        text
                        color="primary"
                        @click="onAddJobTitle"
                        :disabled="!has_staff_group || !hasAuthStaffGroupPermission"
                    >
                        Add Job Title
                    </v-btn>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import StaffingDetail from "./StaffingDetail";

export default {
    name: "StaffGroupStaffingDetailPanel",
    components: {StaffingDetail},
    props: {
        staffGroupStaffingDetail: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            staff_group_staffing_detail: this.staffGroupStaffingDetail,
            staff_group_id: this.staffGroupStaffingDetail.staff_group_id,
            staffing_details: this.staffGroupStaffingDetail.staffing_details,
        }
    },
    computed: {
        has_staffing_details() {
            return !!this.staffing_details.length
        },

        has_staff_group() {
            return !!this.staff_group_id
        },
        hasAuthStaffGroupPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.DEPARTMENTS.AUTHORIZE_STAFF_GROUP)
        },
        available_staff_groups: {
            get() {
                let available_staff_groups = [...this.$store.getters.availableStaffGroupsForSelection]

                if (!this.staff_group_id) {
                    return available_staff_groups
                }

                available_staff_groups.unshift(this.staff_group_data)
                return available_staff_groups
            }
        },

        managers_data: {
            get() {
                return this.$store.getters.managersInCurrentLocation
            }
        },

        staff_group_data: {
            get() {
                if (!this.staff_group_id) {
                    return {}
                }

                return this.$store.getters.getStaffGroupById(this.staff_group_id)
            }
        },

        staffing_details_count() {
            return this.staffing_details.length
        }
    },
    methods: {
        onStaffGroupChange() {
            this.$store.dispatch('setStaffingDetailStaffGroup', {index: this.index, staff_group_id: this.staff_group_id})
        },

        onAddJobTitle() {
            this.$store.dispatch('addNewStaffingDetail', this.staff_group_id)
        },
    },
    watch: {
        staffGroupStaffingDetail: {
            immediate: true,
            deep: true,
            handler: function (staffGroupStaffingDetail) {
                this.staff_group_staffing_detail = staffGroupStaffingDetail
                this.staff_group_id = staffGroupStaffingDetail.staff_group_id
                this.staffing_details = staffGroupStaffingDetail.staffing_details
            }
        }
    }
}
</script>

<style scoped>
    .status-indicator-icon {
        flex: unset !important;
        margin-right: 16px;
    }

    .active-indicator {
        color: #6BC96E;
    }

    .inactive-indicator {
        color: #ACAAAF;
    }

    .staff-group-select {
        flex: unset !important;
    }
</style>
