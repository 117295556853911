<template>
    <v-card :class="containerClass" v-show="shown" :height="maxHeight < 560 ? maxHeight : 560" :elevation="2">
        <v-form ref="reportDatePickerFormRef">
            <v-container fluid>
                <v-row class="mb-3">
                    <v-col class="report-date-picker-row d-flex justify-center">
                        <v-btn-toggle
                            v-model="dateType"
                            color="secondary"
                            mandatory
                            @change="onDateTypeChange"
                        >
                            <v-btn :value="DATE_TYPES.CUSTOM" :disabled="readonly">
                                Custom
                            </v-btn>
                            <v-btn :value="DATE_TYPES.DAY" :disabled="readonly">
                                Day
                            </v-btn>
                            <v-btn :value="DATE_TYPES.WEEK" :disabled="readonly">
                                Week
                            </v-btn>
                            <v-btn :value="DATE_TYPES.MONTH" :disabled="readonly">
                                Month
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="report-date-picker-row">
                        <v-text-field
                            :label="dateTextFieldProps.label"
                            :value="dateTextFieldProps.value"
                            readonly
                            prepend-icon="mdi-calendar"
                            :rules="rules.required"
                            :error-messages="errorMessages"
                        >

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col class="report-date-picker-row mx-5 px-5">
                        <v-date-picker
                            :key="datePickerKey"
                            v-model="selectedDate"
                            elevation="15"
                            locale="en-US"
                            color="secondary"
                            :range="datePickerProps.range"
                            :type="datePickerProps.type"
                            :title-date-format="getTitleFormat"
                            :readonly="readonly"
                            :disabled="readonly"
                            full-width
                            @click:date="onDateClick"
                        >
                        </v-date-picker>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-card>
</template>

<script>
import validationRules from '../../../lib/mixins/validationRules'

export default {
    name: "ReportDatePicker",
    mixins: [validationRules],
    props: {
        value: {},
        readonly: {
            type: Boolean,
            default: false,
        },
        shown: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        const DATE_TYPES = {
            CUSTOM: 'custom',
            DAY: 'day',
            WEEK: 'week',
            MONTH: 'month'
        }
        const DATE_LABELS = {
            CUSTOM: 'Selected Date Range',
            DAY: 'Selected Day',
            WEEK: 'Selected Week',
            MONTH: 'Selected Month'
        }
        const DATE_PICKER_TYPES = {
            DATE: 'date',
            MONTH: 'month'
        }
        return {
            selectedDate: [],
            errorMessages: [],
            datePickerKey: 10000,
            datePickerProps: {
                range: true,
                type: DATE_PICKER_TYPES.DATE,
            },
            dateTextFieldProps: {
                label: DATE_LABELS.CUSTOM,
                value: ''
            },
            dateType: DATE_TYPES.CUSTOM,
            DATE_TYPES,
            DATE_LABELS,
            DATE_PICKER_TYPES
        }
    },
    computed: {
        dateRange() {
            const {
                selectedDate,
                dateType,
                DATE_TYPES
            } = this
            const defaultDate = moment().format('YYYY-MM-DD')
            if(dateType === DATE_TYPES.CUSTOM) {
                if(Array.isArray(selectedDate)) {
                    if(selectedDate.length > 1) {
                        const selectedDateCopy = Array.from(selectedDate)
                        return this.sortDates(selectedDateCopy, 'YYYY-MM-DD')
                    } else {
                        const date = selectedDate[0]
                        return [date, date]
                    }
                } else {
                    return [defaultDate, defaultDate]
                }
            } else if(dateType === DATE_TYPES.DAY) {
                if(typeof selectedDate === 'string') {
                    return [selectedDate, selectedDate]
                } else {
                    return [defaultDate, defaultDate]
                }
            } else if(dateType === DATE_TYPES.WEEK) {
                if(Array.isArray(selectedDate)) {
                    return selectedDate
                } else {
                    return [defaultDate, defaultDate]
                }
            } else if(dateType === DATE_TYPES.MONTH) {
                if(typeof selectedDate === 'string') {
                    const month = moment(selectedDate, 'YYYY-MM')
                    return [
                        month.startOf('month').format('YYYY-MM-DD'),
                        month.endOf('month').format('YYYY-MM-DD')
                    ]
                } else {
                    return [defaultDate, defaultDate]
                }
            } else {
                return [defaultDate, defaultDate]
            }
        },
        containerClass() {
            return {
                'report-date-picker-container': true,
                'readonly-container': this.readonly,
                'scrollbox': true,
                'rounded-card': true
            }
        },
        maxHeight() {
            return window.innerHeight - 288
        },
        reportDatePickerFormRef() {
            return this.$refs.reportDatePickerFormRef
        },
    },
    methods: {
        onDateTypeChange() {
            const { dateType } = this
            if(dateType) {
                const { DATE_TYPES, DATE_PICKER_TYPES } = this
                this.datePickerProps = {
                    range: dateType === DATE_TYPES.CUSTOM || dateType === DATE_TYPES.WEEK,
                    type: dateType === DATE_TYPES.MONTH ? DATE_PICKER_TYPES.MONTH : DATE_PICKER_TYPES.DAY
                }
                const now = moment()
                if(dateType === DATE_TYPES.CUSTOM) {
                    this.selectedDate = []
                } else if(dateType === DATE_TYPES.DAY) {
                    this.selectedDate = now.format('YYYY-MM-DD')
                } else if(dateType === DATE_TYPES.WEEK) {
                    this.selectedDate = [now.format('YYYY-MM-DD'), now.add(6, 'days').format('YYYY-MM-DD')]
                } else if(dateType === DATE_TYPES.MONTH) {
                    this.selectedDate = now.format('YYYY-MM')
                }
                this.datePickerKey = this.datePickerKey + 1
            }
        },
        onDateClick() {
            if(this.dateType === this.DATE_TYPES.WEEK) {
                if(Array.isArray(this.selectedDate) && this.selectedDate.length > 0) {
                    const from = this.selectedDate[0]
                    const fromMoment = moment(from)
                    const to = fromMoment.add(6, 'days').format('YYYY-MM-DD')
                    this.selectedDate = [from, to]
                }
            }
        },
        transformDate(date) {
            const parts = date.split('-')
            if(parts.length > 1) {
                if(parts.length > 2) {
                    const [year, month, day] = date.split('-')
                    return `${month}/${day}/${year}`
                } else {
                    const [year, month] = date.split('-')
                    return `${month}/${year}`
                }
            }
            return date
        },
        getTitleFormat() {
            const {
                selectedDate,
                dateType,
                DATE_TYPES,
                dateRange
            } = this
            const now = moment()
            if(dateType === DATE_TYPES.CUSTOM) {
                if(Array.isArray(selectedDate)) {
                    if (selectedDate.length > 1) {
                        const first = moment(dateRange[0])
                        const second = moment(dateRange[1])
                        return `${first.format('MMM D')} - ${second.format('MMM D')}`
                    } else if (selectedDate.length > 0) {
                        const date = moment(dateRange[0])
                        return date.format('ddd, MMM D')
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } else if(dateType === DATE_TYPES.DAY) {
                if(typeof selectedDate === 'string') {
                    return moment(selectedDate).format('ddd, MMM D')
                } else {
                    return now.format('ddd, MMM D')
                }
            } else if(dateType === DATE_TYPES.WEEK) {
                if(Array.isArray(selectedDate)) {
                    const first = moment(dateRange[0])
                    const second = moment(dateRange[1])
                    return `${first.format('MMM D')} - ${second.format('MMM D')}`
                } else {
                    return now.format('ddd, MMM D')
                }
            } else if(dateType === DATE_TYPES.MONTH) {
                if(typeof selectedDate === 'string') {
                    return moment(selectedDate).format('MMMM')
                } else {
                    return now.format('MMMM')
                }
            } else {
                return now.format('ddd, MMM D')
            }
        },
        sortDates(dates, format) {
            if(Array.isArray(dates) && dates.length > 1) {
                const first = moment(dates[0], format)
                const second = moment(dates[1], format)
                if(first.isAfter(second)) {
                    return [dates[1], dates[0]]
                }
                return dates
            }
            return dates
        },
        validateForm() {
            if (this.dateType === this.DATE_TYPES.CUSTOM) {
                if (Array.isArray(this.selectedDate) && this.selectedDate.length === 1) {
                    this.errorMessages = [
                        'Must select start and end date'
                    ]
                    return false
                } else if (this.reportDatePickerFormRef) {
                    return this.reportDatePickerFormRef.validate()
                }
            }
            return true
        }
    },
    watch: {
        selectedDate(value) {
            let date
            if(Array.isArray(value)) {
                date = value.map(this.transformDate)
            } else {
                date = this.transformDate(value)
            }
            const { dateType, DATE_TYPES, DATE_LABELS } = this
            if(dateType === DATE_TYPES.CUSTOM) {
                this.dateTextFieldProps = {
                    label: DATE_LABELS.CUSTOM,
                    value: Array.isArray(date) && date.length > 0 ?
                        this.sortDates(date, 'MM/DD/YYYY').join(' - ') : ''
                }
            } else if(dateType === DATE_TYPES.DAY) {
                this.dateTextFieldProps = {
                    label: DATE_LABELS.DAY,
                    value: date
                }
            } else if(dateType === DATE_TYPES.WEEK) {
                this.dateTextFieldProps = {
                    label: DATE_LABELS.WEEK,
                    value: Array.isArray(date) && date.length > 0 ?
                        date.join(' - ') : ''
                }
            } else if(dateType === DATE_TYPES.MONTH) {
                this.dateTextFieldProps = {
                    label: DATE_LABELS.MONTH,
                    value: date
                }
            }
            this.errorMessages = []
            this.$emit('input', this.dateRange)
        }
    },
    mounted() {
        this.$emit('input', this.dateRange)
    }
}
</script>

<style scoped>
    .report-date-picker-container {
        padding: 12px 0;
    }
    .report-date-picker-row {
        padding: 0 12px;
    }
    .readonly-container {
        background-color: #DCDCDC;
    }
    .scrollbox {
        overflow: auto;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
    .rounded-card {
        border-radius: 12px !important;
    }
</style>
