<template>
    <v-row class="dashboard-header-container">
        <v-col md="6" cols="12" align-self="center" class="d-flex text--h1" id="dashboard-header">
            {{ currRange.start.format(format) }} - {{ currRange.end.format(format) }}
        </v-col>
        <v-col md="6" cols="12" class="d-flex" id="dashboard-range">
            <v-btn-toggle
                v-model="range"
                color="secondary"
                mandatory
            >
                <v-btn :value="DATE_RANGES.THIS_WEEK" :disabled="disabled">
                    This Week
                </v-btn>
                <v-btn :value="DATE_RANGES.NEXT_WEEK" :disabled="disabled">
                    Next Week
                </v-btn>
                <v-btn :value="DATE_RANGES.THIS_MONTH" :disabled="disabled">
                    This Month
                </v-btn>
                <v-btn :value="DATE_RANGES.NEXT_MONTH" :disabled="disabled">
                    Next Month
                </v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "DashboardHeader",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        const DATE_RANGES = {
            THIS_WEEK: 'this_week',
            NEXT_WEEK: 'next_week',
            THIS_MONTH: 'this_month',
            NEXT_MONTH: 'next_month',
        }
        return {
            range: DATE_RANGES.THIS_WEEK,
            DATE_RANGES,
            format: 'MMM D, YYYY',
        }
    },
    computed: {
        currTimezone() {
            return this.$root.globalTimezone
        },
        currDate() {
            if(this.currTimezone) {
                return moment().tz(this.currTimezone)
            }
            return moment()
        },
        currRange() {
            const { range, DATE_RANGES } = this
            let start, end
            if(range === DATE_RANGES.THIS_WEEK) {
                start = this.currDate.clone().startOf('week')
                end = this.currDate.clone().endOf('week')
            } else if(range === DATE_RANGES.NEXT_WEEK) {
                start = this.currDate.clone().add(1, 'weeks').startOf('week')
                end = this.currDate.clone().add(1, 'weeks').endOf('week')
            } else if(range === DATE_RANGES.THIS_MONTH) {
                start = this.currDate.clone().startOf('month')
                end = this.currDate.clone().endOf('month')
            } else if(range === DATE_RANGES.NEXT_MONTH) {
                start = this.currDate.clone().add(1, 'months').startOf('month')
                end = this.currDate.clone().add(1, 'months').endOf('month')
            } else {
                start = this.currDate.clone().startOf('week')
                end = this.currDate.clone().endOf('week')
            }
            return { start, end }
        },
        isMonthlyView() {
            const { range, DATE_RANGES } = this
            if(range) {
                return range === DATE_RANGES.THIS_MONTH || range === DATE_RANGES.NEXT_MONTH
            }
            return false
        }
    },
    watch: {
        range: {
            immediate: true,
            handler(range) {
                this.$emit('onChange', range, this.isMonthlyView)
            }
        },
        currTimezone() {
            this.$emit('onChange', this.range, this.isMonthlyView)
        }
    }
}
</script>

<style scoped>
    .dashboard-header-container {
        padding: 0 30px;
        margin-bottom: 18px;
    }
    @media only screen and (min-width: 1264px) {
        #dashboard-range {
            justify-content: flex-end;
        }
    }
    @media only screen and (max-width: 1264px) {
        #dashboard-header, #dashboard-range {
            justify-content: center;
        }
    }
</style>
