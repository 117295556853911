<template>
    <v-dialog v-model="showModal" max-width="450px" elevation="10" scrollable persistent>
        <v-card>
            <v-toolbar dark color="primary" elevation="0" height="60">
                <v-toolbar-title class="white--text text--h2">Existing Availability</v-toolbar-title>
            </v-toolbar>

            <v-card-title class="text--paragraph no-word-break">
                The availability you are trying to add overlaps with existing availability:
            </v-card-title>

            <v-card-text style="max-height: 250px">
                <div
                    v-for="(events, date) in formatted_events_by_date"
                    :key="date"
                >
                    <h4>{{ date }}</h4>
                    <ul>
                        <li
                            v-for="(event, index) in events"
                            :key="index"
                        >
                            {{ event.start }} - {{ event.end }} <span v-if="event.is_recurring" class="font-weight-bold">(Recurring)</span>
                        </li>
                    </ul>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12" class="mt-4">
                        <v-btn block color="primary" @click="confirmMerge">Continue</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    name: "OverlappingEventsModal",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        events: {
            type: Array,
            default: () => []
        },
        timezone: {
            type: String,
            default: 'utc'
        }
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        formatted_events_by_date() {
            return this.events.reduce((groups, event) => {
                let date = moment(event.start_date).tz(this.timezone).format('YYYY/MM/DD')

                if (!groups[date]) {
                    groups[date] = []
                }

                event.start = moment(event.start_date).tz(this.timezone).format('h:mm A')
                event.end = moment(event.end_date).tz(this.timezone).format('h:mm A')
                event.is_recurring = typeof event.availability_event_group_id !== 'undefined' && event.availability_event_group_id

                groups[date].push(event)
                return groups
            }, {})
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
        },

        confirmMerge() {
            this.$emit('merge', true)
            this.closeModal()
        },
    },
    watch: {
        value(newVal) {
            this.showModal = newVal
        },
        showModal(newVal) {
            if (!newVal) {
                this.$emit('input', false)
            }
        }
    }
}
</script>

<style scoped>
    .toolbar-title {
        font-size: 1rem;
    }

    .heading {
        font-size: 18px;
    }
</style>
