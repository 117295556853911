<template>
    <v-card :class="containerClass" v-show="shown" :height="maxHeight < 560 ? maxHeight : 560" :elevation="2">
        <v-form>
            <v-container fluid>
                <v-row class="mb-3">
                    <v-col class="report-date-picker-row d-flex justify-center">
                        <p :class="{'text--paragraph': true, 'text-center': true, 'error--text': !isValid}">
                            Choose a date on the calendar to select the Payroll Period that includes that date.
                        </p>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col class="report-date-picker-row mx-5 px-5">
                        <div style="width: 100%; height: 100%; position: relative;">
                            <loading-spinner :value="true" v-if="api.busy"/>
                            <v-date-picker
                                :value="selectedDates"
                                elevation="15"
                                locale="en-US"
                                color="secondary"
                                show-adjacent-months
                                range
                                :title-date-format="getTitleFormat"
                                :readonly="readonly"
                                :disabled="readonly"
                                full-width
                                @click:date="onDateClick"
                            >
                            </v-date-picker>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="report-date-picker-row mt-3">
                        <v-text-field
                            label="Payroll Period"
                            :value="dateTextFieldProps.value"
                            readonly
                            prepend-inner-icon="mdi-calendar"
                            outlined
                        >

                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </v-card>
</template>

<script>
import LoadingSpinner from "../../../loaders/LoadingSpinner";
import validationRules from '../../../../lib/mixins/validationRules'

export default {
    name: "PayrollPeriodReportDatePicker",
    components: {LoadingSpinner},
    mixins: [validationRules],
    props: {
        value: {},
        readonly: {
            type: Boolean,
            default: false,
        },
        shown: {
            type: Boolean,
            default: true,
        },
        location: {
            type: Object,
            default: () => ({})
        },
        employmentTypeId: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            selectedDate: null,
            lastEmploymentTypeId: null,
            selectedDates: [],
            dateTextFieldProps: {
                value: ''
            },
            isValid: true,
            api: new formHelper(),
        }
    },
    computed: {
        containerClass() {
            return {
                'report-date-picker-container': true,
                'readonly-container': this.readonly,
                'scrollbox': true,
                'rounded-card': true
            }
        },
        maxHeight() {
            return window.innerHeight - 288
        },
        globalLocation() {
            return this.$root.globalLocation;
        },
    },
    methods: {
        onDateClick(date) {
            this.isValid = true
            this.selectedDate = date
            if (Array.isArray(this.selectedDates) && this.selectedDates.length === 2) {
                const start = moment(this.selectedDates[0], 'YYYY-MM-DD').startOf('day')
                const end = moment(this.selectedDates[1], 'YYYY-MM-DD').endOf('day')
                const current = moment(date, 'YYYY-MM-DD')
                if (current.isSameOrAfter(start) && current.isSameOrBefore(end) && this.lastEmploymentTypeId === this.employmentTypeId) {
                    return
                }
            }
            this.api.post('/staff-type/calculate-payroll-period', {
                date,
                timezone: this.location ? this.location.timezone : this.globalLocation.timezone,
                staff_type_id: this.employmentTypeId,
            }).then(({data}) => {
                if (data) {
                    const {start, end, timezone} = data
                    if (start && end && timezone) {
                        const startMoment = moment(start).tz(timezone)
                        const endMoment = moment(end).tz(timezone)
                        this.selectedDates = [
                            startMoment.format('YYYY-MM-DD'),
                            endMoment.format('YYYY-MM-DD'),
                        ]
                        this.dateTextFieldProps = {
                            value: [
                                startMoment.format('MM/DD/YYYY'),
                                endMoment.format('MM/DD/YYYY'),
                            ].join(' - ')
                        }
                        this.lastEmploymentTypeId = this.employmentTypeId
                    }
                }
            }).catch(() => {
            })
        },
        getTitleFormat() {
            if (Array.isArray(this.selectedDates) && this.selectedDates.length === 2) {
                const start = moment(this.selectedDates[0], 'YYYY-MM-DD')
                const end = moment(this.selectedDates[1], 'YYYY-MM-DD')
                return `${start.format('MMM D')} - ${end.format('MMM D')}`
            }
            return 'Select a date'
        },
        validateForm() {
            const isValid = Array.isArray(this.selectedDates) && this.selectedDates.length === 2
            this.isValid = isValid
            return isValid
        },
        getSelectedDate() {
            return this.selectedDate
        }
    },
}
</script>

<style scoped>
.report-date-picker-container {
    padding: 12px 0;
}

.report-date-picker-row {
    margin: 0 16px;
    padding: 0 24px;
}

.readonly-container {
    background-color: #DCDCDC;
}

.scrollbox {
    overflow: auto;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

.rounded-card {
    border-radius: 12px !important;
}
</style>
