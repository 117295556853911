<template>
    <div :class="classObj" :style="styleObj">
        <p class="column-label white--text text-uppercase">
            {{ title }}
        </p>
        <p class="column-label white--text">
            {{ content }}
        </p>
    </div>
</template>

<script>
export default {
    name: "HeaderColumn",
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        maxWidth: {
            type: Number,
            default: 0
        },
        hasColumnSeparator: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classObj() {
            return {
                'fixed-column-container': true,
                'column-separator': this.hasColumnSeparator
            }
        },
        styleObj() {
            if(this.maxWidth) {
                return {
                    'max-width': `${this.maxWidth }%`
                }
            }
            return {}
        }
    }
}
</script>

<style scoped>
    .fixed-column-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
    }
    .column-separator {
        border-right: 2px solid white;
    }
    .column-label {
        font-size: 14px;
        margin-bottom: 0;
    }
</style>
