<template>
    <v-container fluid style="padding: 0;">
        <div class="department-filters-view-container pt-5">
            <div class="min-width">
                <v-autocomplete
                    style="width: 100%;"
                    v-model="selectedDepartmentId"
                    label="Department"
                    item-value="_id"
                    item-text="text"
                    :items="departments"
                    :loading="departmentsLoading"
                    :disabled="eventsLoading"
                    :search-input.sync="searchInput"
                    @change="onChangeDepartment"
                    outlined
                >
                    <template v-slot:prepend-inner>
                        <v-icon color="primary">mdi-domain</v-icon>
                    </template>
                </v-autocomplete>
            </div>
            <div class="pb-10">
                <department-filters
                    :department-id="selectedDepartmentId"
                    :disabled="loading || isSchedulerDisabled"
                ></department-filters>
            </div>
            <div class="align-self-start">
                <v-btn
                    v-if="filtersApplied"
                    color="error"
                    text
                    :disabled="loading || isSchedulerDisabled"
                    @click="onClearAll"
                    style="margin-top: 13px;"
                >
                    Clear All
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
import MenuOptions from '../../../../common/MenuOptions/MenuOptions'
import DepartmentFilters from '../../../Filters/DepartmentFilters'

export default {
    name: "DepartmentFiltersView",
    components: { MenuOptions, DepartmentFilters },
    data: () => ({
        searchInput: '',
    }),
    computed: {
        selectedDepartmentId: {
            get () {
                return this.$store.getters.schedulerGetDepartmentId
            },
            set (departmentId) {
                this.$store.dispatch('schedulerSetDepartmentId', { departmentId })
            }
        },
        departments () {
            return this.$store.getters.schedulerGetDepartments
        },
        departmentsLoading () {
            return this.$store.getters.schedulerGetDepartmentsLoading
        },
        isSchedulerDisabled() {
            return this.$store.getters.schedulerGetIsDisabled
        },
        filtersApplied: {
            get () {
                return this.$store.getters.schedulerGetDepartmentFiltersApplied
            },
            set (filtersApplied) {
                this.$store.dispatch('schedulerSetDepartmentFiltersApplied', { filtersApplied })
            },
        },
        eventsLoading () {
            return this.$store.getters.schedulerGetDepartmentEventsLoading
        },
        loading () {
            return this.departmentsLoading || this.eventsLoading
        },
        globalLocation () {
            return this.$root.globalLocation
        },
    },
    methods: {
        onClearAll () {
            this.filtersApplied = false
            window.EventBus.$emit('scheduler/onClearDepartmentFilters')
            window.EventBus.$emit('departmentScheduler/fetchEvents')
        },
        onChangeDepartment () {
            this.filtersApplied = false
            this.searchInput = ''
        }
    },
    watch: {
        globalLocation () {
            this.selectedDepartmentId = null
        }
    }
}
</script>

<style scoped>
    .department-filters-view-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
        z-index: 18;
    }

    .min-width {
        min-width: 400px;
    }

    @media only screen and (max-width: 580px) {
        .min-width {
            min-width: 150px;
        }
    }
</style>
