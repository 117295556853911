import { render, staticRenderFns } from "./UserJobsTab.vue?vue&type=template&id=1081b466&scoped=true"
import script from "./UserJobsTab.vue?vue&type=script&lang=js"
export * from "./UserJobsTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1081b466",
  null
  
)

export default component.exports