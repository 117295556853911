<template>
    <bar-chart
        :chart-data="chartData"
        :chart-options="chartOptions"
        :height="140"
    />
</template>

<script>
import BarChart from '../../../../charts/bar/BarChart'

export default {
    name: "ShiftsBarChart",
    components: { BarChart },
    props: {
        dataSource: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        chartData() {
            let firstDataSet = [], backgroundColors = [], labels = []
            this.dataSource.forEach(({ x, y }) => {
                labels.push(x)
                firstDataSet.push(y)
                backgroundColors.push(this.getColor(y))
            })
            return {
                labels,
                datasets: [
                    {
                        data: firstDataSet,
                        backgroundColor: backgroundColors,
                        hoverBackgroundColor: backgroundColors,
                    }
                ]
            }
        },
        chartOptions() {
            return {
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            autoSkip: false,
                            callback: value => {
                                const number = parseInt(value)
                                const numberMatch = (val) => {
                                    for (let n = val; n < 100; n = n + 12) {
                                        if (number === n) {
                                            return true
                                        }
                                }
                                }
                                if (numberMatch(4)) {
                                    return '6a'
                                } else if (numberMatch(7)) {
                                    return '12p'
                                } else if (numberMatch(10)){
                                    return '6p'
                                } else if (numberMatch(13)){
                                    return '12a'
                                } else if ((number - 1) % 3 > 0) {
                                    return undefined
                                } else {
                                    return ''
                                }
                            },
                            padding: 10,
                        },
                        gridLines: {
                            color: '#A9A9A9',
                            lineWidth: _.range(this.dataSource.length + 1).map(value => {
                                if (value === this.dataSource.length) {
                                    return 1
                                } else if (value % 12 > 0) {
                                    return 1
                                } else {
                                    return 3
                                }
                            }),
                            zeroLineWidth: 3,
                            drawTicks: false,
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 25,
                            callback: value => {
                                if(value === 0 || value === 100) {
                                    return ''
                                }
                                return `${value}%`
                            },
                            padding: 4
                        },
                        gridLines: {
                            display: false,
                        }
                    }]
                },
                tooltips: {
                    enabled: false,
                }
            }
        },
    },
    methods: {
        getColor (value) {
            if (value <= 10) {
                return '#D13534'
            } else if (value <= 20) {
                return '#D8523F'
            } else if (value <= 30) {
                return '#DF6F49'
            } else if (value <= 40) {
                return '#E68D53'
            } else if (value <= 50) {
                return '#EDAA5D'
            } else if (value <= 60) {
                return '#EBC366'
            } else if (value <= 70) {
                return '#D4C468'
            } else if (value <= 80) {
                return '#BAC569'
            } else if (value <= 90) {
                return '#9FC76B'
            } else {
                return '#85C86D'
            }
        },
    }
}
</script>

<style scoped>

</style>
