<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        @input="stopSearch"
        @change="search = null"
        hide-no-data
        hide-selected
        item-text="full_name"
        item-value="_id"
        label="Staff Members"
        clearable
        multiple
        cache-items
        :chips="chips"
        :deletable-chips="chips"
        no-data-text="No active Staff in Staff Group"
        placeholder="Start typing to search"
        outlined
    >
        <template v-slot:prepend-inner>
            <v-icon color="primary">mdi-account-search</v-icon>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "UserSearch",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        staffGroupId: {
            type: String,
            default: '',
        },
        chips: {
            type: Boolean,
            default: false
        }
    },
    data: ()  => ({
        items: [],
        isLoading: false,
        search: null,
        preventSearch: false,
        api: new formHelper(),
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    watch: {
        search: _.debounce(function(name) {
            // Do not search if query string is empty
            if (!name || (typeof name === 'string' && name.trim().length === 0)) {
                return
            }

            // Do not search if item has been selected from the menu
            if (this.preventSearch) {
                this.preventSearch = false
                if (this.items.some(user => user.full_name === name)) {
                    return
                }
            }

            this.fetchUsers(this.staffGroupId, name)
        }, 500)
    },
    methods: {
        fetchUsers (staffGroupId, query) {
            this.isLoading = true
            this.api.post('/schedules/staff-member-filter-data', {
                staff_group_id: staffGroupId,
                name_query: query,
            }).then(({ data }) => {
                if (Array.isArray(data)) {
                    this.items = Array.from(data)
                }
            }).catch(console.log).finally(() => {
                this.isLoading = false
            })
        },
        stopSearch() {
            this.preventSearch = true
        },
    }
}
</script>

<style scoped>

</style>
