<template>
    <v-expansion-panel class="ds-expansion-panel">
        <v-expansion-panel-header class="ds-expansion-panel-header">
            <span v-if="hasStaffGroupName">
                {{ staffGroup.name }}
            </span>
            <v-row v-else>
                <v-col
                    cols="auto"
                >
                    <div
                        class="d-flex staff-group-autocomplete"
                        @click="onClickStaffGroup"
                    >
                        <v-autocomplete
                            :items="availableStaffGroups"
                            name="staff-group"
                            label="Staff Group"
                            class="staff-group-autocomplete"
                            item-value="_id"
                            item-text="name"
                            autocomplete="off"
                            :readonly="!canEdit"
                            hide-details
                            outlined
                            dense
                            return-object
                            @change="onChangeStaffGroup"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon color="primary">mdi-account-group</v-icon>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-col>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="ds-expansion-panel-content mt-4" :key="staffGroupKey">
            <job-title
                v-for="(jt, index) in staffGroup.job_titles"
                :key="index"
                :user-id="userId"
                :job-title="jt"
                :available-job-titles="staffGroup.available_job_titles"
                :class="{'job-title-container': index < staffGroup.job_titles.length - 1}"
                @onChangeJobTitle="onChangeJobTitle"
                @onSaveJobTitle="onSaveJobTitle"
                @onDeleteJobTitle="onDeleteJobTitle"
            />
            <v-row>
                <v-col cols="12" class="ds-modal-input-container">
                    <v-btn
                        text
                        color="primary"
                        class="mb-3"
                        :disabled="isAddJobTitleDisabled || !canEdit"
                        @click="onAddJobTitle"
                    >
                        Add Job Title
                    </v-btn>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import JobTitle from "./JobTitle";

export default {
    name: "StaffGroup",
    components: {JobTitle},
    props: {
        userId: {
            type: String,
            default: null,
        },
        staffGroup: {
            type: Object,
            default: () => ({})
        },
        availableStaffGroups: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        staffGroupKey: 9999,
    }),
    computed: {
        hasStaffGroupName() {
            return Boolean(this.staffGroup._id) && Boolean(this.staffGroup.name)
        },
        isAddJobTitleDisabled() {
            const {job_titles, available_job_titles} = this.staffGroup
            if (Array.isArray(job_titles) && Array.isArray(available_job_titles)) {
                return job_titles.length === available_job_titles.length
            }
            return false
        },
        canEdit() {
            return this.$userHasPermission(this.$user, this.$config.permissions.USERS.EDIT_OTHERS_JOBS)
        }
    },
    methods: {
        onClickStaffGroup(event) {
            event.stopPropagation()
        },
        onChangeStaffGroup(staffGroup) {
            this.$emit('onChangeStaffGroup', staffGroup)
        },
        onAddJobTitle() {
            this.$emit('onAddJobTitle', {
                staffGroup: {
                    _id: this.staffGroup._id,
                    name: this.staffGroup.name,
                },
                jobTitleIndex: this.staffGroup.job_titles.length
            })
        },
        onChangeJobTitle(jobTitle, jobTitleId) {
            this.$emit('onChangeJobTitle', jobTitle, jobTitleId)
        },
        onDeleteJobTitle(jobId, prevJobId, jobs) {
            this.$emit('onDeleteJobTitle', jobId, prevJobId, jobs)
            this.staffGroupKey = this.staffGroupKey + 1
        },
        onSaveJobTitle(jobId, prevJobId, jobs) {
            this.$emit('onSaveJobTitle', jobId, prevJobId, jobs)
        }
    }
}
</script>

<style scoped>
.job-title-container {
    margin-bottom: -8px;
}

.staff-group-autocomplete {
    flex: unset !important;
}
</style>
