<template>
    <v-dialog v-model="showModal" scrollable max-width="450px" persistent>
        <v-card>

            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title>
                    <div class="d-flex">
                        <v-icon style="color: #1B1B1F;" class="mr-3">mdi-alert</v-icon>
                        <div class="mt-1 text--h2">
                            Mark "Call Out"
                        </div>
                    </div>
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="black--text mt-5 text--paragraph">
                <p class="black--text">Would you like to mark {{ staff_member }} as a "Call Out" for this shift?</p>

                <p class="black--text">This action can't be undone.</p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn
                            class="mr-2"
                            text
                            color="primary_text"
                            @click="cancel"
                        >
                            Go Back
                        </v-btn>

                        <v-btn
                            class="ml-2"
                            outlined
                            color="error"
                            @click="confirm"
                            name="confirm"
                        >
                            Call Out
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "CallOutConfirmationDialog",

        data() {
            return {
                staff_member: 'Staff Member',
                showModal: false,
                resolve: null,
                reject: null,
                api: new formHelper(),
            }
        },

        methods: {
            open(staff_member) {
                this.staff_member = staff_member

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                    this.showModal = true
                })
            },

            confirm() {
                this.closeModal()
                this.resolve()
            },

            cancel() {
                this.closeModal()
                this.reject()
            },

            closeModal() {
                this.showModal = false
                this.staff_member = "Staff Member"
            },
        },
    }
</script>

<style scoped>

</style>
