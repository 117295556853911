<template>
    <v-card :class="{ 'actionable-user-list-container': hasMaxHeight }" style="padding-top: 8px; padding-bottom: 8px;">
        <div v-if="loading">
            <div v-for="index in 6" :key="(index + 1) * 123">
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-divider v-if="index < 6"></v-divider>
            </div>
        </div>
        <template v-else>
            <div v-if="showNoAvailableUsers" class="text-body-1 d-flex justify-center align-center py-8" style="color: #6c757d;">
                No staff with matching availability.
            </div>
            <div v-if="showNoAvailableDivider" style="border-bottom: 2px solid black;">

            </div>
            <v-list v-if="usersCount > 0" style="padding-top: 0; padding-bottom: 0;">
                <v-slide-y-transition group tag="div">
                    <template v-for="(user, index) in users">
                        <actionable-user-list-item
                            :key="`user-item-${user._id}-${index}`"
                            :user="user"
                            :score="predictedAvailability && predictedAvailability[user._id]"
                            @click="onUserClick"
                            @userAssignedDirectlyShift="handleDirectUserAssign"
                            :can-direct-assign="canDirectAssign"
                        ></actionable-user-list-item>
                        <div :key="`user-divider-${user._id}`" v-if="!showNoAvailableDivider && index === availableUsersCount - 1 && type === 'all'" style="border-bottom: 2px solid black;">

                        </div>
                    </template>
                </v-slide-y-transition>

                
            </v-list>
            <div v-if="showNoEligibleUsers" class="text-body-1 d-flex justify-center align-center py-8" style="color: #6c757d; padding-top: 20px; padding-bottom: 20px;">
                    No eligible staff for this shift.
                </div>
        </template>
    </v-card>
</template>

<script>
import ActionableUserListItem from './ActionableUserListItem'
export default {
    name: "ActionableUserList",
    components: { ActionableUserListItem },
    props: {
        users: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'all',
        },
        predictedAvailability: {
            type: Object,
            default: null,
        },
        canDirectAssign: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        usersCount () {
            if (Array.isArray(this.users)) {
                return this.users.length
            }
            return 0
        },
        availableUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.available).length
            }
            return 0
        },
        eligibleUsersCount () {
            if (Array.isArray(this.users)) {
                return this.users.filter(user => user.eligible).length
            }
        },
        hasMaxHeight () {
            if (this.type === 'all') {
                return !this.showNoAvailableUsers || !this.showNoEligibleUsers
            } else if (this.type === 'assign') {
                return this.availableUsersCount > 0
            } else if (this.type === 'offer') {
                return this.eligibleUsersCount > 0
            } else {
                return false
            }
        },
        showNoAvailableUsers () {
            const { type, availableUsersCount } = this
            if (availableUsersCount > 0) {
                return false
            }
            return type === 'all' || type === 'assign'
        },
        showNoEligibleUsers () {
            const { type, eligibleUsersCount } = this
            if (eligibleUsersCount > 0) {
                return false
            }
            return type === 'all' || type === 'offer'
        },
        showNoAvailableDivider () {
            return this.showNoAvailableUsers && this.type === 'all'
        },
    },
    methods: {
        onUserClick(user) {
            this.$emit('click', user, false);
        },
        handleDirectUserAssign(user, direct_assign) {
            this.$emit('userAssignedDirectlyShift', user, direct_assign);
        }
    }
}
</script>

<style scoped>
    .actionable-user-list-container {
        height: 99%;
    }
</style>
