<template>
    <v-data-table
        :headers="headers"
        :items="formattedUsers"
        :loading="loadingUsers"
        :server-items-length="totalUsers"
        :options.sync="options"
        @update:options="onUpdateOptions"
        @click:row="onUserClick"
        id="administrators-table"
        :title="dataTableTitle"
        no-data-text="Assign Users the Administrator role to grant them oversight of selected Managers, Staff Groups, and Departments. This role also provides access to Koroid Reporting features."
    >
        <template v-slot:item.status="{ item }">
            <v-chip :class="{ 'ds-active-chip' : item.status === 'active', 'ds-inactive-chip': item.status !== 'active' }">{{ item.status | capitalize}}</v-chip>
        </template>
        <template v-slot:item.username="{ item }">
            <custom-avatar :user="item" chip></custom-avatar>
        </template>
        <template v-slot:item.lastLogin="{ item }">
            {{ item.last_login_label }}
        </template>
        <template v-slot:item.location="{ item }">
            <truncating-chips-list
                :items="item.displayedLocations"
                @onMouseEnter="onMouseEnterTruncatingList"
                @onMouseLeave="onMouseLeaveTruncatingList"
            />
        </template>
    </v-data-table>
</template>

<script>

import CustomAvatar from "../../components/avatar/CustomAvatar";
import UserTooltip from "../../components/profile/UserTooltip";
import TruncatingChipsList from "../../components/common/Lists/TruncatingChipsList";
import userMethods from "../../lib/mixins/userMethods";
import dataTableTitle from "../../lib/mixins/dataTableTitle";

export default {
    name: "AdministratorsTable",
    components: {
        CustomAvatar,
        TruncatingChipsList
    },
    mixins: [userMethods, dataTableTitle],
    props: {
        users: {
            type: Array,
            default: () => []
        },
        totalUsers: {
            type: Number,
            default: 0,
        },
        loadingUsers: {
            type: Boolean,
            default: false,
        },
        locationsMap: {
            type: Object,
            default: () => {}
        },
        shouldUpdateTableOptions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            headers: [
                { text: 'Status', value: 'status', width: '10%' },
                { text: 'Name', value: 'username', width: '25%' },
                { text: 'Last Login', value: 'lastLogin', width: '25%' },
                { text: 'Location', value: 'location', width: '40%', sortable: false }
            ],
            options: {}
        }
    },
    computed: {
        formattedUsers() {
            return this.users.map(user => {
                user.last_login_label = 'N/A'
                if (user.last_login) {
                    user.last_login_label = this.formatLastLogin(user, this.globalTimezone)
                }

                const newLocations = user.locations
                    .filter(location => this.locationsMap.hasOwnProperty(location))
                    .map(location => this.locationsMap[location])
                return {
                    ...user,
                    displayedLocations: newLocations
                }
            })
        },
        globalLocation() {
            return this.$root.globalLocation;
        },
        globalTimezone() {
            return this.$root.globalTimezone;
        },
    },
    methods: {
        onUpdateOptions() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options
            this.$emit('update:options', {
                page,
                itemsPerPage,
                sortBy: this.getSortByOptions(sortBy),
                sortDesc: this.getSortDescOptions(sortDesc)
            })
        },
        getSortByOptions(sortByArray) {
            if(sortByArray.length > 0) {
                const sortBy = sortByArray[0]
                if(sortBy === 'username') {
                    return 'last_name'
                } else if(sortBy === 'lastLogin') {
                    return 'last_login'
                } else if(sortBy === 'location') {
                    return 'locations'
                } else {
                    return sortBy
                }
            }
            return null
        },
        getSortDescOptions(sortDescArray) {
            if(sortDescArray.length > 0) {
                return sortDescArray[0]
            }
            return null
        },
        onUserClick(user) {
            const {
                $user,
                $userHasPermission,
            } = this
            if($userHasPermission($user, this.$config.permissions.USERS.VIEW_OTHERS_PROFILE) || $userHasPermission($user, this.$config.permissions.USERS.VIEW_OTHERS_PERMISSIONS)) {
                this.$emit('user-clicked', user)
            }
        }
    },

    watch: {
        globalLocation() {
            if(this.shouldUpdateTableOptions) {
                const tableOptionsCopy = {...this.options}
                this.options = {
                    ...this.options,
                    page: 1,
                    sortBy: [],
                    sortDesc: []
                }
                if(_.isEqual(tableOptionsCopy, this.options)) {
                    this.onUpdateOptions()
                }
            }
        }
    }
}
</script>

<style scoped>
    #administrators-table :deep(tr:hover) {
        cursor: pointer;
    }

    #administrators-table {
        margin-left: 20px;
        margin-right: 20px;
    }
</style>
