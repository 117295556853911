<template>
    <custom-icon icon="mdi-exclamation" :color="color"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";
export default {
    name: "AlertIcon",
    components: { CustomIcon },
    props: {
        color: {
            type: String,
            default: '#FFFFFF'
        }
    }
}
</script>

<style scoped>

</style>
