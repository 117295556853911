module.exports = {
    methods: {
        /*
        Takes a lists of users that needs to be filtered an an object with searchParameters including name,
        staff group, or job title
        */
        usersFilter(users, {name = null, staff_group = null, job_title = null} = {}) {
            if (!users || !users.length) {
                return []
            }

            let usersLength = users.length
            let filteredUsers = []

            for (let index = 0; index < usersLength; index++) {
                let user = users[index]
                let match = true

                if (match && ((typeof user.jobs === 'undefined' || !user.jobs.length) && (staff_group || job_title))) {
                    // If the user has no jobs, but the filter has staff group or job title, don't bother
                    match = false
                } else {
                    if (match && staff_group) {
                        if (!this.userIsInStaffGroup(user, staff_group, job_title)) {
                            match = false
                        }
                    } else if (match && job_title) {
                        if (!this.userHasJobTitle(user, job_title)) {
                            match = false
                        }
                    }
                }

                if (match && name) {
                    if (!this.userHasName(user, name)) {
                        match = false
                    }
                }

                if (match) {
                    filteredUsers.push(user)
                }
            }

            return filteredUsers
        },

        userHasName(user, name) {
            let firstnameFound = false;
            let lastNameFound = false;
            if (typeof user.first_name !== 'undefined' && user.first_name) {
                firstnameFound = user.first_name.toLowerCase().includes(name.toLowerCase())
            }

            if (typeof user.last_name !== 'undefined' && user.last_name) {
                lastNameFound = user.last_name.toLowerCase().includes(name.toLowerCase())
            }

            return firstnameFound || lastNameFound
        },

        userIsInStaffGroup(user, staff_group_id, job_title_id = null) {
            if (typeof user.jobs === 'undefined') {
                return false
            }
            return user.jobs.find(job => {
                if ((typeof job.staff_group !== 'undefined') && (typeof job.staff_group.id !== 'undefined') && (job.staff_group.id === staff_group_id)) {
                    if (!job_title_id) {
                        return true
                    } else if (typeof job.job_title !== 'undefined' && job.job_title.id === job_title_id) {
                        return true
                    }
                }
            })
        },

        userWorksInLocation(user, location_id) {
            return user.jobs.find(job => {
                if ((typeof job.location !== 'undefined') && (typeof job.location.id !== 'undefined') && (job.location.id === location_id)) {
                    return true
                }
            })
        },

        userHasJobTitle(user, job_title) {
            return user.jobs.find(job => typeof job.job_title !== 'undefined' && job.job_title === job_title)
        },
    }
}
