<template>
    <v-menu open-on-click bottom offset-y>
        <template v-slot:activator="{ on, value }">
            <v-btn
                v-on="on"
                color="secondary"
                text
                class="button-container"
                :loading="loading"
                :disabled="disabled"
            >
                {{ title }}
                <v-icon
                    right
                    dark
                >
                    {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list class="department-dropdown overflow-y-auto">
                <v-list-item-group
                    v-model="selectedId"
                    color="secondary"
                    mandatory
                >
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        :value="item._id"
                        @click="onChange"
                    >
                        <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "MenuOptions",
    props: {
        value: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        selectedId: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
        title () {
            const findItem = this.items.find(item => item._id === this.selectedId)
            if (findItem) {
                return findItem.text
            }
            return ''
        },
    },
    methods: {
        onChange () {
            this.$emit('onChange')
        }
    }
}
</script>

<style scoped>
    .button-container {
        font-size: 18px;
        text-transform: none !important;
    }

    .department-dropdown {
        max-height: 300px !important;
    }
</style>
