<template>
    <div>
        <v-tooltip top v-if="itemsLength > 3">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    {{ truncatedItems }}
                </div>
            </template>
            <span>{{ allItems }}</span>
        </v-tooltip>
        <span v-else>{{ allItems }}</span>
    </div>
</template>

<script>
import { parseArr } from "../../lib/helpers/parsers";

export default {
    name: "UserTooltip",
    props: ['items'],
    computed: {
        allItems() {
            if(Array.isArray(this.items)) {
                return parseArr(this.items, this.items.length + 1)
            }
            return this.items
        },
        truncatedItems() {
            return parseArr(this.items)
        },
        itemsLength() {
            if(Array.isArray(this.items)) {
                return this.items.length
            }
            return 0
        }
    }
}
</script>

<style scoped>

</style>
