<template>
    <v-dialog v-model="showModal" max-width="450px" elevation="10" scrollable persistent>
        <v-card>
            <v-toolbar dark color="primary" elevation="0" height="40">
                <v-toolbar-title class="white--text toolbar-title">Remove Profile Picture</v-toolbar-title>
            </v-toolbar>

            <v-card-title class="headline no-word-break">
                Are you sure you want to remove your profile picture?
            </v-card-title>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12" class="mt-4">
                        <v-btn
                            block
                            color="primary"
                            @click="confirm"
                            :loading="api.busy"
                        >
                            Confirm
                        </v-btn>
                    </v-col>

                    <v-col cols="12" class="mt-4 mb-4">
                        <v-btn
                            block
                            text
                            color="primary"
                            elevation="1"
                            @click="closeModal"
                            :loading="api.busy"
                        >
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    name: "RemoveAvatarModal",

    props: {
        value: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            showModal: false,
            api: new formHelper()
        }
    },

    methods: {
        closeModal() {
            this.showModal = false
        },

        confirm() {
            if (!this.user) {
                return
            }

            this.api.delete('/user/avatar/' + this.user._id)
                .then(({message}) => {
                    this.$emit('avatarRemoved')
                    this.$snackNotify('success','Profile picture removed.')
                    this.closeModal()
                })
        }
    },

    watch: {
        value(newVal) {
            this.showModal = newVal
        },
        showModal(newVal) {
            if (!newVal) {
                this.$emit('input', false)
            }
        }
    }
}
</script>

<style scoped>

</style>
