<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="1300px"
    >
        <staffing-automation-details
            :edit-mode="false"
            :staffing-automation-id="staffingAutomationId"
            :start-date="startDate"
            :run-date="runDate"
            @onClose="onClose"
            @onActivate="onActivate"
        />
    </v-dialog>
</template>

<script>
import StaffingAutomationDetails from "./StaffingAutomationDetails";

export default {
    name: "AddJobTitlesModal",
    components: {StaffingAutomationDetails},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        staffingAutomationId: {
            type: String,
            default: null,
        },
        startDate: {
            type: String,
            default: null
        },
        runDate: {
            type: String,
            default: null,
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onClose() {
            this.modalValue = false
        },
        onActivate() {
            this.modalValue = false
            this.$emit('onActivate')
        }
    }
}
</script>

<style scoped>

</style>
