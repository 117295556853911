<template>
    <summary-view>
        <template v-slot:title-content>
            <template v-if="loading">
                <v-skeleton-loader type="paragraph"></v-skeleton-loader>
            </template>
            <template v-else>
                <div class="shifts-summary-view-title-container">
                    <span class="text--h2">{{ title }}</span>
                    <div class="text-body-1 d-flex flex-row align-center ml-5" style="column-gap: 12px;">
                        <v-btn
                            color="primary"
                            icon
                            elevation="2"
                            :loading="loading"
                            @click="onUpdateLocation">
                            <v-icon>
                                mdi-reload
                            </v-icon>
                        </v-btn>
                        <div class="d-flex flex-column justify-center align-center" v-if="lastUpdated">
                            <span class="text--caption">Last Updated:</span>
                            <span class="text--label">{{ lastUpdated }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <template v-slot:text-content>
            <div class="text--h4">
                Total Shifts
            </div>
            <v-progress-circular
                v-if="loading"
                color="primary"
                indeterminate
                class="mt-2"
            ></v-progress-circular>
            <div v-else class="text--h3 mt-2">
                {{ totalCount }}
            </div>
        </template>
        <template v-slot:doughnut-chart>
            <shifts-doughnut-chart
                :key="doughnutChartKey"
                :percentage="percentage"
                :filled="filledCount"
                :open="openCount"
                :loading="loading"
            />
        </template>
        <template v-slot:bar-chart>
            <dashboard-bar-chart
                :key="barChartKey"
                :data-source="barDataSource"
                :loading="loading"
                style="height: 400px;"
            />
            <div class="shifts-summary-view-loading-container">
                <div class="shifts-summary-view-loading-wrapper">
                    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
                </div>
            </div>
        </template>
    </summary-view>
</template>

<script>
import SummaryView from "./SummaryView";
import ShiftsDoughnutChart from "../../charts/doughnut/ShiftsDoughnutChart";
import DashboardBarChart from "../../charts/bar/DashboardBarChart";
export default {
    name: "ShiftsSummaryView",
    components: { SummaryView, ShiftsDoughnutChart, DashboardBarChart },
    props: {
        location: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false,
        },
        monthly: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        doughnutChartKey: 1111,
        barChartKey: 9999,
    }),
    computed: {
        title() {
            const { location } = this
            return (location || {}).name || ''
        },
        summary() {
            const { location } = this
            return ((location || {}).location_summary || {}).shifts || {}
        },
        filledCount() {
            return this.summary.filled_count || 0
        },
        openCount() {
            return this.totalCount - this.filledCount
        },
        totalCount() {
            return this.summary.total_count || 0
        },
        percentage() {
            return this.summary.ratio || 0
        },
        barDataSource() {
            if(this.loading) {
                return this.getDefaultBarDataSource()
            } else {
                if(this.summary.daily_count) {
                    return this.getBarDataSource(this.summary.daily_count, this.monthly)
                }
                return this.getDefaultBarDataSource()
            }
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        lastUpdated() {
            const { location } = this
            if(location && location.summary_updated_at) {
                if(this.globalTimezone) {
                    return `${moment(location.summary_updated_at).tz(this.globalTimezone).format('MMM DD, LT')}`
                }
                return `${moment(location.summary_updated_at).format('MMM DD, LT')}`
            }
            return ''
        },
    },
    methods: {
        getBarDataSource(dailyCount, monthly) {
            const dates = Object.keys(dailyCount)
            return dates.map((date, index) => {
                const summary = dailyCount[date] || {}
                const percentage = summary.ratio || 0
                const filledCount = summary.filled_count || 0
                const totalCount = summary.total_count || 0
                const openCount = totalCount - filledCount
                const shouldNotBeSkipped = !monthly || index === 0 ||
                    index === dates.length - 1 ||
                    index === (Math.floor(dates.length / 2) - 1)
                return {
                    x: !shouldNotBeSkipped ? '' : date,
                    y: totalCount,
                    percentage,
                    label: [
                        `Filled         ${filledCount}`,
                        `Open         ${openCount}`,
                    ],
                    title: monthly ? `DAY          ${date}` : '',
                    footer: `FILLED     ${percentage}%`
                }
            })
        },
        getDefaultBarDataSource() {
            return [
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
                { x: '', y: 0 },
            ]
        },
        onUpdateLocation() {
            this.$emit('onUpdateLocation', this.location._id)
        }
    },
    watch: {
        loading() {
            this.doughnutChartKey = this.doughnutChartKey + 1
            this.barChartKey = this.barChartKey + 1
        }
    }
}
</script>

<style scoped>
    .shifts-summary-view-loading-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
    .shifts-summary-view-loading-wrapper {
        width: 91%;
    }
    .shifts-summary-view-title-container {
        display: flex;
        flex-direction: row;
    }
</style>
