<template>
    <div class="schedule-container">
        <div class="grouping-view-min-width pl-5 pr-2" :style="thirdGroupingStyle">
            <calendar-details
                v-if="isOpen"
                icon="mdi-clipboard-account"
                :text="groupingNames.third"
            ></calendar-details>
        </div>
        <div class="schedule-row-outter grid">
            <div class="schedule-row-inner days">
                <div
                    v-if="currentDay.exists"
                    class="current-day-gradient"
                    :style="{ left: `${14.25 * currentDay.index}%` }"
                >

                </div>
                <event
                    name="calendar-event"
                    v-for="event in schedule.events"
                    :key="event._id"
                    :event="event"
                    @onEventClick="onEventClick"
                ></event>
            </div>
        </div>
    </div>
</template>

<script>
import DepartmentEvent from './DepartmentEvent'
import CalendarDetails from '../Common/CalendarDetails'
import Event from './Event'

export default {
    name: "DepartmentSchedule",
    components: { CalendarDetails, DepartmentEvent, Event },
    props: {
        schedule: {
            type: Object,
            default: function() {
                return {}
            }
        },
        startOfWeek: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        groupingNames () {
            const { keys, level } = this.schedule
            if(level === 1) {
                return {
                    first: keys[0],
                    second: keys[1],
                    third: keys[2]
                }
            } else if(level === 2) {
                return {
                    first: '',
                    second: keys[1],
                    third: keys[2]
                }
            } else if(level === 3) {
                return {
                    first: '',
                    second: '',
                    third: keys[2]
                }
            } else {
                return {
                    first: '',
                    second: '',
                    third: ''
                }
            }
        },
        isOpen () {
            return this.schedule.type === 'open'
        },
        thirdGroupingStyle () {
            return {
                borderBottom: this.isOpen ? '2px solid #A9A9A9' : ''
            }
        },
        currentDay () {
            let exists = false
            let index = 0
            let dayOfWeek = null
            let dayOfMonth = null
            if (this.startOfWeek) {
                const now = moment().tz(this.globalTimezone)
                const startOfWeek = this.startOfWeek.clone()
                _.range(7).forEach(i => {
                    const isToday = startOfWeek.isSame(now, 'day')
                    if (isToday) {
                        exists = true
                        index = i
                        dayOfWeek = startOfWeek.format('ddd').toUpperCase()
                        dayOfMonth = startOfWeek.format('D')
                    }
                    startOfWeek.add(1, 'days')
                })
            }
            return {
                exists,
                index,
                dayOfWeek,
                dayOfMonth,
            }
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        onEventClick (event) {
            this.$emit('onEventClick', event)
        }
    }
}
</script>

<style scoped>
    .schedule-container {
        display: flex;
        flex-direction: row;
        min-width: 1000px;
    }

    .grouping-view-min-width {
        min-width: 15%;
        max-width: 15%;
        min-height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .schedule-row-outter {
        min-width: auto;
        min-height: 70px;
        border-bottom: 2px solid #A9A9A9;
        width: 100%;
    }

    .grid {
        background-image: repeating-linear-gradient(
            90deg,
            #C0C0C0 0px,
            #C0C0C0 1px,
            transparent 1px 100%
        );
        background-repeat: repeat;
        background-size: 3.565% 71px;
    }

    .schedule-row-inner {
        height: 100%;
        width: 100%;
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .days {
        background-image: repeating-linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            transparent 1px 100%
        );
        background-repeat: repeat;
        background-size: 14.25% 71px;
    }

    .current-day-gradient {
        position: absolute;
        background-image: linear-gradient(
            90deg,
            #A9A9A9 0px,
            #A9A9A9 2px,
            rgba(240, 240, 240, 0.6) 1px 100%
        );
        width: 14.25%;
        height: 100%;
    }
</style>
