<template>
    <div class="location-modal">
        <v-dialog
            v-model="show_modal"
            scrollable
            max-width="800px"
            persistent
            content-class="ds-dialog"
        >
            <v-card class="ds-modal-card" elevation="2" dusk="locationModal">
                <modal-toolbar :title="editing_existing_location ? 'Edit Location' : 'Add Location'" @close="onClose" />

                <v-card-text class="ds-modal-content">
                    <v-form
                        ref="locationForm"
                        v-model="form_is_valid"
                        :lazy-validation="false"
                        class="pt-2"
                    >
                        <v-row>

                            <div v-show="show_loading_overlay" style="height:100%">
                                <loading-spinner :value="true"></loading-spinner>
                            </div>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="location-name"
                                    label="Location Name"
                                    v-model.trim="location.name"
                                    :error-messages="api.hasError('name')"
                                    :rules="rules.required"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="primary-address"
                                    label="Address (Line 1)"
                                    v-model.trim="location.primary_address"
                                    :error-messages="api.hasError('primary_address')"
                                    :rules="rules.required"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-text-field
                                    name="secondary-address"
                                    label="Address (Line 2)"
                                    v-model.trim="location.secondary_address"
                                    :error-messages="api.hasError('secondary_address')"
                                    :readonly="!editable"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="4" class="ds-modal-input-container">
                                <v-text-field
                                    name="city"
                                    label="City"
                                    v-model.trim="location.city"
                                    :error-messages="api.hasError('city')"
                                    :readonly="!editable"
                                    :rules="rules.required"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="4" class="ds-modal-input-container">
                                <v-autocomplete
                                    name="state"
                                    dusk="stateDropdown"
                                    label="State"
                                    v-model="location.state"
                                    :error-messages="api.hasError('state')"
                                    :items="states"
                                    :readonly="!editable"
                                    :rules="rules.required"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="4" class="ds-modal-input-container">
                                <v-text-field
                                    name="postal"
                                    label="ZIP Code"
                                    v-model.trim="location.zip_code"
                                    :error-messages="api.hasError('zip_code')"
                                    :readonly="!editable"
                                    :rules="rules.required"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                            <v-col cols="12" class="ds-modal-input-container">
                                <v-autocomplete
                                    name="country"
                                    dusk="countryDropdown"
                                    label="Country"
                                    v-model="location.country"
                                    :error-messages="api.hasError('country')"
                                    :items="countries"
                                    :readonly="!editable"
                                    :rules="rules.required"
                                    autocomplete="off"
                                    outlined
                                />
                            </v-col>

                        </v-row>

                        <v-row class="mt-0">
                            <v-spacer/>
                            <v-col class="text-right">
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="save"
                                    :loading="api.busy"
                                    :disabled="isSaveDisabled"
                                >
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </div>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";
import LoadingSpinner from "../../../components/loaders/LoadingSpinner";
import ModalToolbar from "../../../components/modals/components/ModalToolbar";

export default {
    name: "LocationModal",
    components: {ModalToolbar, LoadingSpinner, UnsavedChangesDialog},
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        const user_can_edit = this.$userHasPermission(this.$user, this.$config.permissions.LOCATIONS.EDIT)

        return {
            resolve: null,
            reject: null,
            show_modal: this.value,
            api: new formHelper(),
            editing_existing_location: false,
            show_loading_overlay: false,
            form_is_valid: false,
            location: {},
            prevLocation: {},
            editable: user_can_edit,
            states: this.$config.states,
            countries: this.$config.countries,
        }
    },
    methods: {
        open(location_id = null) {
            if (location_id) {
                this.editing_existing_location = true
                this.loadExistingLocation(location_id)
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
                this.show_modal = true
            })
        },

        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },

        closeModal() {
            this.show_modal = false
            this.resetModal()
        },

        resetModal() {
            this.editing_existing_location = false
            this.form_is_valid = false
            this.location = {}
            this.prevLocation = {}
            this.$refs.locationForm.resetValidation()
        },

        locationCreated(location) {
            this.$snackNotify('success', 'New Location created.')
            this.resolve({action: 'created', location: location})
            this.closeModal()
        },

        locationUpdated(data) {
            this.$snackNotify('success', 'Changes to Location saved.' )
            this.resolve({action: 'updated'})
            this.closeModal()
        },

        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {})
        },

        save() {
            if (!this.$refs.locationForm.validate()) {
                return false
            }

            this.api.post(
                this.editing_existing_location ? '/locations/update' : '/locations/create',
                this.location
            )
                .then(({data}) => {
                    if (this.editing_existing_location) {
                        this.locationUpdated(data)
                    } else {
                       this.locationCreated(data)
                    }
                })
        },

        loadExistingLocation(location_id) {
            this.show_loading_overlay = true
            this.api.get('/locations/' + location_id)
                .then(({data}) => {
                    this.location = data
                    this.prevLocation = {...data}
                })
                .finally(() => {
                    this.show_loading_overlay = false
                })
        },

        getCompareObject(location_object) {
            const { name, primary_address, secondary_address, city, state, zip_code, country } = location_object

            return {
                name,
                primary_address,
                secondary_address,
                city,
                state,
                zip_code,
                country
            }
        },
    },
    computed: {
        isDirty() {
            return !_.isEqual(this.getCompareObject(this.prevLocation), this.getCompareObject(this.location))
        },
        isSaveDisabled() {
            return this.api.busy || !this.editable || !this.isDirty
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.show_modal = newVal
            }
        },

        show_modal: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.$emit('input', false)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
