<template>
    <v-col class="d-flex align-center event-row">
        <v-icon class="black--text mr-5">{{ icon }}</v-icon>
        <span :dusk="duskSelector" :class="classObj" :style="styleObj">{{ text }}</span>
    </v-col>
</template>

<script>
export default {
    name: "EventDetailsRow",
    props: {
        icon: {
            type: String,
            default: 'mdi-account'
        },
        text: {
            type: String,
            default: 'None'
        },
        fontSize: {
            type: Number,
            default: 24
        },
        bold: {
            type: Boolean,
            default: false
        },
        duskSelector: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            classObj: {
                'black--text': true,
                'font-weight-bold': this.bold,
            },
            styleObj: {
                fontSize: `${this.fontSize}px`
            }
        }
    }
}
</script>

<style scoped>
    .event-row {
        padding-top: 6px;
        padding-bottom: 6px;
    }
</style>
