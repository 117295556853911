<template>
    <div>
        <v-navigation-drawer
            id="koroid-navigation-drawer"
            fixed
            permanent
            clipped
            floating
            v-model="showSidebar"
            width="300"
            :mini-variant="mini"
            mini-variant-width="80"
        >
            <v-list id="nav-list" dense nav class="pa-0">

                <div class="nav-top-container">
                    <div>
                        <v-list-item id="nav-hamburger-item" class="nav-sidebar-list-item">
                            <v-list-item-icon :title="mini ? 'Expand Menu' : 'Collapse Menu'">
                                <v-btn
                                    id="nav-hamburger-btn"
                                    @click.stop="mini = !mini"
                                    large
                                    icon
                                    class="nav-sidebar-icon"
                                >
                                    <v-icon color="#000000">mdi-menu</v-icon>
                                </v-btn>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <div id="nav-username" class="nav-text text-right">{{ user.first_name }} {{ user.last_name }}</div>
                            </v-list-item-content>
                        </v-list-item>
                    </div>

                    <div v-for="item in permittedItems" :key="item.url">
                        <v-divider v-if="item.label === 'Organization'"></v-divider>

                        <v-list-item
                            class="nav-sidebar-list-item"
                            :class="{'nav-item--active': isMenuItemActive(item.url)}"
                            link
                            :href="item.url"
                        >
                            <v-list-item-icon :title="item.label">
                                <v-btn
                                    large
                                    icon
                                    class="nav-sidebar-icon"
                                >
                                    <v-icon size="24" color="#000000">{{ getIcon(item) }}</v-icon>
                                </v-btn>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <div class="nav-text">{{ item.label }}</div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider v-if="item.label === 'Organization'"></v-divider>
                    </div>
                </div>

                <div class="nav-bottom-container">
                    <div>
                        <v-list-item
                            id="nav-support-item"
                            class="nav-sidebar-list-item"
                            link
                            target="_blank"
                            :href=supportUrl
                        >
                            <v-list-item-icon>
                                <v-btn
                                    id="nav-support-btn"
                                    large
                                    icon
                                    :ripple="false"
                                    class="nav-sidebar-icon"
                                >
                                    <v-icon color="#000000">mdi-help-circle-outline</v-icon>
                                </v-btn>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <div class="nav-text">Support <v-icon class="ml-2" size="16" color="#000000">mdi-open-in-new</v-icon></div>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </div>
            </v-list>
        </v-navigation-drawer>
        <v-overlay :value="!mini" color="black" opacity="0.2" absolute z-index="20" @click="mini = true"></v-overlay>
    </div>
</template>

<script>
    import CustomAvatar from "../avatar/CustomAvatar";

    export default {
        name: "NavSidebar",
        components: {
            CustomAvatar
        },
        props: {
          value: {
              type: Boolean,
              default: false
          }
        },
        data() {
            const PERMISSIONS = {...this.$config.permissions}
            const PATH = window.location.pathname.split("/")[1]

            return {
                user: this.$user,
                showSidebar: this.value,
                path: PATH,
                mini: true,
                listItems: [
                    {
                        label: 'Dashboard',
                        icon: 'mdi-view-dashboard-outline',
                        active_icon: 'mdi-view-dashboard',
                        url: '/dashboard',
                        requiredPermissions: [],
                        explicitPermissions: false
                    },
                    {
                        label: 'Schedules',
                        icon: 'mdi-calendar-month-outline',
                        active_icon: 'mdi-calendar-month',
                        url: '/schedules',
                        requiredPermissions: [PERMISSIONS.USERS.VIEW_SCHEDULES_PAGE],
                        explicitPermissions: false
                    },
                    {
                        label: 'Automated Shifts',
                        icon: 'mdi-update',
                        active_icon: 'mdi-update',
                        url: '/staffing-automations',
                        requiredPermissions: [PERMISSIONS.STAFFING_AUTOMATION.VIEW],
                        explicitPermissions: false
                    },
                    {
                        label: 'Reports',
                        icon: 'mdi-chart-box-outline',
                        active_icon: 'mdi-chart-box',
                        url: '/reports',
                        requiredPermissions: [PERMISSIONS.REPORTS.VIEW],
                        explicitPermissions: false
                    },
                    {
                        label: 'Users',
                        icon: 'mdi-account-multiple-outline',
                        active_icon: 'mdi-account-multiple',
                        url: '/users',
                        requiredPermissions: [PERMISSIONS.USERS.VIEW_USERS_PAGE],
                        explicitPermissions: false
                    },
                    {
                        label: 'Organization',
                        icon: 'mdi-file-tree-outline',
                        active_icon: 'mdi-file-tree',
                        url: '/organization',
                        requiredPermissions: [PERMISSIONS.ORGANIZATION.VIEW],
                        explicitPermissions: false
                    },
                    {
                        label: 'Shifts',
                        icon: 'mdi-calendar-account-outline',
                        active_icon: 'mdi-calendar-account',
                        url: '/shifts',
                        requiredPermissions: [PERMISSIONS.USERS.VIEW_SHIFTS_PAGE],
                        explicitPermissions: true
                    },
                    {
                        label: 'Profile',
                        icon: 'mdi-account-outline',
                        active_icon: 'mdi-account',
                        url: '/profile',
                        requiredPermissions: [],
                        explicitPermissions: false
                    },
                ]
            }
        },
        methods: {
            userHasPermission(requiredPermissions, explicit) {
                if (!requiredPermissions.length) {
                    return true;
                }

                return this.$userHasPermissions(this.user, requiredPermissions, explicit)
            },

            isMenuItemActive(item_path) {
                item_path = item_path.split("/")[1]

                return item_path === this.path
            },

            getIcon(item) {
                const isActive = this.isMenuItemActive(item.url)

                if (!isActive || !item.active_icon) {
                    return item.icon
                }

                return item.active_icon
            }
        },
        computed: {
          permittedItems() {
              return this.listItems.filter(item => this.userHasPermission(item.requiredPermissions, item.explicitPermissions))
          },
          supportUrl() {
            return this.$root.websiteUrl + '/support';
          }
        },
        watch: {
            value() {
                this.showSidebar = this.value
            },
            showSidebar(newVal) {
                this.$emit('input', newVal)
            }
        }
    }
</script>

<style scoped>
    .v-navigation-drawer {
        z-index: 21 !important;
        top: 64px !important;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 12px;
        height: calc(100vh - 64px) !important;
    }

    #nav-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .nav-sidebar-list-item {
        height: 56px;
        border-radius: 30px;
        margin: 5px 0;
    }

    .nav-sidebar-list-item .v-list-item__icon:first-child {
        margin-right: 0 !important;
    }

    .nav-sidebar-list-item:hover:not(#nav-hamburger-item) {
        background-color: #02658f14;
    }

    .nav-sidebar-list-item.nav-item--active, .nav-sidebar-list-item.nav-item--active:hover {
        background-color: #C8E6FF !important;
    }

    .nav-sidebar-list-item:hover.v-list-item--link:before {
        background-color: unset !important;
    }

    .nav-sidebar-list-item:not(#nav-hamburger-item) .v-btn:before {
        background-color: unset !important;
    }

    #nav-hamburger-item .nav-sidebar-list-item .v-btn:before {
        background-color: #C8E6FF !important;
    }

    .nav-sidebar-list-item :deep(.v-list-item__icon) {
        height: 56px !important;
        width: 56px !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
    }

    .nav-text {
        color: #46464e;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        padding: 0 12px;
    }

    .nav-item--active .nav-text {
        color: #000000;
    }

    #nav-hamburger-item {
        padding-left: 0;
    }

    #nav-hamburger-item .v-list-item__icon {
        height: 56px !important;
        margin: 0 !important;
    }

    #nav-hamburger-btn {
        width: 56px !important;
        height: 56px !important;
    }

    #nav-username {
        cursor: default;
        font-size: 16px;
    }
</style>
