import { render, staticRenderFns } from "./ReportDetailsTableHeader.vue?vue&type=template&id=1335cb44&scoped=true"
import script from "./ReportDetailsTableHeader.vue?vue&type=script&lang=js"
export * from "./ReportDetailsTableHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1335cb44",
  null
  
)

export default component.exports