<template>
    <v-row class="ml-8" no-gutters>
        <v-row no-gutters>
            <v-col cols="8">
                <v-dialog
                    ref="startDatePicker"
                    v-model="startDatePickerModal"
                    :return-value.sync="start_date"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="formatted_start_date"
                            label="Date"
                            prepend-inner-icon="mdi-calendar-blank"
                            readonly
                            class="pt-0"
                            :rules="rules.required"
                            :error-messages="api.hasError('start')"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-if="startDatePickerModal"
                        v-model="start_date"
                        full-width
                        locale="en-US"
                        color="secondary"
                        :allowed-dates="allowedDates"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="startDatePickerModal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.startDatePicker.save(start_date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="4">
                <v-dialog
                    ref="startTimePicker"
                    v-model="startTimePickerModal"
                    :return-value.sync="start_time"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="formatted_start_time"
                            :disabled="!start_date"
                            :rules="rules.required"
                            :error-messages="api.hasError('start_time')"
                            class="pt-0"
                            label="Start Time"
                            prepend-icon="access_time"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="startTimePickerModal"
                        v-model="start_time"
                        full-width
                        ampm-in-title
                        scrollable
                        color="primary"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="startTimePickerModal = false">Cancel
                        </v-btn>
                        <v-btn text color="primary"
                               @click="$refs.startTimePicker.save(start_time)">
                            OK
                        </v-btn>
                    </v-time-picker>
                </v-dialog>
            </v-col>

            <v-col cols="4">
                <v-dialog
                    ref="endTimePicker"
                    v-model="endTimePickerModal"
                    :return-value.sync="end_time"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="formatted_end_time"
                            :disabled="!start_date || !start_time"
                            :rules="rules.required"
                            :error-messages="api.hasError('end_time')"
                            :hint="end_time_hint"
                            persistent-hint
                            class="pt-0 ml-2"
                            label="End Time"
                            prepend-icon="mdi-minus"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="endTimePickerModal"
                        v-model="end_time"
                        full-width
                        ampm-in-title
                        scrollable
                        color="primary"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="endTimePickerModal = false">Cancel
                        </v-btn>
                        <v-btn text color="primary"
                               @click="$refs.endTimePicker.save(end_time)">
                            OK
                        </v-btn>
                    </v-time-picker>
                </v-dialog>
            </v-col>

            <v-col cols="3" class="text-right pt-1">
                <v-btn
                    icon
                    @click="saveEdits"
                    :disabled="!is_save_button_enabled"
                >
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>

                <v-btn
                    icon
                    @click="cancelEdit"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <p v-show="hasShiftTimeDiffError" class="ma-0 red--text text--darken-2">Start and end times can be moved by a maximum of 6 hours</p>
                <p v-if="api.hasError('shift_time')" class="ma-0 red--text text--darken-2">{{ api.errors['shift_time'][0] }}</p>
            </v-col>
        </v-row>

    </v-row>
</template>

<script>
import moment from "moment-timezone";
import validationRules from '../../../lib/mixins/validationRules'

export default {
    name: "EditableShiftTimeSection",
    mixins: [validationRules],
    props: {
        shift: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            api: new formHelper(),
            startDatePickerModal: false,
            startTimePickerModal: false,
            endTimePickerModal: false,

            start_date: null,
            start_time: null,
            end_time: null,

            hasShiftTimeDiffError: false,
        }
    },

    methods: {
        setProperties() {
            this.hasShiftTimeDiffError = false
            if (this.shift) {
                this.start_date = this.original_start_date.format('YYYY-MM-DD')
                this.start_time = this.original_start_date.format('HH:mm')
                this.end_time = this.original_end_date.format('HH:mm')
            } else {
                this.unsetProperties()
            }
        },

        unsetProperties() {
            this.start_date = null
            this.start_time = null
            this.end_time = null
        },

        allowedDates(val) {
            return this.allowed_start_dates.indexOf(val) !== -1
        },

        saveEdits() {
            if (!this.new_start_date || !this.new_end_date) {
                return
            }

            let start_date_diff = Math.abs(this.new_start_date.diff(this.shift_request_start_date, 'hours'))
            let end_date_diff = Math.abs(this.new_end_date.diff(this.shift_request_end_date, 'hours'))

            if ((start_date_diff > 6) || (end_date_diff > 6)) {
                this.hasShiftTimeDiffError = true
                return
            }

            const params = {
                start: this.new_start_date.format('YYYY-MM-DD HH:mm'),
                end: this.new_end_date.format('YYYY-MM-DD HH:mm'),
                timezone: this.globalTimezone
            }

            window.EventBus.$emit('shift-details-loading-status-change', true)

            this.api.post('/shifts/update-time/' + this.shift._id, params)
                .then(({data}) => {
                    window.EventBus.$emit('shift-time-updated', data)
                    this.cancelEdit()
                })
                .finally(() => {
                   window.EventBus.$emit('shift-details-loading-status-change', false)
                })
        },

        cancelEdit() {
            this.unsetProperties()
            this.api.clearErrors()
            this.$emit('editCancelled')
        }
    },

    computed: {
        globalTimezone() {
            return this.$root.globalTimezone
        },

        shift_request() {
            if (!this.shift || typeof this.shift.shift_request === 'undefined') {
                return null
            }

            return this.shift.shift_request
        },

        shift_request_start_date() {
            return this.shift_request ? moment(this.shift_request.start).tz(this.globalTimezone) : null
        },

        shift_request_end_date() {
            return this.shift_request ? moment(this.shift_request.end).tz(this.globalTimezone) : null
        },

        original_start_date() {
            return this.shift ? moment(this.shift.start).tz(this.globalTimezone) : null
        },

        original_end_date() {
            return this.shift ? moment(this.shift.end).tz(this.globalTimezone) : null
        },

        new_start_date() {
            if (!this.start_date || !this.start_time) {
                return null
            }

            let start_date = moment.tz(this.start_date, this.globalTimezone).format('YYYY-MM-DD')

            return moment.tz(`${start_date} ${this.start_time}`, this.globalTimezone)
        },

        new_end_date() {
            if (!this.new_start_date || !this.end_time) {
                return null
            }

            let date = this.new_start_date.format('YYYY-MM-DD')

            let end_date = moment.tz(`${date} ${this.end_time}`, this.globalTimezone)

            if (end_date.isBefore(this.new_start_date)) {
                end_date.add(1, 'day')
            }

            return end_date
        },

        formatted_start_date() {
            return this.start_date ? moment(this.start_date).format('MM/DD/YYYY') : null
        },

        formatted_start_time() {
            return this.start_time ? moment(moment().format('YYYY-MM-DD') + ' ' + this.start_time).format('h:mm A') : null
        },

        formatted_end_time() {
            return this.end_time ? moment(moment().format('YYYY-MM-DD') + ' ' + this.end_time).format('h:mm A') : null
        },

        end_time_hint() {
            if (!this.new_end_date || !this.new_start_date) {
                return ''
            }

            const start = moment(this.new_start_date.format('YYYY-MM-DD'))
            const end = moment(this.new_end_date.format('YYYY-MM-DD'))

            let diff_in_days = Math.abs(end.diff(start, 'days'))

            if (diff_in_days) {
                return '+ ' + diff_in_days + ' ' + (diff_in_days === 1 ? 'Day' : 'Days')
            }

            return ''
        },

        allowed_start_dates() {
            if (!this.shift_request) {
                return []
            }

            let allowed_dates = []

            let original_start_date = this.shift_request_start_date
            allowed_dates.push(original_start_date.format('YYYY-MM-DD'))

            let date_minus_threshold = original_start_date.clone().subtract(6, 'hours')
            if (date_minus_threshold.format('DD') !== original_start_date.format('DD')) {
                allowed_dates.push(date_minus_threshold.format('YYYY-MM-DD'))
            }

            let date_plus_threshold = original_start_date.clone().add(6, 'hours')
            if (date_plus_threshold !== original_start_date.format('DD')) {
                allowed_dates.push(date_plus_threshold.format('YYYY-MM-DD'))
            }

            return allowed_dates.filter(function (value, index, self) {
                return self.indexOf(value) === index
            })
        },

        is_save_button_enabled() {
            if (!this.new_start_date || !this.new_end_date || !this.original_start_date || !this.original_end_date) {
                return false
            }

            let start_changed = this.original_start_date.format("YYYY-MM-DD HH:mm") !== this.new_start_date.format("YYYY-MM-DD HH:mm")
            let end_changed = this.original_end_date.format("YYYY-MM-DD HH:mm") !== this.new_end_date.format("YYYY-MM-DD HH:mm")

            return start_changed || end_changed
        },
    },
}
</script>

<style scoped>

</style>
