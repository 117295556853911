<template>
    <v-banner
        v-model="show"
        sticky
        transition="slide-y-transition"
        id="mobile-notice-banner"
        class="elevation-1"
    >
        <div class="notice-text text-h6">
            Try the mobile app today and have Koroid right where you need it.
        </div>

        <div class="notice-icons text-center">
            <v-btn
                v-if="isAndroidDevice()"
                :href="android_link"
                target="_blank"
                outlined
                x-large
                color="success"
                class="notice-btn"
            >
                <v-icon x-large>mdi-android</v-icon>
            </v-btn>

            <v-btn
                v-if="isAppleDevice()"
                :href="ios_link"
                target="_blank"
                outlined
                x-large
                class="notice-btn"
            >
                <v-icon x-large>mdi-apple</v-icon>
            </v-btn>
        </div>
        <template v-slot:actions="{ dismiss }">
            <v-btn
                outlined
                small
                block
                color="primary"
                @click="dismiss"
            >Continue using web version</v-btn>
        </template>
    </v-banner>
</template>

<script>
export default {
    name: "MobileAppNotice",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        device: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            android_link: 'https://play.google.com/store/apps/details?id=com.koroid',
            ios_link: 'https://apps.apple.com/us/app/koroid/id1409304397'
        }
    },
    methods: {
        isAndroidDevice() {
            return this.device === 'android'
        },

        isAppleDevice() {
            return this.device === 'ios'
        }
    },
    watch: {
        value(show) {
            this.show = show
        },
        show(value) {
            if (!value) {
                this.$emit('input', false)
            }
        }
    }
}
</script>

<style scoped>
    #mobile-notice-banner {
        z-index: 999 !important;
        background-color: white;
        padding: 20px 25px;
        position: fixed !important;
    }

    #mobile-notice-banner :deep(.v-banner__wrapper) {
        border-bottom: 0;
    }

    #mobile-notice-banner .notice-text {
        text-align: justify;
        text-justify: inter-word;
    }

    #mobile-notice-banner .notice-icons {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #mobile-notice-banner .notice-btn {
        height: 90px;
    }
</style>
