<template>
    <v-dialog
        v-model="modalValue"
        scrollable width="500px"
        content-class="ds-dialog"
        persistent
    >
        <v-card class="ds-modal-card">
            <v-toolbar class="warning-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Changing Employment Type
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="body-1 ds-modal-content">
                <p class="black--text">
                    Changing the Employment Type while this user is assigned to active shifts may lead to conflicts with
                    their Employment Limits, Scheduling Limits, and Working Hours.
                </p>
                <p class="black--text">
                    The updated Employment Type will take effect immediately.
                </p>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn class="mr-2" text color="primary" @click="onCancel" :disabled="isSavingEmploymentType">
                            Cancel
                        </v-btn>
                        <v-btn class="ml-2" color="primary" outlined @click="onSave" :loading="isSavingEmploymentType">
                            Continue
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "EmploymentTypeInUseModal",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: false,
        },
        employmentTypeId: {
            type: String,
            default: false,
        }
    },
    data() {
        return {
            isSavingEmploymentType: false,
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCancel() {
            this.modalValue = null
        },
        onSave() {
            this.isSavingEmploymentType = true
            this.api.post(`/user/save-staff-type/${this.userId}`, {
                staff_type_id: this.employmentTypeId,
            })
                .then(() => {
                    this.$snackNotify('success', 'Employment updated')
                    this.$emit('onSave')
                })
                .catch(() => {
                    this.$snackNotify('warning', 'Unable to save changes to employment type. Please try again.')
                })
                .finally(() => {
                    this.isSavingEmploymentType = false
                })
        }
    }
}
</script>

<style scoped>

</style>
