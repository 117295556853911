<template>
    <v-text-field
        type="number"
        hide-spin-buttons

        :name="name"
        :label="label"
        :hint="hint"
        :persistent-hint="!!hint"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        :readonly="readonly"
        :disabled="disabled"
        :rules="rules"
        :loading="loading"

        autocomplete="off"
        v-model.number="number"

        @change="changeEvent"
        @input="inputEvent"
        :outlined="outlined"

        class="koroid-number-input"
        :class="{'koroid-number-input-with-controls': controls}"
    >
        <template v-if="!readonly && !disabled" v-slot:append>
            <v-icon
                v-if="clearable"
                class="number-input-control number-input-clear-icon"
                :class="{'visible-clear-icon': number !== null}"
                v-ripple
                @click="clear"
            >
                mdi-close
            </v-icon>
            <v-icon
                v-if="controls"
                class="number-input-control"
                v-ripple
                @click="decrement"
            >
                mdi-minus
            </v-icon>
            <v-icon
                v-if="controls"
                class="number-input-control"
                v-ripple
                @click="increment"
            >
                mdi-plus
            </v-icon>
        </template>
    </v-text-field>
</template>

<script>

export default {
    name: "NumberInput",
    props: {
        value: {
            type: Number,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        hint: {
            type: String,
            default: null
        },
        errorMessages: {
            type: [String, Array],
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        incrementInterval: {
            type: Number,
            default: 1
        },
        decrementInterval: {
            type: Number,
            default: 1
        },
        required: {
            type: Boolean,
            default: false
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: null
        },
        outlined: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            number: this.value,
        }
    },

    computed: {
        rules() {
            if (this.disabled) {
                return []
            }

            let rules = []

            if (this.required) {
                rules.push(v => (!!v || v === 0) || 'Required')
            }

            if (this.min > 0) {
                rules.push(() => this.number >= this.min || this.min_number_hint)
            }

            if (this.max) {
                rules.push(() => this.number <= this.max || this.max_number_hint)
            }

            return rules
        },

        min_number_hint() {
            return "Must be at least " + this.min
        },

        max_number_hint() {
            return "Can not exceed " + this.max
        }
    },

    methods: {
        increment() {
            if (this.max) {
                this.number = this.number + this.incrementInterval <= this.max ?
                    this.number + this.incrementInterval : this.max
            } else {
                this.number = this.number + this.incrementInterval
            }

            this.inputEvent()
        },

        decrement() {
            this.number = this.number - this.decrementInterval >= this.min ?
                this.number - this.decrementInterval : this.min

            this.inputEvent()
        },

        clear() {
            this.number = null
            this.inputEvent()
        },

        changeEvent() {
            if (this.number === "") {
                this.number = null
            }
            this.$emit('change', this.number)
        },

        inputEvent() {
            if (this.number === "") {
                this.number = null
            }
            this.$emit('fieldInput', this.number)
        },
    },

    watch: {
        number() {
            this.$emit('input', this.number)
        },
        value(newValue) {
            if (newValue === "" ) {
                this.number = null
                return
            }

            this.number = newValue
        }
    },
}
</script>

<style scoped>
    .koroid-number-input .number-input-control {
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-number-input:hover .number-input-control {
        visibility: visible;
        opacity: 1;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-number-input.v-input.v-input--is-focused .number-input-control {
        visibility: visible;
        color: #0D0A32;
        opacity: 1;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-number-input .number-input-clear-icon {
        opacity: 0 !important;
        pointer-events: none;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-number-input .number-input-clear-icon.visible-clear-icon {
        opacity: 1 !important;
        pointer-events: auto;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-number-input-with-controls >>> .v-input__control .v-label {
        max-width: calc(100% + 50px);
    }

    .koroid-number-input-with-controls:hover >>> .v-input__control .v-label {
        max-width: 100%;
    }

    .koroid-number-input-with-controls >>> .v-input__control .v-label--active {
        max-width: calc(150% + 50px) !important;
    }
</style>
