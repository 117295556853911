<template>
    <v-row>
        <v-col class="ds-modal-input-container">
            <v-autocomplete
                :value="jobTitle._id"
                :items="availableJobTitles"
                :disabled="isSaving || isDeleting"
                :readonly="!canEdit"
                item-value="_id"
                item-text="title"
                label="Job Title"
                no-data-text="No Job Titles in Staff Group"
                outlined
                return-object
                @change="onChangeJobTitle"
            />
        </v-col>
        <v-col class="ds-modal-input-container">
            <v-text-field
                :value="getTimeStringFromMinutes(jobTitle.max_shift_duration)"
                label="Max Shift Duration"
                disabled
                hint="HH:MM"
                persistent-hint
                outlined
                class="job-title-max-shift-duration"
            />
        </v-col>
        <v-col cols="auto" class="action-button-col">
            <div class="action-button-container">
                <v-btn
                    small
                    icon
                    color="primary"
                    class="action-button"
                    :loading="isDeleting"
                    :disabled="isSaving || !canEdit"
                    @click="onClickDeleteJobTitle"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </div>
        </v-col>
        <v-col cols="auto" class="action-button-col">
            <div class="action-button-container">
                <v-slide-y-reverse-transition>
                    <v-btn
                        v-if="isSaveEnabled"
                        icon
                        class="action-button"
                        :loading="isSaving"
                        :disabled="isDeleting || !canEdit"
                        @click="onSave"
                    >
                        <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                </v-slide-y-reverse-transition>
            </div>
        </v-col>
        <job-title-in-use-modal v-model="isJobTitleInUseModalOpen"/>
        <delete-job-title-modal
            v-model="jobTitleToBeDeleted"
            :user-id="userId"
            :job-id="jobTitle.prevJobId"
            @onDelete="onDeleteJobTitle"
        />
    </v-row>
</template>

<script>
import JobTitleInUseModal from "./JobTitleInUseModal";
import DeleteJobTitleModal from "./DeleteJobTitleModal";

export default {
    name: "JobTitle",
    components: {JobTitleInUseModal, DeleteJobTitleModal},
    props: {
        userId: {
            type: String,
            default: null,
        },
        jobTitle: {
            type: Object,
            default: () => ({})
        },
        availableJobTitles: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        isJobTitleInUseModalOpen: false,
        jobTitleToBeDeleted: null,
        isDeleting: false,
        isSaving: false,
        api: new formHelper(),
    }),
    computed: {
        isSaveEnabled() {
            return this.jobTitle.prevId !== this.jobTitle._id
        },
        isSavedInDb() {
            return this.jobTitle.isSavedInDb
        },
        canEdit() {
            return this.$userHasPermission(this.$user, this.$config.permissions.USERS.EDIT_OTHERS_JOBS)
        }
    },
    methods: {
        onChangeJobTitle(jobTitle) {
            this.$emit('onChangeJobTitle', jobTitle, this.jobTitle._id)
        },
        getTimeStringFromMinutes(minutes) {
            if (typeof minutes === 'number') {
                return this.$options.filters.timeStringFromMinutes(minutes)
            }
            return null
        },
        onClickDeleteJobTitle() {
            if (this.isSavedInDb) {
                const [locationId, staffGroupId, jobTitleId] = this.jobTitle.prevId.split('|')
                this.isDeleting = true
                this.api.post('/shifts/exists', {
                    location_id: locationId,
                    staff_group_id: staffGroupId,
                    job_title_id: jobTitleId,
                    employee_id: this.userId,
                })
                    .then(({data}) => {
                        if (data) {
                            this.isJobTitleInUseModalOpen = true
                        } else {
                            this.jobTitleToBeDeleted = this.jobTitle
                        }
                    })
                    .catch(() => {
                        this.$snackNotify('warning', 'Unable to delete this job. Please try again.')
                    })
                    .finally(() => {
                        this.isDeleting = false
                    })
            } else {
                this.jobTitleToBeDeleted = this.jobTitle
            }
        },
        onDeleteJobTitle(jobId, prevJobId, jobs) {
            this.$emit('onDeleteJobTitle', jobId, prevJobId, jobs)
        },
        onSave() {
            this.isSaving = true
            const {isSavedInDb} = this
            const [locationId, staffGroupId, jobTitleId] = this.jobTitle.job_id.split('|')
            this.api.post(`/user/save-job/${this.userId}`, {
                ...isSavedInDb && {job_id: this.jobTitle.prevJobId},
                location_id: locationId,
                staff_group_id: staffGroupId,
                job_title_id: jobTitleId,
            }).then(({data}) => {
                this.$snackNotify('success', isSavedInDb ? 'Job Updated' : 'Job Created')
                this.$emit('onSaveJobTitle', this.jobTitle.job_id, this.jobTitle.prevJobId, data.user_jobs)
            }).finally(() => {
                this.isSaving = false
            })
        }
    }
}
</script>

<style scoped>
.job-title-max-shift-duration {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.action-button-col {
    padding: 0 !important;
}

.action-button-container {
    width: 40px;
}

.action-button {
    margin-top: 8px;
}
</style>
