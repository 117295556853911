<template>
    <v-container fluid style="padding: 0;">
        <div class="staff-group-filters-view-container pt-5">
            <div class="min-width">
                <v-autocomplete
                    style="width: 100%;"
                    v-model="selectedStaffGroupId"
                    label="Staff Group"
                    item-value="_id"
                    item-text="text"
                    :items="staffGroups"
                    :loading="staffGroupsLoading"
                    :disabled="eventsLoading"
                    :search-input.sync="searchInput"
                    @change="onChangeStaffGroup"
                    outlined
                >
                    <template v-slot:prepend-inner>
                        <v-icon color="primary">mdi-account-group</v-icon>
                    </template>
                </v-autocomplete>
            </div>
            <div class="pb-10">
                <staff-group-filters
                    :staff-group-id="selectedStaffGroupId"
                    :disabled="loading || isSchedulerDisabled"
                ></staff-group-filters>
            </div>
            <div class="align-self-start">
                <v-btn
                    v-if="filtersApplied"
                    color="error"
                    text
                    :disabled="loading || isSchedulerDisabled"
                    @click="onClearAll"
                    style="margin-top: 13px;"
                >
                    Clear All
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
import MenuOptions from '../../../../common/MenuOptions/MenuOptions'
import StaffGroupFilters from '../../../Filters/StaffGroupFilters'

export default {
    name: "StaffGroupFiltersView",
    components: { MenuOptions, StaffGroupFilters },
    data: () => ({
        searchInput: '',
    }),
    computed: {
        selectedStaffGroupId: {
            get () {
                return this.$store.getters.schedulerGetStaffGroupId
            },
            set (staffGroupId) {
                this.$store.dispatch('schedulerSetStaffGroupId', { staffGroupId })
            }
        },
        staffGroups () {
            return this.$store.getters.schedulerGetStaffGroups
        },
        staffGroupsLoading () {
            return this.$store.getters.schedulerGetStaffGroupsLoading
        },
        isSchedulerDisabled() {
            return this.$store.getters.schedulerGetIsDisabled
        },
        filtersApplied: {
            get () {
                return this.$store.getters.schedulerGetStaffGroupFiltersApplied
            },
            set (filtersApplied) {
                this.$store.dispatch('schedulerSetStaffGroupFiltersApplied', { filtersApplied })
            },
        },
        eventsLoading () {
            return this.$store.getters.schedulerGetStaffGroupEventsLoading
        },
        loading () {
            return this.staffGroupsLoading || this.eventsLoading
        },
        globalLocation () {
            return this.$root.globalLocation
        },
    },
    methods: {
        onClearAll () {
            this.filtersApplied = false
            window.EventBus.$emit('scheduler/onClearStaffGroupFilters')
            window.EventBus.$emit('staffGroupScheduler/fetchEvents')
        },
        onChangeStaffGroup () {
            this.filtersApplied = false
            this.searchInput = ''
        }
    },
    watch: {
        globalLocation () {
            this.selectedStaffGroupId = null
        }
    }
}
</script>

<style scoped>
    .staff-group-filters-view-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
        z-index: 18;
    }

    .min-width {
        min-width: 400px;
    }

    @media only screen and (max-width: 580px) {
        .min-width {
            min-width: 150px;
        }
    }

</style>
