<template>
    <v-dialog v-model="showModal" max-width="450px" elevation="10" scrollable persistent>
        <v-card dusk="deleteAvailabilityConfirmationModal">
            <v-toolbar class="destructive-modal" elevation="0" height="60">
                <v-toolbar-title class="text--h2">Delete Availability</v-toolbar-title>
            </v-toolbar>

            <v-card-title v-if="!is_recurring" class="text--paragraph no-word-break">
                Are you sure you want to remove this availability event?
            </v-card-title>

            <v-card-text class="pa-6" v-if="is_recurring">
                <v-radio-group v-model="delete_type">
                    <v-radio
                        value="single"
                    >
                        <template v-slot:label>
                            <div class="black--text text--paragraph">
                                This Period
                            </div>
                        </template>
                    </v-radio>
                    <v-radio
                        dusk="thisAndFollowingPeriodsButton"
                        value="recurring"
                    >
                        <template v-slot:label>
                            <div class="black--text text--paragraph">
                                This and Following Periods
                            </div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-end" style="column-gap: 5px;">
                        <v-btn text color="primary" @click="closeModal">Cancel</v-btn>
                        <v-btn color="error" outlined @click="confirmDelete" dusk="confirmDelete">Delete</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteAvailabilityModal",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        availabilityEvent: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            showModal: false,
            delete_type: 'single',
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
        },

        confirmDelete() {
            this.$emit('delete', this.delete_type)
            this.closeModal()
        }
    },
    computed: {
        is_recurring() {
            return !((typeof this.availabilityEvent.availability_event_group_id === 'undefined') || (!this.availabilityEvent.availability_event_group_id))
        }
    },
    watch: {
        value(newVal) {
            this.showModal = newVal
        },
        showModal(newVal) {
            if (!newVal) {
                this.$emit('input', false)
            }
        }
    }
}
</script>

<style scoped>
    .toolbar-title {
        font-size: 1rem;
    }
</style>
