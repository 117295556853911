<template>
    <save-preset-modal
        v-model="modalValue"
        :report="report"
        :filters="filters"
        :filters-body-param="filtersBodyParam"
        @onSavePreset="onSavePreset"
    />
</template>

<script>
import SavePresetModal from '../SavePresetModal'
import {
    parseReportDepartments,
    parseReportStaffGroups,
    parseReportJobTitles,
    parseReportShiftTimes,
    parseReportStaffMembers,
    parseReportShiftCancellationStatus,
    parseReportShiftCancellationType,
} from '../../../../../lib/helpers/reports'

export default {
    name: "SaveShiftCancellationPresetModal",
    components: {SavePresetModal},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        report: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        filtersBodyParam() {
            return {
                ...this.report.filters && Array.isArray(this.report.filters.departments) && {departments: this.report.filters.departments.map(dep => dep.id)},
                ...this.report.filters && Array.isArray(this.report.filters.staff_groups) && {staff_groups: this.report.filters.staff_groups.map(sg => sg.id)},
                ...this.report.filters && Array.isArray(this.report.filters.job_titles) && {job_titles: this.report.filters.job_titles.map(jt => jt.id)},
                ...this.report.filters && Array.isArray(this.report.filters.shift_times) && {shift_times: this.report.filters.shift_times.map(st => st.id)},
                ...this.report.filters && Array.isArray(this.report.filters.staff_members) && {staff_members: this.report.filters.staff_members.map(sm => sm.id)},
                ...this.report.filters && this.report.filters.type && {type: this.report.filters.type},
                locations: this.globalLocation ? [this.globalLocation._id] : []
            }
        },
        filters() {
            return {
                'Departments': parseReportDepartments(this.report),
                'Staff Groups': parseReportStaffGroups(this.report),
                'Job Titles': parseReportJobTitles(this.report),
                'Shift Times': parseReportShiftTimes(this.report),
                'Staff Members': parseReportStaffMembers(this.report),
                'Cancellation Type': parseReportShiftCancellationType(this.report),
            }
        },
        globalLocation() {
            return this.$root.globalLocation
        },
    },
    methods: {
        onSavePreset(preset) {
            this.$emit('onSavePreset', preset)
        }
    }
}
</script>

<style scoped>
</style>
