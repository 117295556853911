<template>
    <v-data-table
        :headers="headers"
        :items="formattedUsers"
        :loading="loadingUsers"
        :server-items-length="totalUsers"
        :options.sync="options"
        @update:options="onUpdateOptions"
        @click:row="onUserClick"
        id="managers-table"
        :title="dataTableTitle"
        no-data-text="Assign Users the Manager role to allow them to supervise Staff Groups and scheduling tasks."
    >
        <template v-slot:item.status="{ item }">
            <v-chip :class="{ 'ds-active-chip' : item.status === 'active', 'ds-inactive-chip': item.status !== 'active' }">{{ item.status | capitalize}}</v-chip>
        </template>
        <template v-slot:item.username="{ item }">
            <custom-avatar :user="item" chip></custom-avatar>
        </template>
        <template v-slot:item.department="{ item }">
            <truncating-chips-list
                :items="item.displayedDepartments"
                @onMouseEnter="onMouseEnterTruncatingList"
                @onMouseLeave="onMouseLeaveTruncatingList"
            />
        </template>
        <template v-slot:item.staff_group="{ item }">
            <truncating-chips-list
                :items="item.displayedStaffGroups"
                @onMouseEnter="onMouseEnterTruncatingList"
                @onMouseLeave="onMouseLeaveTruncatingList"
            />
        </template>
        <template v-slot:item.location="{ item }">
            <truncating-chips-list
                :items="item.displayedLocations"
                @onMouseEnter="onMouseEnterTruncatingList"
                @onMouseLeave="onMouseLeaveTruncatingList"
            />
        </template>
        <template v-slot:item.lastLogin="{ item }">
            {{ item.last_login_label }}
        </template>
    </v-data-table>
</template>

<script>

import CustomAvatar from "../../components/avatar/CustomAvatar";
import TruncatingChipsList from "../../components/common/Lists/TruncatingChipsList";
import userMethods from "../../lib/mixins/userMethods";
import dataTableTitle from "../../lib/mixins/dataTableTitle";

export default {
    name: "ManagersTable",
    components: {
        CustomAvatar,
        TruncatingChipsList
    },
    mixins: [userMethods, dataTableTitle],
    props: {
        users: {
            type: Array,
            default: () => []
        },
        totalUsers: {
            type: Number,
            default: 0,
        },
        loadingUsers: {
            type: Boolean,
            default: false,
        },
        locationsMap: {
            type: Object,
            default: () => {}
        },
        staffingDetails: {
            type: Array,
            default: () => []
        },
        shouldUpdateTableOptions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            headers: [
                { text: 'Status', value: 'status', width: '10%' },
                { text: 'Name', value: 'username', width: '15%' },
                { text: 'Department', value: 'department', width: '20%', sortable: false },
                { text: 'Staff Group', value: 'staff_group', width: '20%', sortable: false },
                { text: 'Location', value: 'location', width: '20%', sortable: false },
                { text: 'Last Login', value: 'lastLogin', width: '15%' }
            ],
            options: {},
            departmentsByUser: {},
            staffGroupsByUser: {}
        }
    },
    computed: {
        formattedUsers() {
            return this.users.map(user => {
                user.last_login_label = 'N/A'
                if (user.last_login) {
                    user.last_login_label = this.formatLastLogin(user, this.globalTimezone)
                }

                const newLocations = user.locations
                    .filter(location => this.locationsMap.hasOwnProperty(location))
                    .map(location => this.locationsMap[location])
                const departments = this.departmentsByUser.hasOwnProperty(user._id) ? this.departmentsByUser[user._id] : []
                const staff_groups = this.staffGroupsByUser.hasOwnProperty(user._id) ? this.staffGroupsByUser[user._id] : []
                return {
                    ...user,
                    displayedLocations: newLocations,
                    displayedDepartments: _.sortBy(departments),
                    displayedStaffGroups: _.sortBy(staff_groups)
                }
            })
        },
        globalLocation() {
            return this.$root.globalLocation;
        },
        globalTimezone() {
            return this.$root.globalTimezone
        }
    },
    methods: {
        onUpdateOptions() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options
            this.$emit('update:options', {
                page,
                itemsPerPage,
                sortBy: this.getSortByOptions(sortBy),
                sortDesc: this.getSortDescOptions(sortDesc)
            })
        },
        getSortByOptions(sortByArray) {
            if(sortByArray.length > 0) {
                const sortBy = sortByArray[0]
                if(sortBy === 'username') {
                    return 'last_name'
                } else if(sortBy === 'lastLogin') {
                    return 'last_login'
                } else if(sortBy === 'location') {
                    return 'locations'
                } else {
                    return sortBy
                }
            }
            return null
        },
        getSortDescOptions(sortDescArray) {
            if(sortDescArray.length > 0) {
                return sortDescArray[0]
            }
            return null
        },
        onUserClick(user) {
            const {
                $user,
                $userHasPermission,
            } = this
            if($userHasPermission($user, this.$config.permissions.USERS.VIEW_OTHERS_PROFILE) || $userHasPermission($user, this.$config.permissions.USERS.VIEW_OTHERS_PERMISSIONS)) {
                this.$emit('user-clicked', user)
            }
        },
        initDepartmentsByUser() {
            let departmentsByUser = {}
            this.staffingDetails.forEach(staffingDetail => {
                if (Array.isArray(staffingDetail.managers)) {
                    staffingDetail.managers.forEach(manager => {
                        departmentsByUser = {
                            ...departmentsByUser,
                            [manager.id]: departmentsByUser.hasOwnProperty(manager.id) ?
                                [...departmentsByUser[manager.id], {
                                    id: staffingDetail.department_id,
                                    name: staffingDetail.department_name
                                }] :
                                [{
                                    id: staffingDetail.department_id,
                                    name: staffingDetail.department_name
                                }]
                        }
                    })
                }
            })
            this.departmentsByUser = Object.keys(departmentsByUser).reduce((accum, managerId) => {
                return {
                    ...accum,
                    [managerId]: _.uniqBy(departmentsByUser[managerId], 'id').map(department => department.name)
                }
            }, {})
        },
        initStaffGroupsByUser() {
            let staffGroupsByUser = {}
            this.staffingDetails.forEach(staffingDetail => {
                if (Array.isArray(staffingDetail.managers)) {
                    staffingDetail.managers.forEach(manager => {
                        staffGroupsByUser = {
                            ...staffGroupsByUser,
                            [manager.id]: staffGroupsByUser.hasOwnProperty(manager.id) ?
                                [...staffGroupsByUser[manager.id], {
                                    id: staffingDetail.staff_group_id,
                                    name: staffingDetail.staff_group_name
                                }] :
                                [{
                                    id: staffingDetail.staff_group_id,
                                    name: staffingDetail.staff_group_name
                                }]
                        }
                    })
                }
            })
            this.staffGroupsByUser = Object.keys(staffGroupsByUser).reduce((accum, managerId) => {
                return {
                    ...accum,
                    [managerId]: _.uniqBy(staffGroupsByUser[managerId], 'id').map(staffGroup => staffGroup.name)
                }
            }, {})
        },
    },
    created() {
        this.initDepartmentsByUser()
        this.initStaffGroupsByUser()
    },

    watch: {
        globalLocation() {
            if(this.shouldUpdateTableOptions) {
                const tableOptionsCopy = {...this.options}
                this.options = {
                    ...this.options,
                    page: 1,
                    sortBy: [],
                    sortDesc: []
                }
                if(_.isEqual(tableOptionsCopy, this.options)) {
                    this.onUpdateOptions()
                }
            }
        }
    }
}
</script>

<style scoped>
#managers-table :deep(tr:hover) {
    cursor: pointer;
}
</style>
