<template>
    <div class="d-flex flex-column align-center px-8" style="width: 100%;">
        <p class="text--h1">
            You’re all set!
        </p>
        <p class="text--paragraph text-center">
            Start adding users, managing permissions, and tracking custom data for your organization.
        </p>
        <div class="d-flex justify-center align-center mt-5 mb-16">
            <v-btn
                color="primary"
                @click="onLogin"
                href="/onboarding/complete"
            >
                Log In to Koroid
            </v-btn>
        </div>
        <p class="text--paragraph text-center">
            You can learn about Koroid features and find answers to common questions at <br />
            <a :href=supportUrl class="text-decoration-underline">Koroid Support</a>.
        </p>
    </div>
</template>

<script>
export default {
    name: "AllSet",
    methods: {
        onLogin() {
            this.$emit('onLogin')
        }
    },
    computed: {
        supportUrl() {
            return this.$root.websiteUrl + '/support';
        }
    }
}
</script>

<style scoped>

</style>
