<template>
    <div>
        <v-app-bar dark color="primary">
            <v-btn icon @click="onBackPressed">
                <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="openDialog"
            >
                <v-icon>delete</v-icon>
            </v-btn>
        </v-app-bar>
        <v-dialog
            v-model="dialog"
            max-width="290"
        >
            <v-card>
                <v-card-title class="headline">
                    Are you sure you want to delete this notification ?
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-3"
                        outlined
                        @click="onDeleteNotification"
                        :loading="isRemoving"
                        :disabled="isRemoving"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "RemoveNotificationToolbar",
        props: {
            notificationId: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            },
            isRemoving: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                dialog: false
            }
        },
        methods: {
            openDialog: function() {
                this.dialog = true
            },
            closeDialog: function() {
                this.dialog = false
            },
            onBackPressed: function() {
                this.$emit('onBackPressed')
            },
            onDeleteNotification: function() {
                this.$emit('onDeleteNotification', this.notificationId)
            }
        }
    }
</script>

<style scoped>

</style>
