<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="1200px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Create Historical Shift</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text ref="requestFormContent" class="request-form-content">
                <v-row>
                    <v-col cols="12" md="6" class="mt-6 pl-0">
                        <historical-shift-date-form
                            ref="shiftDateFormRef"
                            :edit-mode=false
                            :initial-request-form="initialRequestForm"
                            :request-form-ids-by-day="requestFormIdsByDay"
                        ></historical-shift-date-form>
                        <shift-time-form
                            ref="shiftTimeFormRef"
                            v-model="duration"
                            :location="location"
                            :edit-mode=false
                            :initial-request-form="initialRequestForm">
                        </shift-time-form>
                    </v-col>
                    <v-col cols="12" md="6" class="mt-6" :style="rhsContainerStyle">
                        <shift-details-form
                            ref="shiftDetailsFormRef"
                            :location="location"
                            :edit-mode=false
                            :initial-request-form="initialRequestForm"
                            :auth-staffing-details="authStaffingDetails"
                            @onAddJobTitle="onAddJobTitle"
                        ></shift-details-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" :disabled="isCreating" @click="onClose">Close</v-btn>
                <v-btn
                    ref="nextBtn"
                    color="primary"
                    outlined
                    :loading="isCreating"
                    @click="onNext"
                >Next
                </v-btn>
            </v-card-actions>
        </v-card>
        <unsaved-changes-dialog ref="unsavedChangesDialogRef"/>
    </v-dialog>
</template>

<script>
import HistoricalShiftDateForm from "../Forms/ShiftDateForm/HistoricalShiftDateForm";
import ShiftDetailsForm from "../Forms/ShiftDetailsForm/ShiftDetailsForm";
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";
import message from '../../../lib/toast'
import ShiftTimeForm from "../Forms/ShiftTimeForm/ShiftTimeForm";

export default {
    name: "HistoricalRequestFormModal",
    components: {HistoricalShiftDateForm, ShiftDetailsForm, UnsavedChangesDialog, ShiftTimeForm},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            default: () => null
        },
        timezone: {
            type: String,
            default: null
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
        authStaffingDetails: {
            type: Array,
            default: function () {
                return []
            }
        },
        requestFormIdsByDay: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        isCreating: false,
        duration: 0,
        innerWidth: window.innerWidth,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        shiftDateFormRef() {
            return this.$refs.shiftDateFormRef
        },
        shiftDetailsFormRef() {
            return this.$refs.shiftDetailsFormRef
        },
        shiftTimeFormRef() {
            return this.$refs.shiftTimeFormRef
        },
        unsavedChangesDialogRef() {
            return this.$refs.unsavedChangesDialogRef
        },
        bodyParams() {
            const shiftDateFormBodyParams = this.shiftDateFormRef?.bodyParams || {}
            const shiftTimeFormBodyParams = this.shiftTimeFormRef?.bodyParams || {}
            const shiftDetailsFormBodyParams = this.shiftDetailsFormRef?.bodyParams || {}
            const {
                location,
                timezone,
            } = this
            return {
                timezone,
                location_id: location && location._id,
                ...shiftDateFormBodyParams,
                ...shiftTimeFormBodyParams,
                ...shiftDetailsFormBodyParams,
            }
        },
        rhsContainerStyle() {
            return {
                ...this.innerWidth >= 960 && {
                    'border-left': '1px solid #000000'
                }
            }
        }
    },
    methods: {
        onAddJobTitle(scrollTo) {
            this.scrollTo(scrollTo)
        },
        scrollTo(ref) {
            this.$vuetify.goTo(ref, {
                container: this.$refs.requestFormContent,
                easing: 'easeInOutQuart',
                duration: 300,
            })
        },
        onClose() {
            const {shiftDateFormRef, shiftDetailsFormRef, unsavedChangesDialogRef, shiftTimeFormRef} = this
            if (shiftDateFormRef && shiftDetailsFormRef && shiftTimeFormRef) {
                if (shiftDateFormRef.isDirty || shiftTimeFormRef.isDirty || shiftDetailsFormRef.isDirty) {
                    return unsavedChangesDialogRef.open()
                        .then(() => {
                            this.modalValue = false
                            this.$emit('onClose')
                        })
                        .catch(() => {
                        })
                }
            }
            this.modalValue = false
            this.$emit('onClose')
        },
        onNext() {
            const validateShiftDateForm = this.shiftDateFormRef?.validate
            const validateShiftTimeForm = this.shiftTimeFormRef?.validate
            const validateShiftDetailsForm = this.shiftDetailsFormRef?.validate

            let isValid = false

            if (validateShiftDetailsForm) {
                let isDateFormValid = false
                let isShiftTimeFormValid = false
                if (validateShiftTimeForm) {
                    isShiftTimeFormValid = validateShiftTimeForm()
                }
                const isShiftDetailsFormValid = validateShiftDetailsForm()
                const isShiftCountValid = !this.shiftDetailsFormRef.noJobTitlesWithShifts

                if (validateShiftDateForm) {
                    isDateFormValid = validateShiftDateForm()
                }

                isValid = isDateFormValid && isShiftDetailsFormValid && isShiftTimeFormValid && isShiftCountValid
            }

            if (isValid) {
                this.$set(this.bodyParams, 'historical_shift', true);
                this.createRequestForms(this.bodyParams)
            }
        },
        createRequestForms(bodyParams) {
            this.isCreating = true
            this.api.post('/request-forms/create-historical-shift', bodyParams)
                .then(data => {
                    if (data && data.data && data.data.request_details && Array.isArray(data.data.request_form_ids_by_day)) {
                        this.$emit('onNext', data.data)
                    } else {
                        this.$emit('onNext', null)
                    }
                    message('success', 'Historical shift added to schedule.')
                }).catch(console.log)
                .finally(() => {
                    this.isCreating = false
                })
        },
        onResize() {
            this.innerWidth = window.innerWidth
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>
.request-form-content {
    padding: 0 12px;
}
</style>
