<template>
    <div class="bulk-upload-users-match-fields-container">
        <div class="bulk-upload-users-match-fields-instructions">
            <bulk-upload-users-instructions :email-domains="emailDomains" />
        </div>
        <div class="bulk-upload-users-match-fields-content">
            <div class="bulk-upload-users-match-fields-wrapper">
                <div class="text--h3 text-center">
                    Match File Data
                </div>
                <div class="text-center text--paragraph">
                    Use the dropdowns to match the columns from your file to the Koroid database.
                </div>
                <v-container>
                    <v-row no-gutters class="text--h4">
                        <v-col cols="5" class="text-center">
                            File Data
                        </v-col>
                        <v-col cols="2">

                        </v-col>
                        <v-col cols="5" class="text-center">
                            Koroid Database
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                :value="matchFields.firstName"
                                @input="syncFirstName"
                                :error="errors.firstName"
                                label="Select Column"
                                outlined
                                hide-details
                                clearable
                                :items="headers"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                            <v-icon>
                                mdi-arrow-right-thick
                            </v-icon>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                outlined
                                value="First Name"
                                readonly
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                :value="matchFields.lastName"
                                @input="syncLastName"
                                :error="errors.lastName"
                                label="Select Column"
                                outlined
                                hide-details
                                clearable
                                :items="headers"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                            <v-icon>
                                mdi-arrow-right-thick
                            </v-icon>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                outlined
                                value="Last Name"
                                readonly
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                :value="matchFields.username"
                                @input="syncUsername"
                                :error="errors.username"
                                label="Select Column"
                                outlined
                                hide-details
                                clearable
                                :items="headers"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                            <v-icon>
                                mdi-arrow-right-thick
                            </v-icon>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                outlined
                                value="Username"
                                readonly
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                :value="matchFields.email"
                                @input="syncEmail"
                                :error="errors.email"
                                label="Select Column"
                                outlined
                                hide-details
                                clearable
                                :items="headers"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                            <v-icon>
                                mdi-arrow-right-thick
                            </v-icon>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                outlined
                                value="Email Address"
                                readonly
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="text-center error--text" v-show="hasError">
                    Can not match a column from file data more than once.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BulkUploadUsersInstructions from './BulkUploadUsersInstructions'

export default {
    name: "BulkUploadUsersMatchFields",
    components: { BulkUploadUsersInstructions },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        headers: {
            type: Array,
            default: () => []
        },
        emailDomains: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        matchFields: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        hasError () {
            return Object.values(this.errors).some(value => value)
        }
    },
    methods: {
        syncFirstName (firstName) {
            this.matchFields = {
                ...this.matchFields,
                firstName,
            }
        },
        syncLastName (lastName) {
            this.matchFields = {
                ...this.matchFields,
                lastName,
            }
        },
        syncUsername (username) {
            this.matchFields = {
                ...this.matchFields,
                username,
            }
        },
        syncEmail (email) {
            this.matchFields = {
                ...this.matchFields,
                email,
            }
        }
    }
}
</script>

<style scoped>
    .bulk-upload-users-match-fields-container {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .bulk-upload-users-match-fields-instructions {
        flex: 0 0 auto;
    }

    .bulk-upload-users-match-fields-content {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        overflow-y: auto;
    }

    .bulk-upload-users-match-fields-wrapper {
        display: flex;
        flex-direction: column;
        padding: 24px 80px;
        row-gap: 16px;
        width: 100%;
    }
</style>
