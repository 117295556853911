<template>
    <div>
        <v-form ref="mainReportFiltersRef">
            <v-autocomplete
                v-if="!hideEmploymentType"
                v-model="selectedEmploymentTypeId"
                :items="employmentTypes"
                :loading="loading"
                :readonly="readonly"
                :clearable="!readonly"
                :disabled="loading"
                label="Employment Type"
                item-value="_id"
                item-text="name"
                no-data-text="No Employment Types in Location"
                :rules="rules.required"
                class="required"
                @change="emitOnChange"
                outlined
            >
            </v-autocomplete>
            <v-autocomplete
                v-if="!hideDepartments"
                v-model="selectedDepartmentIds"
                :items="departments"
                :loading="loading"
                :readonly="readonly"
                :clearable="!readonly"
                :disabled="loading"
                label="Departments"
                item-value="_id"
                item-text="name"
                no-data-text="No Departments in Location"
                @change="emitOnChange"
                multiple
                outlined
            >
                <template v-slot:selection="{ attrs, item, index, select, selected }">
                    <v-chip
                        v-if="index < maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeSelectedDepartment(index)"
                    >
                        {{ item.name }}
                    </v-chip>

                    <v-chip
                        v-else-if="index === maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                    >
                        (+{{ selectedDepartmentIds.length - maxDisplay }})
                    </v-chip>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="!hideStaffGroups"
                v-model="selectedStaffGroupIds"
                :items="staffGroups"
                :loading="loading"
                :disabled="loading"
                :readonly="readonly"
                :clearable="!readonly"
                label="Staff Groups"
                item-value="_id"
                item-text="name"
                no-data-text="No Staff Groups in Location"
                @change="emitOnChange"
                multiple
                outlined
            >
                <template v-slot:selection="{ attrs, item, index, select, selected }">
                    <v-chip
                        v-if="index < maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeSelectedStaffGroup(index)"
                    >
                        {{ item.name }}
                    </v-chip>

                    <v-chip
                        v-else-if="index === maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                    >
                        (+{{ selectedStaffGroupIds.length - maxDisplay }})
                    </v-chip>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="!hideJobTitles"
                v-model="selectedJobTitleIds"
                :items="jobTitles"
                :loading="loading"
                :disabled="loading"
                :readonly="readonly"
                :clearable="!readonly"
                label="Job Titles"
                item-value="_id"
                item-text="name"
                no-data-text="No Job Titles in Location"
                @change="emitOnChange"
                multiple
                outlined
            >
                <template v-slot:selection="{ attrs, item, index, select, selected }">
                    <v-chip
                        v-if="index < maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeSelectedJobTitle(index)"
                    >
                        {{ item.name }}
                    </v-chip>

                    <v-chip
                        v-else-if="index === maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                    >
                        (+{{ selectedJobTitleIds.length - maxDisplay }})
                    </v-chip>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="!hideCredentials"
                v-model="selectedCredentialIds"
                :items="credentials"
                :loading="loading"
                :disabled="loading"
                :readonly="readonly"
                :clearable="!readonly"
                label="Credentials"
                item-value="_id"
                item-text="name"
                @change="emitOnChange"
                multiple
                outlined
            >
                <template v-slot:selection="{ attrs, item, index, select, selected }">
                    <v-chip
                        v-if="index < maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeSelectedCredential(index)"
                    >
                        {{ item.name }}
                    </v-chip>

                    <v-chip
                        v-else-if="index === maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                    >
                        (+{{ selectedCredentialIds.length - maxDisplay }})
                    </v-chip>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="!hideShiftTimes"
                v-model="selectedShiftTimeIds"
                :items="shiftTimes"
                :loading="loading"
                :disabled="loading"
                :readonly="readonly"
                :clearable="!readonly"
                label="Shift Times"
                item-value="_id"
                item-text="name"
                @change="emitOnChange"
                multiple
                outlined
            >
                <template v-slot:selection="{ attrs, item, index, select, selected }">
                    <v-chip
                        v-if="index < maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeSelectedShiftTime(index)"
                    >
                        {{ item.name }}
                    </v-chip>

                    <v-chip
                        v-else-if="index === maxDisplay"
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                    >
                        (+{{ selectedShiftTimeIds.length - maxDisplay }})
                    </v-chip>
                </template>
            </v-autocomplete>
        </v-form>
    </div>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";

export default {
    name: "MainReportFilters",
    mixins: [validationRules],
    props: {
        preselectedFilters: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        reportType: {
            type: String,
            default: 'shift_details'
        },
        hideEmploymentType: {
            type: Boolean,
            default: true,
        },
        hideDepartments: {
            type: Boolean,
            default: false,
        },
        hideStaffGroups: {
            type: Boolean,
            default: false,
        },
        hideJobTitles: {
            type: Boolean,
            default: false,
        },
        hideCredentials: {
            type: Boolean,
            default: false,
        },
        hideShiftTimes: {
            type: Boolean,
            default: false,
        },
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        selectedEmploymentTypeId: null,
        selectedDepartmentIds: [],
        selectedStaffGroupIds: [],
        selectedJobTitleIds: [],
        selectedCredentialIds: [],
        selectedShiftTimeIds: [],
        employmentTypes: [],
        departments: [],
        staffGroups: [],
        jobTitles: [],
        credentials: [],
        shiftTimes: [],
        loading: false,
        api: new formHelper(),
        maxDisplay: 3,
    }),
    computed: {
        filters() {
            return {
                ...(!this.hideEmploymentType && {
                    employmentTypeId: this.selectedEmploymentTypeId,
                }),
                ...(!this.hideDepartments && {
                    departmentIds: this.selectedDepartmentIds,
                }),
                ...(!this.hideStaffGroups && {
                    staffGroupIds: this.selectedStaffGroupIds,
                }),
                ...(!this.hideJobTitles && {
                    jobTitleIds: this.selectedJobTitleIds,
                }),
                ...(!this.hideCredentials && {
                    credentialIds: this.selectedCredentialIds,
                }),
                ...(!this.hideShiftTimes && {
                    shiftTimeIds: this.selectedShiftTimeIds
                }),
            }
        },
        mainReportFiltersRef() {
            return this.$refs.mainReportFiltersRef
        },
    },
    methods: {
        loadData() {
            if (this.location) {
                this.loading = true
                this.api.post('/reports/filters/all', {
                    location_ids: [this.location._id],
                    report_type: this.reportType
                }).then(({data}) => {
                    if (data.data) {
                        if (Array.isArray(data.data.staff_types)) {
                            this.employmentTypes = Array.from(data.data.staff_types)
                        }
                        if (Array.isArray(data.data.departments)) {
                            this.departments = Array.from(data.data.departments)
                        }
                        if (Array.isArray(data.data.staff_groups)) {
                            this.staffGroups = Array.from(data.data.staff_groups)
                        }
                        if (Array.isArray(data.data.job_titles)) {
                            this.jobTitles = Array.from(data.data.job_titles)
                        }
                        if (Array.isArray(data.data.requirements)) {
                            this.credentials = Array.from(data.data.requirements)
                        }
                        if (Array.isArray(data.data.shift_times)) {
                            this.shiftTimes = Array.from(data.data.shift_times)
                        }
                    }
                }).catch(() => {
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        emitOnFiltersChange() {
            this.$emit('onFiltersChange', this.filters)
        },
        emitOnChange() {
            this.$emit('onChange')
        },
        setFilters(filters) {
            if (filters) {
                this.selectedEmploymentTypeId = Boolean(!!filters.employmentTypeId) ? filters.employmentTypeId : null
                this.selectedDepartmentIds = Array.isArray(filters.departmentIds) ? filters.departmentIds : []
                this.selectedStaffGroupIds = Array.isArray(filters.staffGroupIds) ? filters.staffGroupIds : []
                this.selectedJobTitleIds = Array.isArray(filters.jobTitleIds) ? filters.jobTitleIds : []
                this.selectedCredentialIds = Array.isArray(filters.credentialIds) ? filters.credentialIds : []
                this.selectedShiftTimeIds = Array.isArray(filters.shiftTimeIds) ? filters.shiftTimeIds : []
            }
        },
        removeSelectedShiftTime(index) {
            this.selectedShiftTimeIds.splice(index, 1)
        },
        removeSelectedCredential(index) {
            this.selectedCredentialIds.splice(index, 1)
        },
        removeSelectedJobTitle(index) {
            this.selectedJobTitleIds.splice(index, 1)
        },
        removeSelectedStaffGroup(index) {
            this.selectedStaffGroupIds.splice(index, 1)
        },
        removeSelectedDepartment(index) {
            this.selectedDepartmentIds.splice(index, 1)
        },
        validateFilters() {
            if (this.mainReportFiltersRef) {
                return this.mainReportFiltersRef.validate()
            }
            return true
        }
    },
    watch: {
        preselectedFilters(filters) {
            this.setFilters(filters)
        },
        filters: {
            immediate: true,
            handler() {
                this.emitOnFiltersChange()
            }
        }
    },
    mounted() {
        this.setFilters(this.preselectedFilters, true)
        this.loadData()
    }
}
</script>

<style scoped>

</style>
