module.exports = {
    data() {
        return {
            isDataTableTitleHidden: false
        }
    },
    computed: {
        dataTableTitle() {
            if (this.isDataTableTitleHidden) {
                return ''
            }
            return 'Click on a row to access user details'
        }
    },
    methods: {
        onMouseEnterTruncatingList() {
            this.isDataTableTitleHidden = true
        },
        onMouseLeaveTruncatingList() {
            this.isDataTableTitleHidden = false
        }
    }
}
