<template>
    <filters-picker :disabled="disabled" @onOpen="onOpen" ref="filtersPicker">
        <template v-slot:filters>
            <v-card :width="500" class="pa-3" elevation="2">
                <v-container fluid>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedJobTitleIds"
                                label="Job Title"
                                no-data-text="No active Job Titles in Staff Group"
                                item-value="_id"
                                item-text="title"
                                :items="jobTitles"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="jobTitlesSearchInput"
                                @change="jobTitlesSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-clipboard-account</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <user-search
                                v-model="selectedStaffMemberIds"
                                :staff-group-id="staffGroupId"
                                chips
                            ></user-search>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedCredentialIds"
                                label="Credential"
                                no-data-text="No Credentials connected to Staff Group"
                                item-value="_id"
                                item-text="name"
                                :items="credentials"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="credentialsSearchInput"
                                @change="credentialsSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-license</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="selectedEmploymentTypeIds"
                                label="Employment Type"
                                item-value="_id"
                                item-text="name"
                                :items="employmentTypes"
                                :loading="api.busy"
                                :disabled="api.busy"
                                outlined
                                clearable
                                multiple
                                chips
                                deletable-chips
                                :search-input.sync="employmentTypesSearchInput"
                                @change="employmentTypesSearchInput = ''"
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-badge-account-horizontal</v-icon>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <v-btn
                                color="primary"
                                outlined
                                :disabled="filtersDisabled"
                                @click="onApplyFilters"
                            >
                                Apply Filters
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </template>
    </filters-picker>
</template>

<script>
import FiltersPicker from '../Inputs/FiltersPicker'
import UserSearch from '../Scheduler/Staff/UserSearch'

export default {
    name: "StaffFilters",
    components: { FiltersPicker, UserSearch },
    props: {
        staffGroupId: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        filters: {},
        shouldFetch: false,
        jobTitlesSearchInput: '',
        credentialsSearchInput: '',
        employmentTypesSearchInput: '',
        api: new formHelper(),
    }),
    computed: {
        selectedValues: {
            get () {
                return this.$store.getters.schedulerGetStaffFilters
            },
            set (filters) {
                this.dispatchSchedulerSetStaffFilters(filters)
            },
        },
        selectedJobTitleIds: {
            get () {
                return this.$store.getters.schedulerGetStaffFilters.selectedJobTitleIds
            },
            set (selectedJobTitleIds) {
                this.dispatchSchedulerSetStaffFilters({
                    ...this.selectedValues,
                    selectedJobTitleIds,
                })
            },
        },
        selectedStaffMemberIds: {
            get () {
                return this.$store.getters.schedulerGetStaffFilters.selectedStaffMemberIds
            },
            set (selectedStaffMemberIds) {
                this.dispatchSchedulerSetStaffFilters({
                    ...this.selectedValues,
                    selectedStaffMemberIds,
                })
            },
        },
        selectedCredentialIds: {
            get () {
                return this.$store.getters.schedulerGetStaffFilters.selectedCredentialIds
            },
            set (selectedCredentialIds) {
                this.dispatchSchedulerSetStaffFilters({
                    ...this.selectedValues,
                    selectedCredentialIds,
                })
            },
        },
        selectedEmploymentTypeIds: {
            get () {
                return this.$store.getters.schedulerGetStaffFilters.selectedEmploymentTypeIds
            },
            set (selectedEmploymentTypeIds) {
                this.dispatchSchedulerSetStaffFilters({
                    ...this.selectedValues,
                    selectedEmploymentTypeIds,
                })
            },
        },
        filtersApplied: {
            get () {
                return this.$store.getters.schedulerGetStaffFiltersApplied
            },
            set (filtersApplied) {
                this.$store.dispatch('schedulerSetStaffFiltersApplied', { filtersApplied })
            },
        },
        jobTitles () {
            return this.filters.job_titles || []
        },
        credentials () {
            return this.filters.requirements || []
        },
        employmentTypes () {
            return this.filters.staff_types || []
        },
        filtersDisabled () {
            return this.api.busy || (
                this.selectedJobTitleIds.length === 0 &&
                this.selectedStaffMemberIds.length === 0 &&
                this.selectedCredentialIds.length === 0 &&
                this.selectedEmploymentTypeIds.length === 0
            )
        }
    },
    methods: {
        onOpen () {
            if (!this.shouldFetch) {
                this.shouldFetch = true
            }
        },
        onApplyFilters () {
            this.filtersApplied = true
            window.EventBus.$emit('staffScheduler/fetchEvents')
            this.$refs?.filtersPicker.closePicker()
        },
        clearData () {
            this.selectedValues = {
                selectedJobTitleIds: [],
                selectedStaffMemberIds: [],
                selectedCredentialIds: [],
                selectedEmploymentTypeIds: [],
            }
            this.filtersApplied = false
            this.shouldFetch = false
        },
        dispatchSchedulerSetStaffFilters (filters) {
            this.$store.dispatch('schedulerSetStaffFilters', { filters })
        }
    },
    watch: {
        staffGroupId: {
            immediate: true,
            handler () {
                this.clearData()
            }
        },
        shouldFetch: {
            immediate: true,
            handler (shouldFetch) {
                if (shouldFetch) {
                    const { staffGroupId } = this
                    if (staffGroupId) {
                        this.api.get(`/schedules/filters/staff/${staffGroupId}`).then(({ data }) => {
                            if (data) {
                                this.filters = {
                                    ...this.filters,
                                    ...data,
                                }
                            }
                        }).catch(() => {})
                    }
                }
            }
        }
    },
    created () {
        window.EventBus.$on('scheduler/onClearStaffFilters', () => {
            this.dispatchSchedulerSetStaffFilters({
                selectedJobTitleIds: [],
                selectedStaffMemberIds: [],
                selectedCredentialIds: [],
                selectedEmploymentTypeIds: [],
            })
        })
    }
}
</script>

<style scoped>

</style>
