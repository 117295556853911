<template>
    <v-card>
        <v-toolbar class="informational-modal" elevation="0">
            <v-toolbar-title class="text--h2">
                Options
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="saveAndClose()" :disabled="isSaving">
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </v-toolbar>

        <v-container v-if="isLoading" fluid style="padding: 0 18px;">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-container>

        <v-container v-else fluid style="padding: 0 18px;">
            <v-switch v-model="enablePredictions" color="primary" inset>
                <template v-slot:label>
                    <div style="padding-left: 10px;">
                        <p style="margin-bottom: 4px;">
                            <v-icon small>mdi-auto-fix</v-icon>
                            <span class="text--h4">AI-powered scheduling (experimental)</span>
                        </p>
                        <div class="text--paragraph">
                            Use machine learning to automatically predict which staff members will be available at a selected shift time. This feature is experimental and may not be accurate.
                        </div>
                    </div>
                </template>
            </v-switch>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="secondary"
                @click="saveAndClose()"
                :loading="isSaving"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "SchedulingOptionsModal",
    model: {
        prop: "open",
        event: "change",
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isSaving: false,
        enablePredictions: null,
        api: new formHelper(),
    }),
    computed: {
        isLoading() {
            return this.enablePredictions == null;
        },
        predictionsEnabled() {
            return this.$root.instancePredictionsEnabled && this.$root.organizationPredictionsEnabled && this.$store.getters.getPredictionsEnabled;
        },
    },
    mounted() {
        this.enablePredictions = this.predictionsEnabled;
    },
    methods: {
        async saveAndClose() {
            this.isSaving = true;

            await this.api.post('/user/ai-predictions-enabled', {
                enabled: this.enablePredictions,
            });

            this.dispatchSetPredictionsEnabled(this.enablePredictions);

            this.isSaving = false;
            this.$emit('change', false);
        },
        dispatchSetPredictionsEnabled(enabled) {
            this.$store.dispatch('setPredictionsEnabled', { enabled })
        },
    },
}
</script>
